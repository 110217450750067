import React, { createContext, useState, useEffect } from "react";
import { sessionAPI } from "./../api/sessionAPI.js";
import { useRef } from "react";

export const SessionContext = createContext();
const SessionProvider = (props) => {
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const isSessionId = JSON.parse(sessionStorage.getItem("SessionId"));
  const session_id = Session || JSON.parse(sessionStorage.getItem("SessionId"));

  const token = localStorage.getItem("token");
  let organization_id = school_user?.user_detail?.organization_id;
  const [SessionData, setSessionData] = useState([]);
  var [Session, setSession] = useState("");
  const sessionRef = useRef(session_id);

  useEffect(() => {
    if (sessionRef.current !== session_id) {
      window.location.href = "/School/dashboard";
    }
  }, [session_id]);
  const organizationId = { organization_id };

  useEffect(() => {
    const fetchSessionData = async () => {
      try {
        const response = await sessionAPI.getAllSession(organizationId);
        setSessionData(response.data.payload);
        const defaultSession = response.data.payload.find(
          (session) => session.current_session === true
        )?.id;
        isSessionId ? setSession(isSessionId) : setSession(defaultSession);
      } catch (error) {
        console.error(error.message);
      }
    };
    if (token && organization_id) {
      fetchSessionData();
    }

 
    
  }, [organization_id]);

  let handlesessionselect = (e) => {
    setSession(e.target.value);
    window.sessionStorage.setItem("SessionId", e.target.value);
    localStorage.removeItem('selectedModuleId');
    localStorage.removeItem('selectedMenuId');
  };

  return (
    <SessionContext.Provider
      value={{
        SessionData,
        setSessionData,
        Session,
        setSession,
        handlesessionselect,
      }}
    >
      {props.children}
    </SessionContext.Provider>
  );
};

export default SessionProvider;
