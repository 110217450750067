import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";

const token = localStorage.getItem("token");

export const StudentPromotionAPI = {
  getNextSession: async (data) => {
    const axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_next_session/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getPromoteDataSectionWise: async (data) => {
    const axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_promote_data_section_wise/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getStudentForPromotion: async (data) => {
    const axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_adddimited_student_for_promotion/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  promoteStudentForNextSession: async (data) => {
    const axiosConfig = {
      method: "POST",
      url: `${BASE_URL}promote_student_next_class/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  checkNextSessionFees: async (data) => {
    const axiosConfig = {
      method: "POST",
      url: `${BASE_URL}check_next_session_fees_structure/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
};
