import React, { useCallback, useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AiOutlineFileSearch } from "react-icons/ai";
import moment from "moment/moment";
import Swal from "sweetalert2";
import { SessionContext } from "../../../context/sessionContext";
import { Modal, ModalBody, ModalFooter, Table } from "reactstrap";
import { getStudentList } from "../../../actions/school_user/StudentRegistration";
import Pagination from "react-js-pagination";
const  AdmittedStudentList = (props) => {
  const { setstudent_uid } = props;
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  let school_id = user_detail?.fk_school_id;
  const { Session } = useContext(SessionContext);
  let session_id = Session || sessionStorage.getItem("SessionId");
  const { studentdata } = useSelector((state) => state.studentRegistration);
  const [CurrentPage, setCurrentPage] = useState(1);

  const [Page, setPage] = useState(1);
  const resultPerPage = 10;
  const [searchQuery, setSearchQuery] = useState("");
  const [idQuery, setIdQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const dispatch = useDispatch();
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setSearchResults([]);
    setNoResultsMessage("");
    setCurrentPage(1);
    // setIdQuery("");
    // setSearchQuery("");
  };

  const handleInputChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const handleIdInputChange = (e) => {
    const query = e.target.value;
    setIdQuery(query);
  };
  const handleSearch = () => {
    // if (searchQuery.trim() === "" && idQuery.trim() === "") {
    //   Swal.fire({
    //     text: "Please enter Student code or Student Name",
    //     icon: "error",
    //   });

    //   return;
    // }
    const filteredResults = studentdata?.payload?.filter(
      (item) =>
        item.std_name?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
        item.student_code?.toString().includes(idQuery)
    );
    setSearchResults(filteredResults);
    setSearchQuery("");
    setIdQuery("");
    if (filteredResults?.length === 0) {
      setNoResultsMessage("No results found.");
    } else {
      setNoResultsMessage("");
    }
  };

  const dispatchGetStudentList = useCallback(
    (token, body) => {
      return dispatch(getStudentList(token, body));
    },
    [dispatch]
  );
  useEffect(() => {
    let body = {
      fk_academic: session_id,
      school_id: school_id,
      student_name: searchQuery,
      student_code: idQuery,
      page: CurrentPage,
      status_type: "Admitted",
    };
    if (token) {
      dispatchGetStudentList(token, body).then((res) =>
        setPage(res.totalRecordCount)
      );
    }
  }, [
    dispatchGetStudentList,
    token,
    session_id,
    CurrentPage,
    idQuery,
    searchQuery,
  ]);

  const handleStudentEdit = (data) => {
    setstudent_uid(data?.student_code);
    setModal(!modal);
  };
  useEffect(() => {
    setSearchResults(studentdata?.payload);
  }, [studentdata]);
  function convertDate(data) {
    const parsedDate = moment(data, "YYYY-MM-DD");
    const formattedDate = parsedDate.format("DD-MM-YYYY");
    return formattedDate;
  }
  return (
    <>
      <span className="" type="button" onClick={toggle}>
        <AiOutlineFileSearch
          style={{ fontSize: "1.5rem" }}
          data-toggle="tooltip"
          data-placement="top"
          title="View Student List"
        />
      </span>
      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box" backdrop="static" keyboard={false}>
        {/* <ModalHeader toggle={toggle}>  </ModalHeader> */}
        <div className="form-group row p-4">
          <div className="col-lg-3 col-md-3 col-sm-12 my-1">
            <input
              name="code"
              type="text"
              className="form-control"
              placeholder="Student code"
              value={idQuery}
              onChange={handleIdInputChange}
              autoComplete="off"
            />
          </div>
          <div className="col-lg-3 col-md-3 col-sm-12 my-1 ">
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Student Name"
              value={searchQuery}
              onChange={handleInputChangeSearch}
              autoComplete="off"
            />
          </div>
          <div className="col-lg-2 col-md-2 col-sm-12  my-1">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
          <div className="col-lg-4 col-md-4 col-sm-12 d-flex justify-content-end order-sm-1">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={toggle}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
        <ModalBody>
          {noResultsMessage ? (
            <p>{noResultsMessage}</p>
          ) : (
            <div className="table-responsive">
              <Table size="sm" className="table-hover text-nowrap">
                <thead>
                  <tr className="text-center text-nowrap">
                    <th>Registration Date</th>
                    <th>Stud Code</th>
                    <th>Student Name</th>
                    <th>Gender</th>
                    <th>DOB</th>
                    <th>Category</th>
                    <th>Caste</th>
                    <th>Sub Caste</th>
                    <th>Student Status</th>
                    <th>Enrollment No</th>
                    <th>Academic Session</th>
                  </tr>
                </thead>
                <tbody>
                  {searchResults?.map((stu, index) => (
                    <tr
                      key={index}
                      className="text-center"
                      onClick={() => handleStudentEdit(stu)}
                      style={{ cursor: "pointer" }}
                    >
                      <td>{convertDate(stu.registration_date)}</td>
                      <td>{stu.student_code}</td>
                      <td className="text-left">{stu.std_name}</td>
                      <td>{stu.gender}</td>
                      <td>{convertDate(stu.date_of_birth)}</td>
                      <td>{stu.fk_category_name}</td>
                      <td>{stu.fk_caste_name}</td>
                      <td>{stu.sub_caste}</td>
                      <td>{stu.student_status}</td>
                      <td></td>
                      <td>{stu?.academic_year}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </div>
          )}
        </ModalBody>
        <ul className="pagination justify-content-center">
          {
            <Pagination
              activePage={CurrentPage}
              itemsCountPerPage={resultPerPage}
              totalItemsCount={Page}
              // totalItemsCount={
              //   filteredProducts && productCount ? productCount : 0
              // }
              hideFirstLastPages={false}
              onChange={(e) => setCurrentPage(e)}
              nextPageText="Next"
              prevPageText="Previous"
              lastPageText="Last"
              firstPageText="1st"
              itemClass="page-item"
              linkClass="page-link"
              activeClass="active"
              activeLinkClass="active"
              hideDisabled={false}
            />
          }
        </ul>
      </Modal>
    </>
  );
};

export default AdmittedStudentList;
