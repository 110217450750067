import {
  CREATE_BONAFIDE_FAILURE,
  CREATE_BONAFIDE_REQUEST,
  CREATE_BONAFIDE_SUCCESS,
  CREATE_CHARACTER_FAILURE,
  CREATE_CHARACTER_REQUEST,
  CREATE_CHARACTER_SUCCESS,
  GET_BONAFIDELIST_FAILURE,
  GET_BONAFIDELIST_REQUEST,
  GET_BONAFIDELIST_SUCCESS,
  GET_CHARACTERLIST_FAILURE,
  GET_CHARACTERLIST_REQUEST,
  GET_CHARACTERLIST_SUCCESS,
  GET_STUBY_ID_FAILURE,
  GET_STUBY_ID_REQUEST,
  GET_STUBY_ID_SUCCESS,
} from "../../Constants/school_user/Certificete";

const initialState = {
  bdata: null,
  cdata: null,
  create: null,
  loading: false,
  error: null,
  studata: null,
  bonalist: null,
  charlist: null,
};

export const certificateReducer = (state = initialState, action) => {
  switch (action.type) {
     case "RESET_STATE":
      return initialState;
    case CREATE_BONAFIDE_REQUEST:
    case CREATE_CHARACTER_REQUEST:
    case GET_BONAFIDELIST_REQUEST:
    case GET_CHARACTERLIST_REQUEST:
    case GET_STUBY_ID_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_STUBY_ID_SUCCESS:
      return {
        ...state,
        studata: action.payload,
        loading: false,
        error: null,
      };
    case GET_BONAFIDELIST_SUCCESS:
      return {
        ...state,
        bonalist: action.payload,
        loading: false,
        error: null,
      };
    case GET_CHARACTERLIST_SUCCESS:
      return {
        ...state,
        charlist: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_BONAFIDE_SUCCESS:
      return {
        ...state,
        bdata: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_CHARACTER_SUCCESS:
      return {
        ...state,
        cdata: action.payload,
        loading: false,
        error: null,
      };

    case CREATE_BONAFIDE_FAILURE:
    case CREATE_CHARACTER_FAILURE:
    case GET_BONAFIDELIST_FAILURE:
    case GET_CHARACTERLIST_FAILURE:
    case GET_STUBY_ID_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
