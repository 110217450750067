import {
  CREATE_FEES_STRUCTURE_FAILD,
  CREATE_FEES_STRUCTURE_REQUIST,
  CREATE_FEES_STRUCTURE_SUCCESS,
  DELETE_FEES_FAILD,
  DELETE_FEES_REQUIST,
  DELETE_FEES_STRUCTURE_FAILD,
  DELETE_FEES_STRUCTURE_REQUIST,
  DELETE_FEES_STRUCTURE_SUCCESS,
  DELETE_FEES_SUCCESS,
  FEES_FAILD,
  FEES_LIST_FAILD,
  FEES_LIST_REQUIST,
  FEES_LIST_SUCCESS,
  FEES_REQUIST,
  FEES_SUCCESS,
  GET_FEES_STRUCTURE_LIST_FAILD,
  GET_FEES_STRUCTURE_LIST_REQUIST,
  GET_FEES_STRUCTURE_LIST_SUCCESS,
  UPDATE_FEES_FAILD,
  UPDATE_FEES_REQUIST,
  UPDATE_FEES_SUCCESS,
} from "../../Constants/SchoolRegConstant";

const initialState = {
  fees: [],
  feeslist: null,
  deletefeeslist: null,
  updatefeelist: null,
  loading: false,
  feeStructure: null,
};

export const schoolFeeReducer = (state = initialState, action) => {
  switch (action.type) {
    case FEES_REQUIST:
    case UPDATE_FEES_REQUIST:
    case DELETE_FEES_REQUIST:
    case FEES_LIST_REQUIST:
    case GET_FEES_STRUCTURE_LIST_REQUIST:
    case CREATE_FEES_STRUCTURE_REQUIST:
    case DELETE_FEES_STRUCTURE_REQUIST:
      return {
        loading: true,
      };
    case FEES_SUCCESS:
      return {
        ...state,
        loading: false,
        fees: action.payload,
      };
    case FEES_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        feeslist: action.payload,
      };
    case DELETE_FEES_SUCCESS:
      return {
        ...state,
        loading: false,
        deletefeeslist: action.payload,
      };
    case UPDATE_FEES_SUCCESS:
      return {
        ...state,
        loading: false,
        updatefeelist: action.payload,
      };
    case GET_FEES_STRUCTURE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        feeStructure: action.payload,
      };
    case CREATE_FEES_STRUCTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        feeStructure: action.payload,
      };
    case DELETE_FEES_STRUCTURE_SUCCESS:
      return {
        ...state,
        loading: false,
        feeStructure: action.payload,
      };
    case FEES_FAILD:
    case FEES_LIST_FAILD:
    case DELETE_FEES_FAILD:
    case UPDATE_FEES_FAILD:
    case GET_FEES_STRUCTURE_LIST_FAILD:
    case CREATE_FEES_STRUCTURE_FAILD:
    case DELETE_FEES_STRUCTURE_FAILD:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
