import {
  CREATE_SUBJECT_FAILURE,
  CREATE_SUBJECT_REQUEST,
  CREATE_SUBJECT_SUCCESS,
  DELETE_SUBJECT_FAILURE,
  DELETE_SUBJECT_REQUEST,
  DELETE_SUBJECT_SUCCESS,
  GET_SUBJECT_FAILURE,
  GET_SUBJECT_REQUEST,
  GET_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_FAILURE,
  UPDATE_SUBJECT_REQUEST,
  UPDATE_SUBJECT_SUCCESS,
} from "../../Constants/school_user/SubjectMaster";

const initialState = {
  subjectdata: null,
  createsubject: null,
  updatesubject: null,
  deletesubject: null,
  loading: false,
  error: null,
};

export const subjectMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_SUBJECT_REQUEST:
    case GET_SUBJECT_REQUEST:
    case DELETE_SUBJECT_REQUEST:
    case UPDATE_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SUBJECT_SUCCESS:
      return {
        ...state,
        subjectdata: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_SUBJECT_SUCCESS:
      return {
        ...state,
        createsubject: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_SUBJECT_SUCCESS:
      return {
        ...state,
        updatesubject: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_SUBJECT_SUCCESS:
      return {
        ...state,
        deletesubject: action.payload,
        loading: false,
        error: null,
      };

    case CREATE_SUBJECT_FAILURE:
    case GET_SUBJECT_FAILURE:
    case UPDATE_SUBJECT_FAILURE:
    case DELETE_SUBJECT_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
