import React, { useEffect, useState } from "react";
import { StudentInfoAPI } from "../../../api/Administration/SMSNotification";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";

const SchoolStudentInfo = ({schoolId,organization_id}) => {
    const [studentInfoList, setStudentInfoList] = useState([]);
    const fetchMenuList = async () => {
        
        try {
          let data = {
            school_id: schoolId,
            organization_id: organization_id,
          };
          let response = await StudentInfoAPI.getSchoolStudentInfo(data);
          let responseData = response.data;
          setStudentInfoList(responseData.school_info);
        } catch (error) {
          console.error(error);
        } 
      };
      useEffect(() => {
        fetchMenuList();
      }, []);
  return ( 
    <>
      <div className="card">
        <div className="card-body"> <div className="table-responsive">
              {studentInfoList && studentInfoList?.length > 0 ? (
                <table className="table table-hover table-vcenter table-striped table-sm text-nowrap">
                  <thead>
                    <tr className="text-center">
                      <th>Sr No</th>
                      <th>Session</th>
                      <th>Total Students</th>
                      
                    </tr>
                  </thead>
                  <tbody>
                    {studentInfoList?.map((stu, index) => (
                      <tr key={index} className="text-center">
                        <td >{index + 1}</td>
                        <td>{stu.name}</td>
                        <td>{stu.student_count} <small>{stu.cureent_session ? "( Admitted )" : "( Promoted )"}</small></td>
                      
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <EmptyListMessage message={"Student Not Available !"} Div="div" />
              )}
            </div></div>
      </div>
    </>
  );
};

export default SchoolStudentInfo;
