import {
  GET_DASHBOARD2_DATA_FAILURE,
  GET_DASHBOARD2_DATA_REQUEST,
  GET_DASHBOARD2_DATA_SUCCESS,
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
} from "../../Constants/Dashboard/Dashboard";
import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const getDashboardData = (body) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DASHBOARD_DATA_REQUEST });
      const response = await axios.post(
        `${BASE_URL}Dashboard_Api/`,
        body,
        config
      );
      const data = response.data;

      dispatch({ type: GET_DASHBOARD_DATA_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: GET_DASHBOARD_DATA_FAILURE,
        payload: error,
      });
    }
  };
};

export const getDashboard2Data = (body) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_DASHBOARD2_DATA_REQUEST });
      const response = await axios.post(
        `${BASE_URL}Dashboard_Api2/`,
        body,
        config
      );
      const data = response.data;
    

      dispatch({ type: GET_DASHBOARD2_DATA_SUCCESS, payload: data });
      return data;
    } catch (error) {
      console.log(error);
      dispatch({
        type: GET_DASHBOARD2_DATA_FAILURE,
        payload: error,
      });
    }
  };
};
