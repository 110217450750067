import React, { useState, useEffect } from "react";
import { useNavigate, useLocation, NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useAlert } from "react-alert";
import { AuthAPI } from "../../api/authAPI";
import CustomPage from "./CustomPage";
import { MdModeEditOutline } from "react-icons/md";
import Logos from "../../Logo/Logos.svg";

const VerifyOTP = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const alert = useAlert();
  const [timer, setTimer] = useState(() => {
    const storedTimer = parseInt(localStorage.getItem("otpTimer")) || 0;
    return storedTimer > 0 ? storedTimer : 120; 
  });
  const [timerActive, setTimerActive] = useState(true);
  const [resendDisabled, setResendDisabled] = useState(false);

  let navigate = useNavigate();

  useEffect(() => {
    const timerInterval = setInterval(() => {
      if (timer > 0) {
        setTimer((prevTimer) => prevTimer - 1);
        localStorage.setItem("otpTimer", timer - 1);
      } else {
        setTimerActive(false);
        setResendDisabled(false);
        clearInterval(timerInterval);
      }
    }, 1000);

    // Clear interval when the component is unmounted or refreshed
    window.addEventListener("beforeunload", () => {
      clearInterval(timerInterval);
    });

    return () => clearInterval(timerInterval);
  }, [timer]);

  const handleResendOTP = () => {
    setResendDisabled(true);
    setTimer(120); 
    setTimerActive(true);
    localStorage.setItem("otpTimer", 120);

    AuthAPI.forgotPassword({ email: location.state.email })
      .then((res) => {
        if (res.data.status === 200) {
          navigate("/verify-otp", { state: { email: location.state.email } });
        } else {
          // Handle other cases if needed
        }
        if (res.data.status === 403 || res.data.status === 503) {
          alert.error(res.data.msg);
        }
      })
      .catch(function (error) {
        // Handle error
      });
  };

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      email: location.state.email,
      otp: "",
    },
    validationSchema: Yup.object({}),
    onSubmit: (values) => {
      verifyOTP(values);
    },
  });

  const verifyOTP = (value) => {
    AuthAPI.verifyOTP(value)
      .then((res) => {
        if (res.data.status === 200) {
          navigate("/confirm-password", { state: { email: value.email } });
        } else {
          // Handle other cases if needed
        }
        if (res.data.status === 403 || res.data.status === 503) {
          alert.error(res.data.msg);
        }
      })
      .catch(function (error) {
        // Handle error
      });
  };

  return (
   
    <CustomPage>
      <div className="mt-3">
      <img
                src={Logos}
                alt=""
                style={{ width: "20%", margin: "auto" , display: "block"  }}
              /> 
      <form
     style={{ width: "100%", marginTop: "10px" }}
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <h4
          className="text-center"
          style={{ color: "#19bb00", fontWeight: 700 }}
        >
          <span style={{ fontSize: 18, color: "#0E487A" }}>
            CHECK YOUR EMAIL FOR OTP
          </span>
          <br />
          <span style={{ fontSize: 12, color: "rgb(62 103 138)" }}>
            {location.state.email}
          </span>
          <span
            style={{
              fontSize: 18,
              color: "rgb(62 103 138)",
              marginLeft: 10,
              cursor: "pointer",
            }}
            onClick={() => navigate("/forgot-password")}
          >
            <MdModeEditOutline />
          </span>
        </h4>
        <div className="mt-3">
          <i
            style={{
              fontSize: 18,
              position: "absolute",
              zIndex: 1,
              marginTop: 16,
              marginLeft: 30,
            }}
            className="fa-solid fa-lock"
          />
          <input
            style={{
              padding: "12px 12px 12px 60px",
              borderRadius: 50,
            }}
            className="form-control"
            type="password"
            name="otp"
            value={validation.values.otp || ""}
            onChange={validation.handleChange}
            onBlur={validation.handleBlur}
            maxLength="6"
            placeholder="Enter 6-digit OTP"
            autoComplete="off"
            required
          />
        </div>
        <div className="text-center mx-auto mt-4">
  <button
    type="submit"
    style={{
      borderRadius: 50,
      backgroundColor: "#19bb00",
      color: "#fff",
      fontWeight: 600,
    }}
    className="btn btn-block btn-lg mb-3"
    title=""
  >
    Verify OTP
  </button>

  <p className="text-muted mb-2 text-left" style={{ display: "inline-block", marginRight: "10px" }}>
    {timerActive ? `${Math.floor(timer / 60)}:${timer % 60} seconds` : ""}
  </p>

  <NavLink
    to="/login"
    className="justify-content-center float-right"
    style={{ textDecoration: "none", display: "inline-block" }}
  >
    Back to{" "}
    <span style={{ fontWeight: 600 }} className="px-1">
      {" "}
      Sign In ?
    </span>
  </NavLink>

  <button
    type="button"
    onClick={handleResendOTP}
    disabled={resendDisabled || timerActive}
    style={{
      display: timerActive ? "none" : "inline-block",
      color: "rgb(14 72 122)",
      fontWeight: 600,
      border: "none",
      background: "none",
      cursor: "pointer",
      float: "left"
    }}
    title=""
  >
    Resend OTP
  </button>
</div>



         
      
      </form> </div>
    </CustomPage>
 
  );
};

export default VerifyOTP;
