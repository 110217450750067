import React from "react";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const SaveButton = ({ onClick, className, isEdit, ...props }) => {
  return (
    <button
      className={`btn btn-sm btn-primary  ${className}`}
      onClick={onClick}
      type="submit"
      {...props}
    > 
      <i className="fa fa-plus px-1"></i>
      {isEdit ? "Update" : "Save"}
    </button>
  );
};
const ClearButton = ({ onClick, className, ...props }) => {
  return (
    <button
      type="button"
      className={`btn btn-sm btn-outline-danger ${className}`}
      onClick={onClick}
      {...props}
    >
      <i className="fa fa-times px-1"></i>
      Clear
    </button>
  );
};
const ViewButton = ({ onClick, className, ...props }) => {
  return (
    <button
      type="button"
      className={`btn btn-outline-secondary btn-sm ${className}`}
      onClick={onClick}
      {...props}
    >
      <i className="fa fa-eye  px-1"></i>
      View
    </button>
  );
};
const ExportButton = ({ ...props }) => {
  return (
    <ReactHTMLTableToExcel
      {...props}
      className="download-table-xls-button btn-sm btn btn-success"
      color="success"
      table="table-to-xls"
      filename="Report"
      sheet="tablexls"
      buttonText="EXPORT"
    />
  );
};

{
  /* <button
type="button"
className="btn btn-outline-danger btn-sm mx-1"
onClick={() => onDelete(isExamWiseSubjectId)}
disabled={isExamWiseSubjectId ? false : true}
>
<i className="fa fa-trash px-1"></i>
Delete
</button> */
}
export { ClearButton, SaveButton, ViewButton, ExportButton };
