import React, { useContext, useEffect, useState } from "react"; 
import {
    Col,
    Row,
    Form,
    Input,
    Button,
    FormFeedback,
    Container,
    Table
  } from "reactstrap";
  import Select from "react-select";

const TeacherDashboard = () => {
    return (
        <h1>Dashboard</h1>
    )
}

export default TeacherDashboard;