import {
  GET_DESI_WISE_MANU_FAILD,
  GET_DESI_WISE_MANU_REQUIST,
  GET_DESI_WISE_MANU_SUCCESS,
  RESET_USER_MENU,
} from "../../Constants/SuperUser/UserAuthtication";

const initialState = {
  data: [],
  loading: false,
};

export const UserAuthicationReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_USER_MENU:
      return state;
    case GET_DESI_WISE_MANU_REQUIST:
      return {
        ...state,
        loading: true,
      };
    case GET_DESI_WISE_MANU_SUCCESS:
      return {
        ...state,
        data: action.payload,
      };
    case GET_DESI_WISE_MANU_FAILD:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
