import React from "react";
import { Heading } from "../../CommonComponent/heading";
import ChangePasswordForm from "../../Auth/ChangePasswordForm";
import { AuthAPI } from "../../../api/authAPI";

const SuperAdminProfile = () => {
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { name, email } = school_user;
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <Heading pageTitle={"Profile"} />
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <ul className="nav nav-tabs">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    data-toggle="tab"
                    href="#Profile"
                  >
                    Profile
                  </a>
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    data-toggle="tab"
                    href="#ChangePassword"
                  >
                    Change Password
                  </a>
                </li>
              </ul>
              <div className="tab-content mt-4">
                <div className="tab-pane active" id="Profile">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Name
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            defaultValue={name}
                            readOnly
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Email
                          <span className="text-danger"></span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Name"
                            name="name"
                            defaultValue={email}
                            readOnly
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="tab-pane" id="ChangePassword">
                  <div className="row">
                    <div className="col-lg-6 col-md-8 col-sm-12">
                      <ChangePasswordForm
                        apiFunction={AuthAPI.superAdminChangePassword}
                        navigateTo={`/admin/admin-dashboard`}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SuperAdminProfile;
