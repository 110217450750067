import React from "react";

const TimetableTable = (props) => {
  const { isTimetableData, isTeacherName, isClassName, selectedClassName } =
    props;
  return (
    <div className={`table-responsive ${isClassName}`}>
      <table className="table table-bordered table-hover" id="table-to-xls">
        <thead>
          {selectedClassName ? (
            <tr className="bg-gray text-white d-none">
              <th className="text-white " colSpan={3}>
                Class Name :- {selectedClassName}
              </th>
            </tr>
          ) : (
            <tr className="bg-gray text-white d-none">
              <th className="text-white " colSpan={3}>
                Teacher Name :- {isTeacherName}
              </th>
            </tr>
          )}
        </thead>
        <thead>
          <tr className="bg-gray text-white d-none">
            <th className="text-white text-center" colSpan={7}>
              TIME-TABLE
            </th>
          </tr>
        </thead>
        <thead>
          <tr className="bg-gray text-white text-nowrap">
            <th className="text-white">Period Time / Day(s)</th>
            <th className="text-center text-white">Monday</th>
            <th className="text-center text-white">Tuesday</th>
            <th className="text-center text-white">Wednesday</th>
            <th className="text-center text-white">Thursday</th>
            <th className="text-center text-white">Friday</th>
            <th className="text-center text-white">Saturday</th>
          </tr>
        </thead>
        <tbody>
          {isTimetableData?.map((time, rowIndex) => {
            let timeSlot = Object.keys(time)[0];
            const schedule = time[timeSlot];
            return (
              <tr key={rowIndex} className="" style={{ height: "5rem" }}>
                <td className="align-middle text-center" key={rowIndex}>
                  <span className="obj_bolder">{timeSlot}</span>
                </td>
                {schedule.map((t, dayIndex) => (
                  <td
                    className={`align-middle text-center ${
                      t.subject_name !== "NA" ? "table-warning" : ""
                    }`}
                    key={dayIndex}
                  >
                    {t.subject_name !== "NA" ? (
                      <p className="font-weight-bold mb-0">
                        <span className="text-uppercase d-block">
                          {t.subject_name}
                        </span>
                        <br className="d-none" />
                        <span className="d-block">
                          {t.teacher_name ? t.teacher_name : t.class_name}
                        </span>
                      </p>
                    ) : (
                      <p className="mb-0">--NA--</p>
                    )}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default TimetableTable;
