import { useEffect, useState } from "react";

const useLocalStorageData = () => {
  const [token, setToken] = useState(localStorage.getItem("token"));
  const [schoolUser, setSchoolUser] = useState(
    JSON.parse(localStorage.getItem("school_user"))
  );

  useEffect(() => {
    const storedToken = localStorage.getItem("token");
    const storedSchoolUser = JSON.parse(localStorage.getItem("school_user"));

    if (storedToken !== token) {
      setToken(storedToken);
    }

    if (JSON.stringify(storedSchoolUser) !== JSON.stringify(schoolUser)) {
      setSchoolUser(storedSchoolUser);
    }
  }, [token, schoolUser]);

  const { email, id, user_type, name, user_detail } = schoolUser || {};
  const { school_emp_id,designation_id } = user_detail || {};
  const school_id = schoolUser?.user_detail?.fk_school_id;

  return {
    token,
    schoolUser,
    email,
    id,
    user_type,
    name,
    user_detail,
    school_emp_id,
    school_id,
    designation_id
  };
};

export default useLocalStorageData;
