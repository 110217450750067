import React from "react";
import { useState } from "react";
import { Link, NavLink } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import { SessionContext } from "../../../../context/sessionContext";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Select from "react-select";
import {
  ShowOnlineTestQuiz,
  deleteTest,
  reSetReducerState,
} from "../../../../actions/MockTest/MockTest";
import {
  getChaptertList,
  getClassWiseSubjectList,
} from "../../../../actions/school_user/contentUploader";
import Swal from "sweetalert2";
import OnlineTestList from "./OnlineTestList";
import { Heading }from "../../../CommonComponent/heading";

const OnlineTest = () => {
  const token = localStorage.getItem("token");
  const { Session } = useContext(SessionContext);
  let session_id = Session || sessionStorage.getItem("SessionId");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id } = school_user?.user_detail;

  const dispatch = useDispatch();
  const { subject, chapter, contentData } = useSelector(
    (state) => state.contentUploader
  );
  const { allClass } = useSelector((state) => state.getClass);
  const { showOnlineTest } = useSelector((state) => state.ShowTestQuiz);
  const { deleteTestQuestion } = useSelector((state) => state.deleteQuestion);
  const [selectClass, setSelectClass] = useState(null);
  const [selectSubject, setSelectSubject] = useState(null);
  const [selectChapter, setSelectChapter] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [chapterList, setChapterList] = useState([]);

  let cl_subId = {
    academic_id: session_id,
    school_id: school_id,
    class_id: selectClass,
    employee_id: school_emp_id,
  };

  useEffect(() => {
    if (token && selectClass) {
      dispatch(getClassWiseSubjectList(token, cl_subId));
    }
  }, [dispatch, token, selectClass]);

  useEffect(() => {
    let List = subject?.payload?.map((c) => {
      return {
        label: `${c.subject_name}`,
        value: c.id,
      };
    });
    setSubjectList(List);
  }, [subject]);

  useEffect(() => {
    let List = chapter?.payload?.map((c) => {
      return {
        label: `${c.name}`,
        value: c.id,
      };
    });
    setChapterList(List);
  }, [chapter]);

  let cdata = {
    school_id: school_id,
    class_id: selectClass,
    subject_id: selectSubject,
  };
  useEffect(() => {
    if (selectSubject) {
      dispatch(getChaptertList(cdata));
    }
  }, [selectSubject]);

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

  let handleChangeClass = (select) => {
    setSelectClass(select.value);
    setSelectSubject(null);
    // setSelectChapter(null);
  };

  useEffect(() => {
    dispatch(reSetReducerState());
  }, []);

  const GetAllTest = async () => {
    const body = {
      academic_id: session_id,
      school_id: school_id,
      employee_id: school_emp_id,
      class_id: selectClass,
      subject_id: selectSubject,
      // chapter_id: selectChapter,
      // test_type: "Online Test",
    };
    try {
      dispatch(ShowOnlineTestQuiz(body));
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    GetAllTest();
    console.log("1");
  }, [selectClass, selectSubject, session_id, deleteTestQuestion]);

  const handleResetFilter = () => {
    setSelectClass(0);
    setSelectSubject(0);
    // setSelectChapter(0);
    GetAllTest();
  };

  const onTestDelete = (id) => {
    let body = {
      test_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Mock Test ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteTest(body)).then((res) => {
          Swal.fire("Deleted!", "Deleted success", "success");
          GetAllTest();
        });
      }
    });
  };



  return (
    <div className="section-body">
      <Container fluid={true}>
        <Heading pageTitle={"Online Test"}>
          <Link
            className="btn btn-primary me-1"
            to="/school/create-online-test"
          >
            <i className="fa fa-plus-square px-1"></i> Create Test
          </Link>
          <button className="btn btn-danger ml-1" onClick={handleResetFilter}>
            Reset Filter
          </button>
        </Heading>

        <Card className="mt-4">
          <CardBody>
            <Row className="">
              <Col lg={4} md={6} sm={12}>
                <FormGroup row>
                  <Label
                    className="form-check-label"
                    htmlFor="inlineFormCheck"
                    sm={4}
                  >
                    Class
                  </Label>

                  <Col sm={8}>
                    <Select
                      name="class_id"
                      placeholder="Select Class"
                      options={classList}
                      value={
                        selectClass
                          ? classList?.find((c) => c.value === selectClass)
                          : null
                      }
                      onChange={handleChangeClass}
                      isSearchable={true}
                    />
                  </Col>
                </FormGroup>
              </Col>
              <Col lg={4} md={6} sm={12}>
                <FormGroup row>
                  <Label
                    className="form-check-label"
                    htmlFor="inlineFormCheck"
                    sm={4}
                  >
                    Subject
                  </Label>

                  <Col sm={8}>
                    <Select
                      placeholder="Select Subject"
                      options={subjectList}
                      value={
                        selectSubject
                          ? subjectList.find(
                              (option) => option.value === selectSubject
                            )
                          : null
                      }
                      onChange={(select) => setSelectSubject(select.value)}
                      isSearchable={true}
                    />
                  </Col>
                </FormGroup>
              </Col>
            </Row>
          </CardBody>

          <OnlineTestList
            testType="Ongoing"
            tests={showOnlineTest?.ongoing_quiz}
            onTestDelete={onTestDelete}
          />
          <OnlineTestList
            testType="Upcomming"
            tests={showOnlineTest?.upcomming_quiz}
            onTestDelete={onTestDelete}
          />
          <OnlineTestList
            testType="Completed"
            tests={showOnlineTest?.completed_quiz}
            onTestDelete={onTestDelete}
          />
        </Card>
      </Container>{" "}
    </div>
  );
};

export default OnlineTest;
