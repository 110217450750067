import React from "react";

const Footer = ({ nonAuth }) => {
  return (
    <>
      <div className="section-body">
        <footer className="footer" style={{ color: nonAuth ? "white" : "" }}>
          <hr />
          {/* <div className="container-fluid ">
            <div className="row">
              <div className="col-lg-4   col-sm-12">
                <p className="">Copyright © 2023 - 2024</p>
              </div>

              <div className="col-lg-4  col-sm-12 text-md-right ">
                <span className="text-capitalize">
                  {" "}
                  For Any Technical Issue Contact us on
                </span>
               
              </div>
              <div className="col-lg-4  col-sm-12  ">
              (
                <span>
                  <i className="fa fa-phone mx-1"  aria-hidden="true"></i>
                  +919346730371{" "}
                </span>
                <span>
                  <i className="fa fa-envelope mx-1" aria-hidden="true"></i>
                  support@vidyamate.in
                </span>{" "}
                )
              </div>

            </div>
          </div> */}

          <div className="container-fluid">
            <div className="row forRight ">
              <div className="  justify-content-sm-center">
                <p className="">Copyright © 2023 - 2024</p>
              </div>
              <div className="">
                <span className="text-capitalize">
                  For Any Technical Issue Contact us on
                </span>{" "}
                (<i className="fa fa-phone mx-1" aria-hidden="true"></i>
                +919346730371{" "}
                <i className="fa fa-envelope mx-1" aria-hidden="true"></i>
                support@vidyamate.in)
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
