import React, { useState, useEffect } from "react";
import Select from "react-select";
import StudentsListAPI from "../../api/Admission/StudentListAPI";
import { useNavigate } from "react-router-dom";
import useLocalStorageData from "../schooladmin/Comman/LocalStorageData";
import { FaSearch } from 'react-icons/fa';
import { useContext } from "react";
import { SessionContext } from "./../../context/sessionContext.js";


const StudentList = ({SessionID}) => {



  const { school_id, token, schoolUser } = useLocalStorageData();
  const [studentsData, setstudentsData] = useState([]);
  const navigate = useNavigate();

  const handleRowClick = (stuId) => {
    navigate("/school/student-profile", { state: { studentId: stuId } });
    setselectedStudent(null);
  };
  const [width ,setWidth] = useState(0)
  const [menuWidth ,setMenuWidth] = useState(0)

  useEffect(() => {
    if(window.innerWidth < 500){
    setWidth("12rem")
    setMenuWidth("18rem")
  }else
  {
    setWidth("18rem")
    setMenuWidth("18rem")
  }

  }, [])

  const [selectedStudent, setselectedStudent] = useState(null);

  const studentOptions = studentsData.map((student) => ({
    value: student.id,
    label: `[ ${student.student_code} ] ${student.std_name}`,
  }));

  
  useEffect(() => {
    const fetchData = async () => {
      let body = {}

      if(schoolUser.user_detail?.user_type_dashboard === "Teacher"){
        body = {
          school_id: school_id,
          staff_id:schoolUser?.user_detail?.staff_id,
          academic_id:SessionID
        }

      }else{
       body = {
        school_id: school_id,
      };
    }
      try {
        const response = await StudentsListAPI.getStudentsList(body);
        let responseData = response.data;
        if (responseData.status === 200) {
          setstudentsData(responseData.Student);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData(); 
  }, [SessionID]);

  const customStyles = {
    control: (provided) => ({
      ...provided,
       minWidth: width,
    }),
    indicatorSeparator: (provided) => ({
      ...provided,
      display: "none",
    }),
    option: (provided) => ({
      ...provided,
      whiteSpace: 'nowrap', 
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      display: 'none', 
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: "#fff",
      zIndex: 999,
      minWidth: menuWidth ,
      maxWidth: menuWidth,
    })
  };

  const CustomDropdownIndicator = () => {
    return <FaSearch size={16} style={{ margin: 'auto', marginRight: '10px', color: '#777', cursor: "pointer" }} />;
  };

  return (
    <>
      <Select
        name="student"
        placeholder="Search Student..."
        className="Select-1"
        options={studentOptions}
        value={
          selectedStudent
            ? studentOptions?.find((option) => option.value === selectedStudent)
            : null
        }
        onChange={(selectedOption) => {
          setselectedStudent(selectedOption?.value);
          handleRowClick(selectedOption?.value);
        }}
        styles={customStyles}
        components={{ DropdownIndicator: CustomDropdownIndicator }}
      />
    </>
  );
};

export default StudentList;
