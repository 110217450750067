import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};
const createAxiosConfigForPagination = (url, method, data, cpage) => {
  return {
    method,
    url: `${BASE_URL}${url}/?page=${cpage}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

const HostelAPI = {
  registerHostel: async (data) => {
    const axiosConfig = createAxiosConfig(
      "addUpdate_HostelRegistration",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
  getHostelList: async (data) => {
    const axiosConfig = createAxiosConfig("get_HostelList", "POST", data);
    return await axios(axiosConfig);
  },
  deleteHostel: async (data) => {
    const axiosConfig = createAxiosConfig("delete_Hostel", "POST", data);
    return await axios(axiosConfig);
  },

  addHostelRoomDetails: async (data) => {
    const axiosConfig = createAxiosConfig(
      "addUpdate_HostelRoomDetails",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
  getHostelRoomDetails: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_HostelRoomDetails",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
  getAvailabletyRoom: async (data) => {
    const axiosConfig = createAxiosConfig("get_AvailabletyRoom", "POST", data);
    return await axios(axiosConfig);
  },
  getRoomAllotmentDetails: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_RoomAllotmentDetails",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
  hostelAllotmenttoStudent: async (data) => {
    const axiosConfig = createAxiosConfig(
      "Hostel_AllotmenttoStudent",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
  getHostelReport: async (data) => {
    const axiosConfig = createAxiosConfig("get_HostelReport", "POST", data);
    return await axios(axiosConfig);
  },
  deAllocateHostelRoom: async (data) => {
    const axiosConfig = createAxiosConfig(
      "deAllocate_HostelRoom",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
  getHostelWiseStudentList: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_HostelWiseStudentList",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
  payHostelpayment: async (data) => {
    const axiosConfig = createAxiosConfig("hostel_payment", "POST", data);
    return await axios(axiosConfig);
  },
  getHostelPaymentfeeCollection: async (data, cpage) => {
    const axiosConfig = createAxiosConfigForPagination(
      "hostelPayment_feeCollection",
      "POST",
      data,
      cpage
    );
    return await axios(axiosConfig);
  },
  cancelHostelPayment: async (data) => {
    const axiosConfig = createAxiosConfig("cancel_HostelPayment", "POST", data);
    return await axios(axiosConfig);
  },
  getHostelPayment: async (data, cpage) => {
    const axiosConfig = createAxiosConfigForPagination(
      "search_HostelPayment",
      "POST",
      data,
      cpage
    );
    return await axios(axiosConfig);
  },
};

export default HostelAPI;
