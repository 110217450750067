import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const useEnsureId = () => {
  const location = useLocation();
  const id = location?.state?.id;
  const navigate = useNavigate();

  useEffect(() => {
    if (!id) {
    //   window.location.replace(location?.pathname);
      navigate(location?.pathname);
    }
  }, [id]);

  return id;
};

export default useEnsureId;
