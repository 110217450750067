import React, { useEffect, useState } from "react";
import { Card, CardBody, Col, Label, Row } from "reactstrap";
import Select from "react-select";
import { TimeTableAPI } from "../../../../api/TimeTable/timeTableAPI";
import Swal from "sweetalert2";
import useSessionRedirect from "../../Comman/SessionChange";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import TimetableTable from "./TimetableTable";
import {
  ClearButton,
  ViewButton,
} from "../../../CommonComponent/ReusableButton";
import { Heading } from "../../../CommonComponent/heading";

const TeacherTimeTable = () => {
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  const [selectTeacher, setSelectTeacher] = useState(null);
  const [isTeacherName, setIsTeacherName] = useState([]);
  const [isTeachertList, setIsTeachertList] = useState([]);
  const [isButtonView, setIsButtonView] = useState(true);
  const [isTimetableData, setIsTimetableData] = useState([]);

  const teacherOption = isTeachertList?.map((item) => ({
    value: item.fk_staff,
    label: `${item.fk_staff__first_name} ${item.fk_staff__last_name}`,
  }));

  useEffect(() => {
    let data = {
      academic_id: session_id,
      school_id: school_id,
    };
    let fetchData = async () => {
      try {
        let response = await TimeTableAPI.getTeacherList(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          setIsTeachertList(responseData.teacher_list);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, [session_id]);

  let handleClear = () => {
    setSelectTeacher(null);
    setIsButtonView(true);
    setIsTimetableData([]);
  };
  let handelChangeTeacher = (select) => {
    setSelectTeacher(select.value);
    setIsTeacherName(select.label);
    setIsButtonView(false);
    setIsTimetableData([]);
  };

  const handleFatchTimetableData = async () => {
    try {
      let data = {
        academic_id: session_id,
        school_id: school_id,
        teacher_id: selectTeacher,
      };
      const response = await TimeTableAPI.getTimetableTeacherWise(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        let timetableData = responseData.teacher_timetable;
        setIsTimetableData(timetableData);
      } else if (responseData.status === 403) {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      } else if (responseData.status === 500) {
        Swal.fire({
          text: responseData.msg,
          icon: "warning",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <Heading pageTitle={"Teacher wise Time Table"}>
            {isTimetableData?.length > 0 && (
              <ReactHTMLTableToExcel
                id="test-table-xls-button"
                className="download-table-xls-button  btn btn-success"
                color="success"
                table="table-to-xls"
                filename="Timetable"
                sheet="tablexls"
                buttonText="EXPORT"
              />
            )}
            <ViewButton
              className="mx-1"
              onClick={handleFatchTimetableData}
              disabled={isButtonView}
            />
            <ClearButton className="mx-1" onClick={() => handleClear()} />
          </Heading>
        </div>
      </div>
      <Card className="mt-4">
        <CardBody className="">
          <Row>
            <Col lg={4} md={6}>
              <Label className="form-check-label " htmlFor="inlineFormCheck">
                Teacher
              </Label>
              <Select
                name="teacher"
                placeholder="Select Teacher"
                options={teacherOption}
                value={
                  selectTeacher
                    ? teacherOption?.find((c) => c.value === selectTeacher)
                    : null
                }
                onChange={handelChangeTeacher}
                isSearchable={true}
              />
            </Col>
          </Row>
        </CardBody>
        <TimetableTable
          isTimetableData={isTimetableData}
          isClassName={"d-block"}
          isTeacherName={isTeacherName}
        />
      </Card>
    </>
  );
};

export default TeacherTimeTable;
