import {
  ADD_PROSPECTUS_SUCCESS,
  DELETE_PROSPECTUS_SUCCESS,
  EDIT_PROSPECTUS_SUCCESS,
  GET_PROSPECTUS_SUCCESS,
  PROSPECTUS_FAIELD,
  PROSPECTUS_REQUIST,
} from "../../Constants/Admission/Prospectus";

const initialState = {
  payload: null,
  Prospectus: null,
  loading: false,
  error: null,
};

export const saveProspectus = (state = initialState, action) => {
  switch (action.type) {
    case PROSPECTUS_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_PROSPECTUS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        error: null,
      };
    case PROSPECTUS_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const updateProspectus = (state = initialState, action) => {
  switch (action.type) {
    case PROSPECTUS_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_PROSPECTUS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        error: null,
      };
    case PROSPECTUS_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteProspectus = (state = initialState, action) => {
  switch (action.type) {
    case PROSPECTUS_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_PROSPECTUS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        error: null,
      };
    case PROSPECTUS_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const viewProspectus = (state = initialState, action) => {
  switch (action.type) {
    case PROSPECTUS_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_PROSPECTUS_SUCCESS:
      return {
        ...state,
        Prospectus: action.payload,
        loading: false,
        error: null,
      };
    case PROSPECTUS_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
