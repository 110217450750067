
export const setActiveTab = (tabId) => {
    return {
      type: 'SET_ACTIVE_TAB',
      payload: tabId,
    };
  };
  
export const setActiveTabSR = (tabId) => {
    return {
      type: 'SET_ACTIVE_TAB_SR',
      payload: tabId,
    };
  };
  