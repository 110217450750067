import React from "react";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate, useParams } from "react-router-dom";
import {
  Table,
  Modal,
  ModalBody,
  ModalFooter,
  Container,
  Row,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { ShowOnlineTestResult } from "../../../../actions/MockTest/OnlineTest";
import { useEffect } from "react";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
import Loader from "../../../Loader/Loader";
const OnlineTestResult = () => {
  const session_id = useSessionRedirect();
  const { school_id, token } = useLocalStorageData();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  console.log(typeof id);
  const { result, loading } = useSelector((state) => state.onlineTest);
  console.log(result?.Result, "res");

  useEffect(() => {
    const fetchData = async () => {
      let body = {
        quiz_id: Number(id),
        school_id: school_id,
        academic_id: session_id,
      };
      try {
        const response = await dispatch(ShowOnlineTestResult(body));
      } catch (error) {
        console.error(error);
      }
    };

    fetchData();
  }, [dispatch, token]);
  if (loading) return <Loader />;

  return (
    <Container fluid={true}>
      <Row>
        <Col lg="12">
          <Card>
            <CardBody className="border-bottom">
              <div className="d-flex align-items-center">
                <h1 className="mb-0 card-title flex-grow-1">
                  Online Test Result{" "}
                </h1>
                <div className="flex-shrink-0">
                  <Link
                    className="btn btn-outline-info"
                    to={"/school/online-test"}
                  >
                    <i className="fa fa-backward pr-1" aria-hidden="true"></i>{" "}
                    Go Back
                  </Link>
                </div>
              </div>
            </CardBody>
          </Card>
        </Col>
        <Col lg="12">
          <Card>
            <CardBody className="border-bottom">
              {result?.Result && result?.Result.length > 0 ? (
                <Table size="sm" className="table-hover">
                  <thead>
                    <tr className="text-center">
                      <th>Sr No</th>
                      <th>Stud Code</th>
                      <th>Student Name</th>
                      <th>Class</th>
                      <th>Subject</th>
                      <th>Question</th>
                      <th>Mark</th>
                      <th>Percent</th>
                    </tr>
                  </thead>
                  <tbody>
                    {result?.Result?.map((stu, index) => (
                      <tr
                        key={index}
                        className="text-center"
                        style={{ cursor: "pointer" }}
                      >
                        <td>{index + 1}</td>
                        <td>{stu.student_code}</td>
                        <td className="text-left">{stu.student_name}</td>
                        <td>{stu.class}</td>
                        <td>{stu.subject}</td>
                        <td>{`${stu.attempt_question} / ${stu.total_question}`}</td>
                        <td>{`${stu.obtain_marks} / ${stu.total_marks}`}</td>
                        <td>{stu.obtain_percent} %</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <EmptyListMessage
                  message={"Result Not Available !"}
                  Div="div"
                />
              )}
            </CardBody>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default OnlineTestResult;
