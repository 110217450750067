import axios from "axios";
import { BASE_URL} from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfigForPagination = (url, method, data, cpage) => {
  return {
    method,
    url: `${BASE_URL}${url}/?page=${cpage}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};
export const BusReportAPI = {
      Dailybusfeereportapi: async (data, cpage) => {
        const axiosConfig = createAxiosConfigForPagination(
          "Daily_bus_fee_report_api",
          "POST",
          data,
          cpage
        );
        return await axios(axiosConfig);
      },
      getRouteConfigurationapi: async (data, cpage) => {
        const axiosConfig = createAxiosConfigForPagination(
          "get_RouteConfiguration_api",
          "POST",
          data,
          cpage
        );
        return await axios(axiosConfig);
      },
      studentbusroutereport: async (data, cpage) => {
        const axiosConfig = createAxiosConfigForPagination(
          "student_bus_route_report_api",
          "POST",
          data,
          cpage
        );
        return await axios(axiosConfig);
      },
      BusFeeBalanceFeeReport: async (data, cpage) => {
        const axiosConfig = createAxiosConfigForPagination(
          "Bus_fee_balance_feereport_api",
          "POST",
          data,
          cpage
        );
        return await axios(axiosConfig);
      },
      BusDetailReport: async (data, cpage) => {
        const axiosConfig = createAxiosConfigForPagination(
          "Bus_detail_report_api",
          "POST",
          data,
          cpage
        );
        return await axios(axiosConfig);
      },
}