import React, { useEffect, useState } from "react";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useSessionRedirect from "../../Comman/SessionChange";
import useLoading from "../../../CommonComponent/useLoading";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import Loader from "../../../Loader/Loader";
import Select from "react-select";
import HostelTable from "./HostelTable";
const BulkRoomDeallocation = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const session_id = useSessionRedirect();
  const [hostelData, setHostelData] = useState([]);
  const [hostelId, setHostelId] = useState("All");
  const [hostelReportData, setHostelReportData] = useState([]);
  const [roomIdList, setRoomIdList] = useState([]);

  let hostelList;
  hostelList = [  
    { label: "All", value: "All" },
    ...(hostelData?.map((hostel) => ({
      value: hostel.id,
      label: hostel.hostelName,
    })) ?? []),
  ];

  const fetchData = async () => {
    startLoading();
    try {
      let data = {
        school_id: school_id,
      };
      const response = await HostelAPI.getHostelList(data);
      let responseData = response.data;
      setHostelData(responseData.hostelList);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  const fetchHostelReports = async () => {
    startLoading();
    try {
      let data = {
        hostel_id: hostelId,
        bedTypeId: "All",
        studentType: "All",
        school_id: school_id,
      };
      const response = await HostelAPI.getHostelReport(data);
      let responseData = response.data;
      setHostelReportData(responseData.hostel_report);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const handleClear = () => {
    setRoomIdList([])
    setHostelReportData([])
    setHostelId("All")
  };
  const roomDeallocation = async () => {
    startLoading();
    try {
      let data = {
        roomIdList: JSON.stringify(roomIdList),
      };
      const response = await HostelAPI.deAllocateHostelRoom(data);
      let responseData = response.data;
      if (responseData.status === 200) {
        Swal.fire({
          text: responseData.msg,
          icon: "success",
          allowOutsideClick: false,
        });
        handleClear();
        fetchHostelReports()
      } else if (responseData.status === 403) {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };

  if (isLoading) return <Loader />;
  return (
    <div className="section-body">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="header-action">
            <h1 className="page-title">Bulk Room Deallocation</h1>
          </div>
          <div className=" mt-2">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm mx-1"
              onClick={() => fetchHostelReports()}
            >
              <i className="fa fa-eye  px-1"></i>
              View
            </button>
            <button
              className="btn btn-primary btn-sm mx-1"
              type="submit"
              onClick={roomDeallocation}
            >
              <i className="fa fa-plus px-1"></i>
              Save
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-danger mx-1"
              onClick={() => handleClear()}
            >
              <i className="fa fa-times px-1"></i>
              Clear
            </button>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="card">
            <div className="card-body row">
              <div className="col-lg-6 col-md-8">
                <div className="form-group  row">
                  <label className="col-md-4 col-form-label">
                    Select Hostel <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-8">
                    <Select
                      name="hostelTypeId"
                      placeholder="Select Hostel"
                      options={hostelList}
                      value={
                        hostelId
                          ? hostelList?.find(
                              (option) => option.value === hostelId
                            )
                          : null
                      }
                      onChange={(selectedOptions) => {
                        setHostelId(selectedOptions.value);
                      }}
                      isSearchable={true}
                    />
                    {/* {idValidationError && !hostelId ? (
                      <ErrorDisplay errorMsg={"Hostel is required !"} />
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <HostelTable
                hostelData={hostelReportData}
                setRoomIdList={setRoomIdList}
                roomIdList={roomIdList}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BulkRoomDeallocation;
