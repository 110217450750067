export const CREATE_BONAFIDE_REQUEST = 'CREATE_BONAFIDE_REQUEST';
export const CREATE_BONAFIDE_SUCCESS = 'CREATE_BONAFIDE_SUCCESS';
export const CREATE_BONAFIDE_FAILURE = 'CREATE_BONAFIDE_FAILURE';


export const GET_BONAFIDELIST_REQUEST = 'GET_BONAFIDELIST_REQUEST';
export const GET_BONAFIDELIST_SUCCESS = 'GET_BONAFIDELIST_SUCCESS';
export const GET_BONAFIDELIST_FAILURE = 'GET_BONAFIDELIST_FAILURE';


export const GET_CHARACTERLIST_REQUEST = 'GET_CHARACTERLIST_REQUEST';
export const GET_CHARACTERLIST_SUCCESS = 'GET_CHARACTERLIST_SUCCESS';
export const GET_CHARACTERLIST_FAILURE = 'GET_CHARACTERLIST_FAILURE';


export const CREATE_CHARACTER_REQUEST = 'CREATE_CHARACTER_REQUEST';
export const CREATE_CHARACTER_SUCCESS = 'CREATE_CHARACTER_SUCCESS';
export const CREATE_CHARACTER_FAILURE = 'CREATE_CHARACTER_FAILURE';



export const GET_STUBY_ID_REQUEST = 'GET_STUBY_ID_REQUEST';
export const GET_STUBY_ID_SUCCESS = 'GET_STUBY_ID_SUCCESS';
export const GET_STUBY_ID_FAILURE = 'GET_STUBY_ID_FAILURE';