import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};


export const DualLoginApi = {
   DualLogin: async (data) => {
      var axiosConfig = createAxiosConfig("check_user_token", "POST", data);
      return await axios(axiosConfig);
    },
}