import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

export const SMSNotificationAPI = {
  getSMSModuleList: async (data) => {
    var axiosConfig = createAxiosConfig("get_SMSModuleList", "POST", data);
    return await axios(axiosConfig);
  },
  addUpdateSMSSetting: async (data) => {
    var axiosConfig = createAxiosConfig("addUpdateSMS_Settings", "POST", data);
    return await axios(axiosConfig);
  },
};


export const StudentInfoAPI={
  getSchoolStudentInfo:async(data)=>{
    var axiosConfig = createAxiosConfig("school_studentInfo","POST",data)
    return await axios(axiosConfig)
  }
}