import React, { useState } from "react";
import {
  Carousel,
  CarouselItem,
  CarouselControl,
  CarouselIndicators,
  CarouselCaption,
  Card,
  CardBody,
} from "reactstrap";
import { FaArrowLeft, FaArrowRight } from "react-icons/fa";
import { convertDateIntoString } from "../../CommonComponent/DateFormatUtil";

const HolidayCarousel = (props) => {
  const { holiday } = props;
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);
  const holidays = holiday || [
    {
      description: "Republic Day",
      holiday_type: "Event",
      start_date: "2024-01-26",
      end_date: "2024-01-26",
      for_all: true,
    },
  ];

  const next = () => {
    if (animating) return;
    const nextIndex = activeIndex === holidays.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  };

  const previous = () => {
    if (animating) return;
    const nextIndex = activeIndex === 0 ? holidays.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };

  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  const slides =
    holidays &&
    holidays?.map((holiday, index) => (
      <CarouselItem key={index}>
        <Card className="bg-b-holiday" style={{ color: "#fff" }}>
          <CardBody className="text-center">
            <b style={{fontSize:"0.8rem"}}>
             { `${convertDateIntoString(holiday.start_date)} - 
              ${convertDateIntoString(holiday.end_date)}`}
            </b>
            <br/>
            <b>
              <span style={{ fontWeight: "bold", fontSize: "1.3rem" }}>
                {holiday.description?.length > 10
                  ? `${holiday.description.slice(0, 15)}...`
                  : holiday.description}
              </span>
            </b>
          </CardBody>
        </Card>
      </CarouselItem>
    ));

  return (
    <Carousel activeIndex={activeIndex} next={next} previous={previous}>
      {slides}
      <CarouselControl
        direction="prev"
        onClickHandler={previous}
        icon={<FaArrowLeft size={24} color="black" />}
        className="custom-carousel-control"
      />
      <CarouselControl
        direction="next"
        onClickHandler={next}
        icon={<FaArrowRight size={24} color="black" />}
      />
    </Carousel>
  );
};

export default HolidayCarousel;
