import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data,
  };
};

// getHostelList: async (data) => {
//     const axiosConfig = createAxiosConfig("get_HostelList", "POST", data);
//     return await axios(axiosConfig);
//   },

const NotificationApi = {
getNotificationList: async (data) => {
  const axiosConfig = createAxiosConfig("get_AddedNotification", "POST", data);
  return await axios(axiosConfig);
},  
get_StaffList: async (data) => {
  const axiosConfig = createAxiosConfig("get_StaffList", "POST", data);
  return await axios(axiosConfig);
},
send_StaffNotification: async (data) => {
  const axiosConfig = createAxiosConfig("send_StaffNotification", "POST", data);
  return await axios(axiosConfig);
},
delete_StaffNotification : async (data) => {
  const axiosConfig = createAxiosConfig("delete_StaffNotification", "POST", data);
  return await axios(axiosConfig);
},
get_StaffNotification : async (data) => {
  const axiosConfig = createAxiosConfig("get_StaffNotification", "POST", data);
  return await axios(axiosConfig);
},
seenStaffNotificationMessage : async (data) => {
  const axiosConfig = createAxiosConfig("seen_staffNotification_message", "POST", data);
  return await axios(axiosConfig);
}
}
export default NotificationApi
