import {
  GET_DASHBOARD_DATA_FAILURE,
  GET_DASHBOARD_DATA_REQUEST,
  GET_DASHBOARD_DATA_SUCCESS,
} from "../../Constants/Dashboard/Dashboard";

const initialState = {
  dashboard: [],
  loading: false,
  error: null,  
};

export const DashboardReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_DASHBOARD_DATA_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_DASHBOARD_DATA_SUCCESS:
      return {
        ...state,
        dashboard: action.payload,
        loading: false,
      };
    case GET_DASHBOARD_DATA_FAILURE:
      return {
        ...state,
        error: action.payload,
        loading: false,
      };

    default:
      return state;
  }
};
