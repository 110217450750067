import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";
import {
  ADD_CLASS_TEST_FAIELD,
  ADD_CLASS_TEST_REQUIST,
  ADD_CLASS_TEST_SUCCESS,
  ADD_QUESTION_FAIELD,
  ADD_QUESTION_REQUIST,
  ADD_QUESTION_SUCCESS,
  CREATE_MOCK_TEST_FAIELD,
  CREATE_MOCK_TEST_REQUIST,
  CREATE_MOCK_TEST_SUCCESS,
  DELETE_QUESTION_FAIELD,
  DELETE_QUESTION_REQUIST,
  DELETE_QUESTION_SUCCESS,
  DELETE_TEST_FAIELD,
  DELETE_TEST_REQUIST,
  DELETE_TEST_SUCCESS,
  EDIT_CLASS_TEST_FAIELD,
  EDIT_CLASS_TEST_REQUIST,
  EDIT_CLASS_TEST_SUCCESS,
  GET_QUESTION_FAIELD,
  GET_QUESTION_REQUIST,
  GET_QUESTION_SUCCESS,
  SHOW_TEST_QUIZ_FAIELD,
  SHOW_TEST_QUIZ_REQUIST,
  SHOW_TEST_QUIZ_SUCCESS,
  TEST_STATUS_FAIELD,
  TEST_STATUS_REQUIST,
  TEST_STATUS_SUCCESS,
  TEST_STATUS_RESET_STATE,
  SHOW_ONLINETEST_REQUIST,
  SHOW_ONLINETEST_SUCCESS,
  SHOW_ONLINETEST_FAIELD,
} from "../../Constants/MockTest/MockTest";
const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const setID = (id) => ({
  type: "SET_ID",
  payload: id,
});

export const reSetReducerState = () => ({
  type: TEST_STATUS_RESET_STATE,
});

export const createMockTest = (body, navigate, handleLoader) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_MOCK_TEST_REQUIST });
      const response = await axios.post(
        `${BASE_URL}add_class_test_api/`,
        body,
        config
      );
      const data = response.data;
      if (response.data.status === 200) {
        handleLoader();
        dispatch({ type: CREATE_MOCK_TEST_SUCCESS, payload: response.data });
      } else {
        handleLoader();
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(response.data);
          }
        });
      }
      return response.data;
    } catch (error) {
      handleLoader();
      dispatch({ type: CREATE_MOCK_TEST_FAIELD, payload: error });
    }
  };
};

export const addQuestion = (
  body,
  handleClear,
  increaseQuestionCount,
  handleLoader
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_QUESTION_REQUIST });
      const response = await axios.post(
        `${BASE_URL}add_test_question/`,
        body,
        config
      );
      const data = response.data;
      if (data?.status === 200) {
        Swal.fire({
          text: data?.msg,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        }).then((result) => {
          dispatch({ type: ADD_QUESTION_SUCCESS, payload: data });
          if (result.isConfirmed) {
            increaseQuestionCount();
            handleClear();
            handleLoader();
          }
        });
      } else {
        handleLoader();
        Swal.fire({
          text: data?.msg,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(data);
          }
        });
      }
    } catch (error) {
      handleLoader();
      dispatch({ type: ADD_QUESTION_FAIELD, payload: error });
    }
  };
};

export const getQuestion = (body) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_QUESTION_REQUIST });
      const response = await axios.post(
        `${BASE_URL}get_test_question_api/`,
        body,
        config
      );

      const data = response.data;
      dispatch({ type: GET_QUESTION_SUCCESS, payload: data });
      if (response.data.status === 200) {
        return response.data;
      } else {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
          }
        });
      }
    } catch (error) {
      dispatch({ type: GET_QUESTION_FAIELD, payload: error });
    }
  };
};

export const deleteQuestion =
  (body, descreaseQuestionCount) => async (dispatch) => {
    try {
      dispatch({ type: DELETE_QUESTION_REQUIST });
      const response = await axios.post(
        `${BASE_URL}delete_question_api/`,
        body,
        config
      );
      const data = response.data;
      dispatch({ type: DELETE_QUESTION_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        }).then((result) => {
          console.log(result);
          descreaseQuestionCount();
        });
      } else {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(response.data);
          }
        });
      }
    } catch (error) {
      dispatch({ type: DELETE_QUESTION_FAIELD, payload: error });
    }
  };

export const deleteTest = (body) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_TEST_REQUIST });
    const response = await axios.post(
      `${BASE_URL}delete_test_api/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: DELETE_TEST_SUCCESS, payload: data });
    if (response.data.status === 200) {
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
        allowOutsideClick: false,
      }).then((result) => {
        console.log(result);
      });
    } else {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(response.data);
        }
      });
    }
  } catch (error) {
    dispatch({ type: DELETE_TEST_FAIELD, payload: error });
  }
};

export const TestStatusChange = (body, handleLoader) => async (dispatch) => {
  try {
    dispatch({ type: TEST_STATUS_REQUIST });
    const response = await axios.post(
      `${BASE_URL}test_active_deactive_api/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: TEST_STATUS_SUCCESS, payload: data });
    if (response.data.status === 200) {
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
        allowOutsideClick: false,
      }).then((result) => {
        console.log(result);
        handleLoader();
      });
    } else {
      handleLoader();
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
        allowOutsideClick: false,
      }).then((result) => {
        handleLoader();
        if (result.isConfirmed) {
          console.log(response.data);
        }
      });
    }
  } catch (error) {
    handleLoader();
    dispatch({ type: TEST_STATUS_FAIELD, payload: error });
  }
};

export const AddClassTest = (body) => async (dispatch) => {
  try {
    dispatch({ type: ADD_CLASS_TEST_REQUIST });
    const response = await axios.post(
      `${BASE_URL}add_class_test_api/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: ADD_CLASS_TEST_SUCCESS, payload: data });
    if (response.data.status === 200) {
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
        allowOutsideClick: false,
      }).then((result) => {
        console.log(result);
      });
    } else {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(response.data);
        }
      });
    }
  } catch (error) {
    dispatch({ type: ADD_CLASS_TEST_FAIELD, payload: error });
  }
};

export const EditClassTest = (body, handleLoader) => async (dispatch) => {
  try {
    dispatch({ type: EDIT_CLASS_TEST_REQUIST });
    const response = await axios.post(
      `${BASE_URL}edit_class_test_api/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: EDIT_CLASS_TEST_SUCCESS, payload: data });
    if (response.data.status === 200) {
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
        allowOutsideClick: false,
      }).then((result) => {
        console.log(result);
        handleLoader();
      });
    } else {
      handleLoader();
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(response.data);
        }
      });
    }
  } catch (error) {
    handleLoader();
    dispatch({ type: EDIT_CLASS_TEST_FAIELD, payload: error });
  }
};

export const ShowTestQuiz = (body) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_TEST_QUIZ_REQUIST });
    const response = await axios.post(
      `${BASE_URL}show_TestQuiz/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: SHOW_TEST_QUIZ_SUCCESS, payload: data });
    if (response.data.status === 200) {
      // console.log(data);
    }
  } catch (error) {
    dispatch({ type: SHOW_TEST_QUIZ_FAIELD, payload: error });
  }
};
export const ShowOnlineTestQuiz = (body) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_ONLINETEST_REQUIST });
    const response = await axios.post(
      `${BASE_URL}get_onlineTestQuiz_web/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: SHOW_ONLINETEST_SUCCESS, payload: data });
    if (response.data.status === 200) {
      // console.log(data);
    }
  } catch (error) {
    dispatch({ type: SHOW_ONLINETEST_FAIELD, payload: error });
  }
};
