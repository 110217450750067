import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");
export const LeavingAPI = {
  getLeavedStudentList: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}get_list_of_leaved_cancel_student/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  viewLeavingCertificateDetail: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}view_leaving_certificate_detail_inbulk/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  generateLeavingCertificate: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}save_leaving_certificate_detailbulk/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },

  getFilterStudentList: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}filter_student_leaving_certificate/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  cancelLeaving: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}cancel_student_leaving_certificate/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getLeavingInfo: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_leaving_info_with_tc_code/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
};
