export const GET_ORGANIZATION_REQUIST = "GET_ORGANIZATION_REQUIST";
export const GET_ORGANIZATION_FAILD = "GET_ORGANIZATION_FAILD";
export const GET_ORGANIZATION_SUCCESS = "GET_ORGANIZATION_SUCCESS";

export const CREATE_ORGANIZATION_REQUIST = "CREATE_ORGANIZATION_REQUIST";
export const CREATE_ORGANIZATION_FAILD = "CREATE_ORGANIZATION_FAILD";
export const CREATE_ORGANIZATION_SUCCESS = "CREATE_ORGANIZATION_SUCCESS";

export const DELETE_ORGANIZATION_REQUIST = "DELETE_ORGANIZATION_REQUIST";
export const DELETE_ORGANIZATION_SUCCESS = "DELETE_ORGANIZATION_SUCCESS";
export const DELETE_ORGANIZATION_FAILD = "DELETE_ORGANIZATION_FAILD";

export const UPDATE_ORGANIZATION_REQUIST = "UPDATE_ORGANIZATION_REQUIST";
export const UPDATE_ORGANIZATION_SUCCESS = "UPDATE_ORGANIZATION_SUCCESS";
export const UPDATE_ORGANIZATION_FAILD = "UPDATE_ORGANIZATION_FAILD";
