import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";
import {
  CREATE_BONAFIDE_FAILURE,
  CREATE_BONAFIDE_REQUEST,
  CREATE_BONAFIDE_SUCCESS,
  CREATE_CHARACTER_FAILURE,
  CREATE_CHARACTER_REQUEST,
  CREATE_CHARACTER_SUCCESS,
  GET_BONAFIDELIST_FAILURE,
  GET_BONAFIDELIST_REQUEST,
  GET_BONAFIDELIST_SUCCESS,
  GET_CHARACTERLIST_FAILURE,
  GET_CHARACTERLIST_REQUEST,
  GET_CHARACTERLIST_SUCCESS,
  GET_STUBY_ID_FAILURE,
  GET_STUBY_ID_REQUEST,
  GET_STUBY_ID_SUCCESS,
} from "../../Constants/school_user/Certificete";

const token = localStorage.getItem("token");

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const getBonafiteCertificateList = (body) => async (dispatch) => {
  try {
    dispatch({ type: GET_BONAFIDELIST_REQUEST });
    const response = await axios.post(
      `${BASE_URL}get_students_bonafite_history/`,
      body,
      config
    );
    const data = response.data;

    dispatch({ type: GET_BONAFIDELIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_BONAFIDELIST_FAILURE, payload: error });
  }
};
export const bonafiteCertificate = (body) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_BONAFIDE_REQUEST });
    const response = await axios.post(
      `${BASE_URL}save_bonafite/`,
      body,
      config
    );
    const data = response.data;

    dispatch({ type: CREATE_BONAFIDE_SUCCESS, payload: data });
    return data
  } catch (error) {
    dispatch({ type: CREATE_BONAFIDE_FAILURE, payload: error });
  }
};
export const characterCertificate = (body) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CHARACTER_REQUEST });
    const response = await axios.post(
      `${BASE_URL}save_character_certificate/`,
      body,
      config
    );
    const data = response.data;

    dispatch({ type: CREATE_CHARACTER_SUCCESS, payload: data });
    return data
  } catch (error) {
    dispatch({ type: CREATE_CHARACTER_FAILURE, payload: error });
  }
};

export const getCharacterCertificateList = (body) => async (dispatch) => {
  try {
    dispatch({ type: GET_CHARACTERLIST_REQUEST });
    const response = await axios.post(
      `${BASE_URL}get_students_charector_cert_history/`,
      body,
      config
    );
    const data = response.data;

    dispatch({ type: GET_CHARACTERLIST_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_CHARACTERLIST_FAILURE, payload: error });
  }
};

export const getStudentById = (body) => async (dispatch) => {
  try {
    dispatch({ type: GET_STUBY_ID_REQUEST });
    const response = await axios.post(
      `${BASE_URL}search_using_student_id/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: GET_STUBY_ID_SUCCESS, payload: data });
    return data
  } catch (error) {
    dispatch({ type: GET_STUBY_ID_FAILURE, payload: error });
  }
};
