import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
import ValidationComponent from "../../Comman/Validation";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";

const FeesReceiptList = (props) => {
  const { studentList, setSelectStudent, hostelId, setHostelId } = props;
  const [modal, setModal] = useState(false);
  const { validationError, validateControl } = ValidationComponent({
    data: { hostelId },
    requiredFields: ["hostelId"], 
  });
  const toggle = async () => {
    const isValid = await validateControl();
    if (!isValid) return;
    setModal(!modal);
  };

  const handleSelectRoom = (data) => {
    setSelectStudent(data);
    setModal(!modal);
  };

  const closeBtn = (
    <button className="btn btn-sm close " onClick={toggle} type="button">
      <i className="fa fa-times" style={{ color: "red" }}></i>
    </button>
  );

  return (
    <>
      <div className="input-group-append">
        <button
          className="btn btn-outline-secondary"
          data-toggle="tooltip"
          data-placement="top"
          title="View Student List"
          type="button"
          onClick={() => {
            setSelectStudent("");
            toggle();
          }}
        >
          <i className="fa fa-search"> </i>
        </button>
        {validationError && !hostelId ? (
          <ErrorDisplay errorMsg={"Hostel is required !"} />
        ) : null}
      </div>
      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box">
        <ModalHeader toggle={toggle} close={closeBtn}>
          {/* {title} */}
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            {studentList && studentList?.length > 0 ? (
              <Table size="sm" className="table-bordered" striped>
                <thead className="table-dark">
                  <tr className="text-center text-nowrap ">
                    <th>Sr No</th>
                    <th>Student Name</th>
                    <th>Student Type</th>
                    <th>total fees</th>
                    <th>balance fees</th>
                    <th>totalpaid fees</th>
                  </tr>
                </thead>
                <tbody>
                  {studentList?.map((room, index) => (
                    <tr
                      key={index}
                      className="clickable-row-color text-center"
                      onClick={() => handleSelectRoom(room)}
                    >
                      <td className="text-center">{index + 1}</td>
                      <td className="text-left">
                        {room.student_name
                          ? room.student_name
                          : room.fk_student__fk_student__std_name}
                      </td>
                      <td>{room.student_type}</td>
                      <td className="text-right">{room.total_fees}</td>
                      <td className="text-right">{room.balance_fees}</td>
                      <td className="text-right">{room.totalpaid_fees}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyListMessage message={"Student Not Available !"} Div="div" />
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default FeesReceiptList;
