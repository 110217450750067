import React, { useEffect } from "react";
import { useState } from "react";
import { StudentPromotionAPI } from "../../../../api/Admission/studentPromotion";
import Select from "react-select";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
import Loader from "../../../Loader/Loader";
import useLoading from "../../../CommonComponent/useLoading";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import { getMasterData } from "../../../../actions/super-user/MasterData";
import { useDispatch, useSelector } from "react-redux";

const ListForPromotion = (props) => {
  const {
    school_id,
    session_id,
    sessionLabel,
    isResultClass,
    isPromoteClass,
    selectNextSession,
    promotedList,
    setPromotedList,
    selectedStudent,
    setSelectedStudent,isResult
  } = props;
  const token = localStorage.getItem("token");
  const { isLoading, startLoading, stopLoading } = useLoading();
  const dispatch = useDispatch();
  const { masterData } = useSelector((state) => state.masterData);
  const [getConcessionList, setGetConcessionList] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  

  const fetchForPromotion = async () => {
    const body = {
      school_id: school_id,
      academic_id: session_id,
      section_id: isResultClass?.value,
    };
    startLoading();
    try {
      let response = await StudentPromotionAPI.getStudentForPromotion(body);
      let responseData = response.data;
      setPromotedList(responseData.student_list);
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    fetchForPromotion();
  }, [session_id]);

  const connessionList = getConcessionList?.map((cl) => {
    return {
      label: cl.value,
      value: cl.id,
    };
  });
  useEffect(() => {
    setGetConcessionList(
      masterData?.payload?.filter((obj) => obj.name === "Concession")
    );
  }, [masterData, session_id]);

  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token));
    }
  }, [dispatch, token, session_id]);

  

  useEffect(() => {
    setSelectAllChecked(selectedStudent?.length === promotedList?.length);
  }, [selectedStudent, promotedList]);


  const handleSelectStudent = (index) => {
    const newSelectedStudent = [...selectedStudent];
    const existStudent = newSelectedStudent?.findIndex(
      (s) => s.student_id === promotedList[index].student_id
    );
    if (existStudent === -1) {
      newSelectedStudent.push(promotedList[index]);
    } else {
      newSelectedStudent.splice(existStudent, 1);
    }
    setSelectedStudent(newSelectedStudent);
    setSelectAllChecked(newSelectedStudent?.length === promotedList?.length);
  };

  const handleSelectAllStudent = () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      const all = promotedList?.map((student) => student);
      setSelectedStudent(all);
    } else {
      setSelectedStudent([]);
    }
  };

  const handleConcessionChange = (index, select) => {
    const newList = [...promotedList];
    newList[index].concession_id = select.value;
    setPromotedList(newList);
  };

  if (isLoading) return <Loader />;
  return (
    <>
      <div className="card">
        <div className="card-body">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 form-group row">
              <label className="col-form-label col-md-5">
                Current Session<span className="text-danger">*</span>
              </label>
              <div className="col-md-7">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Current Session"
                  defaultValue={sessionLabel}
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 form-group row">
              <label className="col-form-label col-md-5">
                Result Class<span className="text-danger">*</span>
              </label>
              <div className="col-md-7">
                <Select
                  className=""
                  placeholder="Select Class"
                  defaultValue={isResultClass}
                />
              </div>
            </div>
          </div>
          {!isResult && ( <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 form-group row">
              <label className="col-form-label col-md-5">
                Promote To Session<span className="text-danger">*</span>
              </label>
              <div className="col-md-7">
                <Select
                  placeholder="Promote To Session"
                  defaultValue={selectNextSession}
                />
              </div>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 form-group row">
              <label className="col-form-label col-md-5">
                Promote To Class <span className="text-danger">*</span>
              </label>
              <div className="col-md-7">
                <Select
                  className=""
                  placeholder="Select Class"
                  defaultValue={isPromoteClass}
                />
              </div>
            </div>
          </div>)}
         
        </div>
      </div>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">List Of Student</h3>
        </div>
        <div className="card-body">
          <PerfectScrollbar
            style={{
              maxHeight: "19rem",
              paddingRight: "1rem",
              boxSizing: "content-box",
            }}
            options={{ suppressScrollX: true }}
          >
            <div className="table-responsive">
              {promotedList?.length > 0 ? (
                <table
                  className="table table-striped table-vcenter table-bordered table-hover table-sm"
                 
                >
                  <thead>
                    <tr className="text-center text-nowrap">
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAllChecked}
                          onChange={handleSelectAllStudent}
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                      {/* <th> Sr No</th> */}
                      <th> Student Code</th>
                      <th>Full Name</th>
                      <th>Concession</th>
                      <th>Total Balance</th>
                    </tr>
                  </thead>
                  <tbody>
                    {promotedList?.map((s, index) => (
                      <tr className="" key={index}>
                        <td className="text-center">
                          <input
                            type="checkbox"
                            checked={selectedStudent?.some(
                              (st) => st.student_id === s.student_id
                            )}
                            onChange={() => handleSelectStudent(index)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                        {/* <td className="text-center">{index + 1}</td> */}
                        <td className="text-center">{s.student_code}</td>
                        <td>{s.student_name}</td>
                        <td className="">
                          <Select
                            options={connessionList}
                            placeholder="Select Concession"
                            value={
                              s.concession_id
                                ? connessionList?.find(
                                    (c) => c.value === Number(s.concession_id)
                                  )
                                : null
                            }
                            onChange={(select) =>
                              handleConcessionChange(index, select)
                            }
                            styles={{
                              menu: (provided) => ({
                                ...provided,
                                zIndex: 9999,
                              }),
                            }}
                          />
                        </td>
                        <td className="text-right">{s.balance_amount}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <EmptyListMessage
                  message={"Student Not Available !"}
                  Div="div"
                />
              )}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </>
  );
};

export default ListForPromotion;
