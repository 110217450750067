import React, { useEffect } from 'react'
import { useState } from 'react';
import { BusFeeCollectionAPI } from "../../../../api/Transport/BusFeeCollection";
import useLocalStorageData from "../../Comman/LocalStorageData";
import html2pdf from "html2pdf.js";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap"; 
import numberToWords from "number-to-words";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import { CustomModalBody } from '../../../CommonComponent/modelComponent';
import useSessionRedirect from "../../Comman/SessionChange";

const SearchStudent = (props) => {
  const {setSelectedId ,handleClear ,setStuId} = props
    const { school_id, token, school_emp_id } = useLocalStorageData();
    const [stuList, setStuList] = useState([]);
    const [modal, setModal] = useState(false);
    const session_id = useSessionRedirect();
    const getStudentData = async () => {
        try{
             let data = {
                school_id: school_id,
                 student_code: "",
                 academic_id: session_id,
            }
            const response = await BusFeeCollectionAPI.getStudentRouteMapped(data);
            const responseData = response.data;
            setStuList(responseData.student_route);
        }
        catch(err)
        {

        }        
    }

    const handleRowClick = (id ,stuID) => {
        setSelectedId(id);
        setStuId(stuID)
        
        toggle1();
      };

    const toggle1 = () => {
        setModal(!modal);
        getStudentData() 
      };
  return (
    <>
      
      <div className="input-group-append">
        <button
          className="btn btn-outline-secondary"
          data-toggle="tooltip"
          data-placement="top"
          title="View Student List"
          type="button"
          onClick={() => {
         handleClear()
            setSelectedId(null);
            // setSearch(stuList);
            toggle1();
          }}
        >
          <i className="fa fa-search"> </i>
        </button>
      </div>


      <Modal isOpen={modal} toggle={toggle1} fullscreen className="mobel_box" backdrop="static" keyboard={false}>
        <CustomModalBody>
        <div className="table-responsive">
          <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
            <thead>
              <tr className="text-center">
                <th>Reg Date</th>
                <th>Stud No.</th>
                <th>Student Name</th>
                <th>Bus Start Date</th>
                <th>Class-Section</th>
                <th>Route Name</th>
                <th>Pickup Point</th>
              </tr>
            </thead>
            <tbody>
              {stuList.map((student) => (
                <tr key={student.id} className="text-center"
                onClick={() => handleRowClick(student.id , student.fk_student__fk_student__student_code)}
                  style={{ cursor: 'pointer' }}>
                  <td>{student.bus_start_date}</td>
                  <td>{student.fk_student__fk_student__student_code}</td>
                  <td>{student.fk_student__fk_student__std_name}</td>
                  <td>{student.bus_start_date}</td>
                  <td>{`${student.fk_student__fk_section__fk_class__class_name}-${student.fk_student__fk_section__section}`}</td>
                  <td>{student.fk_pickup_point__fk_route__route_name}</td>
                  <td>{student.fk_pickup_point__pickup_point}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
        </CustomModalBody>
        
         <ModalFooter>
              <Button
                color="secondary"
                onClick={() => {
                  toggle1();
               
                }}
              >
                Cancel
              </Button>
            </ModalFooter>
      </Modal>
    </>
  )
}

export default SearchStudent
