import React, { useState, useEffect } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import {
  createHoliday,
  deleteHoliday,
  getHolidayList,
} from "../../../../actions/school_user/HolidayMaster";
import Loader from "../../../Loader/Loader";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
import useSessionRedirect from "../../Comman/SessionChange";
import { convertDateInMMDDYY } from "../../../CommonComponent/DateFormatUtil";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import TimeoutErrorDisplay from "../../Comman/TimeoutErrorDisplay";
import { holidayType } from "../../../../data/optionData";

const HolidayMaster = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user || {};
  let s_id = school_user?.user_detail?.fk_school_id || {};

  const session_id = useSessionRedirect();
  const dispatch = useDispatch();
  const { allClass } = useSelector((state) => state.getClass);
  const { holidaydata, loading } = useSelector((state) => state.holidayMaster);
  const [allClasses, setAllClasses] = useState(false);
  let navigate = useNavigate();
  let handleCancel = (event) => {
    event.preventDefault();
    window.location.href = "/school/holiday-master";
  };
  const classList = allClass?.payload
    ?.map((list) => {
      return {
        label: list.class_name,
        value: list.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  useEffect(() => {
    if (token) {
      dispatch(getHolidayList(token, s_id, session_id));
    }
  }, [dispatch, token, session_id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fk_school: s_id,
      fk_academic: session_id,
      class_id: [],
      description: "",
      holiday_type: "",
      start_date: "",
      end_date: "",
      for_all: false,
    },
    validationSchema: Yup.object().shape({
      holiday_type: Yup.string().required("This is required"),
      start_date: Yup.string().required("This is required"),
      end_date: Yup.string().required("This is required"),
      description: Yup.string().required("This is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      if (
        formik.values.for_all === false &&
        formik.values.class_id.length === 0
      ) {
        Swal.fire({
          title: "",
          text: "Please Select Class ",
          icon: "warning",
          allowOutsideClick: false,
        });
        return;
      }

      if (values.start_date > values.end_date) {
        Swal.fire({
          title: "",
          text: "The 'To' date cannot be before the 'From' date. ",
          icon: "info",
          allowOutsideClick: false,
        });
        return;
      }
      const classIdsArray = values.class_id?.map((option) => option.value);
      const data = new FormData();
      data.append(
        "class_id",
        values.for_all ? [] : JSON.stringify(classIdsArray)
      );
      data.append("fk_academic", values.fk_academic);
      data.append("fk_school", values.fk_school);
      data.append("description", values.description);
      data.append("holiday_type", values.holiday_type);
      data.append("start_date", values.start_date);
      data.append("end_date", values.end_date);
      data.append("for_all", values.for_all);

      dispatch(createHoliday(token, data, navigate, resetForm))
        .then(() => {
          dispatch(getHolidayList(token, s_id, session_id));
        })
        .catch((err) => console.error(err));
    },
  });

  // useEffect(() => {
  //   const timeouts = {};
  //   console.log(timeouts,"first ");
  //   Object.keys(formik.errors).forEach((field) => {
  //     if (formik.touched[field] && formik.errors[field]) {
  //       timeouts[field] = setTimeout(() => {
  //         formik.setFieldError(field, "");
  //       }, 5000);
  //     }
  //   });
  //   return () => {
  //     Object.values(timeouts).forEach((timeout) => clearTimeout(timeout));
  //     console.log(timeouts,"second ");
  //   };
  // }, [formik.touched, formik.errors]);

  const handleAllClassesChange = (event) => {
    const isChecked = event.target.checked;
    if (isChecked) {
      formik.setFieldValue("class_id", []);
    }
    formik.setFieldValue("for_all", isChecked);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteHoliday(token, id))
          .then(() => {
            dispatch(getHolidayList(token, s_id, session_id));
          })
          .catch((err) => console.error(err));
      }
    });
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Holiday Master</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active" 
                  data-toggle="tab"
                  href="#Holiday-all"
                >
                  Holiday List
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Holiday-add">
                  Add Holiday
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="tab-pane active" id="Holiday-all">
              {loading ? (
                <Loader />
              ) : (
                <div className="table-responsive card">
                  {holidaydata?.payload?.length > 0 ? (
                    <table className="table table-hover table-vcenter table-striped table-sm text-nowrap">
                      <thead>
                        <tr className="text-center">
                          <th>Sr.No.</th>
                          <th>Holiday Description</th>
                          <th>Class</th>
                          <th>Holiday Type</th>
                          <th>From Date</th>
                          <th>To Date</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {holidaydata?.payload?.map((h, index) => {
                          const classLabels = classList
                            ?.filter((cl) => h.class_id.includes(cl.value))
                            .map((org) => org.label)
                            .join(" , ");
                          return (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td>{h.description}</td>
                              <td>{h.for_all ? "All" : classLabels}</td>
                              <td>{h.holiday_type}</td>
                              <td className="text-center">
                                {convertDateInMMDDYY(h.start_date)}
                              </td>
                              <td className="text-center">
                                {convertDateInMMDDYY(h.end_date)}
                              </td>
                              <td className="text-center">
                                <NavLink
                                  to="#"
                                  className="card-options-remove mr-3"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  onClick={() => onDelete(h.id)}
                                >
                                  <i className="fa fa-trash-o text-danger"></i>
                                </NavLink>
                                <NavLink
                                  className="text-muted"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                  to={`/school/edit-holiday`}
                                  state={{ id: h.id }}
                                >
                                  <i className="fa fa-edit"></i>
                                </NavLink>
                                {/* <NavLink
                                  className="text-muted"
                                  to={`/school/edit-holiday/${h.id}`}
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Edit"
                                >
                                  <i className="fa fa-edit"></i>
                                </NavLink> */}
                              </td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </table>
                  ) : (
                    <EmptyListMessage
                      message={"Holiday Not Available !"}
                      Div="div"
                    />
                  )}
                </div>
              )}
            </div>

            <div className="tab-pane" id="Holiday-add">
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="card">
                  <form
                    className="card-body"
                    onSubmit={(e) => {
                      e.preventDefault();
                      formik.handleSubmit();
                      return false;
                    }}
                  >
                    <div className="row">
                      <div className="col-md-12">
                        <div className="form-group row">
                          <label className="col-md-3 col-form-label ">
                            Class<span className="text-danger"></span>
                          </label>
                          <div className="col-md-7">
                            <Select
                              isMulti
                              name="class_id"
                              placeholder="Select Class..."
                              options={classList}
                              value={formik.values.class_id}
                              onChange={(selectedOptions) =>
                                formik.setFieldValue(
                                  "class_id",
                                  selectedOptions
                                )
                              }
                              onBlur={formik.handleBlur}
                              isSearchable={true}
                              isDisabled={formik.values.for_all}
                            />
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 col-form-label">
                            All Classes
                          </label>
                          <div className="col-md-4 mt-auto">
                            <label className="custom-switch">
                              <input
                                type="checkbox"
                                className="custom-switch-input"
                                name="for_all"
                                checked={formik.values.for_all}
                                onChange={handleAllClassesChange}
                                onBlur={formik.handleBlur}
                              />
                              <span className="custom-switch-indicator"></span>
                            </label>
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 col-form-label ">
                            Holiday Type<span className="text-danger">*</span>
                          </label>
                          <div className="col-md-7">
                            <Select
                              name="holiday_type"
                              placeholder="Holiday Type"
                              options={holidayType}
                              value={
                                formik.values.holiday_type
                                  ? holidayType?.find(
                                      (option) =>
                                        option.value ===
                                        formik.values.holiday_type
                                    )
                                  : null
                              }
                              onChange={(selectedOption) =>
                                formik.setFieldValue(
                                  "holiday_type",
                                  selectedOption?.value
                                )
                              }
                              onBlur={formik.handleBlur}
                              isSearchable={true}
                            />
                            {formik.touched.holiday_type &&
                            formik.errors.holiday_type ? (
                              <ErrorDisplay
                                errorMsg={formik.errors.holiday_type}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 col-form-label">
                            From Date <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="date"
                              className="form-control"
                              name="start_date"
                              value={formik.values.start_date}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.start_date &&
                            formik.errors.start_date ? (
                              <ErrorDisplay
                                errorMsg={formik.errors.start_date}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 col-form-label">
                            To Date <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-7">
                            <input
                              type="date"
                              className="form-control"
                              name="end_date"
                              value={formik.values.end_date}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.end_date &&
                            formik.errors.end_date ? (
                              <ErrorDisplay errorMsg={formik.errors.end_date} />
                            ) : null}
                          </div>
                        </div>
                        <div className="form-group row">
                          <label className="col-md-3 col-form-label">
                            Holiday Description
                            <span className="text-danger">*</span>
                          </label>
                          <div className="col-md-7">
                            <textarea
                              type="text"
                              className="form-control"
                              name="description"
                              placeholder="Enter Holiday Description"
                              value={formik.values.description}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.description &&
                            formik.errors.description ? (
                              <ErrorDisplay
                                errorMsg={formik.errors.description}
                              />
                            ) : null}
                          </div>
                        </div>
                        <div className="d-flex justify-content-end align-item-center my-3">
                          <button
                            type="submit"
                            className="mr-2 btn btn-primary btn-sm text-center"
                          >
                            <i className="fa fa-plus px-1"></i> Save
                          </button>
                          <button
                            onClick={handleCancel}
                            className="btn btn-outline-danger btn-sm text-center"
                          >
                            <i className="fa fa-times px-1" />
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                    <TimeoutErrorDisplay
                      formik={formik}
                      timeoutDuration={3000}
                    />
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default HolidayMaster;
