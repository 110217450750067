import React from "react";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  getFeeList,
  getClassSectionList,
} from "../../../actions/super-user/SchoolRegistration";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
import { getMasterData } from "../../../actions/super-user/MasterData.js";
import { useContext } from "react";
import { SessionContext } from "../../../context/sessionContext";
import {
  getAcademicFilterStudentList,
  getStudentName,
  studentDataByCode,
} from "../../../actions/Admission/AdmissionAction";
import { Modal, ModalBody, ModalFooter, Table } from "reactstrap";
import moment from "moment";

const CancelReceipt = () => {
  const token = localStorage.getItem("token");
  const { uid } = useParams();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  const {
    school_name,
    organization_name,
    school_address,
    school_contact_email,
    school_contact_number,
    school_pincode,
    udise_no,
    school_logo,
    school_emp_id,
    fk_school_id,
  } = user_detail;
  const { Session } = useContext(SessionContext);
  let session_id = Session || sessionStorage.getItem("SessionId");

  const { academicStudentList } = useSelector(
    (state) => state.getAcademicFilterStudentList
  );
  const { studentData } = useSelector((state) => state.studentDataByCode);
  const { admissionCancel } = useSelector((state) => state.cancelAdmission);
  const classesAllRef = useRef(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [remark, setRemark] = useState("");
  const [student_uid, setstudent_uid] = useState(uid);
  const [isMasterData, setIsMasterData] = useState("cancelreason");
  const [getMaster, setGetMaster] = useState([]);
  const [classdata, setClassData] = useState([]);
  const [SearchData, setSearchData] = useState([]);

  const { classlist, classsection } = useSelector(
    (state) => state.classSection
  );
  const { createmasterData, masterData } = useSelector(
    (state) => state.masterData
  );

  useEffect(() => {
    let body = {
      fk_academic: session_id,
      school_id: fk_school_id,
    };
    if (token) {
      // dispatch(getStudentList(token, body));
      dispatch(getAcademicFilterStudentList(body));
    }
  }, [dispatch, token, session_id, admissionCancel]);

  useEffect(() => {
    setSearchData(academicStudentList);
    handleClear();
  }, [academicStudentList, session_id]);

  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token, "data"));
    }
  }, [dispatch, token]);

  useEffect(() => {
    setClassData(
      masterData?.payload?.filter((obj) => obj.name === isMasterData)
    );

    setGetMaster(masterData?.payload);
  }, [masterData, isMasterData]);

  const cancelReasonList = classdata?.map((cl) => {
    return {
      label: cl.value,
      value: cl.id,
    };
  });

  if (
    classsection?.status === 403 ||
    classsection?.status === 503 ||
    classsection?.status === 200
  ) {
    if (classsection?.status === 200) {
      alert.success(classsection.msg);
    } else {
      alert.error(classsection.msg);
    }
  }

  let feeId = {
    school_id: Number(user_detail.fk_school_id),
  };

  useEffect(() => {
    if (token) {
      dispatch(getFeeList(token, feeId));
    }
  }, [dispatch, token]);
  useEffect(() => {
    if (token) {
      dispatch(getClassSectionList(token, feeId));
    }
  }, [dispatch, token]);

  let nameS = studentData?.payload?.name;
  let statusS = studentData?.payload?.student_status;
  if (student_uid === "") {
    nameS = "";
    statusS = "";
  } else if (student_uid !== studentData?.payload?.student_code) {
    nameS = "";
    statusS = "";
  }
  useEffect(() => {
    const body = {
      fk_academic: session_id,
      student_code: Number(student_uid),
      school_id: Number(user_detail.fk_school_id),
    };
    dispatch(getStudentName(body, handleClear));
    dispatch(studentDataByCode(body));
  }, [dispatch, student_uid, session_id]);

  const [CancelAdmissionDate, setCancelAdmissionDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const pay_mode = [
    {
      label: "Cash",
      value: "Cash",
    },
    {
      label: "Bank",
      value: "Bank",
    },
    {
      label: "UPI",
      value: "UPI",
    },
    {
      label: "DD",
      value: "DD",
    },
    {
      label: "Cheque",
      value: "Cheque",
    },
  ];
  const [PaymentMode, setPaymentMode] = useState();
  const PaymentList = pay_mode?.map((item) => {
    return {
      label: item.label,
      value: item.value,
    };
  });
  function handlePaymentMode(data) {
    setPaymentMode(data);
  }

  //   =================================== =======================
  const [searchQuery, setSearchQuery] = useState("");
  const [idQuery, setIdQuery] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
    setSearchResults([]);
    setNoResultsMessage("");
  };

  const handleInputChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const handleIdInputChange = (e) => {
    const query = e.target.value;
    setIdQuery(query);
  };
  const handleSearch = () => {
    if (searchQuery.trim() === "" && idQuery.trim() === "") {
      Swal.fire({
        text: "Please enter Student code or Student Name",
        icon: "error",
      });

      return;
    }
    const filteredResults = SearchData?.filter(
      (item) =>
        item.std_name?.toLowerCase().includes(searchQuery?.toLowerCase()) &&
        item.student_code?.toString().includes(idQuery)
    );
    setSearchResults(filteredResults);
    setSearchQuery("");
    setIdQuery("");
    if (filteredResults?.length === 0) {
      setNoResultsMessage("No results found.");
    } else {
      setNoResultsMessage("");
    }
  };

  const handleStudentEdit = (data) => {
    setstudent_uid(data?.student_code);
    setModal(!modal);
  };

  function handleClear() {
    setstudent_uid("");
  }
  function convertDate(data) {
    const parsedDate = moment(data, "YYYY-MM-DD");
    const formattedDate = parsedDate.format("DD-MM-YYYY");
    return formattedDate;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="section-body">
          <div className="container-fluid">
            <div className="header-action">
              <h1 className="page-title">Cancel Receipt</h1>
            </div>
          </div>
        </div>
        <div className="row m-4">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <form className="card-body">
                <div className="col-12">
                  <div className="row">
                    <div className="col-9">
                      <div className="form-group row">
                        <div className="col-5">
                          <label className="form-label">
                            Student Code <span className="text-danger">*</span>
                          </label>

                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Student Code"
                              value={student_uid}
                              onChange={(e) => setstudent_uid(e.target.value)}
                            />
                            <div className="input-group-append">
                              <button
                                className="btn btn-outline-secondary"
                                type="button"
                                onClick={toggle}
                              >
                                <i className="fa fa-search"> </i>
                              </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-7">
                          <label className="form-label">
                            Student Name <span className="text-danger">*</span>
                          </label>
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              value={nameS}
                              // onChange={(e) => setstdname(e.target.value)}
                              placeholder="Student Name"
                              disabled
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        {/* <div className="col-4">
                          <label className="form-label">
                            Date <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="date"
                              className="form-control"
                              value={CancelAdmissionDate}
                              onChange={(e) =>
                                setCancelAdmissionDate(e.target.value)
                              }
                            />
                          </div>
                        </div> */}
                        <div className="col-5">
                          <label className="form-label">
                            Amount <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              value={statusS}
                              // onChange={(e) => setStatus(e.target.value)}
                              placeholder="Amount"
                              disabled
                            />
                          </div>
                        </div>
                        <div className="col-5">
                          <label className="form-label">
                            Payment Mode <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <Select
                              options={PaymentList}
                              placeholder="Payment Mode"
                              //   value={PaymentMode}
                              //   defaultValue={PaymentList[0]}
                              //   onChange={(event) => {
                              //     handlePaymentMode(event);
                              //     setPaymentInfo((prevState) => ({
                              //       ...prevState,
                              //       payment_mode: event.value,
                              //     }));
                              //   }}
                              isSearchable={true}
                              className="form-control m-0 p-0"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-5">
                          <label className="form-label"> Cancel Reason<span className="text-danger">*</span></label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                              placeholder="Cancel Reason"
                            />
                          </div>
                        </div>
                        <div className="col-5">
                          <label className="form-label"> Authority<span className="text-danger">*</span></label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                              placeholder="Authority"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <button
                      type="button"
                      className={`btn btn-outline-danger py-1`}
                      // onClick={(e) => {
                      //   CheckUpdateStatus(e);
                      // }}
                    >
                      Cancel Receipt
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box">
        {/* <ModalHeader toggle={toggle}>  </ModalHeader> */}{" "}
        <div className="form-group row p-4">
          <div className="col-lg-3 col-md-3">
            <input
              name="code"
              type="number"
              className="form-control"
              placeholder="Student code"
              value={idQuery}
              onChange={handleIdInputChange}
              autoComplete="off"
            />
          </div>
          <div className="col-lg-3 col-md-3 ">
            <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Student Name"
              value={searchQuery}
              onChange={handleInputChangeSearch}
              autoComplete="off"
            />
          </div>
          <div className="col-md-2 col-lg-2">
            <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSearch}
            >
              Search
            </button>
          </div>
          <div className="col-lg-4 col-md-4 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={toggle}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
        <ModalBody>
          {noResultsMessage ? (
            <p>{noResultsMessage}</p>
          ) : (
            <Table size="sm" className="table-hover">
              <thead>
                <tr className="text-center">
                  <th>Registration Date</th>
                  <th>Stud Code</th>
                  <th>Student Name</th>
                  <th>Gender</th>
                  <th>DOB</th>
                  <th>Category</th>
                  <th>Caste</th>
                  <th>Sub Caste</th>
                  <th>Student Status</th>
                  <th>Enrollment No</th>
                  {/* <th>Action</th> */}
                </tr>
              </thead>
              <tbody>
                {searchResults?.map((stu, index) => {
                  return stu.student_status === "Registered" ? null : (
                    <tr
                      key={index}
                      className="text-center"
                      onClick={() => handleStudentEdit(stu)}
                    >
                      <td>{convertDate(stu.registration_date)}</td>
                      <td>{stu.student_code}</td>
                      <td className="text-left">{stu.std_name}</td>
                      <td>{stu.gender}</td>
                      <td>{convertDate(stu.date_of_birth)}</td>
                      <td>{stu.fk_category_name}</td>
                      <td>{stu.fk_caste_name}</td>
                      <td>{stu.sub_caste}</td>
                      <td>{stu.student_status}</td>
                      <td></td>
                      {/* <td className="text-center ">
                      <NavLink
                        className="text-muted"
                        // to={`/school/editstaffmaster/${school.id}`}
                      >
                        <i className="fa fa-edit"></i>
                      </NavLink>
                    </td> */}
                    </tr>
                  );
                })}
              </tbody>
            </Table>
          )}
        </ModalBody>
        <ModalFooter>
          {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default CancelReceipt;
