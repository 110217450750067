import React, { useState, useEffect } from "react";
import {
  Link,
  useNavigate,
  NavLink,
  useParams,
  useLocation,
} from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getClass } from "../../../../actions/Admission/StudentReport";
import {
  getHolidayList,
  updateHoliday,
} from "../../../../actions/school_user/HolidayMaster";
import TimeoutErrorDisplay from "../../Comman/TimeoutErrorDisplay";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import { holidayType } from "../../../../data/optionData";
import { SelectField } from "../../../CommonComponent/SelectField";
import useSessionRedirect from "../../Comman/SessionChange";
import useEnsureId from "../../Comman/useEnsureId";

const EditHoliday = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let s_id = school_user?.user_detail?.fk_school_id;
  const { user_type, name, user_detail } = school_user;
  const session_id = useSessionRedirect();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  // const { id } = useParams();
  // console.log(id,"param")
  // const location = useLocation();
  // const id = location?.state?.id;
  // useEffect(() => {
  //   if (!id) {
  //     navigate(location?.pathname);
  //   }
  // }, [id]);
  const id = useEnsureId();
  const [editData, setEditData] = useState(null);
  const { holidaydata } = useSelector((state) => state.holidayMaster);
  const { allClass } = useSelector((state) => state.getClass);
  const [allClasses, setAllClasses] = useState(false);

  const classList = allClass?.payload
    ?.map((list) => {
      return {
        label: list.class_name,
        value: list.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));
  let body = {
    school_id: user_detail?.fk_school_id,
  };
  useEffect(() => {
    if (token) {
      dispatch(getHolidayList(token, s_id, session_id));
    }
  }, [dispatch, token, s_id, session_id]);
  useEffect(() => {
    if (token) {
      dispatch(getClass(body));
    }
  }, [dispatch, token]);

  useEffect(() => {
    let post = holidaydata?.payload?.find((sid) => sid.id === Number(id));
    setEditData(post);
    setAllClasses(post?.for_all);
  }, [id]);

  const selectedClassIds = editData?.class_id;
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fk_academic: editData?.fk_academic_id,
      fk_school: s_id,
      class_id: classList?.filter((item) =>
        selectedClassIds?.includes(item.value)
      ),
      description: editData?.description || "",
      holiday_type: editData?.holiday_type || "",
      start_date: editData?.start_date || "",
      end_date: editData?.end_date || "",
      for_all: "",
    },
    validationSchema: Yup.object().shape({
      holiday_type: Yup.string().required("This is required"),
      start_date: Yup.string().required("This is required"),
      end_date: Yup.string().required("This is required"),
      description: Yup.string().required("This is required"),
    }),

    onSubmit: (values) => {
      if (
        formik.values.for_all === false &&
        formik.values.class_id.length === 0
      ) {
        Swal.fire({
          title: "",
          text: "Please Select Class ",
          icon: "warning",
          allowOutsideClick: false,
        });
        return;
      }
      if (values.start_date > values.end_date) {
        Swal.fire({
          title: "",
          text: "The 'To' date cannot be before the 'From' date. ",
          icon: "info",
          allowOutsideClick: false,
        });
        return;
      }
      const classIdsArray = values.class_id?.map((option) => option.value);
      const data = new FormData();
      data.append("class_id", allClasses ? [] : JSON.stringify(classIdsArray));
      data.append("fk_school", values.fk_school);
      data.append("fk_academic", values.fk_academic);
      data.append("description", values.description);
      data.append("holiday_type", values.holiday_type);
      data.append("start_date", values.start_date);
      data.append("end_date", values.end_date);
      data.append("for_all", allClasses);
      dispatch(updateHoliday(token, editData?.id, data, navigate));
    },
  });
  const handleAllClassesChange = (event) => {
    setAllClasses(event.target.checked);
  };

  let handleCancel = (event) => {
    event.preventDefault();
    window.location.href = "/school/holiday-master";
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Holiday Master</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  data-toggle="tab"
                  href="#staff-master"
                  as={NavLink}
                  to="/school/holiday-master"
                >
                  Holiday List
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Staff-edit"
                >
                  Update Holiday
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="tab-pane active" id="Staff-edit">
              <div className="row clearfix">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="card">
                    <form
                      className="card-body"
                      onSubmit={(e) => {
                        e.preventDefault();
                        formik.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group row">
                            <label className="col-md-3 col-form-label ">
                              Class<span className="text-danger"></span>
                            </label>
                            <div className="col-md-7">
                              <Select
                                isMulti
                                name="class_id"
                                placeholder="Select Class..."
                                options={classList}
                                value={formik.values.class_id}
                                onChange={(selectedOptions) =>
                                  formik.setFieldValue(
                                    "class_id",
                                    selectedOptions
                                  )
                                }
                                onBlur={formik.handleBlur}
                                isSearchable={true}
                                isDisabled={allClasses}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 col-form-label">
                              All Classes
                            </label>
                            <div className="col-md-4 mt-auto">
                              <label className="custom-switch">
                                <input
                                  type="checkbox"
                                  className="custom-switch-input"
                                  name="for_all"
                                  checked={allClasses}
                                  onChange={handleAllClassesChange}
                                  onBlur={formik.handleBlur}
                                />
                                <span className="custom-switch-indicator"></span>
                              </label>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-3 col-form-label ">
                              Holiday Type<span className="text-danger">*</span>
                            </label>
                            <div className="col-md-7">
                              <SelectField
                                name="holiday_type"
                                placeholder="Holiday Type"
                                options={holidayType}
                                value={formik.values.holiday_type}
                                onChange={(selectedOption) =>
                                  formik.setFieldValue(
                                    "holiday_type",
                                    selectedOption?.value
                                  )
                                }
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.holiday_type &&
                            formik.errors.holiday_type ? (
                              <ErrorDisplay
                                errorMsg={formik.errors.holiday_type}
                              />
                            ) : null}
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 col-form-label">
                              From Date <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-7">
                              <input
                                type="date"
                                className="form-control"
                                name="start_date"
                                value={formik.values.start_date}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.start_date &&
                              formik.errors.start_date ? (
                                <ErrorDisplay
                                  errorMsg={formik.errors.start_date}
                                />
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 col-form-label">
                              To Date <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-7">
                              <input
                                type="date"
                                className="form-control"
                                name="end_date"
                                value={formik.values.end_date}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.end_date &&
                              formik.errors.end_date ? (
                                <ErrorDisplay
                                  errorMsg={formik.errors.end_date}
                                />
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-3 col-form-label">
                              Holiday Description
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-7">
                              <textarea
                                type="text"
                                className="form-control"
                                name="description"
                                placeholder="Enter Holiday Description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />

                              {formik.touched.description &&
                              formik.errors.description ? (
                                <ErrorDisplay
                                  errorMsg={formik.errors.description}
                                />
                              ) : null}
                            </div>
                          </div>
                          <div className="d-flex justify-content-end align-item-center my-3">
                            <button
                              type="submit"
                              className="mr-2 btn btn-primary btn-sm text-center"
                            >
                              <i className="fa fa-plus px-1"></i> Update
                            </button>
                            <button
                              onClick={handleCancel}
                              className="btn btn-outline-danger btn-sm text-center"
                            >
                              <i className="fa fa-times px-1" />
                              Cancel
                            </button>
                          </div>
                        </div>
                      </div>
                      <TimeoutErrorDisplay
                        formik={formik}
                        timeoutDuration={3000}
                      />
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditHoliday;
