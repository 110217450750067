import React, { useEffect, useState } from "react";
import { Map, GoogleApiWrapper, Marker } from "google-maps-react";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
import { Modal, ModalBody, ModalFooter, Button } from "reactstrap";
import { FaMapMarkedAlt } from "react-icons/fa";


const MapHelper = ({ google, setMapAddress, setlatlng ,lato ,lngo}) => {
    // console.log(lat,lng ,"from helper")
    console.log(lato,lngo,"lato,lngo")
  
  const [open, setOpen] = useState(false);
  const [selectedLocation, setSelectedLocation] = useState("");
  console.log(selectedLocation, "selectedLocation");
  const [searchedLocation, setSearchedLocation] = useState("");
  const [actualLocation ,setActualLocation] = useState("")
  const [mapCenter, setMapCenter] = useState({ lat: lato, lng: lngo });
  console.log(actualLocation)
  useEffect(() => {
    setMapCenter({ lat: lato, lng: lngo });
}, [lato, lngo]);

//   console.log(mapCenter, "mapCenter");


  const toggle = () =>{ 
    setOpen(!open)
   
};

  const mapClicked = async (mapProps, map, clickEvent) => {
    try {
      const geocoder = new google.maps.Geocoder();
      const latLng = {
        lat: clickEvent.latLng.lat(),
        lng: clickEvent.latLng.lng(),
      };
      setActualLocation(latLng)
      geocoder.geocode({ location: latLng }, (results, status) => {
        if (status === "OK") {
          if (results[0]) {
            const address = results[0].formatted_address;
            setSearchedLocation(address);
            setSelectedLocation(clickEvent.latLng);
          } else {
            console.error("No results found");
          }
        } else {
          console.error("Geocoder failed due to: " + status);
        }
      });
    } catch (error) {
      console.error("Error geocoding location", error);
    }
  };

  const handleSelect = async (address) => {
    setSearchedLocation(address);
    try {
      const results = await geocodeByAddress(address);
      const latLng = await getLatLng(results[0]);
      console.log(latLng, "latLngfrom handleSelect");
      setSelectedLocation(latLng);
      setMapCenter(latLng);
    
    } catch (error) {
      console.error("Error fetching location", error);
    }
  };
  const handleSave = () => {
    if (selectedLocation) {
      setMapAddress(searchedLocation);
      setOpen(!open);
      setlatlng(actualLocation);
    } 
  };

  return (
    <>
      <button
        className="btn btn-primary btn-sm m-1"
        type="button"
        onClick={() => toggle()}
      >
        <FaMapMarkedAlt />
        {"  "} Pick Your Location
      </button>
      <div className="container-fluid">
        <Modal isOpen={open} fullscreen className="modal-lg">
          <ModalBody>
            <div className="row">
              <div className="col-md-12 ">
                <PlacesAutocomplete
                  value={searchedLocation}
                  onChange={setSearchedLocation}
                  onSelect={handleSelect}
                  onEnterKeyDown={handleSelect}
                >
                  {({
                    getInputProps,
                    suggestions,
                    getSuggestionItemProps,
                    loading,
                  }) => (
                    <div className="">
                      <div className="d-flex mb-2">
                        <div className="col-md-6">
                          <input
                            className="form-control custom-input "
                            {...getInputProps({ placeholder: "Search Place" })}
                          />
                        </div>
                        <div className="col-md-6">
                          <button
                            className="btn btn-primary"
                            type="button"
                            onClick={handleSave}
                          >
                            Save
                          </button>
                        </div>
                      </div>
                      <div>
                        {loading ? <div>Loading...</div> : null}
                        {suggestions.map((suggestion) => {
                          const style = {
                            backgroundColor: suggestion.active
                              ? "#41b6e6"
                              : "transparent",
                            cursor: "pointer",
                            padding: "8px",
                            borderBottom: "1px solid #ddd",
                          };
                          return (
                            <div
                              className="m-4"
                              {...getSuggestionItemProps(suggestion, { style })}
                            >
                              {suggestion.description}
                            </div>
                          );
                        })}
                      </div>
                     
                    </div>
                  )}
                </PlacesAutocomplete>
              </div>
              <div className="col-md-12 mt-1">
                <div
                  style={{
                    height: "500px",
                    width: "100%",
                    marginRight: "10px",
                  }}
                >
                  <Map
                    google={google}
                    zoom={15}
                    initialCenter={mapCenter}
                    center={mapCenter}
                
                    style={{ height: "100%", width: "98%" }}
                    onClick={mapClicked}
                  >
                    <Marker position={selectedLocation? selectedLocation : mapCenter} />
                  </Map>
                </div>
              </div>
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
};

export default GoogleApiWrapper({ 
  apiKey: "AIzaSyA9w97EGCFVrTi0OEkK4N5T30nyEpP7IBM",
})(MapHelper);
