import {
  CREATE_STAFF_FAILURE,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  DELETE_STAFF_FAILURE,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  GET_STAFF_FAILURE,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  UPDATE_STAFF_FAILURE,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
} from "../../Constants/school_user/StaffMaster";

const initialState = {
  staffdata: null,
  createstaff: null,
  updatestaff: null,
  deletestaff: null,
  loading: false,
  error: null,
};

export const staffMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STAFF_REQUEST:
    case CREATE_STAFF_REQUEST:
    case UPDATE_STAFF_REQUEST:
    case DELETE_STAFF_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_STAFF_SUCCESS:
      return {
        ...state,
        staffdata: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_STAFF_SUCCESS:
      return {
        ...state,
        createstaff: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        updatestaff: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_STAFF_SUCCESS:
      return {
        ...state,
        deletestaff: action.payload,
        loading: false,
        error: null,
      };
    case GET_STAFF_FAILURE:
    case CREATE_STAFF_FAILURE:
    case UPDATE_STAFF_FAILURE:
    case DELETE_STAFF_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
