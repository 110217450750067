import axios from "axios";
import {
  CLEAR_ERRORS,
  LOGIN_FAILD,
  LOGIN_REQUIST,
  LOGIN_SUCCESS,
  FORGOT_PASSWORD_FAILD,
  FORGOT_PASSWORD_REQUEST,
  FORGOT_PASSWORD_SUCCESS,
  VERIFY_OTP_FAILD,
  VERIFY_OTP_REQUEST,
  VERIFY_OTP_SUCCESS,
  CONFIRM_OTP_FAILD,
  CONFIRM_OTP_REQUEST,
  CONFIRM_OTP_SUCCESS,
  loginFailure,
  loginSuccess,
} from "../Constants/AuthConstant";
import { BASE_URL } from "../Config/BaseUrl";

export const login = (values) => async (dispatch) => {
 
  try {
    dispatch({ type: LOGIN_REQUIST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(`${BASE_URL}user_login/`, values, config);
    dispatch({ type: LOGIN_SUCCESS, payload: data });
    return data
  } catch (error) {
    dispatch({
      type: LOGIN_FAILD,
      payload: error.response.data.message,
    });
  }
};

export const authLogin = (credentials) => {
  return async (dispatch) => {
    try {
      const res = await axios.post("/api/login", credentials);
      const { data } = res;
      if (data.status === 200) {
        const { token, user_type: userType } = data.temp_dict;
        localStorage.setItem("token", token);
        localStorage.setItem("User", JSON.stringify(userType));
        dispatch(loginSuccess(token, userType));
      } else {
        dispatch(
          loginFailure("Login failed. Please enter correct credentials.")
        );
      }
    } catch (error) {
      dispatch(loginFailure(error.message));
    }
  };
};

export const forgotPWD = (email) => async (dispatch) => {
  console.log(email);
  try {
    dispatch({ type: FORGOT_PASSWORD_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${BASE_URL}forgot_passowrd/`,
      { email: email },
      config
    );
    dispatch({ type: FORGOT_PASSWORD_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: FORGOT_PASSWORD_FAILD,
      payload: error.response.data.message,
    });
  }
};

export const verfOTP_ = (email, otp) => async (dispatch) => {
  console.log(email, otp);
  try {
    dispatch({ type: VERIFY_OTP_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${BASE_URL}verify_otp/`,
      { email: email, otp: otp },
      config
    );
    dispatch({ type: VERIFY_OTP_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: VERIFY_OTP_FAILD,
      payload: error.response.data.message,
    });
  }
};

export const cofOTP_ = (email, passward) => async (dispatch) => {
  console.log(email, passward);
  try {
    dispatch({ type: CONFIRM_OTP_REQUEST });
    const config = {
      headers: {
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${BASE_URL}confirm_verified_password/`,
      { email: email, passward: passward },
      config
    );
    dispatch({ type: CONFIRM_OTP_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: CONFIRM_OTP_FAILD,
      payload: error.response.data.message,
    });
  }
};

export const clearError = () => async (dispatch) => {
  dispatch({ type: CLEAR_ERRORS });
};
