import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";
import {
  ADD_PROSPECTUS_SUCCESS,
  DELETE_PROSPECTUS_SUCCESS,
  EDIT_PROSPECTUS_SUCCESS,
  GET_PROSPECTUS_SUCCESS,
  PROSPECTUS_FAIELD,
  PROSPECTUS_REQUIST,
} from "../../Constants/Admission/Prospectus";

const token = localStorage.getItem("token");

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const saveProspectus = (body, alert) => async (dispatch) => {
  try {
    dispatch({ type: PROSPECTUS_REQUIST });
    const response = await axios.post(
      `${BASE_URL}save_prospectus_details/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: ADD_PROSPECTUS_SUCCESS, payload: data });
    if (response.data.status === 200) {
      // alert.success(response.data.msg);
    } else {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(response.data);
        }
      });
    }
  } catch (error) {
    dispatch({ type: PROSPECTUS_FAIELD, payload: error });
  }
};

export const updateProspectus = (body, alert) => async (dispatch) => {
  try {
    dispatch({ type: PROSPECTUS_REQUIST });
    const response = await axios.post(
      `${BASE_URL}update_prospectus_details/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: EDIT_PROSPECTUS_SUCCESS, payload: data });
    if (response.data.status === 200) {
      // alert.success(response.data.msg);
    } else {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(response.data);
        }
      });
    }
  } catch (error) {
    dispatch({ type: PROSPECTUS_FAIELD, payload: error });
  }
};

export const deleteProspectus = (body) => async (dispatch) => {
  try {
    dispatch({ type: PROSPECTUS_REQUIST });
    const response = await axios.post(
      `${BASE_URL}delete_prospectus_details/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: DELETE_PROSPECTUS_SUCCESS, payload: data });
    if (response.data.status === 200) {
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
      }).then((result) => {
        console.log(result);
      });
    } else {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(response.data);
        }
      });
    }
  } catch (error) {
    dispatch({ type: PROSPECTUS_FAIELD, payload: error });
  }
};

export const viewProspectus = (body) => async (dispatch) => {
  try {
    dispatch({ type: PROSPECTUS_REQUIST });
    const response = await axios.post(
      `${BASE_URL}get_prospectus_details_list/`,
      body,
      config
    );
    const data = response.data;
    console.log(data.payload ,"/////////////??????????????????????");
    if (response.data.status === 200) {
      if(data.payload.length===0){
        Swal.fire({
          text: "No Data Avalable",
          icon: "warning",
          imageAlt: "warning image",
        })
      }else{
        dispatch({ type: GET_PROSPECTUS_SUCCESS, payload: data });
      }
    
      console.log(response.data);
    } else {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(response.data);
        }
      });
    }
  } catch (error) {
    dispatch({ type: PROSPECTUS_FAIELD, payload: error });
  }
};







