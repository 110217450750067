import {
  FETCH_SCHOOL_LOGO_REQUEST,
  FETCH_SCHOOL_LOGO_SUCCESS,
  FETCH_SCHOOL_LOGO_FAILURE,
} from "../actions/SchoolLogo/SchoolLogoActionTypes";

const initialState = {
  logoData: null,
  loading: false,
  error: null,
};

const schoolLogoReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_SCHOOL_LOGO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case FETCH_SCHOOL_LOGO_SUCCESS:
      return {
        ...state,
        logoData: action.payload,
        loading: false,
        error: null,
      };
    case FETCH_SCHOOL_LOGO_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default schoolLogoReducer;