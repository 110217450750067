import React, { useContext, useEffect, useState } from "react";
import { Card, CardBody, CardTitle, Col, Row } from "reactstrap";
import { SmallSelectField as SelectField } from "../../CommonComponent/SelectField";
import BarChart1 from "./BarChart";
import Event from "./Event";
import HolidayCarousel from "./Holiday";
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboard2Data,
  getDashboardData,
} from "../../../actions/Dashboard/Dashboard";
import useLocalStorageData from "../Comman/LocalStorageData";
import useLoading from "../../CommonComponent/useLoading";
import { SessionContext } from "../../../context/sessionContext";
import { useClass } from "../../../context/attendanceContext";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
import { DayList } from "../../../data/optionData";
import { SmallSelectField as SelectField1 } from "../../CommonComponent/SelectField1";
import {
  Notice,
  PresidantMessage,
  StudentBirthdayList,
  TestScheduleList,
  TopPerformers,
  Slideshow,
} from "./CustomCard";
import Loader from "../../Loader/Loader";
import TimeTableList from "./TimeTableList";
import { NavLink } from "react-router-dom";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";

import { TeacherInOutapis } from "../../../api/TeacherInOutAPI";
import Swal from "sweetalert2";

const TeacherDashboard = () => {
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const { user_type, name, user_detail } = schoolUser;
  const { user_type_dashboard, staff_id } = user_detail;
  // const session_id = useSessionRedirect();
  const { Session } = useContext(SessionContext);
  const session_id = Session || sessionStorage.getItem("SessionId");
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { dashboard, loading } = useSelector((state) => state.Dashboard);
  const { dashboard2 } = useSelector((state) => state.Dashboard2);
  const dispatch = useDispatch();
  const { allClassList, classListOption } = useClass();
  const [selectClass, setSelectClass] = useState(null);
  const [selectClassSection, setSelectClassSection] = useState(null);
  const [selectDay, setSelectDay] = useState("Monday");
  const [selectedTeacher, setSelectedTeacher] = useState("");
  const [TeacherDetails, setTeacherDetails] = useState([]);

  const [showLinks, setShowLinks] = useState(window.innerWidth <= 768);

  const [modal, setModal] = useState(false);
  const [currentMonth, setCurrentMonth] = useState("");
  useEffect(() => {
    const date = new Date();
    const year = date.getFullYear();
    const month = date.getMonth() + 1; // getMonth() returns month from 0 to 11
    setCurrentMonth(`${year}-${month}`);
  }, []);

  const toggle = () => setModal(!modal);

  useEffect(() => {
    setShowLinks(window.innerWidth <= 900);
  }, [window.innerWidth]);

  const {
    student_count,
    other_staffcount,
    teacher_count,
    holiday,
    staff_birthday,
    fees_summary_list,
    attendance_list,
    birth_daylist,
    upcomming_list,
    time_tabtable_list,
    banner_images,
    allpresent_count,
    allabsent_count,
    school_count,
    addmitated_student,
    organization_count,
    staff_count,
    school_profile_list,
    president_message,
    president_image,
    president_introduction,
    school_banner,
    teacher_yesterday_att_time,
    teacher_today_att_time,
  } = dashboard?.payload || [];

  const { circulars, eventactivity, top_performance } =
    dashboard2?.payload || [];

  useEffect(() => {
    let body = {
      school_id: school_id,
      academic_id: session_id,
      class_id: selectClass,
      section_id: selectClassSection,
      staff_id: staff_id,
      day: selectDay,
      user_type: user_type_dashboard,
    };
    dispatch(getDashboardData(body));
    dispatch(getDashboard2Data(body));
  }, [
    school_id,
    session_id,
    user_type_dashboard,
    selectClass,
    selectClassSection,
    staff_id,
    selectDay,
  ]);

  let feeSummary = attendance_list?.reduce(
    (acc, current) => {
      acc.attendance_date.push(current.attendance_date);
      acc.present.push(current.present);
      acc.absent.push(current.absent);
      acc.is_holiday.push(current.is_holiday);
      return acc;
    },
    { present: [], absent: [], attendance_date: [], is_holiday: [] }
  );
  console.log(feeSummary, "feeSummary");

  useEffect(() => {
    if (classListOption?.length > 0) {
      setSelectClassSection(
        selectClassSection ? selectClassSection : classListOption[0].value
      );
    }
    if (allClassList?.length > 0) {
      setSelectClass(selectClass ? selectClass : allClassList[0].value);
    }
  }, [classListOption, allClassList]);

  const GetTeacherAttendence = async () => {
    let data = {
      school_id: school_id,
      user_id: staff_id,
      attendence_month: "2024-06",
    };
    try {
      const response = await TeacherInOutapis.getAttendanceByTeacher(data);
      const resposnData = response.data;

      if (resposnData.status === 200) {
        setTeacherDetails(resposnData.leave_opening);
        toggle();
      } else {
        Swal.fire({
          text: resposnData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
    } finally {
    }
  };

  //   if (loading) return <Loader />;
  return (
    <div className="section-body">
      <div className="container-fluid">
        <div className="row mt-2">
          {showLinks && (
            <div className="col-12">
              {" "}
              <ul className="metismenu">
                <li>
                  <NavLink
                    to="/school/student-attendance"
                    style={{ fontWeight: "bold" }}
                  >
                    <i className={`text-info fa fa-users`}></i>
                    Mark Attendance
                    <hr className="m-1" />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/school/home-work"
                    style={{ fontWeight: "bold" }}
                  >
                    <i className={`text-info fa fa-book`}></i>
                    Home Work
                    <hr className="m-1" />
                  </NavLink>
                </li>
              </ul>
            </div>
          )}
          <div className="col-lg-8  col-sm-12">
            <Card>
              <CardBody>
                <>
                  <Row className="mb-2">
                    <Col lg={8} md={6} sm={6}>
                      <h3 className="card-title"> Attendance</h3>
                    </Col>
                    <Col lg={4} md={6} sm={6} className="dash-select">
                      <SelectField1
                        placeholder={"Select Class"}
                        options={classListOption}
                        value={selectClassSection}
                        onChange={(select) =>
                          setSelectClassSection(select.value)
                        }
                      />
                    </Col>
                  </Row>
                  <div>
                    <BarChart1
                      dataColors='["#59e0c5","#ffcb80" ,"#808080"]'
                      balance={feeSummary?.absent || []}
                      paid={feeSummary?.present || []}
                      heads_name={feeSummary?.attendance_date || []}
                      is_holiday={feeSummary?.is_holiday || []}
                      chartFor={"Attendance"}
                    />
                  </div>
                </>
              </CardBody>
            </Card>
            <Card>
              <CardBody>
                <CardTitle>Notice</CardTitle>
                <Notice circulars={circulars} />
              </CardBody>
            </Card>
          </div>
          <div className="col-lg-4  col-sm-12">
            {!showLinks && (
              <ul className="metismenu">
                <li>
                  <NavLink
                    to="/school/student-attendance"
                    style={{ fontWeight: "bold" }}
                  >
                    <i className={`text-info fa fa-users`}></i>
                    Mark Attendance
                    <hr className="m-1" />
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/school/home-work"
                    style={{ fontWeight: "bold" }}
                  >
                    <i className={`text-info fa fa-book`}></i>
                    Home Work
                    <hr className="m-1" />
                  </NavLink>
                </li>
              </ul>
            )}
            <Card className="">
              <CardBody>
                <h3 className="card-title">
                  Today's Birthday
                  <i className="fa fa-user-circle-o px-2"></i>(Student's)
                  <hr className="m-1" />
                </h3>

                <StudentBirthdayList data={birth_daylist} />

                <h5 className="card-title">
                  <i className="fa fa-bullhorn"></i> upcoming Holiday
                  <hr className="m-1" />
                </h5>
                {holiday && holiday.length > 0 ? (
                  <HolidayCarousel holiday={holiday} />
                ) : (
                  <EmptyListMessage
                    message={"Holiday Not Available !"}
                    Div="div"
                    size={"1.2rem"}
                  />
                )}

                <Event eventactivity={eventactivity} />
              </CardBody>
            </Card>
          </div>
        </div>
        <Row>
          <Col lg={6}>
            <Card>
              <CardBody>
                <div className="d-flex justify-content-between align-items-center mb-2">
                  <h3 className="card-title"> Top Performers </h3>
                  <div className="dash-select">
                    <SelectField1
                      placeholder={"Select Class"}
                      options={allClassList}
                      value={selectClass}
                      onChange={(select) => setSelectClass(select.value)}
                    />
                  </div>
                </div>
                <TopPerformers top_performance={top_performance} />
              </CardBody>
            </Card>

            <Card>
              <CardBody>
                <CardTitle>Upcoming Online Test</CardTitle>
                <TestScheduleList data={upcomming_list} />
              </CardBody>
            </Card>

            <div className="card">
              <div className="card-body">
                <div className="d-flex justify-content-between align-items-center">
                  <div className="card-title m-3 ">Attendence Overview</div>
                  <button
                    className="btn btn-primary"
                    onClick={GetTeacherAttendence}
                  >
                    {" "}
                    More Details ...
                  </button>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-title m-3">Yesterday</div>

                      <table className="table  table-hover table-bordered text-nowrap  text-center ">
                        <th style={{ fontSize: "12px" }}>In Time</th>
                        <th style={{ fontSize: "12px" }}>Out Time</th>
                        <tbody>
                          <tr>
                            <td>
                              {teacher_yesterday_att_time?.in_time === "" ? (
                                <span className="text-danger text-center">
                                  NA
                                </span>
                              ) : (
                                <span
                                  className={
                                    teacher_yesterday_att_time?.in_time
                                      ? "text-success text-center"
                                      : "text-muted text-center"
                                  }
                                >
                                  {teacher_yesterday_att_time?.in_time}
                                </span>
                              )}
                            </td>
                            <td>
                              {teacher_yesterday_att_time?.out_time === "" ? (
                                <span className="text-danger text-center">
                                  NA
                                </span>
                              ) : (
                                <span
                                  className={
                                    teacher_yesterday_att_time?.out_time
                                      ? "text-success text-center"
                                      : "text-muted text-center"
                                  }
                                >
                                  {teacher_yesterday_att_time?.out_time}
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="card">
                      <div className="card-title m-3">Today</div>

                      <table className="table  table-hover table-bordered text-nowrap  text-center ">
                        <th
                          style={{ fontSize: "12px" }}
                          className="text-center"
                        >
                          In Time{" "}
                        </th>
                        <th
                          style={{ fontSize: "12px" }}
                          className="text-center"
                        >
                          Out Time
                        </th>
                        <tbody>
                          <tr>
                            <td>
                              {teacher_today_att_time?.in_time === "" ? (
                                <span className="text-danger text-center">
                                  NA
                                </span>
                              ) : (
                                <span
                                  className={
                                    teacher_today_att_time?.in_time
                                      ? "text-success text-center"
                                      : "text-muted text-center"
                                  }
                                >
                                  {teacher_today_att_time?.in_time}
                                </span>
                              )}
                            </td>
                            <td>
                              {teacher_today_att_time?.out_time === "" ? (
                                <span className="text-danger text-center">
                                  NA
                                </span>
                              ) : (
                                <span
                                  className={
                                    teacher_today_att_time?.out_time
                                      ? "text-success text-center"
                                      : "text-muted text-center"
                                  }
                                >
                                  {teacher_today_att_time?.out_time}
                                </span>
                              )}
                            </td>
                          </tr>
                          <tr></tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <Modal isOpen={modal} toggle={toggle} size="lg" backdrop="static">
                <ModalHeader>Teacher In Out Details </ModalHeader>
                <ModalBody>
                  <div
                    className="table-responsive"
                    style={{ maxHeight: "400px", overflowY: "auto" }}
                  >
                    <table className="table table-bordered">
                      <thead>
                        <tr>
                          <th>Sr No</th>
                          <th>attendance date</th>
                          <th>In Time</th>
                          <th>Out Time</th>
                          <th>machine type</th>
                          <th>working Hours</th>
                        </tr>
                      </thead>
                      <tbody>
                        {TeacherDetails?.map((s, index) => (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{s.attendance_date || "--"}</td>
                            <td>{s.in_time || "--"}</td>
                            <td>{s.out_time || "--"}</td>
                            <td>{s.machine_type || "--"}</td>
                            <td>{s.working_hours || "--"}</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </ModalBody>
                <ModalFooter>
                  <Button color="secondary" onClick={toggle}>
                    Close
                  </Button>
                </ModalFooter>
              </Modal>
            </div>
          </Col>
          <Col lg={6}>
            <Card style={{ minHeight: "96%" }}>
              <CardBody>
                <CardTitle>Principal's Message</CardTitle>
                <PresidantMessage
                  presidentData={{
                    president_message,
                    president_image,
                    president_introduction,
                  }}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardBody>
                <Row className="mb-2">
                  <Col lg={6} md={6} sm={6}>
                    <h3 className="card-title"> Time-Table Summary </h3>
                  </Col>
                  <Col lg={3} md={3} sm={6}></Col>
                  <Col lg={3} md={3} sm={6} className="dash-select">
                    {" "}
                    <SelectField1
                      placeholder={"Select Day"}
                      options={DayList}
                      value={selectDay}
                      onChange={(select) => setSelectDay(select.value)}
                    />{" "}
                  </Col>
                </Row>
                <TimeTableList time_tabtable_list={time_tabtable_list} />
              </CardBody>
            </Card>
          </Col>
        </Row>
        {school_banner && school_banner.length > 0 && (
          <Row>
            <Col lg={12} md={12} sm={12}>
              <div>
                <div className="hh">
                  <Slideshow images={school_banner} />
                </div>
              </div>
            </Col>
          </Row>
        )}
      </div>
    </div>
  );
};

export default TeacherDashboard;
