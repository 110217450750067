import React, { useEffect } from "react";
import { Card, CardBody, CardHeader, Col, Row } from "reactstrap";
import { PiStudentBold } from "react-icons/pi";
import { CustomCard } from "./CustomCard";
import StudentImg from "../../../assets/image/img1.png";
import SchoolImg from "../../../assets/image/school.png";
import org from "../../../assets/image/organization.png";
import img3 from "../../../assets/image/staff.png";
import { getDashboardData } from "../../../actions/Dashboard/Dashboard";
import useLocalStorageData from "../Comman/LocalStorageData";
import { useDispatch, useSelector } from "react-redux";
import CustomScrollbar from "../../CommonComponent/PerfectScrollbar";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
import { NavLink, useNavigate } from "react-router-dom";
import { setActiveTabSR } from "../../../actions/TabAction/TabAction";
import { getTitle } from "../../CommonComponent/helper";

const AdminDashboard = () => {
  getTitle("Dashboard");
  const { school_id, token, school_emp_id, schoolUser } = useLocalStorageData();
  const { user_type, name, user_detail } = schoolUser;
  const { user_type_dashboard } = user_detail;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { dashboard } = useSelector((state) => state.Dashboard);
  const {
    student_count,
    other_staffcount,
    teacher_count,
    holiday,
    staff_birthday,
    fees_summary_list,
    attendance_list,
    birth_daylist,
    upcomming_list,
    time_tabtable_list,
    circulars,
    eventactivity,
    top_performance,
    banner_images,
    allpresent_count,
    allabsent_count,
    school_count,
    addmitated_student,
    organization_count,
    staff_count,
    school_profile_list,
  } = dashboard?.payload || [];
  useEffect(() => {
    let body = {
      school_id: "",
      academic_id: "",
      class_id: "",
      section_id: "",
      staff_id: "",
      day: "",
      user_type: "Super Admin",
    };
    dispatch(getDashboardData(body));
  }, [user_type_dashboard]);
  const handleNavigate = (schoolId) => {
    navigate(`/admin/school-details`, { state: { id: schoolId } });
  };
  return (
    <div className="section-body">
      <div className="container-fluid">
        <Row className="mt-3">
          <Col lg={3} md={6} sm={6}>
            <NavLink to="/admin/organization">
              <CustomCard
                heading={"Organizations"}
                count={organization_count}
                colorBg={"#DC8686"}
                nameClass={"bg-b-green"}
                img={org}
              />
            </NavLink>
          </Col>
          <Col lg={3}  md={6} sm={6}>
            <NavLink to="/admin/school-registration">
              <CustomCard
                heading={"Schools"}
                count={school_count}
                colorBg={"#F0DBAF"}
                nameClass={"bg-b-yellow "}
                img={SchoolImg}
              />{" "}
            </NavLink>
          </Col>
          <Col lg={3}  md={6} sm={6}>
            <CustomCard
              heading={"Students"}
              count={addmitated_student}
              colorBg={"#7ED7C1"}
              nameClass={"bg-b-pink"}
              img={StudentImg}
            />
          </Col>
          <Col lg={3} md={6}  sm={6}>
            <NavLink to="/admin/users">
              <CustomCard
                heading={"Staff"}
                count={staff_count}
                colorBg={"#7ED7C1"}
                nameClass={"bg-b-holiday"}
                img={img3}
              />{" "}
            </NavLink>
          </Col>
        </Row>
        <Row>
          <Col lg={6}  md={12} sm={12}>
            <Card>
              <CardHeader className="bg-transparent border-bottom">
                <div className="me-2">
                  <h5 className="card-title mb-0">Schools</h5>
                </div>
              </CardHeader>
              <CardBody>
                <CustomScrollbar
                  style={{
                    height: "45vh",
                    paddingRight: "1rem",
                    boxSizing: "content-box",
                  }}
                >
                  {school_profile_list?.length > 0 ? (
                    <table className="table table-sm table-hover table-bordered small-table text-nowrap">
                      <thead className="thead-light sticky-header">
                        <tr className="text-center">
                          <th>Sr No</th>
                          <th>School Name</th>
                          <th>Session </th>
                          <th>Total Staff </th>
                          <th>Status</th>
                        </tr>
                      </thead>
                      <tbody>
                        {school_profile_list?.map((sch, index) => (
                          <tr
                            key={index}
                            className="text-center clickable-row text-nowrap"
                            onClick={() => handleNavigate(sch.school_id)}
                          >
                            <td className="text-center">{index + 1}</td>
                            <td className="text-capitalize text-left">
                              <NavLink
                                key={index}
                                className="text-muted"
                                to={`/admin/school-details`}
                                state={{ id: sch.school_id }}
                                onClick={() =>
                                  dispatch(setActiveTabSR("Basic-info"))
                                }
                              >
                                {sch.school_name}
                              </NavLink>
                            </td>{" "}
                            <td>{sch.session_name}</td>
                            <td className="text-center">{sch.staff_count}</td>
                            <td>
                              <span
                                class={`${
                                  sch.status === "In-Complete"
                                    ? "text-danger"
                                    : "text-success"
                                }`}
                              >
                                {sch.status}
                              </span>
                              {sch.status === "In-Complete" && (
                                <>
                                  (
                                  {sch.pending_list?.length > 0 &&
                                    sch.pending_list?.map((p, i) => (
                                      <small key={i} style={{fontSize:"0.6rem"}}>
                                        {p}
                                        {i < sch.pending_list?.length - 1
                                          ? " , "
                                          : ""}
                                      </small>
                                    ))}
                                  )
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  ) : (
                    <EmptyListMessage
                      message={"Schools Not Available !"}
                      Div="div"
                      size={"1.2rem"}
                    />
                  )}
                </CustomScrollbar>
              </CardBody>
            </Card>
          </Col>
          <Col lg={6} sm={12}>
            <Card>
              <CardBody>
                <img src={banner_images} alt="banner" />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    </div>
  );
};

export default AdminDashboard;
