import {
  GET_CLASS_REQUEST,
  GET_CLASS_SUCCESS,
  GET_CLASS_FAILURE,
  GET_SECTION_REQUEST,
  GET_SECTION_SUCCESS,
  GET_SECTION_FAILURE,
  GET_STUDENT_REPORT_REQUEST,
  GET_STUDENT_REPORT_SUCCESS,
  GET_STUDENT_REPORT_FAILURE,
  BULK_STUDENT_UPLOAD_REQUEST,
  BULK_STUDENT_UPLOAD_SUCCESS,
  BULK_STUDENT_UPLOAD_FAILURE,
} from "../../Constants/Admission/StudentReport";

const initialState = {
  payload: null,
  // allClass: null,
  allSection: null,
  stduentReport: null,
  bulkStudent: null,
  loading: false,
  error: null,
};

export const getClass = (state = { allClass: [] }, action) => {
  switch (action.type) {
    case GET_CLASS_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CLASS_SUCCESS:
      return {
        ...state,
        allClass: action.payload,
        loading: false,
        error: null,
      };
    case GET_CLASS_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getSection = (state = initialState, action) => {
  switch (action.type) {
    case GET_SECTION_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_SECTION_SUCCESS:
      return {
        ...state,
        allSection: action.payload,
        loading: false,
        error: null,
      };
    case GET_SECTION_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getStudentAllReport = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_REPORT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_STUDENT_REPORT_SUCCESS:
      return {
        ...state,
        stduentReport: action.payload,
        loading: false,
        error: null,
      };
    case GET_STUDENT_REPORT_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const bulkStudentUpload = (state = initialState, action) => {
  switch (action.type) {
    case BULK_STUDENT_UPLOAD_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case BULK_STUDENT_UPLOAD_SUCCESS:
      return {
        ...state,
        bulkStudent: action.payload,
        loading: false,
        error: null,
      };
    case BULK_STUDENT_UPLOAD_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
