import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";
import {
    CREATE_STUDENT_SUBJECT_REQUIST,
    CREATE_STUDENT_SUBJECT_SUCCESS,
    CREATE_STUDENT_SUBJECT_FAIELD,
    SHOW_STUDENT_SUBJECT_REQUIST,
    SHOW_STUDENT_SUBJECT_SUCCESS,
    SHOW_STUDENT_SUBJECT_FAIELD,
} from "../../Constants/TeacherDesk/StudentSubject";
const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};


export const SaveStudentSubjectMapping = (body,handleLoader) => async (dispatch) => {
  try {     
    dispatch({ type: CREATE_STUDENT_SUBJECT_REQUIST });
    const response = await axios.post(
      `${BASE_URL}mapped_student_subject_wise/`,
      body,
      config
    );    
    const data = response.data;
    dispatch({ type: CREATE_STUDENT_SUBJECT_SUCCESS, payload: data });
    if (response.data.status === 200) {
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
        allowOutsideClick: false 
      }).then((result) => {
        console.log(result);
        handleLoader();
      });
    }else{
      handleLoader();
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
        allowOutsideClick: false 
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(response.data);
        }
      });
    }
  } catch (error) {
    dispatch({ type: CREATE_STUDENT_SUBJECT_FAIELD, payload: error });
    handleLoader();
  }
};


export const ShowStudentSubjectMapping = (body,setShowLoader) => async (dispatch) => {
    try {     
      dispatch({ type: SHOW_STUDENT_SUBJECT_REQUIST });
      const response = await axios.post(
        `${BASE_URL}get_subjectwise_map_student/`,
        body,
        config
      );       
      const data = response.data;
      dispatch({ type: SHOW_STUDENT_SUBJECT_SUCCESS, payload: data });
      if (response.data.status === 200) {
        setShowLoader();
        if(response.data?.student_list?.length===0){         
          Swal.fire({
            text: "No Student data available.",
            icon: "warning",
            imageAlt: "warning image",
            allowOutsideClick: false 
          }).then((result) => {
            if (result.isConfirmed) {
              console.log(response.data);
            }
          });
        }
      }else{
        setShowLoader();
        Swal.fire({
          text: "No Student data available.",
          icon: "warning",
          imageAlt: "warning image",
          allowOutsideClick: false 
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(response.data);
          }
        });
      }
    } catch (error) {
      setShowLoader();
      dispatch({ type: SHOW_STUDENT_SUBJECT_FAIELD, payload: error });
    }
  };