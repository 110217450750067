import {
  GET_MODULE_FAILD,
  GET_MODULE_REQUIST,
  GET_MODULE_SUCCESS,
} from "../../Constants/Common";

const initialState = {
  moduleList: [],
  loading: false,
};

export const commonModuleReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_MODULE_REQUIST:
      return {
        loading: true,
      };

    case GET_MODULE_SUCCESS:
      return {
        ...state,
        loading: false,
        moduleList: action.payload,
      };

    case GET_MODULE_FAILD:
      return {
        ...state,
        loading: false,
        moduleList: null,
        error: action.payload,
      };

    default:
      return state;
  }
};
