import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import {
  getStudentName,
  getspecialFeesTypes,
  specialfeesReceipt,
} from "../../../actions/Admission/AdmissionAction";
import Select from "react-select";
import PrintSpecialFeeRecipt from "./PrintSpecialFeeRecipt";
import { Modal } from "reactstrap";
import Swal from "sweetalert2";
import moment from "moment";
import { useAlert } from "react-alert";
import { ReceiptAPI } from "../../../api/Admission/cancelReceiptAPI";
import StudentSessionWiseList from "../Comman/StudentListSessionWise";
import useSessionRedirect from "../Comman/SessionChange";
import { pay_mode } from "../../../data/optionData";
import FeeReceiptList from "./FeeReceiptList";
// const { logoData, loading, error } = useSelector((state) => state.schoolLogo);

const SpecialFee = () => {
  const token = localStorage.getItem("token");
  const { uid } = useParams();
  console.log(uid)
  const [FeesTable, setFeesTable] = useState();
  const [student_uid, setstudent_uid] = useState(uid);
  const [receiptDate, setreceiptDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  let s_id = user_detail?.fk_school_id;
  const { school_emp_id, fk_school_id } = user_detail;

  const session_id = useSessionRedirect();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();

  const { studentName } = useSelector((state) => state.studentName);
  const { feesReceipt } = useSelector((state) => state.specialfeesReceipt);
  const { specialFeesTypes } = useSelector(
    (state) => state.getspecialFeesTypes
  );
  const [receiptType, setReceiptType] = useState("New");
  const [receiptData, setReceiptData] = useState([]);
  const [selectFeeType, setSelectFeeType] = useState();
  const [isEditReceipt, setIsEditReceipt] = useState([]);
  const [PaymentMode, setPaymentMode] = useState();

  const FeeTypeList = specialFeesTypes?.payload?.map((item) => { 
    return {
      label: item.value,
      value: item.id,
    };
  });

  function handleFeeType(data) {
    setSelectFeeType(data);
  }

  const PaymentList = pay_mode?.map((item) => {
    return {
      label: item.label,
      value: item.value,
    };
  });
  function handlePaymentMode(data) {
    setPaymentMode(data);
  }

  let idS = studentName?.payload.id;
  let nameS = studentName?.payload.name;
  let receiptS = feesReceipt?.special_fee_code;
  if (student_uid === "") {
    nameS = "";
    receiptS = "";
    idS = "";
  } else if (student_uid !== studentName?.payload.student_code) {
    nameS = "";
    receiptS = "";
    idS = "";
  }

  function getName() {
    const body = {
      fk_academic: session_id,
      student_code: student_uid,
      school_id: Number(user_detail.fk_school_id),
      for_type: "Admission",
    };
    dispatch(getStudentName(body, handleClear));
    dispatch(getspecialFeesTypes(body));
  }

  useEffect(() => {
    getName();
  }, [uid, student_uid]);

  useEffect(() => {
    if (nameS) {
      setstudent_uid("");
    }
  }, [session_id]);
  const fetchData = async () => {
    const body = {
      fk_academic: session_id,
      receipt_no: "",
      fk_school: Number(user_detail.fk_school_id),
    };
    try {
      const response = await ReceiptAPI.getAllSpecialReceipt(body);
      setReceiptData(response.data.payload);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchData();
  }, [session_id]);

  const [paymentInfo, setPaymentInfo] = useState({
    payment_mode: "Cash",
    total_amount: 0,
    fine_amount: 0,
    paid_amount: 0,
    bank_name: "",
    pay_no: "",
    pay_date: "",
    remark: "",
  });

  const handleInputChange = (index, field, value) => {
    const newData = [...FeesTable];
    newData[index][field] = value;
    setFeesTable(newData);
  };

  const saveSpecialFees = async () => {
    if (receiptType === "Edit") {
      let body = {
        receipt_id: isEditReceipt?.id,
        payment_mode: paymentInfo.payment_mode,
        bank_name: paymentInfo.bank_name,
        pay_no: paymentInfo.pay_no,
        pay_date: paymentInfo.pay_date,
        remark: paymentInfo.remark,
        insert_by: school_emp_id,
        total_amount: paymentInfo.paid_amount,
        fine_amount: paymentInfo.fine_amount,
        paid_amount: paymentInfo.total_amount,
      };

      const res = await ReceiptAPI.updatespecialReceipt(body);
      if (res.data.status === 200) {
        Swal.fire({
          text: res.data.msg,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            handleClear();
            setReceiptType("New");
            setIsEditReceipt([]);
          }
    
        });
      } else if (res.data.status === 403) {
        Swal.fire({
          text: res.data.msg,
          icon: "error",
          imageAlt: "success image",
          allowOutsideClick: false,
        });
      }
    } else {
      let body = {
        fk_academic: session_id,
        student_id: idS,
        fee_id: selectFeeType?.value,
        school_id: Number(user_detail.fk_school_id),
        receipt_no: receiptS,
        receipt_date: receiptDate,
        payment_mode: paymentInfo.payment_mode,
        fine_amount: paymentInfo.fine_amount,
        bank_name: paymentInfo.bank_name,
        pay_no: paymentInfo.pay_no,
        pay_date: paymentInfo.pay_date,
        remark: paymentInfo.remark,
        insert_by: school_emp_id,
        paid_amount: paymentInfo.total_amount,
        total_amount: paymentInfo.paid_amount,
        // total_amount: paymentInfo.total_amount,
        // paid_amount: paymentInfo.paid_amount,
      };
      await dispatch(specialfeesReceipt(body, alert));
    }
  };

  const annual_fee = [];
  const Sum_annual_fee = () => {
    const sum = annual_fee.reduce((acc, current) => acc + current, 0);
    return sum;
  };

  const [btnClick, setbtnClick] = useState(true);
  useEffect(() => {
    if (
      nameS &&
      student_uid
      // receiptS &&
      // receiptDate &&
      // paymentInfo.total_amount &&
      // paymentInfo.paid_amount &&
      // paymentInfo.payment_mode &&
      // school_user &&
      // studentName
    ) {
      setbtnClick(false);
    }
  }, []);

  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => {
    fetchData();
    setModal(!modal);
    setSearchResults([]);
    setNoResultsMessage("");
  };

  const handleStudentEdit = (data) => {
    setstudent_uid(data?.student_code);
    getName();
    setReceiptType("Edit");
    setIsEditReceipt(data);
    setPaymentInfo({
      payment_mode: data?.payment_mode,
      // total_amount: data?.total_amount,
      // paid_amount: data?.paid_amount,
      total_amount: data?.paid_amount,
      paid_amount: data?.total_amount,
      fine_amount: data?.fine_amount,
      bank_name: data?.bank_name,
      pay_no: data?.pay_no,
      pay_date: data?.pay_date,
      remark: data?.remark,
    });
    setPaymentMode({
      label: data?.payment_mode,
      value: data?.payment_mode,
    });
    let feeType = FeeTypeList.find((f) => f.label === data.Consession);
    setSelectFeeType(feeType);
    setModal(!modal);
    // FeesDetails();
  };

  const handleClear = () => {
    setstudent_uid("");
    setPaymentInfo({
      payment_mode: "Cash",
      total_amount: 0,
      fine_amount: 0,
      paid_amount: 0,
      bank_name: "",
      pay_no: "",
      pay_date: "",
      remark: "",
    });
    setSelectFeeType("");
    setReceiptType("New");
    setIsEditReceipt([]);
  };

  function convertDate(data) {
    const parsedDate = moment(data, "YYYY-MM-DD");
    const formattedDate = parsedDate.format("DD-MM-YYYY");
    // const parts = data.split("-");
    // const formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    return formattedDate;
  }
  const CancelReceipt = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    Swal.fire({
      title: "CONFIRMATION",
      text: "Do you want to Cancel Receipt?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { value: cancelReason } = await Swal.fire({
          icon: "question",
          title: "Enter Cancel Reason ?",
          input: "text",
          inputPlaceholder: "Enter your cancel reason",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          allowOutsideClick: false,
        });

        if (cancelReason) {
          let body = {
            student_fees_id: isEditReceipt?.id,
            school_emp_id: school_emp_id,
            cancel_date: formattedDate,
            cancel_reason: cancelReason,
            insert_by: school_emp_id,
          };
          const res = await ReceiptAPI.cancelspecialReceipt(body);
          if (res.data.status === 200) {
            Swal.fire({
              text: res.data.msg,
              icon: "success",
              imageAlt: "success image",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                handleClear();
                setReceiptType("New");
                setIsEditReceipt([]);
              }
            });
          } else if (res.data.status === 403) {
            Swal.fire({
              text: res.data.msg,
              icon: "error",
              imageAlt: "success image",
              allowOutsideClick: false,
            });
          }
        }
      }
    });
  };
  return (
    <div>
      <div class="section-body">
        <div class="container-fluid">
          <div class="d-flex justify-content-between align-items-center">
            <div class="header-action">
              <h1 class="page-title">Special Fee Receipt</h1>
            </div>
            <div className="input-group-append">
              {receiptType === "New" ? null : (
                <button
                  type="button"
                  className="btn btn-danger mx-2"
                  onClick={() => CancelReceipt()}
                >
                  Cancel
                </button>
              )}
              <button
                type="button"
                className="btn btn-outline-danger mx-2"
                onClick={handleClear}
              >
                {" "}
                <i className="fa fa-times px-1"></i>
                CLEAR
              </button>
              <button
                className="btn btn-outline-secondary"
                type="button"
                onClick={toggle}
                // disabled
              >
                <i className="fa fa-search"> </i> Search
              </button>
            </div>
          </div>
        </div>
      </div>
      <br />
      <div className="container-fluid">
        <div className="card">
          <div className="card-body">
            <div className="row">
              <div className="col-lg-8">
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <label className="col-form-label mx-2">
                          Student Code <span className="text-danger">*</span>
                        </label>
                        <div className="input-group">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Student Code"
                            value={student_uid}
                            onChange={(e) => setstudent_uid(e.target.value)}
                          />
                          <StudentSessionWiseList
                            setstudent_uid={setstudent_uid}
                          />
                          {/* <div className="input-group-append">
                            <button
                              className="btn btn-outline-secondary"
                              type="button"
                              onClick={() => toggle()}
                            >
                              <i className="fa fa-search"> </i>
                            </button>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-lg-8 col-md-4 col-sm-6">
                        <label className="col-form-label mx-2">
                          Student Name <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Name"
                          disabled
                          value={nameS}
                        />
                      </div>
                      {/* <div className="col-lg-4 col-md-4 col-sm-6 d-none">
                        <label className="col-form-label mx-2">
                          Receipt No <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Receipt No"
                          disabled
                          value={receiptS}
                        />
                      </div> */}
                    </div>

                    <div className="row">
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <label className="col-form-label mx-2">
                          Receipt Date <span className="text-danger">*</span>
                        </label>
                        <input
                          type="date"
                          className="form-control"
                          value={receiptDate}
                          onChange={(e) => setreceiptDate(e.target.value)}
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <label className="col-form-label mx-2">
                          Receipt No <span className="text-danger">*</span>
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Receipt No"
                          value={isEditReceipt?.receipt_no || ""}
                          // onChange={(e) => setreceiptDate(e.target.value)}
                          readOnly
                        />
                      </div>
                      <div className="col-lg-4 col-md-4 col-sm-6">
                        <label className="col-form-label mx-2">
                          Special Fees <span className="text-danger">*</span>
                        </label>
                        <Select
                          options={FeeTypeList}
                          placeholder="Select Special Fees"
                          value={selectFeeType}
                          onChange={handleFeeType}
                          isSearchable={true}
                          className="form-control m-0 p-0"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-4">
                <div className="card">
                  <div className="card-body">
                    <h6 className="page-title">Payment Information</h6>
                    <div className="d-flex py-1">
                      <label className="givenWigth m-auto">
                        Payment Mode <span className="text-danger">*</span>
                      </label>
                      <Select
                        options={PaymentList}
                        placeholder="Payment Mode"
                        value={PaymentMode}
                        defaultValue={PaymentList[0]}
                        onChange={(event) => {
                          handlePaymentMode(event);
                          setPaymentInfo((prevState) => ({
                            ...prevState,
                            payment_mode: event.value,
                          }));
                        }}
                        isSearchable={true}
                        className="form-control m-0 p-0"
                      />
                    </div>
                    {paymentInfo.payment_mode === "Cash" ? null : (
                      <>
                        {paymentInfo.payment_mode === "UPI" ? null : (
                          <div className="d-flex py-1">
                            <label className="givenWigth m-auto">Bank Name</label>
                            <input
                              disabled={
                                PaymentMode?.value === "Cash" ? true : false
                              }
                              className="form-control"
                              type="text"
                              placeholder="Bank Name"
                              value={paymentInfo.bank_name}
                              onChange={(event) =>
                                setPaymentInfo((prevState) => ({
                                  ...prevState,
                                  bank_name: event.target.value,
                                }))
                              }
                            />
                          </div>
                        )}
                        <div className="d-flex py-1">
                          <label className="givenWigth m-auto">
                            {paymentInfo.payment_mode === "Bank"
                              ? "Account"
                              : paymentInfo.payment_mode}{" "}
                            Number
                          </label>
                          <input
                            disabled={
                              PaymentMode?.value === "Cash" ? true : false
                            }
                            className="form-control"
                            type="number"
                            placeholder={
                              paymentInfo.payment_mode === "Bank"
                                ? "Account Number"
                                : paymentInfo.payment_mode + " Number"
                            }
                            value={paymentInfo.pay_no}
                            onChange={(event) =>
                              setPaymentInfo((prevState) => ({
                                ...prevState,
                                pay_no: event.target.value,
                              }))
                            }
                          />
                        </div>
                        <div className="d-flex py-1">
                          <label className="givenWigth m-auto">Payment Date</label>
                          <input
                            disabled={
                              PaymentMode?.value === "Cash" ? true : false
                            }
                            className="form-control"
                            type="date"
                            placeholder="Payment Date"
                            value={paymentInfo.pay_date}
                            onChange={(event) =>
                              setPaymentInfo((prevState) => ({
                                ...prevState,
                                pay_date: event.target.value,
                              }))
                            }
                          />
                        </div>
                      </>
                    )}
                    <div className="d-flex py-1">
                      <label className="givenWigth m-auto">
                        Total Amount <span className="text-danger">*</span>
                      </label>
                      <input
                        className="form-control text-right"
                        type="number"
                        placeholder="Total Amount"
                        value={paymentInfo.total_amount}
                        onChange={(event) => {
                          if (/^-?\d*(\.\d*)?$/.test(event.target.value)) {
                            const numericValue = Number(event.target.value);
                            // if (numericValue <= paymentInfo.total_amount) {
                            if (numericValue < 0) {
                              alert.info("Amount can not be less than zero");
                            } else {
                              setPaymentInfo((prevState) => ({
                                ...prevState,
                                total_amount: numericValue.toString(),
                              }));
                            }
                            // } else {
                            //   alert.info(
                            //     "Discount can not be greater than Total Amount"
                            //   );
                            // }
                          }
                        }}
                      />
                    </div>
                    <div className="d-flex py-1">
                      <label className="givenWigth m-auto">Fine Amount</label>
                      <input
                        className="form-control text-right"
                        type="number"
                        placeholder="Fine Amount"
                        value={paymentInfo.fine_amount}
                        onChange={(event) => {
                          if (/^-?\d*(\.\d*)?$/.test(event.target.value)) {
                            const numericValue = Number(event.target.value);
                            // if (numericValue <= paymentInfo.total_amount) {
                            if (numericValue < 0) {
                              alert.info("Amount can not be less than zero");
                            } else {
                              setPaymentInfo((prevState) => ({
                                ...prevState,
                                fine_amount: numericValue.toString(),
                              }));
                            }
                            // } else {
                            //   alert.info(
                            //     "Discount can not be greater than Total Amount"
                            //   );
                            // }
                          }
                        }}
                      />
                    </div>

                    <div className="d-flex py-1">
                      <label className="givenWigth m-auto">Paid Amount</label>
                      <input
                        disabled
                        className="form-control text-right"
                        type="number"
                        placeholder="Paid Amount"
                        value={
                          (paymentInfo.paid_amount =
                            Number(paymentInfo.total_amount) +
                            Number(paymentInfo.fine_amount))
                        }
                        onChange={(event) =>
                          setPaymentInfo((prevState) => ({
                            ...prevState,
                            paid_amount: event.target.value,
                          }))
                        }
                      />
                    </div>
                    <div className="d-flex py-1">
                      <label className="givenWigth m-auto">Remark</label>
                      <input
                        className="form-control"
                        type="text"
                        placeholder="Remark"
                        value={paymentInfo.remark}
                        onChange={(event) =>
                          setPaymentInfo((prevState) => ({
                            ...prevState,
                            remark: event.target.value,
                          }))
                        }
                      />
                    </div>
                    <PrintSpecialFeeRecipt
                      saveSpecialFees={saveSpecialFees}
                      name={nameS}
                      code={student_uid}
                      receiptNo={receiptS}
                      receiptDate={receiptDate}
                      selectFeeType={selectFeeType?.label}
                      paymentInfo={paymentInfo}
                      schoolUser={school_user}
                      session={studentName?.payload?.academic_year}
                      classSec={
                        studentName?.payload?.std_class +
                        " " +
                        studentName?.payload?.section
                      }
                      // btnClick={btnClick}
                      convertDate={convertDate}
                      navigate={navigate}
                      handleClear={handleClear}
                      alert={alert}
                      receiptType={receiptType}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box" backdrop="static" keyboard={false}>
        <FeeReceiptList
          toggle={toggle}
          receiptData={receiptData}
          noResultsMessage={noResultsMessage}
          setNoResultsMessage={setNoResultsMessage}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          handleStudentEdit={handleStudentEdit}
        />
      </Modal>
    </div>
  );
};

export default SpecialFee;
