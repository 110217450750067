import React, { useContext, useEffect, useState } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { SessionContext } from "../../../../context/sessionContext";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import {
  EditClassTest,
  addQuestion,
  deleteQuestion,
  getQuestion,
  TestStatusChange,
} from "../../../../actions/MockTest/MockTest";
import {
  getChaptertList,
  getClassWiseSubjectList,
} from "../../../../actions/school_user/contentUploader";
import { useFormik } from "formik";
import Loader from "../../../Loader/Loader";
import * as Yup from "yup";
import {
  Col,
  Row,
  Form,
  Input,
  Button,
  FormFeedback,
  Container,
} from "reactstrap";

import Swal from "sweetalert2";
import ShowTestQuestion from "./ShowTestQuestion";
import { Heading } from "../../../CommonComponent/heading";

const TestEditPage = () => {
  const { state } = useLocation();
  const {
    id,
    exam_code,
    class_id,
    class_name,
    subject_id,
    subject_name,
    chapter_id,
    chapter_name,
    test_type,
    test_name,
    no_of_question,
    duration,
    start_datetime,
    total_marks,
    end_datetime,
    eachquemark,
    is_active,
    isdraft,
    created_date,
  } = state.test;
  const token = localStorage.getItem("token");

  const { Session } = useContext(SessionContext);
  let session_id = Session || sessionStorage.getItem("SessionId");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id } = school_user?.user_detail;
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { subject, chapter, contentData } = useSelector(
    (state) => state.contentUploader
  );
  const { mockTest } = useSelector((state) => state.createMockTest);
  const { mockTestQuestion } = useSelector((state) => state.addQuestion);
  const { getTestQuestion } = useSelector((state) => state.getQuestion);
  const { deleteTestQuestion } = useSelector((state) => state.deleteQuestion);
  const { allClass } = useSelector((state) => state.getClass);
  const [selectClass, setSelectClass] = useState(class_id);
  const [selectSubject, setSelectSubject] = useState(0);
  const [selectChapter, setSelectChapter] = useState(0);
  const [subjectList, setSubjectList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [currentFileName1, setCurrent1FileName] = useState("");
  const [currentFileName2, setCurrent2FileName] = useState("");
  const [currentFileName3, setCurrent3FileName] = useState("");
  const [currentFileName4, setCurrent4FileName] = useState("");
  const [currentFileName5, setCurrent5FileName] = useState("");
  const [showValidationError, setValidationError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const [optionCount, setOptionCount] = useState(1);
  const [selectedExamType, setSelectedExamType] = useState("");
  const [correctAnswer, setCorrectAnswer] = useState("");
  const questionTypeList = [
    { label: "Single Correct Answer", value: "Single Correct Answer" },
    { label: "True or False", value: "True or False" },
    { label: "Fill in the Blanks", value: "Fill in the Blanks" },
    { label: "Multiple Correct Answer", value: "Multiple Correct Answer" },
  ];

  let cl_subId = {
    academic_id: session_id,
    school_id: school_id,
    class_id: selectClass,
    employee_id: school_emp_id,
  };

  useEffect(() => {
    if (id > 0) {
      setAddQuestions(true);
      setDisabled(true);
    }
  }, [id]);

  useEffect(() => {
    if (token && selectClass) {
      setChapterList([]);
      dispatch(getClassWiseSubjectList(token, cl_subId));
    }
  }, [dispatch, token, selectClass]);

  useEffect(() => {
    let List = subject?.payload?.map((c) => {
      return {
        label: `${c.subject_name}`,
        value: c.id,
      };
    });
    setSubjectList(List);
    if (subject) {
      setSelectSubject(subject_id);
      setCreate({
        ...Create,
        subject_id: subject_id,
      });
    }
  }, [subject]);

  useEffect(() => {}, []);

  useEffect(() => {
    let List = chapter?.payload?.map((c) => {
      return {
        label: `${c.name}`,
        value: c.id,
      };
    });
    setChapterList(List);
    if (chapter) {
      setSelectChapter(chapter_id);
      setCreate({
        ...Create,
        chapter_id: chapter_id,
      });
    }
  }, [chapter]);

  let cdata = {
    school_id: school_id,
    class_id: selectClass,
    subject_id: selectSubject,
  };
  useEffect(() => {
    if (selectSubject) {
      dispatch(getChaptertList(cdata));
    }
  }, [selectSubject]);

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

  useEffect(() => {
    setCreate({
      ...Create,
      academic_id: session_id,
    });
  }, [session_id]);

  

  let handleChangeClass = (select) => {
    setSelectClass(select.value);
    setSelectSubject(0);
    setSelectChapter(0);
  };
  const [Create, setCreate] = useState({
    test_id: id,
    employee_id: school_emp_id,
    academic_id: session_id,
    school_id: school_id,
    class_id: selectClass,
    subject_id: selectSubject,
    chapter_id: selectChapter,
    test_name: test_name || "",
    no_of_question: no_of_question || "",
    duration: duration || 0,
    start_date: new Date(start_datetime).toISOString().substr(0, 10),
    start_time: convertTime(start_datetime),
    test_type: test_type || "Mock",
    insert_by: school_emp_id,
    total_marks: 0,
  });

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setCreate((values) => ({ ...values, [name]: value }));
  };

  const handleCreateTest = async (e) => {
    e.preventDefault();
    try {
      await validatecontrol();
    } catch (error) {
      console.log(error);
    }
  };

  const handleUnableTest = async (e) => {
    e.preventDefault();
    try {
      setAddQuestions(false);
      setDisabled(false);
    } catch (error) {
      console.log(error);
    }
  };

  async function validatecontrol() {
    let valStatus = true;
    if (Create.test_name == "") {
      valStatus = false;
    }
    if (Create.no_of_question == "" || Create.no_of_question === 0) {
      valStatus = false;
    }
    if (Create.class_id === 0) {
      valStatus = false;
    }
    if (Create.subject_id === 0) {
      valStatus = false;
    }
    if (Create.chapter_id === 0) {
      valStatus = false;
    }
    if (Create.start_date === "") {
      valStatus = false;
    }
    if (Create.start_time === "") {
      valStatus = false;
    }

    const { no_of_question } = { ...Create };

    if (questionCount > no_of_question) {
      Swal.fire({
        text: "No. of Question must be greater than or equal to added question.",
        icon: "info",
        allowOutsideClick: false,
      });
      valStatus = false;
    }

    // if(questionCount>)

    if (!valStatus) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 5000);
    } else {
      setShowLoader(true);
      const res = await dispatch(EditClassTest(Create, handleLoader));
      setAddQuestions(true);
      setDisabled(true);
    }
  }

  const [Disabled, setDisabled] = useState(false);
  const [AddQuestions, setAddQuestions] = useState(false);
  const [QuizId, setQuizId] = useState(mockTest?.test_id);
  const [selectedOption, setSelectedOption] = useState(null);
  const [singleChoice, setSingleChoice] = useState(false);
  const [trueFalseValid, setTrueFalseValid] = useState(false);
  const [fileIBValid, setfileIBValid] = useState(false);
  const [questionCount, setQuestionCount] = useState(0);
  const [op1valid, setOp1valid] = useState("");
  const [op2valid, setOp2valid] = useState("");
  const [op3valid, setOp3valid] = useState("");
  const [op4valid, setOp4valid] = useState("");
  const [op5valid, setOp5valid] = useState("");
  const [fop1valid, setfOp1valid] = useState(false);
  const [fop2valid, setfOp2valid] = useState(false);
  const [fop3valid, setfOp3valid] = useState(false);
  const [fop4valid, setfOp4valid] = useState(false);
  const [fop5valid, setfOp5valid] = useState(false);
  const [fileData, setFileData] = useState("");
  const [Answer, setAnswer] = useState("");
  const [question, setquestion] = useState("");
  const [ViewQuestion, setViewQuestion] = useState([]);
  const [OptionImage, setOptionImage] = useState(false);
  const [arrayIndex, setArrayIndex] = useState(0);
  const [testStatus, setTestStatus] = useState(is_active);

  useEffect(() => {
    if (id) {
      viewDraftQuestion();
    }
  }, [id, questionCount]);

  const [user, setUserLogged] = useState(
    JSON.parse(localStorage.getItem("User"))
  );

  const handleOptionChange = (event) => {
    if (selectedExamType !== "Multiple Correct Answer") {
      setAnswer(event.target.value);
    } else {
      if (Answer.indexOf(event.target.value) !== -1) {
        const ans = Answer.split(",");
        const newans = ans.filter((x) => x !== event.target.value);
        setAnswer(newans.join(","));
      } else {
        if (Answer === "") {
          setAnswer(event.target.value);
        } else {
          setAnswer((Answer) => Answer + "," + event.target.value);
        }
      }
    }
  };
  const handleFileRead = async (event) => {
    const file = event.target.files[0];
    const base64 = await convertBase64(file);
    setFileData(base64.split("base64,")[1]);
  };

  const handleLoader = () => {
    setShowLoader((showLoader) => !showLoader);
  };

  const optionFileRead = async (event, imageoption) => {
    const file = event.target.files[0];
    if (file.size <= 524288) {
      const base64 = await convertBase64(file);
      if (imageoption == "1") {
        setOp1valid(base64);
      } else if (imageoption == "2") {
        setOp2valid(base64);
      } else if (imageoption == "3") {
        setOp3valid(base64);
      } else if (imageoption == "4") {
        setOp4valid(base64);
      } else if (imageoption == "5") {
        setOp5valid(base64);
      }
      setOptionImage(true);
    } else {
      Swal.fire({
        text: "Please select less than 500kb image",
        icon: "warning",
        imageAlt: "warning image",
      }).then((e) => {
        if (!op1valid) {
          setOp1valid("");
          // setfOp1valid(false)
        } else if (!op2valid) {
          setOp2valid("");
          // setfOp2valid(false)
        } else if (!op3valid) {
          setOp3valid("");
          // setfOp3valid(false)
        } else if (!op4valid) {
          setOp4valid("");
          // setfOp4valid(false)
        } else if (!op5valid) {
          setOp5valid("");
          // setfOp4valid(false)
        }
        event.target.value = null;
      });
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleAddQuestion = async () => {
    let setval = true;

    const values = {
      test_id: id,
      single_choice: singleChoice ? "True" : "False",
      fillinblanks: fileIBValid ? "True" : "False",
      true_false: trueFalseValid ? "True" : "False",
      question: question,
      option_data: [],
      question_marks:0,
      insert_by: school_emp_id,
      question_type: selectedExamType,
      explanation: correctAnswer,
      answer_count: Answer.indexOf(",") > -1 ? Answer.split(",").length : 1,
    };

    if (question == "") {
      setval = false;
    } else if (Answer == "") {
      setval = false;
    } else if (correctAnswer == "") {
      setval = false;
    } else if (selectedExamType == "") {
      setval = false;
    }
    if (setval == true) {
      if (optionCount === 1) {
        Swal.fire({
          text: "Mininum 2 options required.",
          icon: "warning",
          imageAlt: "warning image",
          allowOutsideClick: false,
        }).then((result) => {
          return;
        });
      } else if (selectedExamType === "Multiple Correct Answer") {
        const ans = Answer.split(",");

        if (ans.length < 2) {
          Swal.fire({
            text: "Multiple Correct Answer required minimum 2 correct answer.",
            icon: "warning",
            imageAlt: "warning image",
            allowOutsideClick: false,
          }).then((result) => {
            return;
          });
        }
      } else {
        if (optionCount == 1) {
          if (op1valid == "") {
            setval = false;
          }
        } else if (optionCount == 2) {
          if (op1valid == "") {
            setval = false;
          } else if (op2valid == "") {
            setval = false;
          }
        } else if (optionCount == 3) {
          if (op1valid == "") {
            setval = false;
          } else if (op2valid == "") {
            setval = false;
          } else if (op3valid == "") {
            setval = false;
          }
        } else if (optionCount == 4) {
          if (op1valid == "") {
            setval = false;
          } else if (op2valid == "") {
            setval = false;
          } else if (op3valid == "") {
            setval = false;
          } else if (op4valid == "") {
            setval = false;
          }
        } else if (optionCount == 5) {
          if (op1valid == "") {
            setval = false;
          } else if (op2valid == "") {
            setval = false;
          } else if (op3valid == "") {
            setval = false;
          } else if (op4valid == "") {
            setval = false;
          } else if (op5valid == "") {
            setval = false;
          }
        }
      }
    }

    if (setval === false) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 5000);
    } else {
      const optionValueData = [];
      let obj = {};

      if (selectedExamType === "Multiple Correct Answer") {
        if (optionCount >= 2) {
          obj = {
            option: !fop1valid ? op1valid : "",
            is_correct: "False",
            file: fop1valid ? op1valid : "",
          };
          optionValueData.push(obj);
          obj = {
            option: !fop2valid ? op2valid : "",
            is_correct: "False",
            file: fop2valid ? op2valid : "",
          };
          optionValueData.push(obj);
        }
        if (optionCount >= 3) {
          obj = {
            option: !fop3valid ? op3valid : "",
            is_correct: "False",
            file: fop3valid ? op3valid : "",
          };
          optionValueData.push(obj);
        }
        if (optionCount >= 4) {
          obj = {
            option: !fop4valid ? op4valid : "",
            is_correct: "False",
            file: fop4valid ? op4valid : "",
          };
          optionValueData.push(obj);
        }
        if (optionCount == 5) {
          obj = {
            option: !fop5valid ? op5valid : "",
            is_correct: "False",
            file: fop5valid ? op5valid : "",
          };
          optionValueData.push(obj);
        }
        let arrAnswer = Answer.split(",");
        arrAnswer.map((data) => {
          optionValueData.map((mdata, index) => {
            if (parseInt(data) === index + 1) {
              mdata.is_correct = "True";
            }
          });
        });
      } else {
        if (optionCount >= 2) {
          obj = {
            option: !fop1valid ? op1valid : "",
            is_correct: Answer === "1" ? "True" : "False",
            file: fop1valid ? op1valid : "",
          };
          optionValueData.push(obj);
          obj = {
            option: !fop2valid ? op2valid : "",
            is_correct: Answer === "2" ? "True" : "False",
            file: fop2valid ? op2valid : "",
          };
          optionValueData.push(obj);
        }
        if (optionCount >= 3) {
          obj = {
            option: !fop3valid ? op3valid : "",
            is_correct: Answer === "3" ? "True" : "False",
            file: fop3valid ? op3valid : "",
          };
          optionValueData.push(obj);
        }
        if (optionCount >= 4) {
          obj = {
            option: !fop4valid ? op4valid : "",
            is_correct: Answer === "4" ? "True" : "False",
            file: fop4valid ? op4valid : "",
          };
          optionValueData.push(obj);
        }
        if (optionCount == 5) {
          obj = {
            option: !fop5valid ? op5valid : "",
            is_correct: Answer === "5" ? "True" : "False",
            file: fop5valid ? op5valid : "",
          };
          optionValueData.push(obj);
        }
      }
      values.option_data = JSON.stringify(optionValueData);

      setShowLoader(true);
      const res = await dispatch(
        addQuestion(values, handleClear, increaseQuestionCount, handleLoader)
      );
    }
  };

  const increaseQuestionCount = () => {
    setQuestionCount((questionCount) => questionCount + 1);
  };

  const descreaseQuestionCount = () => {
    setQuestionCount((questionCount) => questionCount - 1);
  };

  const viewDraftQuestion = async () => {
    const body = {
      test_id: id,
    };
    const res = await dispatch(getQuestion(body));
    if (res.status == 200) {
      setViewQuestion(res?.payload);
      setQuestionCount(res?.payload.length);
    }
    console.log(getTestQuestion?.payload);
  };

  const testStatusChange = async () => {
    const body = {
      test_id: id,
      is_active: !testStatus,
    };
    setShowLoader(true);
    const res = await dispatch(TestStatusChange(body, handleLoader));
    setTestStatus((testStatus) => !testStatus);
  };

  const deleteThisQuestion = async (id) => {
    Swal.fire({
      text: "Do you want to delete Question ?",
      icon: "warning",
      imageAlt: "warning image",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, cancel!",
    }).then((result) => {
      if (result.isConfirmed) {
        let body = {
          question_id: id,
        };
        dispatch(deleteQuestion(body, descreaseQuestionCount));
        console.log(deleteTestQuestion);
      }
    });
  };

  const handleClear = () => {
    setquestion("");
    setFileData("");
    setAnswer("");
    setOp1valid("");
    setOp2valid("");
    setOp3valid("");
    setOp4valid("");
    setOp5valid("");
    setCorrectAnswer("");
    setOptionImage(false);
    setArrayIndex(0);
    setCurrent1FileName("");
    setCurrent2FileName("");
    setCurrent3FileName("");
    setCurrent4FileName("");
    setCurrent5FileName("");
    setfOp1valid(false);
    setfOp2valid(false);
    setfOp3valid(false);
    setfOp4valid(false);
    setfOp5valid(false);
    setSelectedExamType("");
    setOptionCount(1);
  };

  const handleClearOnQuestionTypeChange = () => {
    // setquestion("");
    setFileData("");
    setAnswer("");
    setOp1valid("");
    setOp2valid("");
    setOp3valid("");
    setOp4valid("");
    setOp5valid("");
    setCorrectAnswer("");
    setOptionImage(false);
    setArrayIndex(0);
    setCurrent1FileName("");
    setCurrent2FileName("");
    setCurrent3FileName("");
    setCurrent4FileName("");
    setCurrent5FileName("");
    setfOp1valid(false);
    setfOp2valid(false);
    setfOp3valid(false);
    setfOp4valid(false);
    setfOp5valid(false);
  };

  function convertTime(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const formattedHours = String(hours).padStart(2, "0");
    const formattedMinutes = String(minutes).padStart(2, "0");
    const time = `${formattedHours}:${formattedMinutes}`;
    return time;
  }

  const AddQuestion = useFormik({
    enableReinitialize: true,
    initialValues: {
      test_id: QuizId,
      answer_index: arrayIndex,
      question: question,
      media: fileData,
      single_choice: singleChoice ? "True" : "False",
      fillinblanks: fileIBValid ? "True" : "False",
      true_false: trueFalseValid ? "True" : "False",
      option_media: OptionImage ? "True" : "False",
      option_data: [],
      answer: Answer,
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {},
  });

  const getFileExtension = (filename) => {
    // get file extension
    const extension = reverseString(filename);
    const ext = reverseString(extension.substring(0, extension.indexOf(".")));
    return ext;
  };

  const reverseString = (str) => {
    // Step 1. Use the split() method to return a new array
    var splitString = str.split("").reverse().join("");
    return splitString; // "olleh"
  };

  if (showLoader) return <Loader />;
  return (
    <Container fluid={true} className="mb-5">
         <Heading pageTitle={"Modify Mock Test"}>
         <Link
              className="btn btn-outline-info"
              to={"/school/mock-test"}
              onClick={() => setViewQuestion([])}
            >
              Go Back
            </Link>

            {testStatus ? (
              <button
                className="btn btn-danger mx-2"
                onClick={testStatusChange}
              >
                Inactive
              </button>
            ) : (
              <button
                className="btn btn-primary mx-2"
                onClick={testStatusChange}
              >
                Active
              </button>
            )}
         </Heading>
      
      <div className="card mt-4">
        <div className="card-body">
          <div className="mb-3 px-lg-4 row">
            <div className="col-sm-9"></div>
          </div>
          <div className="card-body m-0 p-0">
            <div className="mb-3 row">
              <div className="col-md-9 col-sm-12 col-xs-12">
                <div className="mb-3 form-check">
                  <label className="form-label">
                    Test Name <span style={{ color: "Red" }}>*</span>
                  </label>
                  <input
                    disabled={Disabled}
                    name="test_name"
                    placeholder="Enter Test Name"
                    type="text"
                    className="form-control-auto form-control"
                    aria-invalid="false"
                    value={Create.test_name}
                    onChange={handleChange}
                  />
                  {showValidationError && Create.test_name === "" ? (
                    <div class="popup-message">
                      <i class="fa fa-exclamation-circle"></i>
                      This field is required.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-3 col-sm-12 col-xs-12">
                <div className="mb-3 form-check">
                  <label className="form-label form-label">
                    No. of Questions <span style={{ color: "Red" }}>*</span>
                  </label>

                  <input
                    disabled={Disabled}
                    name="no_of_question"
                    placeholder="No. of Questions"
                    type="number"
                    className="form-control-auto form-control"
                    aria-invalid="false"
                    value={Create.no_of_question}
                    onChange={handleChange}
                    min="1"
                    max="30"
                  />
                  {showValidationError &&
                  (Create.no_of_question === 0 ||
                    Create.no_of_question === "") ? (
                    <div class="popup-message">
                      <i class="fa fa-exclamation-circle"></i>
                      This field is required.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="mb-3 form-check">
                  <label className="form-label form-label">
                    Class <span style={{ color: "Red" }}>*</span>
                  </label>
                  <Select
                    name="class_id"
                    placeholder="Select Class"
                    options={classList}
                    value={
                      selectClass
                        ? classList?.find((c) => c.value === selectClass)
                        : null
                    }
                    onChange={(e) => {
                      handleChangeClass(e);
                      setCreate({
                        ...Create,
                        class_id: e.value,
                      });
                    }}
                    isSearchable={true}
                    isDisabled={Disabled}
                  />
                  {showValidationError &&
                  (Create.class_id === "" || Create.class_id === 0) ? (
                    <div class="popup-message">
                      <i class="fa fa-exclamation-circle"></i>
                      This field is required.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="mb-3 form-check">
                  <label className="form-label form-label">
                    Subjects <span style={{ color: "Red" }}>*</span>
                  </label>
                  <Select
                    name="subject_id"
                    placeholder="Select Subject"
                    options={subjectList}
                    value={
                      selectSubject
                        ? subjectList.find(
                            (option) => option.value === selectSubject
                          )
                        : null
                    }
                    onChange={(e) => {
                      setSelectSubject(e.value);
                      setCreate({
                        ...Create,
                        subject_id: e.value,
                      });
                    }}
                    isSearchable={true}
                    isDisabled={Disabled}
                  />
                  {showValidationError &&
                  (Create.subject_id === "" || Create.subject_id === 0) ? (
                    <div class="popup-message">
                      <i class="fa fa-exclamation-circle"></i>
                      This field is required.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-xs-12">
                <div className="mb-3 form-check">
                  <label className="form-label form-label">
                    Chapter <span style={{ color: "Red" }}>*</span>
                  </label>
                  <Select
                    name="chapter_id"
                    placeholder="Select Chapter"
                    options={chapterList}
                    value={
                      selectChapter
                        ? chapterList.find(
                            (option) => option.value === selectChapter
                          )
                        : null
                    }
                    onChange={(e) => {
                      setSelectChapter(e.value);
                      setCreate({
                        ...Create,
                        chapter_id: e.value,
                      });
                    }}
                    isSearchable={true}
                    isDisabled={Disabled}
                  />

                  {showValidationError &&
                  (Create.chapter_id === "" || Create.chapter_id === 0) ? (
                    <div class="popup-message">
                      <i class="fa fa-exclamation-circle"></i>
                      This field is required.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              <div className="col-md-2 col-sm-12 col-xs-12">
                <div className="mb-3 form-check">
                  <label className="form-label form-label">
                    Date <span style={{ color: "Red" }}>*</span>
                  </label>
                  <input
                    disabled={Disabled}
                    name="start_date"
                    placeholder="Select Date"
                    type="date"
                    className="form-control-auto form-control"
                    value={Create.start_date}
                    onChange={handleChange}
                  />
                  {showValidationError && Create.start_date === "" ? (
                    <div class="popup-message">
                      <i class="fa fa-exclamation-circle"></i>
                      This field is required.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>
              <div className="col-md-2 col-sm-12 col-xs-12">
                <div className="mb-3 form-check">
                  <label className="form-label form-label">
                    Time <span style={{ color: "Red" }}>*</span>
                  </label>
                  <input
                    disabled={Disabled}
                    name="start_time"
                    type="time"
                    className="form-control form-control"
                    value={Create.start_time}
                    onChange={handleChange}
                  />
                  {showValidationError && Create.start_time === "" ? (
                    <div class="popup-message">
                      <i class="fa fa-exclamation-circle"></i>
                      This field is required.
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              </div>

              {/* Disabled ? "d-none" : "" */}
              <div className={` col-sm-8`}>
                <div className="mt-4 form-check">
                  <div className="d-flex flex-wrap gap-2">
                    {!AddQuestions ? (
                      <button
                        type="button"
                        className="btn  btn btn-primary px-4 float-end"
                        onClick={handleCreateTest}
                      >
                        Update Questions
                      </button>
                    ) : (
                      <i
                        className="fa fa-edit"
                        onClick={handleUnableTest}
                        style={{ fontSize: "25px", cursor: "pointer" }}
                      ></i>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="card">
        {AddQuestions ? (
          <div className="container top-100">
            <form
              onSubmit={(e) => {
                e.preventDefault();
                handleAddQuestion();
              }}
            >
              <div className="bg-body">
                <div className="mb-3">
                  <Row>
                    <div className="col-md-12">
                      <label
                        className="float-end col mt-3"
                        style={{ textAlign: "right" }}
                      >
                        <p className="fs-6 bold">
                          Question Added{" "}
                          {Number(questionCount) + "/" + Create.no_of_question}
                        </p>
                      </label>
                    </div>
                  </Row>
                  <Row className="mb-3">
                    <div className="col-md-8">
                      <Row>
                        <label className="col-md-12 form-label">
                          Question <span style={{ color: "Red" }}>*</span>
                        </label>
                      </Row>
                      <Row>
                        <div className="col-md-12">
                          <Input
                            name="question"
                            rows="2"
                            placeholder="Write your Question"
                            type="textarea"
                            className="border border-info form-control"
                            value={question}
                            disabled={
                              Number(questionCount) ===
                              Number(Create.no_of_question)
                            }
                            onChange={(e) => setquestion(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12">
                          {showValidationError && question === "" ? (
                            <div class="popup-message">
                              <i class="fa fa-exclamation-circle"></i>
                              This field is required.
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Row>
                    </div>
                    <div className="col-md-4">
                      <Row>
                        <label className="col-md-12 form-label">
                          Question Type<span style={{ color: "Red" }}>*</span>
                        </label>
                      </Row>
                      <Row>
                        <div className="col-md-12">
                          <Select
                            name="question_type"
                            placeholder="Select Question Type"
                            options={questionTypeList}
                            value={
                              selectedExamType
                                ? questionTypeList.find(
                                    (option) =>
                                      option.value === selectedExamType
                                  )
                                : null
                            }
                            onChange={(e) => {
                              setSelectedExamType(e.value);
                              handleClearOnQuestionTypeChange();
                              setOptionImage(false);
                              setValidationError(false);
                              if (e.value == "True or False") {
                                setOptionCount(2);
                                setOp1valid("True");
                                setOp2valid("False");
                              } else {
                                setOptionCount(1);
                              }
                            }}
                          />
                        </div>
                        <div className="col-md-12">
                          {showValidationError && selectedExamType === "" ? (
                            <div class="popup-message">
                              <i class="fa fa-exclamation-circle"></i>
                              This field is required.
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Row>
                    </div>
                  </Row>
                  {optionCount >= 1 ? (
                    <Row className="mb-3">
                      <label className="col-md-2 form-label">
                        Option 1<span style={{ color: "Red" }}>*</span>
                      </label>
                      <div className="col-md-9">
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="d-flex">
                              {fop1valid ? (
                                <Input
                                  type="file"
                                  className="border border-info form-control"
                                  id="img"
                                  accept="image/*"
                                  name="op1"
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  value={currentFileName1}
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (
                                      getFileExtension(file.name) === "png" ||
                                      getFileExtension(file.name) === "jpg" ||
                                      getFileExtension(file.name) === "jpeg"
                                    ) {
                                      setCurrent1FileName(e.target.value);
                                      optionFileRead(e, "1");
                                    } else {
                                      Swal.fire({
                                        text: "Please upload file with png,jpg,jpeg extension.",
                                        icon: "warning",
                                        imageAlt: "warning image",
                                        allowOutsideClick: false,
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          e.target.value = null;
                                        }
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                <Input
                                  className="border border-info form-control"
                                  type="text"
                                  name="op1"
                                  value={op1valid}
                                  disabled={
                                    Number(questionCount) ===
                                      Number(Create.no_of_question) ||
                                    selectedExamType === "True or False"
                                  }
                                  onChange={(e) => {
                                    setOp1valid(e.target.value);
                                  }}
                                  placeholder="Option 1"
                                  aria-label=""
                                />
                              )}
                              {selectedExamType !== "True or False" ? (
                                <i
                                  className={
                                    !fop1valid
                                      ? "fa fa-image m-2 pt-1"
                                      : "fa fa-text-width m-2 pt-1"
                                  }
                                  onClick={() => setfOp1valid(!fop1valid)}
                                ></i>
                              ) : (
                                ""
                              )}
                              {selectedExamType !==
                              "Multiple Correct Answer" ? (
                                <input
                                  className="ml-2"
                                  type="radio"
                                  name="answer"
                                  onClick={handleOptionChange}
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  checked={Answer == "1" && Answer !== ""}
                                  value={1}
                                />
                              ) : (
                                <input
                                  className="ml-2"
                                  type="checkbox"
                                  name="answer"
                                  onClick={handleOptionChange}
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  checked={
                                    Answer.indexOf("1") !== -1 && Answer !== ""
                                  }
                                  value={1}
                                />
                              )}{" "}
                              <label className="m-2">Correct</label> <br />
                            </div>
                          </div>
                          <div className="col-md-12">
                            {showValidationError &&
                            op1valid === "" &&
                            optionCount >= 1 ? (
                              <div class="popup-message">
                                <i class="fa fa-exclamation-circle"></i>
                                This field is required.
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="col-md-12">
                              {showValidationError &&
                              question !== "" &&
                              Answer === "" ? (
                                <div class="popup-message-right">
                                  <i class="fa fa-exclamation-circle"></i>
                                  Corrent Answer is required
                                </div>
                              ) : (
                                ""
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1"></div>
                    </Row>
                  ) : (
                    ""
                  )}
                  {optionCount >= 2 ? (
                    <Row className="mb-3">
                      <label className="col-md-2 form-label">
                        Option 2<span style={{ color: "Red" }}>*</span>
                      </label>
                      <div className="col-md-9">
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="d-flex">
                              {fop2valid ? (
                                <Input
                                  type="file"
                                  className="border border-info form-control"
                                  id="img"
                                  accept="image/*"
                                  name="op2"
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  value={currentFileName2}
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (
                                      getFileExtension(file.name) === "png" ||
                                      getFileExtension(file.name) === "jpg" ||
                                      getFileExtension(file.name) === "jpeg"
                                    ) {
                                      setCurrent2FileName(e.target.value);
                                      optionFileRead(e, "2");
                                    } else {
                                      Swal.fire({
                                        text: "Please upload file with png,jpg,jpeg extension.",
                                        icon: "warning",
                                        imageAlt: "warning image",
                                        allowOutsideClick: false,
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          e.target.value = null;
                                        }
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                <Input
                                  className="border border-info form-control"
                                  type="text"
                                  name="op2"
                                  disabled={
                                    Number(questionCount) ===
                                      Number(Create.no_of_question) ||
                                    selectedExamType === "True or False"
                                  }
                                  value={op2valid}
                                  onChange={(e) => {
                                    setOp2valid(e.target.value);
                                  }}
                                  placeholder="Option 2"
                                  aria-label=""
                                />
                              )}
                              {selectedExamType !== "True or False" ? (
                                <i
                                  className={
                                    !fop2valid
                                      ? "fa fa-image m-2 pt-1"
                                      : "fa fa-text-width m-2 pt-1"
                                  }
                                  onClick={() => setfOp2valid(!fop2valid)}
                                ></i>
                              ) : (
                                ""
                              )}
                              {selectedExamType !==
                              "Multiple Correct Answer" ? (
                                <input
                                  className="ml-2"
                                  type="radio"
                                  name="answer"
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  onClick={handleOptionChange}
                                  checked={Answer === "2" && Answer !== ""}
                                  value={2}
                                  onBlur={() => setArrayIndex(1)}
                                />
                              ) : (
                                <input
                                  className="ml-2"
                                  type="checkbox"
                                  name="answer"
                                  onClick={handleOptionChange}
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  checked={
                                    Answer.indexOf("2") !== -1 && Answer !== ""
                                  }
                                  value={2}
                                />
                              )}{" "}
                              <label className="m-2">Correct</label> <br />
                            </div>
                          </div>
                          <div className="col-md-12">
                            {showValidationError &&
                            op2valid === "" &&
                            optionCount >= 2 ? (
                              <div class="popup-message">
                                <i class="fa fa-exclamation-circle"></i>
                                This field is required.
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1">
                        {optionCount == 2 &&
                        selectedExamType !== "True or False" ? (
                          <i
                            className="fa fa-close"
                            
                            style={{
                              fontSize: "30px",
                              fontWeight: "bold",
                              color: "Red",
                            }}
                            onClick={() => {
                              setOp2valid("");
                              setCurrent2FileName("");
                              setfOp2valid(false);
                              setOptionCount((optionCount) => optionCount - 1);
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                  {optionCount >= 3 ? (
                    <Row className="mb-3">
                      <label className="col-md-2 form-label">
                        Option 3<span style={{ color: "Red" }}>*</span>
                      </label>
                      <div className="col-md-9">
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="d-flex">
                              {fop3valid ? (
                                <Input
                                  type="file"
                                  className="border border-info form-control"
                                  id="img"
                                  accept="image/*"
                                  name="op3"
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  value={currentFileName3}
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (
                                      getFileExtension(file.name) === "png" ||
                                      getFileExtension(file.name) === "jpg" ||
                                      getFileExtension(file.name) === "jpeg"
                                    ) {
                                      setCurrent3FileName(e.target.value);
                                      optionFileRead(e, "3");
                                    } else {
                                      Swal.fire({
                                        text: "Please upload file with png,jpg,jpeg extension.",
                                        icon: "warning",
                                        imageAlt: "warning image",
                                        allowOutsideClick: false,
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          e.target.value = null;
                                        }
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                <Input
                                  className="border border-info form-control"
                                  type="text"
                                  name="op3"
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  value={op3valid}
                                  onChange={(e) => {
                                    setOp3valid(e.target.value);
                                  }}
                                  placeholder="Option 3"
                                  aria-label=""
                                />
                              )}
                              <i
                                className={
                                  !fop3valid
                                    ? "fa fa-image m-2 pt-1"
                                    : "fa fa-text-width m-2 pt-1"
                                }
                                onClick={() => setfOp3valid(!fop3valid)}
                              ></i>
                              {selectedExamType !==
                              "Multiple Correct Answer" ? (
                                <input
                                  type="radio"
                                  name="answer"
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  onClick={handleOptionChange}
                                  checked={Answer === "3" && Answer !== ""}
                                  value={3}
                                  onBlur={() => setArrayIndex(2)}
                                />
                              ) : (
                                <input
                                  className="ml-2"
                                  type="checkbox"
                                  name="answer"
                                  onClick={handleOptionChange}
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  checked={
                                    Answer.indexOf("3") !== -1 && Answer !== ""
                                  }
                                  value={3}
                                />
                              )}{" "}
                              <label className="m-2">Correct</label> <br />
                            </div>
                          </div>
                          <div className="col-md-12">
                            {showValidationError &&
                            op3valid === "" &&
                            optionCount >= 3 ? (
                              <div class="popup-message">
                                <i class="fa fa-exclamation-circle"></i>
                                This field is required.
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1">
                        {optionCount == 3 ? (
                          <i
                            className="fa fa-close"
                            style={{
                              fontSize: "30px",
                              fontWeight: "bold",
                              color: "Red",
                            }}
                            onClick={() => {
                              setOp3valid("");
                              setCurrent3FileName("");
                              setfOp3valid(false);
                              setOptionCount((optionCount) => optionCount - 1);
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                  {optionCount >= 4 ? (
                    <Row className="mb-3">
                      <label className="col-md-2 form-label">
                        Option 4<span style={{ color: "Red" }}>*</span>
                      </label>
                      <div className="col-md-9">
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="d-flex">
                              {fop4valid ? (
                                <Input
                                  type="file"
                                  className="border border-info form-control"
                                  id="img"
                                  accept="image/*"
                                  name="op4"
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  value={currentFileName4}
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (
                                      getFileExtension(file.name) === "png" ||
                                      getFileExtension(file.name) === "jpg" ||
                                      getFileExtension(file.name) === "jpeg"
                                    ) {
                                      setCurrent4FileName(e.target.value);
                                      optionFileRead(e, "4");
                                    } else {
                                      Swal.fire({
                                        text: "Please upload file with png,jpg,jpeg extension.",
                                        icon: "warning",
                                        imageAlt: "warning image",
                                        allowOutsideClick: false,
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          e.target.value = null;
                                        }
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                <Input
                                  className="border border-info form-control"
                                  type="text"
                                  name="op4"
                                  value={op4valid}
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  onChange={(e) => {
                                    setOp4valid(e.target.value);
                                  }}
                                  placeholder="Option 4"
                                  aria-label=""
                                />
                              )}
                              <i
                                className={
                                  !fop4valid
                                    ? "fa fa-image m-2 pt-1"
                                    : "fa fa-text-width m-2 pt-1"
                                }
                                onClick={() => setfOp4valid(!fop4valid)}
                              ></i>
                              {selectedExamType !==
                              "Multiple Correct Answer" ? (
                                <input
                                  type="radio"
                                  name="answer"
                                  onClick={handleOptionChange}
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  checked={Answer === "4" && Answer !== ""}
                                  value={4}
                                  onBlur={() => setArrayIndex(3)}
                                />
                              ) : (
                                <input
                                  className="ml-2"
                                  type="checkbox"
                                  name="answer"
                                  onClick={handleOptionChange}
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  checked={
                                    Answer.indexOf("4") !== -1 && Answer !== ""
                                  }
                                  value={4}
                                />
                              )}
                              <label className="m-2">Correct</label> <br />
                            </div>
                          </div>
                          <div className="col-md-12">
                            {showValidationError &&
                            op4valid === "" &&
                            optionCount >= 4 ? (
                              <div class="popup-message">
                                <i class="fa fa-exclamation-circle"></i>
                                This field is required.
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1">
                        {optionCount == 4 ? (
                          <i
                            className="fa fa-close"
                            style={{
                              fontSize: "30px",
                              fontWeight: "bold",
                              color: "Red",
                            }}
                            onClick={() => {
                              setOp4valid("");
                              setCurrent4FileName("");
                              setfOp4valid(false);
                              setOptionCount((optionCount) => optionCount - 1);
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}
                  {optionCount == 5 ? (
                    <Row className="mb-3">
                      <label className="col-md-2 form-label">
                        Option 5<span style={{ color: "Red" }}>*</span>
                      </label>
                      <div className="col-md-9">
                        <div className="row mb-3">
                          <div className="col-md-12">
                            <div className="d-flex">
                              {fop5valid ? (
                                <Input
                                  type="file"
                                  className="border border-info form-control"
                                  id="img"
                                  accept="image/*"
                                  name="op5"
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  value={currentFileName5}
                                  onChange={(e) => {
                                    const file = e.target.files[0];
                                    if (
                                      getFileExtension(file.name) === "png" ||
                                      getFileExtension(file.name) === "jpg" ||
                                      getFileExtension(file.name) === "jpeg"
                                    ) {
                                      setCurrent5FileName(e.target.value);
                                      optionFileRead(e, "5");
                                    } else {
                                      Swal.fire({
                                        text: "Please upload file with png,jpg,jpeg extension.",
                                        icon: "warning",
                                        imageAlt: "warning image",
                                        allowOutsideClick: false,
                                      }).then((result) => {
                                        if (result.isConfirmed) {
                                          e.target.value = null;
                                        }
                                      });
                                    }
                                  }}
                                />
                              ) : (
                                <Input
                                  className="border border-info form-control"
                                  type="text"
                                  name="op4"
                                  value={op5valid}
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  onChange={(e) => {
                                    setOp5valid(e.target.value);
                                  }}
                                  placeholder="Option 5"
                                  aria-label=""
                                />
                              )}
                              <i
                                className={
                                  !fop5valid
                                    ? "fa fa-image m-2 pt-1"
                                    : "fa fa-text-width m-2 pt-1"
                                }
                                onClick={() => setfOp5valid(!fop5valid)}
                              ></i>
                              {selectedExamType !==
                              "Multiple Correct Answer" ? (
                                <input
                                  type="radio"
                                  name="answer"
                                  onClick={handleOptionChange}
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  checked={Answer === "5" && Answer !== ""}
                                  value={5}
                                  onBlur={() => setArrayIndex(3)}
                                />
                              ) : (
                                <input
                                  className="ml-2"
                                  type="checkbox"
                                  name="answer"
                                  onClick={handleOptionChange}
                                  disabled={
                                    Number(questionCount) ===
                                    Number(Create.no_of_question)
                                  }
                                  checked={
                                    Answer.indexOf("5") !== -1 && Answer !== ""
                                  }
                                  value={5}
                                />
                              )}
                              <label className="m-2">Correct</label> <br />
                            </div>
                          </div>
                          <div className="col-md-12">
                            {showValidationError &&
                            op5valid === "" &&
                            optionCount === 5 ? (
                              <div class="popup-message">
                                <i class="fa fa-exclamation-circle"></i>
                                This field is required.
                              </div>
                            ) : (
                              ""
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="col-md-1">
                        {optionCount == 5 ? (
                          <i
                            className="fa fa-close"
                            style={{
                              fontSize: "30px",
                              fontWeight: "bold",
                              color: "Red",
                            }}
                            onClick={() => {
                              setOp5valid("");
                              setCurrent5FileName("");
                              setfOp5valid(false);
                              setOptionCount((optionCount) => optionCount - 1);
                            }}
                          ></i>
                        ) : (
                          ""
                        )}
                      </div>
                    </Row>
                  ) : (
                    ""
                  )}

                  <Row className="mb-3">
                    <div className="col-md-10">
                      <Row>
                        <label className="col-md-12 form-label">
                          Correct Answer Explaination{" "}
                          <span style={{ color: "Red" }}>*</span>
                        </label>
                      </Row>
                      <Row>
                        <div className="col-md-12">
                          <Input
                            name="correctansexplain"
                            rows="2"
                            placeholder="Write your Correct Answer Explaination"
                            type="textarea"
                            className="border border-info form-control"
                            value={correctAnswer}
                            disabled={
                              Number(questionCount) ===
                              Number(Create.no_of_question)
                            }
                            onChange={(e) => setCorrectAnswer(e.target.value)}
                          />
                        </div>
                        <div className="col-md-12">
                          {showValidationError && correctAnswer === "" ? (
                            <div class="popup-message">
                              <i class="fa fa-exclamation-circle"></i>
                              This field is required.
                            </div>
                          ) : (
                            ""
                          )}
                        </div>
                      </Row>
                    </div>
                    <div className="col-md-2" style={{ textAlign: "center" }}>
                      {selectedExamType === "" ||
                      selectedExamType === "True or False" ? (
                        ""
                      ) : optionCount < 5 ? (
                        <a
                          href="javascript:void(0)"
                          style={{ fontSize: "15px" }}
                          onClick={() => {
                            setOptionCount((optionCount) => optionCount + 1);
                          }}
                        >
                          <b>Add Option</b>
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </Row>
                  <Row>
                    <div className="col-md-12">
                      <Button
                        type="submit"
                        className="btn btn-primary me-1"
                        disabled={
                          Number(questionCount) ===
                          Number(Create.no_of_question)
                        }
                      >
                        ADD QUESTION
                      </Button>
                      <Button
                        onClick={() => handleClear()}
                        className="btn btn-danger mx-1"
                      >
                        CLEAR
                      </Button>
                    </div>
                  </Row>
                </div>
              </div>
            </form>
          </div>
        ) : (
          ""
        )}
      </div>
      <ShowTestQuestion
        ViewQuestion={ViewQuestion}
        deleteQuestion={deleteThisQuestion}
      />
    </Container>
  );
};

export default TestEditPage;
