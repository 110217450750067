import axios from "axios";
import { BASE_URL } from "../Config/BaseUrl";
const token = localStorage.getItem("token");
const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};
export const AuthAPI = {
  login: async (data) => {
    return await axios.post(`${BASE_URL}user_login/`, data);
  },

  forgotPassword: async (data) => {
    return await axios.post(`${BASE_URL}forgot_passowrd/`, data);
  },

  verifyOTP: async (data) => {
    return await axios.post(`${BASE_URL}verify_otp/`, data);
  },
  
  confirmOTP: async (data) => {
    return await axios.post(`${BASE_URL}confirm_verified_password/`, data);
  },
  changePassword: async (data) => {
    const axiosConfig = createAxiosConfig("change_PasswordUser", "POST", data);
    return await axios(axiosConfig);
  },
  superAdminChangePassword: async (data) => {
    const axiosConfig = createAxiosConfig("superadmin_change_Password", "POST", data);
    return await axios(axiosConfig);
  },


};
