import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

export const SchoolCommAPI = {
  getStudent: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_student_sectionwise/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },

  getStudentListforSchoolCommunication: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_StudentListfor_SchoolCommunication/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  sendCommMessage: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}send_schoolcommunication_message/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getAnnualPlan: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_annualPlanner/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  deleteAnnualPlan: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}delete_annualPlanner/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
};
