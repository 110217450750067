import {
  ADD_CLASS_TEST_FAIELD,
  ADD_CLASS_TEST_REQUIST,
  ADD_CLASS_TEST_SUCCESS,
  ADD_QUESTION_FAIELD,
  ADD_QUESTION_REQUIST,
  ADD_QUESTION_SUCCESS,
  CREATE_MOCK_TEST_FAIELD,
  CREATE_MOCK_TEST_REQUIST,
  CREATE_MOCK_TEST_SUCCESS,
  DELETE_QUESTION_FAIELD,
  DELETE_QUESTION_REQUIST,
  DELETE_QUESTION_SUCCESS,
  DELETE_TEST_FAIELD,
  DELETE_TEST_REQUIST,
  DELETE_TEST_SUCCESS,
  EDIT_CLASS_TEST_FAIELD,
  EDIT_CLASS_TEST_REQUIST,
  EDIT_CLASS_TEST_SUCCESS,
  GET_QUESTION_FAIELD,
  GET_QUESTION_REQUIST,
  GET_QUESTION_SUCCESS,
  SHOW_TEST_QUIZ_FAIELD,
  SHOW_TEST_QUIZ_REQUIST,
  SHOW_TEST_QUIZ_SUCCESS,
  TEST_STATUS_FAIELD,
  TEST_STATUS_REQUIST,
  TEST_STATUS_SUCCESS,
  TEST_STATUS_RESET_STATE,
  SHOW_ONLINETEST_REQUIST,
  SHOW_ONLINETEST_SUCCESS,
} from "../../Constants/MockTest/MockTest";

const initialState = {
  payload: null,
  showTest: null,
  showOnlineTest: null,
  mockTest: null,
  mockTestQuestion: null,
  getTestQuestion: null,
  deleteTestQuestion: null,
  loading: false,
  error: null,
};

export const createMockTest = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MOCK_TEST_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case CREATE_MOCK_TEST_SUCCESS:
      return {
        ...state,
        mockTest: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_MOCK_TEST_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    case TEST_STATUS_RESET_STATE:
      return {
        ...state,
        mockTest: null,
        loading: false,
        error: null,
      };
    default:
      return state;
  }
};

export const addQuestion = (state = initialState, action) => {
  switch (action.type) {
    case ADD_QUESTION_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_QUESTION_SUCCESS:
      return {
        ...state,
        mockTestQuestion: action.payload,
        loading: false,
        error: null,
      };
    case ADD_QUESTION_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const getQuestion = (state = initialState, action) => {
  switch (action.type) {
    case GET_QUESTION_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_QUESTION_SUCCESS:
      return {
        ...state,
        getTestQuestion: action.payload,
        loading: false,
        error: null,
      };
    case GET_QUESTION_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteQuestion = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_QUESTION_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_QUESTION_SUCCESS:
      return {
        ...state,
        deleteTestQuestion: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_QUESTION_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const deleteTest = (state = initialState, action) => {
  switch (action.type) {
    case DELETE_TEST_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case DELETE_TEST_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_TEST_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const TestStatusChange = (state = initialState, action) => {
  switch (action.type) {
    case TEST_STATUS_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case TEST_STATUS_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        error: null,
      };
    case TEST_STATUS_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const AddClassTest = (state = initialState, action) => {
  switch (action.type) {
    case ADD_CLASS_TEST_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case ADD_CLASS_TEST_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        error: null,
      };
    case ADD_CLASS_TEST_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const EditClassTest = (state = initialState, action) => {
  switch (action.type) {
    case EDIT_CLASS_TEST_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case EDIT_CLASS_TEST_SUCCESS:
      return {
        ...state,
        payload: action.payload,
        loading: false,
        error: null,
      };
    case EDIT_CLASS_TEST_FAIELD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};

export const ShowTestQuiz = (state = initialState, action) => {
  switch (action.type) {
    case SHOW_TEST_QUIZ_REQUIST:
    case SHOW_ONLINETEST_REQUIST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case SHOW_TEST_QUIZ_SUCCESS:
      return {
        ...state,
        showTest: action.payload,
        loading: false,
        error: null,
      };
    case SHOW_ONLINETEST_SUCCESS:
      return {
        ...state,
        showOnlineTest: action.payload,
        loading: false,
        error: null,
      };
    case SHOW_TEST_QUIZ_FAIELD:
    case SHOW_ONLINETEST_SUCCESS:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
