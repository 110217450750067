import {
    CREATE_STUDENT_SUBJECT_REQUIST,
    CREATE_STUDENT_SUBJECT_SUCCESS,
    CREATE_STUDENT_SUBJECT_FAIELD,
    SHOW_STUDENT_SUBJECT_REQUIST,
    SHOW_STUDENT_SUBJECT_SUCCESS,
    SHOW_STUDENT_SUBJECT_FAIELD,
} from "../../Constants/TeacherDesk/StudentSubject";

const initialState = {
    payload: null,
    showStudentSubject: [],  
    showStudentSubjectMapping:[],  
    loading: false,
    error: null,
  };

  export const SaveStudentSubjectMapping = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_STUDENT_SUBJECT_REQUIST:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case CREATE_STUDENT_SUBJECT_SUCCESS:
          return {
            ...state,
            showStudentSubjectMapping: action.payload,
            loading: false,
            error: null,
          };
        case CREATE_STUDENT_SUBJECT_FAIELD:
          return {
            ...state,
            loading: true,
            error: action.payload,
          };
        default:
          return state;
      }
    };

    export const ShowStudentSubjectMapping = (state = initialState, action) => {
        switch (action.type) {
          case SHOW_STUDENT_SUBJECT_REQUIST:
            return {
              ...state,
              loading: true,
              error: null,
            };
          case SHOW_STUDENT_SUBJECT_SUCCESS:
            return {
              ...state,
              showStudentSubject: action.payload,
              loading: false,
              error: null,
            };
          case SHOW_STUDENT_SUBJECT_FAIELD:
            return {
              ...state,
              loading: true,
              error: action.payload,
            };
          default:
            return state;
        }
      };
