import React, { useState, useEffect } from "react";
import { Heading } from "../../CommonComponent/heading";
// import MonthlyAttendenceModle from "./MonthlyAttendenceModle/MonthlyAttendenceModle";
import Swal from "sweetalert2";
import { AttendenceReportAPI } from "../../../api/Payroll/AttendenceReport";
import useLoading from "../../CommonComponent/useLoading";
import useLocalStorageData from "../Comman/LocalStorageData";
import Loader from "../../Loader/Loader";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
const AttendenceReport = () => {
  const [Date1, setDate1] = useState("");

  const { school_id, token, school_emp_id } = useLocalStorageData();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [attendanceData, setAttendanceData] = useState([]);
  const [SearchFilter, setSearchFilter] = useState(false);
  const [isDaily ,setIsDaily] = useState(false);

  const handelDateChange = (e) => {
    setDate1(e.target.value);
  };

  const hanleClear = () => {
    setDate1("");
    setAttendanceData([]);
    setFilteredData([]);
    setIsDaily(false);
  };

  const getEmployList = async () => {
    setAttendanceData([]);
    setFilteredData([]);
    if (!Date1) {
      Swal.fire({
        text: "Please select month and year",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }
    try {
      let data = {
        school_id: school_id,
        attendance_month: Date1,
        is_attendance:isDaily ? "Daily": "Month" ,
      };

      startLoading();
      const response = await AttendenceReportAPI.inOutTimeReportArr(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        setAttendanceData(responseData.result);
        setFilteredData(responseData.result);
        setSearchFilter(true);
      } else {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      stopLoading();
    }
  };
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredData, setFilteredData] = useState(attendanceData);


  const handleSearch = () => {
    if (searchQuery.trim() === "") {
      setFilteredData(attendanceData);
      return;
    }
    const query = searchQuery.toLowerCase();
    const result = attendanceData.filter((staff) =>
      `${staff.first_name.toLowerCase()} ${staff.last_name.toLowerCase()}`.includes(
        query
      )
    );
    setFilteredData(result);
  };
  useEffect(() => {
    if (searchQuery.trim() === "") {
      setFilteredData(attendanceData);
    }
  }, [searchQuery, attendanceData]);
  if (isLoading) return <Loader />;
  return (
    <div className="container-fluid">
      <Heading pageTitle={"Attendence Report"}></Heading>

      <div className="card mt-2">
        <div className="card-body">
          <div className="col-lg-12 row form-group ">
            <div className="col-auto">
              <label className="col-form-label ">Daily Report</label>
            </div>
            <div className=" col-auto align-self-center">
              <label className="custom-switch">
                <input
                  type="checkbox"
                  className="custom-switch-input "
                  name="custom-switch-checkbox"
                  id="smsCheckbox"
                  onChange={()=>setIsDaily(!isDaily)}
                  checked={isDaily}
                />
                <span className="custom-switch-indicator mr-3"></span>
              </label>
            </div>
            <div className="col-auto ">
              <label className="col-form-label ">{isDaily ? "Date" : "Month & Year"}</label>
            </div>
            <div className="col-auto">
              <input
                type={isDaily ? "date" : "month"}
                className="form-control"
                placeholder="Month & Year"
                value={Date1}
                onChange={handelDateChange}
              />
            </div>
            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary mx-1"
                type="button"
                onClick={getEmployList}
              >
                <i className="fa fa-eye"> </i> View
              </button>
            </div>
            <div className="input-group-append">
              {filteredData.length > 0 ? (
                <ReactHTMLTableToExcel
                  id="test-table-xls-button"
                  className="download-table-xls-button btn-sm btn btn-success"
                  color="success"
                  table="table-to-xls"
                  filename="AttendenceReport"
                  sheet="tablexls"
                  buttonText="EXPORT"
                />
              ) : null}
            </div>
            <div className="input-group-append">
              <button
                className="btn btn-outline-danger mx-1"
                type="button"
                onClick={hanleClear}
              >
                <i className="fa fa-times"></i> Clear
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="card mt-2">
        <div class="card-header">
          <h3 class="card-title">Employee List</h3>
        </div>
        <div className="card-body">
          <div className="row">
            {SearchFilter && (
              <>
                <div className="col-4 ">
                  <input
                    type="text"
                    placeholder="Search by name"
                    value={searchQuery}
                    onChange={(e) => setSearchQuery(e.target.value)}
                    className="form-control"
                  />
                </div>
                <button onClick={handleSearch} className="btn btn-primary ">
                  Search
                </button>
              </>
            )}
          </div>
          <div className="table-responsive mt-2">
            <table
              className="table table-hover table-vcenter table-sm table-striped text-nowrap"
              id="table-to-xls"
            >
              <thead>
                <tr>
                  <th>Attendance Date</th>
                  <th>In Time</th>
                  <th>Out Time</th>
                  <th>Working Hours</th>
                </tr>
              </thead>
              <tbody>
                {filteredData.length > 0 ? (
                  filteredData.map((staff) => (
                    <React.Fragment key={staff.fk_staff_id}>
                      <tr className="text-white bg-lightgray">
                        <td colSpan="4" style={{ fontWeight: "bold" }}>
                          <h6 className="m-auto p-2">
                            {staff.first_name + " " + staff.last_name}
                          </h6>
                        </td>
                      </tr>
                      {staff.attendance.map((record, index) => (
                        <tr key={index}>
                          <td>{record.attendance_date}</td>
                          <td>{record.in_time || "--"}</td>
                          <td>{record.out_time || "--"}</td>
                          <td>
                            {record.working_hours == 0
                              ? "--"
                              : record.working_hours}
                          </td>
                        </tr>
                      ))}
                    </React.Fragment>
                  ))
                ) : (
                  <tr>
                    <td
                      colSpan="4"
                      style={{ textAlign: "center", fontSize: "2.5rem" }}
                      className="no-list-message"
                    >
                      {" "}
                      No data found {searchQuery ? "for " + searchQuery : ""}
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AttendenceReport;
