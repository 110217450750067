import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
import {
  CREATE_CATEGORY_FAILD,
  CREATE_CATEGORY_REQUIST,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILD,
  DELETE_CATEGORY_REQUIST,
  DELETE_CATEGORY_SUCCESS,
  GET_CAST_MASTERDATA_FAILD,
  GET_CAST_MASTERDATA_REQUIST,
  GET_CAST_MASTERDATA_SUCCESS,
} from "../../Constants/SuperUser/CastMaster";

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const getCastMasterData = () => async (dispatch) => {
  try {
    dispatch({ type: GET_CAST_MASTERDATA_REQUIST });
    const response = await axios.get(
      `${BASE_URL}get_CasteandCategory/`,
      config
    );
    const data = response.data;
    dispatch({ type: GET_CAST_MASTERDATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_CAST_MASTERDATA_FAILD,
      payload: error,
    });
  }
};

export const CreateCategory = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CATEGORY_REQUIST });
    const response = await axios.post(
      `${BASE_URL}add_UpdateCategory/`,
      data,
      config
    );
    const responseData = response.data;
    dispatch({ type: CREATE_CATEGORY_SUCCESS, payload: responseData });
    return responseData
  } catch (error) {
    dispatch({ type: CREATE_CATEGORY_FAILD, payload: error });
  }
};
export const CreateCaste = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CATEGORY_REQUIST });
    const response = await axios.post(
      `${BASE_URL}addupdate_Caste/`,
      data,
      config
    );
    const responseData = response.data;
    dispatch({ type: CREATE_CATEGORY_SUCCESS, payload: responseData });
    return responseData
  } catch (error) {
    dispatch({ type: CREATE_CATEGORY_FAILD, payload: error });
  }
};
export const CreateSubCaste = (data) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_CATEGORY_REQUIST });
    const response = await axios.post(
      `${BASE_URL}addupdate_SubCaste/`,
      data,
      config
    );
    const responseData = response.data;
    dispatch({ type: CREATE_CATEGORY_SUCCESS, payload: responseData });
    return responseData
  } catch (error) {
    dispatch({ type: CREATE_CATEGORY_FAILD, payload: error });
  }
};
export const deleteCategory = (Id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_CATEGORY_REQUIST });
    const response = await axios.post(
      `${BASE_URL}delete_Category/`,
      Id,
      config
    );
    const responseData = response.data;
    dispatch({ type: DELETE_CATEGORY_SUCCESS, payload: responseData });
    return responseData
  } catch (error) {
    dispatch({
      type: DELETE_CATEGORY_FAILD,
      payload: error,
    });
  }
};



