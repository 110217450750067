import React, { useEffect } from "react";
import { useState } from "react";
import { StudentPromotionAPI } from "../../../../api/Admission/studentPromotion";

const PromoteStudentList = (props) => {
  const { school_id, session_id } = props;
  const [promotedList, setPromotedList] = useState([]);

  const fetchSessionData = async () => {
    const body = {
      school_id: school_id,
      academic_id: session_id,
    };
    try {
      let response = await StudentPromotionAPI.getPromoteDataSectionWise(body);
      let responseData = response.data;
      setPromotedList(responseData.payload);
    } catch (err) {
      console.error(err);
    }
  };
  useEffect(() => {
    fetchSessionData();
  }, [session_id]);
  return (
    <div className="card">
      <div className="card-body">
        <div className="table-responsive">
          <table
            className="table table-striped table-bordered table-hover table-sm"
            id="table-to-xls"
          >
            <thead>
              <tr className="text-center text-nowrap">
                <th> Sr No</th>
                <th>Class-Section Name</th>
                <th>Medium</th>
                <th>Promoted Status</th>
              </tr>
            </thead>
            <tbody>
              {promotedList?.map((s, index) => (
                <tr className="" key={index}>
                  <td className="text-center">{index + 1}</td>
                  <td>{s.class_section}</td>
                  <td>{s.medium}</td>
                  <td className="text-center">{s.promoted_status}</td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default PromoteStudentList;
