import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
import Loader from "../../Loader/Loader";
import { sessionAPI } from "../../../api/sessionAPI";
import * as Yup from "yup";
import Select from "react-select";
import { getSchoolOrganization } from "../../../actions/super-user/SchoolRegistration";
import { getTitle } from "../../CommonComponent/helper";

const AddSession = () => {
  getTitle("Academic Session");
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let super_admin_id = school_user?.id
  

  const [loader, setLoader] = useState(false);
  const [sessiondata, setSessiondata] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const { organizationdata } = useSelector((state) => state.schoolRegister);
  const [input, setInput] = useState({
    name: "",
    start_date: "",
    end_date: "",
    current_session: false,
    organization_id: "",
    insert_by: school_user?.id,
    super_admin_id
  });
  const orgListOption = organizationdata?.payload?.map((org) => {
    return {
      value: org.id,
      label: org.name,
    };
  });

  useEffect(() => {
    if (token) {
      dispatch(getSchoolOrganization(token));
    }
  }, [dispatch, token]);

  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setInput((values) => ({ ...values, [name]: value }));
  };

  const handleChecked = (e) => {
    setInput((values) => ({ ...values, current_session: e.target.checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (input.start_date > input.end_date) {
      Swal.fire({
        title: "",
        text: "The 'To' date cannot be before the 'From' date. ",
        icon: "info",
        allowOutsideClick: false,
      });
      return;
    }
    if (input) {
      const confirmed = await Swal.fire({
        title: "Set Current Session",
        text: "You want this Session as Current Session?",
        icon: "question",
        showCancelButton: true,
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        cancelButtonText: "No",
        allowOutsideClick: false,
      });
      if (confirmed.isConfirmed) {
        input.current_session = true;
      } else {
        input.current_session = false;
      }
    }
    try {
      const res = await sessionAPI.createSession(input);
      if (res.data.status === 200) {
        setFetchingData(!fetchingData);
        Swal.fire({
          text: res.data.msg,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            window.location.href = "/admin/session-management";
          }
        });
      } else if (res.data.status === 403) {
        Swal.fire({
          text: res.data.msg,
          icon: "error",
          imageAlt: "success image",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      if (sessiondata?.length === 0) {
        setLoader(<Loader />);
      }
      try {
        const response = await sessionAPI.getAllSession();
        setSessiondata(response.data.payload);
        setLoader("");
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [fetchingData]);

  const hanldeDropdown = (selectedOptions) => {
    setInput((values) => ({
      ...values,
      organization_id: selectedOptions.value,
    }));
  };

  function convertDate(data) {
    const parts = data.split("-");
    const formattedDate = parts[2] + "/" + parts[1] + "/" + parts[0];
    return formattedDate;
  }
  const onDelete = async (id) => {
    const did = {
      session_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes !",
      cancelButtonText: "No",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await sessionAPI.deleteSession(did);
          Swal.fire("Deleted!", res.data.msg, "success");
          setFetchingData(!fetchingData);
          navigate("/admin/session-management");
        } catch (err) {
          console.error(err);
        }
      }
    });
  };
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Academic Session</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Session-all"
                >
                  Session List
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Session-add">
                  Add Session
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="tab-pane active" id="Session-all">
              {loader ? (
                <div>{loader}</div>
              ) : (
                <div className="table-responsive card">
                  <table
                    className="table table-hover 
                  table-vcenter table-sm table-striped text-nowrap
                  
                  "
                  >
                    <thead>
                      <tr className="text-center">
                        <th>Sr.No.</th>
                        <th>Session Name</th>
                        <th>Start date</th>
                        <th>End date</th>
                        <th>Current Session</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {organizationdata?.payload?.map((org) => (
                        <React.Fragment key={org.id}>
                          {sessiondata?.find(
                            (school) => school.fk_organization_id === org.id
                          ) && (
                            <tr className="text-white bg-lightgray">
                              <td colSpan="6" className="p-0">
                                <h6 className="m-auto p-2">{org.name}</h6>
                              </td>
                            </tr>
                          )}
                          {sessiondata
                            ?.filter(
                              (school) => school.fk_organization_id === org.id
                            )
                            .map((data, index) => {
                              return (
                                <tr key={index} className="text-center">
                                  <td>{index + 1}</td>
                                  <td className="text-left">{data.name}</td>
                                  <td>{convertDate(data.start_date)}</td>
                                  <td>{convertDate(data.end_date)}</td>
                                  <td>
                                    <b>{data.current_session ? "Yes" : "No"}</b>
                                  </td>
                                  <td className="">
                                    <NavLink
                                      to="#"
                                      className="card-options-remove mr-3"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Delete"
                                      onClick={() => onDelete(data.id)}
                                    >
                                      <i className="fa fa-trash-o text-danger"></i>
                                    </NavLink>
                                    <NavLink
                                      className="text-muted"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="Edit"
                                      to={{
                                        pathname: `/admin/session-details`,
                                      }}
                                      state={data.id}
                                    >
                                      <i className="fa fa-edit"></i>
                                    </NavLink>
                                  </td>
                                </tr>
                              );
                            })}
                        </React.Fragment>
                      ))}
                    </tbody>
                  </table>
                </div>
              )}
            </div>

            <div className="tab-pane" id="Session-add">
              <div className="row clearfix">
                <div className="col-lg-7 col-md-12 col-sm-12">
                  <div className="card">
                    <form className="card-body" onSubmit={handleSubmit}>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Organization
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-6">
                          <Select
                            name="organization_id"
                            options={orgListOption}
                            placeholder="Select "
                            onChange={hanldeDropdown}
                            isSearchable={true}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Session Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Session name"
                            name="name"
                            value={input.name}
                            onChange={handleChange}
                            required={true}
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Start Date <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-6">
                          <input
                            type="date"
                            className="form-control"
                            name="start_date"
                            value={input.start_date}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          End Date <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-6">
                          <input
                            type="date"
                            className="form-control"
                            name="end_date"
                            value={input.end_date}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Current Session <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-7 ml-1">
                          <label className="custom-switch">
                            <input
                              type="checkbox"
                              className="custom-switch-input"
                              name="current_session"
                              checked={input.current_session}
                              onChange={handleChecked}
                            />
                            <span className="custom-switch-indicator"></span>
                          </label>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label className="col-md-3 col-form-label"></label>
                        <div className="col-md-7">
                          <button type="submit" className="btn btn-primary">
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddSession;
