import { combineReducers, applyMiddleware } from "redux";
import thunk from "redux-thunk";
import { composeWithDevTools } from "redux-devtools-extension";
import { legacy_createStore as createStore } from "redux";
import { organizationReducer } from "./reducers/super-user/organizationReducer";
import { usersReducer } from "./reducers/users/usersReducer";
import { addUsersReducer } from "./reducers/users/AddUsersReducer";
import { schoolRegistrationReducer } from "./reducers/super-user/SchoolRegistration";
import { registerSchoolReducer } from "./reducers/super-user/RegisterSchool";
import { schoolFeeReducer } from "./reducers/super-user/FeesReducer";
import { classSectionReducer } from "./reducers/super-user/ClassSectionReducer";
import { commonModuleReducer } from "./reducers/super-user/CommanReducer";
import { masterDataReducer } from "./reducers/super-user/MasterDataReducer";
import authReducer from "./reducers/auth/authReducer";
import { staffMasterReducer } from "./reducers/school_user/StaffMasterReducer";
import { studentRegistrationReducer } from "./reducers/school_user/StudentRegistrationReducer";
import {
  UpdateAdmission,
  addAdmissionReducer,
  cancelAdmission,
  getAcademicFilterStudentList,
  getRegularFees,
  getStudentCount,
  getStudentName,
  getspecialFeesTypes,
  regularfeesReceipt,
  specialfeesReceipt,
  studentDataByCode,
} from "./reducers/Admission/AdmissionReducer";
import {
  bulkStudentUpload,
  getClass,
  getSection,
  getStudentAllReport,
} from "./reducers/Admission/StudentReport";
import { attendanceReducer } from "./reducers/school_user/AttendanceReducer";
import { holidayMasterReducer } from "./reducers/school_user/HolidayReducer";
import { certificateReducer } from "./reducers/school_user/CertificareReducer";
import {
  addStaffUser,
  getStaffUser,
} from "./reducers/school_user/StaffUserMasterReducer";
import {
  deleteProspectus,
  saveProspectus,
  updateProspectus,
  viewProspectus,
} from "./reducers/Admission/ProspectusReducer";
import { subjectMasterReducer } from "./reducers/school_user/SubjectReducer";
import { classSubjectReducer } from "./reducers/school_user/ClassSubjectReducer";
import tabReducer from "./reducers/tabReducer";
import { rollNoReducer } from "./reducers/school_user/RollNoReducer";
import {
  AddClassTest,
  EditClassTest,
  ShowTestQuiz,
  TestStatusChange,
  addQuestion,
  createMockTest,
  deleteQuestion,
  deleteTest,
  getQuestion
} from "./reducers/MockTest/MockTest";

import {
  ShowStudentSubjectMapping, 
  SaveStudentSubjectMapping,
} from "./reducers/TeacherDesk/StudentSubject";


import { contentUploaderReducer } from "./reducers/school_user/contentUploaderReducer";
import { feeDiscountReducer } from "./reducers/school_user/FeeDiscountReducer";
import { feeCollectionReducer } from "./reducers/school_user/FeeCollectionReducer";

import {
  GetHomeWorkList, 
  CreateUpdateHomeWork,
  DeleteWorkSheet,
} from "./reducers/TeacherDesk/WorkSheet";
import { OnlineTestReducer } from "./reducers/MockTest/OnlineTest";
import { castMasterReducer } from "./reducers/super-user/CastMasterReducer";
import { UserAuthicationReducer } from "./reducers/super-user/UserAuthicationReducer";
import { DashboardReducer } from "./reducers/Dashboard/DashboardReducer";
import schoolLogoReducer from "./reducers/SchoolLogoReducer";
import { Dashboard2Reducer } from "./reducers/Dashboard/Dashboard2Reducer";
import studentListForDeleteReducer from "./actions/school_user/StudentRegistration";

const reducer = combineReducers({
  auth: authReducer,
  organization: organizationReducer,
  schoolRegister: schoolRegistrationReducer,
  user: usersReducer,
  addUsers: addUsersReducer,
  schoolFee: schoolFeeReducer,
  createSchool: registerSchoolReducer,
  classSection: classSectionReducer,
  module: commonModuleReducer,
  masterData: masterDataReducer,
  staffMaster: staffMasterReducer,
  holidayMaster: holidayMasterReducer,
  studentRegistration: studentRegistrationReducer,
  studentName: getStudentName,
  admissionCount: getStudentCount,
  admission: addAdmissionReducer,
  getClass: getClass,
  getSection: getSection,
  getStudentAllReport: getStudentAllReport,
  attendance: attendanceReducer,
  getRegularFees: getRegularFees,
  regularfeesReceipt: regularfeesReceipt,
  getspecialFeesTypes: getspecialFeesTypes,
  specialfeesReceipt: specialfeesReceipt,
  certificate: certificateReducer,
  addStaffUser: addStaffUser,
  getStaffUser: getStaffUser,
  saveProspectus: saveProspectus,
  updateProspectus: updateProspectus,
  deleteProspectus: deleteProspectus,
  viewProspectus: viewProspectus,
  subject: subjectMasterReducer,
  classSubject: classSubjectReducer,
  studentDataByCode: studentDataByCode,
  cancelAdmission: cancelAdmission,
  bulkStudentUpload: bulkStudentUpload,
  activeTab: tabReducer,
  rollNo: rollNoReducer,
  createMockTest: createMockTest,  
  addQuestion: addQuestion,
  getQuestion: getQuestion,
  deleteQuestion: deleteQuestion,
  deleteTest: deleteTest,
  TestStatusChange: TestStatusChange,  
  AddClassTest: AddClassTest,
  EditClassTest: EditClassTest,
  ShowTestQuiz: ShowTestQuiz,
  contentUploader: contentUploaderReducer,
  UpdateAdmission: UpdateAdmission,
  feeDiscount: feeDiscountReducer,
  feeCollection: feeCollectionReducer,
  getAcademicFilterStudentList: getAcademicFilterStudentList,
  ShowStudentSubjectMapping:ShowStudentSubjectMapping,
  SaveStudentSubjectMapping:SaveStudentSubjectMapping,
  GetHomeWorkList:GetHomeWorkList,
  CreateUpdateHomeWork:CreateUpdateHomeWork,
  DeleteWorkSheet:DeleteWorkSheet,
  onlineTest:OnlineTestReducer,
  castMaster:castMasterReducer,
  userMenu:UserAuthicationReducer,
  Dashboard:DashboardReducer,
  schoolLogo: schoolLogoReducer,
  Dashboard2: Dashboard2Reducer,
  DeleteStudent :studentListForDeleteReducer
});

let initialState = {};
const middleware = [thunk];
const store = createStore(
  reducer,
  initialState,
  composeWithDevTools(applyMiddleware(...middleware))
);
export default store;
