import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Modal, ModalBody, ModalFooter, Table } from "reactstrap";

const StaffList = (props) => {
  const { data, setIsStaff } = props;
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSelectStaff = (data) => {
    setIsStaff(data);
    setModal(!modal);
  };

  return (
    <>
      <button
        className="btn btn-outline-secondary mr-1"
        data-toggle="tooltip"
        data-placement="top"
        title="View Student List"
        type="button"
        onClick={() => {
          // setSearch(stuList);
          toggle();
        }}
      >
        <i className="fa fa-search"> </i>
      </button>

      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box">
        {/* <ModalHeader toggle={toggle}>  </ModalHeader> */}
        <div className="form-group row p-4">
          <div className="col-lg-3 col-md-3">
            {/* <input
              name="code"
              type="number"
              className="form-control"
              placeholder="Student code"
              value={idQuery}
              onChange={handleIdInputChange}
              autoComplete="off"
            /> */}
          </div>
          <div className="col-lg-3 col-md-3 ">
            {/* <input
              type="text"
              className="form-control"
              name="name"
              placeholder="Student Name"
              value={searchQuery}
              onChange={handleInputChangeSearch}
              autoComplete="off"
            /> */}
          </div>
          <div className="col-md-2 col-lg-2">
            {/* <button
              type="submit"
              className="btn btn-primary"
              onClick={handleSearch}
            >
              Search
            </button> */}
          </div>
          <div className="col-lg-4 col-md-4 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={toggle}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div>
        <ModalBody>
          {noResultsMessage ? (
            <p>{noResultsMessage}</p>
          ) : (
            <Table size="sm" className="table-hover">
              <thead>
                <tr className="text-center">
                  <th>SR NO.</th>
                  <th>EMP_NO .</th>
                  <th>Staff Name</th>
                  <th>Address</th>
                  <th>CONTACT NO.</th>
                </tr>
              </thead>
              <tbody>
                {data?.map((staff, index) => (
                  <tr
                    key={index}
                    className="text-left"
                    onClick={() => handleSelectStaff(staff)}
                    style={{ cursor: "pointer" }}
                  >
                    <td className="text-center">{index + 1}</td>
                    <td>{staff.emp_no}</td>
                    <td>
                      {staff.first_name} {staff.last_name}
                    </td>
                    <td>{staff.address}</td>
                    <td>{staff.mobile_no}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          )}
        </ModalBody>
        {/* <ul className="pagination justify-content-center">
          {Page && (
            <Pagination
              activePage={CurrentPage}
              itemsCountPerPage={resultPerPage}
              totalItemsCount={academicStudentList?.length}
              // totalItemsCount={
              //   filteredProducts && productCount ? productCount : 0
              // }
              hideFirstLastPages={false}
              onChange={(e) => setCurrentPage(e)}
              nextPageText="Next"
              prevPageText="Previous"
              lastPageText="Last"
              firstPageText="1st"
              itemClass="page-item"
              linkClass="page-link"
              activeClass="active"
              activeLinkClass="active"
              hideDisabled={false}
            />
          )}
        </ul> */}
        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};

export default StaffList;
