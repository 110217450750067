import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import { RoomTable } from "../HostelDetails/Floor";

const AvailabletyRoom = (props) => {
  const { hostelId, setIsRoom } = props;
  const [modal, setModal] = useState(false);
  const [availabletyRoom, setAvailabletyRoom] = useState([]);
  const toggle = () => {
    setModal(!modal);
  };

  const handleSelectRoom = (room) => {
    setIsRoom(room);
    setModal(!modal);
  };

  const fetchAvailabletyRoom = async () => {
    try {
      let data = {
        hostelId: hostelId,
      };
      const response = await HostelAPI.getAvailabletyRoom(data);
      let responseData = response.data;

      setAvailabletyRoom(responseData.hostel_rooms);
    } catch (error) {
      console.error(error);
    }
  };

  const closeBtn = (
    <button className="btn btn-sm close " onClick={toggle} type="button">
      <i className="fa fa-times" style={{ color: "red" }}></i>
    </button>
  );
  return (
    <>
      <button
        type="button"
        className="btn btn-outline-secondary btn-sm mx-1"
        onClick={() => {
          toggle();
          fetchAvailabletyRoom();
        }}
      >
        Availability
      </button>

      <Modal isOpen={modal} toggle={toggle} fullscreen className="">
        <ModalHeader toggle={toggle} close={closeBtn}>
          Room List
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            {availabletyRoom?.length > 0 ? (
              availabletyRoom?.map((floor, index) => {
                const [floorName, floorData] = Object.entries(floor)[0];
                return (
                  <div key={index}>
                    <h6 style={{ fontWeight: "bold" }}>{floorName}</h6>
                    <div className="d-flex flex-wrap">
                      {floorData?.map((room, roomIndex) => (
                        <div
                          className={`badge m-1 p-2 bed-style ${
                            room.is_available === "True"
                              ? "bg-light text-dark"
                              : "tag-red"
                          } `}
                          key={roomIndex}
                          onClick={() =>
                            room.is_available === "True" &&
                            handleSelectRoom(room)
                          }
                          style={{
                            cursor:
                              room.is_available === "True"
                                ? "pointer"
                                : "default",
                          }}
                        >
                          Floor :-{room.floorNo} Room :-{room.roomNo} Bed :-
                          {room.bedNo}{" "}
                        </div>
                      ))}
                    </div>
                  </div>
                );
              })
            ) : (
              <EmptyListMessage message={"Room Not Available !"} Div="div" />
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default AvailabletyRoom;
