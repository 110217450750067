import React, { useContext } from "react";
import Select from "react-select";
import useSessionRedirect from "../../Comman/SessionChange";
import { StudentPromotionAPI } from "../../../../api/Admission/studentPromotion";
import { useEffect } from "react";
import { useState } from "react";
import { SessionContext } from "../../../../context/sessionContext";
import { getClassSection } from "../../../../actions/school_user/Attenadance";
import { useDispatch, useSelector } from "react-redux";
import PromoteStudentList from "./PromoteStudentList";
import ListForPromotion from "./ListForPromotion";
import Swal from "sweetalert2";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import useLoading from "../../../CommonComponent/useLoading";
import Loader from "../../../Loader/Loader";

const PromoteStudent = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { email, id, user_type, name, user_detail } = school_user;
  const { school_emp_id } = user_detail;
  let school_id = school_user?.user_detail?.fk_school_id;
  const session_id = useSessionRedirect();
  const { SessionData } = useContext(SessionContext);
  const { isLoading, startLoading, stopLoading } = useLoading();
  const dispatch = useDispatch();
  const { classsectionList } = useSelector((state) => state.attendance);
  const [nextSession, setNextSession] = useState([]);
  const [sessionLabel, setSessionLabel] = useState("");
  const [classList, setClassList] = useState([]);
  const [isResultClass, setIsResultClass] = useState(null);
  const [isPromoteClass, setIsPromoteClass] = useState(null);
  const [promoteClassOption, setPromoteClassOption] = useState([]);
  const [selectNextSession, setSelectNextSession] = useState(null);
  const [promotedList, setPromotedList] = useState([]);
  const [selectedStudent, setSelectedStudent] = useState([]);
  const [isResult, setIsResult] = useState(false);
  let [validationError, setValidationError] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [nextSessionFees, setNextSessionFees] = useState(null);
  const [currentDiv, setCurrentDiv] = useState(1);
  const validationControl = async () => {
    let Validationstatus = true;
    if (!isResultClass) {
      Validationstatus = false;
      setErrorMsg("Result Class is Required !");
    }

    if (!isResult) {
      if (!isPromoteClass) {
        Validationstatus = false;
      } else if (!selectNextSession?.value) {
        Validationstatus = false;
      }
    }

    if (!Validationstatus) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
        setErrorMsg("");
      }, 4000);
    }
    return Validationstatus;
  };
  const handleClear = () => {
    setIsResultClass(null);
    setIsPromoteClass(null);
    setSelectNextSession(null);
    setIsResult(false);
  };
  useEffect(() => {
    let session_label = SessionData.find(
      (s) => s.id === Number(session_id)
    )?.name;
    setSessionLabel(session_label);
  }, [session_id]);

  let sessionOption = nextSession?.map((s) => {
    return {
      value: s.id,
      label: s.name,
    };
  });
  const fetchSessionData = async () => {
    const body = {
      school_id: school_id,
    };
    try {
      let response = await StudentPromotionAPI.getNextSession(body);
      let responseData = response.data;
      setNextSession(responseData.next_session);
    } catch (err) {
      console.error(err);
    }
  };
  const checkNextSessionFees = async () => {
    const body = {
      school_id: school_id,
      next_seesion_academic_id: selectNextSession?.value,
      next_seesion_section_id: isPromoteClass?.value,
      current_academic_id: session_id,
      current_section_id: isResultClass?.value,
    };

    try {
      let response = await StudentPromotionAPI.checkNextSessionFees(body);
      let responseData = response.data;
      setNextSessionFees(responseData);
      return responseData;
    } catch (err) {
      console.error(err);
    }
  };

  useEffect(() => {
    fetchSessionData();
  }, [session_id]);
  useEffect(() => {}, [session_id]);

  useEffect(() => {
    setClassList(classsectionList?.payload);
  }, [classsectionList]);

  let classListOption = classList?.map((c) => {
    return {
      label: `${c.class_name} ${c.section}`,
      value: c.id,
    };
  });
  let s_data = {
    school_id: school_id,
  };
  useEffect(() => {
    if (token) {
      dispatch(getClassSection(token, s_data));
    }
  }, [dispatch, token, school_id]);
  const handleResultStudent = (select) => {
    setIsResultClass(select);
    setIsPromoteClass(null);
    let classListOpt = classList
      ?.filter((c) => c.id !== select.value)
      ?.map((c) => {
        return {
          label: `${c.class_name} ${c.section}`,
          value: c.id,
        };
      });
    setPromoteClassOption(classListOpt);
  };

  let handleProcess = async (data) => {
    if (selectedStudent?.length === 0) {
      Swal.fire({
        title: "",
        text: "Please Select Student ",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }

    Swal.fire({
      title: "CONFIRMATION",
      text: "Do you want to Process for Promotion ?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let list = selectedStudent?.map((p) => ({
          student_id: p.student_id,
          concession_id: p.concession_id,
        }));

        let body = {
          school_id: school_id,
          academic_id: session_id,
          section_id: isResultClass?.value,
          next_seesion_academic_id: selectNextSession?.value,
          next_seesion_section_id: isPromoteClass?.value,
          result_in_last_class: isResult ? "True" : "False",
          insert_by: school_emp_id,
          promoted_list: JSON.stringify(list),
        };
        const response = await StudentPromotionAPI.promoteStudentForNextSession(
          body
        );
        const responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          handleClear();
          setCurrentDiv(1);
        } else if (responseData.status === 500) {
          Swal.fire({
            text: responseData.msg,
            icon: "warning",
            allowOutsideClick: false,
          });
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } else if (result.isDenied) {
        setCurrentDiv(1);
        handleClear();
      }
    });
  };

  const handleNext = async () => {
    if (currentDiv === 1) {
      handleClear();
      setCurrentDiv(currentDiv + 1);
    } else if (currentDiv === 2) {
      const isValid = await validationControl();
      if (!isValid) return;
      const feesStructure = !isResult ? await checkNextSessionFees() : true;
      if (feesStructure.fees_status === "True") {
        setCurrentDiv(currentDiv + 1);
      } else if (feesStructure) {
        setCurrentDiv(currentDiv + 1);
      } else {
        await Swal.fire({
          title: "CONFIRMATION",
          text: "Do you want to Process for Promotion Without Fees Structure?",
          icon: "question",
          showDenyButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes",
          denyButtonText: "No",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            setCurrentDiv(currentDiv + 1);
          } else if (result.isDenied) {
            setCurrentDiv(1);
            handleClear();
          }
        });
      }
    }
  };

  const handleBack = () => {
    setCurrentDiv(currentDiv - 1);
  };
  if (isLoading) return <Loader />;
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="header-action">
              <h1 className="page-title">Student Promotion </h1>
              {/* <ol className="breadcrumb page-breadcrumb">
            <li className="breadcrumb-item active" aria-current="page">
              Certificates
            </li>
          </ol> */}
            </div>

            <div className="mt-2">
              {currentDiv > 1 && (
                <button
                  type="button"
                  className="btn btn-outline-secondary px-3 mx-2"
                  onClick={handleBack}
                >
                  <i className="fa fa-step-backward px-1"></i>
                  Back
                </button>
              )}
              {currentDiv < 3 && (
                <button
                  type="button"
                  className="btn btn-outline-secondary px-3 mx-2"
                  onClick={handleNext}
                >
                  Next<i className="fa fa-step-forward px-1"></i>
                </button>
              )}
              {currentDiv === 3 && (
                <button
                  type="button"
                  className="btn btn-outline-secondary px-3 mx-2"
                  onClick={handleProcess}
                  disabled={promotedList?.length > 0 ? false : true}
                >
                  Process
                </button>
              )}
              {currentDiv === 2 && (
                <button
                  type="button"
                  className="btn btn-outline-danger   px-3 mx-2"
                  onClick={handleClear}
                >
                  <i className="fa fa-times px-1"></i>
                  Clear
                </button>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          {currentDiv === 1 && (
            <PromoteStudentList session_id={session_id} school_id={school_id} />
          )}
          {currentDiv === 2 && (
            <div className="card">
              <div className="card-body">
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-12 form-group row">
                    <label className="col-form-label col-md-5">
                      Current Session<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Current Session"
                        defaultValue={sessionLabel}
                      />
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 form-group row">
                    <label className="col-form-label col-md-5">
                      Result Class<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <Select
                        className=""
                        options={classListOption}
                        placeholder="Select Class"
                        value={isResultClass}
                        onChange={handleResultStudent}
                        isSearchable={true}
                      />
                      {validationError && !isResultClass ? (
                        <ErrorDisplay errorMsg={errorMsg} />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-12 form-group row">
                    <label className="col-form-label col-md-6">
                      Result Class is Last Class
                      <span className="text-danger"></span>
                    </label>
                    <div className="col-md-6 d-flex align-items-center">
                      <input
                        type="checkbox"
                        checked={isResult}
                        onChange={() => setIsResult(!isResult)}
                        style={{ cursor: "pointer" }}
                      />
                    </div>
                  </div>
                </div>
                {!isResult && (<div className="row">
                  <div className="col-lg-5 col-md-6 col-sm-12 form-group row">
                    <label className="col-form-label col-md-5">
                      Promote To Session<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <Select
                        options={sessionOption}
                        placeholder="Promote To Session"
                        value={selectNextSession}
                        onChange={(select) => {
                          setSelectNextSession(select);
                        }}
                        isSearchable={true}
                      />
                      {validationError && !selectNextSession ? (
                        <ErrorDisplay errorMsg={"Next Session is Required !"} />
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-5 col-md-6 col-sm-12 form-group row">
                    <label className="col-form-label col-md-5">
                      Promote To Class <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7">
                      <Select
                        className=""
                        options={promoteClassOption}
                        placeholder="Select Class"
                        value={isPromoteClass}
                        onChange={(select) => {
                          setIsPromoteClass(select);
                        }}
                        isSearchable={true}
                      />
                      {validationError && !isPromoteClass ? (
                        <ErrorDisplay
                          errorMsg={"Promote Class is Required !"}
                        />
                      ) : null}
                    </div>
                  </div>
                </div>)}
                
              </div>
            </div>
          )}
          {currentDiv === 3 && (
            <ListForPromotion
            isResult={isResult}
              sessionLabel={sessionLabel}
              isPromoteClass={isPromoteClass}
              isResultClass={isResultClass}
              session_id={session_id}
              school_id={school_id}
              selectNextSession={selectNextSession}
              promotedList={promotedList}
              setPromotedList={setPromotedList}
              selectedStudent={selectedStudent}
              setSelectedStudent={setSelectedStudent}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default PromoteStudent;
