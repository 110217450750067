export const LOGIN_REQUIST='LOGIN_REQUIST'
export const LOGIN_SUCCESS="LOGIN_SUCCESS"
export const LOGIN_FAILD="LOGIN_FAILD"
export const CLEAR_ERRORS="CLEAR_ERRORS"

//  for forgot password 
export const FORGOT_PASSWORD_REQUEST=' FORGOT_PASSWORD_REQUIST'
export const  FORGOT_PASSWORD_SUCCESS=" FORGOT_PASSWORD_SUCCESS"
export const  FORGOT_PASSWORD_FAILD=" FORGOT_PASSWORD_FAILD"


// for verify_otp
export const VERIFY_OTP_REQUEST= 'VERIFY_OTP_REQUIST'
export const  VERIFY_OTP_SUCCESS= "VERIFY_OTP_SUCCESS"
export const  VERIFY_OTP_FAILD= "VERIFY_OTP_FAILD"


// for confirm_verified_password
export const CONFIRM_OTP_REQUEST= 'CONFIRM_OTP_REQUIST'
export const  CONFIRM_OTP_SUCCESS= "CONFIRM_OTP_SUCCESS"
export const  CONFIRM_OTP_FAILD= "CONFIRM_OTP_FAILD"

// Action Types
// export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// Action Creators
export const loginSuccess = (token, userType) => ({
  type: LOGIN_SUCCESS,
  payload: { token, userType },
});

export const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});
