import React from "react";
import img3 from "../../assets/image/NewErp.png";
import Footer from "../Layout/Footer";
const CustomPage = ({ children }) => {
  return ( 
    <div
      style={{ overflow: "hidden", background: "rgb(255,175,0)" }}
      className="resp-height"
    >
      <div
        className="d-flex flex-column"
        style={{
          height: "100%",
          background:
            "linear-gradient(0deg, rgba(255,175,0,1) 0%, rgba(25,54,87,1) 100%)",
        }}
      >
        <div
          className="col-xl-8 col-lg-10 col-md-10 col-11 box-margin"
          style={{
            margin: "auto",
            background:
              "linear-gradient(180deg, rgba(184,193,206,1) 0%, rgba(235,220,181,1) 100%)",
            border: 0,
            borderRadius: 8,
          }}
        >
          <div className="row px-md-5"></div>
          <div className="row px-md-5 px-3 py-lg-5 py-3">
            <div className="col-lg-6 px-md-5 pc-2 d-flex justify-content-center flex-column">
              <h1
                className="text-center  login_font py-3"
                style={{ color: "#0E487A", fontWeight: 900, fontSize: "2rem" }}
              >
                VidyaMate
              </h1>
              <img
                src={img3}
                alt=""
                style={{ width: "100%", borderRadius: "2rem" }}
              />
            </div>
            <div className="col-lg-6 px-md-5 px-3 mt-lg-0 mt-5">{children}</div>
          </div>
        </div>
        <Footer nonAuth={true}/>
      </div>
    </div>
  );
};

export default CustomPage;
