import axios from "axios";
import { BASE_URL } from "../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data,
  };
};


export const TeacherInOutapis = {
    getAttendanceByTeacher: async (data) => {
        const axiosConfig = createAxiosConfig("get_attendance_by_teacher", "POST", data);
        return await axios(axiosConfig);
      },  
 }