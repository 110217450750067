import React, { useState, useEffect } from "react";
import { NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import { Col, TabContent, TabPane } from "reactstrap";
import {
  CreateConnession,
  deleteMasterData,
  getMasterData,
  updateMasterData,
} from "../../../actions/super-user/MasterData";
import { useDispatch, useSelector } from "react-redux";
import MasterComponent from "./MasterComponent";
import CastMaster from "./CastMaster";
import CustomScrollbar from "../../CommonComponent/PerfectScrollbar";
import { getTitle } from "../../CommonComponent/helper";

const Master = () => {
  getTitle("Master");
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();
  const { createmasterData, masterData, loading } = useSelector(
    (state) => state.masterData
  );
  const [verticalActiveTab, setverticalActiveTab] = useState("cancelreason");
  const [isMasterData, setIsMasterData] = useState("cancelreason");
  const [getMaster, setGetMaster] = useState([]);
  const [classdata, setClassData] = useState([]);
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState(null);
  const [isValue, setIsValue] = useState("");

  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    setClassData(
      masterData?.payload?.filter((obj) => obj.name === isMasterData)
    );

    setGetMaster(masterData?.payload);
  }, [masterData, isMasterData]);

  const toggleVertical = (tab) => {
    if (verticalActiveTab !== tab) {
      setverticalActiveTab(tab);
      setIsMasterData(tab);
      setIsValue("");
      setEdit(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (edit) {
      const data = {
        name: isMasterData,
        value: isValue,
      };
      dispatch(updateMasterData(token, editId, JSON.stringify(data)))
        .then(() => {
          dispatch(getMasterData(token));
          setIsValue("");
          setEdit(false);
        })
        .catch((error) => console.error(error));
    } else {
      const data = {
        name: isMasterData,
        value: isValue,
      };

      dispatch(CreateConnession(token, JSON.stringify(data)))
        .then(() => {
          dispatch(getMasterData(token));
          setIsValue("");
        })
        .catch((error) => console.error(error));
    }
  };

  const onEdit = (data) => {
    setEdit(true);
    setEditId(data.id);
    setIsValue(data.value);
  };

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteMasterData(token, id)).then(() => {
          dispatch(getMasterData(token));
        });
      }
    });
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title"> Master</h1>
              {/* <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  <a href="#">Master</a>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  Master List
                </li>
              </ol> */}
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a className="nav-link active" data-toggle="tab" href="#master">
                  Master
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#castMaster">
                  Caste Master
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="tab-pane active" id="master">
              <div className="row mt-4">
                <div className="col-lg-3 col-md-12 col-sm-12">
                  <CustomScrollbar
                    style={{
                      height: "75vh",
                      paddingRight: ".5rem",
                      boxSizing: "content-box",
                    }}
                  >
                    <div className="card">
                      <div className="card-body">
                        <ul className="nav nav-tabs flex-column m-auto">
                          <li className="nav-item">
                            <a
                              className="nav-link active my_active-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("cancelreason");
                              }}
                            >
                              <span>Cancel Reason</span>
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link my_active-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("Concession");
                              }}
                            >
                              <span>Concession</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("designation");
                              }}
                            >
                              <span>Designation</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("document");
                              }}
                            >
                              <span>Document</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("Education");
                              }}
                            >
                              <span>Education</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("Occupation");
                              }}
                            >
                              <span>Occupation</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("MotherTongue");
                              }}
                            >
                              <span>Mother Tongue</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("Nationality");
                              }}
                            >
                              <span>Nationality</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("Religion");
                              }}
                            >
                              <span>Religion</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("SpecialFee");
                              }}
                            >
                              <span>Special Fee</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("Scholarship");
                              }}
                            >
                              <span>Scholarship</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("DiscountReason");
                              }}
                            >
                              <span>Discount Reason</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("Medium");
                              }}
                            >
                              <span>Medium</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("Hostel Type");
                              }}
                            >
                              <span>Hostel Type</span>{" "}
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={() => {
                                toggleVertical("Bed Type");
                              }}
                            >
                              <span>Bed Type</span>{" "}
                            </a>
                          </li>
                        </ul>{" "}
                      </div>
                    </div>
                  </CustomScrollbar>
                </div>
                <div className="col-lg-9 col-md-12 col-sm-12">
                  <Col className="col-lg-7 col-md-12 col-sm-12">
                    <TabContent
                      activeTab={verticalActiveTab}
                      className="text-muted mt-4 mt-md-0"
                    >
                      <TabPane tabId="cancelreason">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Cancel Reason"}
                        />
                      </TabPane>
                      <TabPane tabId="Concession">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Concession"}
                        />
                      </TabPane>
                      <TabPane tabId="designation">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Designation"}
                        />
                      </TabPane>
                      <TabPane tabId="document">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Document"}
                        />
                      </TabPane>
                      <TabPane tabId="Education">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={isMasterData}
                        />
                      </TabPane>
                      <TabPane tabId="Occupation">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={isMasterData}
                        />
                      </TabPane>
                      <TabPane tabId="MotherTongue">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Mother Tongue"}
                        />
                      </TabPane>
                      <TabPane tabId="Nationality">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={isMasterData}
                        />
                      </TabPane>
                      <TabPane tabId="Religion">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={isMasterData}
                        />
                      </TabPane>
                      <TabPane tabId="SpecialFee">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Special Fee"}
                        />
                      </TabPane>
                      <TabPane tabId="Scholarship">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Scholarship"}
                        />
                      </TabPane>
                      <TabPane tabId="DiscountReason">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Discount Reason"}
                        />
                      </TabPane>
                      <TabPane tabId="Medium">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Medium"}
                        />
                      </TabPane>
                      <TabPane tabId="Hostel Type">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Hostel Type"}
                        />
                      </TabPane>
                      <TabPane tabId="Bed Type">
                        <MasterComponent
                          classdata={classdata}
                          onDelete={onDelete}
                          onEdit={onEdit}
                          onSubmit={handleSubmit}
                          isValue={isValue}
                          setIsValue={setIsValue}
                          edit={edit}
                          title={"Bed Type"}
                        />
                      </TabPane>
                    </TabContent>
                  </Col>
                </div>
              </div>
            </div>

            <div className="tab-pane" id="castMaster">
              <CastMaster />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Master;
