import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
import {
  CREATE_DESI_WISE_MANU_FAILD,
  CREATE_DESI_WISE_MANU_REQUIST,
  CREATE_DESI_WISE_MANU_SUCCESS,
  GET_DESI_WISE_MANU_FAILD,
  GET_DESI_WISE_MANU_REQUIST,
  GET_DESI_WISE_MANU_SUCCESS,
  RESET_USER_MENU,
} from "../../Constants/SuperUser/UserAuthtication";

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const getDesignationWiseMenu = (mdata) => async (dispatch) => {
  try {
    dispatch({ type: GET_DESI_WISE_MANU_REQUIST });
    const response = await axios.post(
      `${BASE_URL}get_designationWiseMenu/`,
      mdata,
      config
    );
    const data = response.data;
    dispatch({ type: GET_DESI_WISE_MANU_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_DESI_WISE_MANU_FAILD,
      payload: error,
    });
  }
};
export const addUpdatedesignationWiseRole = (mdata) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_DESI_WISE_MANU_REQUIST });
    const response = await axios.post(
      `${BASE_URL}addUpdate_designationWiseRole/`,
      mdata,
      config
    );
    const data = response.data;
    dispatch({ type: CREATE_DESI_WISE_MANU_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({
      type: CREATE_DESI_WISE_MANU_FAILD,
      payload: error,
    });
  }
};

export const resetUserMenu = () => ({
  type: RESET_USER_MENU,
});
