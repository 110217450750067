import axios from "axios";
import { BASE_URL} from "../../Config/BaseUrl";
const token = localStorage.getItem("token");
const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`, 
      "Content-Type": "application/json",
    },
    data,
  };
};
export const DriverMasterAPI = {
    getDrivermasterdata : async (data) => {
        const axiosConfig = createAxiosConfig("get_Drivermasterdata","POST", data); 
        return await axios(axiosConfig);
      },
      saveUpdateDrivermaster: async (data) => {
        const axiosConfig = createAxiosConfig("save_update_Drivermaster","POST", data);
        return await axios(axiosConfig);
      },
      deleteDrivermaster: async (data) => {
        const axiosConfig = createAxiosConfig("delete_Drivermaster","POST", data);
        return await axios(axiosConfig);
      },

}