import React from "react";

function ErrorDisplay({ errorMsg }) {
  return (
    <div className="popup-message">
      <i className="fa fa-exclamation-circle"></i>
      {errorMsg}
    </div>
    // <div className="error-message">
    //   {errorMsg}
    // </div>
  );
}

export default ErrorDisplay;
