import React, { useState, useEffect } from 'react';
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Table
} from 'reactstrap';
import useLocalStorageData from '../Comman/LocalStorageData';
import { BusMasterApi } from "../../../api/Transport/BusMasterAPI";
import EmptyListMessage from '../../CommonComponent/EmptyListMessage';

function SearchBus(props) {
  const { school_id } = useLocalStorageData();
  const [busDetails, setBusDetails] = useState([]);
  const { setBusDetailsId } = props;
  const [open, setOpen] = useState(false);
  const [focusAfterClose, setFocusAfterClose] = useState(true);

  const get_Busmaster_data = async () => {
    try {
      let data = {
        school_id: school_id
      }

      const response = await BusMasterApi.BusMasterListData(data);
      const responseData = response.data;
      const busResponse = responseData.bus_data;

      if (responseData.status === 200) {
        setBusDetails(busResponse);
      } else {
        // Handle error
      }
    } catch (err) {
      console.error(err);
    }
  }

  useEffect(() => {
    get_Busmaster_data();
  }, []);


  const toggle = () => {
    setOpen(!open);
    if (!open) {
      get_Busmaster_data();
    }
  };

  const handleSelectGroup = (Id) => {
    setBusDetailsId(Id);
    toggle();
  };

  return (
    <>
      <button
        className="btn btn-outline-secondary btn-sm mx-1"
        type="button"
        onClick={() => toggle()}
      >
        <i className="fa fa-search"> </i> Search
      </button>
      <div>
        <Modal returnFocusAfterClose={focusAfterClose} isOpen={open} fullscreen className="mobel_box">
          <ModalBody>
            <div className="table-responsive">
              {busDetails && busDetails.length > 0 ? (
                <Table size="sm" className="table-bordered" striped>
                  <thead className="table-dark">
                    <tr className="text-center text-nowrap ">
                      <th>Sr No.</th>
                      <th>Registration No</th>
                      <th>Registration Year</th>
                      <th>Engine No</th>
                      <th>Chesis No</th>
                      <th>Battery No</th>
                      <th>Manufacturer</th>
                      <th>Model</th>
                      <th>PUC No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {busDetails.map((stu, index) => (
                      <tr
                        key={index}
                        className="clickable-row-color"
                        onClick={() => handleSelectGroup(stu.id)}
                      >
                        <td className="text-center">{index + 1}</td>
                        <td>{stu.registration_no}</td>
                        <td>{stu.registration_year}</td>
                        <td>{stu.engine_no}</td>
                        <td>{stu.chesis_no}</td>
                        <td>{stu.battery_no}</td>
                        <td>{stu.manufacturer}</td>
                        <td>{stu.model}</td>
                        <td>{stu.puc_no}</td>
                        
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <EmptyListMessage
                  message={"Bus Details Not Available !"}
                  Div="div"
                />
              )}
            </div>
          </ModalBody>
          <ModalFooter>
            <Button color="primary" onClick={toggle}>
              Close
            </Button>
          </ModalFooter>
        </Modal>
      </div>
    </>
  );
}

export default SearchBus;
