import React from "react";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";

const TimeTableList = ({ time_tabtable_list }) => {
  let colRow = [];
  return (
    <div className="table-responsive">
      {time_tabtable_list?.length > 0 ? (
        <table className="table table-sm table-hover table-bordered text-nowrap small-table ">
          <thead className="thead-light">
            <tr className="text-center" >
              <th style={{ fontWeight: "bold" }}>Class </th>
              <th colSpan={colRow} style={{ fontWeight: "bold" }}>Subject / Time</th>
            </tr>
          </thead>
          <tbody>
            {time_tabtable_list?.map((classData, index) => {
              let timeLabel;
              let timeData;
              return (
                <tr key={index} >
                  <td className="align-middle text-center" style={{ fontWeight: "bold" }}>{classData.section}</td>
                  {classData?.timetable?.map((timeSlot, tIndex) => {
                    timeLabel = Object.keys(timeSlot)[0];
                    timeData = timeSlot[timeLabel];
                    colRow.push(classData?.timetable?.length);

                    return (
                      <React.Fragment key={tIndex}>
                        {timeData?.length > 0
                          ? timeData?.map((t) => (
                              <td>
                                {t.fk_subject_teacher__fk_subject__subject_name}
                                <br />
                                <span>{` ${t.fk_period_time__from_time} - 
                               ${t.fk_period_time__to_time}  `}</span>
                              </td>
                            ))
                          :  <td className="align-middle text-center">--NA--</td>}
                      </React.Fragment>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      ) : (
        <EmptyListMessage
          message={"Time-Table Not Available !"}
          Div="div"
          size={"1.2rem"}
        />
      )}
    </div>
  );
};

export default TimeTableList;
