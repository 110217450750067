import React, { useRef, useState } from "react";
import pic1 from "../../../assets/images/dummy.png";
const ImageUpload = (props) => {
  const { formik, fieldName, showImg, setShowImg, fieldName1 } = props;
  const fileInputRef = useRef(null);
  const [showRemoveIcon, setShowRemoveIcon] = useState(false);

  
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  const handleFileRead = async (e) => {
    const file = e.target.files[0];
    formik.setFieldValue(fieldName, file);
    const base64 = await convertBase64(file);
    setShowImg(base64);
    // setShowRemoveIcon(true);
  };

  const handleRemoveImage = () => {
    formik.setFieldValue(fieldName, "");
    formik.setFieldValue(fieldName1, "True");
    setShowImg("");
    // setShowRemoveIcon(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };

  return (
    <>
      <div
        className="d-flex justify-content-center mb-5 position-relative"
        onMouseEnter={() => setShowRemoveIcon(true)}
        onMouseLeave={() => setShowRemoveIcon(false)}
      >
        <img
          alt="profile"
          src={showImg ? showImg : pic1}
          className="crop_img"
          width={100}
        />

        {showImg && (
          <span
            className=""
            // onMouseEnter={() => setShowRemoveIcon(true)}
            // onMouseLeave={() => setShowRemoveIcon(false)}
          >
            {showRemoveIcon && (
              <span
                className="image-remove position-absolute top-0 start-0"
                onClick={handleRemoveImage}
                data-toggle="tooltip"
                data-placement="top"
                title={"Remove"}
              >
                <i className="fa fa-times"></i>
              </span>
            )}
          </span>
        )}
      </div>
      <div className="form-group row">
        <div className="col-md-10 mx-auto">
          <input
            style={{ width: "100%" }}
            type="file"
            ref={fileInputRef}
            className="dropify"
            name={fieldName}
            accept="image/*"
            onChange={handleFileRead} 
          />
        </div>
      </div>
    </>
  );
};

export default ImageUpload;
