import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};
export const markEntryAPI = {
  createMarkEntry: async (data) => {
    const axiosConfig = createAxiosConfig("save_MarkEntryapi", "POST", data);
    return await axios(axiosConfig);
  },
  getClassWiseExamsList: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_ClassWiseExamsList",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
  getClassWiseExamCategory: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_ClassWiseExamCategory",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
  getMarkEntryListClassWise: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_MarkEntryListClassWise",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
};
export const examDetailsAPI = {
  createExamDetail: async (data) => {
    const axiosConfig = createAxiosConfig("save_updateExamDetails", "POST", data);
    return await axios(axiosConfig);
  },
  getExamDetails: async (data) => {
    const axiosConfig = createAxiosConfig("get_ExamDetailsApi", "POST", data);
    return await axios(axiosConfig);
  },
  deleteExamDetail: async (data) => {
    const axiosConfig = createAxiosConfig(
      "delete_examDetails",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
};
