import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
import Swal from "sweetalert2";
import {
  CREATE_ROLL_NO_FAILURE,
  CREATE_ROLL_NO_REQUEST,
  CREATE_ROLL_NO_SUCCESS,
  GET_ROLL_NO_FAILURE,
  GET_ROLL_NO_REQUEST,
  GET_ROLL_NO_SUCCESS,
} from "../../Constants/school_user/RollNoAllotment";

export const getRollNoList = (token, stu_data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ROLL_NO_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_addmitted_student_with_section/`,
        stu_data,
        config
      );
      const data = response.data;
      dispatch({ type: GET_ROLL_NO_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_ROLL_NO_FAILURE,
        payload: error,
      });
    }
  };
};
export const createRollNo = (token, stu_data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_ROLL_NO_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}assign_student_roll_no/`,
        stu_data,
        config
      );
      const data = response.data;
      dispatch({ type: CREATE_ROLL_NO_SUCCESS, payload: data });
      return data
    } catch (error) {
      dispatch({
        type: CREATE_ROLL_NO_FAILURE,
        payload: error,
      });
    }
  };
};
