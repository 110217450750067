import {
  CREATE_HOLIDAY_FAILURE,
  CREATE_HOLIDAY_REQUEST,
  CREATE_HOLIDAY_SUCCESS,
  DELETE_HOLIDAY_FAILURE,
  DELETE_HOLIDAY_REQUEST,
  DELETE_HOLIDAY_SUCCESS,
  GET_HOLIDAY_FAILURE,
  GET_HOLIDAY_REQUEST,
  GET_HOLIDAY_SUCCESS,
  UPDATE_HOLIDAY_FAILURE,
  UPDATE_HOLIDAY_REQUEST,
  UPDATE_HOLIDAY_SUCCESS,
} from "../../Constants/school_user/HolidayMaster";

const initialState = {
  holidaydata: null,
  createholiday: null,
  updateholiday: null,
  deleteholiday: null,
  loading: false,
  error: null,
};

export const holidayMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_HOLIDAY_REQUEST:
    case CREATE_HOLIDAY_REQUEST:
    case UPDATE_HOLIDAY_REQUEST:
    case DELETE_HOLIDAY_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_HOLIDAY_SUCCESS:
      return {
        ...state,
        holidaydata: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_HOLIDAY_SUCCESS:
      return {
        ...state,
        createholiday: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_HOLIDAY_SUCCESS:
      return {
        ...state,
        updateholiday: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_HOLIDAY_SUCCESS:
      return {
        ...state,
        deleteholiday: action.payload,
        loading: false,
        error: null,
      };
    case GET_HOLIDAY_FAILURE:
    case CREATE_HOLIDAY_FAILURE:
    case UPDATE_HOLIDAY_FAILURE:
    case DELETE_HOLIDAY_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
