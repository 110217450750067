import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";

import {
  createClassSubject,
  getClassSubjectList,
  getSubjectList,
} from "../../../../actions/school_user/SubjectMaster";
import { getTeacherList } from "../../../../actions/school_user/StaffMaster";
import Swal from "sweetalert2";
import useSessionRedirect from "../../Comman/SessionChange";
import ValidationComponent from "../../Comman/Validation";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import CustomScrollbar from "../../../CommonComponent/PerfectScrollbar";
import { subjectTypeList } from "../../../../data/optionData";
import { SelectField } from "../../../CommonComponent/SelectField";

const ClassSubject = () => {
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  const dispatch = useDispatch();
  const { subjectdata } = useSelector((state) => state.subject);
  const { classsubject } = useSelector((state) => state.classSubject);
  const { classsectionList } = useSelector((state) => state.attendance);
  const { staffdata } = useSelector((state) => state.staffMaster);
  const [classList, setClassList] = useState([]);
  const [subjectList, setsubjectList] = useState([]);
  const [classSubjectList, setClassSubjectList] = useState([]);
  const [selectedTeacher, setSelectedTeacher] = useState(null);
  const [classSection, setClassSection] = useState(null);
  const [edit, setedit] = useState(false);
  useEffect(() => {
    if (token) {
      dispatch(getSubjectList(token, school_id));
    }
  }, [dispatch, token]);

  useEffect(() => {
    setsubjectList(subjectdata?.payload);
  }, [subjectdata]);
  useEffect(() => {
    if (token) {
      dispatch(getTeacherList(token, school_id));
    }
  }, [dispatch, token]);

  useEffect(() => {
    setClassList(classsectionList?.payload);
  }, [classsectionList]);
  let classSectionList = classList
    ?.map((c) => {
      return {
        label: `${c.class_name} ${c.section}`,
        value: c.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  let staffList = staffdata?.payload
    ?.map((school) => {
      return {
        label: `${school.first_name} ${school.last_name}`,
        value: school.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  let sub_data = {
    academic_id: session_id,
    school_id: school_id,
    section_id: classSection?.value,
  };
  useEffect(() => {
    if (token && classSection) {
      dispatch(getClassSubjectList(token, sub_data));
    }
  }, [dispatch, token, classSection, session_id]);

  useEffect(() => {
    if (classSection?.value) {
      setClassSubjectList(classsubject?.payload);
      setSelectedTeacher(classsubject?.payload?.staff_id);
    } else {
      setClassSubjectList([]);
      setSelectedTeacher(null);
    }
  }, [classsubject, classSection]);

  const initialSubjectInfo = subjectList?.map((sub) => ({
    id: "",
    subject_name: sub.subject_name,
    subject_id: sub.id,
    checked: false,
    serial_no: "",
    staff_id: null,
    subject_type: null,
  }));

  const [subjectInfo, setSubjectInfo] = useState(initialSubjectInfo);
  useEffect(() => {
    if (classSubjectList?.subject_data) {
      const subjectMap = new Map(
        initialSubjectInfo?.map((subject) => [subject.subject_id, subject])
      );
      classSubjectList?.subject_data?.forEach((item) => {
        const matchingSubject = subjectMap.get(item.fk_subject_id);
        if (matchingSubject) {
          matchingSubject.id = item.id;
          matchingSubject.checked = true;
          matchingSubject.serial_no = item.serial_no;
          matchingSubject.staff_id = item.fk_staff_id;
          matchingSubject.subject_type = item.subject_type;
        }
      });
      setSubjectInfo(initialSubjectInfo);
    } else {
      setSubjectInfo(initialSubjectInfo);
    }
  }, [subjectdata?.payload, classSubjectList]);

  const handleCheckboxChange = (index) => {
    const updatedSubjectInfo = [...subjectInfo];
    updatedSubjectInfo[index].checked = !updatedSubjectInfo[index].checked;
    setSubjectInfo(updatedSubjectInfo);
  };

  const handleSerialNumberChange = (index, value) => {
    const updatedSubjectInfo = [...subjectInfo];
    updatedSubjectInfo[index].serial_no = value;
    setSubjectInfo(updatedSubjectInfo);
  };

  const handleTeacherSelect = (index, staff_id) => {
    const updatedSubjectInfo = [...subjectInfo];
    updatedSubjectInfo[index].staff_id = staff_id;
    setSubjectInfo(updatedSubjectInfo);
  };
  const handleSubjectTypeSelect = (index, subject_type) => {
    const updatedSubjectInfo = [...subjectInfo];
    updatedSubjectInfo[index].subject_type = subject_type;
    setSubjectInfo(updatedSubjectInfo);
  };

  const { validationError, validateControl } = ValidationComponent({
    data: { selectedTeacher, classSection },
    requiredFields: ["selectedTeacher", "classSection"],
  });

  const sendDataToServer = async () => {
    const isValid = await validateControl();

    const selectedSubjects = subjectInfo?.filter((subject) => subject.checked);
    const subjectDataArray = selectedSubjects?.map((subject, index) => ({
      id: "",
      staff_id: subject.staff_id,
      subject_id: subject.subject_id,
      serial_no: subject.serial_no,
      subject_name: subject.subject_name,
      subject_type: subject.subject_type,
    }));
    const filteredSubjectDataArray = subjectDataArray?.filter(
      (item) =>
        item.staff_id !== null &&
        item.serial_no !== null ||
        item.subject_type !== null
    );
    const data = {
      classteeacher_id: classSubjectList?.id || "",
      academic_id: session_id,
      school_id: school_id,
      section_id: classSection?.value,
      staff_id: selectedTeacher,
      subject_data: JSON.stringify(filteredSubjectDataArray),
    };

    if (isValid) {
      let response = await dispatch(createClassSubject(token, data));
      if (response.status === 200) {
        setClassSection(null);
        setSelectedTeacher(null);
      }
    }
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Class Subject</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body row">
                  <div className="col-md-6">
                    <div className="form-group col-md-12 row">
                      <label className="col-md-4 col-form-label">
                        Class Section<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-8">
                        <Select
                          options={classSectionList}
                          placeholder="Select Class"
                          value={classSection}
                          onChange={(e) => setClassSection(e)}
                          isSearchable={true}
                        />
                        {validationError && !classSection ? (
                          <ErrorDisplay errorMsg={"Class is required !"} />
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-md-12 row">
                      <label className="col-md-4 col-form-label">
                        Class Teacher<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-8">
                        <Select
                          options={staffList}
                          placeholder="Select Teacher"
                          value={
                            selectedTeacher
                              ? staffList?.find(
                                  (option) => option.value === selectedTeacher
                                )
                              : null
                          }
                          onChange={(selectedOption) =>
                            setSelectedTeacher(selectedOption.value)
                          }
                          isSearchable={true}
                        />
                        {validationError && !selectedTeacher ? (
                          <ErrorDisplay errorMsg={"Teacher is required !"} />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6">
                    <div className="d-flex justify-content-start align-item-center">
                      <button
                        onClick={sendDataToServer}
                        type="button"
                        className="mr-2 btn btn-primary px-4 text-center"
                      >
                        {" "}
                        <i className="fa fa-plus pr-1"></i>
                        {edit ? "Update" : "Save"}
                      </button>
                      {/* <button
                        // onClick={handleCancel}
                        className="btn btn-outline-secondary px-4  text-center"
                      >
                        Cancel
                      </button> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CustomScrollbar
              style={{
                maxHeight: "53vh",
                paddingRight: "1rem",
                boxSizing: "content-box",
              }}
            >
              <div className="table-responsive card">
                <table className="table table-hover table-vcenter table-striped table-sm text-nowrap">
                  <thead>
                    <tr className="text-center">
                      <th></th>
                      <th>Subject</th>
                      <th>Serial No</th>
                      <th>Subject Teacher</th>
                      <th>Subject Type</th>
                    </tr>
                  </thead>
                  <tbody>
                    {subjectInfo?.map((subject, index) => (
                      <tr key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={subjectInfo[index]?.checked || false}
                            onChange={() => handleCheckboxChange(index)}
                          />
                        </td>
                        <td>{subject.subject_name}</td>
                        {/* <td>{subject.subject_id}</td> */}
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Serial No"
                            value={subjectInfo[index]?.serial_no || ""}
                            onChange={(e) =>
                              handleSerialNumberChange(index, e.target.value)
                            }
                            required
                          />
                        </td>
                        <td>
                          <SelectField
                            options={staffList}
                            placeholder="Select Teacher"
                            value={subjectInfo[index]?.staff_id}
                            onChange={(selected) =>
                              handleTeacherSelect(
                                index,
                                selected ? selected.value : null
                              )
                            }
                          />
                        </td>
                        <td>
                          <SelectField
                            options={subjectTypeList}
                            placeholder="Select Subject Type"
                            value={subjectInfo[index]?.subject_type}
                            onChange={(selected) =>
                              handleSubjectTypeSelect(
                                index,
                                selected ? selected.value : null
                              )
                            }
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CustomScrollbar>
          </div>
        </div>
      </div>
    </>
  );
};

export default ClassSubject;
