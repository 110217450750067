import {
  GET_CLASS_SECTION_FAILURE,
  GET_CLASS_SECTION_REQUEST,
  GET_CLASS_SECTION_SUCCESS,
  GET_STUDENTLIST_FAILURE,
  GET_STUDENTLIST_REQUEST,
  GET_STUDENTLIST_SUCCESS,
  GET_STUDENTREPORT_FAILURE,
  GET_STUDENTREPORT_REQUEST,
  GET_STUDENTREPORT_SUCCESS,
  SAVE_STUDENTATTENDANCE_FAILURE,
  SAVE_STUDENTATTENDANCE_REQUEST,
  SAVE_STUDENTATTENDANCE_SUCCESS,
  TAKE_STUDENTATTENDANCE_FAILURE,
  TAKE_STUDENTATTENDANCE_REQUEST,
  TAKE_STUDENTATTENDANCE_SUCCESS,
} from "../../Constants/school_user/Attendance";

const initialState = {
  classsectionList: [],
  studentdata: [],
  takeAttendance: null,
  studentreport: null,
  loading: false,
};

export const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    // case "RESET_STATE":
    //   return initialState;
    case GET_CLASS_SECTION_REQUEST:
    case GET_STUDENTLIST_REQUEST:
    case TAKE_STUDENTATTENDANCE_REQUEST:
    case GET_STUDENTREPORT_REQUEST:
    case SAVE_STUDENTATTENDANCE_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLASS_SECTION_SUCCESS:
      return {
        ...state,
        loading: false,
        classsectionList: action.payload,
      };
    case GET_STUDENTLIST_SUCCESS:
      return {
        ...state,
        loading: false,
        studentdata: action.payload,
      };
    case TAKE_STUDENTATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        takeAttendance: action.payload,
      };
    case SAVE_STUDENTATTENDANCE_SUCCESS:
      return {
        ...state,
        loading: false,
        attendance: action.payload,
      };
    case GET_STUDENTREPORT_SUCCESS:
      return {
        ...state,
        loading: false,
        studentreport: action.payload,
      };
    case GET_CLASS_SECTION_FAILURE:
    case GET_STUDENTLIST_FAILURE:
    case TAKE_STUDENTATTENDANCE_FAILURE:
    case GET_STUDENTREPORT_FAILURE:
    case SAVE_STUDENTATTENDANCE_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
