import React from "react";
import { Link, NavLink, useNavigate } from "react-router-dom";
import { useContext } from "react";
import { SessionContext } from "./../../context/sessionContext.js";
import StudentList from "./StudentList.js";
import Time from "./Time.js";
import Notification from "../schooladmin/Dashboard/Notification.jsx";
import SchoolList from "./SchoolList.js";


const Header = ({ school_user }) => {
  const { SessionData, Session, setSession, handlesessionselect } =
    useContext(SessionContext);
  const { user_type, name, user_detail } = school_user;


 




  return (
    <div className="section-body" id="page_top">
      <div className="container-fluid">
        {user_type === "Admin" && (
          <div className="page-header col-lg-12 col-md-12 col-sm-12 ">
            <div className="left col-lg-4 col-sm-12">
              <div className="">
                <h1 className="card-title">Welcome to Vidyamate</h1>
              </div>
            
            </div>
            <div className="right d-flex justify-content-end align-item-right col-lg-8 col-sm-12">
              <SchoolList />
              <Time />
              <div className="notification d-flex">
              <div className="dropdown d-flex m-1"> 
              <Notification  user_type={user_type}  name={name}/>
              </div>
                <div className="dropdown d-flex">
                  <Link
                    href="javascript:void(0)"
                    className="chip ml-1"
                    data-toggle="dropdown"
                  >
                    {/* <span
                      className="avatar"
                      style={{
                        backgroundImage: "url(../assets/images/xs/avatar5.jpg)",
                      }}
                    ></span> */}
                    {user_detail?.first_name
                      ? `${user_detail?.first_name.charAt(0).toUpperCase()}`
                      : "A"}
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    <Link className="dropdown-item" to="/admin/super-admin-profile">
                      <i className="dropdown-icon fe fe-user"></i> Profile
                    </Link>

                    <div className="dropdown-divider"></div>
                    {/* <a className="dropdown-item" href="javascript:void(0)">
                      <i className="dropdown-icon fe fe-help-circle"></i> Need
                      help?
                    </a> */}
                    <NavLink className="dropdown-item" to="/logout">
                      <i className="dropdown-icon fe fe-log-out"></i> Sign out
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {user_type !== "Admin" && (
          <div className="page-header">
            <div className="left">
              <>
                Session
                <div className="col-md-4">
                  <select
                    className="form-control input-height"
                    name="class"
                    value={Session}
                    onChange={handlesessionselect}
                    placeholder="Select Session"
                  >
                    {SessionData?.map((data, index) => (
                      <option key={index} value={data.id}>
                        {data.name.toUpperCase()}
                      </option>
                    ))}
                  </select>
                </div>
              </>

              <div className="col-md-8">
                <h1 className="card-title text-right">
                  {user_detail?.school_name}
                </h1>
              </div>
            </div>

            <div className="right d-flex justify-content-end align-item-right">
              <div className="input-group mx-2">
                <StudentList SessionID={Session} />
              </div>
              <div className="notification d-flex">
                <div className="dropdown d-flex"> 
                {/* imported a component for bell dropdown here ../schooladmin/Administration/Dashboard/BellDropdown.jsx" */}
                   <Notification />
                   </div>
                <div className="dropdown d-flex">
  
                 
                  <Link
                    href="javascript:void(0)"
                    className="chip ml-1"
                    data-toggle="dropdown"
                  >
                    {/* <span
                      className="avatar"
                      style={{
                        backgroundImage: "url(../assets/images/xs/avatar5.jpg)",
                      }}
                    ></span> */}
                    {user_detail?.first_name
                      ? `${user_detail?.first_name.charAt(0).toUpperCase()}`
                      : "A"}
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
                    <Link className="dropdown-item" to="/school/profile">
                      <i className="dropdown-icon fe fe-user"></i> Profile
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/school/change-Password"
                    >
                      <i className="dropdown-icon fa fa-cog"></i> Change
                      Password
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/school/help"
                    >
                    <i className="dropdown-icon fe fe-help-circle"></i> Need help?
                    </Link>
                    <div className="dropdown-divider"></div>
                    <NavLink className="dropdown-item" to="/logout">
                      <i className="dropdown-icon fe fe-log-out"></i> Sign out
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Header;
