import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";

const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

export const salaryGenerationAPI = {
  salaryDetailsView: async (data) => {
    const axiosConfig = createAxiosConfig(
      "salary_details_view",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  saveSalaryGenerated: async (data) => {
    const axiosConfig = createAxiosConfig(
      "save_salary_generated",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  getSalarySlipDetails: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_salary_slip_details",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },

  deleteSalaryGenerated: async (data) => {
    const axiosConfig = createAxiosConfig(
      "delete_salary_generated",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },


  deleteSalaryGenerated: async (data) => {
    const axiosConfig = createAxiosConfig(
      "delete_salary_generated",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },






}