import React, { useEffect, useState } from "react";
import { Heading } from "../../../CommonComponent/heading";
import { SelectField } from "../../../CommonComponent/SelectField";
import { useSelector } from "react-redux";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useSessionRedirect from "../../Comman/SessionChange";
import { ExamsAPI } from "../../../../api/Examination/Exam";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
import { ExportButton } from "../../../CommonComponent/ReusableButton";
import Swal from "sweetalert2";
const ResultReport = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { allClass } = useSelector((state) => state.getClass);
  const [selectClass, setSelectClass] = useState(null);
  const [resultReportList, setResultReportList] = useState([]);

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });
  const fetchGroup = async () => {
    if (!selectClass){
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Please Select Class",
      })
      return
    }
    let body = {
      school_id: school_id,
      academic_id: session_id,
      class_id: selectClass,
    };
    try {
      let response = await ExamsAPI.getStudentResultReport(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        setResultReportList(responseData.report_list);
      }
    } catch (error) {
      console.error(error);
    }
  };

  // useEffect(() => {
  //   fetchGroup();
  // }, [session_id, school_id, selectClass]);
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <Heading pageTitle={"Result Report"}></Heading>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body row">
              <div className="form-group col-lg-4 col-md-6 row">
                <label className="col-md-3 col-form-label">
                  Class<span className="text-danger">*</span>
                </label>
                <div className="col-md-9">
                  <SelectField
                    name="class_id"
                    placeholder="Select Class..."
                    options={classList}
                    value={selectClass}
                    onChange={(select) => {
                      setSelectClass(select?.value);
                    }}
                  />
                </div>
              </div>
              <div className="form-group col-lg-4 col-md-6 ">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mx-1"
                onClick={fetchGroup}
              >
                <i className="fa fa-eye  px-1"></i>
                View
              </button>
              { resultReportList.length > 0 &&  (
              <ExportButton id="test-table-xls-button" />)}
              </div>
            </div>
          </div>

          <div className="card">
            <div className="card-body">
              {resultReportList && resultReportList.length > 0 ? (
                <div className="table-responsive">
                  <table className="table table-bordered table-sm table-hover mb-0 text-nowrap text-center" id="table-to-xls">
                    <thead>
                      <tr>
                        <th>Sr No</th>
                        <th>Student Code</th>
                        <th>Class</th>
                        <th>Student Name</th>
                        <th>Marks</th>
                        <th>Percentage</th>
                        <th>Rank</th>
                      </tr>
                    </thead>
                    <tbody>
                      {resultReportList?.map(({ section, result }) => (
                        <>
                          <tr className="table-secondary text-success text-left ">
                            <td colSpan={7}>
                              Class : <span className="">{`${section}`}</span>
                            </td>
                          </tr>
                          {result?.map(({ exam_name, result_report }) => (
                            <>
                              <tr className="table-secondary text-success text-left ">
                                <td colSpan={7}>
                                  Exam Name :{" "}
                                  <span className="">{`${exam_name}`}</span>
                                </td>
                              </tr>
                              {result_report?.map((res, stuIndex) => (
                                <tr>
                                  <td>{stuIndex + 1}</td>
                                  <td>{res.student_code}</td>
                                  <td>{res.section}</td>
                                  <td className="text-left">{res.name}</td>
                                  <td>
                                    {res.obtain_marks} / {res.total_maxMarks}{" "}
                                  </td>
                                  <td>{res.percentage} %</td>
                                  <td>{res.student_rank} </td>
                                </tr>
                              ))}
                            </>
                          ))}
                        </>
                      ))}
                    </tbody>
                  </table>
                </div>
              ) : (
                <EmptyListMessage
                  message={"Result Not Available !"}
                  Div="div"
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResultReport;
