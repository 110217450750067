import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

export const SchoolImage = {
  fetchImageFromAPI: async (schoolId) => {
    try {
      const data = { school_id: schoolId }; 
      const axiosConfig = createAxiosConfig("get_School_image", "POST", data);
      const response = await axios(axiosConfig);

      if (response.status === 200 && response.data.base64_image) {
        return response.data.base64_image;
      } else {
        console.error('Image fetching failed:', response.data.msg);
        return null;
      }
    } catch (error) {
      console.error('Error fetching data:', error);
      return null;
    }
  },
};