import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";

const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};
export const SalaryHeadMasterAPI = {
    add_update_salary_heads: async (data) => {
    const axiosConfig = createAxiosConfig(
      "add_update_salary_heads",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  get_salary_heads: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_salary_heads",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  deleteSalaryHeads: async (data) => {
    const axiosConfig = createAxiosConfig(
      "delete_salary_heads",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },

};