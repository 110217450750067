import React, { useState, useEffect, useRef, Suspense, lazy } from "react";
import { FaCloudUploadAlt } from "react-icons/fa";
import { Link, useNavigate, NavLink, useParams } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getMasterData } from "../../../actions/super-user/MasterData";
import {
  addDistrict,
  getCity,
  getState,
} from "../../../actions/super-user/SchoolRegistration";
import {
  deleteStudentReg,
  getCast,
  getCategory,
  updateStudentReg,
} from "../../../actions/school_user/StudentRegistration";
const LazyStudentList = lazy(() => import("./StudentList"));
import useSessionRedirect from "../Comman/SessionChange";
import ImageUpload from "./ImageUpload";
import { HeadingBreadcrumb } from "../../CommonComponent/heading";
import { currentDate } from "../../CommonComponent/DateFormatUtil";
import { bloodGroupList, countaryOptions, genderOptions } from "../../../data/optionData";
import useEnsureId from "../Comman/useEnsureId";

const EditStudentRegistration = () => {
  const token = localStorage.getItem("token");
  // const { id } = useParams();
  const id = useEnsureId();
  const school_user = JSON.parse(localStorage.getItem("school_user"));

  let school_id = school_user?.user_detail?.fk_school_id;
  const session_id = useSessionRedirect();

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { statelist, citylist } = useSelector((state) => state.schoolRegister);
  const { masterData } = useSelector((state) => state.masterData);
  const { cast, category, createstudent, studentdata } = useSelector(
    (state) => state.studentRegistration
  );
  const [selectedState, setSelectedState] = useState("");
  const [ShowImg1, setShowImg1] = useState("");
  const [ShowImg2, setShowImg2] = useState("");
  const [ShowImg3, setShowImg3] = useState("");
  const [getMaster, setGetMaster] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(null);
  const [stateOptions, setStateOptions] = useState([]);
  const [cityOptions, setCityOptions] = useState([]);
  const [districtOptions, setdistrictOptions] = useState([]);
  const [statedistrictOptions, setstatedistrictOptions] = useState([]);
  const [remark, setRemark] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [uploadedDocuments, setUploadedDocuments] = useState([]);
  const [isDocument, setIsDocument] = useState([]);
  const [editData, setEditData] = useState(null);

  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    setGetMaster(masterData?.payload);
  }, [masterData]);

  useEffect(() => {
    if (token) {
      dispatch(getCategory(token));
    }
  }, [dispatch, token]);

  let cast_id = {
    category_id: selectedCategory,
  };

  useEffect(() => {
    if (token) {
      dispatch(getCast(token, cast_id));
    }
  }, [dispatch, token, selectedCategory]);

  let categoryOption = category?.payload?.map((c) => {
    return {
      value: c.id,
      label: c.name,
    };
  });
  let castOption = cast?.payload?.map((c) => {
    return {
      value: c.id,
      label: c.name,
    };
  });

  let nationalityList = getMaster
    ?.filter((obj) => obj.name === "Nationality")
    .map((n) => {
      return {
        value: n.value,
        label: n.value,
      };
    });
  let religionList = getMaster
    ?.filter((obj) => obj.name === "Religion")
    .map((n) => {
      return {
        value: n.value,
        label: n.value,
      };
    });
  let EducationList = getMaster
    ?.filter((obj) => obj.name === "Education")
    .map((n) => {
      return {
        value: n.value,
        label: n.value,
      };
    });
  let OccupationList = getMaster
    ?.filter((obj) => obj.name === "Occupation")
    .map((n) => {
      return {
        value: n.value,
        label: n.value,
      };
    });

  let MotherTongueList = getMaster
    ?.filter((obj) => obj.name === "MotherTongue")
    .map((n) => {
      return {
        value: n.value,
        label: n.value,
      };
    });
  let documentList = getMaster
    ?.filter((obj) => obj.name === "document")
    .map((n) => {
      return {
        value: n.id,
        label: n.value,
      };
    });
 

  useEffect(() => {
    let post = studentdata?.payload?.find((stu) => stu.id === Number(id));
    setEditData(post);
    setShowImg1(post?.std_profile);
    setShowImg2(post?.father_profile);
    setShowImg3(post?.mother_profile);
    setIsDocument(post?.documents);
    setUploadedDocuments(post?.documents);
    setSelectedCategory(post?.fk_category_id);
  }, [id]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fk_academic: editData?.fk_academic_id,
      school_id: school_id,
      std_name: editData?.std_name || "",
      child_id: editData?.child_id || "",
      apar_id: editData?.apar_id || "",
      std_code: editData?.student_code || "",
      date_of_birth: editData?.date_of_birth || "",
      gender: editData?.gender || "",
      nationality: editData?.nationality || "Indian",
      religion: editData?.religion || "",
      fk_category: editData?.fk_category_id || "",
      fk_caste: editData?.fk_caste_id || "",
      adhar_no: editData?.adhar_no || "",
      sub_caste: editData?.sub_caste || "",
      application_no: editData?.application_no || "",
      mother_tongue: editData?.mother_tongue || "",
      registration_date: editData?.registration_date,
      address: editData?.address || "",
      fk_state: editData?.fk_state_id || "",
      district: editData?.district || "",
      fk_city: editData?.fk_city_id || "",
      contact_no: editData?.contact_no || "",
      email: editData?.email || "",
      birth_place: editData?.birth_place || "",
      country: editData?.country || "India",
      state: editData?.state || "",
      o_district: editData?.o_district || "",
      blood_group: editData?.blood_group || "",
      mark_of_identity: editData?.mark_of_identity || "",
      other: editData?.other || "",
      father_name: editData?.father_name || "",
      f_res_address: editData?.f_res_address || "",
      f_office_address: editData?.f_office_address || "",
      f_education: editData?.f_education || "",
      f_contact_no: editData?.f_contact_no || "",
      f_email: editData?.f_email || "",
      f_occupation: editData?.f_occupation || "",
      f_annual_income: editData?.f_annual_income || "",
      f_adhar_no: editData?.f_adhar_no || "",
      f_pan_no: editData?.f_pan_no || "",
      mother_name: editData?.mother_name || "",
      m_res_address: editData?.m_res_address || "",
      m_office_address: editData?.m_office_address || "",
      m_education: editData?.m_education || "",
      m_contact_no: editData?.m_contact_no || "",
      m_email: editData?.m_email || "",
      m_occupation: editData?.m_occupation || "",
      m_annual_income: editData?.m_annual_income || "",
      m_adhar_no: editData?.m_adhar_no || "",
      m_pan_no: editData?.m_pan_no || "",
      std_profile: editData?.std_profile || "",
      father_profile: editData?.father_profile || "",
      mother_profile: editData?.mother_profile || "",
      documents: "",
      is_stuProfileremove: "False",
      is_fatherProfileremove: "False",
      is_motherProfileremove: "False",
    },
    validationSchema: Yup.object().shape({
      std_name: Yup.string().required("Full Name is required"),
      gender: Yup.string().required("Gender is required"),
      date_of_birth: Yup.date()
        .max(new Date(), "Future dates are not allowed")
        .required("Date of birth is required"),
      contact_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Invalid mobile number")
        .notRequired(""),
      adhar_no: Yup.string()
        .matches(/^[0-9]{12}$/, "Invalid Aadhaar number")
        .notRequired("Aadhaar number is required"),
      f_contact_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Invalid mobile number")
        .required("Mobile number is required"),
      f_email: Yup.string()
        .email("Invalid email address")
        .notRequired("Email is required"),
      f_adhar_no: Yup.string()
        .matches(/^[0-9]{12}$/, "Invalid Aadhaar number")
        .notRequired("Aadhaar number is required"),
      f_pan_no: Yup.string()
        .matches(/^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/, "Invalid PAN number")
        .notRequired("PAN number is required"),
      m_contact_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Invalid mobile number")
        .notRequired(""),
      m_adhar_no: Yup.string()
        .matches(/^[0-9]{12}$/, "Invalid Aadhaar number")
        .notRequired("Aadhaar number is required"),
      m_pan_no: Yup.string()
        .matches(/^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/, "Invalid PAN number")
        .notRequired("PAN number is required"),
    }),
    onSubmit: (values) => {
      const data = new FormData();
      data.append("student_id", Number(id));
      data.append("fk_academic", values.fk_academic);
      data.append("school_id", values.school_id);
      data.append("std_name", values.std_name);
      data.append("std_code", values.std_code);
      data.append("child_id", values.child_id);
      data.append("apar_id", values.apar_id);
      data.append("date_of_birth", values.date_of_birth);
      data.append("gender", values.gender);
      data.append("nationality", values.nationality);
      data.append("religion", values.religion);
      data.append("fk_category", values.fk_category);
      data.append("fk_caste", values.fk_caste);
      data.append("adhar_no", values.adhar_no);
      data.append("sub_caste", values.sub_caste);
      data.append("application_no", values.application_no);
      data.append("mother_tongue", values.mother_tongue);
      data.append("registration_date", values.registration_date);
      data.append("address", values.address);
      data.append("fk_state", values.fk_state);
      data.append("district", values.district);
      data.append("fk_city", values.fk_city);
      data.append("contact_no", values.contact_no);
      data.append("email", values.email);
      data.append("birth_place", values.birth_place);
      data.append("country", values.country);
      data.append("state", values.state);
      data.append("o_district", values.o_district);
      data.append("blood_group", values.blood_group);
      data.append("mark_of_identity", values.mark_of_identity);
      data.append("other", values.other);
      data.append("father_name", values.father_name);
      data.append("f_res_address", values.f_res_address);
      data.append("f_office_address", values.f_office_address);
      data.append("f_education", values.f_education);
      data.append("f_contact_no", values.f_contact_no);
      data.append("f_email", values.f_email);
      data.append("f_occupation", values.f_occupation);
      data.append("f_annual_income", values.f_annual_income);
      data.append("f_adhar_no", values.f_adhar_no);
      data.append("f_pan_no", values.f_pan_no);
      data.append("mother_name", values.mother_name);
      data.append("m_res_address", values.m_res_address);
      data.append("m_office_address", values.m_office_address);
      data.append("m_education", values.m_education);
      data.append("m_contact_no", values.m_contact_no);
      data.append("m_email", values.m_email);
      data.append("m_occupation", values.m_occupation);
      data.append("m_annual_income", values.m_annual_income);
      data.append("m_adhar_no", values.m_adhar_no);
      data.append("m_pan_no", values.m_pan_no);
      data.append("std_profile", values.std_profile);
      data.append("mother_profile", values.mother_profile);
      data.append("father_profile", values.father_profile);
      data.append("is_stuProfileremove", values.is_stuProfileremove);
      data.append("is_fatherProfileremove", values.is_fatherProfileremove);
      data.append("is_motherProfileremove", values.is_motherProfileremove);
      data.append("documents", JSON.stringify(isDocument));
      Swal.fire({
        title: "CONFIRMATION",
        text: "Do you want to Update Record?",
        icon: "question",
        showDenyButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        denyButtonText: `No`,
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(updateStudentReg(token, data, navigate, clearField))
            .then(() => {
              //   dispatch(getStudentList(token, st_sess));
            })
            .catch((error) => {
              console.error("Error submitting form:", error);
            });
        } else if (result.isDenied) {
          navigate("/school/student-registration");
        }
      });
    },
  });
  let clearField = () => {
    formik.resetForm();
    setUploadedDocuments([]);
    setIsDocument([]);
  };

  const handleFileRead1 = async (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("std_profile", file);
    const base64 = await convertBase64(file);
    setShowImg1(base64);
  };
  const handleFileRead2 = async (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("father_profile", file);
    const base64 = await convertBase64(file);
    setShowImg2(base64);
  };
  const handleFileRead3 = async (e) => {
    const file = e.target.files[0];
    formik.setFieldValue("mother_profile", file);
    const base64 = await convertBase64(file);
    setShowImg3(base64);
  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    if (!file) {
      return;
    }
    const fileSizeInBytes = file.size;
    const maxSizeInBytes = 500 * 1024;
    if (fileSizeInBytes > maxSizeInBytes) {
      Swal.fire("Error", "File size exceeds the limit (500KB)", "error");
      return;
    }
    if (file) {
      const base64 = await convertBase64(file);
      setSelectedFile(base64);
      if (!selectedDocument) {
        Swal.fire(
          "Error",
          "Please select a document from the dropdown.",
          "error"
        );
        return;
      }
      let existDocument = uploadedDocuments?.find((d) =>
        d.document_type
          ? d.document_type === selectedDocument.label
          : d.fk_document === selectedDocument.label
      );
      if (existDocument) {
        Swal.fire("Error", "Document is Already Added", "error");
        setSelectedDocument(null);
        return;
      }

      const document = {
        fk_document: selectedDocument.label,
        file: base64,
        remark: remark,
        id: "",
      };

      const document1 = {
        fk_document: selectedDocument.value,
        file: base64,
        remark: remark,
        id: "",
      };
      setUploadedDocuments((prevDocuments) => [...prevDocuments, document]);
      setIsDocument((prev) => [...prev, document1]);
      setSelectedFile(null);
      setSelectedDocument(null);
      setRemark("");
    }
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  // const handleDownload = (file) => {
  //   const downloadLink = document.createElement("a");
  //   downloadLink.href = file;
  //   downloadLink.download = selectedDocument?.label;
  //   downloadLink.click();
  // };
  const handleDownload = (fileUrl) => {
    const link = document.createElement("a");
    link.href = fileUrl;
    link.download = fileUrl.substring(fileUrl.lastIndexOf("/") + 1);
    link.target = "_blank";
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const handleRemoveFile = (index) => {
    setUploadedDocuments((prevDocuments) =>
      prevDocuments.filter((_, i) => i !== index)
    );
    setIsDocument((prevDocuments) =>
      prevDocuments.filter((_, i) => i !== index)
    );
  };

  useEffect(() => {
    if (token) {
      dispatch(getState(token));
    }
  }, [dispatch, token]);

  let getcityasperstate = {
    state_id: selectedState?.value,
  };
  useEffect(() => {
    if (token) {
      dispatch(getCity(token, getcityasperstate));
    }
  }, [dispatch, token, selectedState]);

  const stateListOption = statelist?.payload?.map((state) => {
    return {
      value: state.id,
      label: state.name,
    };
  });
  const cityListOption = citylist?.payload?.map((state) => {
    return {
      value: state.id,
      label: state.name,
    };
  });
  const districListOption = citylist?.destrict_data?.map((state) => {
    return {
      value: state.id,
      label: state.name,
    };
  });

  useEffect(() => {
    setStateOptions(stateListOption);
  }, [statelist]);

  const handleStateChange = async (selectedOption) => {
    const selectedStateValue = selectedOption?.value;
    const selectedStateLabel = selectedOption?.label;
    formik.setFieldValue("state", selectedStateLabel);
    let getcityasperstate = {
      state_id: selectedStateValue,
    };
    let response = await dispatch(getCity(token, getcityasperstate));
    const districListOption = response?.destrict_data?.map((state) => {
      return {
        value: state.id,
        label: state.name,
      };
    });
    setstatedistrictOptions(districListOption);
    formik.setFieldValue("o_district", null);
  };
  const handleStateChange1 = async (selectedOption) => {
    const selectedStateValue = selectedOption?.value;
    formik.setFieldValue("fk_state", selectedStateValue);
    let getcityasperstate = {
      state_id: selectedStateValue,
    };
    let response = await dispatch(getCity(token, getcityasperstate));

    const districListOption = response?.destrict_data?.map((state) => {
      return {
        value: state.id,
        label: state.name,
      };
    });
    const cityListOption = response?.payload?.map((state) => {
      return {
        value: state.id,
        label: state.name,
      };
    });
    setCityOptions(cityListOption);
    setdistrictOptions(districListOption);
    formik.setFieldValue("fk_city", null);
    formik.setFieldValue("district", null);
  };

  const handleCreateOption = async (inputValue) => {
    let newOption = {
      state_id: formik.values.fk_state,
      name: inputValue,
    };
    let dis = {
      state_id: formik.values.fk_state,
    };
    try {
      let res = await dispatch(addDistrict(token, newOption));
      let response = await dispatch(getCity(token, dis));
      const districListOption = response?.destrict_data?.map((state) => {
        return {
          value: state.id,
          label: state.name,
        };
      });
      setdistrictOptions(districListOption);

      let result = res?.payload?.find((r) => r.name === inputValue);
      formik.setFieldValue("district", result.name);
    } catch (error) {
      console.error(error);
    }
  };

  const handleCreateOption1 = async (inputValue) => {
    let st_id = stateOptions?.find((o) => o.label === formik.values.state);

    let newOption = {
      state_id: st_id.value,
      name: inputValue,
    };
    let dis = {
      state_id: st_id.value,
    };
    try {
      let res = await dispatch(addDistrict(token, newOption));

      let response = await dispatch(getCity(token, dis));
      const districListOption = response?.destrict_data?.map((state) => {
        return {
          value: state.id,
          label: state.name,
        };
      });
      setstatedistrictOptions(districListOption);
      let result = res?.payload?.find((r) => r.name === inputValue);
      formik.setFieldValue("o_district", result.name);
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        if (formik.values.fk_state) {
          let dis = {
            state_id: formik.values.fk_state,
          };

          let response = await dispatch(getCity(token, dis));
          const districListOption = response?.destrict_data?.map((district) => {
            return {
              value: district.id,
              label: district.name,
            };
          });
          const cityListOption = response?.payload?.map((state) => {
            return {
              value: state.id,
              label: state.name,
            };
          });
          setCityOptions(cityListOption);
          setdistrictOptions(districListOption);
        }
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };

    fetchDistricts();
  }, [formik.values.fk_state, dispatch, token]);
  // useEffect(  () => {
  //   if (formik.values.fk_state) {
  //     let dis = {
  //       state_id: formik.values.fk_state,
  //     };
  //  dispatch(getCity(token, dis));

  //   }
  // }, [formik.values.fk_state, dispatch, token]);

  useEffect(() => {
    const fetchDistricts = async () => {
      try {
        if (formik.values.state) {
          let st_id = stateOptions?.find(
            (o) => o.label === formik.values.state
          );
          let dis = {
            state_id: st_id.value,
          };

          let response = await dispatch(getCity(token, dis));
          const districListOption = response?.destrict_data?.map((state) => {
            return {
              value: state.id,
              label: state.name,
            };
          });
          setstatedistrictOptions(districListOption);
        }
      } catch (error) {
        console.error("Error fetching districts:", error);
      }
    };
    fetchDistricts();
  }, [formik.values.state, dispatch, token]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    window.scrollTo(0, 0);
    formik.handleSubmit();
  };

  function convertDate(data) {
    const parts = data.split("-");
    const formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    return formattedDate;
  }

  const onDelete = (id) => {
    let sId = {
      student_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteStudentReg(token, sId, navigate));
      }
    });
  };
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <HeadingBreadcrumb pageTitle={"Student Registration"}>
            <li className="nav-item">
              <a
                className="nav-link active"
                data-toggle="tab"
                href="#Student-all"
              >
                Personal Details
              </a>
            </li>
            <li className="nav-item">
              <a
                className={`nav-link is-invalid ${
                  (formik.touched.f_email && formik.errors.f_email) ||
                  (formik.touched.f_contact_no && formik.errors.f_contact_no)
                    ? "text-red"
                    : ""
                }`}
                data-toggle="tab"
                href="#Student-parent"
              >
                Parents Details
              </a>
            </li>
            <li className="nav-item">
              <a className="nav-link" data-toggle="tab" href="#Student-upload">
                Upload Documents
              </a>
            </li>
            <li className="nav-item">
              <li className="nav-item">
                <Suspense fallback={<div>Load...</div>}>
                  <LazyStudentList />
                </Suspense>
              </li>
            </li>
          </HeadingBreadcrumb>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <form onSubmit={handleFormSubmit}>
            <div className="tab-content">
              <div className="tab-pane active" id="Student-all">
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="row">
                        <div className="card-header col-md-4 ">
                          <h3 className="card-title px-2">Personal Details</h3>
                        </div>
                        <div className="card-header col-md-4 "></div>
                        <div className="card-header col-md-4 ">
                          <div className="form-group row">
                            <label className="col-md-6 col-form-label">
                              Student Status
                            </label>
                            <div className="col-md-6">
                              <input
                                className="form-control"
                                type="text"
                                name="registration_date"
                                value={editData?.student_status}
                                readOnly
                              />
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="card-body">
                        <div className="row">
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group row">
                              <label className="col-md-4 col-form-label">
                                Reg Date
                              </label>
                              <div className="col-md-8">
                                <input
                                  className="form-control"
                                  type="date"
                                  name="registration_date"
                                  value={formik.values.registration_date}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group row">
                              <label className="col-md-4 col-form-label">
                                Child Id
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="child_id"
                                  value={formik.values.child_id}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group row">
                              <label className="col-md-4 col-form-label">
                                APAAR Id
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="apar_id"
                                  placeholder="APAAR Id"
                                  value={formik.values.apar_id}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="col-md-6 col-sm-12">
                            <div className="form-group row">
                              <label className="col-md-4 col-form-label">
                                Student Code
                              </label>
                              <div className="col-md-8">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="std_code"
                                  value={formik.values.std_code}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  readOnly
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-9 col-sm-12">
                            <div className="form-group row">
                              <label className="col-md-2 col-form-label">
                                Full Name <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-10">
                                <input
                                  type="text"
                                  name="std_name"
                                  className={`form-control input-height ${
                                    formik.touched.std_name &&
                                    formik.errors.std_name
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  placeholder="Enter Full Name"
                                  value={formik.values.std_name}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.std_name &&
                                formik.errors.std_name ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.std_name}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    Date of Birth{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-md-8">
                                    <input
                                      type="date"
                                      className={`form-control input-height ${
                                        formik.touched.date_of_birth &&
                                        formik.errors.date_of_birth
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      name="date_of_birth"
                                      value={formik.values.date_of_birth}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                      max={currentDate()}
                                    />
                                    {formik.touched.date_of_birth &&
                                    formik.errors.date_of_birth ? (
                                      <div className="invalid-feedback">
                                        {formik.errors.date_of_birth}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-3 col-form-label">
                                    Gender{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-md-9">
                                    <Select
                                      name="gender"
                                      placeholder="Select Gender"
                                      options={genderOptions}
                                      value={genderOptions?.find(
                                        (option) =>
                                          option.value === formik.values.gender
                                      )}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          "gender",
                                          selectedOption?.value
                                        )
                                      }
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.gender &&
                                      formik.errors.gender && (
                                        <div className="error-message">
                                          {formik.errors.gender}
                                        </div>
                                      )}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    Nationality
                                  </label>
                                  <div className="col-md-8">
                                    <Select
                                      name="nationality"
                                      placeholder="Select Nationality..."
                                      options={nationalityList}
                                      value={nationalityList?.find(
                                        (option) =>
                                          option.value ===
                                          formik.values.nationality
                                      )}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          "nationality",
                                          selectedOption?.value
                                        )
                                      }
                                      onBlur={formik.handleBlur}
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-3 col-form-label">
                                    Religion{" "}
                                  </label>
                                  <div className="col-md-9">
                                    <Select
                                      name="religion"
                                      placeholder="Select Religion..."
                                      options={religionList}
                                      value={religionList?.find(
                                        (option) =>
                                          option.value ===
                                          formik.values.religion
                                      )}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          "religion",
                                          selectedOption?.value
                                        )
                                      }
                                      onBlur={formik.handleBlur}
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    Category
                                  </label>
                                  <div className="col-md-8">
                                    <Select
                                      name="fk_category"
                                      placeholder="Select Category..."
                                      options={categoryOption}
                                      value={categoryOption?.find(
                                        (option) =>
                                          option.value ===
                                          formik.values.fk_category
                                      )}
                                      onChange={(selectedOption) => {
                                        setSelectedCategory(
                                          selectedOption?.value
                                        );
                                        formik.setFieldValue(
                                          "fk_category",
                                          selectedOption?.value
                                        );
                                      }}
                                      onBlur={formik.handleBlur}
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-3 col-form-label">
                                    Caste
                                  </label>
                                  <div className="col-md-9">
                                    <Select
                                      name="fk_caste"
                                      placeholder="Select Cast..."
                                      options={castOption}
                                      value={castOption?.find(
                                        (option) =>
                                          option.value ===
                                          formik.values.fk_caste
                                      )}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          "fk_caste",
                                          selectedOption?.value
                                        )
                                      }
                                      onBlur={formik.handleBlur}
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    Aadhaar No.
                                  </label>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      className={`form-control input-height ${
                                        formik.touched.adhar_no &&
                                        formik.errors.adhar_no
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Aadhaar No."
                                      name="adhar_no"
                                      value={formik.values.adhar_no}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (
                                          /^\d*$/.test(inputValue) &&
                                          inputValue.length <= 12
                                        ) {
                                          formik.handleChange(e);
                                        }
                                      }}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.adhar_no &&
                                    formik.errors.adhar_no ? (
                                      <div className="invalid-feedback">
                                        {formik.errors.adhar_no}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-3 col-form-label">
                                    Sub Caste{" "}
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Sub Caste"
                                      name="sub_caste"
                                      value={formik.values.sub_caste}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    Application No.
                                  </label>
                                  <div className="col-md-8">
                                    <input
                                      type="number"
                                      className="form-control"
                                      placeholder="Application No."
                                      name="application_no"
                                      value={formik.values.application_no}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-3 col-form-label">
                                    Mother Tongue{" "}
                                  </label>
                                  <div className="col-md-9">
                                    <Select
                                      name="mother_tongue"
                                      placeholder="Select Mother Tongue"
                                      options={MotherTongueList}
                                      value={MotherTongueList?.find(
                                        (option) =>
                                          option.value ===
                                          formik.values.mother_tongue
                                      )}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          "mother_tongue",
                                          selectedOption?.value
                                        )
                                      }
                                      onBlur={formik.handleBlur}
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-md-2 col-form-label">
                                Mark of identity
                              </label>
                              <div className="col-md-10">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Mark of identity"
                                  name="mark_of_identity"
                                  value={formik.values.mark_of_identity}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                            </div>

                            <h3 className="card-title">Contact Details</h3>

                            <div className="form-group row">
                              <label className="col-md-2 col-form-label">
                                Address
                              </label>
                              <div className="col-md-10">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Address"
                                  name="address"
                                  value={formik.values.address}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    State
                                  </label>
                                  <div className="col-md-8">
                                    <Select
                                      name="fk_state"
                                      options={stateOptions}
                                      placeholder="Select"
                                      value={
                                        formik.values.fk_state
                                          ? stateOptions?.find(
                                              (option) =>
                                                option.value ===
                                                formik.values.fk_state
                                            )
                                          : null
                                      }
                                      onChange={handleStateChange1}
                                      onBlur={() =>
                                        formik.setFieldTouched("fk_state", true)
                                      }
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-3 col-form-label">
                                    District{" "}
                                  </label>
                                  <div className="col-md-9">
                                    <CreatableSelect
                                      name="district"
                                      options={districtOptions}
                                      placeholder="Select "
                                      value={
                                        formik.values.district
                                          ? districtOptions?.find(
                                              (option) =>
                                                option.label ===
                                                formik.values.district
                                            )
                                          : null
                                      }
                                      onChange={(option) =>
                                        formik.setFieldValue(
                                          "district",
                                          option.label
                                        )
                                      }
                                      onBlur={formik.handleBlur("district")}
                                      onCreateOption={handleCreateOption}
                                      isSearchable={true}
                                      aria-describedby="distric"
                                    />
                                    <small id="district" className="form-text text-muted">If the district is not available,type and press enter.</small>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    City
                                  </label>
                                  <div className="col-md-8">
                                    <Select
                                      name="fk_city"
                                      options={cityOptions}
                                      placeholder="Select "
                                      onChange={(option) =>
                                        formik.setFieldValue(
                                          "fk_city",
                                          option.value
                                        )
                                      }
                                      onBlur={formik.handleBlur("fk_city")}
                                      value={
                                        formik.values.fk_city
                                          ? cityOptions?.find(
                                              (option) =>
                                                option.value ===
                                                formik.values.fk_city
                                            )
                                          : null
                                      }
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-3 col-form-label">
                                    Contact No.{" "}
                                  </label>
                                  <div className="col-md-9">
                                    <input
                                      type="text"
                                      className={`form-control input-height ${
                                        formik.touched.contact_no &&
                                        formik.errors.contact_no
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      placeholder="Enter Contact No"
                                      name="contact_no"
                                      value={formik.values.contact_no}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (
                                          /^\d*$/.test(inputValue) &&
                                          inputValue.length <= 10
                                        ) {
                                          formik.handleChange(e);
                                        }
                                      }}
                                      onBlur={formik.handleBlur}
                                    />
                                    {formik.touched.contact_no &&
                                    formik.errors.contact_no ? (
                                      <div className="invalid-feedback">
                                        {formik.errors.contact_no}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    Email Id
                                  </label>
                                  <div className="col-md-8">
                                    <input
                                      placeholder="Enter Email"
                                      type="email"
                                      className="form-control"
                                      name="email"
                                      value={formik.values.email}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <h3 className="card-title">Other Details</h3>

                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    Birth Place
                                  </label>
                                  <div className="col-md-8">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Birth Place"
                                      name="birth_place"
                                      value={formik.values.birth_place}
                                      onChange={formik.handleChange}
                                      onBlur={formik.handleBlur}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-3 col-form-label">
                                    Country
                                  </label>
                                  <div className="col-md-9">
                                    <Select
                                      name="country"
                                      placeholder="Country"
                                      options={countaryOptions}
                                      value={countaryOptions?.find(
                                        (option) =>
                                          option.value === formik.values.country
                                      )}
                                      onChange={(selectedOption) =>
                                        formik.setFieldValue(
                                          "country",
                                          selectedOption?.value
                                        )
                                      }
                                      onBlur={formik.handleBlur}
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    State
                                  </label>
                                  <div className="col-md-8">
                                    <Select
                                      name="state"
                                      options={stateOptions}
                                      placeholder="Select"
                                      value={stateOptions?.find(
                                        (option) =>
                                          option.label === formik.values.state
                                      )}
                                      onChange={handleStateChange}
                                      onBlur={() =>
                                        formik.setFieldTouched("state", true)
                                      }
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-3 col-form-label">
                                    District
                                  </label>
                                  <div className="col-md-9">
                                    <CreatableSelect
                                      name="o_district"
                                      options={statedistrictOptions}
                                      placeholder="Select "
                                      value={
                                        formik.values.o_district
                                          ? statedistrictOptions?.find(
                                              (option) =>
                                                option.label ===
                                                formik.values.o_district
                                            )
                                          : null
                                      }
                                      onChange={(option) =>
                                        formik.setFieldValue(
                                          "o_district",
                                          option.label
                                        )
                                      }
                                      onBlur={formik.handleBlur("o_district")}
                                      onCreateOption={handleCreateOption1}
                                      isSearchable={true}
                                      aria-describedby="distric"
                                    />
                                    <small id="district" className="form-text text-muted">If the district is not available,type and press enter.</small>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row">
                              <div className="col-md-6 col-sm-12">
                                <div className="form-group row">
                                  <label className="col-md-4 col-form-label">
                                    Blood Group
                                  </label>
                                  <div className="col-md-8">
                                    <Select
                                      name="blood_group"
                                      options={bloodGroupList}
                                      placeholder="Select Blood Group"
                                      onChange={(option) =>
                                        formik.setFieldValue(
                                          "blood_group",
                                          option.label
                                        )
                                      }
                                      onBlur={formik.handleBlur("blood_group")}
                                      value={bloodGroupList?.find(
                                        (option) =>
                                          option.label ===
                                          formik.values.blood_group
                                      )}
                                      isSearchable={true}
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-md-2 col-form-label">
                                Other
                              </label>
                              <div className="col-md-10">
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder=" Other Details"
                                  name="other"
                                  value={formik.values.other}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                              </div>
                            </div>
                          </div>

                          <div className="col-md-3 col-sm-12">
                            <ImageUpload
                              formik={formik}
                              fieldName="std_profile"
                              fieldName1="is_stuProfileremove"
                              showImg={ShowImg1}
                              setShowImg={setShowImg1}
                            />

                            {/* <div className="d-flex justify-content-center mb-5">
                              {ShowImg1 ? (
                                <img
                                  alt="Cropped Img"
                                  src={ShowImg1}
                                  className="crop_img"
                                  width={100}
                                />
                              ) : editData?.std_profile ? (
                                <img
                                  width={100}
                                  src={editData?.std_profile}
                                  className="crop_img"
                                  alt="..."
                                />
                              ) : (
                                <img
                                  width={100}
                                  src={pic1}
                                  className="crop_img"
                                  alt="..."
                                />
                              )}
                            </div>
                            <div className="form-group row">
                              <div className="col-md-10 mx-auto">
                                <input
                                  style={{ width: "100%" }}
                                  type="file"
                                  className="dropify"
                                  name="std_profile"
                                  accept="image/*"
                                  onChange={handleFileRead1}
                                />
                              </div>
                             
                            </div> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="Student-parent">
                <div className="row clearfix">
                  <div className="col-lg-12 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">FATHER'S DETAILS</h3>
                      </div>
                      <div className="card-body row">
                        <div className="col-md-9 col-sm-12">
                          <div className="form-group row">
                            <label className="col-md-2 col-form-label">
                              Father's name
                            </label>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Father's name"
                                name="father_name"
                                value={formik.values.father_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-2 col-form-label">
                              Res. Address
                            </label>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Address"
                                name="f_res_address"
                                value={formik.values.f_res_address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-2 col-form-label">
                              Office Address{" "}
                            </label>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Office Address"
                                name="f_office_address"
                                value={formik.values.f_office_address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-4 col-form-label">
                                  Education
                                </label>
                                <div className="col-md-8">
                                  <Select
                                    name="f_education"
                                    placeholder="Select Education"
                                    options={EducationList}
                                    value={EducationList?.find(
                                      (option) =>
                                        option.value ===
                                        formik.values.f_education
                                    )}
                                    onChange={(selectedOption) =>
                                      formik.setFieldValue(
                                        "f_education",
                                        selectedOption?.value
                                      )
                                    }
                                    onBlur={formik.handleBlur}
                                    isSearchable={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-3 col-form-label">
                                  Contact <span className="text-danger">*</span>
                                </label>
                                <div className="col-md-9">
                                  <input
                                    type="text"
                                    className={`form-control input-height ${
                                      formik.touched.f_contact_no &&
                                      formik.errors.f_contact_no
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Contact"
                                    name="f_contact_no"
                                    value={formik.values.f_contact_no}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (
                                        /^\d*$/.test(inputValue) &&
                                        inputValue.length <= 10
                                      ) {
                                        formik.handleChange(e);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched.f_contact_no &&
                                  formik.errors.f_contact_no ? (
                                    <div className="invalid-feedback">
                                      {formik.errors.f_contact_no}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-2 col-form-label">
                              Email <span className="text-danger"></span>
                            </label>
                            <div className="col-md-10">
                              <input
                                type="email"
                                className={`form-control input-height ${
                                  formik.touched.f_email &&
                                  formik.errors.f_email
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Email"
                                name="f_email"
                                value={formik.values.f_email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              {formik.touched.f_email &&
                              formik.errors.f_email ? (
                                <div className="invalid-feedback">
                                  {formik.errors.f_email}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-4 col-form-label">
                                  PAN No.
                                </label>
                                <div className="col-md-8">
                                  <input
                                    type="text"
                                    className={`form-control text-uppercase input-height ${
                                      formik.touched.f_pan_no &&
                                      formik.errors.f_pan_no
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Enter PAN No."
                                    name="f_pan_no"
                                    value={formik.values.f_pan_no}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    maxLength={10}
                                  />
                                  {formik.touched.f_pan_no &&
                                  formik.errors.f_pan_no ? (
                                    <div className="invalid-feedback">
                                      {formik.errors.f_pan_no}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-3 col-form-label">
                                  Occupation{" "}
                                </label>
                                <div className="col-md-9">
                                  <Select
                                    name="f_occupation"
                                    placeholder="Select Occupation"
                                    options={OccupationList}
                                    value={OccupationList?.find(
                                      (option) =>
                                        option.value ===
                                        formik.values.f_occupation
                                    )}
                                    onChange={(selectedOption) =>
                                      formik.setFieldValue(
                                        "f_occupation",
                                        selectedOption?.value
                                      )
                                    }
                                    onBlur={formik.handleBlur}
                                    isSearchable={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-4 col-form-label">
                                  Annual Income{" "}
                                </label>
                                <div className="col-md-8">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder=" Annual Income"
                                    name="f_annual_income"
                                    value={formik.values.f_annual_income}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-3 col-form-label">
                                  Aadhaar No.
                                </label>
                                <div className="col-md-9">
                                  <input
                                    type="text"
                                    className={`form-control input-height ${
                                      formik.touched.f_adhar_no &&
                                      formik.errors.f_adhar_no
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Aadhaar No."
                                    name="f_adhar_no"
                                    value={formik.values.f_adhar_no}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (
                                        /^\d*$/.test(inputValue) &&
                                        inputValue.length <= 12
                                      ) {
                                        formik.handleChange(e);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched.f_adhar_no &&
                                  formik.errors.f_adhar_no ? (
                                    <div className="invalid-feedback">
                                      {formik.errors.f_adhar_no}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row"></div>
                        </div>
                        <div className="col-md-3 col-sm-12">
                          <ImageUpload
                            formik={formik}
                            fieldName="father_profile"
                            fieldName1="is_fatherProfileremove"
                            showImg={ShowImg2}
                            setShowImg={setShowImg2}
                          />
                        </div>
                      </div>
                      <div className="card-header">
                        <h3 className="card-title">MOTHER'S DETAILS</h3>
                      </div>
                      <div className="card-body row">
                        <div className="col-md-9 col-sm-12">
                          <div className="form-group row">
                            <label className="col-md-2 col-form-label">
                              Mother's Name
                            </label>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Mother's Name"
                                name="mother_name"
                                value={formik.values.mother_name}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-2 col-form-label">
                              Res. Address
                            </label>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Address"
                                name="m_res_address"
                                value={formik.values.m_res_address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-md-2 col-form-label">
                              Office Address{" "}
                            </label>
                            <div className="col-md-10">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Enter Office Address"
                                name="m_office_address"
                                value={formik.values.m_office_address}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>

                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-4 col-form-label">
                                  Education
                                </label>
                                <div className="col-md-8">
                                  <Select
                                    name="m_education"
                                    placeholder="Select Education"
                                    options={EducationList}
                                    value={EducationList?.find(
                                      (option) =>
                                        option.value ===
                                        formik.values.m_education
                                    )}
                                    onChange={(selectedOption) =>
                                      formik.setFieldValue(
                                        "m_education",
                                        selectedOption?.value
                                      )
                                    }
                                    onBlur={formik.handleBlur}
                                    isSearchable={true}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-3 col-form-label">
                                  Contact{" "}
                                </label>
                                <div className="col-md-9">
                                  <input
                                    type="text"
                                    className={`form-control input-height ${
                                      formik.touched.m_contact_no &&
                                      formik.errors.m_contact_no
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Contact"
                                    name="m_contact_no"
                                    value={formik.values.m_contact_no}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (
                                        /^\d*$/.test(inputValue) &&
                                        inputValue.length <= 10
                                      ) {
                                        formik.handleChange(e);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched.m_contact_no &&
                                  formik.errors.m_contact_no ? (
                                    <div className="invalid-feedback">
                                      {formik.errors.m_contact_no}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-md-2 col-form-label">
                              Email
                            </label>
                            <div className="col-md-10">
                              <input
                                type="email"
                                className="form-control"
                                placeholder="Enter Email"
                                name="m_email"
                                value={formik.values.m_email}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-4 col-form-label">
                                  PAN No.
                                </label>
                                <div className="col-md-8">
                                  <input
                                    className={`form-control text-uppercase input-height ${
                                      formik.touched.m_pan_no &&
                                      formik.errors.m_pan_no
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    type="text"
                                    placeholder="Enter PAN No."
                                    name="m_pan_no"
                                    value={formik.values.m_pan_no}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    maxLength={10}
                                  />
                                  {formik.touched.m_pan_no &&
                                  formik.errors.m_pan_no ? (
                                    <div className="invalid-feedback">
                                      {formik.errors.m_pan_no}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-3 col-form-label">
                                  Occupation{" "}
                                </label>
                                <div className="col-md-9">
                                  <Select
                                    name="m_occupation"
                                    placeholder=" Select Occupation"
                                    options={OccupationList}
                                    value={OccupationList?.find(
                                      (option) =>
                                        option.value ===
                                        formik.values.m_occupation
                                    )}
                                    onChange={(selectedOption) =>
                                      formik.setFieldValue(
                                        "m_occupation",
                                        selectedOption?.value
                                      )
                                    }
                                    onBlur={formik.handleBlur}
                                    isSearchable={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-4 col-form-label">
                                  Annual Income{" "}
                                </label>
                                <div className="col-md-8">
                                  <input
                                    type="number"
                                    className="form-control"
                                    placeholder=" Annual Income"
                                    name="m_annual_income"
                                    value={formik.values.m_annual_income}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6 col-sm-12">
                              <div className="form-group row">
                                <label className="col-md-3 col-form-label">
                                  Aadhaar No.
                                </label>
                                <div className="col-md-9">
                                  <input
                                    type="text"
                                    className={`form-control input-height ${
                                      formik.touched.m_adhar_no &&
                                      formik.errors.m_adhar_no
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    placeholder="Aadhaar No."
                                    name="m_adhar_no"
                                    value={formik.values.m_adhar_no}
                                    onChange={(e) => {
                                      const inputValue = e.target.value;
                                      if (
                                        /^\d*$/.test(inputValue) &&
                                        inputValue.length <= 12
                                      ) {
                                        formik.handleChange(e);
                                      }
                                    }}
                                    onBlur={formik.handleBlur}
                                  />
                                  {formik.touched.m_adhar_no &&
                                  formik.errors.m_adhar_no ? (
                                    <div className="invalid-feedback">
                                      {formik.errors.m_adhar_no}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row"></div>
                        </div>

                        <div className="col-md-3 col-sm-12">
                          <ImageUpload
                            formik={formik}
                            fieldName="mother_profile"
                            fieldName1="is_motherProfileremove"
                            showImg={ShowImg3}
                            setShowImg={setShowImg3}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="tab-pane" id="Student-upload">
                <div className="row clearfix">
                  <div className="col-lg-10 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="card-body">
                        <div className="form-group row">
                          <label className="col-md-2 col-form-label">
                            Document
                          </label>
                          <div className="col-md-6">
                            <Select
                              name="document"
                              placeholder="Select Document"
                              options={documentList}
                              value={selectedDocument}
                              onChange={(selectedOption) =>
                                setSelectedDocument(selectedOption)
                              }
                              isSearchable={true}
                            />
                          </div>
                          <div className="col-md-2">
                            {/* <label htmlFor="cid">
                              <FaCloudUploadAlt />
                            </label> */}
                            {/* <input
                              id="cid"
                              style={{ width: "100%" }}
                              type="file"
                              className="dropify"
                              name="school_logo"
                              // accept="application/pdf"
                              multiple
                              onChange={handleFileChange}
                            /> */}
                            <input
                              type="file"
                              name="documents"
                              className="dropify"
                              style={{ display: "none" }}
                              id="contained-button-file"
                              onChange={handleFileChange}
                              // accept="application/pdf"
                              multiple
                            />
                            <label htmlFor="contained-button-file">
                              <span>
                                <FaCloudUploadAlt
                                  className="i_cloud"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Upload Document"
                                />
                              </span>
                            </label>
                          </div>
                          {/* <div className="col-md-2">
                            <button
                              onClick={handleUpload}
                              type="button"
                              className="btn btn-primary btn-sm"
                            >
                              Upload PDF
                            </button>
                          </div> */}
                        </div>
                        <div className="form-group row">
                          <label className="col-md-2 col-form-label">
                            Remark
                          </label>
                          <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-10 col-md-12 col-sm-12">
                    <div className="card">
                      <div className="card-header">
                        <h3 className="card-title">Document List</h3>
                      </div>
                      {uploadedDocuments?.length > 0 && (
                        <div className="card-body">
                          {uploadedDocuments?.map((document, index) => (
                            <div key={index} className="col-sm-12 row">
                              {/* <p className="col-sm-6">{document.fk_document}</p> */}
                              <p className="col-sm-6">
                                {document.fk_document
                                  ? document.fk_document
                                  : document.document_type}
                              </p>
                              <div className="col-sm-4 ">
                                <button
                                  type="button"
                                  className="btn btn-primary btn-sm mx-2"
                                  onClick={() => handleDownload(document.file)}
                                >
                                  Show
                                </button>
                                <button
                                  onClick={() => handleRemoveFile(index)}
                                  type="button"
                                  className="btn btn-danger btn-sm"
                                >
                                  X
                                </button>{" "}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-12 justify-content-center align-item-center my-3">
              <button type="submit" className="mr-1 btn btn-primary px-3">
                <i className="fa fa-plus px-1" aria-hidden="true"></i>Update
              </button>
              <button
                type="button"
                onClick={() => onDelete(Number(id))}
                className="btn btn-outline-danger px-3"
              >
                <i className="fa fa-trash px-1" aria-hidden="true"></i>
                Delete
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default EditStudentRegistration;
