import React, { useEffect, useState } from "react";
import NotificationApi from "../../../api/Notification/Notification";
import useLocalStorageData from "../Comman/LocalStorageData";
import { NavLink } from "react-router-dom";
import { MEDIA_BASE_URL } from "../../../Config/BaseUrl";
import { FaFileDownload } from "react-icons/fa";
import PerfectScrollbar from "react-perfect-scrollbar";
import { useDispatch ,useSelector } from "react-redux";
import { fetchSchoolLogo } from "../../../actions/SchoolLogo/SchoolLogoActions";


const Notification = () => {
  const [staffList, setStaffList] = useState("");
  const [staffNotification, setStaffNoftification] = useState([]);
  const [addedNotifications, setAddedNotifications] = useState([]);
  const { user_type, school_emp_id, user_detail ,school_id ,token} = useLocalStorageData();
  const [count , setCount] = useState(0)
  const [isOpen ,setIsOpen] = useState(false)
  const dispatch = useDispatch();
  const { logoData, loading, error } = useSelector((state) => state.schoolLogo);
  // console.log(logoData)

  


  const getStaffNotification = async () => {
    try {
      let data = {
        emp_id: school_emp_id,
      };
      const response = await NotificationApi.get_StaffNotification(data);
      let responseData = response.data;
      setStaffNoftification(responseData.notification);
      setCount(responseData.unseen_count)
      if(count >1){
        setIsOpen(!isOpen)
      }
    } catch (error) {
      console.error(error);
    }
  };
  
  const getAddedNotification = async () => {
    try {
      let data = {
        user_type: user_type,
        user_id: school_emp_id,
      };
      const response = await NotificationApi.getNotificationList(data);
      let responseData = response.data;
      setAddedNotifications(responseData.notification);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    getStaffNotification();
    getAddedNotification();
 
  }, [user_type, school_emp_id]);

   
  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await dispatch(fetchSchoolLogo(school_id, token));
        // console.log('School Logo data:', data);
      } catch (error) {
        console.error('Error fetching school logo:', error);
      }
    };
  
    fetchData();
  }, [dispatch, school_id, token]);
 

  const fetchStaffList = async () => {
    try {
      let data = {
        user_type: user_type,
        user_id: school_emp_id,
      };
      const response = await NotificationApi.get_StaffList(data);
      let responseData = response.data;
      setStaffList(responseData.stafff);
    } catch (error) {
      console.error(error);
    }
  };
  const disableNotificationCount = ()=>{
    setCount(0)
    let data = {
      emp_id:school_emp_id
    }
    try{
      const response = NotificationApi.seenStaffNotificationMessage(data);
    let responseData = response.data;
        if(responseData.status === 200){
          getAddedNotification()
          getStaffNotification()
        }
    }catch{

    }
    
  }

  useEffect(() => {
    fetchStaffList();
  }, [user_type, school_emp_id]);

  const formatDate = (dateTimeString) => {
    const options = {
      year: "numeric",
      month: "short",
      day: "numeric",
      hour: "numeric",
      minute: "numeric",
    };
    return new Date(dateTimeString).toLocaleDateString("en-US", options);
  };

  return (
    <div className="">
      <a
      // style={{display:"block"}}
        className="nav-link icon btn btn-default btn-icon mx-1  "
        data-toggle="dropdown"
        onClick={disableNotificationCount}
      >
        <i className="fa fa-bell"></i>
        { count > 0 ? (
          <span className="badge badge-primary nav-unread badge-pill">{count}</span>
        ):("")}
      </a>

      <div className="dropdown-menu dropdown-menu-right dropdown-menu-arrow">
        <ul
          className="list-unstyled feeds_widget flex-column"
          style={{
            maxHeight: "300px",
            overflowY: "auto",
            position: "relative",
            padding: "0.5rem 0.5rem 0.5rem 1rem",
          }}
        >
         {staffNotification?.reverse().map((notificationItem, index) => (
            <li key={index} className="d-flex flex-column">
              <div className="d-flex">
                <div className="feeds-left">
                  {notificationItem.fk_notification__document && (
                    <span className="avatar avatar-blue">
                      <a
                        href={`${MEDIA_BASE_URL}/${notificationItem.fk_notification__document}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <FaFileDownload
                          style={{ height: "20px", width: "20px" }}
                        />
                      </a>
                    </span>
                  )}
                </div>
                <div className="feeds-body ml-2">
                  <p className="text-muted mb-0">
                    {notificationItem.fk_notification__message}
                  </p>
                </div>
              </div>
              <div className="feeds-body  ml-auto" style={{ color: "#A9A9A9" }}>
                <p className="small">
                  {formatDate(notificationItem.fk_notification__message_date)}
                </p>
              </div>
            </li>
          ))}
        </ul>

        <div className="dropdown-divider"></div>

        {user_type === "Admin" ||
        user_detail.user_type_dashboard === "Admin" ? (
          <>
          <div style={{ textAlign: 'center' }}>
            <NavLink
              to={
                user_detail.user_type_dashboard === "Admin"
                  ? "/school/add-notification"
                  : "/admin/add-notification"
              }
              className="d-inline-flex align-items-center rounded"
            >
              Create Notification
            </NavLink>
          </div>
        </>
        ) : (
          <div>
            <ul className="list-unstyled feeds_widget">
              {addedNotifications.map((notificationItem, index) => (
                <li key={index}>
                  <div className="feeds-left">
                    <span className="avatar avatar-blue">
                      <i className="fa fa-check"></i>
                    </span>
                  </div>
                  <div className="feeds-body ml-3">
                    <p className="text-muted mb-0">
                      {notificationItem.fk_notification__message}
                      <strong className="text-blue font-weight-bold">
                        {notificationItem.fk_notification__message_date}
                      </strong>
                    </p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default Notification;
