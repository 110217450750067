import React from "react";
import { convertDateInMMDDYY } from "../../../CommonComponent/DateFormatUtil";
import CustomScrollbar from "../../../CommonComponent/PerfectScrollbar";

const HostelTable = ({ hostelData, setRoomIdList, roomIdList }) => {
  const getNestedProperty = (object, propertyName) => {
    propertyName.reduce((acc, propName) => acc?.[propName], object);
  };

  const handleSelectBed = (selectedBed) => {
    let updateRoomList = [...roomIdList];
    const toRemove = updateRoomList.findIndex(
      (item) => item.id === selectedBed.alloted_id
    );
    if (toRemove !== -1) {
      updateRoomList.splice(toRemove, 1);
    } else {
      updateRoomList.push({ id: selectedBed.alloted_id, is_allocate: "No" });
    }
    setRoomIdList(updateRoomList);
  };
  return (
    <>
      <CustomScrollbar
        style={{
          height: "50vh",
          paddingRight: ".8rem",
          boxSizing: "content-box",
        }}
      >
        <div className="table-responsive">
          <table
            className="table table-bordered table-hover text-nowrap table-sm"
            id="table-to-xls"
          >
            <thead>
              <tr className="text-center">
                <th> Sr No</th>
                <th>Bed Number</th>
                <th>Student Type</th>
                <th>Student No.</th>
                <th>Student Name</th>
                <th>Start Date</th>
                <th>End Date</th>
                <th>Fees</th>
                <th>DeAllocate</th>
              </tr>
            </thead>
            <tbody>
              {hostelData?.map((hostel, hostelIndex) =>
                Object.entries(hostel).map(([hostelName, floors], index) => (
                  <React.Fragment key={index}>
                    {floors?.map((floor, floorIndex) =>
                      Object.entries(floor).map(
                        ([roomInfo, beds], roomIndex) => (
                          <React.Fragment key={roomIndex}>
                            {Object.values(beds)
                              ?.filter((item) => item.is_alloted === "Yes")
                              .map((bed, bedIndex) => (
                                <tr key={bedIndex} className="text-center">
                                  <td>{bedIndex + 1}</td>
                                  <td>{bed.bed_no}</td>
                                  <td>{bed.student_type}</td>
                                  <td>{bed.student_no}</td>
                                  <td className="text-left">
                                    {bed.student_name}
                                  </td>
                                  <td>{convertDateInMMDDYY(bed.start_date)}</td>
                                  <td>{convertDateInMMDDYY(bed.end_date)}</td>
                                  <td>{bed.hostel_fees}</td>
                                  <td>
                                    {/* {bed.is_alloted} */}
                                    <input
                                      type="checkbox"
                                      checked={roomIdList.some(
                                        (item) => item.id === bed.alloted_id
                                      )}
                                      onChange={() => handleSelectBed(bed)}
                                      style={{ cursor: "pointer" }}
                                    />
                                  </td>
                                </tr>
                              ))}
                          </React.Fragment>
                        )
                      )
                    )}
                  </React.Fragment>
                ))
              )}
            </tbody>
          </table>
        </div>
      </CustomScrollbar>
    </>
  );
};

export default HostelTable;
