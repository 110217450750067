import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";

const MasterComponent = ({
  classdata,
  onDelete,
  onEdit,
  onSubmit,
  isValue,
  setIsValue,
  edit,
  title,
}) => {
  return (
    <>
      <h5>{title}</h5>
      <form onSubmit={onSubmit}>
        <div className="row clearfix pb-2 ">
          <div className="col-sm-9 my-1">
            <input
              id="name"
              name="name"
              type="text"
              className="form-control "
              placeholder="Enter Master Value"
              value={isValue}
              onChange={(e) => setIsValue(e.target.value)}
              required
              autoComplete="off"
            />
          </div>
          <div className="col-sm-3 my-1">
            <button type="submit" className="btn btn-primary">
              {edit ? "UPDATE" : "+ ADD"}
            </button>
          </div>
        </div>
      </form>
      <PerfectScrollbar
        style={{
          height: "23.75rem",
          paddingRight: "1rem",
          boxSizing: "content-box",
        }}
        options={{ suppressScrollX: true }}
      >
        <div className="card">
          <div className="card-body">
            <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
              <thead>
                <tr className="">
                  <th>Sr.No.</th>
                  <th>Master Value</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {classdata?.map((row, index) => (
                  <tr key={row.id}>
                    <td>{index + 1}</td>
                    <td>{row.value}</td>
                    <td className="d-flex gap-2">
                      <NavLink
                        to="#"
                        className="card-options-remove mr-3"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Delete"
                        
                        onClick={() => onDelete(row.id)}
                      >
                        <i className="fa fa-trash-o text-danger"></i>
                      </NavLink>
                      <NavLink
                        className="text-muted"
                        to="#"
                        data-toggle="tooltip"
                        data-placement="top"
                        title="Edit"
                        onClick={() => {
                          onEdit(row);
                        }}
                      >
                        <i className="fa fa-edit"></i>
                      </NavLink>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </PerfectScrollbar>
    </>
  );
};

export default MasterComponent;
