import React from "react";
import Select from "react-select";
export const SmallSelectField = ({
  name,
  placeholder, 
  options,
  value,
  onChange,
  ...props
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      // height: "25.5px", 
      // fontSize: "9px",
      // minHeight: "25.5px",
      // padding: "0px",
    }),
    menu: (provided) => ({
      ...provided,
      // fontSize: "9px",
      zIndex: "9999",
    }),
    option: (provided) => ({
      ...provided,
      // fontSize: "9px",
    }),
  };

  return (
    <Select
      name={name}
      placeholder={placeholder}
      options={options}
      value={value ? options?.find((option) => option.value === value) : null}
      onChange={onChange}
      isSearchable={true}
      {...props}
      styles={customStyles}
    />
  );
};

export const SelectField = ({
  name,
  placeholder,
  options,
  value,
  onChange,
  ...props
}) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      height: "25.5px",
      fontSize: "9px",
      minHeight: "25.5px",
      padding: "0px",
    }),
    menu: (provided) => ({
      ...provided,
      fontSize: "9px",
      zIndex: "999999999",
    }),
    option: (provided) => ({
      ...provided,
      fontSize: "9px",
    }),
  };

  return (
    <Select
      name={name}
      placeholder={placeholder}
      options={options}
      value={value ? options?.find((option) => option.value === value) : null}
      onChange={onChange}
      isSearchable={true}
      menuPosition="fixed"
      {...props}
      styles={customStyles}
    />
  );
};
