import React, { useState } from "react";
import Select from "react-select";
import RegisterStudentList from "../../../schooladmin/Comman/RegisterStudentList";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import SearchStudentModal from "./SearchStudent";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import deleteStudentApi from "../../../../api/DeleteStudent/DeleteStudentFromAdmin";

const DeleteStudent = ({ schoolId, token }) => {
  const [student_uid, setstudent_uid] = useState();
  const [isEdit, setIsEdit] = useState(false);
  const [Data, setData] = useState({});


  console.log(Data, "Data");

  const onDelete = (e) => {
    e.preventDefault();
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          student_id: Data?.id,
          fk_user_id: Data?.fk_user_id,
        };
        console.log(data);
        deleteStudentApi
          .deleteStudent(data)
          .then((response) => {
            const responseData = response.data;
            if (responseData.status === 200) {

              Swal.fire({
                text: responseData.msg,
                icon: "success",
              });
              handleClear(e);
            } else {
              Swal.fire({
                text: "Failed to delete item.",
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.error(error)
            Swal.fire({
              text: "An error occurred while processing your request.",
              icon: "error",
            });
          });
      }
    });
  };

  const handleClear = (e) => {
    e.preventDefault();
    setData({});
    setstudent_uid("");
    setIsEdit(false);
  };

  return (
    <div className="row clearfix">
      <div className="col-lg-12 col-lg-12 col-sm-12">
        <div className="card">
          <form className="card-body">
            <div className="form-group row">
              <label className="col-lg-2 col-form-label">
                Student Code <span className="text-danger">*</span>
              </label>
              <div className="col-lg-4">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Student Code"
                    value={student_uid}
                    onChange={(e) => setstudent_uid(e.target.value)}
                    disabled
                  />
                  <div className="input-group-append">
                    <SearchStudentModal
                      setstudent_uid={setstudent_uid}
                      school_id={schoolId}
                      token={token}
                      setData={setData}
                      // setEnableFields={setEnableFields}
                      setIsEdit={setIsEdit}
                    />
                  </div>
                  {/* {validationError && !student_uid ? (
                  <ErrorDisplay errorMsg={"Student Code Requried !"} />
                ) : null} */}
                </div>
              </div>
              <label className="col-lg-2 col-form-label">
                Student Name <span className="text-danger">*</span>
              </label>
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  value={Data.std_name || ""}
                  placeholder="Student Name"
                  disabled
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="col-lg-2 col-form-label">
                Class Section <span className="text-danger">*</span>
              </label>

              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  value={Data.class_section || ""}
                  disabled
                />
                {/* {validationError && !classSelect ? (
                <ErrorDisplay errorMsg={"Select Class Section !"} />
              ) : null} */}
              </div>
              <label className="col-lg-2 col-form-label">
                Admission Date <span className="text-danger">*</span>
              </label>
              <div className="col-lg-4">
                <input
                  type="date"
                  className="form-control"
                  value={Data.registration_date || ""}
                  // onChange={(e) => {
                  //   setadmissionDate(e.target.value);
                  // }}
                  disabled
                />
              </div>
            </div>{" "}
            <div className="form-group row">
              <label className="col-lg-2 col-form-label">
                Concession <span className="text-danger">*</span>
              </label>
              <div className="col-lg-4">
                <input
                  type="text"
                  className="form-control"
                  value={Data.concession || ""}
                  disabled
                />
              </div>
              <div className="col-lg-6 d-flex justify-content-end align-items-center">
                <button
                  className="btn btn-outline-danger mx-2"
                  onClick={(e)=>onDelete(e)}
                  disabled={!isEdit}
                >
                  <i className="fa fa-trash"></i> Delete
                </button>
                <button className="btn btn-danger" onClick={handleClear}>
                  <i className="fa fa-times"></i> Clear
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default DeleteStudent;
