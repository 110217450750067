import html2pdf from "html2pdf.js";
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import numberToWords from "number-to-words";
import Swal from "sweetalert2";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import { convertDateInMMDDYY } from "../../../CommonComponent/DateFormatUtil";
import { useAlert } from "react-alert";
import { useDispatch, useSelector } from "react-redux";
import www from '../../Examination/GradeCard/www.png'

const PrintHostelFeesReceipt = (props) => { 
  const { logoData, loading, error } = useSelector((state) => state.schoolLogo);
  const {
    receiptDate,
    validateControl,
    paymentInfo,
    schoolUser,
    btnClick,
    handleClear,
    receiptType,
    selectStudent,
  } = props;
  const { email, user_detail } = schoolUser;
  const {
    school_name,
    organization_name,
    school_address,
    school_contact_email,
    school_contact_number,
    school_pincode,
    udise_no,
    school_logo,
  } = user_detail;
  const {
    payment_mode,

    paid_amount,
    bank_name,
    pay_no,
    pay_date,
    remark,
  } = paymentInfo;
  const [modal, setModal] = useState(false);
  const [receiptNo, setReceiptNo] = useState("");
  const alert = useAlert();
  const toggle = () => {
    setModal(!modal);
  };

  const handleConvertToPdf = async () => {
    const element = document.getElementById("divToConvert");
    if (element) {
      const opt = {
        margin: 10,
        filename: "generated.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      const pdfDataUri = await html2pdf()
        .from(element)
        .set(opt)
        .outputPdf("datauristring");
      const newTab = window.open();
      newTab.document.write(
        '<style> body { margin: 0px !important; overflow: hidden !important; }</style><iframe width="100%" height="100%" src="' +
          pdfDataUri +
          '"></iframe>'
      );
      newTab.document.close();
      toggle();
      handleClear();
    }
  };

  const handlePrintReceipt = async () => {
    const isValid = await validateControl();
    if (!isValid) {
      return;
    } else if (!paid_amount) {
      alert.error("Please enter Amount");
    } else {
      try {
        Swal.fire({
          title: "Confirmation",
          text:
            receiptType === "New"
              ? "You want to Pay Hostel Fee?"
              : "You want to Update Hostel Fee?",
          icon: "question",
          showDenyButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes",
          denyButtonText: `No`,
          allowOutsideClick: false,
        }).then(async (result) => {
          if (result.isDenied) {
            handleClear()
          }
          if (result.isConfirmed) {
            const response = await HostelAPI.payHostelpayment(paymentInfo);
            const responseData = response.data;

            if (responseData.status === 200) {
              setReceiptNo(responseData.receipt_no);
              await Swal.fire({
                title: "Confirmation",
                text:
                  responseData.msg + "Do You want to Proceed for Fee Receipt?",
                icon: "success",
                showDenyButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Yes",
                denyButtonText: `No`,
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isDenied) {
                  handleClear()
                }
                if (result.isConfirmed) {
                  toggle();
                }
              });
            } else if (responseData.status === 403) {
              Swal.fire({
                text: responseData.msg,
                icon: "error",
                allowOutsideClick: false,
              });
            }
          }
        });
      } catch (error) {
        console.error(error);
      }
    }
  };

  if (paid_amount) {
    var words = numberToWords.toWords(Number(paid_amount));
  }

  return (
    <>
      <button
        disabled={btnClick}
        type="button"
        className="btn btn-sm btn-primary btn-block p-2 my-2 page-breadcrumb "
        onClick={handlePrintReceipt}
      >
        {receiptType === "New"
          ? " Pay And Print Receipt"
          : " Update And Print Receipt"}
      </button>
      <Modal isOpen={modal} toggle={toggle} fullscreen className="modal-lg" backdrop="static">
      <div className="text-right m-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                handleConvertToPdf();
              }}
            >
              Print Fees Receipt
            </button>
            <button
              className="btn btn-secondary ml-2"
              onClick={() => {
               toggle();
              }}
            >
              Close
            </button>
            <hr className="border border-muted" />

          </div>
        <ModalBody>
          <div className="container-fluid" id="divToConvert">
            <main>
            <div className="d-flex align-items-stretch">
               
               <div className="col-3 m-auto">
                 {logoData ? (
                   <img
                     src={`data:image/png;base64,${logoData}`}
                     alt="school_logo"
                     width={100}
                     height={100}
                     className=""
                     style={{ borderRadius: "50%" }}
                   />
                 ) : (
                   <img
                     src={www}
                     alt="fallback_image"
                     width={100}
                     height={100}
                     style={{ borderRadius: "50%" }}
                   />
                 )}
              </div>

          
               <div className="col-9">
                 <div className="text-center col-8">
                   <p className="font-weight-bold text-dark">{organization_name}</p>
                   <div className="font-weight-bold">
                     {school_name}
                   </div>
                   <div>
                     <h6>{school_address}</h6>
                     <h6 style={{fontSize: "0.7rem"}}>
                       Pincode- {school_pincode}, Ph: {school_contact_number}
                     </h6>
                     <h6 style={{fontSize: "0.7rem"}}>E-mail: {school_contact_email}</h6>
                   </div>
                 </div>
               </div>
             </div> 
              <hr className="border border-dark" />

              <section>
                <div className="row">
                  <div className="col-6">
                    <h6>Reg No : {udise_no}</h6>
                    <h6>Name : {selectStudent?.student_name}</h6>
                    {/* <h6>Class : {classSec}</h6> */}
                  </div>
                  <div className="col-6">
                    {/* <h6>Session : {session}</h6> */}
                    <h6>Receipt No : {receiptNo}</h6>
                    <h6>Receipt Date : {convertDateInMMDDYY(receiptDate)}</h6>
                  </div>
                </div>
                <div className="row">
                  <div className="table-responsive card">
                    <table className="table table-hover table-vcenter  text-nowrap ">
                      <thead className="text-center ehed text-dark font-weight-bold">
                        <th className="text-dark font-weight-bold">Sr. No</th>
                        <th className="text-dark font-weight-bold">Fees </th>
                        <th  className="text-dark font-weight-bold">Amount</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>{1}</td>
                          <td>Hostel Fees</td>
                          <td className="text-right text-dark font-weight-bold">{paid_amount}</td>
                        </tr>

                        {/* <tr>
                          <th colSpan={2} className="text-right">
                            Total Amount
                          </th>
                          <td className="">{total_amount}</td>
                        </tr> */}

                        <tr>
                          <th colSpan={2} className="text-right ehed text-dark font-weight-bold">
                            Paid Amount
                          </th>
                          <td className="text-right text-right text-dark font-weight-bold ehed">{paid_amount}</td>
                        </tr>

                        <tr>
                          <td colSpan={1} className="text-dark font-weight-bold">In Word</td>
                          <td
                            colSpan={1}
                            className="text-right text-capitalize"
                          >
                            <strong className="text-dark font-weight-bold">{words} Rupees Only</strong>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div>
                  <h6>Payment Mode : {payment_mode}</h6>
                  {payment_mode === "Cash" ? null : (
                    <>
                      {payment_mode === "UPI" ? null : (
                        <h6>Bank Name : {bank_name}</h6>
                      )}
                      <h6>
                        {payment_mode === "Bank" ? "Account" : payment_mode} No.
                        : {pay_no}
                      </h6>
                      <h6>Payment Date : {convertDateInMMDDYY(pay_date)}</h6>
                    </>
                  )}
                </div>
              </section>
              <hr className="border border-dark" />

              <footer className="row  pt-5 mt-5">
                <div className="col-6">
                  <h6>Received By : Clerk </h6>
                </div>
                <div className="col-6 text-right">
                  <h6>Signature </h6>
                </div>
                <hr />
              </footer>
            </main>
          </div>
          
        </ModalBody>
      </Modal>
    </>
  );
};

export default PrintHostelFeesReceipt;
