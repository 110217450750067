export const RESET_USER_MENU = 'RESET_USER_MENU';



export const GET_DESI_WISE_MANU_REQUIST='GET_DESI_WISE_MANU_REQUIST'
export const GET_DESI_WISE_MANU_SUCCESS="GET_DESI_WISE_MANU_SUCCESS"
export const GET_DESI_WISE_MANU_FAILD="GET_DESI_WISE_MANU_FAILD"



export const CREATE_DESI_WISE_MANU_REQUIST='CREATE_DESI_WISE_MANU_REQUIST'
export const CREATE_DESI_WISE_MANU_SUCCESS="CREATE_DESI_WISE_MANU_SUCCESS"
export const CREATE_DESI_WISE_MANU_FAILD="CREATE_DESI_WISE_MANU_FAILD"