export const CREATE_SUBJECT_REQUEST = 'CREATE_SUBJECT_REQUEST';
export const CREATE_SUBJECT_SUCCESS = 'CREATE_SUBJECT_SUCCESS';
export const CREATE_SUBJECT_FAILURE = 'CREATE_SUBJECT_FAILURE';

export const GET_SUBJECT_REQUEST = 'GET_SUBJECT_REQUEST';
export const GET_SUBJECT_SUCCESS = 'GET_SUBJECT_SUCCESS';
export const GET_SUBJECT_FAILURE = 'GET_SUBJECT_FAILURE';

export const DELETE_SUBJECT_REQUEST = 'DELETE_SUBJECT_REQUEST';
export const DELETE_SUBJECT_SUCCESS = 'DELETE_SUBJECT_SUCCESS';
export const DELETE_SUBJECT_FAILURE = 'DELETE_SUBJECT_FAILURE';

export const UPDATE_SUBJECT_REQUEST = 'UPDATE_SUBJECT_REQUEST';
export const UPDATE_SUBJECT_SUCCESS = 'UPDATE_SUBJECT_SUCCESS';
export const UPDATE_SUBJECT_FAILURE = 'UPDATE_SUBJECT_FAILURE';


export const GET_CLASS_SUBJECT_REQUEST = 'GET_CLASS_SUBJECT_REQUEST';
export const GET_CLASS_SUBJECT_SUCCESS = 'GET_CLASS_SUBJECT_SUCCESS';
export const GET_CLASS_SUBJECT_FAILURE = 'GET_CLASS_SUBJECT_FAILURE';


export const CREATE_CLASS_SUBJECT_REQUEST = 'CREATE_CLASS_SUBJECT_REQUEST';
export const CREATE_CLASS_SUBJECT_SUCCESS = 'CREATE_CLASS_SUBJECT_SUCCESS';
export const CREATE_CLASS_SUBJECT_FAILURE = 'CREATE_CLASS_SUBJECT_FAILURE';

export const UPDATE_CLASS_SUBJECT_REQUEST = 'UPDATE_CLASS_SUBJECT_REQUEST';
export const UPDATE_CLASS_SUBJECT_SUCCESS = 'UPDATE_CLASS_SUBJECT_SUCCESS';
export const UPDATE_CLASS_SUBJECT_FAILURE = 'UPDATE_CLASS_SUBJECT_FAILURE';