import {
  CREATE_ORGANIZATION_FAILD,
  CREATE_ORGANIZATION_REQUIST,
  CREATE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILD,
  DELETE_ORGANIZATION_REQUIST,
  DELETE_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAILD,
  GET_ORGANIZATION_REQUIST,
  GET_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILD,
  UPDATE_ORGANIZATION_REQUIST,
  UPDATE_ORGANIZATION_SUCCESS,
} from "../../Constants/OrganizationConstant";

const initialState = {
  loading: false,
  organization: null,
};

export const organizationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_ORGANIZATION_REQUIST:
    case DELETE_ORGANIZATION_REQUIST:
    case UPDATE_ORGANIZATION_REQUIST:
    case CREATE_ORGANIZATION_REQUIST:
      return {
        ...state,
        loading: true,
        organization: null,
      };
    case GET_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organization: action.payload,
      };
    case CREATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organization: action.payload,
      };
    case DELETE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organization: action.payload,
      };
    case UPDATE_ORGANIZATION_SUCCESS:
      return {
        ...state,
        loading: false,
        organization: action.payload,
      };
    case GET_ORGANIZATION_FAILD:
    case CREATE_ORGANIZATION_FAILD:
    case DELETE_ORGANIZATION_FAILD:
    case UPDATE_ORGANIZATION_FAILD:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
