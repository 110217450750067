import {
  CREATE_STUDENT_REG_FAILURE,
  CREATE_STUDENT_REG_REQUEST,
  CREATE_STUDENT_REG_SUCCESS,
  DELETE_STUDENT_REG_FAILURE,
  DELETE_STUDENT_REG_REQUEST,
  DELETE_STUDENT_REG_SUCCESS,
  GET_CAST_FAILURE,
  GET_CAST_REQUEST,
  GET_CAST_SUCCESS,
  GET_CATEGORY_FAILURE,
  GET_CATEGORY_REQUEST,
  GET_CATEGORY_SUCCESS,
  GET_STUDENT_REG_FAILURE,
  GET_STUDENT_REG_REQUEST,
  GET_STUDENT_REG_SUCCESS,
  UPDATE_STUDENT_REG_FAILURE,
  UPDATE_STUDENT_REG_REQUEST,
  UPDATE_STUDENT_REG_SUCCESS,
} from "../../Constants/school_user/StudentRegistration";

const initialState = {
  studentdata: null,
  createstudent: null,
  updatestudent: null,
  deletestudent: null,
  loading: false,
  error: null,
  category: null,
  cast: null,
};

export const studentRegistrationReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_STUDENT_REG_REQUEST:
    case CREATE_STUDENT_REG_REQUEST:
    case UPDATE_STUDENT_REG_REQUEST:
    case DELETE_STUDENT_REG_REQUEST:
    case GET_CATEGORY_REQUEST:
    case GET_CAST_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_STUDENT_REG_SUCCESS:
      return {
        ...state,
        studentdata: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_STUDENT_REG_SUCCESS:
      return {
        ...state,
        createstudent: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_STUDENT_REG_SUCCESS:
      return {
        ...state,
        updatestudent: action.payload,
        loading: false,
        error: null,
      };
    case GET_CATEGORY_SUCCESS:
      return {
        ...state,
        category: action.payload,
        loading: false,
        error: null,
      };
    case GET_CAST_SUCCESS:
      return {
        ...state,
        cast: action.payload,
        loading: false,
        error: null,
      };
    case DELETE_STUDENT_REG_SUCCESS:
      return {
        ...state,
        deletestudent: action.payload,
        loading: false,
        error: null,
      };

    case GET_STUDENT_REG_FAILURE:
    case CREATE_STUDENT_REG_FAILURE:
    case GET_CATEGORY_FAILURE:
    case GET_CAST_FAILURE:
    case DELETE_STUDENT_REG_FAILURE:
    case UPDATE_STUDENT_REG_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
