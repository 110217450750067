import React from "react";
import { FcEmptyTrash } from "react-icons/fc";
import img4 from "../../assets/image/out-of-stock.png";

const EmptyListMessage = (props) => {
  const { message, Div ,Span ,Table,size} = props;
  return (
    <>
      {Div == "div" && (
        <div className="empty-list-message">
          <p className="no-list-message" style={{fontSize:size}}>{message} </p>
          <div className=" ">
            {/* <img src={img4} alt="pre" width={100} className="rotating-img" /> */}
          </div>
          {/* <i className="empty-icon fas fa-clipboard-list"><FcEmptyTrash/></i> */}
        </div>
      )}

      {Table === "table" && (
        <tr>
          <td colSpan={Span} className="text-center">
            <h3 className="no-list-message mt-5 ">{message}</h3>
            <div className=" ">
            {/* <img src={img4} alt="pre" width={100} className="rotating-img" /> */}
          </div>
          </td>
        </tr>
      )}
    </>
  );
};

export default EmptyListMessage;
