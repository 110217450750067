import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";

const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "multipart/form-data",
    },
    data,
  };
};
const createAxiosConfig1 = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

export const UploadAttendanceAPI = {
    uploadEmployeeAttendance: async (data) => {
    const axiosConfig = createAxiosConfig(
      "upload_employee_attendance",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  getAttendance: async (data) => {
    const axiosConfig = createAxiosConfig1(
      "get_attendance",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  updateAttendanceEntry: async (data) => {
    const axiosConfig = createAxiosConfig1(
      "update_attendance_entry",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },


}