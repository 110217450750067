import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");
export const ReceiptAPI = {
  getAllReceipt: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}search_regular_fee_receipt/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
 
  updateregularReceipt: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}edit_regular_fee_receipt/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  updatespecialReceipt: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}edit_special_regular_fee_receipt/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },


  cancelregularReceipt: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}cancel_regular_receipt/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  cancelspecialReceipt: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}cancel_special_fee_receipt/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  
  
  getAllSpecialReceipt: async (data) => {
    var axiosConfig = {
      method: "post",
      url: `${BASE_URL}search_special_fee_receipt/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
 
};