import React from 'react'
import axios from "axios";
import { BASE_URL } from "../Config/BaseUrl";

const token = localStorage.getItem("token");

export const sessionAPI = {
    getAllSession: async (data) => {
        var axiosConfig = {
          method: "post",
          url: `${BASE_URL}get_academic_session/`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data,
        };
        return await axios(axiosConfig);
      },
      createSession: async (data) => {
        var axiosConfig = {
          method: "post",
          url: `${BASE_URL}add_academic_session/`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data,
        };
        return await axios(axiosConfig);
      },
      updateSession: async (data) => {
        var axiosConfig = {
          method: "post",
          url: `${BASE_URL}update_academic_session/`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data,
        };
        return await axios(axiosConfig);
      },
      deleteSession: async (data) => {
        var axiosConfig = {
          method: "post",
          url: `${BASE_URL}delete_academic_session/`,
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "application/json",
          },
          data,
        };
        return await axios(axiosConfig);
      },
}
