import React, { useState, useEffect } from "react";
import { useNavigate, NavLink } from "react-router-dom";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { setActiveTab } from "../../../actions/TabAction/TabAction";
import { UsersAPI } from "../../../api/usersAPI";
import {
  getStaffList,
  updateStaff,
} from "../../../actions/school_user/StaffMaster";
import useSessionRedirect from "../Comman/SessionChange";
import ImageUpload from "../studentRegistration/ImageUpload";
import { bloodGroupList, genderOptions } from "../../../data/optionData";
import useEnsureId from "../Comman/useEnsureId";
const EditStaffMaster = () => {
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let s_id = school_user?.user_detail?.fk_school_id;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { staffdata } = useSelector((state) => state.staffMaster);
  const [desOptionList, setDesOptionList] = useState([]);
  const [editData, setEditData] = useState([]);
  const [ShowImg, setShowImg] = useState("");
  const id = useEnsureId();
  const [temp, setTemp] = useState(true);
  const [loginUser, setLoginUser] = useState(false);
  const [reason, setReason] = useState("");
  const [remark, setRemark] = useState("");




  useEffect(() => {
    const fetchData = async () => {
      try {
        if (token && !staffdata) {
          await dispatch(getStaffList(token, s_id));
        }
      } catch (error) {
        console.error("Error fetching staff data:", error);
      }
    };

    fetchData();
    if (staffdata && id) {
      let post = staffdata.payload.find((sid) => sid.id === Number(id));
      if (JSON.stringify(post) !== JSON.stringify(editData)) {
        setEditData(post);
        setShowImg(post?.profile);
        setLoginUser(post?.login_user === "True" ? true : false);
        setTemp(post?.staff_status === "Active" ? true : false);
        setReason(post?.reason);
        setRemark(post?.remark);
      }
    }
  }, [id, token, s_id, dispatch, staffdata, editData]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const res = await UsersAPI.getSchoolDesignation();
        const desigOptions = res.data.designation_list
          .map((item) => ({
            value: item.id,
            label: item.value,
          }))
          .sort((a, b) => a.label.localeCompare(b.label));
        setDesOptionList(desigOptions);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, []);

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      fk_school: s_id,
      first_name: editData?.first_name || "",
      last_name: editData?.last_name || "",
      emp_no: editData?.emp_no || "",
      mobile_no: editData?.mobile_no || "",
      date_of_birth: editData?.date_of_birth || "",
      email: editData?.email || "",
      address: editData?.address || "",
      adhar_no: editData?.adhar_no || "",
      pan_no: editData?.pan_no || "",
      joining_date: editData?.joining_date || "",
      leaving_date: editData?.leaving_date || "",
      qualification: editData?.qualification || "",
      blood_group: editData?.blood_group || "",
      fk_designation: editData?.fk_designation_id || "",
      profile: editData?.profile || "",
      is_removeProfile: "False",
      gender: editData?.gender || "",
      login_user: editData?.login_user == "True" ? true : false || false,
    },
    validationSchema: Yup.object().shape({
      first_name: Yup.string().required("Firstname is required"),
      last_name: Yup.string().required("Lastname is required"),
      emp_no: Yup.string().required("Employee number is required"),
      // .matches(/^\d{6}$/, "Employee number should be a 6-digit number"),
      email: Yup.string()
        .required("Email is required")
        .email("Invalid email address"),
      address: Yup.string()
        .required("Address is required")
        .min(1, "Address must be at least 10 characters"),
      date_of_birth: Yup.date()
        .max(new Date(), "Future date are not allowed")
        .required("Date of birth is required"),
      joining_date: Yup.date()
        .max(new Date(), "Future date are not allowed")
        .required("joining date is required"),
      mobile_no: Yup.string()
        .matches(/^[0-9]{10}$/, "Invalid mobile number")
        .required("Mobile No is required"),
      adhar_no: Yup.string()
        .matches(/^[0-9]{12}$/, "Invalid Aadhaar number")
        .notRequired("Aadhaar number is required"),
      pan_no: Yup.string()
        .matches(/^([A-Za-z]){5}([0-9]){4}([A-Za-z]){1}$/, "Invalid PAN number")
        .notRequired("PAN number is required"),
      qualification: Yup.string().required("Qualification is required"),
      blood_group: Yup.string().required("Blood Group is required"),
      fk_designation: Yup.string().required("Designation is required"),
      gender: Yup.string().required("Gender is required"),
      // profile: Yup.mixed()
      // .required('Profile image is required')
      // .test(
      //   'fileFormat',
      //   'Unsupported file format',
      //   (value) => value && ['image/jpeg', 'image/png', 'image/gif'].includes(value.type)
      // ),
    }),

    onSubmit: (values) => {
      let data1;
      if (temp) {
        data1 = {
          ...values,
          staff_status: temp ? "Active" : "InActive",
          login_user: loginUser,
          reason: "",
          remark: "",
        };
      } else {
        data1 = {
          ...values,
          staff_status: temp ? "Active" : "InActive",
          login_user: loginUser,
          reason: reason,
          remark: remark,
        };
      }

      if (data1.staff_status === "InActive" && !data1.reason) {
        Swal.fire({
          title: "Please Enter Reason",
          text: "Reason is required",
          icon: "warning",
          allowOutsideClick: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#3085d6",
          allowEscapeKey: false,
        });
        return;
      }

      if (data1.staff_status === "InActive" && !data1.leaving_date) {
        Swal.fire({
          title: "Please Enter leaving date",
          text: "leaving date is required",
          icon: "warning",
          allowOutsideClick: false,
          confirmButtonText: "Ok",
          confirmButtonColor: "#3085d6",
          allowEscapeKey: false,
        });
        return;
      }
      const data = new FormData();
      data.append("fk_school", data1.fk_school);
      data.append("first_name", data1.first_name);
      data.append("last_name", data1.last_name);
      data.append("gender", data1.gender);
      data.append("login_user", data1.login_user ? "True" : "False");
      data.append("emp_no", data1.emp_no);
      data.append("mobile_no", data1.mobile_no);
      data.append("date_of_birth", data1.date_of_birth);
      data.append("email", data1.email);
      data.append("address", data1.address);
      data.append("fk_designation", data1.fk_designation);
      data.append("adhar_no", data1.adhar_no);
      data.append("pan_no", data1.pan_no);
      data.append("qualification", data1.qualification);
      data.append("blood_group", data1.blood_group);
      data.append("profile", data1.profile);
      data.append("is_removeProfile", data1.is_removeProfile);
      data.append("staff_status", data1.staff_status);
      data.append("joining_date", data1.joining_date);
      data.append("leaving_date", data1.leaving_date);
      data.append("remark", data1.remark);
      data.append("reason", data1.reason);

      dispatch(updateStaff(token, editData?.id, data, navigate))
        .then(() => {
          setShowImg("");
          dispatch(setActiveTab("Staff-all"));
        })
        .catch((err) => console.error(err));
    },
  });

  console.log(loginUser, "login user");

  const formClear = () => {
    validation.resetForm();
  };

  const handelLoginChange = () => {
    setLoginUser(!loginUser);
  };
  const tempChange = () => {
    setTemp(!temp);
    // setReason("");
    // setRemark("");
  };

  let handleCancel = (event) => {
    event.preventDefault();
    navigate("/school/staff-master");
  };
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Staff Master</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  data-toggle="tab"
                  href="#staff-master"
                  as={NavLink}
                  to="/school/staff-master"
                >
                  Staff List
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Staff-edit"
                >
                  Edit Staff
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="tab-pane active" id="Staff-edit">
              <div className="row clearfix">
                <div className="col-lg-12 col-lg-12 col-sm-12">
                  <div className="card">
                    <form
                      className="card-body"
                      onSubmit={(e) => {
                        e.preventDefault();
                        validation.handleSubmit();
                        return false;
                      }}
                    >
                      <div className="row">
                        <div className="col-lg-9">
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label ">
                              First Name<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.first_name &&
                                  validation.errors.first_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter First Name"
                                name="first_name"
                                value={validation.values.first_name}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                autoComplete="off"
                              />
                              {validation.touched.first_name &&
                              validation.errors.first_name ? (
                                <div className="invalid-feedback">
                                  {validation.errors.first_name}
                                </div>
                              ) : null}
                            </div>
                            <label className="col-lg-2 col-form-label ">
                              Last Name<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.last_name &&
                                  validation.errors.last_name
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Last Name"
                                name="last_name"
                                value={validation.values.last_name}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.last_name &&
                              validation.errors.last_name ? (
                                <div className="invalid-feedback">
                                  {validation.errors.last_name}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Employee No<span className="text-danger"></span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.emp_no &&
                                  validation.errors.emp_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Employee Number"
                                name="emp_no"
                                value={validation.values.emp_no}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.emp_no &&
                              validation.errors.emp_no ? (
                                <div className="invalid-feedback">
                                  {validation.errors.emp_no}
                                </div>
                              ) : null}
                            </div>
                            <label className="col-lg-2 col-form-label">
                              Mobile No <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.mobile_no &&
                                  validation.errors.mobile_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Mobile Number"
                                name="mobile_no"
                                value={validation.values.mobile_no}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    /^\d*$/.test(inputValue) &&
                                    inputValue.length <= 10
                                  ) {
                                    validation.handleChange(e);
                                  }
                                }}
                                onBlur={validation.handleBlur}
                                maxLength={10}
                              />
                              {validation.touched.mobile_no &&
                              validation.errors.mobile_no ? (
                                <div className="invalid-feedback">
                                  {validation.errors.mobile_no}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              DOB<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="date"
                                className={`form-control input-height ${
                                  validation.touched.date_of_birth &&
                                  validation.errors.date_of_birth
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="date_of_birth"
                                value={validation.values.date_of_birth}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.date_of_birth &&
                              validation.errors.date_of_birth ? (
                                <div className="error-message">
                                  {validation.errors.date_of_birth}
                                </div>
                              ) : null}
                            </div>
                            <label className="col-lg-2 col-form-label">
                              Gender<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <Select
                                name="gender"
                                options={genderOptions}
                                placeholder="Select Gender"
                                value={genderOptions?.find(
                                  (option) =>
                                    option.value === validation.values.gender
                                )}
                                onChange={(selectedOption) =>
                                  validation.setFieldValue(
                                    "gender",
                                    selectedOption?.value
                                  )
                                }
                                onBlur={validation.handleBlur}
                                className={`${
                                  validation.touched.gender &&
                                  validation.errors.gender
                                    ? "border-color"
                                    : ""
                                }`}
                                isSearchable={true}
                              />
                              {validation.touched.gender &&
                                validation.errors.gender && (
                                  <div className="error-message">
                                    {validation.errors.gender}
                                  </div>
                                )}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label ">
                              Email<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="email"
                                className={`form-control input-height ${
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Email"
                                name="email"
                                value={validation.values.email}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <div className="invalid-feedback">
                                  {validation.errors.email}
                                </div>
                              ) : null}
                            </div>
                            <label className="col-lg-2 col-form-label">
                              Joining Date<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="date"
                                className={`form-control input-height ${
                                  validation.touched.joining_date &&
                                  validation.errors.joining_date
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="joining_date"
                                value={validation.values.joining_date}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.joining_date &&
                              validation.errors.joining_date ? (
                                <div className="error-message">
                                  {validation.errors.joining_date}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Qualification
                              <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.qualification &&
                                  validation.errors.qualification
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Qualification"
                                name="qualification"
                                value={validation.values.qualification}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.qualification &&
                              validation.errors.qualification ? (
                                <div className="invalid-feedback">
                                  {validation.errors.qualification}
                                </div>
                              ) : null}
                            </div>
                            <label className="col-lg-2 col-form-label">
                              Blood Group<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-4">
                              <Select
                                name="status"
                                options={bloodGroupList}
                                placeholder="Select Blood Group "
                                onChange={(option) =>
                                  validation.setFieldValue(
                                    "blood_group",
                                    option.label
                                  )
                                }
                                onBlur={validation.handleBlur("blood_group")}
                                value={
                                  validation.values.blood_group
                                    ? bloodGroupList.find(
                                        (option) =>
                                          option.label ===
                                          validation.values.blood_group
                                      )
                                    : null
                                }
                                isSearchable={true}
                                className={`${
                                  validation.touched.blood_group &&
                                  validation.errors.blood_group
                                    ? "border-color"
                                    : ""
                                }`}
                              />
                              {validation.touched.blood_group &&
                              validation.errors.blood_group ? (
                                <div className="error-message">
                                  {validation.errors.blood_group}
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Aadhaar No<span className="text-danger"></span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.adhar_no &&
                                  validation.errors.adhar_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter Aadhaar Number"
                                name="adhar_no"
                                value={validation.values.adhar_no}
                                onChange={(e) => {
                                  const inputValue = e.target.value;
                                  if (
                                    /^\d*$/.test(inputValue) &&
                                    inputValue.length <= 12
                                  ) {
                                    validation.handleChange(e);
                                  }
                                }}
                                onBlur={validation.handleBlur}
                                autoComplete="off"
                                maxLength={12}
                              />
                              {validation.touched.adhar_no &&
                              validation.errors.adhar_no ? (
                                <div className="invalid-feedback">
                                  {validation.errors.adhar_no}
                                </div>
                              ) : null}
                            </div>
                            <label
                              className="col-lg-2 col-form-label"
                              htmlFor="validationCustomUsername"
                            >
                              PAN No<span className="text-danger"></span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.pan_no &&
                                  validation.errors.pan_no
                                    ? "is-invalid"
                                    : ""
                                }`}
                                placeholder="Enter PAN number"
                                name="pan_no"
                                value={validation.values.pan_no.toUpperCase()}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                autoComplete="off"
                                maxLength={10}
                              />
                              {validation.touched.pan_no &&
                              validation.errors.pan_no ? (
                                <div className="invalid-feedback">
                                  {validation.errors.pan_no}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Designation <span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-10">
                              <Select
                                options={desOptionList}
                                name="fk_designation"
                                placeholder="Select Designation"
                                onChange={(option) =>
                                  validation.setFieldValue(
                                    "fk_designation",
                                    option.value
                                  )
                                }
                                value={
                                  validation.values.fk_designation
                                    ? desOptionList.find(
                                        (option) =>
                                          option.value ===
                                          validation.values.fk_designation
                                      )
                                    : null
                                }
                                isSearchable={true}
                                className={`${
                                  validation.touched.fk_designation &&
                                  validation.errors.fk_designation
                                    ? "border-color"
                                    : ""
                                }`}
                              />
                              {validation.touched.fk_designation &&
                              validation.errors.fk_designation ? (
                                <div className="error-message">
                                  {validation.errors.fk_designation}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-2 col-form-label">
                              Address<span className="text-danger">*</span>
                            </label>
                            <div className="col-lg-10">
                              <input
                                type="text"
                                className={`form-control input-height ${
                                  validation.touched.address &&
                                  validation.errors.address
                                    ? "is-invalid"
                                    : ""
                                }`}
                                name="address"
                                placeholder="Enter Address"
                                value={validation.values.address}
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                              />
                              {validation.touched.address &&
                              validation.errors.address ? (
                                <div className="invalid-feedback">
                                  {validation.errors.address}
                                </div>
                              ) : null}
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">
                              Staff As Login User{" "}
                              <span className="text-danger"></span>
                            </label>

                            <div className="col-lg-3 d-flex  align-items-center">
                              <label className="custom-switch pr-2">
                                <input
                                  type="checkbox"
                                  className="custom-switch-input"
                                  name="login_user"
                                  checked={loginUser}
                                  onChange={handelLoginChange}
                                  // onBlur={validation.handleBlur}
                                />
                                <span className="custom-switch-indicator"></span>
                              </label>
                              {loginUser ? <b>Yes</b> : <b>No</b>}
                            </div>

                            <label className="col-lg-2 col-form-label">
                              Machine Code <span className="text-danger"></span>
                            </label>
                            <div className="col-lg-4">
                              <input
                                type="text"
                                className="form-control input-height"
                                placeholder=" Enter machine code"
                                name="machine_code"
                                value={editData?.id}
                                disabled
                              />
                            </div>
                          </div>
                          <div className="form-group row">
                            <label className="col-lg-3 col-form-label">
                              Active
                              <span className="text-danger"></span>
                            </label>

                            <div className="col-lg-9 d-flex  align-items-center">
                              <label className="custom-switch pr-2">
                                <input
                                  type="checkbox"
                                  className="custom-switch-input"
                                  name="staff_status"
                                  checked={temp}
                                  onChange={tempChange}
                                  onBlur={validation.handleBlur}
                                />
                                <span className="custom-switch-indicator"></span>
                              </label>
                            </div>
                          </div>
                          {!temp && (
                            <>
                              <div className="form-group row">
                                <label className="col-lg-2 col-form-label">
                                  Leaving Date
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-4">
                                  <input
                                    type="date"
                                    className={`form-control input-height ${
                                      validation.touched.leaving_date &&
                                      validation.errors.leaving_date
                                        ? "is-invalid"
                                        : ""
                                    }`}
                                    name="leaving_date"
                                    value={validation.values.leaving_date}
                                    onChange={validation.handleChange}
                                    onBlur={validation.handleBlur}
                                  />
                                  {validation.touched.leaving_date &&
                                  validation.errors.leaving_date ? (
                                    <div className="error-message">
                                      {validation.errors.leaving_date}
                                    </div>
                                  ) : null}
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-lg-2 col-form-label">
                                  Reason<span className="text-danger">*</span>
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    type="text"
                                    className={"form-control input-height"}
                                    name="reason"
                                    placeholder="Enter reason"
                                    value={reason}
                                    onChange={(e) => setReason(e.target.value)}
                                  />
                                </div>
                              </div>
                              <div className="form-group row">
                                <label className="col-lg-2 col-form-label">
                                  Remark
                                </label>
                                <div className="col-lg-10">
                                  <input
                                    type="text"
                                    className={"form-control input-height"}
                                    name="remark"
                                    placeholder="Enter Remark"
                                    value={remark}
                                    onChange={(e) => setRemark(e.target.value)}
                                  />
                                </div>
                              </div>
                            </>
                          )}
                        </div>
                        <div className="col-lg-3">
                          <ImageUpload
                            formik={validation}
                            fieldName="profile"
                            fieldName1="is_removeProfile"
                            showImg={ShowImg}
                            setShowImg={setShowImg}
                          />
                          {/* <div className="d-flex justify-content-center mb-5">
                            {ShowImg ? (
                              <img
                                alt="Cropped Img"
                                src={ShowImg}
                                className="crop_img"
                                width={100}
                              />
                            ) : editData?.profile ? (
                              <img
                                width={100}
                                src={editData?.profile}
                                className="crop_img"
                                alt="..."
                              />
                            ) : (
                              <img
                                width={100}
                                src={pic1}
                                className="crop_img"
                                alt="..."
                              />
                            )}
                          </div>
                          <div className="form-group row">
                            
                            <div className="col-lg-10 mx-auto">
                           
                              <input
                                style={{ width: "100%" }}
                                type="file"
                                className="dropify mx-auto"
                                name="profile"
                                accept="image/*"
                                onChange={handleFileRead}
                              />
                            </div>
                          </div> */}
                        </div>
                      </div>
                      <div className="col-sm-12 justify-content-center align-item-center my-3">
                        <button
                          type="submit"
                          className="mr-1 btn btn-primary btn-sm"
                        >
                          {" "}
                          <i className="fa fa-plus pr-1"></i>
                          Update
                        </button>
                        <button
                          onClick={handleCancel}
                          className="btn btn-outline-danger btn-sm"
                        >
                          {" "}
                          <i className="fa fa-times pr-1"></i>
                          Cancel
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditStaffMaster;
