import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";

const GroupClassList = (props) => {
  const { classGroupList, isGroupId, setIsGroupId } = props;
  const [modal, setModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const handleSelectGroup = (Id) => {
    setIsGroupId(Id);
    setModal(!modal);
  };
  const closeBtn = (
    <button className="btn btn-sm close " onClick={toggle} type="button">
      <i className="fa fa-times" style={{ color: "red" }}></i>
    </button>
  );
  return (
    <>
      <button
        className="btn btn-outline-secondary btn-sm mx-1"
        type="button"
        onClick={toggle}
      >
        <i className="fa fa-search"> </i> Search
      </button>
      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box" backdrop="static" keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          {/* {title} */}
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            {classGroupList && classGroupList?.length > 0 ? (
              <Table size="sm" className="table-bordered" striped>
                <thead className="table-dark">
                  <tr className="text-center text-nowrap ">
                    <th>Sr No.</th>
                    <th>Group Name</th>
                  </tr>
                </thead>
                <tbody>
                  {classGroupList?.map((stu, index) => (
                    <tr
                      key={index}
                      className="clickable-row-color"
                      onClick={() => handleSelectGroup(stu.id)}
                    >
                      <td className="text-center">{index + 1}</td>
                      <td>{stu.group_name}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyListMessage
                message={"Class Group Not Available !"}
                Div="div"
              />
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default GroupClassList;
