import React, { useState } from "react";
import Switch from "react-switch";

const CheckButton = ({ label, formik, fieldName }) => {
  const [checked, setChecked] = useState(false);
  const handleChange = (nextChecked) => {
    setChecked(nextChecked);
  };
  return (
    <>
      <label className="col-md-6 col-form-label">
        {label}
        <span className="text-danger">*</span>
      </label>
      <div className="col-md-6 d-flex align-items-center">
      <Switch
          // onChange={handleChange}
          // checked={checked}
          onChange={(checked) => formik.setFieldValue(fieldName, checked)}
          checked={formik.values[fieldName]}
          className="react-switch"
          borderRadius={6}
         
        />
        {/* <Switch
          // onChange={(checked) => formik.setFieldValue(fieldName, checked)}
          // checked={formik.values[fieldName]}
          onChange={handleChange}
          checked={checked}
          onColor="#42a5f5"
          offColor="#888"
          className="react-switch"
          handleDiameter={24}
          checkedIcon={
            <div className="switch-label">
              <span className="switch-text">Yes</span>
            </div>
          }
          uncheckedIcon={
            <div className="switch-label">
              <span className="switch-text">No</span>
            </div>
          }
        /> */}
      </div>
    </>
  );
};

export default CheckButton;
