import React, { useState } from "react";
import {
  ClearButton,
  SaveButton,
  ViewButton,
} from "../../../CommonComponent/ReusableButton";
import { useLocation } from "react-router-dom";

const AssignPickupPoint = (props) => {
  const location = useLocation()
  const id = location.state.id
  console.log(id)

  return (
    <>
    
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 col-md-12">
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Pickup/Drop Point <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        placeholder="Pickup/Drop Point"
                        className="form-control custom-input"
                        name="registration_no"
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Route <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        placeholder="Route"
                        className="form-control custom-input"
                        name="registration_no"
                      />
                    </div>
                  </div>

                  <div className="form-group col-md-12 row">
                    <div className="form-group col-md-12 row">
                      <label className="col-md-3 col-form-label">
                        New Pickup/Drop Point
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          placeholder="New Pickup/Drop Point"
                          className="form-control custom-input"
                          name="registration_no"
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-12 row">
                      <label className="col-md-3 col-form-label">
                        New Route <span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9">
                        <input
                          type="text"
                          placeholder="New Route"
                          className="form-control custom-input"
                          name="registration_no"
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-12 row mt-3">
                      <label className="  col-md-3 col-form-label">
                        Assign Date
                      </label>
                      <div className="col-md-3">
                        <input
                          type="date"
                          placeholder="Registration No"
                          className="form-control custom-input"
                          name="registration_no"
                        />
                      </div>
                      <label className="  col-md-3 col-form-label">
                        Bus Fee
                      </label>
                      <div className="col-md-3">
                        <input
                          type=""
                          placeholder=" Bus Fee "
                          className="form-control custom-input"
                          name="registration_no"
                        />
                      </div>
                    </div>
                    <div className="form-group col-md-12 row">
                      <label className="  col-md-3 col-form-label">
                        Bus Start Date<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-3">
                        <input
                          type="date"
                          placeholder=" Bus Start Dat"
                          className="form-control custom-input"
                          name="registration_no"
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="justify-content-center align-items-center">
                  <SaveButton className={"mx-1 m-1"} />
                  <ClearButton className={"mx-1 m-1"} />
                </div>
              </div>
            </div>
          </div>    
    </>
  );
};

export default AssignPickupPoint;
