import html2pdf from "html2pdf.js";
import React, { useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import Swal from "sweetalert2";
import numberToWords from "number-to-words";
import { useDispatch, useSelector } from "react-redux";
import './styles.css'
import www from '../Examination/GradeCard/www.png'
const PrintProspectusReceipt = (props) => {
  const { logoData, loading, error } = useSelector((state) => state.schoolLogo);
  const {
    receiptDate,
    schoolUser,
    session,
    classSec,
    btnClick,
    convertDate,
    navigate,
    handleClear,
    formData,
    handleForm,
    receiptType,
    handleDelete,
    alert,
  } = props;
  const { email, user_detail } = schoolUser;
  const {
    school_name,
    organization_name,
    school_address,
    school_contact_email,
    school_contact_number,
    school_pincode,
    udise_no,
    school_logo,
  } = user_detail;
  const {
    id,
    fk_academic_id,
    fk_school_id,
    student_name,
    pros_number,
    pros_date,
    contact_no,
    amount,
    payment_mode,
    bank_name,
    pay_no,
    pay_date,
    remark,
  } = formData;
  console.log(formData, "formData");
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const toggle = () => {
    setModal(!modal);
    setSearchResults([]);
    setNoResultsMessage("");
  };
  const toggle1 = () => {
    setModal1(!modal1);

  };
  

  const handleConvertToPdf = async () => {
    const element = document.getElementById("divToConvert");
    if (element) {
      const opt = {
        margin: 10,
        filename: "generated.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      // Generate the PDF content as a data URL
      const pdfDataUri = await html2pdf()
        .from(element)
        .set(opt)
        .outputPdf("datauristring");

      // Open the PDF in a new tab
      const newTab = window.open();
      newTab.document.write(
        '<style> body { margin: 0px !important; overflow: hidden !important; }</style><iframe width="100%" height="100%" src="' +
          pdfDataUri +
          '"></iframe>'
      );
      newTab.document.close();
      // handleForm();
      handleClear();
      toggle1();
    }
  };
 const  handelClose = () => {
    handleClear()
    toggle1()
  }

  const words = numberToWords.toWords(Number(amount));

  return (
    <>
      <button
        type="button"
        className="btn btn-primary mx-1"
        onClick={() => {
          if (
            formData.contact_no &&
            !formData?.contact_no.match(/^[0-9]{10}$/)
          ) {
            alert.error("Invalid mobile number");
          } else {
            if (!formData.pros_number) {
              alert.error("Please enter Prospectus No");
            } else if (!formData.student_name) {
              alert.error("Please enter Student Name");
            } else if (!formData.amount) {
              alert.error("Please enter Total Amount");
            } else {
              Swal.fire({
                title: "CONFIRMATION",
                text: "You want to Save Receipt?",
                icon: "question",
                showDenyButton: true,
                confirmButtonColor: "#3085d6",
                cancelButtonColor: "#f46a6a",
                confirmButtonText: "Save",
                denyButtonText: `No`,
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  handleForm();
                  Swal.fire({
                    title: "CONFIRMATION",
                    text: `Prospectus details ${
                      receiptType === "New" ? "Save" : "Update"
                    } successfully. Do you want to Proceed for Receipt?`,
                    icon: "success",
                    showDenyButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#f46a6a", 
                    confirmButtonText: "Yes",
                    denyButtonText: `No`,
                    allowOutsideClick: false,
                    // willClose: (e) => {
                    //   handleClear();
                    // },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      toggle1();
                      // handleClear();
                    } else if (result.isDenied) {
                      handleClear();
                    }
                  });
                } else if (result.isDenied) {
                  console.log(result.isDenied);
                }
              });
            }
          }
        }}
        // disabled={btnClick}
      >
        {receiptType === "New" ? "Save" : "Update"}
      </button>
      {receiptType === "New" ? null : (
        <button
          type="button"
          className="btn btn-danger mx-2"
          onClick={() => {
            Swal.fire({
              title: "CONFIRMATION",
              text: "Do you want to Delete Record?",
              icon: "question",
              showDenyButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Yes",
              denyButtonText: `No`,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                handleDelete();
              } else if (result.isDenied) {
                console.log(result.isDenied);
              }
            });
          }}
        >
          Delete
        </button>
      )}
      <button
        type="button"
        className="btn btn-outline-danger mx-2"
        onClick={() => handleClear()}
      >
        <i className="fa fa-times px-1"></i>
        Clear
      </button>

      <Modal isOpen={modal1} toggle={toggle1} fullscreen  className="modal-lg" backdrop="static">
      <div className="text-right m-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                handleConvertToPdf();
              }}
            >
              Print Fees Receipt
            </button>
            <button
              className="btn btn-secondary mx-1 "
              onClick={() => {
                handelClose();
              }}
            >
              Close
            </button>
            <hr className="border border-muted" />

          </div>
        <ModalBody>
          <div className="container-fluid" id="divToConvert">
            <main>
            <div className="d-flex align-items-stretch">
               
               <div className="col-3 m-auto">
                 {logoData ? (
                   <img
                     src={`data:image/png;base64,${logoData}`}
                     alt="school_logo"
                     width={100}
                     height={100}
                     className=""
                     style={{ borderRadius: "50%" }}
                   />
                 ) : (
                   <img
                    src={www}
                     alt="fallback_image"
                     width={100}
                     height={100}
                     style={{ borderRadius: "50%" }}
                   />
                 )}
              </div>

          
               <div className="col-9">
                 <div className="text-center col-8">
                   <p className="font-weight-bold text-dark">{organization_name}</p>
                   <div className="font-weight-bold">
                     {school_name}
                   </div>
                   <div>
                     <h6>{school_address}</h6>
                     <h6 style={{fontSize: "0.7rem"}}>
                       Pincode- {school_pincode}, Ph: {school_contact_number}
                     </h6>
                     <h6 style={{fontSize: "0.7rem"}}>E-mail: {school_contact_email}</h6>
                   </div>
                 </div>
               </div>
             </div> 
              <hr className="border border-dark" />

              <section>
                <div className="row">
                  <div className="col-6">
                    {/* <h6>Reg No : {udise_no}</h6> */}
                    <h6>Name : {student_name}</h6>
                    <h6>Mobile No : {contact_no}</h6>
                  </div>
                  <div className="col-6">
                    {/* <h6>Session : {session}</h6> */}
                    <h6>Prospectus No : {pros_number}</h6>
                    <h6>Receipt Date : {convertDate(receiptDate)}</h6>
                  </div>
                </div>

                <div className="row clearfix">
                  <div className="table-responsive card">
                    <table className="table table-hover table-vcenter  mb-0 text-nowrap ">
                      <thead className="text-center font-weight-bold text-dark Total">
                        <th className="font-weight-bold text-dark">Sr. No</th>
                        <th className="font-weight-bold text-dark">Particular(s) </th>
                        <th className="font-weight-bold text-dark">Amount</th>
                      </thead>
                      <tbody>
                        <tr>
                          <td>1.</td>
                          <td>Prospectus Fee</td>
                          <td className="font-weight-bold text-right">{amount}</td>{console.log(amount + "amount")}
                        </tr>
                        <tr>
                          <th colSpan={2} className="text-right font-weight-bold text-dark Total">
                            Total Amount
                          </th>
                          <td className="font-weight-bold text-right Total">{amount}</td>
                        </tr>

                        <tr>
                          <th colSpan={2} className="text-right font-weight-bold text-dark  Total">
                            Paid Amount
                          </th>
                          <td className="font-weight-bold text-right Total ">
                            <strong className="font-weight-bold text-dark">{amount}</strong>
                          </td>
                        </tr>

                        <tr>
                          <th colSpan={1} className="font-weight-bold text-dark">In Word</th>
                          <th colSpan={1} className="text-right font-weight-bold text-dark">
                            <strong>{words} Rupees</strong>
                          </th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div>
                  <h6>Payment Mode : {payment_mode}</h6>
                  {payment_mode === "Cash" ? null : (
                    <>
                      {payment_mode === "UPI" ? null : (
                        <h6>Bank Name : {bank_name}</h6>
                      )}
                      <h6>
                        {payment_mode === "Bank" ? "Account" : payment_mode} No.
                        : {pay_no}
                      </h6>
                      <h6>Payment Date : {convertDate(pay_date)}</h6>
                    </>
                  )}
                </div>
              </section>

              <p>Remark : {remark}</p>
              <hr className="border border-dark" />

              <footer className="row pt-5 mt-5">
                <div className="col-6">
                  <h6>Received By : Clerk </h6>
                </div>
                <div className="col-6 text-right">
                  <h6>Signature </h6>
                </div>
                <hr />
              </footer>
            </main>
          </div>
         
       
            
        </ModalBody>
      </Modal>
    </>
  );
};

export default PrintProspectusReceipt;
