import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import CategoryList from "./CategoryList";
import { NavLink } from "react-router-dom";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import { categoryMasterAPI } from "../../../../api/Examination/Category";
import useLoading from "../../../CommonComponent/useLoading";
import Loader from "../../../Loader/Loader";
import Swal from "sweetalert2";



const CategoryMaster = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  const [groupList, setGroupList] = useState([]);
  console.log(groupList)
  const [categoryList, setCategoryList] = useState([]);
  const [SubjectOption, setSubjectOption] = useState([]);
  const [SubjectFilterOption, setSubjectFilterOption] = useState([]);
  const [isCategory, setIsCategory] = useState([]);
  const [subject, setSubject] = useState({
    subject_id: "",
    sequence_no: "",
  });
  const [subjectList, setSubjectList] = useState([]);

  // const existingSubjectIds = [4, 11, 13];
  // const filteredSubjectOptions = SubjectOption.filter(
  //   (option) => !existingSubjectIds.includes(option.value)
  // );
  // const filteredSubjectOptions = SubjectOption?.filter(
  //   (option) => !existingSubjectIds.some((existingSubject) => existingSubject.subject_id === option.value)
  // );

  useEffect(() => {
    let filterSubject = SubjectOption?.filter(
      (s) => !subjectList.some((item) => item.subject_id === s.value)
    );
    setSubjectFilterOption(filterSubject);
  }, [SubjectOption,subjectList]);

  let [validationError, setValidationError] = useState(false);
  let validationControl = async () => {
    let Validationstatus = true;
    if (!subject.subject_id) {
      Validationstatus = false;
    } else if (!subject.sequence_no) {
      Validationstatus = false;
    }
    if (!Validationstatus) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 2000);
    }
    return Validationstatus;
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setSubject((prev) => ({ ...prev, [name]: value }));
  };
  const handleDeleteSubject = (number) => {
    let subjects = subjectList?.filter((sub) => sub.sequence_no !== number);
    setSubjectList(subjects);
  };
  const handleAddSubject = async () => {
    let isValid = await validationControl();
    if (!isValid) return;
    setSubjectList([...subjectList, subject]);
    setSubject({
      subject_id: "",
      sequence_no: "",
    });
  };
 
  const fetchCategoryList = async () => {
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
      };
      let response = await categoryMasterAPI.getCategory(body);
      let responseData = response.data;
      setCategoryList(responseData.Exam_category);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchGroup = async () => {
    let body = {
      school_id: school_id,
      academic_id: session_id,
    };
    try {
      let response = await categoryMasterAPI.getGroupList(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        let list = responseData?.GroupList?.map((c) => ({
          label: `${c.group_name}`,
          value: c.id,
        }));
        setGroupList(list);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const fetchSubject = async () => {
    let body = {
      school_id: school_id,
      academic_id: session_id,
    };
    try {
      let response = await categoryMasterAPI.getSubjectList(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        let list = responseData?.Subject?.map((c) => ({
          label: `${c.subject_name}`,
          value: c.subject_id,
        }));
        setSubjectOption(list);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    fetchGroup();
    fetchSubject();
    fetchCategoryList();
  }, [session_id, school_id]);
  let markingOption = [
    { label: "Mark", value: "Mark" },
    { label: "Grade", value: "Grade" },
  ];
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      insert_by: school_emp_id,
      category_id: isCategory?.id ?? "",
      group_id: isCategory?.fk_group ?? "",
      category_name: isCategory?.category_name ?? "",
      marking: isCategory?.marking ?? "",
      subject: [],
    },
    validationSchema: Yup.object().shape({
      category_name: Yup.string().required("This field is required."),
      group_id: Yup.string().required("This field is required."),
      marking: Yup.string().required("This field is required."),
    }),

    onSubmit: async (values) => {
      if (subjectList?.length === 0) {
        Swal.fire({
          title: "",
          text: "Please Add Subject ",
          icon: "warning",
          allowOutsideClick: false,
        });
        return;
      }
      startLoading();
      try {
        const data = new FormData();
        data.append("academic_id", values.academic_id);
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("group_id", values.group_id);
        data.append("category_id", values.category_id);
        data.append("marking", values.marking);
        data.append("category_name", values.category_name);
        data.append("subject", JSON.stringify(subjectList));
        const response = await categoryMasterAPI.createCategory(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          fetchCategoryList();
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        stopLoading();
      }
    },
  });

  const handleClear = () => {
    formik.resetForm();
    setIsEdit(false);
    setIsDelete(true);
    setIsCategory([]);
    setSubjectList([]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    formik.handleSubmit();
    return false;
  };
  const fetchForEdit = async (Id) => {
    let body = {
      category_id: Id,
    };
    startLoading();
    try {
      let response = await categoryMasterAPI.getCategoryData(body);
      let responseData = response.data;
      if (responseData.status === 200) {
       
        setIsCategory(responseData.data[0]);
        let sub_data = responseData.data[0]?.subject;
        let list = sub_data.map((s) => ({
          subject_id: s.fk_subject,
          subject_name: s.fk_subject__subject_name,
          sequence_no: s.sequence_no,
        }));
        setSubjectList(list);
        setIsEdit(true);
        setIsDelete(false);
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  const onDelete = (id) => {
    let delete_id = {
      exam_categoryId: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await categoryMasterAPI.deleteCategory(delete_id);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });
          fetchCategoryList();
          handleClear();
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };
  if (isLoading) return <Loader />;
  return (
    <div className="section-body">
      <div className="container-fluid">
        <form onSubmit={handleSubmit}>
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Category Master</h1>
            </div>
            <div className=" mt-2">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mx-1"
                onClick={() => handleClear()}
              >
                <i className="fa fa-pencil px-1"></i>
                New
              </button>
              <button className="btn btn-primary btn-sm mx-1" type="submit">
                <i className="fa fa-plus px-1"></i>
                {isEdit ? "Update" : "Save"}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm mx-1"
                onClick={() => onDelete(isCategory?.id)}
                disabled={isDelete}
              >
                <i className="fa fa-trash px-1"></i>
                Delete
              </button>
              <CategoryList data={categoryList} fetchForEdit={fetchForEdit} />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="card">
              <div className="card-body row">
                <div className="col-lg-7 col-md-12">
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Category Name<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Category Name"
                        name="category_name"
                        value={formik.values.category_name}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        autoComplete="off"
                      />
                      {formik.touched.category_name &&
                      formik.errors.category_name ? (
                        <ErrorDisplay errorMsg={formik.errors.category_name} />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Group Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="group_id"
                        placeholder="Select Group Name"
                        options={groupList}
                        value={
                          formik.values.group_id
                            ? groupList?.find(
                                (g) => g.value === formik.values.group_id
                              )
                            : null
                        }
                        onChange={(selectedOptions) =>
                          formik.setFieldValue(
                            "group_id",
                            selectedOptions?.value || ""
                          )
                        }
                        onBlur={formik.handleBlur}
                        isSearchable={true}
                      />
                      {formik.touched.group_id && formik.errors.group_id ? (
                        <ErrorDisplay errorMsg={formik.errors.group_id} />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Marking <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="marking"
                        placeholder="Select Marking"
                        options={markingOption}
                        value={
                          formik.values.marking
                            ? markingOption?.find(
                                (g) => g.value === formik.values.marking
                              )
                            : null
                        }
                        onChange={(selectedOptions) =>
                          formik.setFieldValue(
                            "marking",
                            selectedOptions?.value || ""
                          )
                        }
                        onBlur={formik.handleBlur}
                        isSearchable={true}
                      />
                      {formik.touched.marking && formik.errors.marking ? (
                        <ErrorDisplay errorMsg={formik.errors.marking} />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-md-12"></div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Add Subject</h3>
              </div>
              <div className="card-body row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group col-md-12 row">
                    <label className="col-md-4 col-form-label">
                      Subject <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-8">
                      <Select
                        name="subject_id"
                        placeholder="Subject"
                        options={SubjectFilterOption}
                        value={
                          subject.subject_id
                            ? SubjectFilterOption.find(
                                (option) => option.value === subject.subject_id
                              )
                            : null
                        }
                        onChange={(selectedOption) =>
                          handleChange({
                            target: {
                              name: "subject_id",
                              value: selectedOption.value,
                            },
                          })
                        }
                        isSearchable={true}
                      />

                      {validationError && !subject.subject_id ? (
                        <ErrorDisplay errorMsg={"Subject name is required"} />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-md-12 row">
                    <label className="col-md-4 col-form-label">
                      Sequence No. <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-8">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Sequence No"
                        name="sequence_no"
                        value={subject.sequence_no}
                        onChange={(e) => {
                          const inputText = e.target.value;
                          if (/^\d{0,5}$/.test(inputText)) {
                            handleChange(e);
                          }
                        }}
                        autoComplete="off"
                      />
                      {validationError && !subject.sequence_no ? (
                        <ErrorDisplay
                          errorMsg={"Sequence number is required"}
                        />
                      ) : null}
                    </div>
                  </div>
                  <div className="form-group col-md-12 text-right">
                    <button
                      className="btn btn-primary btn-sm mx-1"
                      type="button"
                      onClick={handleAddSubject}
                    >
                      <i className="fa fa-plus px-1"></i>
                      Add
                    </button>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="table-responsive">
                    <table className="table table-hover table-sm ">
                      <thead>
                        <tr className="text-center">
                          <th>Sequence No</th>
                          <th>Subject Name</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {subjectList && subjectList.length > 0 ? (
                          subjectList?.map((subject, index) => (
                            <tr  key={index}>
                              <td>{subject.sequence_no}</td>
                              <td className="text-left">
                                {
                                  SubjectOption?.find(
                                    (op) => op.value === subject.subject_id
                                  )?.label
                                }
                              </td>
                              <td className="text-center">
                                <NavLink
                                  to="#"
                                  className="card-options-remove"
                                  data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                  onClick={() =>
                                    handleDeleteSubject(subject.sequence_no)
                                  }
                                >
                                  <i className="fa fa-trash-o text-danger"></i>
                                </NavLink>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              <h3 className="no-list-message mt-5 ">
                                Subject Not Available !
                              </h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default CategoryMaster;
