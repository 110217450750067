import {
  ADD_CHAPTER_FAILURE,
  ADD_CHAPTER_REQUEST,
  ADD_CHAPTER_SUCCESS,
  ADD_CONTENT_FAILURE,
  ADD_CONTENT_REQUEST,
  ADD_CONTENT_SUCCESS,
  DELETE_CONTENT_FAILURE,
  DELETE_CONTENT_REQUEST,
  DELETE_CONTENT_SUCCESS,
  GET_CHAPTER_FAILURE,
  GET_CHAPTER_REQUEST,
  GET_CHAPTER_SUCCESS,
  GET_CLASS_WISE_SUBJECT_FAILURE,
  GET_CLASS_WISE_SUBJECT_REQUEST,
  GET_CLASS_WISE_SUBJECT_SUCCESS,
  GET_CONTENT_FAILURE,
  GET_CONTENT_REQUEST,
  GET_CONTENT_SUCCESS,
  UPDATE_CONTENT_FAILURE,
  UPDATE_CONTENT_REQUEST,
  UPDATE_CONTENT_SUCCESS,
} from "../../Constants/school_user/contentUploader";

let initialState = {
  loading: false,
  error: null,
  content: null,
  subject: null,
  chapter: null,
  contentData: null,
};

export const contentUploaderReducer = (state = initialState, action) => {
  switch (action.type) {
    case "RESET_STATE":
      return initialState;
    case GET_CLASS_WISE_SUBJECT_REQUEST:
    case GET_CHAPTER_REQUEST:
    case ADD_CHAPTER_REQUEST:
    case ADD_CONTENT_REQUEST:
    case GET_CONTENT_REQUEST:
    case DELETE_CONTENT_REQUEST:
    case UPDATE_CONTENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case GET_CLASS_WISE_SUBJECT_SUCCESS:
      return {
        ...state,
        loading: false,
        subject: action.payload,
      };
    case GET_CHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
        chapter: action.payload,
      };
    case ADD_CHAPTER_SUCCESS:
      return {
        ...state,
        loading: false,
        chapter: action.payload,
      };
    case ADD_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        content: action.payload,
      };
    case DELETE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        content: action.payload,
      };
    case UPDATE_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        content: action.payload,
      };
    case GET_CONTENT_SUCCESS:
      return {
        ...state,
        loading: false,
        contentData: action.payload,
      };
    case GET_CLASS_WISE_SUBJECT_FAILURE:
    case GET_CHAPTER_FAILURE:
    case ADD_CHAPTER_FAILURE:
    case ADD_CONTENT_FAILURE:
    case GET_CONTENT_FAILURE:
    case DELETE_CONTENT_FAILURE:
    case UPDATE_CONTENT_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };

    default:
      return state;
  }
};
