import React, { useEffect, useState } from "react";
import Select from "react-select";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useSessionRedirect from "../../Comman/SessionChange";
import useLoading from "../../../CommonComponent/useLoading";
import { useDispatch, useSelector } from "react-redux";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import { getMasterData } from "../../../../actions/super-user/MasterData";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import { RoomTable } from "./Floor";
import Swal from "sweetalert2";
import Loader from "../../../Loader/Loader";
import ValidationComponent from "../../Comman/Validation";
const HostelDetails = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { masterData } = useSelector((state) => state.masterData);
  const dispatch = useDispatch();
  const [hostelData, setHostelData] = useState([]);
  const [roomList, setRoomList] = useState([]);
  const [hostelId, setHostelId] = useState("");
  const [roomData, setRoomData] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token));
    }
  }, [dispatch, token]);
  let bedTypeList = masterData?.payload
    ?.filter((obj) => obj.name === "Bed Type")
    .map((n) => ({
      value: n.id,
      label: n.value,
    }));

  let hostelList = hostelData?.map((hostel) => ({
    value: hostel.id,
    label: hostel.hostelName,
  }));

  const fetchData = async () => {
    startLoading();
    try {
      let data = {
        school_id: school_id,
      };
      const response = await HostelAPI.getHostelList(data);
      let responseData = response.data;
      setHostelData(responseData.hostelList);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  useEffect(() => {
    let fetchRoom = async () => {
      startLoading();
      try {
        const data = {
          hostelId: hostelId,
        };
        let response = await HostelAPI.getHostelRoomDetails(data);
        let responseData = response.data;
        let oriData = responseData.roomList;
        let data1 = [];
        oriData.forEach((floorData) => {
          Object.entries(floorData).forEach(([roomKey, beds]) => {
            beds.forEach((bed) => {
              data1.push({
                roomId: bed.id,
                floorNo: bed.floorNo,
                roomNo: bed.roomNo,
                bedNo: bed.bedNo,
                bedTypeId: bed.bedTypeId,
              });
            });
          });
        });
        setRoomData(data1);
      } catch (error) {
        console.error(error);
      } finally {
        stopLoading();
      }
    };
    if (hostelId) {
      fetchRoom();
    }
  }, [hostelId]);

  let initial = {
    roomId: "",
    floorNo: "",
    roomNo: "",
    bedNo: "",
    bedTypeId: "",
  };
  const [hostelDetail, setHostelDetail] = useState(initial);
  const [roomEdit, setRoomEdit] = useState([]);
;

  const {
    validationError: detailValidationError,
    validateControl: validateDetailControl,
  } = ValidationComponent({
    data: hostelDetail,
    requiredFields: ["floorNo", "roomNo", "bedNo", "bedTypeId"],
  });
  const {
    validationError: idValidationError,
    validateControl: validateIdControl,
  } = ValidationComponent({
    data: { hostelId },
    requiredFields: ["hostelId"],
  });
  const handleChange = (fieldName, selectValue) => {
    setHostelDetail((prev) => ({ ...prev, [fieldName]: selectValue }));
  };
  const handleAddDetails = async () => {
    const isValid = await validateDetailControl();
    if (isValid) {
      if (isEdit) {
        const existIndex = roomData.findIndex(
          (bed) =>
            bed.floorNo === roomEdit.floorNo &&
            bed.roomNo === roomEdit.roomNo &&
            bed.bedNo === roomEdit.bedNo
        );
        if (existIndex !== -1) {
          const exists = roomData?.some(
            (bed, index) =>
              index !== existIndex &&
              bed.floorNo === hostelDetail.floorNo &&
              bed.roomNo === hostelDetail.roomNo &&
              bed.bedNo === hostelDetail.bedNo
          );
          if (exists) {
            Swal.fire({
              title: "",
              text: "Bed number already exists in the same floor and room.",
              icon: "info",
              allowOutsideClick: false,
            });
            return;
          }
          const updateRoomData = [...roomData];
          updateRoomData[existIndex] = hostelDetail;
          setRoomData(updateRoomData);
          setHostelDetail(initial);
          setIsEdit(false);
          setRoomEdit([]);
        }
      } else {
        const exists = roomData?.some(
          (bed) =>
            bed.floorNo === hostelDetail.floorNo &&
            bed.roomNo === hostelDetail.roomNo &&
            bed.bedNo === hostelDetail.bedNo
        );
        if (exists) {
          Swal.fire({
            title: "",
            text: "Bed number already exists in the same floor and room.",
            icon: "info",
            allowOutsideClick: false,
          });
          return;
        }
        setRoomData((prevList) => [...prevList, hostelDetail]);
        setHostelDetail(initial);
        setIsEdit(false);
      }
    }
  };
  const handeleDeleteRoom = (toRemove) => {
    const filteredData = roomData.filter(
      (item) =>
        !(
          item.floorNo === toRemove.floorNo &&
          item.roomNo === toRemove.roomNo &&
          item.bedNo === toRemove.bedNo &&
          item.bedTypeId === toRemove.bedTypeId
        )
    );
    setRoomData(filteredData);
  };
  const handeleEditRoom = (edit) => {
    setHostelDetail(edit);
    setRoomEdit(edit);
    setIsEdit(true);
  };
  useEffect(() => {
    const roomList = [].sort((a, b) => (a.bedNo > b.bedNo ? 1 : -1));
    roomData?.forEach((item) => {
      const floorNo = item.floorNo;
      const roomNo = item.roomNo;
      const key = `Floor-${floorNo} Room No-${roomNo}`;
      const existName = roomList.find((entry) => Object.keys(entry)[0] === key);
      if (existName) {
        existName[key].push(item);
      } else {
        roomList.push({ [key]: [item] });
      }
    });
    setRoomList(roomList);
  }, [roomData]);

  const handleClear = () => {
    setHostelId("");
    setRoomData([]);
    setHostelDetail(initial);
    setIsEdit(false);
  };
  const sendHostelRoomDetailsToServer = async () => {
    const isValid = await validateIdControl();
    if (isValid) {
      startLoading();
      try {
        const data = {
          school_id: school_id,
          hostelId: hostelId,
          insert_by: school_emp_id,
          room_data: JSON.stringify(roomData),
        };
        const response = await HostelAPI.addHostelRoomDetails(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        stopLoading();
      }
    }
  };
  if (isLoading) return <Loader />;
  return (
    <div className="section-body">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="header-action">
            <h1 className="page-title">Hostel Details</h1>
          </div>
          <div className=" mt-2">
            <button
              className="btn btn-primary btn-sm mx-1"
              type="submit"
              onClick={sendHostelRoomDetailsToServer}
            >
              <i className="fa fa-plus px-1"></i>
              Save
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-danger mx-1"
              onClick={() => handleClear()}
            >
              <i className="fa fa-times px-1"></i>
              Clear
            </button>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="form-group col-lg-6  row ">
                  <label className="col-lg-4 col-form-label ">
                    Select Hostel <span className="text-danger">*</span>
                  </label> 
                  <div className="col-lg-9">
                    <Select
                      name="hostelTypeId"
                      placeholder="Select Hostel"
                      options={hostelList}
                      value={
                        hostelId
                          ? hostelList?.find(
                              (option) => option.value === hostelId
                            )
                          : null
                      }
                      onChange={(selectedOptions) => {
                        setHostelId(selectedOptions.value);
                      }}
                      isSearchable={true}
                    />
                    {idValidationError && !hostelId ? (
                      <ErrorDisplay errorMsg={"Hostel is required !"} />
                    ) : null}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="form-group col-lg-2 col-md-4 ">
                  <label className=" col-form-label">
                    Floor No <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Floor No"
                      name="floorNo"
                      value={hostelDetail.floorNo}
                      onChange={(e) => handleChange("floorNo", e.target.value)}
                      autoComplete="off"
                    />
                    {detailValidationError && !hostelDetail.floorNo ? (
                      <ErrorDisplay errorMsg={"Floor no is required !"} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-lg-2 col-md-4 ">
                  <label className="col-form-label">
                    Room No <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Room No"
                      name="roomNo"
                      value={hostelDetail.roomNo}
                      onChange={(e) => handleChange("roomNo", e.target.value)}
                      autoComplete="off"
                    />
                    {detailValidationError && !hostelDetail.roomNo ? (
                      <ErrorDisplay errorMsg={"Room no is required !"} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-lg-2 col-md-4">
                  <label className="col-form-label">
                    Bed No <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Bed No"
                      name="bedNo"
                      value={hostelDetail.bedNo}
                      onChange={(e) => handleChange("bedNo", e.target.value)}
                      autoComplete="off"
                    />
                    {detailValidationError && !hostelDetail.bedNo ? (
                      <ErrorDisplay errorMsg={"Bed no is required !"} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-lg-3 col-md-6">
                  <label className="col-form-label">
                    Bed Type <span className="text-danger">*</span>
                  </label>
                  <div className="">
                    <Select
                      name="bedTypeId"
                      placeholder="Bed Type"
                      options={bedTypeList}
                      value={
                        hostelDetail.bedTypeId
                          ? bedTypeList?.find(
                              (option) =>
                                option.value === hostelDetail.bedTypeId
                            )
                          : null
                      }
                      onChange={(selectedOptions) => {
                        handleChange("bedTypeId", selectedOptions.value);
                      }}
                      isSearchable={true}
                    />
                    {detailValidationError && !hostelDetail.bedTypeId ? (
                      <ErrorDisplay errorMsg={"Bed Type no is required !"} />
                    ) : null}
                  </div>
                </div>{" "}
                <div className="form-group col-lg-3 col-md-6 d-flex align-items-end">
                  <button
                    className="btn btn-primary mx-1"
                    type="button"
                    onClick={handleAddDetails}
                  >
                    <i className="fa fa-plus px-1"></i>
                    {isEdit ? "Update" : "Add"}
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="card col-lg-12 col-md-12 col-sm-12">
            <div className="card-body">
              <RoomTable
                data={roomList}
                handeleDeleteRoom={handeleDeleteRoom}
                handeleEditRoom={handeleEditRoom}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostelDetails;
