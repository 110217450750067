import axios from "axios";
import { BASE_URL_ROUTER,BASE_URL } from "../../Config/BaseUrl";
import Swal from "sweetalert2";
import {
  CREATE_CLASS_SUBJECT_FAILURE,
  CREATE_CLASS_SUBJECT_REQUEST,
  CREATE_CLASS_SUBJECT_SUCCESS,
  CREATE_SUBJECT_FAILURE,
  CREATE_SUBJECT_REQUEST,
  CREATE_SUBJECT_SUCCESS,
  DELETE_SUBJECT_FAILURE,
  DELETE_SUBJECT_REQUEST,
  DELETE_SUBJECT_SUCCESS,
  GET_CLASS_SUBJECT_FAILURE,
  GET_CLASS_SUBJECT_REQUEST,
  GET_CLASS_SUBJECT_SUCCESS,
  GET_SUBJECT_FAILURE,
  GET_SUBJECT_REQUEST,
  GET_SUBJECT_SUCCESS,
  UPDATE_SUBJECT_FAILURE,
  UPDATE_SUBJECT_REQUEST,
  UPDATE_SUBJECT_SUCCESS,
} from "../../Constants/school_user/SubjectMaster";

export const createSubject = (token, subject,resetForm) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_SUBJECT_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL_ROUTER}subject_master_api/`,
        subject,
        config
      );
      const data = response.data;
      dispatch({ type: CREATE_SUBJECT_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",
        }).then(()=>{resetForm()}).catch((err)=> console.log(err))
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
        })
      }
    } catch (error) {
      dispatch({
        type: CREATE_SUBJECT_FAILURE,
        payload: error,
      });
    }
  };
};
export const updateSubject = (token,ID, subject) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_SUBJECT_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.patch(
        `${BASE_URL_ROUTER}subject_master_api/${ID}/`,
        subject,
        config
      );
      const data = response.data;
      dispatch({ type: UPDATE_SUBJECT_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",
        })
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
        })
      }
    } catch (error) {
      dispatch({
        type: UPDATE_SUBJECT_FAILURE,
        payload: error,
      });
    }
  };
};

export const getSubjectList = (token, s_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBJECT_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${BASE_URL_ROUTER}subject_master_api/?fk_school=${s_id}`,

        config
      );
      const data = response.data;
      dispatch({ type: GET_SUBJECT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_SUBJECT_FAILURE,
        payload: error,
      });
    }
  };
};

export const deleteSubject = (token, ID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_SUBJECT_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.delete(
        `${BASE_URL_ROUTER}subject_master_api/${ID}/`,

        config
      );
      const data = response.data;
      dispatch({ type: DELETE_SUBJECT_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",
        })
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
        })
      }
    } catch (error) {
      dispatch({
        type: DELETE_SUBJECT_FAILURE,
        payload: error,
      });
    }
  };
};


export const createClassSubject = (token, classSubject) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_CLASS_SUBJECT_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}add_update_class_and_subject_teacher/`,
        classSubject,
        config
      );
      const data = response.data;
      dispatch({ type: CREATE_CLASS_SUBJECT_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",
        })
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
        })
      }
      return data
    } catch (error) {
      dispatch({
        type: CREATE_CLASS_SUBJECT_FAILURE,
        payload: error,
      });
    }
  };
};

export const getClassSubjectList = (token, classSubject) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CLASS_SUBJECT_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_class_and_subject_teacher/`,
        classSubject,
        config
      );
      const data = response.data;
      dispatch({ type: GET_CLASS_SUBJECT_SUCCESS, payload: data });
      
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
        })
      }
      return data
    } catch (error) {
      dispatch({
        type: GET_CLASS_SUBJECT_FAILURE,
        payload: error,
      });
    }
  };
};