import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

export const CircularAPI = {
  addCircular: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}add_circular/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getCircular: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_circular/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getOneCircular: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_circular_data/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  deleteCircular: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}delete_circular/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
};
