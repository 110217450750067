import React, { useEffect, useState } from "react";
import Select from "react-select";
import LeaveMasterModel from "./LeaveMaster/LeaveMasterModel";
import { LeaveOpeningAPI } from "../../../api/Payroll/Leaveopening";
import useLoading from "../../CommonComponent/useLoading";
import Loader from "../../Loader/Loader";
import useLocalStorageData from "../Comman/LocalStorageData";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Heading } from "../../CommonComponent/heading";

const LeaveMaster = () => {
  const [leaveOption, setLeaveOptions] = useState({});
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [newLeave, setNewLeave] = useState({});
  const [newLeaveAdded, setNewLeaveAdded] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [leaveData, setLeaveData] = useState([]);
  const { isLoading, startLoading, stopLoading } = useLoading();

  console.log(school_id);
  const getLeaveoption = async () => {
    let data = {
      school_id: school_id,
    };
    try {
      const response = await LeaveOpeningAPI.getLeaveType(data);
      let responseData = response.data;
      if (responseData.status === 200) {
        // setLeaveData(responseData?.leave_opening)

        const options = responseData.arr_levae_type.map((item) => {
          const key = Object.keys(item)[0]; // Get the key of the object
          const value = item[key]; // Get the value corresponding to the key
          return { value: key, label: value };
        });
        setLeaveOptions(options);
      }
    } catch (err) {
      console.error(err);
    } finally {
    }
  };
  console.log(leaveOption);

  const getLeaveData = async () => {
    console.log("Fetching leave data...");
    let data = {
      school_id: school_id,
    };
    const response = await LeaveOpeningAPI.getLeaveOpeningApi(data);
    let responseData = response.data;
    if (responseData.status === 200) {
      setLeaveData(responseData?.leave_opening);
    }
  };
  useEffect(() => {
    getLeaveData();
    getLeaveoption();
  }, []);

  const handelClear = () => {
    formik.resetForm();
    setLeaveDays(0);
    setSelectedOption(null);
  };

  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [leaveDays, setLeaveDays] = useState(0);

  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
    formik.setFieldValue("from_date", event.target.value);
  };

  const handleToDateChange = (e) => {
    const { value } = e.target;

    const fromDateValue = new Date(formik.values.from_date);
    const toDateValue = new Date(value);

    // Check if the selected "To Date" is before the "From Date"
    if (toDateValue < fromDateValue) {
      formik.setFieldError("to_date", "To Date must be after From Date");
    } else {
      // Clear the error if the validation passes
      formik.setFieldError("to_date", "");
      formik.setFieldValue("to_date", value);
      setToDate(value);
    }
  };

  useEffect(() => {
    if (fromDate && toDate) {
      calculateLeaveDays();
    }
  }, [fromDate, toDate]);

  const calculateLeaveDays = () => {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const start = new Date(fromDate);
    const end = new Date(toDate);

    // Calculate the number of days between start and end
    const totalDays = Math.round(Math.abs((start - end) / oneDay)) + 1;

    // Count the number of Fridays and Mondays within the leave period
    let leaveDays = totalDays;
    for (
      let date = new Date(start);
      date <= end;
      date.setDate(date.getDate() + 1)
    ) {
      const day = date.getDay();
      if (day === 1 || day === 5) {
        leaveDays--;
      }
    }

    setLeaveDays(leaveDays);
    // formik.setFieldValue("no_of_leaves", leaveDays);
  };

  let handelLeaveChange = (select) => {
    setSelectedOption(select.value);
    formik.setFieldValue("leave_type", select.value);
  };

  const validationSchema = Yup.object().shape({
    leave_type: Yup.string().required("Leave type is required"),
    from_date: Yup.string().required("From date is required"),
    to_date: Yup.string().required("To date is required"),
    no_of_leaves: Yup.number()
    .required("Number of leaves is required")
    .min(1, "Number of leaves must be greater than 0"),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: school_id,
      insert_by: school_emp_id,
      leave_type: "",
      from_date: "",
      to_date: "",
      no_of_leaves: "",
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      let data = {
        ...values,
        leave_type: selectedOption ? selectedOption : values.leave_type,
      };
      startLoading();
      try {
        const response = await LeaveOpeningAPI.saveLeaveOpeningApi(data);
        const responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
          });
          handelClear();
          getLeaveData();
        }
        else{
          Swal.fire({
            text: responseData.msg,
            icon: "error",
          });
        }
      } catch (err) {
      } finally {
        stopLoading();
      }
    },
  });

  const handleDeleteClick = (ids) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          leave_id: ids,
          school_id:school_id
        };
        LeaveOpeningAPI.deleteLeaveOpening(data)
          .then((response) => {
            const responseData = response.data;
            if (responseData.status === 200) {
              Swal.fire({
                text: responseData.msg,
                icon: "success",
              });
              getLeaveData();
            } else {
              Swal.fire({
                text: "Failed to delete item.",
                icon: "error",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              text: "An error occurred while processing your request.",
              icon: "error",
            });
          });
      }
    });
  };
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="container-fluid">
          <Heading pageTitle={"Leave Master"}>
            <div className="d-flex">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mx-1"
                onClick={() => handelClear()}
              >
                <i className="fa fa-pencil px-1"></i>
                New
              </button>
              <button className="btn btn-primary btn-sm mx-1" type="submit">
                <i className="fa fa-plus px-1"></i>
                Save
              </button>
            </div>
          </Heading>

          <div className="container">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 row form-group ">
                  <div className="col-lg-2 ">
                    <label className="col-form-label ">Leave Type</label>
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-6">
                    <Select
                      options={leaveOption}
                      value={
                        selectedOption
                          ? leaveOption.find((c) => c.value === selectedOption)
                          : null
                      }
                      onChange={handelLeaveChange}
                    />
                    {formik.touched.leave_type && formik.errors.leave_type && (
                      <div className="text-danger">
                        {formik.errors.leave_type}
                      </div>
                    )}
                  </div>
                  {/* <LeaveMasterModel
                    school_id={school_id}
                    onSave={getLeaveoption}
                  /> */}
                </div>
                <div className="form-group row col-lg-8">
                <div className="col-lg-3 ">
                  <label className="col-form-label">From Date</label>
                  <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-3">
                    <input
                      type="date"
                      className="form-control"
                      name="from_date"
                      value={formik.values.from_date}
                      onChange={handleFromDateChange}
                    />
                    {formik.touched.from_date && formik.errors.from_date && (
                      <div className="text-danger">
                        {formik.errors.from_date}
                      </div>
                    )}
                  </div>
                  <div className="col-lg-3 ">
                  <label className=" col-form-label">To Date</label>
                  <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-3">
                    <input
                      type="date"
                      className="form-control"
                      name="to_date"
                      value={formik.values.to_date}
                      onChange={handleToDateChange}
                    />
                    {formik.touched.to_date && formik.errors.to_date && (
                      <div className="text-danger">{formik.errors.to_date}</div>
                    )}
                  </div>
                </div>
                <div className="col-12 row mt-3">
                  <div className="col-lg-2">
                    <label className="col-form-label ">Number Of leaves</label>
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-6">
                    <input
                      className="form-control"
                      name="no_of_leaves"
                      value={formik.values.no_of_leaves}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.no_of_leaves &&
                      formik.errors.no_of_leaves && (
                        <div className="text-danger">
                          {formik.errors.no_of_leaves}
                        </div>
                      )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      <div className="section-body">
        <div className="card">
          <div class="card-header">
            <h3 class="card-title">Head Table</h3>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
                <thead>
                  <tr className="text-center">
                    <th>Sr No</th>
                    <th>leave type</th>
                    <th>from date</th>
                    <th>To Date</th>
                    <th>no of leaves</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {leaveData.map((head, index) => (
                    <tr key={head.id}>
                      <td>{index + 1}</td>
                      <td>{head.leave_type}</td>
                      <td>{head.from_date}</td>
                      <td>{head.to_date}</td>
                      <td>{head.no_of_leaves}</td>
                      <td className="text-center">
                        <i
                          class="fa fa-trash-o text-danger mx-1"
                          onClick={() => handleDeleteClick(head.id)}
                        >
                          {" "}
                        </i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeaveMaster;
