export const GET_MODULE_REQUIST='GET_MODULE_REQUIST'
export const GET_MODULE_SUCCESS="GET_MODULE_SUCCESS"
export const GET_MODULE_FAILD="GET_MODULE_FAILD"




export const GET_MASTERDATA_REQUIST='GET_MASTERDATA_REQUIST'
export const GET_MASTERDATA_SUCCESS="GET_MASTERDATA_SUCCESS"
export const GET_MASTERDATA_FAILD="GET_MASTERDATA_FAILD"

export const CREATE_MASTERDATA_REQUIST='CREATE_MASTERDATA_REQUIST'
export const CREATE_MASTERDATA_SUCCESS="CREATE_MASTERDATA_SUCCESS"
export const CREATE_MASTERDATA_FAILD="CREATE_MASTERDATA_FAILD"


export const DELETE_MASTERDATA_REQUIST='DELETE_MASTERDATA_REQUIST'
export const DELETE_MASTERDATA_SUCCESS="DELETE_MASTERDATA_SUCCESS"
export const DELETE_MASTERDATA_FAILD="DELETE_MASTERDATA_FAILD"

export const UPDATE_MASTERDATA_REQUIST='UPDATE_MASTERDATA_REQUIST'
export const UPDATE_MASTERDATA_SUCCESS="UPDATE_MASTERDATA_SUCCESS"
export const UPDATE_MASTERDATA_FAILD="UPDATE_MASTERDATA_FAILD"