import axios from 'axios';
import {
  FETCH_SCHOOL_LOGO_REQUEST,
  FETCH_SCHOOL_LOGO_SUCCESS,
  FETCH_SCHOOL_LOGO_FAILURE,}
  from '../SchoolLogo/SchoolLogoActionTypes';


import { BASE_URL } from '../../Config/BaseUrl';

export const fetchSchoolLogo = (schoolId, token) => async (dispatch) => {
    try {
      dispatch({ type: FETCH_SCHOOL_LOGO_REQUEST });
      const requestBody = {
        school_id: schoolId,   
      };
      const headers = {
        Authorization: `Bearer ${token}`,
      };
      const response = await axios.post(`${BASE_URL}get_School_image/`, requestBody, { headers });
      const data = response.data?.base64_image;
  
      dispatch({ type: FETCH_SCHOOL_LOGO_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({ type: FETCH_SCHOOL_LOGO_FAILURE, payload: error });
      throw error;
    }
  };



  