import React, { useEffect, useRef, useState } from "react";
import { read, utils, writeFile } from "xlsx";
import { useDispatch, useSelector } from "react-redux";
import {
  bulkStudentUpload,
  getSection,
} from "../../../actions/Admission/StudentReport";
import Select from "react-select";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
import Loader from "../../Loader/Loader";
import useSessionRedirect from "../Comman/SessionChange";
import { MEDIA_BASE_URL } from "../../../Config/BaseUrl";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";

const BulkUploadData = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  const {
    school_name,
    organization_name,
    school_address,
    school_contact_email,
    school_contact_number,
    school_pincode,
    udise_no,
    school_logo,
    school_emp_id,
  } = user_detail;

  const session_id = useSessionRedirect();

  const alert = useAlert();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);

  const [Loading, setLoading] = useState(false);
  const [Class, setClass] = useState("");
  const [Section, setSection] = useState("");
  const { allClass } = useSelector((state) => state.getClass);
  const { allSection } = useSelector((state) => state.getSection);
  const { bulkStudent } = useSelector((state) => state.bulkStudentUpload);

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

  const sectionList = allSection?.payload?.map((list) => {
    return {
      label: list.section,
      value: list.id,
    };
  });

  useEffect(() => {
    Class?.value &&
      dispatch(
        getSection({
          class_id: Number(Class?.value),
        })
      );
  }, [dispatch, Class]);

  const [StuData, setStuData] = useState([]);
  console.log(StuData,"StuData")
  const [file, setFile] = useState("");

  // const handleImport = ($event) => {
  //   const files = $event.target.files;
  //   if (files.length) {
  //     const file = files[0];
  //     setFile(file);
  //     const reader = new FileReader();
  //     reader.onload = (event) => {
  //       const wb = read(event.target.result);
  //       const sheets = wb.SheetNames;

  //       if (sheets.length) {
  //         const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
  //         setStuData(rows);
  //       }
  //     };
  //     reader.readAsArrayBuffer(file);
  //   }
  // };



  const handleImport = ($event) => {
    const files = $event.target.files;
    if (files.length) {
      const file = files[0];
      setFile(file);
  
      const reader = new FileReader();
      reader.onload = (event) => {
        const wb = read(event.target.result);
        const sheets = wb.SheetNames;
  
        if (sheets.length) {
          const rows = utils.sheet_to_json(wb.Sheets[sheets[0]]);
          
          if (rows.length > 0) {
            // Proceed with setting the data if the file is not empty
            setStuData(rows);
          } else {
            // Display a message or handle the case where the file is empty
            alert.info("The uploaded file is empty. Please choose a file with data.");
          }
        } else {
          // Display a message or handle the case where the file has no sheets
          alert.info("The uploaded file does not contain any sheets.");
        }
      };
      reader.readAsArrayBuffer(file);
    } else {
      // Handle the case where no file is selected
      alert.info("Please choose a valid file.");
    }
  };

  const handleExport = () => {
    const headings = [
      [
        "Sr_No",
        "Student_name",
        "Student_code",
        "DOB",
        "Gender",
        "Adhar_no",
        "Application_no",
        "Mother_tongue",
        "Registration_date",
        "Student_address",
        "Student_contact_no",
        "Student_email_id",
        "Birth_place",
        "Blood_group",
        "Father_name",
        "f_res_address",
        "f_office_address",
        "f_contact_no",
        "f_email",
        "f_pan_no",
        "f_annual_income",
        "f_adhar_no",
        "mother_name",
        "m_res_address",
        "m_office_address",
        "m_contact",
        "m_email",
        "m_pan_no",
        "m_annual_income",
        "m_aadhar_no",
        "Admission_date",
        "Concession",
      ],
    ];
    const wb = utils.book_new();
    const ws = utils.json_to_sheet([]);
    utils.sheet_add_aoa(ws, headings);
    utils.sheet_add_json(ws, StuData, { origin: "A2", skipHeader: true });
    utils.book_append_sheet(wb, ws, "Report");
    writeFile(wb, "Student Report.xlsx");
  };

  const formData = new FormData();
  const BulkUpload = () => {
    const body = {
      academic_id: session_id,
      school_id: Number(user_detail.fk_school_id),
      class_id: Number(Class?.value),
      section_id: Number(Section?.value),
      bulk_student_data: file,
      // bulk_student_data: StuData,
      insert_by: school_emp_id,
    };
    if (!Class) {
      alert.info("Please Select Class");
    } else if (!Section) {
      alert.info("Please Select Section");
    } else if (!file) {
      alert.info("Please Choose File");
    } else {
      setLoading(true);
      formData.append("academic_id", body.academic_id);
      formData.append("school_id", body.school_id);
      formData.append("class_id", body.class_id);
      formData.append("section_id", body.section_id);
      formData.append("bulk_student_data", body.bulk_student_data);
      dispatch(bulkStudentUpload(formData, handleClear, setLoading));
    }
  };

  function handleClear() {
    setStuData([]);
    setClass("");
    setSection("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  }
  const excelSerialToDate = (serial) => {
    const utcDays = Math.floor(serial - 25569);
    const utcValue = utcDays * 86400;
    const dateInfo = new Date(utcValue * 1000);
  
    const year = dateInfo.getUTCFullYear();
    const month = dateInfo.getUTCMonth() + 1;
    const day = dateInfo.getUTCDate();
  
    return `${day < 10 ? '0' : ''}${day}-${month < 10 ? '0' : ''}${month}- ${year}    `;
  };
  if (Loading) { 
    return <Loader />;
  }
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="header-action">
              <h1 className="page-title">Bulk Student Upload</h1>
            </div>
          </div>
        </div>
      </div>
      <div className="container-fluid">
        <div className="col-md-12">
          <div className="row mt-4">
            <div className="col-lg-5 col-md-12">
              <div className="row">
                <div className="col-lg-6 col-md-4 col-sm-6 my-1">
                  {/* <div className="input-group">
                        <label className="col-form-label mx-2">Class</label>
                      </div> */}
                  <Select
                    options={classList}
                    placeholder="Select Class"
                    value={Class}
                    onChange={(e) => setClass(e)}
                    isSearchable={true}
                  />
                </div>
                <div className="col-lg-6 col-md-4 col-sm-6 my-1">
                  {/* <div className="input-group">
                        <label className="col-form-label mx-2">Section</label> </div> */}
                  <Select
                    options={sectionList}
                    placeholder="Select Section"
                    value={Section}
                    onChange={(e) => setSection(e)}
                    isSearchable={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-1">
              <div className="custom-file">
                <input
                  type="file"
                  name="bulk_student_data"
                  className="custom-file-input form-control" 
                  id="inputGroupFile"
                  ref={fileInputRef}
                  onChange={handleImport}
                  accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                  required
                />
                <label className="custom-file-label" htmlFor="inputGroupFile">
                  Choose file
                </label>
              </div>
            </div>
            <div className="col-lg-4 my-1">
            <button
          disabled={StuData.length === 0} // Disable the button if StuData is empty
          onClick={BulkUpload}
          className="btn btn-primary"
        >
          Upload <i className="fa fa-upload"></i>
        </button>
              <button
                type="button"
                className="btn btn-outline-danger mx-2"
                onClick={() => handleClear()}
              >
                Reset <i className="fa fa-refresh "></i>
              </button>
              <a
                href={`${MEDIA_BASE_URL}student_details.xlsx`}
                // onClick={handleExport}
                className="btn btn-primary"
              >
                Download <i className="fa fa-download"></i>
              </a>
            </div>
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="table-responsive card">
              <table className="table text-nowrap">
                <thead>
                  <tr className="text-nowrap">
                    <th scope="col">Sr_no</th>
                    <th scope="col">Child Id</th>
                    <th scope="col">Apaar Id</th>
                    <th scope="col">Student_name</th>
                    <th scope="col">Student_code</th>
                    <th scope="col">DOB</th>
                    <th scope="col">Gender</th>
                    <th scope="col">Aadhaar_no</th>
                    <th scope="col">Application_no</th>
                    <th scope="col">Mother_tongue</th>
                    <th scope="col">Registration_date</th>
                    <th scope="col">Student_address</th>
                    <th scope="col">Student_contact_no</th>
                    <th scope="col">Student_email_id</th>
                    <th scope="col">Birth_place</th>
                    <th scope="col">Blood_group</th>
                    <th scope="col">Father_name</th>
                    <th scope="col">Father_address</th>
                    <th scope="col">Father_office_address</th>
                    <th scope="col">Father_contact_no</th>
                    <th scope="col">Father_email</th>
                    <th scope="col">Father_pan_no</th>
                    <th scope="col">Father_annual_income</th>
                    <th scope="col">Father_Aadhaar_no</th>
                    <th scope="col">Mother_name</th>
                    <th scope="col">Mother_address</th>
                    <th scope="col">Mother_office_address</th>
                    <th scope="col">Mother_contact_no</th>
                    <th scope="col">Mother_email</th>
                    <th scope="col">Mother_pan_no</th>
                    <th scope="col">Mother_annual_income</th>
                    <th scope="col">Mother_Aadhaar_no</th>
                    <th scope="col">Admission_date</th>
                    <th scope="col">Concession</th>
                  </tr>
                </thead>
                <tbody>
                  {StuData.length ? (
                    StuData.map((items, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>

                        <td>{items.Child_Id}</td>
                        <td>{items.Apar_Id}</td>
                        <td>{items.Student_name}</td>

                        <td>{items.Student_code}</td>

                        <td>{excelSerialToDate(items.DOB)}</td>

                        <td>{items.Gender}</td>

                        <td>{items.Adhar_no}</td>

                        <td>{items.Application_no}</td>

                        <td>{items.Mother_tongue}</td>

                        <td>{excelSerialToDate(items.Registration_date)}</td>

                        <td>{items.Student_address}</td>

                        <td>{items.Student_contact_no}</td>

                        <td>{items.Student_email_id}</td>

                        <td>{items.Birth_place}</td>

                        <td>{items.Blood_group}</td>

                        <td>{items.Father_name}</td>

                        <td>{items.f_res_address}</td>

                        <td>{items.f_office_address}</td>

                        <td>{items.f_contact_no}</td>

                        <td>{items.f_email}</td>

                        <td>{items.f_pan_no}</td>

                        <td>{items.f_annual_income}</td>

                        <td>{items.f_adhar_no}</td>

                        <td>{items.mother_name}</td>

                        <td>{items.m_res_address}</td>

                        <td>{items.m_office_address}</td>

                        <td>{items.m_contact}</td>

                        <td>{items.m_email}</td>

                        <td>{items.m_pan_no}</td>

                        <td>{items.m_annual_income}</td>

                        <td>{items.m_aadhar_no}</td>

                        <td>{items.Admission_date}</td>

                        <td>{items.Concession}</td>
                      </tr>
                    ))
                  ) : (
                    <EmptyListMessage
                      Table="table"
                      message={`Student's Data Not Found!`}
                      Span={10}
                    />
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default BulkUploadData;
