// reducer.js
const initialState = {
    activeTab: 'Staff-all', 
    active: 'Basic-info' , 
  };
  
  const tabReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_ACTIVE_TAB':
        return {
          ...state,
          activeTab: action.payload,
        };
      case 'SET_ACTIVE_TAB_SR':
        return {
          ...state,
          active: action.payload,
        };
      default:
        return state;
    }
  };
  
  export default tabReducer;
  