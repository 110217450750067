import React, { createContext, useState, useEffect, useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SessionContext } from "./sessionContext";
import {
  getClassSection,
  getStudentList,
} from "../actions/school_user/Attenadance";
import { resetState } from "../actions/ResetAction/ResetAction";
import { getClass } from "../actions/Admission/StudentReport";
import { getSchoolOrganization, getSchoolRegistrationList, getState } from "../actions/super-user/SchoolRegistration";
import { getMasterData } from "../actions/super-user/MasterData";
export const AttendanceContext = createContext();

const AttendanceProvider = (props) => {
  const token = localStorage.getItem("token");
  const { Session } = useContext(SessionContext);
  let session_id = Session ?? sessionStorage.getItem("SessionId");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { fk_school_id, school_emp_id, staff_id ,user_type_dashboard
  } =
    school_user?.user_detail || {};
  let school_id = school_user?.user_detail?.fk_school_id;
  const dispatch = useDispatch();
  const { classsectionList, studentdata } = useSelector(
    (state) => state.attendance
  );
  const { allClass } = useSelector((state) => state.getClass);

  const currentDate = new Date();
  const formattedDate = convertDate(currentDate.toISOString().slice(0, 10));
  const [studentList, setStudentList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedDate, setSelectedDate] = useState(formattedDate);
  const [classSelect, setClassSelect] = useState("");
  const [classList, setClassList] = useState([]);

  useEffect(() => {
    setClassList(classsectionList?.payload);
  }, [classsectionList]);
  let classListOption = classList?.map((c) => {
    return {
      label: `${c.class_name} ${c.section}`,
      value: c.id,
    };
  });
  const allClassList = allClass?.payload?.map(({ class_name, id }) => ({
    label: class_name,
    value: id,
  }));

  let attendanceDate = studentList?.attendence_date;

  function convertDate(data) {
    const dateString = data;
    const date = new Date(dateString);
    const formattedDate = date.toLocaleDateString("en-IN", {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
    return formattedDate;
  }
  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  let s_data = {
    school_id: school_id,
    acedamic_id: session_id,
    user_id: user_type_dashboard==="Teacher" ? staff_id : "",
  };
  useEffect(() => {
    if (token && school_id) {
      dispatch(getClassSection(token, s_data));
    }
  }, [dispatch, token, school_id, session_id,staff_id,user_type_dashboard]);

  useEffect(() => {
    if (token && school_id) {
      dispatch(getClass(s_data));
    }
  }, [dispatch, token, session_id, school_id,staff_id,user_type_dashboard]);

  useEffect(() => {
    setStudentList(studentdata);
  }, [studentdata]);

  let handleclassselect = (select) => {
    setIsLoading(true);
    setClassSelect(select.value);
  };
  const handleDateChange = (data) => {
    if (!classSelect) return;
    setIsLoading(true);
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    setSelectedDate(convertDate(formattedDate));
  };

  function attendanceListDate(data) {
    const date = new Date(data);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;
    return formattedDate;
  }
  var attendence_date = attendanceListDate(selectedDate);
  let data = {
    fk_academic: session_id,
    section_id: classSelect,
    school_id: school_id,
    staff_id: school_emp_id,
    attendance_date: attendence_date,
  };

  useEffect(() => {
    if (token && classSelect) {
      dispatch(getStudentList(token, data))
        .then(() => {
          setIsLoading(false);
        })
        .catch((err) => console.error(err));
    }
  }, [dispatch, token, classSelect, attendence_date, session_id]);
//  =======   global function  ======== 

  useEffect(() => {
    if (token) {
      dispatch(getSchoolOrganization(token));
      dispatch(getState(token));
    }
  }, [dispatch, token]);
  useEffect(() => {
    if (token) {
      dispatch(getSchoolRegistrationList(token));
    }
  }, [dispatch, token]);
  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token));
    }
  }, [dispatch, token]);
  return (
    <AttendanceContext.Provider
      value={{
        attendence_date,
        handleclassselect,
        setStudentList,
        convertDate,
        handleDateChange,
        setIsLoading,
        classListOption,
        studentList,
        attendanceDate,
        selectedDate,
        data,
        s_data,
        isLoading,
        session_id,
        classSelect,
        school_id,
        setClassSelect,
        school_emp_id,allClassList
      }}
    >
      {props.children}
    </AttendanceContext.Provider>
  );
};


export const useClass = () => {
  return useContext(AttendanceContext);
};

export default AttendanceProvider;
