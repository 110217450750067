import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import "react-circular-progressbar/dist/styles.css";
import { getClassWiseSubjectList } from "../../../../actions/school_user/contentUploader";

import { NavLink } from "react-router-dom";
import { FaFilePdf } from "react-icons/fa";
import { resetState } from "../../../../actions/ResetAction/ResetAction";
import useSessionRedirect from "../../Comman/SessionChange";
import { SyllabusAPI } from "../../../../api/TeacherDesk/Syllabus";
import { MEDIA_BASE_URL } from "../../../../Config/BaseUrl";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import { validateFile } from "../../../CommonComponent/FileValidationUtil";

const Syllabus = () => {
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  // console.log(session_id,"session_id")
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id } = school_user?.user_detail;
  const dispatch = useDispatch();
  const { subject, chapter, contentData } = useSelector(
    (state) => state.contentUploader
  );
  const { allClass } = useSelector((state) => state.getClass);
  const [selectClass, setSelectClass] = useState(null);
  const [syllabusData, setSyllabusData] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [formValues, setFormValues] = useState({
    academic_id: session_id,
    school_id: school_id,
    class_id: null,
    subject_id: null,
    syllabus: null,
    insert_by: school_emp_id,
  });
  const fileInputRef = useRef(null);
  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  let class_wise_subId = {
    academic_id: session_id,
    school_id: school_id,
    class_id: selectClass,
    employee_id: school_emp_id,
  };

  useEffect(() => {
    if (token && selectClass) {
      dispatch(getClassWiseSubjectList(token, class_wise_subId));
    }
  }, [dispatch, token, selectClass, session_id]);

  useEffect(() => {
    let List = subject?.payload?.map((c) => {
      return {
        label: `${c.subject_name}`,
        value: c.id,
      };
    });
    setSubjectList(List);
  }, [subject]);

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });


  const fetchSyllabus = async () => {
    let data = {
      academic_id: session_id,
      school_id: school_id,
      class_id: selectClass,
    };
    try {
      let response = await SyllabusAPI.getSyllabus(data);
      let responseData = response.data;
      if (responseData.status === 200) {
        setSyllabusData(responseData.syllabus);
      
      } 
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchSyllabus();


  }, [selectClass,session_id,school_id]);
  const handleClear = () => {
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }

    setFormValues((values) => ({
      ...values,
      class_id: null,
      subject_id: null,
      syllabus: null,
    }));
    setSelectClass(null);
  };
  const handleSelectChange = (fieldName, selectedValue) => {
    if (fieldName === "class_id") {
      setFormValues((values) => ({
        ...values,
        [fieldName]: selectedValue,
        subject_id: null,
      }));
    } else {
      setFormValues((values) => ({
        ...values,
        [fieldName]: selectedValue,
      }));
    }
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (validateFile(file, fileInputRef)) {
      setFormValues((values) => ({
        ...values,
        syllabus: file,
      }));
    }
  };
  const [validationError, setValidationError] = useState(false);

  const validateControl = async () => {
    let validationStatus = true;
    if (!formValues.class_id) {
      validationStatus = false;
    }
    if (!formValues.subject_id) {
      validationStatus = false;
    }
    if (!formValues.syllabus) {
      validationStatus = false;
    }
    if (!validationStatus) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 3000);
    }
    return validationStatus;
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    const isValid = await validateControl();

    if (isValid) {
      const data = new FormData();
      data.append("academic_id", formValues.academic_id);
      data.append("school_id", formValues.school_id);
      data.append("subject_id", formValues.subject_id);
      data.append("class_id", formValues.class_id);
      data.append("insert_by", formValues.insert_by);
      data.append("syllabus", formValues.syllabus);

      
      try {
        const response = await SyllabusAPI.addSyllabus(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          fetchSyllabus();
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const onDelete = (id) => {
    let syllabus_Id = {
      syllabus_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await SyllabusAPI.deleteSyllabus(syllabus_Id);
        let responseData = response.data;
        if (responseData.status === 200) {
          fetchSyllabus();
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };


  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Syllabus</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <form onSubmit={handleSubmit}>
                <div className="card">
                  <div className="card-body row">
                    <div className="col-sm-12  col-md-8">
                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label">
                          Class<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <Select
                            name="class_id"
                            placeholder="Select Class..."
                            options={classList} 
                            value={
                              formValues.class_id
                                ? classList?.find(
                                    (c) => c.value === formValues.class_id
                                  )
                                : null
                            }
                            onChange={(selected) => {
                              handleSelectChange("class_id", selected.value);
                              setSelectClass(selected?.value);
                            }}
                            isSearchable={true}
                          />
                          {validationError && formValues.class_id === null ? (
                            <ErrorDisplay
                              errorMsg={"This field is required."}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label">
                          Subject<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <Select
                            name="subject_id"
                            placeholder="Select Subject"
                            options={subjectList}
                            value={
                              formValues.subject_id
                                ? subjectList?.find(
                                    (option) =>
                                      option.value === formValues.subject_id
                                  )
                                : null
                            }
                            onChange={(selected) => {
                              handleSelectChange("subject_id", selected.value);
                            }}
                            isSearchable={true}
                          />
                          {validationError && formValues.subject_id === null ? (
                            <ErrorDisplay
                              errorMsg={"This field is required."}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label">
                          Upload File:<span className="text-danger">*</span>
                        </label>{" "}
                        <div className="col-md-8">
                          <input
                            type="file"
                            className="form-control"
                            name="syllabus"
                            ref={fileInputRef}
                            onChange={handleFileChange}
                            accept=".jpg, .jpeg, .png, .gif, .pdf"
                          />
                          {validationError && formValues.syllabus === null ? (
                            <ErrorDisplay
                              errorMsg={"This field is required."}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    </div>

                    <div className="col-sm-12 col-md-4 text-sm-right text-lg-left text-right">
                      <button
                        type="submit"
                        className="btn btn-primary btn-sm px-3"
                      >
                        <i className="fa fa-plus px-1"></i> Add
                      </button>
                      <button
                        type="button"
                        className="btn btn-outline-danger btn-sm mx-2"
                        onClick={handleClear}
                      >
                        <i className="fa fa-times px-1"></i>
                        Clear
                      </button>
                    </div>
                  </div>
                </div>
              </form>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover table-vcenter table-striped table-sm text-nowrap">
                      <thead>
                        <tr className="text-center">
                          <th>Sr No</th>
                          <th>Class Name</th>
                          <th>Subject Name</th>
                          <th>File</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {syllabusData && syllabusData.length > 0 ? (
                          syllabusData?.map((video, index) => (
                            <tr className="text-center">
                              <td>{index + 1}</td>
                              <td>{video.fk_class__class_name}</td>
                              <td>{video.fk_subject__subject_name}</td>
                              <td>
                                {video.syllabus ? (
                                  <a
                                    href={`${MEDIA_BASE_URL}${video.syllabus}`}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <FaFilePdf
                                      style={{
                                        fontSize: "1.5rem",
                                      }}
                                      className="text-danger"
                                    />
                                  </a>
                                ) : (
                                  ""
                                )}
                              </td>
                              <td>
                                <NavLink
                                  to="#"
                                  className="card-options-remove mr-3"
                                  data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                                  onClick={() => onDelete(video.id)}
                                >
                                  <i className="fa fa-trash-o text-danger"></i>
                                </NavLink>
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan="5" className="text-center">
                              <h3 className="no-list-message mt-5 ">
                                Syllabus Not Available !
                              </h3>
                            </td>
                          </tr>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Syllabus;
