import {
  CREATE_CLASS_SUBJECT_FAILURE,
  CREATE_CLASS_SUBJECT_REQUEST,
  CREATE_CLASS_SUBJECT_SUCCESS,
  GET_CLASS_SUBJECT_FAILURE,
  GET_CLASS_SUBJECT_REQUEST,
  GET_CLASS_SUBJECT_SUCCESS,
  UPDATE_CLASS_SUBJECT_FAILURE,
  UPDATE_CLASS_SUBJECT_REQUEST,
  UPDATE_CLASS_SUBJECT_SUCCESS,
} from "../../Constants/school_user/SubjectMaster";

const initialState = {
  classsubject: null,
  createsubject: null,
  updatesubject: null,
  deletesubject: null,
  loading: false,
  error: null,
};

export const classSubjectReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_CLASS_SUBJECT_REQUEST:
    case GET_CLASS_SUBJECT_REQUEST:
    case UPDATE_CLASS_SUBJECT_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_CLASS_SUBJECT_SUCCESS:
      return {
        ...state,
        classsubject: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_CLASS_SUBJECT_SUCCESS:
      return {
        ...state,
        createsubject: action.payload,
        loading: false,
        error: null,
      };
    case UPDATE_CLASS_SUBJECT_SUCCESS:
      return {
        ...state,
        updatesubject: action.payload,
        loading: false,
        error: null,
      };

    case GET_CLASS_SUBJECT_FAILURE:
    case CREATE_CLASS_SUBJECT_FAILURE:
    case UPDATE_CLASS_SUBJECT_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
