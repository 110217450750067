import React, { useEffect, useState } from "react";
import Category from "./Category";
import { useDispatch, useSelector } from "react-redux";
import {
  CreateCaste,
  CreateCategory,
  deleteCategory,
  getCastMasterData,
} from "../../../actions/super-user/CastMaster";
import useLocalStorageData from "../../schooladmin/Comman/LocalStorageData";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import Loader from "../../Loader/Loader";
const CastMaster = () => {
  const { school_id, token, schoolUser } = useLocalStorageData();
  const { loading, castMaster, error, category } = useSelector(
    (state) => state.castMaster
  );
  const dispatch = useDispatch();
  const [CategoryMasterList, setCategoryMasterList] = useState([]);
  const [CategoryList, setCategoryList] = useState([]);
  const [isCategoryId, setIsCategoryId] = useState([]);
  // const [CastList, setCastList] = useState([]);
  let initialData = {
    caste_id: "",
    caste_name: "",
    fk_category: "",
    id: "",
    name: "",
    subCaste: [],
  };
  const [CastList, setCastList] = useState([initialData]);
  console.log(CastList, "CastList");
  const [isCategory, setIsCategory] = useState(false);
  const [isCast, setIsCast] = useState(false);
  const [isSubcaste, setIsSubcaste] = useState(false);
  const [isCategorySeleted, setIsCategorySeleted] = useState([]);
  console.log(isCategorySeleted, "isCategorySeleted");

  const resetStates = () => {
    setIsCategory(false);
    setIsCast(false);
    setIsSubcaste(false);
  };
  useEffect(() => {
    dispatch(getCastMasterData());
  }, [dispatch]);
  useEffect(() => {
    setCategoryMasterList(castMaster?.caste_Category);
  }, [castMaster]);

  const [addCatagory, setAddCatagory] = useState({
    category_id: "",
    category_name: "",
    insert_by: -schoolUser?.id,
  });
  const handleClearCatagory = () => {
    setAddCatagory({
      category_id: "",
      category_name: "",
      insert_by: -schoolUser?.id,
    });
  };
  useEffect(() => {
    if (isCategorySeleted?.category_id) {
      setAddCatagory({
        category_id: isCategorySeleted?.category_id || "",
        category_name: isCategorySeleted?.category_name || "",
        insert_by: -schoolUser?.id,
      });
      resetStates();
      console.log("first");
      setIsCategory(true);
    }
  }, [isCategorySeleted]);
  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;
    setAddCatagory((values) => ({ ...values, [name]: value }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      let response;
      if (isCast) {
        let list = CastList?.map((c) => ({
          id: c.id,
          name: c.caste_name,
        })).filter((c) => c.name !== "");
        let data = {
          categoryId: isCategoryId ?? "",
          insert_by: -schoolUser?.id,
          caste_data: JSON.stringify(list),
        };
        response = await dispatch(CreateCaste(data));
      } else if (isCategory) {
        response = await dispatch(CreateCategory(addCatagory));
      }
      console.log(response);
      if (response?.status === 200) {
        Swal.fire({
          text: response.msg,
          icon: "success",
          imageAlt: "success image",
        });
        handleClearCatagory();
        handleClear();
      }
      if (response?.status === 403) {
        Swal.fire({
          text: response.msg,
          icon: "error",
          imageAlt: "error image",
        });
      }
      await dispatch(getCastMasterData());
    } catch (error) {
      console.error(error);
    }
  };
  let handleClear = () => {
    setIsCategorySeleted([]);
    resetStates();
    setCastList([initialData]);
    handleClearCatagory();
  };
  const handleAddCast = (Id) => {
    handleClear();
    setIsCategoryId(Id);
    let list = castMaster?.caste_Category.find((cat) => cat.id === Id);
    if (list?.caste?.length > 0) {
      setCastList([...list.caste, initialData]);
    } else {
      setCastList([initialData]);
    }
    resetStates();
    setIsCast(true);
  };
  const handleAddSubcaste=(Id)=>{
    console.log(Id,"cast Id")
    resetStates();
    setIsSubcaste(true)
  }
  const handleAddCategory = () => {
    handleClear();
    resetStates();
    setIsCategory(true);
  };
  // ============================= =====================
  const handleAddRow = (e) => {
    e.preventDefault();
    const newRow = initialData;
    setCastList([...CastList, newRow]);
  };
  const handleDeleteRow = (indexRow) => {
    let deleteRow = CastList.filter((_, index) => index !== indexRow);
    setCastList(deleteRow);
  };
  let handleChangeCast = (e, index) => {
    const updateCast = [...CastList];
    updateCast[index].caste_name = e.target.value;
    setCastList(updateCast);
  };

  const onDelete = (id) => {
    let Id = {
      categoryId: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await dispatch(deleteCategory(Id));
        if (response?.status === 200) {
          Swal.fire({
            text: response.msg,
            icon: "success",
            imageAlt: "success image",
          });
          handleClear();
        }
        if (response?.status === 403) {
          Swal.fire({
            text: response.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
        await dispatch(getCastMasterData());
      }
    });
  };
  if (loading) return <Loader />;

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <div className="d-flex justify-content-between align-items-center ">
          <div className="header-action">
            <h1 className="page-title">Category And Caste</h1>
          </div>
          <div className=" mb-2">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm mx-1"
              // onClick={() => fetchStudents()}
              disabled={true}
            >
              <i className="fa fa-eye  px-1"></i>
              View
            </button>
            <button
              className="btn btn-primary btn-sm mx-1"
              type="submit"
              // disabled={StudentList?.length > 0 ? false : true}
            >
              <i className="fa fa-plus px-1"></i>
              Save
            </button>
            <button
              type="button"
              className="btn btn-outline-danger btn-sm mx-1"
              onClick={() => onDelete(isCategorySeleted?.category_id)}
              disabled={isCategorySeleted?.category_id ? false : true}
            >
              <i className="fa fa-trash px-1"></i>
              Delete
            </button>
            <button
              type="button"
              className="btn btn-outline-danger btn-sm mx-1"
              onClick={() => handleClear()}
            >
              <i className="fa fa-times px-1"></i>
              Clear
            </button>
          </div>
        </div>
        <div className="row">
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body">
                <PerfectScrollbar
                  style={{
                    height: "28rem",
                    paddingRight: "1rem",
                    boxSizing: "content-box",
                  }}
                  options={{ suppressScrollX: true }}
                >
      
                  {CategoryMasterList?.map((category) => (
                    <Category
                      key={category.id}
                      category={category}
                      handleAddCast={handleAddCast}
                      setIsCategorySeleted={setIsCategorySeleted}
                      isCategorySeleted={isCategorySeleted}
                      handleAddSubcaste={handleAddSubcaste}
                    />
                  ))}
                  <span
                    type="button"
                    className="btn-outline-secondary cast-button"
                    onClick={() => handleAddCategory()}
                  >
                    <i className=""></i>
                    Add New Category
                  </span>
                </PerfectScrollbar>
              </div>
            </div>
          </div>
       {addCatagory &&(
        <>
          <div className="col-lg-6 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body">
                <PerfectScrollbar
                  style={{
                    height: "28rem",
                    paddingRight: "1rem",
                    boxSizing: "content-box",
                  }}
                  options={{ suppressScrollX: true }}
                >
                      <h3 className="card-title text-center">Category</h3>
                      <div className="row mb-1">
                        <div className="col-md-1"></div>
                        <input
                          name="category_name"
                          type="text"
                          className="form-control col-md-10 col-sm-12"
                          placeholder="Enter Category"
                          value={addCatagory.category_name || ""}
                          onChange={handleChange}
                          required
                          autoComplete="off"
                        />

                        <div className="col-md-1 "></div>
                      </div>
                 
                 

                  {isCast && (
                    <>
                      <h3 className="card-title text-center">Caste</h3>
                      {CastList?.map((cast, index) => (
                        <div className="row mb-1">
                          <div className="col-md-1"></div>
                          <input
                            name={`content_file-${index}`}
                            className="form-control col-md-10"
                            type="text"
                            value={cast.caste_name}
                            onChange={(e) => handleChangeCast(e, index)}
                            placeholder={`Add Cast`}
                            autoComplete="off"
                          />
                          {index === CastList.length - 1 && (
                            <button
                              className="btn btn-primary col-md-1"
                              onClick={handleAddRow}
                            >
                              <i className="fa fa-plus"></i>
                            </button>
                          )}
                          {index !== CastList.length - 1 && (
                            <button
                              type="button"
                              className="btn btn-danger btn-sm col-md-1"
                              onClick={() => handleDeleteRow(index)}
                            >
                              <i className="fa fa-times"></i>
                            </button>
                          )}
                        </div>
                      ))}
                    </>
                  )}
                </PerfectScrollbar>
              </div>
            </div>
          </div>
          </>
       )}
       
        </div>
      </form>
    </div>
  );
};

export default CastMaster;
