import React, { useState, useEffect } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import { getClassSection } from "../../../actions/school_user/Attenadance";
import {
  createRollNo,
  getRollNoList,
} from "../../../actions/school_user/RollNoAllotment";
import Swal from "sweetalert2";
import useSessionRedirect from "../Comman/SessionChange";
import CustomScrollbar from "../../CommonComponent/PerfectScrollbar";
import Pagination from "../../../hooks/Pagination";

const RollNoAllotment = () => {
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  const dispatch = useDispatch();
  const { rollnodata } = useSelector((state) => state.rollNo);
  const { classsectionList } = useSelector((state) => state.attendance);
  const [classList, setClassList] = useState([]);
  const [classSection, setClassSection] = useState(null);
  const [studentInfo, setStudentInfo] = useState([]);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const [rollNumbers, setRollNumbers] = useState([]);
  // studentInfo?.map((stu, index) => stu.roll_no)



  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 5; // Change this to the desired number of items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = studentInfo?.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(studentInfo?.length / itemsPerPage);

  const onPageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handleGenerateRollNumber = () => {
    const newRollNumbers = [];
    for (let i = 1; i <= studentInfo.length; i++) {
      newRollNumbers.push(i);
    }
    setRollNumbers(newRollNumbers);
    setIsButtonDisabled(true);
  };
  let stu_data = {
    academic_id: session_id,
    school_id: school_id,
    section_id: classSection?.value || "",
  };
  useEffect(() => {
    if (token) {
      dispatch(getRollNoList(token, stu_data));
    }
  }, [classSection, session_id]);

  useEffect(() => {
    setClassList(classsectionList?.payload);
  }, [classsectionList]);

  let classSectionList = classList
    ?.map((c) => {
      return {
        label: `${c.class_name} ${c.section}`,
        value: c.id,
      };
    })
    .sort((a, b) => a.label.localeCompare(b.label));

  let s_data = {
    school_id: school_id,
  };
  useEffect(() => {
    if (token) {
      dispatch(getClassSection(token, s_data));
    }
  }, [dispatch, token, school_id]);

  useEffect(() => {
    setStudentInfo([]);
    setIsButtonDisabled(true);
  }, [session_id]);

  let handleStudentView = () => {
    if (!classSection?.value) {
      Swal.fire({
        title: "",
        text: "Please Select Class ",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }
    if (rollnodata?.payload?.length === 0) {
      Swal.fire({
        title: "",
        text: "Student List Not Found",
        icon: "info",
        allowOutsideClick: false,
      });
      return;
    }
    setStudentInfo(rollnodata?.payload);
    setIsButtonDisabled(false);
  };

  const sendDataToServer = async () => {
    const studentData = studentInfo.map((stu, index) => ({
      id: stu.id,
      roll_no: rollNumbers[index],
    }));

    if (studentData.some((data) => !data.roll_no)) {
      Swal.fire({
        title: "",
        text: "Please Generate Roll Numbers for all students",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }
    if (studentData?.length === 0) {
      return;
    }
    const data = {
      data_list: JSON.stringify(studentData),
    };

    try {
      let response = await dispatch(createRollNo(token, data));
      if (response.status === 200) {
        await Swal.fire({
          text: response.msg,
          icon: "success",
          imageAlt: "success image",
          confirmButtonText: "OK",
        });
      } else if (response.status === 403) {
        await Swal.fire({
          text: response.msg,
          icon: "error",
          imageAlt: "error image",
          confirmButtonText: "OK",
        });
      }
    } catch (error) {
      console.error("Error sending roll numbers:", error);
    }
  };

  let handleClassChange = (e) => {
    setClassSection(e);
    setStudentInfo([]);
    setRollNumbers([]);
    setIsButtonDisabled(true);
  };
  let handleCancel = () => {
    setStudentInfo([]);
    setClassSection(null);
    setIsButtonDisabled(true);
    setRollNumbers([]);
  };
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Roll No Allotment</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body row">
                  <div className="form-group col-md-6 row">
                    <label className="col-md-4 col-form-label">
                      Class<span className="text-danger"></span>
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-8">
                      <Select
                        options={classSectionList}
                        placeholder="Select Class"
                        value={classSection}
                        onChange={handleClassChange}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-6">
                    <div className="d-flex justify-content-start align-item-center">
                      <button
                        onClick={handleStudentView}
                        type="button"
                        className="mr-2 btn btn-outline-secondary px-3 text-center"
                      >
                        <i className="fa fa-eye px-1" aria-hidden="true"></i>
                        View
                      </button>
                      <button
                        onClick={handleGenerateRollNumber}
                        className="btn btn-outline-success px-3  text-center"
                        disabled={isButtonDisabled}
                      >
                        Generate
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <CustomScrollbar
              style={{
                height: "52vh",
                paddingRight: "1rem",
                boxSizing: "content-box",
              }}
            >
              <div className="table-responsive card col-lg-12 col-md-12 col-sm-12">
                <table className="table table-hover table-vcenter table-striped table-sm text-nowrap">
                  <thead>
                    <tr>
                      <th>Sr No</th>
                      <th>Student No</th>
                      <th>Student Name</th>
                      <th>Roll No</th>
                    </tr>
                  </thead>
                  <tbody>
                    {studentInfo?.map((stu, index) => (
                      <tr key={index}>
                        <td>{index + 1}</td>
                        <td>{stu.student_code}</td>
                        <td>{stu.student_name}</td>
                        <td>
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Roll No"
                            value={
                              stu.roll_no ? stu.roll_no : rollNumbers[index]
                            }
                            readOnly
                          />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </CustomScrollbar>

            <div className="col-sm-12 justify-content-center align-item-center my-3">
              <button
                type="submit"
                className="mr-1 btn btn-primary px-3"
                onClick={sendDataToServer}
              >
                <i className="fa fa-plus px-1"></i>Save
              </button>
              <button
                type="button"
                onClick={handleCancel}
                className="btn btn-outline-danger px-3"
              >
                <i className="fa fa-times px-1"></i>
                Clear
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RollNoAllotment;
