import React from "react";
import Loader from "../../../Loader/Loader";
import { useState } from "react";
import { NavLink } from "react-router-dom";

const ShowUserMaster = (props) => {
  const { staffdata, id, navigate, dispatch } = props;
  const [loader] = useState(<Loader />);
  return (
    <div className={!id ? "tab-pane active" : "tab-pane"} id="Fees-all">
      <div className="card">
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover text-nowrap js-basic-example dataTable table-striped table_custom border-style spacing5">
              <thead>
                <tr>
                  <th>Sr.No.</th>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Designation</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {staffdata?.map((data, index) => {
                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{data.first_name + " " + data.last_name}</td>
                      <td>{data.email}</td>
                      <td>{data.designation}</td>
                      <td>
                        <NavLink
                          className="text-muted"
                          to={`/school/user-master/${data.id}`}
                        >
                          <i className="fa fa-edit"></i>
                        </NavLink>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ShowUserMaster;
