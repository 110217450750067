import html2pdf from "html2pdf.js";
import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import numberToWords from "number-to-words";
import Swal from "sweetalert2";
import { useDispatch, useSelector } from "react-redux";
import "./style.css";
import www from "../Examination/GradeCard/www.png";

const PrintRegularFeesRecipt = (props) => {
  const {
    name,
    code,
    receiptNo,
    msgS,
    receiptDate,
    selectFeeType,
    paymentInfo,
    schoolUser,
    session,
    classSec,
    FeesReceipt,
    FeesTable,
    btnClick,
    convertDate,
    navigate,
    handleClear,
    alert,
    receiptType,
  } = props;
  const { email, user_detail } = schoolUser;
  const {
    school_name,
    organization_name,
    school_address,
    school_contact_email,
    school_contact_number,
    school_pincode,
    udise_no,
    school_logo,
  } = user_detail;
  const {
    payment_mode,
    total_amount,
    fine_amount,
    paid_amount,
    bank_name,
    pay_no,
    pay_date,
    remark,
    late_charge,
    bounce_charge,
    balance_amount,
  } = paymentInfo;
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [modal1, setModal1] = useState(false);
  const { logoData, loading, error } = useSelector((state) => state.schoolLogo);
  const toggle = () => {
    setModal(!modal);
    setSearchResults([]);
    setNoResultsMessage("");
  };

  const toggle1 = () => {
    setModal1(!modal1);
  };




  const handleConvertToPdf = async () => {
    const element = document.getElementById("divToConvert");
    if (element) {
      const opt = {
        margin: 10,
        filename: "generated.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      // Generate the PDF content as a data URL
      const pdfDataUri = await html2pdf()
        .from(element)
        .set(opt)
        .outputPdf("datauristring");

      // Open the PDF in a new tab
      const newTab = window.open();
      newTab.document.write(
        '<style> body { margin: 0px !important; overflow: hidden !important; }</style><iframe width="100%" height="100%" src="' +
          pdfDataUri +
          '"></iframe>'
      );
      newTab.document.close();
      handleClear();
      toggle1();
    }
  };

  if (paid_amount) {
    var words = numberToWords.toWords(Number(paid_amount)); 
  }
  const handelClose = () => {
    handleClear();
    toggle1();
  };

  return (
    <>
      <button
        disabled={btnClick}
        type="button"
        className="btn btn-sm btn-primary btn-block p-2 my-2 page-breadcrumb "
        onClick={() => {
          if (!code) {
            alert.error("Please enter Student Code");
          } else if (!name) {
            alert.error("Please enter Student name");
          } else if (!paymentInfo.total_amount) {
            alert.error("Please enter Amount");
          } else {
            Swal.fire({
              title: "CONFIRMATION",
              text: "You want to Save Fee Receipt?",
              icon: "question",
              showDenyButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Save",
              denyButtonText: `No`,
              allowOutsideClick: false,
            }).then(async (result) => {
              if (result.isConfirmed === false) {
                handleClear();
              }
              if (result.isConfirmed) {
                if (result.isConfirmed) {
                  await FeesReceipt();
                  Swal.fire({
                    title: "CONFIRMATION",
                    text:
                      receiptType === "New"
                        ? "Fees receipt saved succesfully. Do you want to Proceed for fee Receipt?"
                        : "Fees receipt Update succesfully. Do you want to Proceed for fee Receipt?",
                    icon: "success",
                    showDenyButton: true,
                    confirmButtonColor: "#3085d6",
                    cancelButtonColor: "#f46a6a",
                    confirmButtonText: "Yes",
                    denyButtonText: `No`,
                    allowOutsideClick: false,
                    // willClose: (e) => {
                    //   handleClear();
                    // },
                  }).then((result) => {
                    if (result.isConfirmed) {
                      toggle1();
                    } else if (result.isDenied) {
                      handleClear();
                    }
                  });
                } else if (result.isDenied) {
                  console.log(result.isDenied);
                }
              } else if (result.isDenied) {
                console.log(result.isDenied);
              }
            });
          }
        }}
      >
        {receiptType === "New"
          ? "Save And Print Receipt"
          : "Update And Print Receipt"}
      </button>
      <Modal
        isOpen={modal1}
        toggle={toggle1}
        fullscreen
        className="modal-lg"
        backdrop="static"
      >
        {/* <ModalHeader> */}
          {" "}
         
          <div className="text-right m-2">
            <button
              className="btn btn-primary"
              onClick={() => {
                handleConvertToPdf();
              }}
            >
              Print Fees Receipt
            </button>
            <Button color="secondary ml-2" onClick={handelClose}>
              Close
            </Button>
            <hr className="border border-muted" />
          </div>
         
         
        {/* </ModalHeader> */}
        <ModalBody>
          <div className="container-fluid" id="divToConvert">
            <main>
              {/* <section className="col-12">
                <div className="row">
                  <div className="col-12 text-center">
                    <p>{organization_name}</p>
                    <h2>{school_name}</h2>
                  </div>
                </div>

                <div className="text-center">
                  <h6>{school_address}</h6>
                  <h6>
                    pincode- {school_pincode}, Ph: {school_contact_number}
                  </h6>
                  <h6>E-mail: {school_contact_email}</h6>
                </div>
              </section> */}
              <div className="d-flex align-items-stretch">
                <div className="col-3 m-auto">
                  {logoData ? (
                    <img
                      src={`data:image/png;base64,${logoData}`}
                      alt="school_logo"
                      width={100}
                      height={100}
                      className=""
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <img
                      src={www}
                      alt="fallback_image"
                      width={100}
                      height={100}
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                </div>

                {/* Column for the text in the center */}
                <div className="col-9">
                  <div className="text-center col-8">
                    <p className="font-weight-bold text-dark">
                      {organization_name}
                    </p>
                    <div className="font-weight-bold">{school_name}</div>
                    <div>
                      <h6>{school_address}</h6>
                      <h6 style={{ fontSize: "0.7rem" }}>
                        Pincode- {school_pincode}, Ph: {school_contact_number}
                      </h6>
                      <h6 style={{ fontSize: "0.7rem" }}>
                        E-mail: {school_contact_email}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>
              <hr className="border border-dark" />

              <section>
                <div className="row">
                  <div className="col-6">
                    <h6>Reg No : {udise_no}</h6>
                    <h6>Name : {name}</h6>
                    <h6>Class : {classSec}</h6>
                  </div>
                  <div className="col-6">
                    <h6>Session : {session}</h6>
                    <h6>Receipt No : {receiptNo}</h6>
                    <h6>Receipt Date : {convertDate(receiptDate)}</h6>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="table-responsive card">
                    <table className="table  table-vcenter  mb-0 text-nowrap ">
                      <thead className="ehed text-center  ">
                        <th className="text-dark font-weight-bold">Sr. No</th>
                        <th className="text-dark font-weight-bold">
                          Particular(s){" "}
                        </th>
                        <th className="text-dark font-weight-bold">Amount</th>
                      </thead>
                      <tbody className="T-body">
                        {FeesTable?.map((row, index) => {
                          // Only proceed if installment_amount is not zero
                          if (
                            row.installment_amount !== 0 && 
                            row.installment_amount !== "" && 
                            row.paid_amount !== 0 && 
                            row.paid_amount !== ""
                           
                          ) {

                            return (
                              <tr key={index}>
                                <td>{index + 1}</td>

                                <td className="text-left">
                                  {row.feeshead_name}
                                </td>

                                <td className="text-right font-weight-bold">
                                  {row.installment_amount || row.paid_amount }
                                </td>
                              </tr>
                            );
                          } else {
                            // Render nothing if installment_amount is zero
                            return;
                          }
                        })}

                        <tr>
                          <th
                            colSpan={2}
                            className="text-right Total text-dark font-weight-bold"
                          >
                            Total Amount
                          </th>
                          <td className="Total text-right font-weight-bold">
                            {total_amount}
                          </td>
                        </tr>
                        {/* <tr>
                          <th colSpan={2} className="text-right">
                            Balance Amount
                          </th>
                          <td className="">{0}</td>
                        </tr> */}
                        <tr>
                          <th
                            colSpan={2}
                            className="text-right T-Body text-dark font-weight-bold"
                          >
                            Late Charges
                          </th>
                          <td className="T-Body  text-right font-weight-bold">
                            {late_charge}
                          </td>
                        </tr>
                        <tr>
                          <th
                            colSpan={2}
                            className="text-right T-Body text-dark font-weight-bold"
                          >
                            Bounce Charges
                          </th>
                          <td className="T-Body  text-right font-weight-bold">
                            {bounce_charge}
                          </td>
                        </tr>
                        <tr>
                          <th
                            colSpan={2}
                            className="text-right Total text-dark font-weight-bold"
                          >
                            Paid Amount
                          </th>
                          <td className="Total  text-right font-weight-bold">
                            {paid_amount}
                          </td>
                        </tr>

                        <tr>
                          <th
                            colSpan={1}
                            className="text-dark font-weight-bold"
                          >
                            In Word
                          </th>
                          <th
                            colSpan={1}
                            className="text-right text-dark font-weight-bold"
                          >
                            <strong className="font-weight-bold">
                              {words} Rupees
                            </strong>
                          </th>
                          <th colSpan={1} className="text-dark"></th>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>

                <div>
                  <h6>Payment Mode : {payment_mode}</h6>
                  {payment_mode === "Cash" ? null : (
                    <>
                      {payment_mode === "UPI" ? null : (
                        <h6>Bank Name : {bank_name}</h6>
                      )}
                      <h6>
                        {payment_mode === "Bank" ? "Account" : payment_mode} No.
                        : {pay_no}
                      </h6>
                      <h6>Payment Date : {convertDate(pay_date)}</h6>
                    </>
                  )}
                </div>
              </section>
              <hr className="border border-dark" />

              <footer className="row  pt-5 mt-5">
                <div className="col-6">
                  <h6>Received By : Clerk </h6>
                </div>
                <div className="col-6 text-right">
                  <h6>Signature </h6>
                </div>
                <hr />
              </footer>
            </main>
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default PrintRegularFeesRecipt;
