import {
  CREATE_ROLL_NO_FAILURE,
  CREATE_ROLL_NO_REQUEST,
  CREATE_ROLL_NO_SUCCESS,
  GET_ROLL_NO_FAILURE,
  GET_ROLL_NO_REQUEST,
  GET_ROLL_NO_SUCCESS,
} from "../../Constants/school_user/RollNoAllotment";

const initialState = {
  rollnodata: null,
  createrollno: null,
  loading: false,
  error: null,
};

export const rollNoReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_ROLL_NO_REQUEST:
    case GET_ROLL_NO_REQUEST:
      return {
        ...state,
        loading: true,
        error: null,
      };
    case GET_ROLL_NO_SUCCESS:
      return {
        ...state,
        rollnodata: action.payload,
        loading: false,
        error: null,
      };
    case CREATE_ROLL_NO_SUCCESS:
      return {
        ...state,
        ccreaterollno: action.payload,
        loading: false,
        error: null,
      };

    case GET_ROLL_NO_FAILURE:
    case CREATE_ROLL_NO_FAILURE:
      return {
        ...state,
        loading: true,
        error: action.payload,
      };
    default:
      return state;
  }
};
