import React, { useEffect, useRef, useState } from "react";
import html2pdf from "html2pdf.js";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import Swal from "sweetalert2";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { gradeMasterAPI } from "../../../../api/Examination/Grade";
import { MEDIA_BASE_URL } from "../../../../Config/BaseUrl";
import { SchoolImage } from "../../../../api/SchoolImage/SchoolLogoAPI";
import Card from "./Card";
import Loader from "../../../Loader/Loader";
import { useSelector } from "react-redux";

const GradeCardPrint = (props) => {
  // const [loadingLogo, setLoadingLogo] = useState(false);
  const {
    selectedStudent,
    examId,
    gradeData,
    sectionId,
    handleClear,
    title,
    stu_Ranking,
  } = props;

  const { school_id, schoolUser, token, school_emp_id, user_detail } =
    useLocalStorageData();

  const {
    school_name,
    organization_name,
    school_address,
    school_contact_email,
    school_contact_number,
    school_pincode,
    udise_no,
    school_logo,
  } = user_detail;

  const session_id = useSessionRedirect();
  const [modal, setModal] = useState(false);
  const [gradeCard, setGradeCard] = useState([]);

  const [cardInfo, setCardInfo] = useState([]);
  const [grade, setGrade] = useState([]);
  const [studentImage, setStudentImage] = useState("");

  const toggle = () => {
    if (!modal) {
      setModal(true);
    } else {
      setModal(false);
    }
  };
  const { logoData, loading, error } = useSelector((state) => state.schoolLogo);

  // const [base64Image, setBase64Image] = useState("");
  // const [loadingLogo, setLoadingLogo] = useState(true);

  // const fetchData = async () => { // Start loading when fetching data
  //   setLoadingLogo(true);
  //   try {
  //     const imageBase64 = await SchoolImage.fetchImageFromAPI(school_id);
  //     if (imageBase64) {
  //       setBase64Image(imageBase64);
  //       setLoadingLogo(false);
  //     } else {
  //       console.error("Image fetching failed");
  //     }
  //   } catch (error) {
  //     console.error("Error fetching image:", error);
  //   } finally {

  //   }
  // };

  const generateGradeCard = async () => {
    try {
      await Swal.fire({
        title: "CONFIRMATION",
        text: "Do you want to Generate Grade Card for Selected Student ?",
        icon: "question",
        showDenyButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        denyButtonText: `No`,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          const data = {
            academic_id: session_id,
            school_id: school_id,
            section_id: sectionId,
            exam_id: examId,
            student_list: JSON.stringify(selectedStudent),
          };
          const response = await gradeMasterAPI.createGradeCard(data);
          let responseData = response.data;
          // console.log(responseData);
          if (responseData.status === 200) {
            setGradeCard(responseData.GradeCard_list);
            setCardInfo(responseData.Info_dict);
            setGrade(responseData.grade_details);
            setStudentImage(
              `${MEDIA_BASE_URL}${responseData.GradeCard_list[0].student_image}`
            );
            toggle();
          }
        } else if (result.isDenied) {
        }
      });
    } catch (error) {
      console.error(error);
    }
  };

  //  console.log(studentImage)
  // console.log(isLoading)

  const handleConvertToPdf = async () => {
    const element = document.getElementById(`model-con`);
    if (element) {
      const opt = {
        margin: 15,
        filename: `generated_.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1 },
        pagebreak: { mode: "css", after: "#divToConvert" },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      const pdfDataUri = await html2pdf()
        .from(element)
        .set(opt)
        .outputPdf("datauristring");

      const newTab = window.open();
      if (newTab) {
        newTab.document.write(
          `<style> body { margin: 0px !important; overflow: hidden !important; }</style><iframe width="100%" height="100%" src="${pdfDataUri}"></iframe>`
        );
        newTab.document.close();
      } else {
        console.error("Failed to open a new tab.");
      }
    }

    setModal(!modal);
    setGradeCard([]);

    if (title === "Grade Card") {
      handleClear();
    }
  };

  // const handleConvertToPdf = async () => {
  //   const element = document.getElementById(`model-con`);

  //   // Ensure that the image is loaded before proceeding
  //   const loadImagePromise = new Promise((resolve) => {
  //     const img = new Image();
  //     img.onload = resolve;
  //    // img.src = `${MEDIA_BASE_URL}/${studentImage}`;
  //     img.src = {school_logo};

  //   });

  //   await loadImagePromise;

  //   if (element) {
  //     const opt = {
  //       margin: 15,
  //       filename: `generated_.pdf`,
  //       image: { type: "jpeg", quality: 0.98 },
  //       html2canvas: { scale: 1 },
  //       pagebreak: { mode: "css", after: "#divToConvert" },
  //       jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
  //     };

  //     try {
  //       const pdfDataUri = await html2pdf().from(element).set(opt).outputPdf("datauristring");

  //       const newTab = window.open();
  //       if (newTab) {
  //         newTab.document.write(
  //           `<style> body { margin: 0px !important; overflow: hidden !important; }</style><iframe width="100%" height="100%" src="${pdfDataUri}"></iframe>`
  //         );
  //         newTab.document.close();
  //       } else {
  //         console.error("Failed to open a new tab.");
  //       }
  //     } catch (error) {
  //       console.error("Error converting to PDF:", error);
  //     }
  //   }

  //   // Reset your state or perform additional actions after PDF conversion
  //   setModal(!modal);
  //   setGradeCard([]);
  //   if (title === "Grade Card") {
  //     handleClear();
  //   }
  // };

  const viewGradeCard = () => {
    if (gradeData.length > 0) {
      const data = gradeData[0];
      const { GradeCard_list, Info_dict, grade_details } = data;
      setGradeCard(GradeCard_list);
      setCardInfo(Info_dict);
      setGrade(grade_details);
      toggle();
    } else {
      console.error("No data available for generating the grade card.");
    }
  };

  return (
    <>
      {title === "Grade Card" && (
        <button
          type="button"
          className="btn btn-primary btn-sm mx-1"
          onClick={() => {
            generateGradeCard();
          }}
          disabled={selectedStudent.length > 0 ? false : true}
        >
          <i className="fa fa-print px-1"></i>
          Print
        </button>
      )}

      {title === "Student Profile" && (
        <button
          type="button"
          className="btn btn-outline-secondary btn-sm mx-1"
          onClick={() => {
            viewGradeCard();
          }}
        >
          <i className="fa fa-eye  px-1"></i>View
        </button>
      )}
      <Modal
        isOpen={modal}
        toggle={toggle}
        fullscreen
        className="mobel_box_Grade "
        backdrop="static"
        keyboard={false}
      >
        <div className="text-right m-3">
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>

          {loading ? (
            <>
              <div>Loading...</div>{" "}
            </>
          ) : (
            <>
              <button
                className="btn btn-primary mx-1"
                onClick={handleConvertToPdf} 
                disabled={loading}
              >
                Print Certificate
              </button>
            </>
          )}
          <hr className="border border-muted" />
        </div>

        <ModalBody id="model-con">
          {gradeCard.length > 0 ? (
            gradeCard?.map((g, index) =>
              index !== gradeCard?.length - 1 ? (
             
                <Card
                  g={g}
                  id={`divToConvert`}
                  // base64Image={base64Image}
                  loading={loading}
                  base64Image={logoData}
                  grade={grade}
                  cardInfo={cardInfo}
                  stu_Ranking={stu_Ranking}
                />
              ) : (
                <Card
                  g={g}
                  // id={`divToConvert`}
                  loading={loading}
                  base64Image={logoData}
                  grade={grade}
                  cardInfo={cardInfo}
                  stu_Ranking={stu_Ranking}
                />
              )
            )
          ) : (
            <p className="no-list-message" style={{ fontSize: "1.5rem" }}>
              Grade Card Not Found!{" "}
            </p>
          )}
        </ModalBody>

        <ModalFooter></ModalFooter>
      </Modal>
    </>
  );
};

export default GradeCardPrint;
