import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
import {
  GET_MODULE_FAILD,
  GET_MODULE_REQUIST,
  GET_MODULE_SUCCESS,
} from "../../Constants/Common";

export const getAllModule = (token) => async (dispatch) => {
  try {
    dispatch({ type: GET_MODULE_REQUIST });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(`${BASE_URL}mudule_list/`, {}, config);

    dispatch({ type: GET_MODULE_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_MODULE_FAILD, payload: error });
  }
};
