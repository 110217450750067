

export const scrollToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
export function padWithZero(value) {
  return (value < 10 ? `0${value}` : `${value}`);
}


export function getTitle (data){
 
   return document.title = data;
 
}

