import React, { useEffect } from "react";
import { useState } from "react";
import { Table } from "reactstrap";
import useLocalStorageData from "../Comman/LocalStorageData";
import { salaryStructure } from "../../../api/Payroll/SalaryStructure";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
import { Heading } from "../../CommonComponent/heading";
import Swal from "sweetalert2";
import useLoading from "../../CommonComponent/useLoading";
import Loader from "../../Loader/Loader";

const SalaryStructure = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [HeadList, setHeadList] = useState([]);
  const [employList, setEmployList] = useState([]);
  const [newDate, setnewDate] = useState();
  const [emp, setEmp] = useState("");
  console.log(employList, "::::employList::::: ::::");

  const [d, setD] = useState([]);

  const { isLoading, startLoading, stopLoading } = useLoading();
  // console.log(emp);
  const getSalaryStructure = async () => {
    startLoading();
    let data = {
      school_id: school_id,
    };
    try {
      const response = await salaryStructure.getSalarystructureSchoolwise(data);
      const responseData = response.data;
      console.log(responseData.salary_heads, "salary_heads");
      const sortedSalaryHeadList = responseData.salary_heads
  .filter(salaryHead => salaryHead.is_active) // Filter out inactive salary heads
  .sort((a, b) => a.sequence - b.sequence); // Sort the active salary heads by sequence

      setHeadList(sortedSalaryHeadList);
      setEmployList(responseData.salary_structure);
      if (responseData.status === 200) {
        stopLoading();
      }
    } catch (err) {
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    getSalaryStructure();
  }, []);
  const handleAmountChange = (staffId, headsName, amount) => {
    const employee = employList.find((emp) => emp.staff_id === staffId);
    if (employee) {
      const head = employee.heads.find((h) => h.heads_name === headsName);
      if (head) {
        head.amount = amount;
        const updatedEmployList = employList.map((emp) => {
          if (emp.staff_id === staffId) {
            return {
              ...emp,
              heads: emp.heads.map((h) => {
                if (h.heads_name === headsName) {
                  return {
                    ...h,
                    amount: amount,
                  };
                }
                return h;
              }),
            };
          }
          return emp;
        });

        setEmp(updatedEmployList);
      }
    }
  };
  const handleEffectiveDateChange = (staffId, effectiveDate) => {
    const updatedEmployList = employList.map((employee) => {
      if (employee.staff_id === staffId) {
        return {
          ...employee,
          effective_date: effectiveDate,
        };
      }
      return employee;
    });

    // Update the state with the updated employList
    setEmployList([...updatedEmployList]);
    setEmp([...updatedEmployList]);

    setnewDate(effectiveDate);
  };

  // function convertArray(data) {
  //   // School ID and inserted by can be set here or passed as arguments
  //   const schoolId = "701";
  //   const insertedBy = "3";

  //   const convertedData = {
  //     school_id: schoolId,
  //     insert_by: insertedBy,
  //     staff_list: [],
  //   };

  //   for (const staff of data) {
  //     const staffObject = {
  //       staff_id: staff.staff_id,
  //       structure_id: staff.structure_id,
  //       effective_date:staff.effective_date,
  //       heads: [],
  //     };

  //     for (const head of staff.heads) {
  //       const headObject = {
  //         structure_details_id: head.structure_details_id,
  //         heads_id: head.heads_id,
  //         amount: head.amount === "" ? "0" : head.amount,
  //       };
  //       staffObject.heads.push(headObject);
  //     }

  //     convertedData.staff_list.push(staffObject);
  //   }
  //   console.log(convertedData)

  // }

  const handelSubmit = async (data) => {
    if (!data) {
      Swal.fire({
        text: "Please add some changes",
        icon: "error",
      });
      return;
    }

    const hasInvalidEffectiveDate = data.some(staff => !staff.effective_date || staff.effective_date === 'None');

  if (hasInvalidEffectiveDate) {
    // Show error message
    Swal.fire({
      text: "Please select an effective date for all entries.",
      icon: "error",
    });
    return;
  }
  

    const convertedData = {
      school_id: school_id,
      insert_by: school_emp_id,
      staff_list: [],
    };

    for (const staff of data) {
      const staffObject = {
        staff_id: staff.staff_id,
        structure_id: staff.structure_id,
        effective_date: staff.effective_date,
        heads: [],
      };

      for (const head of staff.heads) {
        const headObject = {
          structure_details_id: head.structure_details_id,
          heads_id: head.heads_id,
          amount: head.amount === "" ? "0" : head.amount,
        };
        staffObject.heads.push(headObject);
      }

      convertedData.staff_list.push(staffObject);
    }
console.log(convertedData)
    startLoading();
    try {
      let response = await salaryStructure.addUpdateSalaryStructure(
        convertedData
      );
      const responseData = response.data;

      if (responseData.status === 200) {
        Swal.fire({
          text: responseData.msg,
          icon: "success",
        });
        getSalaryStructure();
      } else {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
        });
      }
    } catch (err) {
      console.log(err);
    } finally {
      stopLoading();
    }
  };
  if (isLoading) return <Loader />;
  return (
    <div className="container-fluid">
      <Heading pageTitle={"Salary Structure"}>
        <div className="d-flex">
          <button
            className="btn btn-primary btn-sm mx-4"
            type="submit"
            onClick={() => handelSubmit(emp)}
          >
            <i className="fa fa-plus px-1"></i>
            Save
          </button>
        </div>
      </Heading>

      <div className="card mt-2">
        <div class="card-header">
          <h3 class="card-title">Employee List</h3>
        </div>
       
        <div className="card-body">
          {HeadList.length > 0 ? (
            <div className="table-responsive">
              <table className="table table-hover table-vcenter table-striped text-nowrap">
                <thead>
                  <tr className="text-center">
                    <th>Sr No</th>
                    <th>Employee No</th>
                    <th>Employee Name</th>
                    <th>Designation</th>
                    <th>Effective Date</th>
                    {HeadList.map((head, index) => (
                      <th
                        key={index}
                        className={
                          head.heads_type === "Earning"
                            ? "text-success text-bold"
                            : head.heads_type === "Deduction"
                            ? "text-danger text-bold"
                            : ""
                        }
                      >
                        {head.heads_name}
                      </th>
                    ))}

                    <th>total</th>

                    {/* New column for effective date */}
                  </tr>
                </thead>
                <tbody>
                  {employList.map((emp, index) => {
                    // Calculate the total of all input fields in the row
                    const totalAmount = emp.heads.reduce((total, head) => {
                      const amount = parseInt(head.amount || 0);
                      if (isNaN(amount)) {
                        return total;
                      }

                      const roundedAmount = Math.round(amount);

                      if (head.heads_type === "Earning") {
                        return total + roundedAmount;
                      } else if (head.heads_type === "Deduction") {
                        return total - roundedAmount;
                      } else {
                        return total;
                      }
                    }, 0);

                    return (
                      <tr className="text-center" key={index}>
                        <td>{index + 1}</td>
                        <td>{emp.emp_no}</td>
                        <td>{emp.first_name + " " + emp.last_name}</td>
                        <td>{emp.designation}</td>
                        <td>
                          <input
                            type="date"
                            className="form-control"
                            value={emp.effective_date || ""}
                            onChange={(e) =>
                              handleEffectiveDateChange(
                                emp.staff_id,
                                e.target.value
                              )
                            }
                          />
                        </td>

                        {HeadList.map((head, idx) => {
                          // Find the head corresponding to the current head name
                          const correspondingHead = emp.heads.find(
                            (h) => h.heads_name === head.heads_name
                          );
                          // Parse the amount to an integer and round it
                          const roundedAmount = correspondingHead
                            ? Math.round(
                                parseInt(correspondingHead.amount || 0)
                              )
                            : 0;

                          return (
                            <td key={idx} style={{ minWidth: "100px" }}>
                              <input
                                type="text"
                                className="form-control text-right"
                                value={
                                  isNaN(roundedAmount)
                                    ? ""
                                    : roundedAmount.toString()
                                }
                                onChange={(e) =>
                                  handleAmountChange(
                                    emp.staff_id,
                                    head.heads_name,
                                    e.target.value
                                  )
                                }
                              />
                            </td>
                          );
                        })}

                        {/* Add a new table cell for the total amount */}
                        <td style={{ minWidth: "100px" }}>
                          <input
                            type="text"
                            className="form-control text-right"
                            value={totalAmount}
                            readOnly
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          ) : (
            <EmptyListMessage message={"List Not Available !"} Div="div" />
          )}
        </div>
      </div>
    </div>
    //   </div>
    // </div>
  );
};

export default SalaryStructure;
