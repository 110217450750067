import {
    CREATE_WORKSHEET_REQUIST,
    CREATE_WORKSHEET_SUCCESS,
    CREATE_WORKSHEET_FAIELD,
    SHOW_WORKSHEET_REQUIST,
    SHOW_WORKSHEET_SUCCESS,
    SHOW_WORKSHEET_FAIELD,
    DELETE_WORKSHEET_REQUIST,
    DELETE_WORKSHEET_SUCCESS,
    DELETE_WORKSHEET_FAIELD,
} from "../../Constants/TeacherDesk/WorkSheet";


const initialState = {
    payload: null,
    showWorkSheetList: [],  
    showSelectedWorkSheet:[],  
    loading: false,
    error: null,
    deleteWorkSheetList:[], 
  };


  export const CreateUpdateHomeWork = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_WORKSHEET_REQUIST:
          return {
            ...state,
            loading: true,
            error: null,
          };
        case CREATE_WORKSHEET_SUCCESS:
          return {
            ...state,
            showSelectedWorkSheet: action.payload,
            loading: false,
            error: null,
          };
        case CREATE_WORKSHEET_FAIELD:
          return {
            ...state,
            loading: true,
            error: action.payload,
          };
        default:
          return state;
      }
    };

    export const GetHomeWorkList = (state = initialState, action) => {
        switch (action.type) {
          case SHOW_WORKSHEET_REQUIST:
            return {
              ...state,
              loading: true,
              error: null,
            };
          case SHOW_WORKSHEET_SUCCESS:
            return {
              ...state,
              showWorkSheetList: action.payload,
              loading: false,
              error: null,
            };
          case SHOW_WORKSHEET_FAIELD:
            return {
              ...state,
              loading: true,
              error: action.payload,
            };
          default:
            return state;
        }
      };


      export const DeleteWorkSheet = (state = initialState, action) => {
        switch (action.type) {
          case DELETE_WORKSHEET_REQUIST:
            return {
              ...state,
              loading: true,
              error: null,
            };
          case DELETE_WORKSHEET_SUCCESS:
            return {
              ...state,
              deleteWorkSheetList: action.payload,
              loading: false,
              error: null,
            };
          case DELETE_WORKSHEET_FAIELD:
            return {
              ...state,
              loading: true,
              error: action.payload,
            };
          default:
            return state;
        }
      };

