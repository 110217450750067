import React from "react";

const Loader = () => {
  return (
    <div className="card">
      <div className="card-body ">
        <div className="loading">
          <div></div>
        </div>
      </div>
    </div>
  );
};

export default Loader;
