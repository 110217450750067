import {
    GET_DASHBOARD2_DATA_FAILURE,
    GET_DASHBOARD2_DATA_REQUEST,
    GET_DASHBOARD2_DATA_SUCCESS,
  } from "../../Constants/Dashboard/Dashboard";
  
  const initialState = {
    dashboard2: [],
    loading: false,
    error: null,
  };
  
  export const Dashboard2Reducer = (state = initialState, action) => { 
    switch (action.type) {
      case GET_DASHBOARD2_DATA_REQUEST:
        return {
          ...state, 
          loading: true,
        };
      case GET_DASHBOARD2_DATA_SUCCESS:
        return {
          ...state,
          dashboard2: action.payload,
          loading: false,
        };
      case GET_DASHBOARD2_DATA_FAILURE:
        return {
          ...state,
          error: action.payload,
          loading: false,
        };
  
      default:
        return state;
        
    }
  };
  