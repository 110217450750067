import React from 'react'

const TermsAndCondition = () => {
  return (
    <div className='p-5'>
     <h1> Terms & Conditions</h1>
<p>By using the vidyamate.in or school.vidyamate.in or teacher.vidyamate.in web site ("Service"), provided by FiPal Technologies, LLP, a company established under the Companies Act of 1956 India, located at Kanekal, Anantapur, 515871, Andhra Pradesh, India, you are agreeing to be bound by the following Terms & Conditions ("Terms & Conditions"). The Service provider reserves the right to update and change the Terms & Conditions from time to time without notice. Any new features that augment or enhance the current Service, including the release of new tools and resources, shall be subject to the Terms & Conditions. Continued use of the Service after any such changes shall constitute your consent to such changes. You can review the most current version of the Terms & Conditions at any time at: https://www.vidyamate.in/terms_and_conditions.
Violation of any of the terms below will result in the termination of your Account. While the service provider prohibits such conduct and Content on the Service, you understand and agree that the service provider cannot be responsible for the Content posted on the Service, and you nonetheless may be exposed to such materials. You agree to use the Service at your own risk.
Basic Terms</p>
<ul>

<li>If you do not agree, you should decline this Agreement and immediately stop using the Service. Access to the Service is permitted only to those who fully agree with the Terms & Conditions of this Terms & Conditions agreement.
</li>
<li>You agree that this Terms & Conditions Agreement and the relationship between the parties shall be exclusively governed by the laws of the Republic of India without regard to conflict of law principles or international conventions. You must provide your legal full name, a valid email address, and any other information requested to complete the signup process.
</li>
<li>Your login may only be used by one person; a single login shared by multiple people is not permitted. You may create separate logins for as many people as your plan allows.
</li>
<li>You are responsible for maintaining the security of your account and password. The service provider cannot and will not be liable for any loss or damage resulting from your failure to comply with this security obligation.
</li>
<li>You are responsible for all Content posted and activity that occurs under your account (even when Content is posted by others who have accounts under your account).
</li>
<li>You may not use the Service for any illegal or unauthorized purpose. You must not, in the use of the Service, violate any laws in your jurisdiction (including but not limited to copyright laws).
</li>
<li>You undertake not to harass, threaten, abuse, or harm the Service Provider or other users of the Service in any way.

Refunds, Upgrading and Downgrading Terms
</li>
<li>The Service is billed in advance on a yearly basis and is non-refundable. There will be no refunds or credits for partial months of service, upgrade/downgrade refunds, or refunds for months unused with an open account.
</li>
<li>All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities if not stated otherwise. You will be responsible for payment of all such taxes, levies, or duties.
</li>
<li>For any upgrade or downgrade in plan level, your credit card that you provided will automatically be charged the new rate on your next billing cycle.
</li>
<li>Downgrading your Service may cause the loss of Content, features, or capacity of your Account. The service provider does not accept any liability for such loss.
Cancellation and Termination
</li>
<li>You are solely responsible for properly canceling your account. You can contact support at vidyamate dot in to know the cancellation procedure.
</li>
<li>All of your Content will be immediately deleted from the Service upon cancellation. This information cannot be recovered once your account is canceled.
</li>
<li>If you cancel the Service before the end of your current paid-up year, your cancellation will take effect immediately, and you will not be charged again.
</li>
<li>The service provider, in its sole discretion, has the right to suspend or terminate your account and refuse any and all current or future use of the Service for any reason at any time. Such termination of the Service will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all content in your Account. The service provider reserves the right to refuse service to anyone for any reason at any time.
Modifications Terms
</li>
<li>The service provider reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice.
</li>
<li>Prices of all Services, including but not limited to annual subscription plan fees to the Service, are subject to change upon 30 days' notice from us. Such notice may be provided at any time by posting the changes to the Vidyamate Site (www.vidyamate.in) or the Service itself.
</li>
<li>The service provider shall not be liable to you or to any third party for any modification, price change, suspension, or discontinuance of the Service.
</li>
<li>You acknowledge and agree that the form and nature of the Service may change from time to time without prior notice to you due to the fact that the Service Provider is constantly innovating and improving the Service. Also, you acknowledge and agree that the Service Provider may stop (permanently or temporarily) providing the Service (or any features within the Service) to you at the Service Provider's sole discretion, without prior notice to you.
Content Terms
</li>
<li>Service Provider's Content is protected by copyright. You may not copy, distribute, modify, rent, lease, loan, sell, distribute, create derivative works, reverse engineer, decompile or otherwise attempt to extract the source code of the Service or any part thereof without the copyright owner's respective license.
</li>
<li>The Service Provider claims no intellectual property rights over the material provided by you to the Service. Your profile and materials uploaded remain yours. However, by setting your pages to be shared publicly, you agree to allow others to view and share your Content.
</li>
<li>The Service provider does not pre-screen Content, but the service provider and its designee have the right (but not the obligation) in their sole discretion to refuse or remove any Content that is available via the Service.
</li>
<li>You undertake not to create, transmit, display, or make otherwise available any Content that is unlawful, harmful, threatening, abusive, harassing, tortuous, defamatory, vulgar, obscene, invasive of another's privacy, or hateful (including viruses, worms, and any other destructive codes).
General Conditions
</li>
<li>Your use of the Service is at your sole risk. The service is provided on an "as is" and "as available" basis.
</li>
<li>Among other things, the Service Provider does not represent and warrant to you that:
</li>


<li>Your use of the Service will meet your requirements;</li>

<li>Your use of the Service will be uninterrupted, timely, secure, or free from error;
</li>
<li>Any information obtained by you as a result of your use of the Service will be accurate, correct, reliable, and up to date;
</li>
<li>Defects in the operation or functionality of
</li>
<li>Defects in the operation or functionality of the Service will be corrected.
</li>
<li>Technical support is available through all modes.
</li>
<li>No warranties (including for satisfactory quality, fitness for purpose, or conformance with description) apply to the Service except to the extent expressly stipulated in the Agreement.
</li>
<li>The Service Provider is not liable for any direct, indirect, or consequential damage (including loss of profit, loss of data, loss of goodwill, or business reputation) of the Client which may be incurred in relation to the Service, including:
</li>
<li>Damage resulting from any changes which the Service Provider may make to the Service;

</li>
<li>Damage resulting from any permanent or temporary interruption in the provision of the Service;
</li>
<li>Damage resulting from deletion of, corruption of, or failure to store, any Client's Content;
</li>
<li>Damage resulting from the Client's failure to provide the Service Provider with accurate account information;
</li>
<li>Damage resulting from the Client's failure to keep the Client's password or the details of the Client's Account secure and confidential.
</li>
<li>You must not modify, adapt, or hack the Service or modify another website so as to falsely imply that it is associated with the Service.
</li>
<li>You understand that the service provider uses third-party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service.
</li>
<li>You agree not to reproduce, duplicate, copy, sell, resell, or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission of the Service Provider.
</li>
<li>You must indemnify the Service Provider, its officers, directors, employees, agents, licensors, suppliers, etc., for any and all claims, liabilities, losses, expenses, damage, and costs, including attorney's fees, resulting from the breach of the Agreement and from the activities on your Account.
</li>
<li>We may, but have no obligation to, remove Content and Accounts containing Content that we determine in our sole discretion are unlawful, offensive, threatening, libelous, defamatory, pornographic, obscene, or otherwise objectionable or violates any party's intellectual property or these Terms & Conditions.
</li>
<li>You agree that the Service Provider may provide you with notices, including those regarding changes to the Agreement, by email, regular mail, or postings on the Service.
</li>
<li>You understand that the technical processing and transmission of the Service,
</li>
<li>You must not upload, post, host, or transmit unsolicited email, SMSs, or "spam" messages.
</li>
<li>You must not transmit any worms or viruses or any code of a destructive nature.
</li>
<li>Nothing in the Agreement shall exclude or limit the Service Provider's liability for damage which may not be lawfully excluded or limited by the laws of the Republic of India.
</li>
<li>The failure of a service provider to exercise or enforce any right or provision of the Terms & Conditions shall not constitute a waiver of such right or provision. The Terms & Conditions constitutes the entire agreement between you and the service provider and govern your use of the Service, superseding any prior agreements between you and the service provider (including, but not limited to, any prior versions of the Terms & Conditions).
</li>
<li>The Agreement is governed by the laws of the Republic of India.
</li>
<li>In case the Agreement has been translated into some other language than English and there are contradictions between the English version and the translation, then the English version shall prevail over the translation.
</li>
<li>Any disputes related to the Agreement are to be solved by means of negotiations.
</li>
<li>Questions about the Terms & Conditions should be sent to support at vidyamate dot in.
</li>
</ul>
    </div>

)
}

export default TermsAndCondition
