import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";
import {
    CREATE_WORKSHEET_REQUIST,
    CREATE_WORKSHEET_SUCCESS,
    CREATE_WORKSHEET_FAIELD,
    SHOW_WORKSHEET_REQUIST,
    SHOW_WORKSHEET_SUCCESS,
    SHOW_WORKSHEET_FAIELD,
    DELETE_WORKSHEET_REQUIST,
    DELETE_WORKSHEET_SUCCESS,
    DELETE_WORKSHEET_FAIELD,
} from "../../Constants/TeacherDesk/WorkSheet";

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};
const configForm = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "multipart/form-data",
  },
};

export const CreateUpdateHomeWork =  (body,handleLoader,handleWorkSheetClear) => {
  return async (dispatch) => {  
    try {
          
          dispatch({ type: CREATE_WORKSHEET_REQUIST });
          const response = await axios.post(
            `${BASE_URL}add_update_homeworkapi/`,
            body,
            configForm
          );
            
          const data = response.data;   
          if (response.data.status === 200) {         
                  handleLoader();   
                  handleWorkSheetClear();   
                  dispatch({ type: CREATE_WORKSHEET_SUCCESS, payload: response.data });  
                  Swal.fire({
                      text: response.data.msg,
                      icon: "success",
                      imageAlt: "success image",
                      allowOutsideClick: false 
                  }).then((result) => {
                    if (result.isConfirmed) {
                      console.log(response.data);
                    }
                  });
          } else {
                  handleLoader();         
                  Swal.fire({
                    text: response.data.msg,
                    icon: "error",
                    imageAlt: "error image",
                    allowOutsideClick: false 
                  }).then((result) => {
                    if (result.isConfirmed) {
                      console.log(response.data);
                    }
                  });
          }
          return response.data;
        } catch (error) {
                handleLoader();         
                dispatch({ type: CREATE_WORKSHEET_FAIELD, payload: error });
        }
    }
};


export const GetHomeWorkList = (body) => async (dispatch) => {
  try {
    dispatch({ type: SHOW_WORKSHEET_REQUIST });
    const response = await axios.post(
      `${BASE_URL}get_homework_api/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: SHOW_WORKSHEET_SUCCESS, payload: data });
    if (response.data.status === 200) {
      // console.log(data);
    }
  } catch (error) {
    dispatch({ type: SHOW_WORKSHEET_FAIELD, payload: error });
  }
};


export const DeleteWorkSheet = (body,handleLoader) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_WORKSHEET_REQUIST });
    const response = await axios.post(
      `${BASE_URL}delete_homework/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: DELETE_WORKSHEET_SUCCESS, payload: data });
    if (response.data.status === 200) {
            handleLoader();   
            Swal.fire({
              text: response.data.msg,
              icon: "success",
              imageAlt: "success image",
              allowOutsideClick: false 
            }).then((result) => {
              if (result.isConfirmed) {
                console.log(response.data);
              }
            });
    }else{
            handleLoader();         
            Swal.fire({
              text: response.data.msg,
              icon: "error",
              imageAlt: "error image",
              allowOutsideClick: false 
            }).then((result) => {
              if (result.isConfirmed) {
                console.log(response.data);
              }
            });
    }
  } catch (error) {
    dispatch({ type: DELETE_WORKSHEET_FAIELD, payload: error });
  }
};




