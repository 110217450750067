import React, { useEffect, useState } from "react";
import { Card, CardBody } from "reactstrap";
import CustomScrollbar from "../../CommonComponent/PerfectScrollbar";
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";
import { FaBell, FaFilePdf } from "react-icons/fa";
import { GiDuration } from "react-icons/gi";
import { convertDateIntoString } from "../../CommonComponent/DateFormatUtil";
import { MEDIA_BASE_URL } from "../../../Config/BaseUrl";
import { padWithZero } from "../../CommonComponent/helper";
import { Slide } from 'react-slideshow-image';
import 'react-slideshow-image/dist/styles.css';
import './SlideShow.css';


export const CustomCard = ({ heading, count, colorBg, nameClass, img }) => {
  return (
    <Card
      className={`${nameClass}`}
      style={{ backgroundColor: colorBg, color: "#fff" }}
    >
      <CardBody>
        <div className="d-flex justify-content-around card-hover">
          <div className="">
            <h6
              className="mb-2"
              style={{ fontWeight: "bold", fontSize: "1.3rem" }}
            >
              {heading}
            </h6>
            <h5
              className="mb-0"
              style={{ fontWeight: "bold", fontSize: "1.3rem" }}
            >
              { padWithZero(count ?? "0") }
            </h5>
          </div>
          <div className="">
            <div
              className="rounded-circle p-1"
              style={{ backgroundColor: "rgba(0,0,0,0.1)" }}
            >
              <img src={img} alt="pre" width={59} />
              {/* <span style={{ fontSize: "1.9rem" }} className="p-2">
                  <PiStudentBold />
                </span> */}
              {/* <i className="fa fa-users" aria-hidden="true"></i> */}
            </div>
          </div>
        </div>
      </CardBody>
    </Card>
  );
};

export const ScrollBar = ({ children, customHeight }) => {
  return (
    <>
      <CustomScrollbar
        style={{
          maxHeight: customHeight ? customHeight : "30vh",
          paddingRight: "0.8rem",
          boxSizing: "content-box",
        }}
      >
        <div className="table-responsive">{children}</div>
      </CustomScrollbar>
    </>
  );
};

export const TopPerformers = ({ top_performance }) => {
  return (
    <>
      <ScrollBar>
        {top_performance?.length > 0 ? (
          <table className="table table-sm table-hover table-bordered text-nowrap small-table ">
            <thead className="thead-light">
              <tr className="text-center">
                <th>Student ID</th>
                <th>Name</th>
                <th>Class</th>
                <th>Percentage</th>
              </tr>
            </thead>
            <tbody>
              {top_performance?.map((tp,index) => (
                <tr key={index}>
                  <td>{tp.student_code}</td>
                  <td>{tp.name}</td>
                  <td>{tp.section}</td>
                  <td>{tp.percentage} %</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyListMessage
            message={"Students Not Available !"}
            Div="div"
            size={"1.2rem"}
          />
        )}{" "}
      </ScrollBar>
    </>
  );
};

export const Notice = ({ circulars }) => {
  return (
    <>
      <ScrollBar>
        {circulars?.length > 0 ? (
          <div className="">
            {circulars?.map((d, index) => (
              <>
                <div className="text-left" key={index}>
                  {" "}
                  <span className="pr-2" style={{ color: "green" }}>
                    <FaBell />
                  </span>
                  {d.main_title}
                  <br />
                  {d.from_date && (
                    <b>
                      <span className="pr-2" style={{ color: "#ffb64d" }}>
                        <GiDuration />
                        <span
                          style={{
                            fontSize: "0.7rem",
                          }}
                        >
                          {`${convertDateIntoString(d.from_date)} to
                              ${convertDateIntoString(d.to_date)}`}
                        </span>
                      </span>
                      <br />
                    </b>
                  )}
                  <span>
                    {d?.circular && (
                      <>
                        <small>Please See the Attached Notice</small>
                        <a
                          href={`${MEDIA_BASE_URL}${d.circular}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          <FaFilePdf
                            style={{
                              fontSize: "0.8rem",
                            }}
                            className="text-danger mx-1"
                          />
                        </a>
                      </>
                    )}
                  </span>
                </div>
                <hr className="mt-1" />
              </>
            ))}
          </div>
        ) : (
          <EmptyListMessage
            message={"Circular Not Available !"}
            Div="div"
            size={"1.2rem"}
          />
        )}
      </ScrollBar>
    </>
  );
};

// export const PresidantMessage = ({ data }) => {
 
//   return (
//     <>
//       <ScrollBar customHeight={"60vh"}>
//         {data?.length > 0 ? (
//           <div dangerouslySetInnerHTML={{ __html: data }} />
//         ) : (
//           <EmptyListMessage
//             message={"President Message Not Available!"}
//             Div="div"
//             size={"1.2rem"}
//           />
//         )}
//       </ScrollBar>
//     </>
//   );
// };

export const PresidantMessage = ({ presidentData }) => {
  const { president_message, president_image, president_introduction , TeacherContainerHeight} = presidentData;

  let height1 = ""
    if(TeacherContainerHeight){
      height1 = TeacherContainerHeight
    }

  const displayContent = () => {
  
    if (!president_message || president_message === "<p><br></p>") {
      return (
        <>
        
        <EmptyListMessage
          message={"Principal's Message Not Available!"}
          Div="div"
          size={"1.5rem"}
        />
        </>
      );
    }
  
    return (
      <div className="president-content">
        <div className="row ">
          <div className="col-8">
          <div className="president-introduction" dangerouslySetInnerHTML={{ __html:president_introduction }} /> 
          </div>
          <div className="col-4">
            <img src={president_image} alt="President" className="president-image float-end imgshadow mb-3  text-wrap"  height={120} width={120} style={{ borderRadius: '50%' }} />
          </div>
        
          
        </div>

    
        <div className="row">
          <div className="col-md-12">
            <div dangerouslySetInnerHTML={{ __html:`<span style="font-size: 30px;">“</span>${president_message}<span style="font-size: 30px;">”</span>`}} />
          </div>
        </div>
      </div>
    );
  };

// const isMobile = window.innerWidth <= 768; 
  return (
    <ScrollBar customHeight={`80vh`}>
      {displayContent()}
    </ScrollBar>
  );
};



// export const Slideshow = ({ images }) => {
//   const [text ,setText] = useState(false)


//   useEffect(() => {
//     if(window.innerWidth < 400){
//       setText(true) 
//   }
//   }, [])


//   if (!images || images.length === 0) {
//     return null;
//   }
//   const slideImages = images.map(
//     (image) => `${MEDIA_BASE_URL}/${image.banner_image}`
//   );
//   const properties = {
//     duration: 2000,
//     transitionDuration: 1500,
//     arrows: true,
//   };

 
//   // Pad the images array to ensure it has enough elements for three images per row
//   const paddedImages1 = images.concat(images.slice(0, 3 - (images.length % 3)));

//   return (
//     <>
//     {text ? (
//     <div className="slide-container1">
//       <Slide {...properties}>
//         {slideImages.map((image, index) => (
//           <div key={index} className="each-slide1">
//             <div style={{ backgroundImage: `url(${image})` }} />
//           </div>
//         ))}
//       </Slide>
//     </div>):(
//     <div className="slide-container">
//       <Slide {...properties}>
//         {Array.from({ length: Math.ceil(paddedImages1.length / 3) }).map((_, slideIndex) => {
//           const startIndex = slideIndex * 3;
//           const slideImages = paddedImages1.slice(startIndex, startIndex + 3);

//           return (
//             <div key={slideIndex} className="each-slide">
//               <div className="image-container">
//                 {slideImages.map((image, idx) => (
//                   <div key={idx} className="image-wrapper">
//                     <img src={`${MEDIA_BASE_URL}/${image.banner_image}`} alt={`Slide ${slideIndex + 1}`} className="slide-image" />
//                   </div>
//                 ))}
//               </div>
//             </div>
//           );
//         })}
//       </Slide>
//     </div>
//   )} 
//   </>
//   );
 
// };


export const Slideshow = ({ images }) => {
  const [text ,setText] = useState(false)


  useEffect(() => {
    setText(window.innerWidth > 400);
  }, []);


  if (!images || images.length === 0) {
    return null;
  }
  const slideImages = images.map(
    (image) => `${MEDIA_BASE_URL}/${image.banner_image}`
  );
  const properties = {
    duration: 2000,
    transitionDuration: 1500,
    arrows: true,
  };

 
  const paddedImages1 = images.concat(images.slice(0, 3 - (images.length % 3)));

  return (
    <>
    {/* {text ? (
    <div className="">
     { console.log("i am slide")}
      <Slide {...properties}>
        {slideImages.map((image, index) => (
          <div key={index} className="">
            <div style={{ backgroundImage: `url(${image})` }}/>
          </div> 
        ))}
      </Slide>
    </div>
    ):( */}
    <div className="slide-container">
   
      <Slide {...properties}>
        {Array.from({ length: Math.ceil(paddedImages1.length / 3) }).map((_, slideIndex) => {
          const startIndex = slideIndex * 3;
          const slideImages = paddedImages1.slice(startIndex, startIndex + 3);

          return (
            <div key={slideIndex} className="each-slide">
              <div className="image-container">
                {slideImages.map((image, idx) => (
                  <div key={idx} className="image-wrapper">
                    <img src={`${MEDIA_BASE_URL}/${image.banner_image}`} alt={`Slide ${slideIndex + 1}`} className="slide-image" />
                  </div>
                ))}
              </div>
          
            </div>
          );
        })}
      </Slide>
    </div>
  {/* )}  */}
  </>
  );
 
};



// export const PresidantMessage = ({ data }) => {
//   console.log(data); // Log the base64-encoded data (optional)

//   function displayBase64Image(base64ImageString) {
//     try {
//       // Create a data URI with appropriate MIME type
//       const imageDataUrl = `data:image/png;base64,${base64ImageString}`;

//       // Construct and return the image element
//       return <img src={imageDataUrl} alt="Decoded Image" />;
//     } catch (error) {
//       // Handle decoding errors gracefully
//       console.error("Error decoding base64 image:", error);
//       return <EmptyListMessage message={"Image Decode Failed!"} Div="div" size={"1.2rem"} />;
//     }
//   }

//   if (data) {
//     // Attempt to decode the data as an image
//     const image = displayBase64Image(data);

//     return (
//       <ScrollBar customHeight={"60vh"}>
//         {/* Display the image if decoding was successful, otherwise display the text content */}
//         {image ? (
//           image
//         ) : (
//           <div dangerouslySetInnerHTML={{ __html: data }} />
//         )}
//       </ScrollBar>
//     );
//   } else {
//     // Handle the case when President Message is not available
//     return (
//       <EmptyListMessage
//         message={"President Message Not Available!"}
//         Div="div"
//         size={"1.2rem"}
//       />
//     );
//   }
// };
export const BirthdayList = ({ data }) => {
  return (
    <>
      {" "}
      <ScrollBar>
        {data && data.length > 0 ? (
          <table className="table table-sm table-hover table-bordered small-table text-nowrap ">
            <thead className="thead-light">
              <tr className="table-dark">
                {/* <th>Sr No</th> */}
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((staff, index) => (
                <tr key={index}>
                  {/* <td className="text-center">{index + 1}</td> */}
                  <td className="text-capitalize">{`${staff.first_name} ${staff.last_name}`}</td>
                </tr>
              ))}
            </tbody>
          </table>
        ) : (
          <EmptyListMessage
            message={"Birthday Not Available !"}
            Div="div"
            size={"1.2rem"}
          />
        )}
      </ScrollBar>
    </>
  );
};
export const StudentBirthdayList = ({ data }) => {
  return (
    <>
      <ScrollBar>
        {data && data.length > 0 ? (
          <table className="table table-sm table-bordered small-table text-nowrap ">
            <thead className="thead-light">
              <tr className="table-dark">
                {/* <th>Sr No</th> */}
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((studentGroup, sIndex) =>
                studentGroup?.map((stu, index) => (
                  <tr key={index}>
                    {/* <td className="text-center">{sIndex * data[0].length + index + 1}</td> */}
                    <td>
                      {" "}
                      <span className="text-capitalize">
                        {`${stu.fk_student__std_name}`}
                      </span>
                      <small>{`(${stu.fk_section__fk_class__class_name}${stu.fk_section__section})`}</small>
                    </td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        ) : (
          <EmptyListMessage
            message={"Birthday Not Available !"}
            Div="div"
            size={"1.2rem"}
          />
        )}
      </ScrollBar>
    </>
  );
};

export const TestScheduleList = ({ data }) => {
  return (
    <>
      <ScrollBar>
        {data && data.length > 0 ? (
          <table className="table table-sm table-bordered text-nowrap small-table">
            <thead className="thead-light text-center">
              <tr className="table-dark">
                <th>Sr No</th>
                <th>Test Name</th>
                <th>Start Date & Time</th>
                <th>Class</th>
              </tr>
            </thead>
            <tbody>
              {data?.map((testGroup, groupIndex) =>
                testGroup?.map((test, index) => (
                  <tr key={`${groupIndex}-${index}`}>
                    <td className="text-center">
                      {groupIndex * data[0].length + index + 1}
                    </td>
                    <td>{test.test_name}</td>
                    <td>{new Date(test.start_datetime).toLocaleString()}</td>
                    <td>{`${test.fk_class__class_name}`}</td>
                  </tr>
                ))
              )}
            </tbody>
          </table>
        ) : (
          <EmptyListMessage
            message={"Test Schedule Not Available!"}
            Div="div"
            size={"1.2rem"}
          />
        )}
      </ScrollBar>
    </>
  );
};

export const Table = () => {
  return (
    <>
      <div class="table-responsive" style={{ height: "310px" }}>
        <table class="table card-table table-vcenter text-nowrap table-striped mb-0">
          <tbody>
            <tr>
              <th>No.</th>
              <th>Name</th>
              <th></th>
              <th>Marks</th>
              <th>%AGE</th>
            </tr>
            <tr>
              <td>11</td>
              <td class="w40">
                <div class="avatar">
                  <img
                    class="avatar"
                    src="../assets/images/xs/avatar1.jpg"
                    alt="avatar"
                  />
                </div>
              </td>
              <td>
                <div>Merri Diamond</div>
                <div class="text-muted">Science</div>
              </td>
              <td>199</td>
              <td>99.00</td>
            </tr>
            <tr>
              <td>23</td>
              <td class="w40">
                <div class="avatar">
                  <img
                    class="avatar"
                    src="../assets/images/xs/avatar2.jpg"
                    alt="avatar"
                  />
                </div>
              </td>
              <td>
                <div>Sara Hopkins</div>
                <div class="text-muted">Mechanical</div>
              </td>
              <td>197</td>
              <td>98.00</td>
            </tr>
            <tr>
              <td>41</td>
              <td class="w40">
                <div class="avatar">
                  <img
                    class="avatar"
                    src="../assets/images/xs/avatar3.jpg"
                    alt="avatar"
                  />
                </div>
              </td>
              <td>
                <div>Allen Collins</div>
                <div class="text-muted">M.C.A.</div>
              </td>
              <td>197</td>
              <td>98.00</td>
            </tr>
            <tr>
              <td>17</td>
              <td class="w40">
                <div class="avatar">
                  <img
                    class="avatar"
                    src="../assets/images/xs/avatar4.jpg"
                    alt="avatar"
                  />
                </div>
              </td>
              <td>
                <div>Erin Gonzales</div>
                <div class="text-muted">Arts</div>
              </td>
              <td>194</td>
              <td>97.00</td>
            </tr>
            <tr>
              <td>57</td>
              <td class="w40">
                <div class="avatar">
                  <img
                    class="avatar"
                    src="../assets/images/xs/avatar5.jpg"
                    alt="avatar"
                  />
                </div>
              </td>
              <td>
                <div>Claire Peters</div>
                <div class="text-muted">Science</div>
              </td>
              <td>192</td>
              <td>95.00</td>
            </tr>
            <tr>
              <td>85</td>
              <td class="w40">
                <div class="avatar">
                  <img
                    class="avatar"
                    src="../assets/images/xs/avatar6.jpg"
                    alt="avatar"
                  />
                </div>
              </td>
              <td>
                <div>Claire Peters</div>
                <div class="text-muted">Science</div>
              </td>
              <td>192</td>
              <td>95.00</td>
            </tr>
            <tr>
              <td>9</td>
              <td class="w40">
                <div class="avatar">
                  <img
                    class="avatar"
                    src="../assets/images/xs/avatar7.jpg"
                    alt="avatar"
                  />
                </div>
              </td>
              <td>
                <div>Claire Peters</div>
                <div class="text-muted">Science</div>
              </td>
              <td>191</td>
              <td>95.00</td>
            </tr>
          </tbody>
        </table>
      </div>
    </>
  );
};
