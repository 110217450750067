import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
import Swal from "sweetalert2";
import {
  GET_CLASS_REQUEST,
  GET_CLASS_SUCCESS,
  GET_CLASS_FAILURE,
  GET_SECTION_REQUEST, 
  GET_SECTION_SUCCESS,
  GET_SECTION_FAILURE,
  GET_STUDENT_REPORT_REQUEST,
  GET_STUDENT_REPORT_SUCCESS,
  GET_STUDENT_REPORT_FAILURE,
  BULK_STUDENT_UPLOAD_REQUEST,
  BULK_STUDENT_UPLOAD_SUCCESS,
  BULK_STUDENT_UPLOAD_FAILURE,
} from "../../Constants/Admission/StudentReport";

const token = localStorage.getItem("token");

const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};



export const getClass = (body) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CLASS_REQUEST });
      const response = await axios.post(
        `${BASE_URL}get_class_api/`,
        body,
        config
      );
      const data = response.data;
      dispatch({ type: GET_CLASS_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_CLASS_FAILURE,
        payload: error,
      });
    }
  };
};

export const getSection = (body) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SECTION_REQUEST });
      const response = await axios.post(
        `${BASE_URL}get_section_api/`,
        body,
        config
      );
      const data = response.data;
      dispatch({ type: GET_SECTION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_SECTION_FAILURE,
        payload: error,
      });
    }
  };
};

export const getStudentAllReport = (body) => {
  return async (dispatch) => {
    try { 
      dispatch({ type: GET_STUDENT_REPORT_REQUEST });
      const response = await axios.post(
        `${BASE_URL}student_report_api/`,
        body,
        config
      );
      const data = response.data;
      dispatch({ type: GET_STUDENT_REPORT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_STUDENT_REPORT_FAILURE,
        payload: error,
      });
    }
  };
};
     
export const getStudentAllReport12 = {
  getStudentAllReport1  : async (data) => {
    var axiosConfig = createAxiosConfig("student_report_api", "POST", data);
    return await axios(axiosConfig);
  },
}

export const bulkStudentUpload = (body, handleClear, setLoading) => {
  return async (dispatch) => {
    try {
      dispatch({ type: BULK_STUDENT_UPLOAD_REQUEST });
      const response = await axios.post(
        `${BASE_URL}bulk_student_admission_api/`,
        body,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      const data = response.data;
      dispatch({ type: BULK_STUDENT_UPLOAD_SUCCESS, payload: data });
      if (data?.status === 200) {
        Swal.fire({
          text: data?.msg,
          icon: "success",
          imageAlt: "success image",
        });
        setLoading(false);
        handleClear();
      } else {
        Swal.fire({
          text: data?.msg,
          icon: "error",
          imageAlt: "error image",
        });
        setLoading(false);
      }
    } catch (error) {
      dispatch({
        type: BULK_STUDENT_UPLOAD_FAILURE,
        payload: error,
      });
    }
  };
};
