import React from "react";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeeList,
  getClassSectionList,
  getFeeListStructure,
} from "../../../actions/super-user/SchoolRegistration";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
import { getMasterData } from "../../../actions/super-user/MasterData.js";
import Loader from "../../Loader/Loader.js";
import { useContext } from "react";
import {
  UpdateAdmission,
  addAdmission,
  getAdmissionCount,
  getStudentName,
} from "../../../actions/Admission/AdmissionAction";
import { AttendanceContext } from "../../../context/attendanceContext";
import useSessionRedirect from "../Comman/SessionChange";
import ValidationComponent from "../Comman/Validation.js";
import ErrorDisplay from "../../CommonComponent/ErrorDisplay.js";
import RegisterStudentList from "../Comman/RegisterStudentList.js";
import AllStudentList from "../Comman/AllStudentList.js";
import useEnsureId from "../Comman/useEnsureId.js";

const Admission = (props) => {
  const token = localStorage.getItem("token");
  const uid = useEnsureId();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { user_type, id, name, user_detail } = school_user;
  const { school_emp_id, fk_school_id } = user_detail;

  const session_id = useSessionRedirect();
  const [getConcessionList, setGetConcessionList] = useState([]);
  const [getScholarshipList, setGetScholarshipList] = useState([]);
  const [getDiscountReason, setGetDiscountReason] = useState([]);
  const [selectDiscountReason, setSelectDiscountReason] = useState(null);
  const [student_uid, setstudent_uid] = useState(uid);
  const [discount_authority, setdiscount_authority] = useState("");
  const [isFeeBalance, setIsFeeBalance] = useState(null);

  const { loading } = useSelector((state) => state.schoolRegister);
  const { feeStructure } = useSelector((state) => state.schoolFee);
  const { classlist, classsection } = useSelector(
    (state) => state.classSection
  );
  const { masterData } = useSelector((state) => state.masterData);
  const { studentName, errorCode, message } = useSelector(
    (state) => state.studentName
  );
  const { admissionCount } = useSelector((state) => state.admissionCount);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [classSelect, setClassSelect] = useState(null);
  const { classListOption } = useContext(AttendanceContext);

  const connessionList = getConcessionList?.map((cl) => {
    return {
      label: cl.value,
      value: cl.id,
    };
  });
  const scholarshipList = getScholarshipList?.map((cl) => {
    return {
      label: cl.value,
      value: cl.id,
    };
  });

  const discountReasonList = getDiscountReason?.map((cl) => {
    return {
      label: cl.value,
      value: cl.id,
    };
  });

  let handleclassselect = (e) => {
    setClassSelect(e);
  };

  function handleSelectconnession(data) {
    setSelectconnession(data);
  }
  if (
    classsection?.status === 403 ||
    classsection?.status === 503 ||
    classsection?.status === 200
  ) {
    if (classsection?.status === 200) {
      alert.success(classsection.msg);
    } else {
      alert.error(classsection.msg);
    }
  }

  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token));
    }
  }, [dispatch, token, session_id]);

  useEffect(() => {
    setGetConcessionList(
      masterData?.payload?.filter((obj) => obj.name === "Concession")
    );
    setGetScholarshipList(
      masterData?.payload?.filter((obj) => obj.name === "Scholarship")
    );
    setGetDiscountReason(
      masterData?.payload?.filter((obj) => obj.name === "DiscountReason")
    );
  }, [masterData, session_id]);

  let feeId = {
    school_id: Number(user_detail.fk_school_id),
  };

  useEffect(() => {
    if (token) {
      dispatch(getFeeList(token, feeId));
    }
  }, [dispatch, token, session_id]);
  useEffect(() => {
    if (token) {
      dispatch(getClassSectionList(token, feeId));
    }
  }, [dispatch, token, session_id]);
  const [tableData1, setTableData1] = useState([]);
  console.log(tableData1, "tableData1");
  const [isFeeMainId, setIsFeeMainId] = useState([]);
  const [selectconnession, setSelectconnession] = useState(null);
  const [selectScholarship, setSelectScholarship] = useState(null);
  const [selectclassSection, setSelectclassSection] = useState(null);
  const [isReset, setIsReset] = useState(false);
  const [EnableFields, setEnableFields] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [UserReset ,setUserReset] = useState(false);

  let c_id = selectclassSection?.value;
  let s_id = selectclassSection?.section;
  let con_id = selectconnession?.value;

  let fdata = {
    fk_section: classSelect?.value || "",
    fk_concession: con_id || "",
    fk_academic_session: session_id,
    school_id: Number(user_detail.fk_school_id),
  };

  useEffect(() => {
    if (token) {
      dispatch(getFeeListStructure(token, fdata));
    }
  }, [token, c_id, con_id, session_id]);

  useEffect(() => {
    setIsFeeMainId(feeStructure?.main_id);
      setTableData1(feeStructure?.payload);
    // if (AdmissionData?.id) {
    // } else {
    //   setIsFeeMainId(feeStructure?.main_id);
    //   setTableData1(feeStructure?.payload);
    // }
  }, [feeStructure, session_id]);

  let nameS = studentName?.payload.name;
  if (student_uid === "") {
    nameS = "";
  } else if (student_uid !== studentName?.payload.student_code) {
    nameS = "";
  }

  function getName() {
    const body = {
      fk_academic: session_id,
      student_code: student_uid,
      school_id: Number(user_detail.fk_school_id),
      for_type: "Admission",
    };
    dispatch(getStudentName(body, handleClear));
  }

  useEffect(() => {
    getName();
  }, [uid, student_uid]);

  useEffect(() => {
    if (nameS) {
      setstudent_uid("");
      setAdmissionData([]);
      setadmissionDate();
      setTableData1([]);
      setSelectconnession();
      setClassSelect();
      setSelectScholarship(null);
      setSelectDiscountReason(null);
    }
  }, [session_id]);

  useEffect(() => {
    if (token && student_uid) {
      const body = {
        fk_academic: session_id,
        section_id: classSelect?.value,
      };
      dispatch(getAdmissionCount(body));
    }
  }, [session_id, classSelect]);
  let date = new Date().toISOString().substr(0, 10);
  const [admissionDate, setadmissionDate] = useState(date);

  const handleCheckboxChange1 = (index, check) => {
    const newData = [...tableData1];
    newData[index].checked = !newData[index].checked;
    setTableData1(newData);
    handleStatusChange1(index, check);
  };

  const handleStatusChange1 = (index, checkboxType) => {
    const newData = [...tableData1];
    if (checkboxType === true) {
      newData[index].checked = true;
    } else {
      newData[index].checked = false;
    }
    setTableData1(newData);
  };

  const handleInputChange1 = (index, field, value) => {
    const newData = [...tableData1];
    newData[index][field] = value;
    setTableData1(newData);
  };

  const [AdmissionData, setAdmissionData] = useState({
    admission_id: "",
    fk_academic: session_id,
    section_id: classSelect?.value,
    student_id: studentName?.payload.id,
    fk_concession: con_id,
    admission_date: admissionDate || new Date().toISOString().substr(0, 10),
  });
  const { validationError, validateControl } = ValidationComponent({
    data: { student_uid, classSelect, selectconnession },
    requiredFields: ["student_uid", "classSelect", "selectconnession"],
  });
  const makeAdmission = async () => {
    const isValid = await validateControl();
    if (!isValid) return;

    const dataArray = tableData1?.filter((row) => row.checked);
    const fees_data = dataArray?.map(
      ({
        checked,
        fees_head_name,
        fees_head_code,
        admission_type,
        id,
        ...rest
      }) => rest
    );

    if (fees_data?.length === 0) {
      alert.error("Select Fee Heads");
    } else {
      fees_data?.map(
        ({
          installments,
          total_amount,
          student_fees_id,
          installment_amount,
        }) => {
          if (installments === "" || 0) {
            alert.error("Installments empty!");
          } else if (installment_amount === "") {
            alert.error("Amount empty!");
          } else {
            const body = {
              fk_academic: session_id,
              student_id: studentName?.payload.id,
              section_id: classSelect?.value,
              fk_concession: con_id,
              fk_scholership: selectScholarship,
              admission_date:
                admissionDate || new Date().toISOString().substr(0, 10),
              student_code: studentName?.payload.student_code,
              fees_data,
              discount_authority,
              insert_by: school_emp_id,
              fk_discount_reason_id: selectDiscountReason,
              is_reset : UserReset ? "True" : "False"
            };

            Swal.fire({
              title: "CONFIRMATION",
              text: "Do you want to Save Record?",
              icon: "question",
              showDenyButton: true,
              confirmButtonColor: "#3085d6",
              cancelButtonColor: "#f46a6a",
              confirmButtonText: "Yes",
              denyButtonText: `No`,
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                if (AdmissionData.id) {
                  dispatch(
                    UpdateAdmission(
                      { ...body, admission_id: AdmissionData.id },
                      navigate,
                      handleClear
                    )
                  );
                } else {
                  dispatch(addAdmission(body, navigate, handleClear));
                }
              } else if (result.isDenied) {
                navigate(`/school/student-admission`);
              }
            });
          }
        }
      );
    }
  };

  useEffect(() => {
    if (errorCode === 500 && student_uid) {
      alert.error(message);
      setAdmissionData([]);
      setadmissionDate();
      setTableData1([]);
      setSelectconnession(null);
      setSelectScholarship(null);
      setSelectDiscountReason(null);
      setdiscount_authority(null);
      setClassSelect(null);
    } else if (errorCode === 403 && student_uid) {
      alert.error(message);
      setAdmissionData([]);
      setadmissionDate();
      setTableData1([]);
      setSelectconnession(null);
      setSelectScholarship(null);
      setSelectDiscountReason(null);
      setClassSelect(null);
      setdiscount_authority(null);
    } else {
      setIsReset(studentName?.payload?.fee_receipt_ccunt === 0);
      setAdmissionData(studentName?.payload?.admission_data);
      setIsFeeBalance(studentName?.payload?.previous_year_balance);
      setadmissionDate(studentName?.payload?.admission_data?.admission_date);
      setTableData1(studentName?.payload?.student_fees_data);
      setSelectconnession(studentName?.payload?.consession_data);
      setClassSelect(studentName?.payload?.section_data);
      setSelectScholarship(
        studentName?.payload?.admission_data?.fk_scholership_id
      );
      setSelectDiscountReason(
        studentName?.payload?.admission_data?.fk_discount_reason_id
      );
      setdiscount_authority(
        studentName?.payload?.admission_data?.discount_authority
      );
    }
  }, [nameS, studentName, errorCode, message]);

  const handleClear = () => {
    setUserReset(false);
    setEnableFields(false);
    setIsReset(false);
    setstudent_uid("");
    setClassSelect(null);
    setClassSelect("");
    setSelectconnession(null);
    setSelectconnession("");
    setTableData1([]);
    setSelectScholarship(null);
    setSelectDiscountReason(null);
  };

  const installments = [];
  const installment_amount = [];
  const discount = [];
  const total_amount = [];
  const Sum_installments = () => {
    const sum = installments.reduce((acc, current) => acc + current, 0);
    return sum;
  };
  const Sum_installment_amount = () => {
    const sum = installment_amount.reduce((acc, current) => acc + current, 0);
    return sum;
  };
  const Sum_discount = () => {
    const sum = discount.reduce((acc, current) => acc + current, 0);
    return sum;
  };
  const Sum_total_amount = () => {
    const sum = total_amount.reduce((acc, current) => acc + current, 0);
    return sum;
  };

  if (isFeeBalance > 0 && nameS) {
    Swal.fire({
      text: "Previous Year Balance",
      icon: "warning",
      title: "ALERT",
      allowOutsideClick: false,
    });
  }
  const [search, setSearch] = useState("All");
  const handleSearchButtonClick = (searchValue) => {
    setSearch(searchValue);
  };

  const handleReset = () => {
    setEnableFields(false);
    setUserReset(true);
  };
  if (loading) return <Loader />;

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center m-1">
            <div className="header-action">
              <h1 className="page-title">Student Admission</h1>
            </div>
            <div className="input-group-append">
              <button
                type="button"
                className="btn btn-outline-danger mx-2"
                onClick={() => handleClear()}
              >
                {" "}
                <i className="fa fa-times px-1"></i>
                Clear
              </button>
              <AllStudentList
                setstudent_uid={setstudent_uid}
                setEnableFields={setEnableFields}
                setIsEdit={setIsEdit}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12 col-lg-12 col-sm-12">
              <div className="card">
                <form className="card-body">
                  <div className="form-group row">
                    <label className="col-lg-2 col-form-label">
                      Student Code <span className="text-danger">*</span> 
                    </label>
                    <div className="col-lg-2">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Code"
                          value={student_uid}
                          onChange={(e) => setstudent_uid(e.target.value)}
                        />
                        <div className="input-group-append">
                          <RegisterStudentList
                            setstudent_uid={setstudent_uid}
                            setEnableFields={setEnableFields}
                            setIsEdit={setIsEdit}
                          />
                        </div>
                        {validationError && !student_uid ? (
                          <ErrorDisplay errorMsg={"Student Code Requried !"} />
                        ) : null}
                      </div>
                    </div>
                    <label className="col-lg-2 col-form-label">
                      Student Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-2">
                      <input
                        type="text"
                        className="form-control"
                        value={nameS}
                        // onChange={(e) => setstdname(e.target.value)}
                        placeholder="Student Name"
                        disabled
                      />
                    </div>
                    <label className="col-lg-2 col-form-label">
                      Admission Date <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-2">
                      <input
                        type="date"
                        className="form-control"
                        value={admissionDate}
                        onChange={(e) => {
                          setadmissionDate(e.target.value);
                        }}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-lg-2 col-form-label">
                      Class Section <span className="text-danger">*</span>
                    </label>
                    {/* {AdmissionData?.std_class + " " + AdmissionData?.section} */}
                    <div className="col-lg-2">
                      <Select
                        options={classListOption}
                        placeholder="Select Class"
                        value={nameS && classSelect}
                        onChange={handleclassselect}
                        isDisabled={EnableFields}
                      />
                      {validationError && !classSelect ? (
                        <ErrorDisplay errorMsg={"Select Class Section !"} />
                      ) : null}
                    </div>
                    <label className="col-lg-2 col-form-label">
                      Concession <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-2">
                      <Select
                        options={connessionList}
                        placeholder="Concession"
                        value={nameS && selectconnession}
                        onChange={handleSelectconnession}
                        isDisabled={EnableFields}
                      />
                      {validationError && !selectconnession ? (
                        <ErrorDisplay errorMsg={"Select Concession !"} />
                      ) : null}
                    </div>
                    <label className="col-lg-2 col-form-label">
                      Admission Count <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-2 mt-2">
                      <strong className="m-auto">
                        {nameS && admissionCount?.admission_count}
                      </strong>
                    </div>
                  </div>{" "}
                  <div className="form-group row">
                    <label className="col-lg-2 col-form-label">
                      Scholarship <span className="text-danger"></span>
                    </label>
                    <div className="col-lg-4">
                      <Select
                        options={scholarshipList}
                        placeholder="Select Scholarship"
                        value={
                          nameS && selectScholarship
                            ? scholarshipList?.find(
                                (s) => s.value === selectScholarship
                              )
                            : null
                        }
                        onChange={(selectedOption) =>
                          setSelectScholarship(selectedOption?.value)
                        }
                        isDisabled={EnableFields}
                      />
                    </div>
                    {isReset && isEdit &&  (
                      <button
                        className="btn btn-outline-danger"
                        type="button"
                        onClick={handleReset}
                      >
                        {" "}
                        Reset <i class="fa fa-refresh "></i>
                      </button>
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="table-responsive card">
              <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
                <thead>
                  <tr>
                    <th></th>
                    <th>Sr.No.</th>
                    <th>Fee Heads</th>
                    <th className="text-center">Total Install</th>
                    <th className="text-center">Amount</th>
                    <th className="text-center">Discount</th>
                    <th className="text-center">Annual Fee Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {nameS &&
                    tableData1?.map((row, index) => {
                      row.total_amount =
                        row.installments * row.installment_amount;
                      row.annual_fee =
                        row.installments * row.installment_amount;
                      return (
                        <tr key={index}>
                          <td>
                            <input
                              type="checkbox"
                              checked={row.checked}
                              disabled={EnableFields}
                              onChange={(e) =>
                                handleCheckboxChange1(index, e.target.checked)
                              }
                            />
                          </td>
                          <td>{index + 1}</td>
                          <td>{row.fees_head_name}</td>
                          <td>
                            <input
                              disabled={EnableFields || !row.checked}
                              type="number"
                              className="form-control text-right"
                              value={row.installments}
                              onChange={(event) =>
                                handleInputChange1(
                                  index,
                                  "installments",
                                  event.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              disabled={EnableFields || !row.checked}
                              type="number"
                              className="form-control text-right"
                              value={row.installment_amount}
                              onChange={(event) =>
                                handleInputChange1(
                                  index,
                                  "installment_amount",
                                  event.target.value
                                )
                              }
                            />
                          </td>
                          <td>
                            <input
                              disabled={EnableFields || !row.checked}
                              type="text"
                              className="form-control  text-right "
                              value={row.discount}
                              onChange={(event) => {
                                if (
                                  /^-?\d*(\.\d*)?$/.test(event.target.value)
                                ) {
                                  const numericValue = Number(
                                    event.target.value
                                  );
                                  if (numericValue <= row.annual_fee) {
                                    if (numericValue < 0) {
                                      alert.info(
                                        "Amount can not be less than zero"
                                      );
                                    } else {
                                      handleInputChange1(
                                        index,
                                        "discount",
                                        numericValue.toString()
                                      );
                                    }
                                  } else {
                                    alert.info(
                                      "Discount can not be greater than Annual Fee Amount"
                                    );
                                  }
                                }
                              }}
                            />
                          </td>
                          <td>
                            <input
                              disabled
                              type="number"
                              className="form-control text-right"
                              value={row.annual_fee}
                              onChange={(event) => {
                                handleInputChange1(
                                  index,
                                  "annual_fee",
                                  event.target.value
                                );
                              }}
                            />
                          </td>
                          <span className="d-none">
                            {/* {installments.push(row.installments)} */}
                            {/* {installment_amount.push(row.installment_amount)} */}
                            {/* {discount.push(row.discount)} */}
                            {total_amount.push(row.annual_fee)}
                          </span>
                        </tr>
                      );
                    })}
                  <tr className="">
                    <th colSpan={6} className="text-right text-dark">
                      <h1 className="page-title">Total Amount</h1>
                    </th>
                    {/* <th colSpan={1} className="text-center">
                      {Sum_installments()}
                    </th>
                    <th colSpan={1} className="text-center">
                      {Sum_installment_amount()}
                    </th>
                    <th colSpan={1} className="text-center">
                      {Sum_discount()}
                    </th> */}
                    <th colSpan={1} className="text-right text-dark">
                      <h1 className="page-title">{Sum_total_amount()}</h1>
                    </th>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="row my-2">
              <div className="col-lg-6 ">
                <h6 className="m-1">
                  Discount Reason <span className="text-danger"></span>
                </h6>
              </div>
              <div className="col-lg-6">
                <Select
                  options={discountReasonList}
                  placeholder="Discount Reason"
                  value={
                    nameS && selectDiscountReason
                      ? discountReasonList?.find(
                          (s) => s.value === selectDiscountReason
                        )
                      : null
                  }
                  onChange={(selectedOption) =>
                    setSelectDiscountReason(selectedOption?.value)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-6">
                <h6 className="m-1">Discount Authority</h6>
              </div>
              <div className="col-lg-6">
                <input
                  className="form-control"
                  value={nameS && discount_authority}
                  placeholder="Authority name"
                  onChange={(e) => setdiscount_authority(e.target.value)}
                />
              </div>
            </div>
          </div>
          <div className="col-sm-12 justify-content-center align-item-center my-3">
            <button
              type="button"
              className="mr-1 btn btn-primary px-5"
              onClick={() => makeAdmission()}
            >
              + Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Admission;
