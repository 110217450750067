import React from "react";
import useLoading from "../../CommonComponent/useLoading";
import Loader from "../../Loader/Loader";
import www from "../Examination/GradeCard/www.png";

const SalarySlipCard = (props) => {
  const { userData, id, base64Image, loading, totalDays, salaryDate } = props;
  if (loading) return <Loader />;
  return (
    <div className="container" id={id} key={id}>
      <div className="Wholeborder p-1">
        <div></div>
        <div className="salary-slip">
          <div className="d-flex ">
            <div className="col-3 m-auto">
              {base64Image ? (
                <img
                  src={`data:image/png;base64,${base64Image}`}
                  alt="school_logo"
                  width={100}
                  height={100}
                  className=""
                  style={{ borderRadius: "50%" }}
                />
              ) : (
                <img
                  src={www}
                  alt="fallback_image"
                  width={100}
                  height={100}
                  style={{ borderRadius: "50%" }}
                />
              )}
            </div>

            {/* Column for the text in the center */}
            <div className="col-9">
              <div className="text-center col-8">
                <p className="font-weight-bold text-dark">
                  {userData?.fk_staff__fk_school__fk_organization__name}
                </p>
                <div className="font-weight-bold">
                  {userData?.fk_staff__fk_school__name}
                </div>
                <div>
                  <h6>{userData.fk_staff__fk_school__address} </h6>
                  <h6 style={{ fontSize: "0.7rem" }}>
                    Pincode- {userData.fk_staff__fk_school__pincode}, Ph:{" "}
                    {userData.fk_staff__fk_school__contact_number}
                  </h6>
                  <h6 style={{ fontSize: "0.7rem" }}>
                    E-mail: {userData.fk_staff__fk_school__contact_email}
                  </h6>
                </div>
                <div className="font-weight-bold">
                  {/* Salary Slip For {Date1} */}
                </div>
              </div>
            </div>
          </div>
        </div>

        <h6 className="text-center">Payslip for The Month of {salaryDate}</h6>
      </div>
      <div
        className="rlborder"
        style={{
          background: "lightgrey",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
        }}
      >
        <h6 className="text-center p-1 " style={{ marginBlock: "auto",  fontWeight: "bold"}}>
          Associate Information
        </h6>
      </div>

      <div className="rlborder">
        <div className="row" >
          <div className="col-12" style={{marginLeft:"1px"}}>
            <div className="">
              {" "}
              <table class="salary-slip-table">
                <tbody>
                  <tr>
                    <td class="label col-3">Associate Id</td>
                    <td class=" value col-3">{userData.fk_staff__emp_no}</td>
                    <td className="label col-3">Leaving Date</td>
                    <td className="value col-3">{userData?.fk_staff__leaving_date || " "}</td>
                  </tr>
                  <tr>
                    <td class="label col-3">Employee Name</td>
                    <td class="value col-3">
                      {userData.fk_staff__first_name +
                        " " +
                        userData.fk_staff__last_name}
                    </td>
                    <td class=" label col-4">
                     Available Calendar Days
                    </td>
                    <td class=" NewValues col-2">{totalDays}</td>
                    
                  </tr>
                  <tr>
                    <td class="label col-3">Designation</td>
                    <td class="value col-3">
                      {userData.fk_staff__fk_designation__value}
                    </td>
                    <td class=" label col-3">
                     Paid Days
                    </td>
                    <td class="NewValues col-3">
                      {userData?.presenty?.present}
                    </td>
                    
                  </tr>
                  <tr>
                  <td class="label col-3">Gender</td>
                    <td class="value col-3">
                      {userData.fk_staff__gender}
                    </td>
                    <td class=" label col-3">
                      Loss of Pay Days
                    </td>
                    <td class="NewValues col-3">
                      {userData?.presenty?.absent || 0}
                    </td>
                  </tr>
                  <tr>
                    <td class="label col-3">Date of Joining</td>
                    <td class="value col-3">
                      {userData.fk_staff__joining_date}
                    </td>
                  </tr>
                  <tr>
                    <td class="label col-3">PAN</td>
                    <td class="value col-3">{userData.fk_staff__pan_no}</td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="row">
          <div className="col-6 "style={{padding:"0px 0px 0px 4px"}}  >
            <div
              className="ml-1 d-flex justify-content-between"
              style={{ background: "lightgrey", borderTop: "2px solid black" }}
            >
              <h6 className="m-auto text-dark font-bold">Earnings</h6>
              <h6 className="m-auto text-dark font-bold">Amount</h6>
            </div>
            <div className="Boder"></div>
            <div className="" style={{padding:"0px"}}>
              <table className="table-custom">
                <tbody >
                  {userData &&
                    userData.earning?.map((s, index) => (
                      <tr key={index} >
                        <td className="label col-3 text-wrap" style={{fontSize:"13px"}}>
                          {s.heads_name}
                        </td>
                        <td className="value col-3">{s.amount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
          <div className="col-6 Bleft " style={{padding:"0px 5px 0px 0px"}}>
            <div
              className="mr-1 d-flex justify-content-between"
              style={{ background: "lightgrey", borderTop: "2px solid black" }}
            >
              <h6 className="m-auto text-dark font-bold">Deductions</h6>
              <h6 className="m-auto text-dark font-bold">Amount</h6>
            </div>
            <div className="Boder mr-1"></div>
            <div className="">
              <table className="table-custom">
                <tbody>
                  {userData &&
                    userData.deduction?.map((s, index) => (
                      <tr key={index}>
                        <td className="label col-3 text-wrap"  style={{marginRight:"10px" ,fontSize:"13px"}}>
                          {s.heads_name}
                        </td>
                        <td className="value col-3">{s.amount}</td>
                      </tr>
                    ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>

        <div className="Boder1"></div>
        <div className="row">
          <div className="col-6 p-0">
            <table className="customTable1">
              <tbody>
                <tr>
                  <td className="label">
                    (A) Total Earning 
                  </td>
                  <td className="value">{userData?.gross}</td>
                </tr>
              </tbody>
            </table>

            {/* <div className="Boder ml-2"></div> */}
            {/* <table className="table-custom">
              <tbody>
                <tr>
                  <td className=" label">
                    Fix Earning
                  </td>
                  <td className="value">
                    {userData?.fixedEarningsTotal || 0}
                  </td>
                </tr>
              </tbody>
            </table> */}
          </div>
          <div className="col-6 Bleft p-0">
            <table className="customTable1">
              <tbody>
                <tr>
                  <td className="label">
                     (B) Total Deduction
                  </td>
                  <td className="value ">{userData?.total_deduction}</td>
                </tr>
              </tbody>
            </table>

            <div className="Boder mr-2"></div>
            <table className="customTable1">
              <tbody>
                <tr>
                  <td className="label">
                     Net Salary = (A)-(B) 
                  </td>
                  <td className="value">{userData?.net}</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
        <div className="Boder1"></div>
        <div className="row">
          <div className="col-6">
            <p
              className="p-1 text-capitalize font-weight-bold"
              style={{ fontSize: "12px" }}
            >
              {/* In Word <span className="mx-2">:</span> {words} */}
            </p>
            <div className="p-1">
              {" "}
              <p className="">Report Generated Details</p>
              <p className=" font-weight-bold">
                Date <span className="mx-2">:</span>
                {userData?.created_date}
              </p>
            </div>
          </div>
          <div className="col-6">
            <p className="p-5 text-capitalize font-weight-bold text-right">
              Seal
            </p>
          </div>
        </div>
        <div>
          <p className="text-center text-dark font-weight-bold">
            This is a Computer Generated Copy , Seal & Signature is not Required
          </p>
        </div>
      </div>
    </div>
  );
};

export default SalarySlipCard;
