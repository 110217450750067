import React, { useState, useEffect } from "react";
import axios from "axios";
import "./d.css";
import { BASE_URL, MEDIA_BASE_URL } from "../../../Config/BaseUrl";
import Swal from "sweetalert2";
import { Row, Col, Card, CardBody } from "reactstrap";
import Dropzone from "react-dropzone";
import { FaTimes } from "react-icons/fa";
import { CiCirclePlus } from "react-icons/ci";
import ReactCrop from 'react-image-crop'
import 'react-image-crop/dist/ReactCrop.css'
import useImageSize from './useImageSize'


const IMG_BASEURL = MEDIA_BASE_URL;

const Banner = ({ token, schoolId }) => {
  const [selectedImages, setSelectedImages] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [loading, setLoading] = useState(false);





  const { dimensions, loading1, error } = useImageSize(selectedFiles[0]?.data);

 

  const [concatenatedArray, setConcatenatedArray] = useState([]);


 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(
          `${BASE_URL}get_schoolbannerImage/`,
          { school_id: schoolId },
          {
            headers: {
              Authorization: `Bearer ${token}`,
            },
          }
        );

        if (response.status === 200) {
          const existingImages = response.data.banner_image || [];

          const existingImagesFormatted = existingImages.map((image) => ({
            id: image.id,
            file: null,
            data: `${MEDIA_BASE_URL}${image.banner_image}`,
          }));

          setSelectedImages(existingImagesFormatted);
          setSubmitDisabled(false);
        } else {
          console.log("Request failed with status:", response.status);
        }
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, [schoolId, token]);

  const handleAcceptedFiles = async (files) => {
    const formattedFiles = await Promise.all(
      files.map(async (file) => {
        const base64Image = await convertToBase64(file);
        return {
          id: "",
          file: file,
          data: URL.createObjectURL(file),
          base64: base64Image,
        };
      })
    );

    setSelectedFiles([...selectedFiles, ...formattedFiles]);
   
  };


  const handleDrop = (acceptedFiles) => {


    const filteredFiles = acceptedFiles.filter(
      (file) =>
        (file.type === "image/jpeg" || file.type === "image/png") &&
        file.size <= 20 * 1024 * 1024
    );

    if (filteredFiles.length > 0) {
      handleAcceptedFiles(filteredFiles);
      setSubmitDisabled(false);
    } else {
      Swal.fire({
        text: "This file is not accepted!",
        icon: "warning",
        allowOutsideClick: false,
      });
    }
  };
 

  const handleRemoveImage = (index) => {
    const updatedFiles = [...selectedFiles];
    updatedFiles.splice(index, 1);
    setSelectedFiles(updatedFiles);

    if (updatedFiles.length === 0) {
      setSubmitDisabled(true);
    }
  };

  const deleteImage = async (index) => {
    const imageId = selectedImages[index].id;

    try {
      const response = await axios.post(
        `${BASE_URL}delete_banner_api/`,
        { banner_id: imageId },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = response.data;
      if (responseData.status === 200) {
        // Handle success
        Swal.fire({
          text: responseData.msg,
          icon: "success",
          allowOutsideClick: false,
        });
      } else {
        // Handle error
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error("Error calling delete API", error);
    }
  };

  const handleRemoveImageArray = async (index) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#d33",
      cancelButtonColor: "#3085d6",
      confirmButtonText: "Yes, remove it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        const updatedImages = [...selectedImages];
        updatedImages.splice(index, 1);

        await deleteImage(index);

        setSelectedImages(updatedImages);

        if (updatedImages.length === 0) {
          setSubmitDisabled(true);
        }
      }
    });
  };

  const convertToBase64 = (file) => {
    console.log(file)
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => resolve(reader.result.split(",")[1]);
      reader.onerror = (error) => reject(error);

      if (file instanceof Blob) {
        reader.readAsDataURL(file);
      } else {
        reject(new Error("Invalid file type"));
      }
    });
  };

  const handleSubmit = async () => {
    if(!loading1 && dimensions.height+10 >  dimensions.width){
      Swal.fire({
        text: "Please select a Landscape Image.",
        icon: "error",
        allowOutsideClick: false,
      })
      return;
     
    }
    if (selectedFiles.length === 0) {
      Swal.fire({
        text: "Please select at least one file.",
        icon: "warning",
        allowOutsideClick: false,
      });
      return;
    }

    setLoading(true);

    const imagesData = [];

    for (const file of selectedFiles) {
      imagesData.push({
        id: file.id,
        image: file.base64,
      });
    }

    const updatedConcatenatedArray = [
      ...selectedImages.map((image) => ({ id: image.id, image: "" })),
      ...imagesData,
    ];

    setConcatenatedArray(updatedConcatenatedArray);

    const payload = {
      banner_image: updatedConcatenatedArray,
      school_id: schoolId,
    };

    try {
      const response = await axios.post(
        `${BASE_URL}add_updateBannerImage/`,
        payload,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );

      const responseData = response.data;
      if (responseData.status === 200) {
        handleClear();
        Swal.fire({
          text: responseData.msg,
          icon: "success",
          allowOutsideClick: false,
        });

        try {
          const response = await axios.post(
            `${BASE_URL}get_schoolbannerImage/`,
            { school_id: schoolId },
            {
              headers: {
                Authorization: `Bearer ${token}`,
              },
            }
          );

          if (response.status === 200) {
            const existingImages = response.data.banner_image || [];

            const existingImagesFormatted = existingImages.map((image) => ({
              id: image.id,
              file: null,
              data: `${MEDIA_BASE_URL}${image.banner_image}`,
            }));

            setSelectedImages(existingImagesFormatted);
            setSubmitDisabled(false);
          } else {
            console.log("Request failed with status:", response.status);
          }
        } catch (error) {
          console.error("Error:", error);
        }
      } else {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error("Error uploading images", error);
    } finally {
      setLoading(false);
    }
  };

  const handleClear = () => {
    setSelectedFiles([]);
    setSubmitDisabled(true);
  };

  return (
    <>
      <div className="card">
        <div className="card-body">
          <form
            className=""
            onSubmit={(e) => {
              e.preventDefault();
              handleSubmit();
            }}
          >

            <div className="col-lg-12 col-md-12 col-sm-12 row">
              <div className="form-group col-lg-10 row">
                <label htmlFor="Banner" className="col-md-3 col-form-label">
                  Banner
                  <span className="text-danger">*</span>
                </label>
                <Card className="col-md-9">
                  <CardBody>
                    <Row className="align-items-center">
                      {selectedFiles?.length > 0 ? (
                        console.log(selectedFiles),
                        selectedFiles.map((f, i) => (
                          <Col key={i} md={4} className="mb-3">
                            <div className="image-container p-2 position-relative">
                              <img
                                data-dz-thumbnail=""
                                height="80"
                                alt={f.file.name}
                                src={f.data}
                              />
                              <button
                                type="button"
                                className="btn btn-link text-danger btn-sm remove-button"
                                onClick={() => handleRemoveImage(i)}
                              >
                                <FaTimes />
                              </button>
                            </div>
                          </Col>
                        ))
                      ) : (
                        <Col className="py-2">
                        
                            <Dropzone onDrop={handleDrop}>
                              {({ getRootProps, getInputProps }) => (
                                <div
                                  className="dropzone p-2"
                                  style={{ cursor: "pointer" }}
                                >
                                  <div
                                    className="dz-message needsclick mt-2"
                                    {...getRootProps()}
                                  >
                                    <input {...getInputProps()} />
                                    <div className="d-flex align-items-center">
                                      <div className="">
                                        <CiCirclePlus size={48} />
                                      </div>
                                      <div
                                        className="px-4"
                                        style={{ fontSize: "0.7rem" }}
                                      >
                                        <small className="text-center">
                                          Choose a photo or drag here.
                                        </small>{" "}
                                        <br />
                                        <small className="text-center">
                                          Accepted formats: png, jpg.
                                        </small>
                                        <br />
                                        <small className="text-center">
                                          Maximum file size: 20MB.
                                        </small>
                                      </div>
                                      <br />
                                    </div>
                                  </div>
                                </div>
                              )}
                            </Dropzone>{" "}
                          { <ReactCrop />}
                         
                        </Col>
                      )}
                    </Row>
                  </CardBody>
                </Card>
              </div>

              <div className="form-group col-lg-2">
                <button
                  type="submit"
                  className="btn btn-primary btn-lg"
                  disabled={loading}
                >
                  {" "}
                  <i className="fa fa-plus px-1"></i>
                  {loading ? "Uploading..." : "ADD"}
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "repeat(auto-fill, minmax(250px, 1fr))", // Adjust the minmax width for landscape orientation
          gap: "20px",
          marginTop: "20px",
        }}
      >
        {selectedImages.map((image, index) => (
          <div
            key={index}
            className="image-container"
            style={{
              padding: "10px",
              borderRadius: "8px",
              width: "100%", // Ensure the image container takes the full width
              height: "auto", // Allow the height to adjust based on the image aspect ratio
            }}
          >
            <img
              src={image.data}
              alt={`preview-${index}`}
              className="uploaded-image"
              style={{
                width: "100%", // Ensure the image fills the container horizontally
                height: "100%", // Stretch the image to fill the container vertically
                objectFit: "cover", // Stretch the image to cover the container while maintaining aspect ratio
                borderRadius: "8px", // Apply border-radius to the image
              }}
            />
            <button
              onClick={() => handleRemoveImageArray(index)}
              className="remove-button text-red text-bold"
            >
              <FaTimes size={20} />
            </button>
          </div>
        ))}
      </div>
    </>
  );
};

export default Banner;
