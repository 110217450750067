import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};
const groupClassMapAPI = {
  createGroupClass: async (data) => {
    const axiosConfig = createAxiosConfig(
      "add_update_GroupofClass",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
  getGroupClass: async (data) => {
    const axiosConfig = createAxiosConfig("get_GroupofClass", "POST", data);
    return await axios(axiosConfig);
  },
  getGroupClassData: async (data) => {
    const axiosConfig = createAxiosConfig("get_GroupofClassData", "POST", data);
    return await axios(axiosConfig);
  },
  deleteGroupClass: async (data) => {
    const axiosConfig = createAxiosConfig(
      "delete_GroupClassMapping",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
};

export default groupClassMapAPI;
