export const GET_CLASS_REQUEST = 'GET_CLASS_REQUEST';
export const GET_CLASS_SUCCESS = 'GET_CLASS_SUCCESS';
export const GET_CLASS_FAILURE = 'GET_CLASS_FAILURE';

export const GET_SECTION_REQUEST = 'GET_SECTION_REQUEST';
export const GET_SECTION_SUCCESS = 'GET_SECTION_SUCCESS';
export const GET_SECTION_FAILURE = 'GET_SECTION_FAILURE';

export const GET_STUDENT_REPORT_REQUEST = 'GET_STUDENT_REPORT_REQUEST';
export const GET_STUDENT_REPORT_SUCCESS = 'GET_STUDENT_REPORT_SUCCESS';
export const GET_STUDENT_REPORT_FAILURE = 'GET_STUDENT_REPORT_FAILURE';

export const BULK_STUDENT_UPLOAD_REQUEST = 'BULK_STUDENT_UPLOAD_REQUEST';
export const BULK_STUDENT_UPLOAD_SUCCESS = 'BULK_STUDENT_UPLOAD_SUCCESS';
export const BULK_STUDENT_UPLOAD_FAILURE = 'BULK_STUDENT_UPLOAD_FAILURE';

