import React, { useState, useEffect } from "react";
import { Heading } from "../../CommonComponent/heading";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLoading from "../../CommonComponent/useLoading";
import useLocalStorageData from "../Comman/LocalStorageData";
import { salaryGenerationAPI } from "../../../api/Payroll/GenerateSalary";
import Swal from "sweetalert2";

const GenerateSalary = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [empList, setEmpList] = useState([]);
  const [HeadList, setHeadList] = useState([]);
  const [Date1, setDate1] = useState(new Date().toISOString().slice(0, 7));
  // const [Date1, setDate1] =  useState("");
  const [salaryValues, setSalaryValues] = useState({});
  const [monthID, setMonthID] = useState("");
  const [isActive, setIsActive] = useState(false);
  const [isGenerated, setIsGnerated] = useState(false);


  const getEmployList = async () => {
    setIsGnerated(false);
    setHeadList([]);
    setMonthID("");
    setEmpList([]);

    let data = {
      school_id: school_id,
      attendance_month: Date1,
    };
    try {
      startLoading();
      const response = await salaryGenerationAPI.salaryDetailsView(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        const sortedEmpList = responseData.result.map((employee) => {
          const salaryList = [
            ...(employee.salary_list[0]?.Earnings || []),
            ...(employee.salary_list[1]?.Deductions || []),
          ];
          const sortedSalaryList = salaryList.sort(
            (a, b) => a.sequence - b.sequence
          );
          return { ...employee, salary_list: sortedSalaryList };
        });
        setEmpList(sortedEmpList);
        setMonthID(responseData?.MonthId);
        let headList = sortedEmpList[0]?.salary_list || [];
        setHeadList(headList);
        setIsActive(false);
        setIsGnerated(responseData.is_generated);
      } else {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleInputChange = (e, empId, headName) => {
    const { value } = e.target;
    const numericValue = Math.max(0, Number(value)); // Ensure the value is at least 0
    setSalaryValues((prevSalaryValues) => ({
      ...prevSalaryValues,
      [empId]: {
        ...prevSalaryValues[empId],
        [headName]: numericValue,
      },
    }));
  };

  const calculateTotals = (employee) => {
    const earnings = employee.salary_list
      .filter((salary) => salary.heads_type === "Earning")
      .reduce((total, salary) => {
        const value =
          salaryValues[employee.fk_staff_id]?.[salary.heads_name] ??
          salary.amount ??
          0;
        return total + Math.round(Number(value));
      }, 0);
    const deductions = employee.salary_list
      .filter((salary) => salary.heads_type === "Deduction")
      .reduce((total, salary) => {
        const value =
          salaryValues[employee.fk_staff_id]?.[salary.heads_name] ??
          salary.amount ??
          0;
        return total + Math.round(Number(value));
      }, 0);
    const net = Math.round(earnings - deductions);
    return { earnings, deductions, net };
  };

  const columnTotals = HeadList.map((head) => {
    return empList.reduce((total, employee) => {
      const salaryItem = employee.salary_list.find(
        (salary) => salary.heads_name === head.heads_name
      );
      const value =
        salaryValues[employee.fk_staff_id]?.[head.heads_name] ??
        salaryItem?.amount ??
        0;
      return total + Math.round(Number(value));
    }, 0);
  });

  const totalEarnings = Math.round(
    empList.reduce((total, employee) => {
      return (
        total +
        employee.salary_list
          .filter((salary) => salary.heads_type === "Earning")
          .reduce((subtotal, salary) => {
            const value =
              salaryValues[employee.fk_staff_id]?.[salary.heads_name] ??
              salary.amount ??
              0;
            return subtotal + Math.round(Number(value));
          }, 0)
      );
    }, 0)
  );

  const totalDeductions = Math.round(
    empList.reduce((total, employee) => {
      return (
        total +
        employee.salary_list
          .filter((salary) => salary.heads_type === "Deduction")
          .reduce((subtotal, salary) => {
            const value =
              salaryValues[employee.fk_staff_id]?.[salary.heads_name] ??
              salary.amount ??
              0;
            return subtotal + Math.round(Number(value));
          }, 0)
      );
    }, 0)
  );

  const totalNet = Math.round(
    empList.reduce((netTotal, employee) => {
      const earnings = employee.salary_list
        .filter((salary) => salary.heads_type === "Earning")
        .reduce((total, salary) => {
          const value =
            salaryValues[employee.fk_staff_id]?.[salary.heads_name] ??
            salary.amount ??
            0;
          return total + Math.round(Number(value));
        }, 0);
      const deductions = employee.salary_list
        .filter((salary) => salary.heads_type === "Deduction")
        .reduce((total, salary) => {
          const value =
            salaryValues[employee.fk_staff_id]?.[salary.heads_name] ??
            salary.amount ??
            0;
          return total + Math.round(Number(value));
        }, 0);
      return netTotal + Math.round(earnings - deductions);
    }, 0)
  );
 
  const handleSubmit = async () => {
    const payload = empList.map((employee) => {
      const { earnings, deductions, net } = calculateTotals(employee);
      const earningsArray = employee.salary_list
        .filter((salary) => salary.heads_type === "Earning")
        .map((salary) => ({
          ...salary,
          amount:
          Math.round(
            salaryValues[employee.fk_staff_id]?.[salary.heads_name] ??
            salary.amount,
        )
        }));
      const deductionsArray = employee.salary_list
        .filter((salary) => salary.heads_type === "Deduction")
        .map((salary) => ({
          ...salary,
          amount:
          Math.round(
            salaryValues[employee.fk_staff_id]?.[salary.heads_name] ??
            salary.amount,
        )
        }));
      return {
        ...employee,
        earning: earningsArray,
        deduction: deductionsArray,
        gross: earnings,
        total_deduction: deductions,
        net: net,
      };
    });
    const data = {
      school_id: school_id,
      attendance_month: monthID || Date1,
      result: payload,
    };
    const response = await salaryGenerationAPI.saveSalaryGenerated(data);
    const responseData = response.data;
    if (responseData.status === 200) {
      Swal.fire({
        text: responseData.msg,
        icon: "success",
        allowOutsideClick: false,
      });
      getEmployList()
    } else {
      Swal.fire({
        text: responseData.msg,
        icon: "error",
        allowOutsideClick: false,
      });
    }
  };

  const handleDeleteClick = () => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          school_id: school_id,
          attendance_month: Date1,
        };
        salaryGenerationAPI
          .deleteSalaryGenerated(data)
          .then((response) => {
            const responseData = response.data;
            if (responseData.status === 200) {
              Swal.fire({
                text: responseData.msg,
                icon: "success",
              });
              getEmployList()
            } else {
              Swal.fire({
                text: responseData.msg,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  return (
    <>
      <div className="container-fluid mt-1">
        <Heading pageTitle={"Generate Salary"}></Heading>

        <div className="card mt-2">
          <div className="card-body">
            <div className="col-lg-12 row form-group ">
              <div className="col-lg-2 ">
                <label className="col-form-label ">Month & Year</label>
              </div>
              <div className="col-lg-3">
                <input
                  type="month"
                  className="form-control"
                  placeholder="Month & Year"
                  value={Date1}
                  onChange={(e) => setDate1(e.target.value)}
                />
              </div>
              <div className="">
                <button
                  className="btn btn-outline-secondary btn-sm mx-1"
                  type="button"
                  onClick={getEmployList}
                >
                  <i className="fa fa-eye mx-1"></i>
                  View
                </button>

                {!isGenerated && empList.length > 0 && (
                  <button
                    className="btn btn-outline-secondary btn-sm"
                    onClick={handleSubmit}
                    disabled={!empList.length}
                  >
                    GENERATE
                  </button>
                )}
                {isGenerated && (
                  <button
                    type="button"
                    class="btn btn-outline-danger btn-sm mx-1"
                    onClick={handleDeleteClick}
                  >
                    <i class="fa fa-trash px-1"></i>Delete
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-2">
          <div class="card-header">
            <h3 class="card-title">Employee List</h3>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
                <thead>
                  <tr>
                    <th>SR NO</th>
                    <th>FULL NAME</th>
                    <th>emp no</th>
                    <th>designation</th>
                    <th>joining date</th>
                    <th>PRESENT DAYS</th>
                    <th>absent</th>
                    {HeadList.filter(
                      (head) => head.heads_type === "Earning"
                    ).map((head, index) => (
                      <th key={index} className="text-success text-bold">
                        {head.heads_name}
                      </th>
                    ))}
                    <th className="text-success text-bold">Gross</th>
                    {HeadList.filter(
                      (head) => head.heads_type === "Deduction"
                    ).map((head, index) => (
                      <th key={index} className="text-danger text-bold">
                        {head.heads_name}
                      </th>
                    ))}
                    <th className="text-danger text-bold">Total Deduction</th>
                    <th>Net Amount</th>
                  </tr>
                </thead>
                <tbody>
                  {empList.map((employee, index) => {
                    const { earnings, deductions, net } =
                      calculateTotals(employee);
                    return (
                      <tr key={employee.fk_staff_id}>
                        <td>{index + 1}</td>
                        <td>{`${employee.first_name} ${employee.last_name}`}</td>
                        <td>{employee.emp_no}</td>
                        <td>{employee.designation}</td>
                        <td>{formatDate(employee.joiningDate)}</td>
                        <td>{employee.mem.present}</td>
                        <td>{employee.mem.absent || 0}</td>
                        {employee.salary_list
                          .filter((salary) => salary.heads_type === "Earning")
                          .map((salary, idx) => (
                            <td key={idx} style={{ minWidth: "100px" }}>
                              {salary.is_manual ? (
                                <input
                                  type="number"
                                  className="form-control"
                                  value={Math.round(
                                    salaryValues[employee.fk_staff_id]?.[
                                      salary.heads_name
                                    ] ??
                                     salary.amount ??
                                      0
                                  )}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      employee.fk_staff_id,
                                      salary.heads_name
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  type="number"
                                  className="form-control"
                                  value={Math.round(salary.amount ?? 0)}
                                  disabled
                                />
                              )}
                            </td>
                          ))}
                        <td style={{ minWidth: "100px" }}>
                          <input
                            type="number"
                            className="form-control"
                            value={Math.round(earnings ?? 0)}
                            disabled
                          />
                        </td>
                        {employee.salary_list
                          .filter((salary) => salary.heads_type === "Deduction")
                          .map((salary, idx) => (
                            <td key={idx} style={{ minWidth: "100px" }}>
                              {salary.is_manual ? (
                                <input
                                  type="number"
                                  className="form-control"
                                  value={Math.round(
                                    salaryValues[employee.fk_staff_id]?.[
                                      salary.heads_name
                                    ] ??
                                      salary.amount ??
                                      0
                                  )}
                                  onChange={(e) =>
                                    handleInputChange(
                                      e,
                                      employee.fk_staff_id,
                                      salary.heads_name
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  type="number"
                                  className="form-control"
                                  value={Math.round(salary.amount ?? 0)}
                                  disabled
                                />
                              )}
                            </td>
                          ))}
                        <td style={{ minWidth: "100px" }}>
                          <input
                            type="number"
                            className="form-control"
                            value={Math.round(deductions ?? 0)}
                            disabled
                          />
                        </td>
                        <td style={{ minWidth: "100px" }}>
                          <input
                            type="number"
                            className="form-control"
                            value={Math.round(net ?? 0)}
                            disabled
                          />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <tfoot>
                  <tr>
                    <th colSpan={7}>Column Totals</th>
                    {columnTotals
                      .filter(
                        (_, index) => HeadList[index].heads_type === "Earning"
                      )
                      .map((total, index) => (
                        <th key={index}>{total}</th>
                      ))}
                    <th>{totalEarnings}</th>
                    {columnTotals
                      .filter(
                        (_, index) => HeadList[index].heads_type === "Deduction"
                      )
                      .map((total, index) => (
                        <th key={index}>{total}</th>
                      ))}
                    <th>{totalDeductions}</th>
                    <th>{totalNet}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateSalary;
