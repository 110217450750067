import React from "react";
import { forwardRef } from "react";
import img1 from "../../../../assets/image/sh.png";
import { useDispatch, useSelector } from "react-redux";
import www from "../../Examination/GradeCard/www.png";
const PageComponent = forwardRef(({ s, tcNo, id }, ref) => {
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { logoData, loading, error } = useSelector((state) => state.schoolLogo);
  const { email, user_type, name, user_detail } = school_user;
  const {
    school_name,
    organization_name,
    school_address,
    school_contact_email,
    school_contact_number,
    school_pincode,
    udise_no,
    school_logo,
    school_emp_id,
  } = user_detail;

  function formatDate1(data) {
    const date = new Date(data);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  return (
    <div
      // className="container"

      ref={ref}
      id={id}
      //   ref={componentRef}
    >
      <div className="d-flex bottom-line">
        <div className="pb-1">
          {logoData ? (
            <img
              src={`data:image/png;base64,${logoData}`}
              alt="school_logo"
              width={65}
              height={65}
              className=""
              style={{ borderRadius: "50%" }}
            />
          ) : (
            <img
              src={www}
              alt="fallback_image"
              width={65}
              height={65}
              style={{ borderRadius: "50%" }}
            />
          )}
        </div>
        <div className="flex-grow-1 pb-1">
          <div className="d-flex justify-content-around">
            <small>Sr No : 12345</small>
            <small>APPENDIX -16 ( Rule - 99. Chapter III)</small>
          </div>
          <div className="text-center" style={{ fontWeight: "bolder" }}>
            {school_name}
          </div>
          <div className="certificate-header">FORM OF TRANSFER CERTIFICATE</div>
        </div>
      </div>

      <div className="container">
        <div className="row">
          <div className="col d-flex">
            <p>1.</p>
            <div className="flex-grow-1">
              <p>Transfer Certificate No :- {tcNo}</p>
            </div>
          </div>
          <div className="col">
            <p className="">Admission No : {s?.student_code}</p>
          </div>
        </div>

        <div className="row">
          <div className="col d-flex">
            <div className="flex-grow-1">
              <p style={{ paddingLeft: "1.5rem" }}>
                a. Name of the School, Place and District
              </p>
            </div>
          </div>
          <div className="col">
            <p className="">
              {" "}
              {`${s?.school_details.name}, ${s?.school_details.address}`}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <div className="flex-grow-1">
              <p style={{ paddingLeft: "1.5rem" }}>
                b. Highest Class in the School
              </p>
            </div>
          </div>
          <div className="col">
            <p className=""></p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <div className="flex-grow-1">
              <p style={{ paddingLeft: "1.5rem" }}>
                c. Non Government, Class upto which recognised No. & date of
                recognition orders
              </p>
            </div>
          </div>
          <div className="col">
            <p className=""> {s?.school_details.udise_no}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>2.</p>
            <p> Name of the Pupil</p>
          </div>
          <div className="col">
            <p className="border-tc-values"> {s?.student_name}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>3.</p>
            <p> Name of the Mother</p>
          </div>
          <div className="col">
            <p className="border-tc-values">{s?.mother_name}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>4.</p>
            <p>Child ID</p>
          </div>
          <div className="col">
            <p className="border-tc-values">{s?.child_id}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>5.</p>
            <p> Aadhaar Card No</p>
          </div>
          <div className="col">
            <p className="border-tc-values">{s?.adhar_no}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>6.</p>
            <p> Name of the Parent or Guardian</p>
          </div>
          <div className="col">
            <p className="border-tc-values">{s?.father_name}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>7.</p>
            <p> Nationality and Religion</p>
          </div>
          <div className="col">
            <p className="border-tc-values">{s?.nationality}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>8.</p>
            <p>
              Whether he candidate belongs to a Scheduled caste, Scheduled
              Tribe. or Vinukha this or their socially and educationally
              Backward Classes specified in the Andhva Pradesh Educational Rules
              or is he has convert from the Scheduled Castes or he Scheduled
              tribes.if so the particular there of Admission Register
            </p>
          </div>
          <div className="col align-self-end">
            <p className="border-tc-values"></p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p> 9.</p>
            <p className="">
              Date of Birth (in words ) as per the Admission Register
            </p>
          </div>
          <div className="col">
            <p className="border-tc-values text-capitalize">
              {s?.dob_in_words}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>10. </p>
            <ol type="a" className="tc-order">
              <li>
                Class in which Pupil was reaching at the time of leaving (in
                word )
              </li>
              <li>First Language</li>
              <li>Second Language</li>
              <li>Optional Subject</li>
            </ol>
          </div>
          <div className="col align-self-end">
            <ol className="tc-order" style={{ listStyleType: "none" }}>
              <li className="border-tc-values"></li>
              <li className="border-tc-values"></li>
              <li className="border-tc-values"></li>
              <li className="border-tc-values"></li>
            </ol>
            {/* <p className="border-tc-values">:</p> */}
          </div>
        </div>
        <div className="row">
          <div className="col">
            <p>11. Mother Tongue ... medium of instruction</p>
          </div>
          <div className="col">
            <p className="border-tc-values">{s?.mother_tongue}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>12. </p>
            <p>
              Date of admission to that Class (The year to be entered in words)
            </p>
          </div>
          <div className="col">
            <p className="border-tc-values">
              {formatDate1(s?.registration_date)}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>13. </p>
            <p>
              Class and year in which th pupil was first admitted into the
              school
            </p>
          </div>
          <div className="col">
            <p className="border-tc-values">{s?.class_year}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>14. </p>
            <p>
              Whether qualified for promotion to a Higher class (In the case of
              Pupil presented for the S.S.L C/H.SC. or S S.C. ‘Whether the pupil
              has been declared eligible for a college course. of study or
              passed or not, has to be stated)
            </p>
          </div>
          <div className="col align-self-end">
            <p className="border-tc-values">:</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>15. </p>
            <p>
              Whether the pupil has paid all the fees due to the school a.
              Whether the pupil was in receipt of any scholarships (Nature of
              the scholarship to be specified)
            </p>
          </div>
          <div className="col align-self-end">
            <p className="border-tc-values">:</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>16. </p>
            <p>
              Whether the pupil was in receipt of any concession (Nature of
              concession to be specific)
            </p>
          </div>
          <div className="col align-self-end">
            <p className="border-tc-values">{s?.concession_name}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>17. </p>
            <p>
              Whether the pupil has undergone medical inspection during the year
              - First or repeat (to be specified)
            </p>
          </div>
          <div className="col align-self-end">
            <p className="border-tc-values">{s?.medical_inspection}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>18. </p>
            <div className="flex-grow-1">
              <p>Personal Marks of Identification</p>
              <p className="border-tc-values">{s?.mark_of_identity}</p>
              <p className="border-tc-values"></p>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>19. </p>
            <p>The date on which the pupils actually left the school</p>
          </div>
          <div className="col align-self-end">
            <p className="border-tc-values">
              {formatDate1(s?.student_left_school_date)}
            </p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>20. </p>
            <p>
              The date on which application for Transfer certificate was made on
              behalf on pupil by his/her parent or Guardian
            </p>
          </div>
          <div className="col align-self-end">
            <p className="border-tc-values">:</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>21. </p>
            <p>Date of the Transfer Certificate</p>
          </div>
          <div className="col align-self-end">
            <p className="border-tc-values">:</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>22. </p>
            <p>CONDUCT</p>
          </div>
          <div className="col align-self-end">
            <p className="border-tc-values">{s?.conduct}</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>23. </p>
            <p>
              Attendance in the class of leaving Number of days present Total
              number of working days
            </p>
          </div>
          <div className="col align-self-end">
            <p className="border-tc-values">:</p>
          </div>
        </div>
        <div className="row">
          <div className="col d-flex">
            <p>24. </p>
            <p>Signature of the Head of the School with stamp</p>
          </div>
          <div className="col">
            {/* <p className="border-tc-values">:</p> */}
          </div>
        </div>
      </div>
    </div>
  );
});

export default PageComponent;
