import axios from "axios";
import { BASE_URL} from "../../Config/BaseUrl";
const token = localStorage.getItem("token");
const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};
export const RouteconfigurationAPI = {
    getRouteconfigurations : async (data) => {
        const axiosConfig = createAxiosConfig("get_Routeconfigurations","POST", data);
        return await axios(axiosConfig);
      },
      addRouteconfigurations : async (data) => {
        const axiosConfig = createAxiosConfig("add_Update_Routeconfiguration","POST", data);
        return await axios(axiosConfig);
      },
      addUpdatePickupdropupApi : async (data) => {
        const axiosConfig = createAxiosConfig("addUpdate_pickupdropup_api","POST", data);
        return await axios(axiosConfig);
      },
      deleteRouteconfigurations : async (data) => {
        const axiosConfig = createAxiosConfig("delete_Routeconfiguration","POST", data);
        return await axios(axiosConfig);
      },
      getPickupdropupApi : async (data) => {
        const axiosConfig = createAxiosConfig("get_PickupPoint_api","POST", data);
        return await axios(axiosConfig);
      },
      deletePickupPoint : async (data) => {
        const axiosConfig = createAxiosConfig("delete_PickupPoint","POST", data);
        return await axios(axiosConfig);
      },
      getPickupPoint :async (data) => {
        const axiosConfig = createAxiosConfig("get_PickupPoint_api","POST", data);
        return await axios(axiosConfig);
      },
      studentRrouteMappingApi : async (data) => {
        const axiosConfig = createAxiosConfig("student_route_mapping_api","POST", data);
        return await axios(axiosConfig);
      }
    }