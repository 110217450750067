import React, { useEffect } from "react";

const Logout = () => {
  useEffect(() => {
    const admin = JSON.parse(localStorage.getItem("Admin"));
    localStorage.clear();
    sessionStorage.clear();
    window.location.href = "/login";
  });

  return <></>;
};

export default Logout;
