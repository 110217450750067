export const ADMISSION_REQUIST = "ADMISSION_REQUIST";
export const ADD_ADMISSION = "ADD_ADMISSION";
export const ADMISSION_FAIELD = "ADMISSION_FAIELD";
export const GET_STUDENT_NAME_REQUEST = "GET_STUDENT_NAME_REQUEST";
export const GET_STUDENT_NAME = "GET_STUDENT_NAME";
export const GET_STUDENT_NAME_FAIELD = "GET_STUDENT_NAME_FAIELD";
export const GET_STUDENT_COUNT_REQUEST = "GET_STUDENT_COUNT_REQUEST";
export const GET_STUDENT_COUNT = "GET_STUDENT_COUNT";
export const GET_STUDENT_COUNT_FAIELD = "GET_STUDENT_COUNT_FAIELD";
export const GET_REGULAR_FEES_REQUEST = "GET_REGULAR_FEES_REQUEST";
export const GET_REGULAR_FEES = "GET_REGULAR_FEES";
export const GET_REGULAR_FEES_FAIELD = "GET_REGULAR_FEES_FAIELD";
export const GET_REGULAR_FEES_RECEIPT_REQUEST = "GET_REGULAR_FEES_RECEIPT_REQUEST";
export const GET_REGULAR_FEES_RECEIPT = "GET_REGULAR_FEES_RECEIPT";
export const GET_REGULAR_FEES_RECEIPT_FAIELD = "GET_REGULAR_FEES_RECEIPT_FAIELD";
export const GET_SPECIAL_FEES_RECEIPT_REQUEST = "GET_SPECIAL_FEES_RECEIPT_REQUEST";
export const GET_SPECIAL_FEES_RECEIPT = "GET_SPECIAL_FEES_RECEIPT";
export const GET_SPECIAL_FEES_RECEIPT_FAIELD = "GET_SPECIAL_FEES_RECEIPT_FAIELD";
export const GET_SPECIAL_FEES_TYPE_REQUEST = "GET_SPECIAL_FEES_TYPE_REQUEST";
export const GET_SPECIAL_FEES_TYPE = "GET_SPECIAL_FEES_TYPE";
export const GET_SPECIAL_FEES_TYPE_FAIELD = "GET_SPECIAL_FEES_TYPE_FAIELD";
export const ADMISSION_CANCEL_REQUIST = "ADMISSION_CANCEL_REQUIST";
export const ADMISSION_CANCEL_SUCCESS = "ADMISSION_CANCEL_SUCCESS";
export const ADMISSION_CANCEL_FAIELD = "ADMISSION_CANCEL_FAIELD";
export const ADMISSION_STUDENT_DATA_REQUIST = "ADMISSION_STUDENT_DATA_REQUIST";
export const ADMISSION_STUDENT_DATA_SUCCESS = "ADMISSION_STUDENT_DATA_SUCCESS";
export const ADMISSION_STUDENT_DATA_FAIELD = "ADMISSION_STUDENT_DATA_FAIELD";
export const ADMISSION_UPDATE_REQUIST = "ADMISSION_UPDATE_REQUIST";
export const ADMISSION_UPDATE_SUCCESS = "ADMISSION_UPDATE_SUCCESS";
export const ADMISSION_UPDATE_FAIELD = "ADMISSION_UPDATE_FAIELD";
export const Academic_Filter_Student_List_REQUIST = "Academic_Filter_Student_List_REQUIST";
export const Academic_Filter_Student_List_SUCCESS = "Academic_Filter_Student_List_SUCCESS";
export const Academic_Filter_Student_List_FAIELD = "Academic_Filter_Student_List_FAIELD";

export const CLEAR_ERROR = "CLEAR_ERROR";
