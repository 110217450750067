import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";

const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

export const LeaveOpeningAPI = {
    getLeaveOpeningApi: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_leave_opening_api",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  saveLeaveOpeningApi: async (data) => {
    const axiosConfig = createAxiosConfig(
      "save_leave_opening_api",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  deleteLeaveOpening: async (data) => {
    const axiosConfig = createAxiosConfig(
      "delete_leave_opening",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  getLeaveType: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_leave_type",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  saveLeaveType: async (data) => {
    const axiosConfig = createAxiosConfig(
      "save_leave_type",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },


};