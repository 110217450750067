import React, { useEffect, useState } from "react";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useSessionRedirect from "../../Comman/SessionChange";
import useLoading from "../../../CommonComponent/useLoading";
import Select from "react-select";
import ReactHTMLTableToExcel from "react-html-table-to-excel";
import Loader from "../../../Loader/Loader";
import { convertDateInMMDDYY } from "../../../CommonComponent/DateFormatUtil";
import CustomScrollbar from "../../../CommonComponent/PerfectScrollbar";

const HostelReport = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [hostelData, setHostelData] = useState([]);
  const [hostelReportData, setHostelReportData] = useState([]);
  const [hostelId, setHostelId] = useState("All");
  const [studentType, setStudentType] = useState("All");
  const [bedType, setBedType] = useState("All");

  let hostelList = [
    { label: "All", value: "All" },
    ...(hostelData?.map((hostel) => ({
      value: hostel.id,
      label: hostel.hostelName,
    })) ?? []),
  ];

  let studentTypeList = [
    { label: "All", value: "All" },
    { label: "Regular", value: "Regular" },
    { label: "External", value: "External" },
  ];
  let bedTypeList = [
    { label: "All", value: "All" },
    { label: "Occupied", value: "Occupied" },
    { label: "Available", value: "Available" },
  ];

  const handleClear = () => {
    setHostelReportData([]);
    setHostelId("All");
    setStudentType("All");
    setBedType("All");
  };
  const fetchData = async () => {
    startLoading();
    try {
      let data = {
        school_id: school_id,
      };
      const response = await HostelAPI.getHostelList(data);
      let responseData = response.data;
      setHostelData(responseData.hostelList);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  const fetchHostelReports = async () => {
    startLoading();
    try {
      let data = {
        hostel_id: hostelId,
        bedTypeId: bedType,
        studentType: studentType,
        school_id: school_id,
      };
      const response = await HostelAPI.getHostelReport(data);
      let responseData = response.data;

      setHostelReportData(responseData.hostel_report);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
 
  useEffect(() => {
    fetchData();
  }, []);
  if (isLoading) return <Loader />;
  return (
    <div className="section-body">
      <div className="container-fluid">
        <div className="d-flex justify-content-between align-items-center ">
          <div className="header-action">
            <h1 className="page-title">Hostel Report</h1>
          </div>
          <div className=" mt-2">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm mx-1"
              onClick={() => fetchHostelReports()}
            >
              <i className="fa fa-eye  px-1"></i>
              View
            </button>
            <button
              type="button"
              className="btn btn-sm btn-outline-danger mx-1"
              onClick={() => handleClear()}
            >
              <i className="fa fa-times px-1"></i>
              Clear
            </button>
          </div>
        </div>
        <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
          <div className="card">
            <div className="card-body row">
              <div className="col-lg-4 col-md-6">
                <div className="form-group  row">
                  <label className="col-md-4 col-form-label">
                    Select Hostel <span className="text-danger"></span>
                  </label>
                  <div className="col-md-8">
                    <Select
                      name="hostelTypeId"
                      placeholder="Select Hostel"
                      options={hostelList}
                      value={
                        hostelId
                          ? hostelList?.find(
                              (option) => option.value === hostelId
                            )
                          : null
                      }
                      onChange={(selectedOptions) => {
                        setHostelId(selectedOptions.value);
                      }}
                      isSearchable={true}
                    />
                    {/* {idValidationError && !hostelId ? (
                      <ErrorDisplay errorMsg={"Hostel is required !"} />
                    ) : null} */}
                  </div>
                </div>
              </div>

              <div className="col-lg-4 col-md-6">
                <div className="form-group  row">
                  <label className="col-md-5 col-form-label">
                    Student Type <span className="text-danger"></span>
                  </label>
                  <div className="col-md-7">
                    <Select
                      name="hostelTypeId"
                      placeholder="Select Hostel"
                      options={studentTypeList}
                      value={
                        studentType
                          ? studentTypeList?.find(
                              (option) => option.value === studentType
                            )
                          : null
                      }
                      onChange={(selectedOptions) => {
                        setStudentType(selectedOptions.value);
                      }}
                      isSearchable={true}
                    />
                    {/* {idValidationError && !hostelId ? (
                      <ErrorDisplay errorMsg={"Hostel is required !"} />
                    ) : null} */}
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="form-group  row">
                  <label className="col-md-4 col-form-label">
                    Bed Type <span className="text-danger"></span>
                  </label>
                  <div className="col-md-8">
                    <Select
                      name="hostelTypeId"
                      placeholder="Select Hostel"
                      options={bedTypeList}
                      value={
                        bedType
                          ? bedTypeList?.find(
                              (option) => option.value === bedType
                            )
                          : null
                      }
                      onChange={(selectedOptions) => {
                        setBedType(selectedOptions.value);
                      }}
                      isSearchable={true}
                    />
                    {/* {idValidationError && !hostelId ? (
                      <ErrorDisplay errorMsg={"Hostel is required !"} />
                    ) : null} */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="d-flex justify-content-end mb-2">
                {hostelReportData && hostelReportData.length > 0 && (
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button btn-sm btn btn-success"
                    color="success"
                    table="table-to-xls"
                    filename="Report"
                    sheet="tablexls"
                    buttonText="EXPORT"
                  />
                )}
              </div>
              <CustomScrollbar
                style={{
                  height: "50vh",
                  paddingRight: ".8rem",
                  boxSizing: "content-box",
                }}
              >
                <div className="table-responsive">
                  <table
                    className="table table-bordered table-hover text-nowrap table-sm"
                    id="table-to-xls"
                  >
                    <thead>
                      <tr className="text-center">
                        <th> Sr No</th>
                        <th>Bed Number</th>
                        <th>Student Type</th>
                        <th>Student No.</th>
                        <th>Student Name</th>
                        <th>Student Contact No.</th>
                        <th>Student Address</th>
                        <th>Parent Name</th>
                        <th>Parent Contact No.</th>
                        <th>Mess</th>
                        <th>Rack</th>
                        <th>Start Date</th>
                        <th>End Date</th>
                        <th>Fees</th>
                        <th>No. of Installment</th>
                        <th>Is Allotted</th>
                      </tr>
                    </thead>
                    <tbody>
                      {hostelReportData?.map((report, index) => {
                        let hostelName = Object.keys(report);
                        let hostelReport = report[hostelName];
                        return (
                          <React.Fragment key={index}>
                            <tr className="table-secondary text-success text-capitalize">
                              <td colSpan={16}>Hostel Name :- {hostelName}</td>
                            </tr>
                            {hostelReport?.map((floor, floorIndex) => {
                              let floorName = Object.keys(floor);
                              let floorReport = floor[floorName[0]];
                              let totalBed = floor[floorName[1]];
                              return (
                                <React.Fragment key={floorIndex}>
                                  <tr className="table-secondary text-success text-capitalize">
                                    <td colSpan={16}>
                                      <i className="fa fa-caret-right px-2"></i>
                                      {`${floorName[0]} ( Total Bed :-${totalBed})`}
                                    </td>
                                  </tr>
                                  {floorReport?.map((room, roomIndex) => {
                                    return (
                                      <tr
                                        key={roomIndex}
                                        className="text-center"
                                      >
                                        <td>{roomIndex + 1}</td>
                                        <td>{room.bed_no}</td>
                                        <td>{room.student_type}</td>
                                        <td>{room.student_no}</td>
                                        <td className="text-left">
                                          {room.student_name}
                                        </td>
                                        <td>{room.contact_no}</td>
                                        <td className="text-left">
                                          {room.address}
                                        </td>
                                        <td className="text-left">
                                          {room.parent_name}
                                        </td>
                                        <td>{room.parent_contactno}</td>
                                        <td>{room.mess ? "Yes" : "No"}</td>
                                        <td>{room.rack ? "Yes" : "No"}</td>
                                        <td>
                                          {convertDateInMMDDYY(room.start_date)}
                                        </td>
                                        <td>
                                          {convertDateInMMDDYY(room.end_date)}
                                        </td>
                                        <td className="text-right">
                                          {room.hostel_fees}
                                        </td>
                                        <td className="text-right">
                                          {room.no_of_installment}
                                        </td>
                                        <td>{room.is_alloted}</td>
                                      </tr>
                                    );
                                  })}
                                </React.Fragment>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </tbody>
                  </table>
                </div>{" "}
              </CustomScrollbar>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HostelReport;
