import React from "react";
import { Modal, ModalBody, ModalHeader, Table } from "reactstrap";
import Pagination from "react-js-pagination";
const CustomModal = ({ modal,toggle,children, ...props }) => {
  return (
    <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box" backdrop="static" keyboard={false}>
      {children}
      <ul className="pagination justify-content-center">
        <Pagination
          {...props}
          hideFirstLastPages={false}
          nextPageText="Next"
          prevPageText="Previous"
          lastPageText="Last"
          firstPageText="1st"
          itemClass="page-item"
          linkClass="page-link"
          activeClass="active"
          activeLinkClass="active"
          hideDisabled={false}
        />
      </ul>
    </Modal>
  );
};
const CustomModalBody = ({ children, noResultsMessage }) => {
  return (
    <ModalBody>
      {noResultsMessage ? (
        <p>{noResultsMessage}</p>
      ) : (
        <div className="table-responsive">
          <Table size="sm" className="table-hover text-nowrap">
            {children}
          </Table>
        </div>
      )}
    </ModalBody>
  );
};

const CustomModalHeader = ({ children, toggle }) => {
  const closeBtn = (
    <button className="btn btn-sm close " onClick={toggle} type="button">
      <i className="fa fa-times" style={{ color: "red" }}></i>
    </button>
  );
  return (
    <ModalHeader toggle={toggle} close={closeBtn}>
      {children}
    </ModalHeader>
  );
};
export { CustomModalBody, CustomModalHeader, CustomModal };
