import React, { useEffect, useState } from "react";
import html2pdf from "html2pdf.js";
import { Heading } from "../../CommonComponent/heading";
import SalarySlipModal from "./SalarySlip/SalarySlipModal";
import Select from "react-select";
import { salaryStructure } from "../../../api/Payroll/SalaryStructure";
import useLoading from "../../CommonComponent/useLoading";
import Loader from "../../Loader/Loader";
import useLocalStorageData from "../Comman/LocalStorageData";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";

import { useDispatch, useSelector } from "react-redux";
import "./SalarySlip.css";
import ValidationComponent from "../Comman/Validation";
import { salaryGenerationAPI } from "../../../api/Payroll/GenerateSalary";
import ValidationMessage from "../../CommonComponent/ValidationMessage";
import numberToWords from "number-to-words";
import Swal from "sweetalert2";
import SalarySlipCard from "./SalarySlipCard";

const SalaryReport = () => {
  const { logoData, loading, error } = useSelector((state) => state.schoolLogo);
  const [modal, setModal] = useState(false);
  const [totalEarnings, setTotalEarnings] = useState(0);
  const [totalDeductions, setTotalDeductions] = useState(0);

  const [Date1, setDate1] = useState("");
  const [selectedEmpId, setSelectedId] = useState("");
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [employList, setEmployList] = useState([]);
  const [totalDays, setTotalDays] = useState("");

  const { isLoading, startLoading, stopLoading } = useLoading();
  const [userData, setUserData] = useState([]);
  const [salaryDate, setSalaryDate] = useState("");

  const [words, setWords] = useState("");
  console.log(userData, " : : : : : : : : :: :");
  // const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  const toggle = () => {
    if (selectedEmpId.length == 0) {
      Swal.fire({
        text: "Please Select Employee",
        icon: "error",
        allowOutsideClick: false,
      });
    } else {
      GeneratePDF();
    }
  };

  const handleSelectAllStudent = () => {
    if (selectAllChecked) {
      setSelectedId([]);
      setSelectAllChecked(false);
    } else {
      const addStudent = employList?.map((stu) => stu.fk_staff_id);
      setSelectedId(addStudent);
      setSelectAllChecked(true);
    }
  };

  const getEmployList = async (ids) => {
    setSelectedId("");
    setSelectAllChecked(false);
    setSelectedId([]);

    startLoading();
    let data = {
      school_id: school_id,
      attendance_month: ids,
    };
    try {
      const response = await salaryStructure.getStaffArr(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        setEmployList(responseData.staff_arr);
        setEmployList(EmpOptions);
      } else {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
    } finally {
      stopLoading();
    }
  };

  const handleClear = () => {
    setEmployList([]);
    setModal(false);
    setSelectedId([]);
    setSelectAllChecked(false);
  };
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const handleSelectStudent = (index) => {
    const updateStudent = [...selectedEmpId];
    const studentId = employList[index].fk_staff_id;
    const existStudent = updateStudent?.indexOf(studentId);
    if (existStudent === -1) {
      updateStudent.push(employList[index].fk_staff_id);
    } else {
      updateStudent.splice(existStudent, 1);
    }
    setSelectedId(updateStudent);
    setSelectAllChecked(updateStudent?.length === employList?.length);
  };

  const GeneratePDF = async () => {
    startLoading();
    try {
      let date = {
        school_id: school_id,
        attendance_month: Date1,
        staff_id: selectedEmpId,
      };

      const response = await salaryGenerationAPI.getSalarySlipDetails(date);
      const responseData = response.data;

      if (responseData.status === 200) {
        let totalDays = responseData.total_days;
        setTotalDays(totalDays);
        let data = responseData.salary_arr;

        // Parse the stringified JSON fields in each item of data
        const UserData1 = data.map((item) => {
          return {
            ...item,
            presenty: JSON.parse(
              item.presenty
                .replace(/'/g, '"')
                .replace(/False/g, "false")
                .replace(/True/g, "true")
            ),
            earning: JSON.parse(
              item.earning
                .replace(/'/g, '"')
                .replace(/False/g, "false")
                .replace(/True/g, "true")
            ),
            deduction: JSON.parse(
              item.deduction
                .replace(/'/g, '"')
                .replace(/False/g, "false")
                .replace(/True/g, "true")
            ),
          };
        });
        console.log(UserData1, " : : : : : : : : UserData:: :");
        let SalaryDate = UserData1[0].created_date;
        console.log(SalaryDate, " : : : : : : : : SalaryDate:: :");

        const date = new Date(Date1);

        const options = { year: "numeric", month: "long" };
        const formattedDate = date.toLocaleDateString("en-US", options);

        console.log(formattedDate);
        setSalaryDate(formattedDate);

        // Calculate the total for fixed earnings
        const calculateFixedEarningsTotal = (earnings) => {
          return earnings
            .filter((entry) => entry.is_fixed === true)
            .reduce((total, entry) => total + entry.amount, 0);
        };

        const updatedUserData = UserData1.map((user) => {
          return {
            ...user,
            fixedEarningsTotal: calculateFixedEarningsTotal(user.earning),
          };
        });

        console.log(updatedUserData, " : : : : : : : : Updated UserData:: :");

        setUserData(updatedUserData);
        setModal(!modal);
      }
    } catch (error) {
      console.error(error);
      // handle the error appropriately
    } finally {
      stopLoading();
    }
  };

  const handleConvertToPdf = async () => {
    const element = document.getElementById(`model-con`);
    if (element) {
      const opt = {
        margin: 15,
        filename: `generated_.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1 },
        pagebreak: { mode: "css", after: "#divToConvert" },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };

      const pdfDataUri = await html2pdf()
        .from(element)
        .set(opt)
        .outputPdf("datauristring");

      const newTab = window.open();
      if (newTab) {
        newTab.document.write(
          `<style> body { margin: 0px !important; overflow: hidden !important; }</style><iframe width="100%" height="100%" src="${pdfDataUri}"></iframe>`
        );
        newTab.document.close();
      } else {
        console.error("Failed to open a new tab.");
      }
    }
  };

  const hadelDateChange = (e) => {
    let value = e.target.value;
    setDate1(value);
    handleClear();
    getEmployList(value);
  };

  if (isLoading) return <Loader />;

  return (
    <>
      <div className="container-fluid mt-1">
        <Heading pageTitle={"Salary Slip"}>
          <button
            type="button"
            className="btn btn-sm btn-primary btn-block p-2 my-2 page-breadcrumb "
            disabled={selectedEmpId.length == 0}
            onClick={toggle}
          >
            Print Salary
          </button>
        </Heading>

        <div className="card mt-2">
          <div className="card-body  row">
            <div className="col-lg-12 row form-group ">
              <div className="col-lg-2 ">
                <label className="col-form-label">Month & Year</label>
              </div>
              <div className="col-lg-4">
                <input
                  type="month"
                  className="form-control"
                  placeholder="Month & Year"
                  value={Date1}
                  onChange={(e) => hadelDateChange(e)}
                />
              </div>
              {/* <div className="col-lg-1">
                <label className="col-form-label ">Staff</label>
              </div>
              <div className="col-lg-4">
                <Select
                  options={employList}
                  onChange={(e) => setSelectedId(e.value)}
                  value={
                    selectedEmpId
                      ? employList.find((c) => c.value === selectedEmpId)
                      : null
                  }
                />
                <ValidationMessage
                  condition={DateValidationError && !selectedEmpId}
                  errorMsg={"Please Select Employee."}
                />
              </div> */}
            </div>
          </div>
        </div>

        <div className="section-body">
          <div className="card">
            <div class="card-header">
              <h3 class="card-title">Employee List</h3>
            </div>
            <div className="card-body">
              <div className="table-responsive">
                <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
                  <thead>
                    <tr className="text-center">
                      <th>
                        <input
                          type="checkbox"
                          checked={selectAllChecked}
                          onChange={handleSelectAllStudent}
                          disabled={employList?.length === 0}
                          style={{ cursor: "pointer" }}
                        />
                      </th>
                      <th>SR.NO</th>
                      {/* <th>EMP_NO</th> */}
                      <th>NAME</th>
                      <th>CONTACT NO.</th>
                      <th>EMAIL</th>
                      <th>QUALIFICATION</th>
                    </tr>
                  </thead>
                  <tbody>
                    {employList?.map((s, index) => (
                      <tr className="text-center" key={index}>
                        <td>
                          <input
                            type="checkbox"
                            checked={selectedEmpId?.includes(s.fk_staff_id)}
                            onChange={() => handleSelectStudent(index)}
                            style={{ cursor: "pointer" }}
                          />
                        </td>
                        <td>{index + 1}</td>
                        <td>
                          {s.fk_staff__first_name + " " + s.fk_staff__last_name}
                        </td>
                        <td>{s.fk_staff__mobile_no}</td>
                        <td>{s.fk_staff__email}</td>
                        <td>{s.fk_staff__qualification}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        fullscreen
        className="modal-lg "
        backdrop="static"
      >
        <div className="text-right p-2">
          {loading ? (
            <>
              <div>Loading...</div>{" "}
            </>
          ) : (
            <>
              <button
                className="btn btn-primary"
                onClick={() => {
                  handleConvertToPdf();
                }}
              >
                Print Fees Receipt
              </button>
            </>
          )}
          <button
            className="btn btn-secondary mx-1 "
            onClick={() => {
              toggle();
            }}
          >
            Close
          </button>
          <hr className="border-muted" />
        </div>

        <ModalBody id="model-con" className="p-0">
          {userData.length > 0 ? (
            userData.map((item, index) => (
              <div className="salary-slip-card" key={index}>
                <SalarySlipCard
                  salaryDate={salaryDate}
                  totalDays={totalDays}
                  userData={item}
                  id={`divToConvert`}
                  loading={loading}
                  base64Image={logoData}
                />
              </div>
            ))
          ) : (
            <p className="no-list-message" style={{ fontSize: "1.5rem" }}>
              Salary Slip Not Found!{" "}
            </p>
          )}
        </ModalBody>
      </Modal>
    </>
  );
};

export default SalaryReport;
