import React, { useState } from 'react'
import MapHelper from './MapHelper'
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Form,
} from 'reactstrap';
import { FaMapMarkedAlt } from "react-icons/fa";

const ActualMap = () => {
  const [open, setOpen] = useState(true);
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const toggle = () => setOpen(!open);
  

 
  return (
    <>
    <button className="btn btn-primary btn-sm m-1" type="button" onClick={() => toggle()} ><FaMapMarkedAlt /></button>
    <div>
       <Modal className='modal-lg'>
       <ModalBody>
        <MapHelper/>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
      
    </div>
    </>
  )
}

export default ActualMap
