import React, { useEffect, useState } from "react";
import StudentSessionWiseList from "../../Comman/StudentListSessionWise";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import Select from "react-select";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useLoading from "../../../CommonComponent/useLoading";
import useSessionRedirect from "../../Comman/SessionChange";
import Loader from "../../../Loader/Loader";
import CheckButton from "./CheckButton";
import RoomAllotmentList from "./RoomAllotmentList";
import AvailabletyRoom from "./AvailabletyRoom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Table } from "reactstrap";
import ValidationComponent from "../../Comman/Validation";
import Swal from "sweetalert2";

const RoomAllotment = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [inputType, setInputType] = useState("Regular");
  const [student_uid, setstudent_uid] = useState("");
  const [student, setStudent] = useState("");
  const [allotmentList, setAllotmentList] = useState([]);
  const [hostelId, setHostelId] = useState("");
  const [hostelData, setHostelData] = useState([]);
  const [isRoom, setIsRoom] = useState("");
  const [isEditRoom, setIsEditRoom] = useState("");
  const { id, floorNo, roomNo, bedNo, bedType, bedTypeId, is_available } =
    isRoom;

  useEffect(() => {
    if (isEditRoom) {
      setHostelId(isEditRoom?.fk_hostel);
      setInputType(isEditRoom?.student_type);
      if (isEditRoom?.fk_room) {
        setIsRoom({
          id: isEditRoom?.fk_room,
          floorNo: isEditRoom?.fk_room__floorNo,
          roomNo: isEditRoom?.fk_room__bedNo,
          bedNo: isEditRoom?.fk_room__roomNo,
        });
      }
    }
  }, [isEditRoom]);

  let defaultDate = new Date().toISOString().substr(0, 10);
  const handleRadioChange = (e) => {
    setInputType(e.target.value);
  };
  let hostelList = hostelData?.map((hostel) => ({
    value: hostel.id,
    label: hostel.hostelName,
  }));
  const fetchData = async () => {
    startLoading();
    try {
      let data = {
        school_id: school_id,
      };
      const response = await HostelAPI.getHostelList(data);
      let responseData = response.data;
      setHostelData(responseData.hostelList);
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };
  useEffect(() => {
    fetchData();
  }, []);
  const fetchRoomAllotmentList = async () => {
    try {
      let data = {
        school_id: school_id,
      };
      const response = await HostelAPI.getRoomAllotmentDetails(data);
      let responseData = response.data;
      setAllotmentList(responseData.data);
    } catch (error) {
      console.error(error);
    }
  };

  useEffect(() => {
    fetchRoomAllotmentList();
  }, []);

  const handleClear = () => {
    setStudent("");
    setHostelId("");
    formik.resetForm();
    setIsEditRoom("");
    setIsRoom("");
  };
  const baseSchema = Yup.object().shape({
    hostel_fees: Yup.string().required("Hostel Fees is required."),
    no_of_installment: Yup.string().required("Installment is required."),
    hostelId: Yup.string().required("Hostel is required."),
  });
  const validationSchema =
    inputType === "Regular"
      ? baseSchema.shape({
          student_id: Yup.string().required("Student is required."),
        })
      : baseSchema.shape({
          student_name: Yup.string().required("Student Name is required."),
          parent_name: Yup.string().required("Parent Name is required."),
          address: Yup.string().required("Address is required."),
          contact_no: Yup.string().required("Contact No. is required."),
          parent_contactno: Yup.string().required(
            "Parent Contact No. is required."
          ),
        });
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: school_id,
      insert_by: school_emp_id,
      alloted_id: isEditRoom?.id ?? "",
      hostelId: isEditRoom?.fk_hostel ?? "",
      room_id: "",
      student_type: "",
      student_id: isEditRoom?.fk_student__fk_student__id ?? student?.id,
      student_name: isEditRoom?.student_name ?? "",
      parent_name: isEditRoom?.parent_name ?? "",
      address: isEditRoom?.address ?? "",
      contact_no: isEditRoom?.contact_no ?? "",
      parent_contactno: isEditRoom?.parent_contactno ?? "",
      hostel_fees: isEditRoom?.hostel_fees ?? "",
      no_of_installment: isEditRoom?.no_of_installment ?? "1",
      start_date: isEditRoom?.start_date ?? defaultDate,
      end_date: isEditRoom?.end_date ?? defaultDate,
      mess: isEditRoom?.mess ?? false,
      rack: isEditRoom?.rack ?? false,
      is_alloted: isEditRoom?.is_alloted === "Yes" ? true : false ?? false,
    },
    validationSchema: validationSchema,
    onSubmit: async (values) => {
      if (!id) {
        Swal.fire({
          text: "Room is not selected.",
          icon: "warning",
          allowOutsideClick: false,
        });
        return;
      }
      startLoading();
      try {
        const data = new FormData();
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("alloted_id", values.alloted_id);
        data.append("hostelId", values.hostelId);
        data.append("room_id", id ? id : "");
        data.append("student_type", inputType);
        data.append("student_id", values.student_id);

        data.append("student_name", values.student_name);
        data.append("parent_name", values.parent_name);
        data.append("address", values.address);
        data.append("contact_no", values.contact_no);
        data.append("parent_contactno", values.parent_contactno);

        data.append("hostel_fees", values.hostel_fees);
        data.append("no_of_installment", values.no_of_installment);
        data.append("start_date", values.start_date);
        data.append("end_date", values.end_date);
        data.append("mess", values.mess ? "True" : "False");
        data.append("rack", values.rack ? "True" : "False");
        data.append("is_alloted", values.is_alloted ? "Yes" : "No");
        // for (const entry of data.entries()) {
        //   const [key, value] = entry;
        //   console.log(key + " - " + value);
        // }
        const response = await HostelAPI.hostelAllotmenttoStudent(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          fetchRoomAllotmentList();
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error.message);
      } finally {
        stopLoading();
      }
    },
  });

  if (isLoading) return <Loader />;
  return (
    <div className="section-body">
      <div className="container-fluid">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Room Allotment</h1>
            </div>
            <div className=" mt-2">
              <button className="btn btn-primary btn-sm mx-1" type="submit">
                <i className="fa fa-plus px-1"></i>
                Save
              </button>
              <button
                type="button"
                className="btn btn-sm btn-outline-danger mx-1"
                onClick={() => handleClear()}
              >
                <i className="fa fa-times px-1"></i>
                Clear
              </button>
              <RoomAllotmentList
                allotmentList={allotmentList}
                setIsEditRoom={setIsEditRoom}
              />
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="card">
              <div className="card-body">
                <div className="form-group col-md-12 row">
                  <label className="col-md-3 col-form-label">
                    Student Type<span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9 d-flex align-items-center justify-content-around">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="content_file"
                        id="Regular"
                        value="Regular"
                        checked={inputType === "Regular"}
                        onChange={handleRadioChange}
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor="Regular" className="form-check-label">
                        Regular
                      </label>
                    </div>
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="radio"
                        name="content_file"
                        id="External"
                        value="External"
                        checked={inputType === "External"}
                        onChange={handleRadioChange}
                        style={{ cursor: "pointer" }}
                      />
                      <label htmlFor="External" className="form-check-label">
                        External
                      </label>
                    </div>
                  </div>
                </div>
                {inputType === "Regular" && (
                  <div className="col-md-12">
                    <div className="form-group row">
                      <label className="col-md-3 col-sm-6 col-form-label">
                        Student No<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 col-sm-6">
                        <div className="row">
                          <div className="input-group col-md-4 col-sm-6 mb-1">
                            <input
                              name="student_id"
                              type="text"
                              className="form-control"
                              placeholder="Student Code"
                              value={
                                student && student?.student_code
                                  ? student?.student_code
                                  : isEditRoom &&
                                    isEditRoom?.fk_student__fk_student__student_code
                                  ? isEditRoom?.fk_student__fk_student__student_code
                                  : ""
                              }
                              readOnly
                            />
                            <StudentSessionWiseList
                              setStudent={setStudent}
                              callFrom={"RoomAllotment"}
                              setstudent_uid={setstudent_uid}
                            />
                          </div>
                          <div className="col-sm-6 col-md-8 mb-1">
                            <input
                              type="text"
                              className="form-control "
                              placeholder="Student Name"
                              value={
                                student?.std_name
                                  ? `${student?.std_name} `
                                  : isEditRoom?.fk_student__fk_student__std_name
                                  ? isEditRoom?.fk_student__fk_student__std_name
                                  : ""
                              }
                              readOnly
                            />{" "}
                          </div>
                          {formik.touched.student_id &&
                          formik.errors.student_id ? (
                            <ErrorDisplay errorMsg={formik.errors.student_id} />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {inputType === "External" && (
                  <>
                    <div className="form-group col-md-12 row">
                      <label className="col-md-3 col-form-label">
                        Student Name<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 row">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Name"
                          name="student_name"
                          value={formik.values.student_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                        {formik.touched.student_name &&
                        formik.errors.student_name ? (
                          <ErrorDisplay errorMsg={formik.errors.student_name} />
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-md-12 row">
                      <label className="col-md-3 col-form-label">
                        Parent Name<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 row">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Parent Name"
                          name="parent_name"
                          value={formik.values.parent_name}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                        {formik.touched.parent_name &&
                        formik.errors.parent_name ? (
                          <ErrorDisplay errorMsg={formik.errors.parent_name} />
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group col-md-12 row">
                      <label className="col-md-3 col-form-label">
                        Address<span className="text-danger">*</span>
                      </label>
                      <div className="col-md-9 row">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Address"
                          name="address"
                          value={formik.values.address}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          autoComplete="off"
                        />
                        {formik.touched.address && formik.errors.address ? (
                          <ErrorDisplay errorMsg={formik.errors.address} />
                        ) : null}
                      </div>
                    </div>
                    <div className="col-md-12 row">
                      <div className="form-group col-md-6 row">
                        <label className="col-md-3 col-form-label">
                          Contact No <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Contact No"
                            name="contact_no"
                            value={formik.values.contact_no}
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                formik.handleChange(e);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            maxLength={10}
                          />
                          {formik.touched.contact_no &&
                          formik.errors.contact_no ? (
                            <ErrorDisplay errorMsg={formik.errors.contact_no} />
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group col-md-6 row">
                        <label className="col-md-4 col-form-label">
                          Parent Contact No{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <input
                            type="number"
                            className="form-control"
                            placeholder="Parent Contact No"
                            name="parent_contactno"
                            value={formik.values.parent_contactno}
                            onChange={(e) => {
                              if (e.target.value.length <= 10) {
                                formik.handleChange(e);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            maxLength={10}
                          />
                          {formik.touched.parent_contactno &&
                          formik.errors.parent_contactno ? (
                            <ErrorDisplay
                              errorMsg={formik.errors.parent_contactno}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
            <div className="card">
              <div className="card-header">
                <h3 className="card-title">Hostel Information</h3>
              </div>
              <div className="card-body">
                <div className="col-lg-10 col-md-12 col-sm-12">
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Select Hostel <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="hostelId"
                        placeholder="Select Hostel"
                        options={hostelList}
                        value={
                          hostelId
                            ? hostelList?.find(
                                (option) => option.value === hostelId
                              )
                            : null
                        }
                        onChange={(selectedOptions) => {
                          setHostelId(selectedOptions.value);
                          formik.setFieldValue(
                            "hostelId",
                            selectedOptions.value
                          );
                        }}
                        isSearchable={true}
                      />
                      {formik.touched.hostelId && formik.errors.hostelId ? (
                        <ErrorDisplay errorMsg={formik.errors.hostelId} />
                      ) : null}
                      {/* {idValidationError && !hostelId ? (
                      <ErrorDisplay errorMsg={"Hostel is required !"} />
                    ) : null} */}
                    </div>
                  </div>
                  {isRoom ? (
                    <div className="d-flex">
                      <Table size="sm" className="table-bordered" striped>
                        <thead className="">
                          <tr className="text-center text-nowrap ">
                            <th>Floor No.</th>
                            <th>Room No.</th>
                            <th>Bed No.</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr className="">
                            <td className="text-center">{floorNo}</td>
                            <td className="text-center">{roomNo}</td>
                            <td className="text-center">{`${floorNo}-${roomNo}-${bedNo}`}</td>
                          </tr>
                        </tbody>
                      </Table>
                      <div className="text-center">
                        <button
                          className="btn btn-sm"
                          onClick={() => setIsRoom("")}
                          type="button"
                        >
                          <i
                            className="fa fa-times"
                            style={{ color: "red", fontSize: "1rem" }}
                          ></i>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="form-group d-flex justify-content-end">
                      <AvailabletyRoom
                        hostelId={hostelId}
                        setIsRoom={setIsRoom}
                      />
                    </div>
                  )}

                  <div className="form-group  row">
                    <div className="col-md-6">
                      <div className="row">
                        <label className="col-md-6 col-form-label">
                          Hostel Fees <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control text-right"
                            placeholder="Hostel Fees"
                            name="hostel_fees"
                            value={formik.values.hostel_fees}
                            onChange={(e) => {
                              const inputValue = e.target.value;
                              if (
                                /^\d*$/.test(inputValue) &&
                                inputValue.length <= 10
                              ) {
                                formik.handleChange(e);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                          />
                          {formik.touched.hostel_fees &&
                          formik.errors.hostel_fees ? (
                            <ErrorDisplay
                              errorMsg={formik.errors.hostel_fees}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row d-none">
                        <label className="col-md-6 col-form-label">
                          No Of Installment{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-6">
                          <input
                            type="text"
                            className="form-control text-right"
                            placeholder="No Of Installment"
                            name="no_of_installment"
                            value={formik.values.no_of_installment}
                            onChange={(e) => {
                              const inputValue=e.target.value;
                              if (/^\d*$/.test(inputValue) && inputValue.length <= 10) {
                                formik.handleChange(e);
                              }
                            }}
                            onBlur={formik.handleBlur}
                            autoComplete="off"
                            
                          />
                          {formik.touched.no_of_installment &&
                          formik.errors.no_of_installment ? (
                            <ErrorDisplay
                              errorMsg={formik.errors.no_of_installment}
                            />
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <div className="row">
                        <CheckButton
                          label="Mess"
                          formik={formik}
                          fieldName="mess"
                        />
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="row">
                        <CheckButton
                          label="Rack"
                          formik={formik}
                          fieldName="rack"
                        />
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <div className="form-group row">
                        <label className="col-md-6 col-form-label">
                          Start Date <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-6">
                          <input
                            type="date"
                            name="start_date"
                            className="form-control"
                            value={formik.values.start_date || ""}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="form-group  row">
                        <label className="col-md-6 col-form-label">
                          End Date <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-6">
                          <input
                            type="date"
                            name="end_date"
                            className="form-control"
                            value={formik.values.end_date || ""}
                            onChange={formik.handleChange}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="form-group row">
                    <div className="col-md-6">
                      <div className="row">
                        <CheckButton
                          label="Alloted"
                          formik={formik}
                          fieldName="is_alloted"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default RoomAllotment;
