export const GET_CLASS_SECTION_REQUEST = "GET_CLASS_SECTION_REQUEST";
export const GET_CLASS_SECTION_SUCCESS = "GET_CLASS_SECTION_SUCCESS";
export const GET_CLASS_SECTION_FAILURE = "GET_CLASS_SECTION_FAILURE";

export const GET_STUDENTLIST_REQUEST = "GET_STUDENTLIST_REQUEST";
export const GET_STUDENTLIST_SUCCESS = "GET_STUDENTLIST_SUCCESS";
export const GET_STUDENTLIST_FAILURE = "GET_STUDENTLIST_FAILURE";

export const TAKE_STUDENTATTENDANCE_REQUEST = "TAKE_STUDENTATTENDANCE_REQUEST";
export const TAKE_STUDENTATTENDANCE_SUCCESS = "TAKE_STUDENTATTENDANCE_SUCCESS";
export const TAKE_STUDENTATTENDANCE_FAILURE = "TAKE_STUDENTATTENDANCE_FAILURE";


export const SAVE_STUDENTATTENDANCE_REQUEST = "SAVE_STUDENTATTENDANCE_REQUEST";
export const SAVE_STUDENTATTENDANCE_SUCCESS = "SAVE_STUDENTATTENDANCE_SUCCESS";
export const SAVE_STUDENTATTENDANCE_FAILURE = "SAVE_STUDENTATTENDANCE_FAILURE";

export const GET_STUDENTREPORT_REQUEST = "GET_STUDENTREPORT_REQUEST";
export const GET_STUDENTREPORT_SUCCESS = "GET_STUDENTREPORT_SUCCESS";
export const GET_STUDENTREPORT_FAILURE = "GET_STUDENTREPORT_FAILURE";
