import React, { useEffect, useState } from "react";
import Select from "react-select";
import { ProfileAPI } from "../../../api/Profile/Profile";
import useLocalStorageData from "../Comman/LocalStorageData";
import useLoading from "../../CommonComponent/useLoading";
import pic1 from "../../../assets/images/dummy.png";
import Loader from "../../Loader/Loader";
import { MEDIA_BASE_URL } from "../../../Config/BaseUrl";
const Profile = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [schoolInfo, setSchoolInfo] = useState([]);

  const {
    organization_name,
    school_name,
    udise_no,
    contact_person,
    contact_number,
    contact_email,
    address,
    state,
    city,
    pincode,
    status,
    is_government,
    school_logo,
    module,
  } = schoolInfo;

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const data = {
          school_id: school_id,
        };
        const response = await ProfileAPI.getProfile(data);
        let resData = response.data;
        setSchoolInfo(resData.schoolInfo);
      } catch (error) {
        console.error(error.message);
      } finally {
        stopLoading();
      }
    };

    fetchData();
  }, [school_id]);
  if (isLoading) return <Loader />;
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">School Profile</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab"></ul>
          </div>
        </div>
      </div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-md-8">
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Organization
                      <span className="text-danger"></span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="organization_id"
                        placeholder="Select "
                        value={{
                          label: organization_name,
                          value: organization_name,
                        }}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      School Name
                      <span className="text-danger"></span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter School Name"
                        name="name"
                        value={school_name}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label
                      className="col-md-3 col-form-label"
                      htmlFor="udisnumber"
                    >
                      U-DISE No
                      <span className="text-danger"></span>
                    </label>
                    <div className="col-md-9">
                      <input
                        id="udisnumber"
                        type="text"
                        className="form-control"
                        placeholder="Please Enter U-DISE Number"
                        name="udise_no"
                        value={udise_no}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Government <span className="text-danger"></span>
                    </label>

                    <div className="col-md-9 d-flex  align-items-center">
                      <label className="custom-switch pr-2">
                        <input
                          type="checkbox"
                          className="custom-switch-input"
                          name="is_government"
                          checked={is_government}
                          readOnly
                        />
                        <span className="custom-switch-indicator"></span>
                      </label>
                      {is_government ? <b>Yes</b> : <b>No</b>}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Address
                      <span className="text-danger"></span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Address"
                        name="address"
                        value={address}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      State <span className="text-danger"></span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        placeholder="Select "
                        value={{
                          label: state,
                          value: state,
                        }}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      City <span className="text-danger"></span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="city_id"
                        placeholder="Select "
                        value={{
                          label: city,
                          value: city,
                        }}
                        isDisabled={true}
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Pincode <span className="text-danger"></span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Pincode"
                        name="pincode"
                        value={pincode}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Status <span className="text-danger"></span>
                    </label>

                    <div className="col-md-9 d-flex  align-items-center">
                      <label className="custom-switch pr-2">
                        <input
                          type="checkbox"
                          className="custom-switch-input"
                          name="status"
                          checked={status}
                          readOnly
                        />
                        <span className="custom-switch-indicator"></span>
                      </label>
                      {status ? <b>Yes</b> : <b>No</b>}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Contact Person <span className="text-danger"></span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact Person"
                        name="contact_person"
                        value={contact_person}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Contact Number
                      <span className="text-danger"></span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Contact Number"
                        name="contact_number"
                        value={contact_number}
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Contact Email <span className="text-danger"></span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="email"
                        className="form-control"
                        placeholder="Enter Contact Email"
                        name="contact_email"
                        value={contact_email}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
                <div className="col-md-4 d-flex justify-content-center">
                  <div>
                    <img
                      alt="profile"
                      src={
                        school_logo==="NA"  ? pic1  : `${MEDIA_BASE_URL}${school_logo}` 
                      }
                      className="crop_img"
                      width={100}
                    />
                   
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
