import React, { useState } from "react";

const ValidationComponent = ({ data, requiredFields }) => {
  const [validationError, setValidationError] = useState(false);

  const validateFields = (data, requiredFields) => {
    let validationStatus = true;
    requiredFields.forEach((field) => {
     
      if (!data[field]) {
        validationStatus = false;
      }
    });
    return validationStatus;
  };

  const validateControl = async () => {
    const isValid = validateFields(data, requiredFields);
    if (!isValid) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 3000);
    }

    return isValid;
  };

  return { validationError, validateControl };
};

export default ValidationComponent;
