import React, { useEffect } from "react";
import Select from "react-select";
import { useState } from "react";
import { Table } from "reactstrap";
import { Heading } from "../../CommonComponent/heading";
import { useFormik } from "formik";
import * as Yup from "yup";
import useLocalStorageData from "../Comman/LocalStorageData";
import { SalaryHeadMasterAPI } from "../../../api/Payroll/SalaryHeadMasterAPI";
import Swal from "sweetalert2";
import useLoading from "../../CommonComponent/useLoading";
import Loader from "../../Loader/Loader";


const SalaryHeadMaster = () => {
  const { isLoading, startLoading, stopLoading } = useLoading();
  // imported this for session data
  const { school_id, token, school_emp_id } = useLocalStorageData();

  // option list for head type
  const headOptions = [
    { value: "Earning", label: "Earning" },
    { value: "Deduction", label: "Deduction" },
  ];

  const [checkbox1, setCheckbox1] = useState(false);
  const [checkbox2, setCheckbox2] = useState(false);
  const [checkbox3, setCheckbox3] = useState(false); //for Fixed Amount
  const [checkbox4, setCheckbox4] = useState(true);
  const [checkbox5, setCheckbox5] = useState(false);//fro manual input
  const [salaryHeadList, setSalaryHeadList] = useState([]);
  const [selectedObject, setSelectedObject] = useState([]);
 

  const [SequenceNo, setSequenceNo] = useState();

  // const handleCheckbox1Change = (event) => {
  //   setCheckbox1(event.target.checked);
  // };

  // useEffect(() => {
  //   setSequenceNo(salaryHeadList.length + 1);
  // }, [salaryHeadList]);

  const getSalaryHeads = async () => {
    let data = {
      school_id: school_id,
    };
    try {
      startLoading()
      const response = await SalaryHeadMasterAPI.get_salary_heads(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        const sortedSalaryHeadList = responseData.salary_heads.sort((a, b) => a.sequence - b.sequence);
        setSalaryHeadList(sortedSalaryHeadList);
        if(sortedSalaryHeadList.length > 0){
        const LastIndex = sortedSalaryHeadList.length-1
        console.log(LastIndex)
        setSequenceNo(Number(sortedSalaryHeadList[LastIndex].sequence) + Number(1));
        }else{
          setSequenceNo(1)
        }
      }
    } catch (err) {
      console.error(err);
    }finally{
      stopLoading()
    }
  };
  useEffect(() => {
    getSalaryHeads();
  }, []);
 
  console.log(SequenceNo,"sequence")


 
  
  const isSequenceDuplicate = (sequence) => {
    if(!selectedObject?.id){
    return salaryHeadList.some(head => head.sequence === sequence);
    }
    
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      heads_id: selectedObject?.id || "",
      school_id: school_id,
      heads_name: selectedObject?.heads_name || "",
      heads_type: selectedObject?.heads_type || "",
      insert_by: school_emp_id,
      sequence: SequenceNo ||"" ,
    },
    validationSchema: Yup.object().shape({
      heads_name: Yup.string().required("Heads name  is required"),
      heads_type: Yup.string().required("Head type  is required"),
      sequence: Yup.string().required("Sequence is required"),
    }),
    onSubmit: async (values) => {
      if (isSequenceDuplicate(values.sequence )) {
        
        Swal.fire({
          text: `Sequence Number ${values.sequence} Already Exist`,
              icon: "question",
              
        })
        return
      }
     
      let data = {
        ...values,
        marks_as_pt: checkbox1 ? "True" : "False",
        marks_as_pf: checkbox2 ? "True" : "False",
        fixed_amount: checkbox3 ? "True" : "False",
        is_active: checkbox4 ? "True" : "False",
        manual_input: checkbox5 ? "True" : "False",
      };
      // console.log(indexOfSelectedObject, values.sequenceNo);
      // if (indexOfSelectedObject === values.sequenceNo) {
      //   Swal.fire({
      //     text: `Do You want to Change The Sequence Number of ${values.heads_name} ?`,
      //     icon: "question",
      //     showDenyButton: true,
      //     confirmButtonColor: "#3085d6",
      //     cancelButtonColor: "#d33",
      //     confirmButtonText: "Yes",
      //     denyButtonText: `No`,
      //     allowOutsideClick: false,
      //   });
      //   return;
      // } else {
      //   Swal.fire({
      //     text: `SucessFully Updated`,
      //     icon: "success",
      //     showDenyButton: true,
      //     confirmButtonColor: "#3085d6",
      //     cancelButtonColor: "#d33",
      //     confirmButtonText: "Yes",
      //     denyButtonText: `No`,
      //     allowOutsideClick: false,
      //   });
      // }

      // console.log(data);

      try {
        const response = await SalaryHeadMasterAPI.add_update_salary_heads(
          data
        );
        const responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
          });
          handelClear();
          getSalaryHeads();
        }else{
          Swal.fire({
            text: responseData.msg,
            icon: "error",
          });
        }
      } catch (err) {
        console.error(err);
      }
    },
  });


  const handleCheckbox5Change = (e) => {
    setCheckbox5(e.target.checked);
    if (e.target.checked) {
      setCheckbox3(false);
    }
  };

  const handleCheckbox3Change = (e) => {
    setCheckbox3(e.target.checked);
    if (e.target.checked) {
      setCheckbox5(false);
    }
  };
  // handel clear button is consist of data shoud be reset and user can add new fee head
  const handelClear = () => {
    formik.resetForm();
    setCheckbox1(false);
    setCheckbox2(false);
    setCheckbox3(false);
    setCheckbox4(false);
    setCheckbox5(false);
    setSelectedObject([]);
    setSequenceNo(salaryHeadList.length+1);
  };
  const handleDeleteClick = (ids) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          heads_id: ids,
          school_id: school_id,
        };
        SalaryHeadMasterAPI.deleteSalaryHeads(data)
          .then((response) => {
            const responseData = response.data;
            if (responseData.status === 200) {
              Swal.fire({
                text: responseData.msg,
                icon: "success",
              });
              getSalaryHeads();
              handelClear();
            } else {
              Swal.fire({
                text: responseData.msg,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            Swal.fire({
              text: "An error occurred while processing your request.",
              icon: "error",
            });
          });
      }
    });
  };
  const handleEditClick = (id, Index) => {

    var SelectedObject1 = salaryHeadList.find((head) => head.id === id);
    setSelectedObject(SelectedObject1);
    setSequenceNo(SelectedObject1.sequence);
    setCheckbox1(SelectedObject1.marks_as_pt);
    setCheckbox2(SelectedObject1.marks_as_pf);
    setCheckbox3(SelectedObject1.fixed_amount);
    setCheckbox4(SelectedObject1.is_active);
    setCheckbox5(SelectedObject1.manual_input);
  };
  if (isLoading) return <Loader />; 

  return (
    <form onSubmit={formik.handleSubmit}>
      <div className="container-fluid">
        <Heading pageTitle={"Salary Head Master"}>
          <div className="d-flex">
            <button
              type="button"
              className="btn btn-outline-secondary btn-sm mx-1"
              onClick={() => handelClear()}
            >
              <i className="fa fa-pencil px-1"></i>
              New
            </button>
            <button className="btn btn-primary btn-sm mx-1" type="submit">
              <i className="fa fa-plus px-1"></i>
              Save
            </button>
          </div>
        </Heading>
        <div className="card mt-2">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="form-group row">
                  <label className="col-lg-2 col-form-label">
                    Head Name
                    <span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-4">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Head Name"
                      name="heads_name"
                      value={formik.values.heads_name}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.heads_name && formik.errors.heads_name && (
                      <div className="text-danger">
                        {formik.errors.heads_name}
                      </div>
                    )}
                  </div>
                  <label className="col-lg-2 col-form-label">
                    Head Type<span className="text-danger">*</span>
                  </label>
                  <div className="col-lg-4">
                    <Select
                      name="status"
                      options={headOptions}
                      placeholder="Select Head type"
                      isSearchable={true}
                      value={
                        formik.values.heads_type
                          ? headOptions?.find(
                              (g) => g.value === formik.values.heads_type
                            )
                          : null
                      }
                      onChange={(selectedOptions) =>
                        formik.setFieldValue(
                          "heads_type",
                          selectedOptions?.value || ""
                        )
                      }
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.heads_type && formik.errors.heads_type && (
                      <div className="text-danger">
                        {formik.errors.heads_type}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-6">
                <div className="form-group row">
                  <label className=" col-form-label col-lg-3">
                    Fixed Amount
                    <span className="text-danger"></span>
                  </label>

                  <label className="custom-switch ">
                    <input
                      type="checkbox"
                      className="custom-switch-input mx-1"
                      name="login_user"
                      checked={checkbox3}
                      onChange={handleCheckbox3Change}
                    />
                    <span className="custom-switch-indicator"></span>
                  </label>

                  <label className=" col-form-label col-lg-2">
                    Active
                    <span className="text-danger"></span>
                  </label>
                  <label className="custom-switch ">
                    <input
                      type="checkbox"
                      className="custom-switch-input mx-1"
                      name="login_user"
                      checked={checkbox4}
                      onChange={(e) => setCheckbox4(e.target.checked)}
                    />
                    <span className="custom-switch-indicator"></span>
                  </label>

                  <label className=" col-form-label col-lg-3 ">
                    Manual Input
                    <span className="text-danger"></span>
                  </label>
                  <label className="custom-switch ">
                    <input
                      type="checkbox"
                      className="custom-switch-input mx-1"
                      name="login_user"
                      checked={checkbox5}
                      onChange={handleCheckbox5Change}
                    />
                    <span className="custom-switch-indicator"></span>
                  </label>
                </div>
              </div>

              <div className="col-lg-6 form-group row ">
                <label className="col-lg-4 col-form-label">
                  Sequence No
                  <span className="text-danger">*</span>
                </label>
                <div className="col-lg-4">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Enter sequence No"
                    name="sequence"
                    value={formik.values.sequence}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />{" "}
                  {formik.touched.sequence && formik.errors.sequence && (
                    <div className="text-danger">
                      {formik.errors.sequence}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body">
        <div className="card">
          <div class="card-header">
            <h3 class="card-title">Head Table</h3>
          </div>
          <div className="card-body">
            <div className="table-responsive">
              <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
                <thead>
                  <tr className="text-center">
                    <th>Sequence</th>
                    <th>Salary Head</th>
                    <th>Head Type</th>
                    <th>Fixed Amount</th>
                    <th>Manual Input</th>
                    <th>Active</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {salaryHeadList.map((head, index) => (
                    <tr key={head.id}>
                      <td className="text-center">{head.sequence}</td>
                      <td>{head.heads_name}</td>
                      <td>{head.heads_type}</td>
                      <td className="text-center">
                        {head.fixed_amount ? "Yes" : "No"}
                      </td>
                      <td className="text-center">
                        {head.manual_input ? "Yes" : "No"}
                      </td>
                      <td className="text-center">
                        {head.is_active ? "Yes" : "No"}
                      </td>
                      <td className="text-center">
                        <i
                          class="fa fa-trash-o text-danger mx-1"
                          onClick={() => handleDeleteClick(head.id)}
                        >
                          {" "}
                        </i>
                        <i
                          class="fa fa-edit mx-1"
                          onClick={() => handleEditClick(head.id, index)}
                        ></i>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default SalaryHeadMaster;
