import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
import Swal from "sweetalert2";
import {
  GET_CLASS_SECTION_FAILURE,
  GET_CLASS_SECTION_REQUEST,
  GET_CLASS_SECTION_SUCCESS,
  GET_STUDENTLIST_FAILURE,
  GET_STUDENTLIST_REQUEST,
  GET_STUDENTLIST_SUCCESS,
  GET_STUDENTREPORT_FAILURE,
  GET_STUDENTREPORT_REQUEST,
  GET_STUDENTREPORT_SUCCESS,
  SAVE_STUDENTATTENDANCE_FAILURE,
  SAVE_STUDENTATTENDANCE_REQUEST,
  SAVE_STUDENTATTENDANCE_SUCCESS,
  TAKE_STUDENTATTENDANCE_FAILURE,
  TAKE_STUDENTATTENDANCE_REQUEST,
  TAKE_STUDENTATTENDANCE_SUCCESS,
} from "../../Constants/school_user/Attendance";

export const getClassSection = (token, s_data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CLASS_SECTION_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_class_and_section/`,
        s_data,
        config
      );
      const data = response.data;
      dispatch({ type: GET_CLASS_SECTION_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_CLASS_SECTION_FAILURE,
        payload: error,
      });
    }
  };
};
export const getStudentList = (token, stu_data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_STUDENTLIST_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}student_attendance_list/`,
        stu_data,
        config
      );
      const data = response.data;
      dispatch({ type: GET_STUDENTLIST_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_STUDENTLIST_FAILURE,
        payload: error,
      });
    }
  };
};
export const takeAttendance = (token, stu_data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: TAKE_STUDENTATTENDANCE_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}student_attendance_action/`,
        stu_data,
        config
      );
      const data = response.data;
      dispatch({ type: TAKE_STUDENTATTENDANCE_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: TAKE_STUDENTATTENDANCE_FAILURE,
        payload: error,
      });
    }
  };
};
export const getAttendanceReport = (token, stu_data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_STUDENTREPORT_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_attendance_report/`,
        stu_data,
        config
      );
      const data = response.data;
      dispatch({ type: GET_STUDENTREPORT_SUCCESS, payload: data });
      return data
    } catch (error) {
      dispatch({
        type: GET_STUDENTREPORT_FAILURE,
        payload: error,
      });
    }
  };
};


export const saveAttendance = (token, stu_data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SAVE_STUDENTATTENDANCE_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}save_student_attendance/`,
        stu_data,
        config
      );
      const data = response.data;
      dispatch({ type: SAVE_STUDENTATTENDANCE_SUCCESS, payload: data });
      return data
    } catch (error) {
      dispatch({
        type: SAVE_STUDENTATTENDANCE_FAILURE,
        payload: error,
      });
    }
  };
};