import { CiSearch } from "react-icons/ci";
import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Form,
  Table
} from "reactstrap";

import {DriverMasterAPI} from "../../../api/Transport/DriverMasterAPI"
import EmptyListMessage from "../../CommonComponent/EmptyListMessage";

const DriverSearch = (props) => {
  const { setDriverId, school_id } = props;
  const [open, setOpen] = useState(false);
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const [driverDetails, setDriverDetails] = useState([]);
  console.log(driverDetails);
  const toggle = () => setOpen(!open);
 const getDriverData = async () => {
   try {
     let data = {
       school_id: school_id,
     };
     const response = await DriverMasterAPI.getDrivermasterdata(data);
     const responseData = response.data;
     const busResponse = responseData.driver_master_data
     if (responseData.status === 200) {
    
       setDriverDetails(busResponse);
     } else {
     }
   } catch (err) {
     console.error(err);
   }
 }
 const handelSearch = () => {
toggle()
getDriverData();
 };
 const handleSelectGroup = (Id) => {
  setDriverId(Id);
  toggle();
};

  return (
    <>
      <div>
        <button type="button" className="btn btn-outline-secondary btn-sm mx-1"  onClick={handelSearch}>
          <CiSearch /> Search

        </button>
      </div>
      <Modal returnFocusAfterClose={focusAfterClose} isOpen={open} fullscreen className="mobel_box">
        <ModalBody>
        <div className="table-responsive"> 
          {driverDetails && driverDetails.length > 0 ? (
                <Table size="sm" className="table-bordered" striped>
                  <thead className="table-dark">
                    <tr className="text-center text-nowrap ">
                      <th>Sr No.</th>
                      <th>Driver Name</th>
                      <th>Type</th>
                      <th>DOB</th>
                      <th>Phone No</th>
                      <th>EmgContact No</th>
                      <th>Address</th>
                      <th>Driver License</th>
                      <th>LcnsIssDate</th>
                      <th>LcnsExprDate</th>
                      <th>Join Date</th>
                     <th>Releaving Date</th> 
                    </tr>
                  </thead>
                  <tbody>
                    {driverDetails.map((stu, index) => (
                      <tr
                        key={index}
                        className="clickable-row-color"
                        onClick={() => handleSelectGroup(stu.id)}
                      >
                        <td className="text-center">{index + 1}</td>
                        <td>{stu.driver_attendant_name || stu.attendant_name}</td>
                        <td>{stu.emp_type}</td>
                        <td>{stu.dob}</td>
                        <td>{stu.phone_no}</td>
                        <td>{stu.emergency_contact_no}</td>
                        <td>{stu.address}</td>
                        <td>{stu.license_no}</td>
                        <td>{stu.license_issue_date}</td>
                        <td>{stu.license_expiry_date}</td>
                        <td>{stu.joining_date}</td>
                        <td>{stu.releaving_date}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              ) : (
                <EmptyListMessage
                  message={"Driver Data Not Available !"}
                  Div="div"
                />
              )}

        </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default DriverSearch;
