import { useFormik } from "formik";
import React from "react";
import { read, utils, writeFile } from "xlsx";
import { useState, useRef, useEffect } from "react";
import { ModalHeader, Table } from "reactstrap";
import * as Yup from "yup";
import { MEDIA_BASE_URL } from "../../../Config/BaseUrl";
import { UploadAttendanceAPI } from "../../../api/Payroll/UplodeAttendenceAPI";
import useLocalStorageData from "../Comman/LocalStorageData";
import Swal from "sweetalert2";
import moment from "moment";
import Loader from "../../Loader/Loader";
import useLoading from "../../CommonComponent/useLoading";

import {
  Button,
  Modal,
  ModalBody,
  ModalFooter,
  Label,
  Input,
  FormGroup,
  Form,
} from "reactstrap";
const UploadAttendance = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [bioMachine, setBioMachine] = useState(false);
  const [excel, setExcel] = useState(false);
  const [date1, setDate1] = useState("");
  const [machine_type, setMachine_type] = useState("");
  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);
  const [fileName, setFileName] = useState("Choose file");
  const [employeeList, setEmployeeList] = useState([]);
  const [open, setOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(null);
  const [empName, setEmpName] = useState("");
  const [empNo, setEmpNo] = useState("");
  const [AttendenceDate, setAttendenceDate] = useState("");
  const [inTime, setIntime] = useState("");
  const [outTime, setOuttime] = useState("");
  const { isLoading, startLoading, stopLoading } = useLoading();

  const handleChange1 = (event) => {
    setBioMachine(event.target.checked);
    setExcel(false);
    setMachine_type("bio_machine");
  };
  const handleChange2 = (event) => {
    setExcel(event.target.checked);
    setBioMachine(false);
    setMachine_type("excel");
  };

  const getEmployeeList = async () => {
    let data = {
      school_id: school_id,
      attendence_month: date1,
    };
    try {
      const response = await UploadAttendanceAPI.getAttendance(data);
      const responseData = response.data;
      if (responseData.status === 200) {
     
  
        // Process the data to sort and group by employee code
        const sortedAndGroupedData = sortAndGroupData(responseData.leave_opening);
  
        // Set the processed data to state
        setEmployeeList(sortedAndGroupedData);
      } else {
        setEmployeeList([]);
      }
    } catch (err) {
      console.error(err);
    } finally {
      // Any cleanup if necessary
    }
  };
  
  // Utility function to sort and group data by employee code
  const sortAndGroupData = (data) => {
    const groupedData = data.reduce((acc, item) => {
      if (!acc[item.fk_staff__emp_no]) {
        acc[item.fk_staff__emp_no] = [];
      }
      acc[item.fk_staff__emp_no].push(item);
      return acc;
    }, {});
  
    const sortedData = Object.keys(groupedData)
      .sort()
      .reduce((acc, key) => {
        acc.push(...groupedData[key]);
        return acc;
      }, []);
  
    return sortedData;
  };
  useEffect(() => {
    getEmployeeList();
  }, [date1]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!date1) {
      Swal.fire({
        text: "Please select a date",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }
    if (!file) {
      Swal.fire({
        text: "Please select a file",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }

   
    let data = {
      school_id: school_id,
      machine_type: machine_type,
      attendance_sheet: file,
      attendance_month: date1,
      overrite_type: "Normal",
    };
    const formData = new FormData();
    formData.append("school_id", data.school_id);
    formData.append("machine_type", data.machine_type);
    formData.append("attendance_sheet", file);
    formData.append("attendance_month", data.attendance_month);
    formData.append("is_overrite", "False");
    formData.append("overrite_type", "Normal");
    startLoading()
    try {
      const response = await UploadAttendanceAPI.uploadEmployeeAttendance(
        formData
      );
      const responseData = response.data;
      if (responseData.status === 200) {
        Swal.fire({
          text: "File uploaded successfully.",
          icon: "success",
          allowOutsideClick: false,
        });
        stopLoading()
        getEmployeeList();
      } else {
        stopLoading()
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });

        if (responseData.is_overrite === true) {
          Swal.fire({
            text: `Do you want to over Write`,
            icon: "question",
            allowOutsideClick: false,
            showDenyButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#d33",
            confirmButtonText: "Yes",
            denyButtonText: `No`,
          }).then(async (result) => {
            if (result.isConfirmed) {
              startLoading()
              const data = {
                school_id: school_id,
                machine_type: machine_type,
                overrite_type: "Overrite",
                attendance_sheet: file,
                attendance_month: date1,
                is_overrite: "False",
              };
              const formData = new FormData();
              formData.append("school_id", data.school_id);
              formData.append("machine_type", data.machine_type);
              formData.append("attendance_sheet", file);
              formData.append("attendance_month", data.attendance_month);
              formData.append("is_overrite", "False");
              formData.append("overrite_type", "Overrite");
              try {
                const response =
                  await UploadAttendanceAPI.uploadEmployeeAttendance(formData);
                const responseData = response.data;
                if (responseData.status === 200) {
                  Swal.fire({
                    text: "File uploaded successfully.",
                    icon: "success",
                    allowOutsideClick: false,
                  });
                  getEmployeeList();
                } else {
                  stopLoading()
                  Swal.fire({
                    text: responseData.msg,
                    icon: "info",
                    allowOutsideClick: false,
                  });
                }
              } catch (error) {
                console.error(error);
              }finally{
                stopLoading()
              }
            } else {
            }
          });
        }
      }

      // if (responseData.status === 200) {
      //   Swal.fire({
      //     text: "File uploaded successfully.",
      //     icon: "success",

      //     allowOutsideClick: false,
      //   });
      // } else {
      //   Swal.fire({
      //     text: `Upload failed: ${result.message}`,
      //     icon: "error",
      //     allowOutsideClick: false,
      //   });
      // }
    } catch (error) {
      // Swal.fire({
      //   text: `An error occurred: ${error.message}`,
      //   icon: "error",
      //   allowOutsideClick: false,
      // });
    }
  };

  const handleImport = (e) => {
    const file = e.target.files[0];
    if (file) {
      // Check if the file type is CSV
      if (file.type !== "text/csv") {
        Swal.fire({
          text: "Please upload a CSV file.",
          icon: "error",
          allowOutsideClick: false,
        });
        setFileName("Choose file");
        // Clear the file input
        fileInputRef.current.value = "";
      } else {
        setFile(file);
        setFileName(file.name);
      }
    } else {
      setFileName("Choose file");
    }
  };
  const handelClear = () => {
    setDate1("");
    setExcel(false);
    setBioMachine(false);
    setMachine_type("");
    setFile(null);
    setFileName("Choose file");
    setEmployeeList([]);
  };
  const handleEditClick = (
    id,
    fname,
    empNo,
    AttendenceDate,
    inTime,
    outTime
  ) => {
    setEmpName(fname);
    setEmpNo(empNo);
    setSelectedId(id);
    setAttendenceDate(AttendenceDate);
    setIntime(inTime);
    setOuttime(outTime);

    console.log(id);
    toggle();
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      in_time: inTime || "",
      out_time: outTime || "",
      attendance_date: AttendenceDate || "",
      id: selectedId || "",
    },
    validationSchema: Yup.object().shape({
      in_time: Yup.string()
        .required("In Time is required"),
        // .test(
        //   "outTimeAfterInTime",
        //   "Out time must be after in time",
        //   function (value) {
        //     const { out_time } = this.parent; // Access out_time from parent form values
        //     if (!out_time) {
        //       return true; // Allow empty out_time if not yet entered
        //     }

        //     const inTimeMoment = moment(value, "HH:mm"); // Parse in_time as moment object
        //     const outTimeMoment = moment(out_time, "HH:mm"); // Parse out_time as moment object

        //     // Handle dates spanning midnight (24-hour format)
        //     if (
        //       inTimeMoment.isAfter(moment().startOf("day")) &&
        //       outTimeMoment.isBefore(moment().startOf("day"))
        //     ) {
        //       // In time is after previous midnight and out time is before current midnight, consider them on the same day
        //       return outTimeMoment.isAfter(inTimeMoment);
        //     }

        //     return (
        //       outTimeMoment.isAfter(inTimeMoment) ||
        //       outTimeMoment.isSame(inTimeMoment)
        //     ); // Allow same time
        //   }
        // ),
      // out_time: Yup.string()
        // .required("Out Time is required")
        // .test(
        //   "outTimeAfterInTime",
        //   "Out time must be after in time",
        //   function (value) {
        //     const { in_time } = this.parent; // Access in_time from parent form values
        //     if (!in_time) {
        //       return true; // Allow empty out_time if in_time not yet entered
        //     }

        //     const inTimeMoment = moment(in_time, "HH:mm"); // Parse in_time as moment object
        //     const outTimeMoment = moment(outTime, "HH:mm"); // Parse out_time as moment object

        //     // Handle dates spanning midnight (24-hour format)
        //     if (
        //       inTimeMoment.isAfter(moment().startOf("day")) &&
        //       outTimeMoment.isBefore(moment().startOf("day"))
        //     ) {
        //       // In time is after previous midnight and out time is before current midnight, consider them on the same day
        //       return outTimeMoment.isAfter(inTimeMoment);
        //     }

        //     return (
        //       outTimeMoment.isAfter(inTimeMoment) 
        //       // outTimeMoment.isSame(inTimeMoment)
        //     ); // Allow same time
        //   }
        // ),
    }),

    onSubmit: async (values) => {
      startLoading()
      const data = {
        ...values,
        in_time:
          values.in_time.length > 6 ? values.in_time : `${values.in_time}:00`,
        out_time:
          values.out_time.length > 6
            ? values.out_time
            : `${values.out_time}:00`,
      };
     
      try {
        const response = await UploadAttendanceAPI.updateAttendanceEntry(data);
        const responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          getEmployeeList();  
          toggle();
        } else {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }finally{
        stopLoading()
      }
    },
  });

  const handelSelectedId = (id) => {
    alert(id);
  };

  const [checkIn, setCheckIn] = useState("");
  const [checkOut, setCheckOut] = useState("");
  const [checkOutError, setCheckOutError] = useState("");

  const handleCheckOutChange = (e) => {
    const value = e.target.value;
    setCheckOut(value);

    // Validate check-out time
    const checkOutTime = new Date(`01/01/2020 ${value}`);
    const midnight = new Date(`01/01/2020 00:00`);
    if (checkOutTime > midnight) {
      setCheckOutError("");
    } else {
      setCheckOutError("Check-out time cannot be before midnight.");
    }
  };
  const toggle = () => setOpen(!open);


  
  if (isLoading) return <Loader />;
  return (
    <div className="section-body">
      <div className="d-flex justify-content-between align-items-center m-2">
        <div className="header-action">
          <h1 className="page-title">Upload Attendance</h1>
        </div>

        <div className="input-group-append">
          <button
            type="button"
            className="btn btn-outline-danger mx-1"
            onClick={handelClear}
          >
            <i className="fa fa-times px-1"></i>
            Clear
          </button>
        </div>
      </div>
      <form>
        <div className="container">
          <div className="card">
            <div className="card-body">
              <div className="col-lg-12 row form-group ">
                <div className="col-lg-2 ">
                  <label className="col-form-label ">Month & Year</label>
                </div>
                <div className="col-lg-5">
                  <input
                    type="month"
                    className="form-control"
                    name="month"
                    value={date1}
                    onChange={(e) => setDate1(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-lg-12 row form-group">
                <div className="col-lg-2">
                  <label className="col-form-label">From</label>
                </div>
                <div className="col-lg-3">
                  <label className="custom-switch">
                    <input
                      type="checkbox"
                      className="custom-switch-input "
                      name="custom-switch-checkbox"
                      id="smsCheckbox"
                      onChange={handleChange1}
                      checked={bioMachine}
                    />
                    <span className="custom-switch-indicator mr-3"></span>
                    BioMachine
                  </label>
                </div>
                <div className="col-lg-3">
                  <label className="custom-switch ">
                    <input
                      type="checkbox"
                      className="custom-switch-input"
                      name="custom-switch-checkbox"
                      id="smsCheckbox"
                      onChange={handleChange2}
                      checked={excel}
                    />
                    <span className="custom-switch-indicator  mr-3 "></span>
                    Excel
                  </label>
                </div>
              </div>
            </div>
          </div>

          {bioMachine && (
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center">
                <h3 className="card-title">Employee List</h3>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                <table  className="table table-hover table-vcenter table-sm table-striped text-nowrap">
                  <thead>
                    <tr className="text-center">
                      <th>Sr No</th>
                      <th>Employee No</th>
                      <th>Employee Name</th>
                      <th>Date</th>
                      <th>In Time</th>
                      <th>Out Time</th>
                      <th>Total Working Hours </th>

                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                  {employeeList.map((head, index) => (
                        <tr key={head.id}>
                          <td className="text-center">{index + 1}</td>

                          <td>{head.fk_staff__emp_no}</td>
                          <td>{`${head.fk_staff__first_name} ${head.fk_staff__last_name}`}</td>
                          <td className="text-center">
                            {head.attendance_date}
                          </td>
                          <td className="text-center">{head.in_time}</td>
                          <td className="text-center">{head.out_time}</td>
                          <td className="text-center">{head.working_hours}</td>
                          <td className="text-center">
                            <i
                              class="fa fa-edit mx-1"
                              onClick={() =>
                                handleEditClick(
                                  head.id,
                                  `${head.fk_staff__first_name} ${head.fk_staff__last_name}`,
                                  head.fk_staff__emp_no,
                                  head.attendance_date,
                                  head.in_time,
                                  head.out_time
                                )
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                </div>
              </div>
            </div>
          )}
          {excel && (
            <div className="card">
              <div className="card-header d-flex justify-content-between align-items-center row">
                <h3 className="card-title">Employee List</h3>
                <div className="row">
                  <div className="col-lg-6 col-md-6 col-sm-12">
                    <div className="custom-file">
                      <input
                        type="file"
                        name="bulk_student_data"
                        className="custom-file-input form-control"
                        id="inputGroupFile"
                        ref={fileInputRef}
                        onChange={handleImport}
                        accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        required
                      />
                      <label
                        className="custom-file-label"
                        htmlFor="inputGroupFile"
                      >
                        {fileName}
                      </label>
                    </div>
                  </div>
                  <div className="col-lg-6 col-md-6 col-sm-12 row">
                    <button
                      className="btn btn-outline-secondary mx-1"
                      onClick={handleSubmit}
                    >
                      {" "}
                      <i class="fa fa-upload mx-1"></i>upload
                    </button>

                    <a
                      href={`${MEDIA_BASE_URL}uploadAttendance.csv`}
                      className="btn btn-primary"
                    >
                      Download <i className="fa fa-download"></i>
                    </a>
                  </div>
                </div>
              </div>
              <div className="card-body">
                <div className="table-responsive">
                  <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
                    <thead>
                      <tr className="text-center">
                        <th>Sr No</th>
                        <th>Employee No</th>
                        <th>Employee Name</th>
                        <th>Date</th>
                        <th>In Time</th>
                        <th>Out Time</th>
                        <th>Total Working Hours </th>

                        <th>Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {employeeList.map((head, index) => (
                        <tr key={head.id}>
                          <td className="text-center">{index + 1}</td>

                          <td>{head.fk_staff__emp_no}</td>
                          <td>{`${head.fk_staff__first_name} ${head.fk_staff__last_name}`}</td>
                          <td className="text-center">
                            {head.attendance_date}
                          </td>
                          <td className="text-center">{head.in_time}</td>
                          <td className="text-center">{head.out_time}</td>
                          <td className="text-center">{head.working_hours}</td>
                          <td className="text-center">
                            <i
                              class="fa fa-edit mx-1"
                              onClick={() =>
                                handleEditClick(
                                  head.id,
                                  `${head.fk_staff__first_name} ${head.fk_staff__last_name}`,
                                  head.fk_staff__emp_no,
                                  head.attendance_date,
                                  head.in_time,
                                  head.out_time
                                )
                              }
                            ></i>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          )}
        </div>
      </form>

      <Modal isOpen={open}>
        <ModalHeader>Edit Details</ModalHeader>

        <ModalBody>
          {/* <form onSubmit={formik.handleSubmit}> */}
          <div className="container-fluid">
            <div className="card">
              <div className="card-body">
                <div className="col-lg-12 row form-group ">
                  <div className="col-lg-4 ">
                    <label className="col-form-label ">Employee No</label>
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      name="empNo"
                      className="form-control"
                      value={empNo}
                      disabled={true}
                      // onChange={(e) => setEmpNo(e.target.value)}
                    />
                  </div>
                </div>
                <div className="col-lg-12 row form-group ">
                  <div className="col-lg-4 ">
                    <label className="col-form-label ">Name</label>
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="text"
                      name="empName"
                      className="form-control"
                      value={empName}
                      disabled={true}
                      // onChange={(e) => setEmpName(e.target.value)}
                    />
                  </div>
                </div>
                <hr></hr>
                <div className="col-lg-12 row form-group ">
                  <div className="col-lg-4 ">
                    <label className="col-form-label ">Date</label>
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="date"
                      name="attendenceDate"
                      className="form-control"
                      value={AttendenceDate}
                      disabled={true}
                      // onChange={(e) => setAttendenceDate(e.target.value)}
                    />
                  </div>
                </div>

                <div className="col-lg-12  row form-group ">
                  <div className=" col-lg-4 ">
                    <label className="col-form-label ">In Time</label>
                    <span className="text-danger">*</span>
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="time"
                      name="in_time"
                      className="form-control"
                      value={formik.values.in_time}
                      onChange={(e) => {
                        setIntime(e.target.value);
                        formik.setFieldValue("in_time", e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />
                    {formik.touched.in_time && formik.errors.in_time && (
                      <div className="text-danger">{formik.errors.in_time}</div>
                    )}
                  </div>
                </div>
                <div className="col-lg-12  row form-group ">
                  <div className=" col-lg-4 ">
                    <label className="col-form-label ">Out Time</label>
                    {/* <span className="text-danger">*</span> */}
                  </div>
                  <div className="col-lg-8">
                    <input
                      type="time"
                      name="out_time"
                      className="form-control"
                      value={formik.values.out_time}
                      onChange={(e) => {
                        setOuttime(e.target.value);
                        formik.setFieldValue("out_time", e.target.value);
                      }}
                      onBlur={formik.handleBlur}
                    />

                    {formik.touched.out_time && formik.errors.out_time && (
                      <div className="text-danger">
                        {formik.errors.out_time}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            {/* <button className="btn btn-primary"  type="submit">Submit</button> */}
          </div>
          {/* </form> */}
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={formik.handleSubmit}>
           
            Update
          </Button>
          <Button color="secondary" onClick={toggle}>
            Close
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default UploadAttendance;
