

export const SCHOOLOREGISTRATION_REQUEST = 'SCHOOLOREGISTRATION_REQUEST';
export const SCHOOLOREGISTRATION_SUCCESS = 'SCHOOLOREGISTRATION_SUCCESS';
export const SCHOOLOREGISTRATION_FAILURE = 'SCHOOLOREGISTRATION_FAILURE';

export const STATE_LIST_REQUEST="STATE_LIST_REQUEST"
export const STATE_LIST_SUCCESS="STATE_LIST_SUCCESS"
export const STATE_LIST_FAILURE="STATE_LIST_FAILURE"

export const CITY_LIST_REQUEST="CITY_LIST_REQUEST"
export const CITY_LIST_SUCCESS="CITY_LIST_SUCCESS"
export const CITY_LIST_FAILURE="CITY_LIST_FAILURE"

export const ADD_DISTRICT_REQUEST = 'ADD_DISTRICT_REQUEST';
export const ADD_DISTRICT_SUCCESS = 'ADD_DISTRICT_SUCCESS';
export const ADD_DISTRICT_FAILURE = 'ADD_DISTRICT_FAILURE';

// actionTypes.js
export const CREATE_SCHOOLREG_REQUEST = 'CREATE_SCHOOLREG_REQUEST';
export const CREATE_SCHOOLREG_SUCCESS = 'CREATE_SCHOOLREG_SUCCESS';
export const CREATE_SCHOOLREG_FAILURE = 'CREATE_SCHOOLREG_FAILURE';

export const UPDATE_SCHOOLREG_REQUEST = 'UPDATE_SCHOOLREG_REQUEST';
export const UPDATE_SCHOOLREG_SUCCESS = 'UPDATE_SCHOOLREG_SUCCESS';
export const UPDATE_SCHOOLREG_FAILURE = 'UPDATE_SCHOOLREG_FAILURE';


export const DELETE_SCHOOLREG_REQUEST = 'DELETE_SCHOOLREG_REQUEST';
export const DELETE_SCHOOLREG_SUCCESS = 'DELETE_SCHOOLREG_SUCCESS';
export const DELETE_SCHOOLREG_FAILURE = 'DELETE_SCHOOLREG_FAILURE';

export const UPDATE_FEES_REQUIST='UPDATE_FEES_REQUIST'
export const UPDATE_FEES_SUCCESS="UPDATE_FEES_SUCCESS"
export const UPDATE_FEES_FAILD="UPDATE_FEES_FAILD"

export const FEES_REQUIST='FEES_REQUIST'
export const FEES_SUCCESS="FEES_SUCCESS"
export const FEES_FAILD="FEES_FAILD"


export const DELETE_FEES_REQUIST='DELETE_FEES_REQUIST'
export const DELETE_FEES_SUCCESS="DELETE_FEES_SUCCESS"
export const DELETE_FEES_FAILD="DELETE_FEES_FAILD"

export const FEES_LIST_REQUIST='FEES_LIST_REQUIST'
export const FEES_LIST_SUCCESS="FEES_LIST_SUCCESS"
export const FEES_LIST_FAILD="FEES_LIST_FAILD"


export const CREATE_CLASS_REQUEST = 'CREATE_CLASS_REQUEST';
export const CREATE_CLASS_SUCCESS = 'CREATE_CLASS_SUCCESS';
export const CREATE_CLASS_FAILURE = 'CREATE_CLASS_FAILURE';

export const CLASS_LIST_REQUEST="CLASS_LIST_REQUEST"
export const CLASS_LIST_SUCCESS="CLASS_LIST_SUCCESS"
export const CLASS_LIST_FAILURE="CLASS_LIST_FAILURE"

export const DELETE_CLASS_REQUEST = 'DELETE_CLASS_REQUEST';
export const DELETE_CLASS_SUCCESS = 'DELETE_CLASS_SUCCESS';
export const DELETE_CLASS_FAILURE = 'DELETE_CLASS_FAILURE';


export const GET_FEES_STRUCTURE_LIST_REQUIST='GET_FEES_STRUCTURE_LIST_REQUIST'
export const GET_FEES_STRUCTURE_LIST_SUCCESS="GET_FEES_STRUCTURE_LIST_SUCCESS"
export const GET_FEES_STRUCTURE_LIST_FAILD="GET_FEES_STRUCTURE_LIST_FAILD"


export const CREATE_FEES_STRUCTURE_REQUIST='CREATE_FEES_STRUCTURE_REQUIST'
export const CREATE_FEES_STRUCTURE_SUCCESS="CREATE_FEES_STRUCTURE_SUCCESS"
export const CREATE_FEES_STRUCTURE_FAILD="CREATE_FEES_STRUCTURE_FAILD"


export const DELETE_FEES_STRUCTURE_REQUIST='DELETE_FEES_STRUCTURE_REQUIST'
export const DELETE_FEES_STRUCTURE_SUCCESS="DELETE_FEES_STRUCTURE_SUCCESS"
export const DELETE_FEES_STRUCTURE_FAILD="DELETE_FEES_STRUCTURE_FAILD"