import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

const deleteStudentApi = {

  deleteStudent: async (data) => {
    const axiosConfig = createAxiosConfig("delete_student_api/", "POST", data);
    return await axios(axiosConfig);
  },
};

export default deleteStudentApi;
