import React, { useEffect, useState, useRef } from "react";
import Select from "react-select";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Heading } from "../../CommonComponent/heading";
import InputMask from "react-input-mask";
import Swal from "sweetalert2";
import { PayrollSettings } from "../../../api/Payroll/PayrollSettings";
import useLoading from "../../CommonComponent/useLoading";
import Loader from "../../Loader/Loader";

const Payroll = ({ schoolId }) => {
  const [temp, setTemp] = useState(false);
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [ip, setIp] = useState("");
  const [port, setPort] = useState("");
  const [minHalfHr, setMinHalfHr] = useState("");
  const [minHalfMinuts, setMinHalfMinuts] = useState("");
  const [minFullHr, setMinFullfHr] = useState("");
  const [minFullMinuts, setMinFullMinuts] = useState("");
  const [selectedWeekdays, setSelectedWeekdays] = useState([]);
  const [edit, setEdit] = useState(false);
  // const [ipAddress, setIpAddress] = useState(["", "", "", ""]);
  // const [ipSegments, setIpSegments] = useState(ipAddress);

  const inputRef = useRef(null);
  // const [formattedIP, setFormattedIP] = useState("");

  const tempChange = () => {
    setTemp(!temp);
  };

  const Weekday = [
    { value: "Monday", label: "Monday" },
    { value: "Tuesday", label: "Tuesday" },
    { value: "Wednesday", label: "Wednesday" },
    { value: "Thursday", label: "Thursday" },
    { value: "Friday", label: "Friday" },
    { value: "Saturday", label: "Saturday" },
    { value: "Sunday", label: "Sunday" },
  ];

  const getpayrollSettings = async () => {
    try {
      let data = {
        school_id: schoolId,
      };
      startLoading();
      const response = await PayrollSettings.getpayrollSetting(data);
      let responseData = response.data;
      setEdit(responseData?.payroll_setting);
      setSelectedWeekdays(responseData?.payroll_setting.weekly_off || []);
      setTemp(responseData?.payroll_setting.biometric ? true : false);

      const initialIp = responseData?.payroll_setting.ip;
      console.log(initialIp, " : : : : : : :: : ;");
      const initialIpArray = initialIp.split(".");
      console.log(initialIpArray, "initialIpArray");

      // setIpAddress(initialIpArray || ["", "", "", ""]);
      // setFormattedIP(initialIpArray || ["", "", "", ""]);
      // Parsing min_fullday_hr
      const minFullDayTime =
        responseData?.payroll_setting.min_fullday_hr.split(":");
      setMinFullfHr(minFullDayTime[0]); // Set hours
      setMinFullMinuts(minFullDayTime[1]); // Set minutes

      // Parsing min_halfday_hr
      const minHalfDayTime =
        responseData?.payroll_setting.min_halfday_hr.split(":");
      setMinHalfHr(minHalfDayTime[0]); // Set hours
      setMinHalfMinuts(minHalfDayTime[1]); // Set minutes
    } catch (err) {
      console.log(err);
    } finally {
      stopLoading();
    }
  };

  useEffect(() => {
    getpayrollSettings();
  }, []);

  // const formatIP = () => {
  //   // Join the list elements into a single IP address string
  //   const ipAddress1 = ipAddress.join('.');
  //   console.log(ipAddress1 ,"ipAddress")

  //   // Regular expression to match a valid IP address format
  //   const ipRegex = /^(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/;

  //   // Check if the ipAddress matches the IP address regex
  //   if (!ipRegex.test(ipAddress1)) {
  //     Swal.fire({
  //       icon: 'error',
  //       title: 'Invalid IP Address',
  //       text: `Please enter a valid IP ${ipAddress1} address (xxx.xxx.xxx.xxx format)`,
  //       allowOutsideClick: false,
  //     });
  //     return false;
  //   }else{
  //     setFormattedIP(ipAddress1);
  //   }

  //   // If ipAddress is valid, set the formatted IP

  //   console.log(formattedIP, "formattedIP  : : : : : :: : : : : : : : : : : : : : : : : : : : : : : : ");
  //   return true;
  // };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: schoolId,
      pf_formala: edit?.pf_formala || "",
      pt_formula: edit?.pt_formula || "",
      weekly_off: edit.weekly_off || [],
      biometric: "",
      port: edit?.port,
      ip: edit?.ip || "",
    },
    validationSchema: temp
      ? Yup.object().shape({
          port: Yup.string().required("Port is required."),
          ip: Yup.string()
          .required("IP is required.")
          .matches(
            /^((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)$/,
            "Invalid IP address"
          ),
        })
      : Yup.object().shape({
          // pf_formala: Yup.string().required("PF formula is required"),
          // pt_formula: Yup.string().required("PT formula is required"),
        }),
    onSubmit: async (values) => {
      // if (temp) {
      //   // Check if the IP address is valid
      //   const isValidIP = formatIP();
      //   if (!isValidIP) {
      //     return; // Exit the function if IP address is invalid
      //   }
      // }

      if (!minHalfHr) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please enter minimum half day hours",
          allowOutsideClick: false,
        });
        return;
      }
      if (!minFullHr) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please enter minimum half day hours",
          allowOutsideClick: false,
        });
        return;
      }
      if (!selectedWeekdays || selectedWeekdays.length === 0) {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: "Please Select Week Off",
          allowOutsideClick: false,
        });
        return;
      }
      let data = {
        ...values,
        biometric: temp ? "True" : "False",
        min_halfday_hr: `${minHalfHr}:${minHalfMinuts || "00"}`,
        min_fullday_hr: `${minFullHr}:${minFullMinuts || "00"}`,
        weekly_off: JSON.stringify(selectedWeekdays),
      };
      console.log(data, "data");

      const response = await PayrollSettings.addUpdatePayrollSetting(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        Swal.fire({
          icon: "success",
          title: "Success",
          text: responseData.msg,
          allowOutsideClick: false,
        });
        getpayrollSettings();
      } else {
        Swal.fire({
          icon: "error",
          title: "Error",
          text: responseData.msg,
          allowOutsideClick: false,
        });
      }
    },
  });
  const handleWeekdayChange = (selectedOptions) => {
    const selectedValues = selectedOptions
      ? selectedOptions.map((option) => option.value)
      : [];
    setSelectedWeekdays(selectedValues);
  };

  console.log();

  // const handleInputChange = (event, index) => {
  //   const newIpAddress = [...ipAddress];
  //   const value = event.target.value;

  //   if (/^\d|\b$/.test(value)) {
  //     const newValue = Math.min(Math.max(value, 0), 255).toString();
  //     newIpAddress[index] = newValue.slice(0, 3);
  //   } else if (value === "") {
  //     if (index > 0 && newIpAddress[index - 1] === "") {
  //       newIpAddress[index - 1] = "";
  //     }
  //     newIpAddress[index] = "";
  //   }

  //   setIpAddress(newIpAddress);
  // };

  // const renderInputFields = () => {
  //   // Function to handle key press event to prevent dots and non-numeric input
  //   const handleKeyPress = (event) => {
  //     const charCode = event.charCode;
  //     if (charCode < 48 || charCode > 57) {
  //       event.preventDefault();
  //     }
  //   };

  //   return (
  //     // Render 4 input fields regardless of IP address presence
  //     Array(4).fill('').map((_, index) => (
  //       <div key={index} className="form-group row">
  //         <input
  //           type="number"
  //           className="form-control form-control-sm sizeforInput mx-2"
  //           maxLength={3} // Limit input to 3 characters
  //           value={ipAddress[index] || ''} // Use empty string if IP not present
  //           placeholder="xxx" // Always show placeholder
  //           onChange={(event) => handleInputChange(event, index)}
  //           onKeyPress={handleKeyPress} // Restrict input to numbers only
  //         />
  //       </div>
  //     ))
  //   );
  // };

  if (isLoading) return <Loader />;

  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <Heading pageTitle={"Payroll Settings"}>
          <div className=" mt-2">
            <button className="btn btn-primary btn-sm mx-1" type="submit">
              <i className="fa fa-plus px-1"></i>
              {edit.pt_formula ? "Update" : "Save"}
            </button>
          </div>
        </Heading>
        <div className="row row-deck mt-2">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <div className="card-body">
                {/* <div className="form-group row">
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Add PF formula <span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <textarea
                      placeholder="Add PF formula"
                        className="form-control"
                        value={formik.values.pf_formala}
                        onChange={formik.handleChange}
                        name="pf_formala"
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.pf_formala &&
                        formik.errors.pf_formala && (
                          <div className="text-danger">
                            {formik.errors.pf_formala}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Add PT formula <span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <textarea
                      placeholder="Add PT formula"
                        className="form-control"
                        value={formik.values.pt_formula}
                        onChange={formik.handleChange}
                        name="pt_formula"
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.pt_formula &&
                        formik.errors.pt_formula && (
                          <div className="text-danger">
                            {formik.errors.pt_formula}
                          </div>
                        )}
                    </div>
                  </div>
                </div> */}
                <div className="form-group row">
                  <div className="col-lg-6">
                    <label className="col-form-label">
                      Min Half Day Hours <span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-12 row">
                      <input
                        type="number"
                        className="form-control col-lg-5 mx-2"
                        value={minFullHr}
                        placeholder="HH"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (
                            newValue === "" ||
                            (newValue.length <= 2 &&
                              newValue >= 0 &&
                              newValue <= 12)
                          ) {
                            setMinFullfHr(newValue);
                          }
                        }}
                      />
                      <span className="text-center">:</span>
                      <input
                        type="number"
                        className="form-control col-lg-5 mx-2"
                        value={minFullMinuts}
                        placeholder="MM"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (
                            newValue === "" ||
                            (newValue.length <= 2 &&
                              newValue >= 0 &&
                              newValue <= 60)
                          ) {
                            setMinFullMinuts(newValue);
                          }
                        }}
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label className="col-form-label">
                      Min Full Day Hours <span className="text-danger">*</span>
                    </label>

                    <div className="col-12 row">
                      <input
                        type="number"
                        className="form-control col-lg-5 mr-2"
                        value={minHalfHr}
                        placeholder="HH"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (
                            newValue === "" ||
                            (newValue.length <= 2 &&
                              newValue >= 0 &&
                              newValue <= 12)
                          ) {
                            setMinHalfHr(newValue);
                          }
                        }}
                      />

                      <span className="text-center">:</span>
                      <input
                        type="number"
                        className="form-control col-lg-5 mx-2"
                        value={minHalfMinuts}
                        placeholder="MM"
                        onChange={(e) => {
                          const newValue = e.target.value;
                          if (
                            newValue === "" ||
                            (newValue.length <= 2 &&
                              newValue >= 0 &&
                              newValue <= 60)
                          ) {
                            setMinHalfMinuts(newValue);
                          }
                        }}
                      />
                    </div>
                    {formik.touched.min_fullday_hr &&
                      formik.errors.min_fullday_hr && (
                        <div className="text-danger">
                          {formik.errors.min_fullday_hr}
                        </div>
                      )}
                  </div>
                </div>
                <div className="form-group d-flex">
                  <div className="col-md-6">
                    <label className="col-form-label">
                      Weekly Offs <span className="text-danger">*</span>
                    </label>
                    <div className="">
                      <Select
                        options={Weekday}
                        isMulti
                        value={Weekday.filter((option) =>
                          selectedWeekdays.includes(option.value)
                        )}
                        onChange={handleWeekdayChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group row">
                  <label className="col-lg-3 col-form-label">
                    BioMatric
                    <span className="text-danger"></span>
                  </label>

                  <div className="col-lg-9 d-flex  align-items-center">
                    <label className="custom-switch pr-2">
                      <input
                        type="checkbox"
                        className="custom-switch-input"
                        name="staff_status"
                        checked={temp}
                        onChange={tempChange}
                      />

                      <span className="custom-switch-indicator"></span>
                    </label>
                  </div>
                </div>
                {temp && (
                  <>
                    <div className="form-group row">
                      <label className="col-lg-2 col-form-label">
                        IP<span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-10">
                      <input
                        type="text"
                        className={"form-control input-height"}
                        name="ip"
                        placeholder="Enter IP"
                        value={formik.values.ip}
                        onChange={(e) => {
                          let newValue = e.target.value;
                          formik.setFieldValue("ip", newValue);
                        }}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.ip && formik.errors.ip && (
                          <div className="text-danger">
                            {formik.errors.ip}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="form-group row">
                      <label className="col-lg-2 col-form-label">
                        Port<span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-10">
                        <input
                          type="number"
                          className={"form-control input-height"}
                          name="port"
                          placeholder="Enter port"
                          value={formik.values.port}
                          onChange={(e) => {
                            let newValue = e.target.value;
                            if (newValue <= 65535) {
                              formik.setFieldValue("port", newValue);
                            }
                          }}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.port && formik.errors.port && (
                          <div className="text-danger">
                            {formik.errors.port}
                          </div>
                        )}
                      </div>
                    </div>
                    <div className="form-group row">
                      <label className="col-lg-2 col-form-label">
                        Machine Code
                      </label>
                      <div className="col-lg-10">
                        <input
                          type="text"
                          className={"form-control input-height"}
                          name="machineCode"
                          placeholder="Machine Code"
                          value={edit?.machine_code}
                          disabled={true}
                        />
                      </div>
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
};

export default Payroll;
