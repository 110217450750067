export const CREATE_WORKSHEET_REQUIST = "CREATE_WORKSHEET_REQUIST";
export const CREATE_WORKSHEET_SUCCESS = "CREATE_WORKSHEET_SUCCESS";
export const CREATE_WORKSHEET_FAIELD = "CREATE_WORKSHEET_FAIELD";

export const SHOW_WORKSHEET_REQUIST = "SHOW_WORKSHEET_REQUIST";
export const SHOW_WORKSHEET_SUCCESS = "SHOW_WORKSHEET_SUCCESS";
export const SHOW_WORKSHEET_FAIELD = "SHOW_WORKSHEET_FAIELD";


export const DELETE_WORKSHEET_REQUIST= "DELETE_WORKSHEET_REQUIST";
export const DELETE_WORKSHEET_SUCCESS= "DELETE_WORKSHEET_SUCCESS";
export const DELETE_WORKSHEET_FAIELD = "DELETE_WORKSHEET_FAIELD";