import {
  CLASS_LIST_FAILURE,
  CLASS_LIST_REQUEST,
  CLASS_LIST_SUCCESS,
  CREATE_CLASS_FAILURE,
  CREATE_CLASS_REQUEST,
  CREATE_CLASS_SUCCESS,
  DELETE_CLASS_FAILURE,
  DELETE_CLASS_REQUEST,
  DELETE_CLASS_SUCCESS,
} from "../../Constants/SchoolRegConstant";

const initialState = {
  classsection: [],
  classlist: null,
  deleteclasslist: null,
  loading: false,
  deleteclass: null,
};

export const classSectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case CLASS_LIST_REQUEST:
    case CREATE_CLASS_REQUEST:
    case DELETE_CLASS_REQUEST:
      return {
        loading: true,
      };
    case CLASS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        classlist: action.payload,
      };
    case CREATE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        classsection: action.payload,
      };
    case DELETE_CLASS_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteclass: action.payload,
      };

    case CLASS_LIST_FAILURE:
    case CREATE_CLASS_FAILURE:
    case DELETE_CLASS_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };
    default:
      return state;
  }
};
