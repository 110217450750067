import React from "react";
import { useEffect, useRef, useState } from "react";
import Select from "react-select";
import { useParams, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  getFeeList,
  getClassSectionList,
} from "../../../actions/super-user/SchoolRegistration";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
import { getMasterData } from "../../../actions/super-user/MasterData.js";
import {
  cancelAdmission,
  getStudentName,
  studentDataByCode,
} from "../../../actions/Admission/AdmissionAction";
import moment from "moment";
import useSessionRedirect from "../Comman/SessionChange";
import StudentSessionWiseList from "../Comman/StudentListSessionWise.js";
import ValidationComponent from "../Comman/Validation.js";
import ValidationMessage from "../../CommonComponent/ValidationMessage.js";

const CancelAdmission = () => {
  const token = localStorage.getItem("token");
  const { uid } = useParams();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  const { school_emp_id, fk_school_id } = user_detail;

  const session_id = useSessionRedirect();

  const { studentName, errorCode, message } = useSelector(
    (state) => state.studentName
  );
  const { studentData } = useSelector((state) => state.studentDataByCode);
  const { admissionCancel } = useSelector((state) => state.cancelAdmission);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [remark, setRemark] = useState("");
  const [student_uid, setstudent_uid] = useState(uid);
  const [classdata, setClassData] = useState([]);

  const { classlist, classsection } = useSelector(
    (state) => state.classSection
  );
  const { masterData } = useSelector((state) => state.masterData);

  useEffect(() => {
    if (token) {
      dispatch(getMasterData(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    setClassData(
      masterData?.payload?.filter((obj) => obj.name === "cancelreason")
    );
  }, [masterData]);

  const cancelReasonList = classdata?.map((cl) => {
    return {
      label: cl.value,
      value: cl.id,
    };
  });

  function handleCancelResion(data) {
    setSelectResion(data);
  }

  if (
    classsection?.status === 403 ||
    classsection?.status === 503 ||
    classsection?.status === 200
  ) {
    if (classsection?.status === 200) {
      alert.success(classsection.msg);
    } else {
      alert.error(classsection.msg);
    }
  }

  let feeId = {
    school_id: Number(user_detail.fk_school_id),
  };

  useEffect(() => {
    if (token) {
      dispatch(getFeeList(token, feeId));
    }
  }, [dispatch, token]);
  useEffect(() => {
    if (token) {
      dispatch(getClassSectionList(token, feeId));
    }
  }, [dispatch, token]);

  const [selectResion, setSelectResion] = useState();

  let reason_id = selectResion?.value;

  let nameS = studentData?.payload?.name;
  let statusS = studentData?.payload?.student_status;
  if (student_uid === "") {
    nameS = "";
    statusS = "";
    reason_id = null;
  } else if (student_uid !== studentData?.payload?.student_code) {
    nameS = "";
    statusS = "";
    reason_id = null;
  }

  function getName() {
    const body = {
      fk_academic: session_id,
      student_code: student_uid,
      school_id: Number(user_detail.fk_school_id),
      for_type: "Admission",
    };
    dispatch(getStudentName(body, handleClear));
    dispatch(studentDataByCode(body));
  }

  useEffect(() => {
    getName();
  }, [uid, student_uid]);

  useEffect(() => {
    if (nameS) {
      setstudent_uid("");
    }
  }, [session_id]);

  const [CancelAdmissionDate, setCancelAdmissionDate] = useState(
    new Date().toISOString().substr(0, 10)
  );

  const thisCancelAdmission = (e) => {
    const body = {
      admission_id: Number(studentData?.payload?.admission_id),
      academic_id: Number(session_id),
      school_id: Number(user_detail.fk_school_id),
      student_id: Number(studentData?.payload?.id),
      section_id: Number(studentData?.payload?.section_id),
      cancel_date: CancelAdmissionDate,
      status: statusS === "Admitted" ? "Cancelled" : "Admitted",
      reason: Number(reason_id),
      remark: remark,
      insert_by: school_emp_id,
    };
    try {
      dispatch(cancelAdmission(body));
      setstudent_uid("");
      setRemark("");
      setSelectResion("");
      // setSearchResults([]);
    } catch (error) {
      console.error(error);
    }
  };
  const {
    validationError: studentValidationError,
    validateControl: studentValidateControl,
  } = ValidationComponent({
    data:
      statusS === "Admitted"
        ? { student_uid, nameS, reason_id }
        : { student_uid, nameS },
    requiredFields:
      statusS === "Admitted"
        ? ["student_uid", "nameS", "reason_id"]
        : ["student_uid", "nameS"],
  });


  const CheckUpdateStatus = async (e) => {
    e.preventDefault();
    const isValid = await studentValidateControl();

    if (!isValid) return;
    if (statusS) {
      if (statusS === "Cancelled") {
        Swal.fire({
          title: "CONFIRMATION",
          text: "Do you want to revert this Admission?",
          icon: "question",
          showDenyButton: true,
          confirmButtonColor: "#3085d6",
          cancelButtonColor: "#f46a6a",
          confirmButtonText: "Yes",
          denyButtonText: `No`,
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            thisCancelAdmission(e);
          } else if (result.isDenied) {
            console.log(result.isDenied);
          }
        });
      } else {
        if (!reason_id) {
          alert.error("Cancel Reason Required");
        } else {
          Swal.fire({
            title: "CONFIRMATION",
            text: "Do you want to cancel this Admission?",
            icon: "question",
            showDenyButton: true,
            confirmButtonColor: "#3085d6",
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes",
            denyButtonText: `No`,
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              thisCancelAdmission(e);
            } else if (result.isDenied) {
              console.log(result.isDenied);
            }
          });
        }
      }
    } else {
      if (!student_uid) {
        alert.error("Student Code Required");
      } else if (!nameS) {
        alert.error("Student Name Required");
      } else {
        Swal.fire({
          title: "Please Paid Balance Fee",
          text: `Student have balance fee amount of ${studentData?.payload?.balance_amount}`,
        });
      }
    }
  };

  function handleClear() {
    setstudent_uid("");
  }

  useEffect(() => {
    if (errorCode === 500 && student_uid) {
      alert.error(message);
    }
    // else if (errorCode === 403 && student_uid) {
    //   alert.error(message);
    //   console.log(errorCode);
    // }
  }, [nameS, studentName, studentData, errorCode, message]);

  useEffect(() => {
    if (student_uid) {
      handleClear();
    }
  }, [session_id]);

  function convertDate(data) {
    const parsedDate = moment(data, "YYYY-MM-DD");
    const formattedDate = parsedDate.format("DD-MM-YYYY");
    return formattedDate;
  }

  return (
    <>
      <div className="container-fluid">
        <div className="section-body">
          <div className="container-fluid">
            <div className="header-action">
              <h1 className="page-title">Cancel Admission</h1>
            </div> 
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="card">
              <form className="card-body">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group">
                      <div className="row">
                        <div className="col-lg-5">
                          <label className="form-label">
                            Student Code <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <div className="input-group">
                              <input
                                type="text"
                                className="form-control"
                                placeholder="Student Code"
                                value={student_uid}
                                onChange={(e) => setstudent_uid(e.target.value)}
                              />
                              <StudentSessionWiseList
                                setstudent_uid={setstudent_uid}
                              />
                              <ValidationMessage
                                condition={
                                  studentValidationError && !student_uid
                                }
                                errorMsg={"Please Select Student code."}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-7">
                          <label className="form-label">
                            Student Name <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              value={nameS}
                              // onChange={(e) => setstdname(e.target.value)}
                              placeholder="Student Name"
                              disabled
                            />
                            <ValidationMessage
                              condition={studentValidationError && !nameS}
                              errorMsg={"Student Name Required."}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-lg-3 mt-3">
                          <label className="form-label">
                            Date <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="date"
                              className="form-control"
                              value={CancelAdmissionDate}
                              onChange={(e) =>
                                setCancelAdmissionDate(e.target.value)
                              }
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 mt-3">
                          <label className="form-label">
                            Status <span className="text-danger">*</span>
                          </label>
                          <div className="">
                            <input
                              type="text"
                              className="form-control"
                              value={statusS}
                              // onChange={(e) => setStatus(e.target.value)}
                              placeholder="Status"
                              disabled
                            />
                          </div>
                        </div>
                        {statusS === "Admitted" && (
                          <div className="col-lg-5 mt-3">
                            <label className="form-label">
                              Cancel Reason{" "}
                              <span className="text-danger">*</span>
                            </label>
                            <div className="">
                              <Select
                                options={cancelReasonList}
                                placeholder="Select Reason"
                                value={selectResion}
                                onChange={handleCancelResion}
                                isSearchable={true}
                                required
                              />
                              <ValidationMessage
                                condition={studentValidationError && !reason_id}
                                errorMsg={"Cancel Reason Required."}
                              />
                            </div>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-lg-10 mt-3">
                          <label className="form-label">Remark</label>
                          <div className="">
                            <textarea
                              type="text"
                              className="form-control"
                              value={remark}
                              onChange={(e) => setRemark(e.target.value)}
                              placeholder="Remark "
                            ></textarea>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <h5 className="pb-2">Regular Fee Summary</h5>
                    <div className="d-flex">
                      <p className="w-50">Total Fees :</p>
                      <strong>{studentData?.payload?.total_amount}</strong>
                    </div>
                    <div className="d-flex">
                      <p className="w-50">Fee Paid :</p>
                      <strong>{studentData?.payload?.paid_amount}</strong>
                    </div>
                    <div className="d-flex">
                      <p className="w-50">Fee Balance :</p>
                      <strong>{studentData?.payload?.balance_amount}</strong>
                    </div>

                    <button
                      // disabled={
                      //   studentData?.payload?.total_amount ===
                      //     studentData?.payload?.paid_amount && statusS
                      //     ? false
                      //     : true
                      // }
                      type="button "
                      className={
                        statusS === "Cancelled"
                          ? `btn btn-primary px-5 mt-3`
                          : `btn btn-danger px-5 mt-3`
                      }
                      onClick={(e) => {
                        CheckUpdateStatus(e);
                      }}
                    >
                      {statusS === "Cancelled"
                        ? "Admission Revert"
                        : "Admission Cancel"}
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CancelAdmission;
