import React, { useEffect, useState } from "react";
import { Heading } from "../../../CommonComponent/heading";
import { useFormik } from "formik";
import * as Yup from "yup";
import Swal from "sweetalert2";
import { CiSearch } from "react-icons/ci";
import Select from "react-select";
import {
  ClearButton,
  SaveButton,
  ViewButton,
} from "../../../CommonComponent/ReusableButton";

import { Link } from "react-router-dom";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";
import { useSubmit } from "react-router-dom";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { RouteconfigurationAPI } from "../../../../api/Transport/RouteConfiguration";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import MapHelper from "../Map/MapHelper";
// import { Navigate ,useNavigate } from 'react-router-dom';

const EditPickUpPoint = () => {
  const location = useLocation();
  const { pickup, id } = location.state;
  const navigate = useNavigate();


  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [open, setOpen] = useState(false);
  const [routeData, setRouteData] = useState([]);
  const [focusAfterClose, setFocusAfterClose] = useState(true);
  const [isChecked, setIsChecked] = useState(false);
  const [pickupPoint, setPickupPoint] = useState([]);
  const [stateVariable, setNewStateVariable] = useState([]);
  const toggle = () => setOpen(!open);
  const [mapAddress, setMapAddress] = useState("");
  const [latLng,setlatlng] = useState("")

  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };

  useEffect(() => {
    getRouteConfiguration(id);
  }, []);

  const getRouteConfiguration = async (id) => {
    try {
      let data = {
        school_id: school_id,
      };
      let response = await RouteconfigurationAPI.getRouteconfigurations(data);
      let responseData = response.data;
      const filteredRoute = responseData.route_configuration.filter(
        (route) => route.id === id
      );
      setRouteData(filteredRoute);
      const findObject = filteredRoute[0]?.pickup_point;
      const pickupPoint = findObject.find((real) => real.id === pickup);
      setIsChecked(pickupPoint.is_active);
      setPickupPoint(pickupPoint);
    } catch (err) {
      console.error(err);
    }
  };
  console.log("pickupPoint", pickupPoint);
 

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: school_id,
      route_id: id,
      pickup_id: pickup,
      pickup_point: pickupPoint?.pickup_point || "",
      distance: pickupPoint.distance || "",
      fee_amount: pickupPoint.fee_amount || "",
      // from_previous_stop: pickupPoint.from_previous_stop || "",
      pickup_time: pickupPoint.pickup_time || "",
      drop_time: pickupPoint.drop_time || "",
      is_active: pickupPoint.is_active ? "True" : "False",
      insert_by: school_emp_id,
      address: mapAddress? mapAddress :pickupPoint.address,
      lat: latLng?.lat ? latLng?.lat : pickupPoint.lat,
      lng: latLng?.lng ? latLng?.lng : pickupPoint.lng,
    },
    validationSchema: Yup.object().shape({
      pickup_point: Yup.string().required(" Pickup Point is required"),
      distance: Yup.string().required(" Distance is required"),
      fee_amount: Yup.string().required("Fee Amount is required"),
    }),
    onSubmit: async (values) => {
      let data = {
        ...values,
        is_active: isChecked ? "True" : "False",

      };
      const response = await RouteconfigurationAPI.addUpdatePickupdropupApi(
        data
      );
      const responseData = response.data;
      if (responseData.status === 200) {
        navigate("/school/Pickup-Point", { state: { id } });
        Swal.fire({
          text: responseData.msg,
          icon: "success",
        });
        handleClear();
      } else {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
        });
      }
    },
  });
  console.log(id);
  const handelBack = () => {
    navigate("/school/Pickup-Point", { state: { id } });
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <Heading pageTitle={"Edit Pickup Point "}>
            <div className="row m-3">
              <button
                className="btn btn-outline-info"
                type="button"
                onClick={handelBack}
              >
                Go Back
              </button>
            </div>
          </Heading>
          <div className="col-lg-12 mt-4">
            <div className="card">
              <div className="card-body">
                <form
                  className="card-body form-horizontal"
                  onSubmit={formik.handleSubmit}
                >
                  <div className="col-lg-12 ">
                    <div className=" form-group row">
                      <label className="col-3 col-form-label text-left ">
                        Pickup Point <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          placeholder="Pickup point"
                          className="form-control custom-input"
                          name="pickup_point"
                          value={formik.values.pickup_point}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.pickup_point &&
                          formik.errors.pickup_point && (
                            <div className="text-danger">
                              {formik.errors.pickup_point}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="form-group  row">
                      <label className="col-3  col-form-label text-left">
                        Distance(Kms) <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          placeholder="Distance"
                          className="form-control custom-input"
                          name="distance"
                          value={formik.values.distance}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.distance && formik.errors.distance && (
                          <div className="text-danger">
                            {formik.errors.distance}
                          </div>
                        )}
                      </div>

                     
                      <label className="col-lg-3 col-form-label text-left">
                        Fee Amount <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          placeholder="Fee amount"
                          className="form-control custom-input"
                          name="fee_amount"
                          value={formik.values.fee_amount}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                        {formik.touched.fee_amount &&
                          formik.errors.fee_amount && (
                            <div className="text-danger">
                              {formik.errors.fee_amount}
                            </div>
                          )}
                      </div>
                    </div>
                    <div className="form-group  row">
                        <label className="col-lg-3 col-form-label text-left">
                          Address
                        </label>
                        <div className="col-lg-3">
                          <textarea
                            type="text"
                            placeholder="Address"
                            className="form-control custom-input"
                            name="address"
                            value={formik.values.address}
                            disabled={true}
                          />
                        </div>
                        <MapHelper setMapAddress={setMapAddress} setlatlng={setlatlng} lato={pickupPoint.lat} lngo={pickupPoint.lng} />
                      </div>
                    

                    <div className="form-group  row">
                    </div>
                    <div className="form-group row">
                      <label className="  col-lg-3 col-form-label text-left">
                        Pickup Time
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          placeholder="HH:MM:SS"
                          className="form-control custom-input"
                          name="pickup_time"
                          value={formik.values.pickup_time}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                      <label className=" col-lg-3 col-form-label text-left">
                        Drop Time
                      </label>
                      <div className="col-lg-3">
                        <input
                          type="text"
                          placeholder="HH:MM:SS"
                          className="form-control custom-input"
                          name="drop_time"
                          value={formik.values.drop_time}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />
                      </div>
                    </div>
                    <div class="form-check col-lg-4 text-lg-right  ">
                      <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="flexCheckDefault"
                        checked={isChecked}
                        onChange={handleCheckboxChange}
                      />
                      <label class="form-check-label" for="flexCheckDefault">
                        Active
                      </label>
                    </div>
                  </div>
                  <div className="justify-content-center align-items-center">
                    <button
                      className="btn btn-primary btn-sm mx-1 m-1"
                      type="submit"
                    >
                      <i className="fa fa-plus px-1"></i>
                      Save
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditPickUpPoint;
