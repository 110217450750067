import React from "react";

const ShowTestQuestion = ({ ViewQuestion, deleteQuestion }) => {
  return (
   <>

      {
      ViewQuestion?
      ViewQuestion?.map(
        (
          {
            id,
            question,
            option_data,
            answer_list,
            fk_test_id,
            option_media,
            media,
          },
          i
        ) => (
          <div className="container bg-white mt-3 pt-1 pb-1 questionlist" key={id}>
            
            <div className="row">    
                <div className="col-md-12 col-sm-12 col-xs-10" style={{backgroundColor:"#e9ecef"}}> 
                        <div className="row">             
                            <div className="col-md-10 col-sm-8 col-xs-8">                           
                                    <div className="col-form-label">                               
                                        <strong>{i + 1}.  {question}</strong>
                                    </div>
                            </div>
                            <div className="col-md-2 col-sm-2 col-xs-2" style={{textAlign:"right"}}>
                              <a onClick={() => deleteQuestion(id)} className="text-danger" data-toggle="tooltip"
                            data-placement="top"
                            title="Delete">
                                <i className="fa fa-trash" id="deletetooltip"></i>
                              </a>
                            </div>
                          </div>
                </div>
            </div>
            <div className="row mt-3">             
                  <div className="col-md-2 col-sm-12 col-xs-12">
                    <strong className="">Option :</strong>
                  </div>
                  <div className="col-md-10 col-sm-12 col-xs-12">
                    <div className="d-flex m-auto px-4">
                        <div className="d-lg-flex ">
                          {option_data?.map(({ id, option, option_media }, i) => (
                            <label className="radio-inline mx-4 d-flex" key={id}>
                              <h6 className="mx-1">
                                {i + 1 === 1
                                  ? "a) "
                                  : i + 1 === 2
                                  ? "b) "
                                  : i + 1 === 3
                                  ? "c) "
                                  : i + 1 === 4
                                  ? "d) "
                                  : i + 1 === 5
                                  ? "e) "
                                  : ""}
                              </h6>

                              {option_media ?                               
                                <img src={option_media}
                                  style={{
                                    width: "100px",
                                    height: "100px",
                                    borderRadius: 5                          
                                  }}                         
                                />
                              : (
                                <span className="mx-1">{option}</span>
                              )}
                            </label>
                          ))}
                        </div>
                    </div>
                  </div>
            </div>
            <div className="row mt-3">   
                  <div className="col-md-2 col-sm-12 col-xs-12">
                    <strong className="text-success">Correct Answer :</strong>
                  </div>
                  <div className="col-md-10 col-sm-12 col-xs-12">                    
                       <div className="d-flex m-auto px-4">
                            <div className="d-lg-flex ">
                            
                            {answer_list  ? (
                              answer_list.map(({optionIndex,option_media,option})=>(
                                <label className="radio-inline mx-4 d-flex" key={id}>
                                  <h6 className="mx-1">
                                {
                                        optionIndex + 1 === 1
                                        ? "a) "
                                        : optionIndex + 1 === 2
                                        ? "b) "
                                        : optionIndex + 1 === 3
                                        ? "c) "
                                        : optionIndex + 1 === 4
                                        ? "d) "
                                        : optionIndex + 1 === 5
                                        ? "e) "
                                        : ""
                                  }
                                  </h6>
                                          {option_media?
                                          (
                                                  <a                  
                                                  target="_blank"
                                                  rel="noreferrer"
                                                  className="mx-3"
                                                >
                                                  <img src={option_media}
                                                    style={{
                                                      width: "100px",
                                                      height: "100px",
                                                      borderRadius: 10,
                                                      borderWidth: 1,
                                                      borderColor: "green",
                                                    }}
                                                    
                                                  />
                                                </a>):
                                                ( <span className="mx-2">                                             
                                                    <strong>{option}</strong>
                                                  </span>
                                                )
                                                  }
                                                  </label>  
                              ) 
                                      
                            )) : null}
                            
                          </div>
                       </div>
                      
                  </div>
            </div>
          </div>
        )
      ):""}
    </>
  );
};

export default ShowTestQuestion;
