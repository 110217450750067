import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import {
  getRegularFees,
  getStudentName,
  regularfeesReceipt,
} from "../../../actions/Admission/AdmissionAction";
import PrintRegularFeesRecipt from "./PrintRegularFeesRecipt";
import Select from "react-select";
import Swal from "sweetalert2";
import { Modal } from "reactstrap";
import moment from "moment/moment";
import { useAlert } from "react-alert";
import { ReceiptAPI } from "../../../api/Admission/cancelReceiptAPI";
import StudentSessionWiseList from "../Comman/StudentListSessionWise";
import useSessionRedirect from "../Comman/SessionChange";
import { pay_mode } from "../../../data/optionData";
import FeeReceiptList from "./FeeReceiptList";

const RegularFees = () => {
  const token = localStorage.getItem("token");
  const { uid } = useParams();
  const location = useLocation();
  const { state } = location;
  const [student_uid, setstudent_uid] = useState(uid);
  const [FeesTable, setFeesTable] = useState([]);

  const [receiptDate, setreceiptDate] = useState(
    new Date().toISOString().substr(0, 10)
  );
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  let s_id = user_detail?.fk_school_id;
  const { school_emp_id } = user_detail;

  const session_id = useSessionRedirect();

  const { studentName } = useSelector((state) => state.studentName);
  const { regularFees } = useSelector((state) => state.getRegularFees);
  const { feesReceipt } = useSelector((state) => state.regularfeesReceipt);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [receiptType, setReceiptType] = useState("New");
  const [receiptData, setReceiptData] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [noResultsMessage, setNoResultsMessage] = useState("");
  const [modal, setModal] = useState(false);
  const [isEditReceipt, setIsEditReceipt] = useState([]);
  const [isReceiptNo, setIsReceiptNo] = useState(null);
  const [PaymentMode, setPaymentMode] = useState();

  const [paymentInfo, setPaymentInfo] = useState({
    payment_mode: "Cash",
    total_amount: 0,
    late_charge: 0,
    bounce_charge: 0,
    paid_amount: 0,
    balance_amount: 0,
    remark: "",
    bank_name: "",
    pay_no: "",
    pay_date: "",
  });

  const PaymentList = pay_mode?.map((item) => {
    return {
      label: item.label,
      value: item.value,
    };
  });
  function handlePaymentMode(data) {
    setPaymentMode(data);
  }

  let idS = studentName?.payload.id;
  let hostelBalancefee = studentName?.payload.hostel_balancefee;
  let nameS = studentName?.payload.name;
  let receiptS = feesReceipt?.receipt_code;
  let msgS = feesReceipt?.msg;
  let statusS = studentName?.payload.student_status;
  let section_id = studentName?.payload?.admission_data?.fk_section_id;
  // console.log(section_id,"section_id")
  if (student_uid === "") {
    nameS = "";
    receiptS = "";
    idS = "";
    statusS = "";
  } else if (student_uid !== studentName?.payload?.student_code) {
    nameS = "";
    receiptS = "";
    idS = "";
    statusS = "";
  }
  // function getName() {
  //   if (student_uid === undefined || "") {
  //     const body = {
  //       fk_academic: session_id,
  //       student_code: uid,
  //       school_id: Number(user_detail.fk_school_id),
  //     };
  //     dispatch(getStudentName(body, handleClear));
  //   } else {
  //     const body = {
  //       fk_academic: session_id,
  //       student_code: student_uid,
  //       school_id: Number(user_detail.fk_school_id),
  //     };
  //     dispatch(getStudentName(body, handleClear));
  //   }
  // }



  console.log(nameS,"...................................")
  useEffect(() => {
    if (nameS) {
      // setstudent_uid("");
      // alert.info(uid)
    }
  }, [session_id]);

  function getName() {
    const body = {
      fk_academic: session_id,
      student_code: student_uid || uid,
      school_id: Number(user_detail.fk_school_id),
      for_type: "Admission",
    };
    dispatch(getStudentName(body, handleClear));
  }

  useEffect(() => {
    getName();
  }, [uid, student_uid]);

  useEffect(() => {
    const fetchData = async () => {
      const body = {
        fk_academic: session_id,
        receipt_no: "",
        fk_school: Number(user_detail.fk_school_id),
      };
      try {
        const response = await ReceiptAPI.getAllReceipt(body);
        setReceiptData(response.data.payload);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [session_id, studentName, student_uid]);

  const FeesDetails = () => {
    if (student_uid === undefined || "") {
      alert.error("Please enter Student Code");
    } else if (nameS === undefined || "") {
      alert.error("Please enter Student name");
    } else {
      try {
        if (statusS === "Registered") {
          setFeesTable([]);
        } else {
          let body = {
            fk_academic: session_id,
            student_id: idS,
            school_id: Number(user_detail.fk_school_id),
            call_from: "Fees Receipt",
          };
          dispatch(getRegularFees(body));
          // setFeesTable(regularFees?.payload);
        }
      } catch (error) {
        console.error(error);
        setFeesTable([]);
      }
    }
  };

  useEffect(() => {
    if (receiptType === "Edit") {
      setFeesTable(isEditReceipt?.fee_details);
    } else {
      setFeesTable(regularFees?.payload);
    }
  }, [FeesDetails, regularFees, nameS, isEditReceipt?.fee_details]);

  useEffect(() => {
    if (idS) {
      FeesDetails();
    } else {
      setFeesTable([]);
    }
  }, [session_id, studentName, student_uid]);

  const handleInputChange = (index, field, value) => {
    const newData = [...FeesTable];
    newData[index][field] = value;
    setFeesTable(newData);
  };

  const FeesReceipt = async () => {
    if (receiptType === "Edit") {
      let body = {
        receipt_id: isEditReceipt?.id,
        payment_mode: paymentInfo.payment_mode,
        bank_name: paymentInfo.bank_name,
        pay_no: paymentInfo.pay_no,
        pay_date: paymentInfo.pay_date,
        remark: paymentInfo.remark,
        insert_by: school_emp_id,
        total_amount: paymentInfo.paid_amount,
        bounce_charge: paymentInfo.bounce_charge,
        late_charge: paymentInfo.late_charge,
        section_id: section_id,
      };
      const res = await ReceiptAPI.updateregularReceipt(body);
      if (res.data.status === 200) {
        Swal.fire({
          text: res.data.msg,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            handleClear();
            setReceiptType("New");
            setIsEditReceipt([]);
          }
        });
      } else if (res.data.status === 403) {
        Swal.fire({
          text: res.data.msg,
          icon: "error",
          imageAlt: "success image",
          allowOutsideClick: false,
        });
      }
    } else {
      let fees_details = FeesTable?.map(
        ({
          feeshead_id,
          total_amount,
          paid_amount,
          installment_amount,
          annual_fee,
        }) => {
          var fees_details = {
            feeshead_id: Number(feeshead_id),
            total_amount: Number(annual_fee),
            paid_amount: Number(installment_amount),
          };
          return fees_details;
        }
      );
      const body = {
        fk_academic: session_id,
        section_id: section_id,
        student_id: idS,
        school_id: Number(user_detail.fk_school_id),
        receipt_no: receiptS,
        receipt_date: receiptDate,
        payment_mode: paymentInfo.payment_mode,
        balance_amount: paymentInfo.balance_amount,
        remark: paymentInfo.remark,
        fees_details: JSON.stringify(fees_details),
        insert_by: school_emp_id,
        // paid_amount: paymentInfo.paid_amount,
        // total_amount: paymentInfo.total_amount,
        paid_amount: paymentInfo.total_amount,
        total_amount: paymentInfo.paid_amount,
        late_charge: paymentInfo.late_charge,
        bounce_charge: paymentInfo.bounce_charge,
      };
      await dispatch(regularfeesReceipt(body));
    }
  };

  const annual_fee = [];
  const Sum_annual_fee = () => {
    const sum = annual_fee.reduce((acc, current) => acc + current, 0);
    return Number(sum);
  };

  const due_amount = [];
  const Sum_due_fee = () => {
    const sum = due_amount.reduce((acc, current) => acc + current, 0);
    return Number(sum);
  };

  const [btnClick, setbtnClick] = useState(false);
  useEffect(() => {
    if (!FeesTable) {
      setbtnClick(false);
    } else {
      if (Sum_due_fee() === 0 || Sum_due_fee() < Sum_annual_fee()) {
        setbtnClick(true);
      } else {
        setbtnClick(false);
      }
    }
  });

  const toggle = () => {
    setModal(!modal);
    setSearchResults([]);
    setNoResultsMessage("");
  };

  const handleStudentEdit = (data) => {
    setstudent_uid(data?.student_code);
    setReceiptType("Edit");
    setIsEditReceipt(data);
    setPaymentInfo({
      payment_mode: data?.payment_mode,
      total_amount: data?.total_amount,
      paid_amount: data?.paid_amount,
      // total_amount: data?.total_amount,
      // paid_amount: data?.paid_amount,
      late_charge: data?.late_charge,
      bounce_charge: data?.bounce_charge,
      balance_amount: data?.balance_amount,
      remark: data?.remark,
      bank_name: data?.bank_name,
      pay_no: data?.pay_no,
      pay_date: data?.pay_date,
    });
    setPaymentMode({
      label: data?.payment_mode,
      value: data?.payment_mode,
    });
    setModal(!modal);
  };
  const handleClear = () => {
    setstudent_uid("");
    setPaymentInfo({
      payment_mode: "Cash",
      total_amount: 0,
      late_charge: 0,
      bounce_charge: 0,
      paid_amount: 0,
      balance_amount: 0,
      remark: "",
    });
    setFeesTable([]);
    setbtnClick(false);
    setReceiptType("New");
    setIsEditReceipt([]);
  };

  function convertDate(data) {
    const parsedDate = moment(data, "YYYY-MM-DD");
    const formattedDate = parsedDate.format("DD-MM-YYYY");
    // const parts = data.split("-");
    // const formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    return formattedDate;
  }

  const CancelReceipt = async () => {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    Swal.fire({
      title: "CONFIRMATION",
      text: "Do you want to Cancel Receipt?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: "No",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        const { value: cancelReason } = await Swal.fire({
          icon: "question",
          title: "Enter Cancel Reason ?",
          input: "text",
          inputPlaceholder: "Enter your cancel reason",
          showCancelButton: true,
          confirmButtonText: "Confirm",
          allowOutsideClick: false,
        });

        if (cancelReason) {
          let body = {
            student_fees_id: isEditReceipt?.id,
            school_emp_id: school_emp_id,
            cancel_date: formattedDate,
            cancel_reason: cancelReason,
            insert_by: school_emp_id,
          };

          const res = await ReceiptAPI.cancelregularReceipt(body);
          if (res.data.status === 200) {
            Swal.fire({
              text: res.data.msg,
              icon: "success",
              imageAlt: "success image",
              allowOutsideClick: false,
            }).then((result) => {
              if (result.isConfirmed) {
                handleClear();
                setReceiptType("New");
                setIsEditReceipt([]);
              }
            });
          } else if (res.data.status === 403) {
            Swal.fire({
              text: res.data.msg,
              icon: "error",
              imageAlt: "success image",
              allowOutsideClick: false,
            });
          }
        }
      }
    });
  };

  return (
    <div>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="header-action">
              <h1 className="page-title"> Regular Fees Receipt</h1>
            </div>
            <div className="input-group-append">
              {receiptType === "New" ? null : (
                <button
                  type="button"
                  className="btn btn-danger mx-1"
                  onClick={() => CancelReceipt()}
                >
                  Cancel
                </button>
              )}
              <button
                type="button"
                className="btn btn-outline-danger mx-1"
                onClick={handleClear}
              >
                <i className="fa fa-times px-1"></i>
                Clear
              </button>
              <button
                className="btn btn-outline-secondary mx-1"
                type="button"
                onClick={toggle}
                // disabled
              >
                <i className="fa fa-search"> </i> Search
              </button>
            </div>
          </div>
        </div>
        <br />
        <div className="container-fluid">
          <div className="card">
            <div className="card-body">
              <div className="row">
                <div className="col-lg-6 ">
                  <div className="row">
                    <div className="col-lg-4 ">
                      <label className="col-form-label mx-2">
                        Student Code <span className="text-danger">*</span>
                      </label>
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Code"
                          value={student_uid}
                          onChange={(e) => setstudent_uid(e.target.value)}
                        />
                        <StudentSessionWiseList
                          setstudent_uid={setstudent_uid}
                        />
                      </div>
                    </div>
                    <div className="col-lg-8 ">
                      <label className="col-form-label mx-2">
                        Student Name <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Name"
                        disabled
                        value={nameS}
                      />
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 ">
                  <div className="row">
                    <div className="col-lg-4 ">
                      <label className="col-form-label mx-2">
                        Receipt Date <span className="text-danger">*</span>
                      </label>
                      <input
                        type="date"
                        className="form-control"
                        value={receiptDate}
                        onChange={(e) => setreceiptDate(e.target.value)}
                      />
                    </div>

                    <div className="col-lg-4 ">
                      <label className="col-form-label mx-2">
                        Receipt No <span className="text-danger">*</span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Receipt No"
                        value={isEditReceipt?.receipt_no || ""}
                        // onChange={(e) => setreceiptDate(e.target.value)}
                        readOnly
                      />
                    </div>
                    <div className="col-lg-4 ">
                      <label className="col-form-label mx-2">
                        Hostel Fees <span className="text-danger"></span>
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Hostel Fees"
                        value={(nameS && hostelBalancefee) || ""}
                        // onChange={(e) => setreceiptDate(e.target.value)}
                        readOnly
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-8">
              <div className="table-responsive card">
                <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
                  <thead>
                    <tr>
                      <th>Sr.No.</th>
                      <th>Fees Heads</th>
                      <th className="text-center">Total Fees</th>
                      <th className="text-center">Discount</th>
                      <th className="text-center">Fees Paid</th>
                      <th className="text-center">Amount</th>
                      {receiptType === "Edit" ? (
                        ""
                      ) : (
                        <th className="text-center">Fees Due</th>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {nameS &&
                      FeesTable?.map((row, index) => {
                        return (
                          <tr>
                            <td>{index + 1}</td>
                            <td>{row.feeshead_name}</td>
                            <td>
                              {receiptType === "Edit" ? (
                                <input
                                  disabled
                                  type="number"
                                  className="form-control text-right"
                                  value={Number(row.total_amount)}
                                  onChange={(event) =>
                                    handleInputChange(
                                      index,
                                      "total_amount",
                                      event.target.value
                                    )
                                  }
                                />
                              ) : (
                                <input
                                  disabled
                                  type="number"
                                  className="form-control text-right"
                                  value={Number(row.annual_fee)}
                                  onChange={(event) =>
                                    handleInputChange(
                                      index,
                                      "annual_fee",
                                      event.target.value
                                    )
                                  }
                                />
                              )}
                            </td>
                            <td>
                              <input
                                disabled
                                type="number"
                                className="form-control text-right"
                                value={Number(row.discount)}
                                onChange={(event) =>
                                  handleInputChange(
                                    index,
                                    "discount_amount",
                                    event.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                disabled
                                type="number"
                                className="form-control text-right"
                                value={Number(row.paid_amount)}
                                onChange={(event) =>
                                  handleInputChange(
                                    index,
                                    "paid_amount",
                                    event.target.value
                                  )
                                }
                              />
                            </td>
                            <td>
                              <input
                                disabled={receiptType === "Edit" ? true : false}
                                type="text"
                                className="form-control text-right"
                                value={
                                  receiptType === "Edit"
                                    ? Number(row.paid_amount)
                                    : Number(row.installment_amount) ||
                                      Number(0)
                                }
                                onChange={(event) => {
                                  if (
                                    /^-?\d*(\.\d*)?$/.test(event.target.value)
                                  ) {
                                    const numericValue = Number(
                                      event.target.value
                                    );
                                    if (numericValue <= row.due_amount) {
                                      if (numericValue < 0) {
                                        alert.info(
                                          "Amount can not be less than zero"
                                        );
                                      } else {
                                        handleInputChange(
                                          index,
                                          "installment_amount",
                                          numericValue.toString()
                                        );
                                      }
                                    } else {
                                      alert.info(
                                        "Amount can not be greater than Fee Amount"
                                      );
                                    }
                                  }

                                  // if (
                                  //   Number(event.target.value) <=
                                  //   Number(row.due_amount)
                                  // ) {
                                  //   if (Number(event.target.value) < 0) {
                                  //     alert.info(
                                  //       "Amount can not be less than zero"
                                  //     );
                                  //   } else {
                                  //     handleInputChange(
                                  //       index,
                                  //       "installment_amount",
                                  //       event.target.value
                                  //     );
                                  //   }
                                  // } else {
                                  //   alert.info(
                                  //     "Amount can not be greater than due amount"
                                  //   );
                                  // }
                                }}
                              />
                            </td>
                            {receiptType === "Edit" ? (
                              ""
                            ) : (
                              <td>
                                <input
                                  disabled
                                  type="number"
                                  className="form-control text-right"
                                  value={Number(row.due_amount)}
                                  onChange={(event) =>
                                    handleInputChange(
                                      index,
                                      "due_amount",
                                      event.target.value
                                    )
                                  }
                                />
                              </td>
                            )}

                            <span className="d-none">
                              {/* <p>{row.installment_amount = row.due_amount - row.installment_amount}</p> */}
                              {due_amount.push(Number(row.due_amount))}
                              {receiptType === "Edit"
                                ? annual_fee.push(Number(row.paid_amount))
                                : annual_fee.push(
                                    Number(row.installment_amount)
                                  )}
                            </span>
                          </tr>
                        );
                      })}
                    {due_amount.reduce((total, amount) => total + amount, 0) >
                      0 && (
                      <tr>
                        <td colSpan="5" className="text-right">
                          <strong>Total Fees Due:</strong>
                        </td>
                        <td className="text-center">
                          {/* Calculate the sum of due_amount array */}
                          {due_amount.reduce(
                            (total, amount) => total + amount,
                            0
                          )}
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div className="col-lg-4">
              <div className="card">
                <div className="card-body">
                  <h6 className="page-title">Payment Information</h6>
                  {/* <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Payment Mode</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Payment Mode"
                      value={paymentInfo.payment_mode}
                      onChange={(event) =>
                        setPaymentInfo((prevState) => ({
                          ...prevState,
                          payment_mode: event.target.value,
                        }))
                      }
                    />
                  </div> */}
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">
                      Payment Mode <span className="text-danger">*</span>
                    </label>
                    <Select
                      options={PaymentList}
                      placeholder="Payment Mode"
                      value={PaymentMode}
                      defaultValue={PaymentList[0]}
                      onChange={(event) => {
                        handlePaymentMode(event);
                        setPaymentInfo((prevState) => ({
                          ...prevState,
                          payment_mode: event.value,
                        }));
                      }}
                      isSearchable={true}
                      className="form-control m-0 p-0"
                    />
                  </div>
                  {paymentInfo.payment_mode === "Cash" ? null : (
                    <>
                      {paymentInfo.payment_mode === "UPI" ? null : (
                        <div className="d-flex py-1">
                          <label className="givenWigth m-auto">Bank Name</label>
                          <input
                            disabled={
                              PaymentMode?.value === "Cash" ? true : false
                            }
                            className="form-control"
                            type="text"
                            placeholder="Bank Name"
                            value={paymentInfo.bank_name}
                            onChange={(event) =>
                              setPaymentInfo((prevState) => ({
                                ...prevState,
                                bank_name: event.target.value,
                              }))
                            }
                          />
                        </div>
                      )}
                      <div className="d-flex py-1">
                        <label className="givenWigth m-auto">
                          {paymentInfo.payment_mode === "Bank"
                            ? "Account"
                            : paymentInfo.payment_mode}{" "}
                          Number
                        </label>
                        <input
                          disabled={
                            PaymentMode?.value === "Cash" ? true : false
                          }
                          className="form-control"
                          type="number"
                          placeholder={
                            paymentInfo.payment_mode === "Bank"
                              ? "Account Number"
                              : paymentInfo.payment_mode + " Number"
                          }
                          value={paymentInfo.pay_no}
                          onChange={(event) =>
                            setPaymentInfo((prevState) => ({
                              ...prevState,
                              pay_no: event.target.value,
                            }))
                          }
                        />
                      </div>
                      <div className="d-flex py-1">
                        <label className="givenWigth m-auto">
                          Payment Date
                        </label>
                        <input
                          disabled={
                            PaymentMode?.value === "Cash" ? true : false
                          }
                          className="form-control"
                          type="date"
                          placeholder="Payment Date"
                          value={paymentInfo.pay_date}
                          onChange={(event) =>
                            setPaymentInfo((prevState) => ({
                              ...prevState,
                              pay_date: event.target.value,
                            }))
                          }
                        />
                      </div>
                    </>
                  )}
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Total Amount </label>
                    <input
                      disabled
                      className="form-control text-right"
                      type="number"
                      placeholder="Total Amount"
                      value={(paymentInfo.total_amount = Sum_annual_fee())}
                      onChange={(event) =>
                        setPaymentInfo((prevState) => ({
                          ...prevState,
                          total_amount: event.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Late Charges</label>
                    <input
                      className="form-control text-right"
                      type="number"
                      placeholder="Late Charges"
                      value={paymentInfo.late_charge}
                      onChange={(event) => {
                        if (/^-?\d*(\.\d*)?$/.test(event.target.value)) {
                          const numericValue = Number(event.target.value);
                          // if (numericValue <= paymentInfo.total_amount) {
                          if (numericValue < 0) {
                            alert.info("Amount can not be less than zero");
                          } else {
                            setPaymentInfo((prevState) => ({
                              ...prevState,
                              late_charge: numericValue.toString(),
                            }));
                          }
                          // } else {
                          //   alert.info(
                          //     "Discount can not be greater than Total Amount"
                          //   );
                          // }
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Bounce Charges</label>
                    <input
                      className="form-control text-right"
                      type="number"
                      placeholder="Bounce Charges "
                      value={paymentInfo.bounce_charge}
                      onChange={(event) => {
                        if (/^-?\d*(\.\d*)?$/.test(event.target.value)) {
                          const numericValue = Number(event.target.value);
                          // if (numericValue <= paymentInfo.total_amount) {
                          if (numericValue < 0) {
                            alert.info("Amount can not be less than zero");
                          } else {
                            setPaymentInfo((prevState) => ({
                              ...prevState,
                              bounce_charge: numericValue.toString(),
                            }));
                          }
                          // } else {
                          //   alert.info(
                          //     "Discount can not be greater than Total Amount"
                          //   );
                          // }
                        }
                      }}
                    />
                  </div>
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Paid Amount</label>
                    <input
                      disabled
                      className="form-control text-right"
                      type="number"
                      placeholder="Paid Amount"
                      value={
                        (paymentInfo.paid_amount =
                          Sum_annual_fee() +
                          Number(paymentInfo.late_charge) +
                          Number(paymentInfo.bounce_charge))
                      }
                      onChange={(event) =>
                        setPaymentInfo((prevState) => ({
                          ...prevState,
                          paid_amount: event.target.value,
                        }))
                      }
                    />
                  </div>
                  <div className="d-flex py-1">
                    <label className="givenWigth m-auto">Remark</label>
                    <input
                      className="form-control"
                      type="text"
                      placeholder="Remark"
                      value={paymentInfo.remark}
                      onChange={(event) =>
                        setPaymentInfo((prevState) => ({
                          ...prevState,
                          remark: event.target.value,
                        }))
                      }
                    />
                  </div>

                  <PrintRegularFeesRecipt
                    FeesReceipt={FeesReceipt}
                    FeesTable={FeesTable}
                    name={nameS}
                    code={student_uid}
                    receiptNo={receiptS ? receiptS : isEditReceipt?.receipt_no}
                    msgS={msgS}
                    receiptDate={receiptDate}
                    paymentInfo={paymentInfo}
                    schoolUser={school_user}
                    session={studentName?.payload?.academic_year}
                    classSec={
                      studentName?.payload?.std_class +
                      " " +
                      studentName?.payload?.section
                    }
                    btnClick={btnClick}
                    convertDate={convertDate}
                    navigate={navigate}
                    handleClear={handleClear}
                    alert={alert}
                    receiptType={receiptType}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        fullscreen
        className="mobel_box"
        backdrop="static"
        keyboard={false}
      >
        <FeeReceiptList
          toggle={toggle}
          receiptData={receiptData}
          noResultsMessage={noResultsMessage}
          setNoResultsMessage={setNoResultsMessage}
          searchResults={searchResults}
          setSearchResults={setSearchResults}
          handleStudentEdit={handleStudentEdit}
        />
      </Modal>
    </div>
  );
};

export default RegularFees;
