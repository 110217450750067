export const GET_CLASS_WISE_SUBJECT_REQUEST = 'GET_CLASS_WISE_SUBJECT_REQUEST';
export const GET_CLASS_WISE_SUBJECT_SUCCESS = 'GET_CLASS_WISE_SUBJECT_SUCCESS';
export const GET_CLASS_WISE_SUBJECT_FAILURE = 'GET_CLASS_WISE_SUBJECT_FAILURE';


export const GET_CHAPTER_REQUEST = 'GET_CHAPTER_REQUEST';
export const GET_CHAPTER_SUCCESS = 'GET_CHAPTER_SUCCESS';
export const GET_CHAPTER_FAILURE = 'GET_CHAPTER_FAILURE';


export const ADD_CHAPTER_REQUEST = 'ADD_CHAPTER_REQUEST';
export const ADD_CHAPTER_SUCCESS = 'ADD_CHAPTER_SUCCESS';
export const ADD_CHAPTER_FAILURE = 'ADD_CHAPTER_FAILURE';


export const ADD_CONTENT_REQUEST = 'ADD_CONTENT_REQUEST';
export const ADD_CONTENT_SUCCESS = 'ADD_CONTENT_SUCCESS';
export const ADD_CONTENT_FAILURE = 'ADD_CONTENT_FAILURE';

export const UPDATE_CONTENT_REQUEST = 'UPDATE_CONTENT_REQUEST';
export const UPDATE_CONTENT_SUCCESS = 'UPDATE_CONTENT_SUCCESS';
export const UPDATE_CONTENT_FAILURE = 'UPDATE_CONTENT_FAILURE';


export const GET_CONTENT_REQUEST = 'GET_CONTENT_REQUEST';
export const GET_CONTENT_SUCCESS = 'GET_CONTENT_SUCCESS';
export const GET_CONTENT_FAILURE = 'GET_CONTENT_FAILURE';


export const DELETE_CONTENT_REQUEST = 'DELETE_CONTENT_REQUEST';
export const DELETE_CONTENT_SUCCESS = 'DELETE_CONTENT_SUCCESS';
export const DELETE_CONTENT_FAILURE = 'DELETE_CONTENT_FAILURE';