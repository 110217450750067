import React, { Component } from "react";
import { Navigate } from "react-router-dom";

// publicRoutes

import ForgotPassword from "../pages/Auth/ForgotPassword.js";
import Logout from "../pages/Auth/Logout.js";
import Login from "../pages/Auth/Login.js";
import Mainscreen from "../pages/en/Mainscreen.js";
import VerifyOTP from "../pages/Auth/VerifyOTP.js";
import ConfirmPassword from "../pages/Auth/ConfirmPassword.js";
import DownloadGradeCard from "../pages/ExternalGradeCard.js/GradeCard.js";



import NotFound from "../pages/404/NotFound.js";
import AddSession from "../pages/superadmin/sessionManagement/AddSession.js";
import SchoolRegistration from "../pages/superadmin/schoolRegistration/SchoolRegistration.js";
import Organization from "../pages/superadmin/Organization/index.js";


import StudentRegistration from "../pages/schooladmin/studentRegistration/StudentRegistration.js";
import StudentProfile from "../pages/schooladmin/studentProfile/StudentProfile.js";
import Admission from "../pages/schooladmin/Admission/StudentAdmission.js";
import Master from "../pages/superadmin/Master/index.js";
import StaffMaster from "../pages/schooladmin/staffMaster/StaffMaster.js";
import EditStaffMaster from "../pages/schooladmin/staffMaster/EditStaffMaster.js";
import HolidayMaster from "../pages/schooladmin/Administration/holidayMaster/HolidayMaster.js";
import EditHoliday from "../pages/schooladmin/Administration/holidayMaster/EditHoliday.js";
import Attendance from "../pages/schooladmin/Attendance/Attendance.js";
import StudentReports from "../pages/schooladmin/Reports/StudentReports.js";
import RegularFees from "../pages/schooladmin/FeeReceipt/RegularFees.js";
import SpecialFee from "../pages/schooladmin/FeeReceipt/SpecialFee.js";
import BonafideCertificate from "../pages/schooladmin/Admission/StudentCertificate/Certificate.js";
import EditStudentRegistration from "../pages/schooladmin/studentRegistration/EditStudentRegistration.js";
import StaffUserMaster from "../pages/schooladmin/master/UserMaster/StaffUserMaster.js";
import AddUsers from "../pages/superadmin/users/AddUsers.js";
import EditUsers from "../pages/superadmin/users/EditUsers.js";
import EditSchoolReg from "../pages/superadmin/schoolRegistration/EditSchoolReg.js";
import EditSession from "../pages/superadmin/sessionManagement/EditSession.js";
import ProspectusReceipt from "../pages/schooladmin/Prospectus/ProspectusReceipt.js";
import ProspectusReport from "../pages/schooladmin/Prospectus/ProspectusReport.js";
import SubjectMaster from "../pages/schooladmin/Administration/subjectMaster/AddSubject.js";
import ClassSubject from "../pages/schooladmin/Administration/subjectMaster/ClassSubject.js";
import CancelAdmission from "../pages/schooladmin/Admission/CancelAdmission.js";
import RollNoAllotment from "../pages/schooladmin/Administration/RollNoAllotment.js";
import BulkUploadData from "../pages/schooladmin/StudentDataUpload/BulkUploadData.js";
import ContentUploader from "../pages/schooladmin/LMS/ContenUploader/ContentUploader.js";
import TestPage from "../pages/schooladmin/LMS/MockTest/TestPage.js";
import TestCreatePage from "../pages/schooladmin/LMS/MockTest/TestCreatePage.js";
import TestEditPage from "../pages/schooladmin/LMS/MockTest/TestEditPage.js";
import TestResult from "../pages/schooladmin/LMS/MockTest/TestResult.js";
import FeeCollection from "../pages/schooladmin/FeeCollection/FeeCollection.js";
import FeeDiscount from "../pages/schooladmin/FeeDiscount/FeeDiscount.js";
import CancelReceipt from "../pages/schooladmin/CancelReceipt/CancelReceipt.js";
import PeriodConfiguration from "../pages/schooladmin/Admission/Timetable/PeriodConfiguration.js";
import LeavingCertificate from "../pages/schooladmin/Admission/LeavingCertificate/LeavingCertificate.js";
import GenerateTimeTable from "../pages/schooladmin/Admission/Timetable/GenerateTimeTable.js";
import DiscountReport from "../pages/schooladmin/Admission/DiscountReport/DiscountReport.js";
import TeacherTimeTable from "../pages/schooladmin/Admission/Timetable/TeacherTimeTable.js";
import Syllabus from "../pages/schooladmin/TeacherDesk/Syllabus/Syllabus.js";
import Circular from "../pages/schooladmin/TeacherDesk/Circular/Circular.js";
import StudentSubject from "../pages/schooladmin/TeacherDesk/StudentSubject/StudentSubject.js";
import WorkSheet from "../pages/schooladmin/TeacherDesk/WorkSheet/WorkSheet.js";
import TeacherDashboard from "../pages/schooladmin/TeacherDesk/Dashboard/Dashboard.js";
import AnnualPlaner from "../pages/schooladmin/TeacherDesk/AnnualPlaner/AnnualPlaner.js";
// import SchoolCommunication from "../pages/schooladmin/TeacherDesk/SchoolCommunication/SchoolCommunication.js";
import FeeOutstandingReport from "../pages/schooladmin/Admission/FeeOutstandingReport/FeeOutstandingReport.js";
import Events from "../pages/schooladmin/TeacherDesk/EventsAndActivity/Event.js";
import PromoteStudent from "../pages/schooladmin/Admission/PromoteStudent/PromoteStudent.js";
import FeesDashboard from "../pages/schooladmin/Fees/Dashboard/Dashboard.js";
import ExamDashboard from "../pages/schooladmin/Examination/Dashboard/Dashboard.js";
import GroupClassMapping from "../pages/schooladmin/Examination/GroupandClass/GroupClassMapping.js";
import CategoryMaster from "../pages/schooladmin/Examination/CategoryMaster/CategoryMaster.js";
import GradeMaster from "../pages/schooladmin/Examination/GradeMaster/GradeMaster.js";
import OnlineTest from "../pages/schooladmin/LMS/OnlineTest/OnlineTest.js";
import CreateTest from "../pages/schooladmin/LMS/OnlineTest/CreateTest.js";
import OnlineTestEdit from "../pages/schooladmin/LMS/OnlineTest/OnlineTestEdit.js";
import OnlineTestResult from "../pages/schooladmin/LMS/OnlineTest/TestResult.js";
import Exam from "../pages/schooladmin/Examination/Exam/Exam.js";
import ExamWiseSubject from "../pages/schooladmin/Examination/ExamWiseSubject/ExamWiseSubject.js";
import MarkEntry from "../pages/schooladmin/Examination/MarkEntry/MarkEntry.js";
import ExamDetails from "../pages/schooladmin/Examination/ExamDetails/ExamDetails.js";
import GradeCard from "../pages/schooladmin/Examination/GradeCard/GradeCard.js";
import ChangePassword from "../pages/Auth/ChangePassword.js";
import HostelDashboard from "../pages/schooladmin/Hostel/Dashboard/Dashboard.js";
import HostelRegistration from "../pages/schooladmin/Hostel/HostelRegistration/HostelRegistration.js";
import HostelDetails from "../pages/schooladmin/Hostel/HostelDetails/HostelDetails.js";
import Dashboard from "../pages/schooladmin/Dashboard/Dashboard.js";
import SMSNotification from "../pages/schooladmin/Administration/SMS/SMSNotification.js";
import Profile from "../pages/schooladmin/Profile/Profile.js";
import AdministrationDashboard from "../pages/schooladmin/Administration/Dashboard/Dashboard.js";
import AdmissionDashboard from "../pages/schooladmin/Admission/Dashboard/Dashboard.js";
import RoomAllotment from "../pages/schooladmin/Hostel/RoomAllotment/RoomAllotment.js";
import HostelReport from "../pages/schooladmin/Hostel/HostelReport/HostelReport.js";
import BulkRoomDeallocation from "../pages/schooladmin/Hostel/BulkRoomDeallocation/BulkRoomDeallocation.js";
import HostelFeesReceipt from "../pages/schooladmin/Hostel/Fees/HostelFeesReceipt.js";
import HostelFeeCollection from "../pages/schooladmin/Hostel/Fees/HostelFeeCollection.js";
import AdminDashboard from "../pages/schooladmin/Dashboard/AdminDashboard.js";
import Help from "../pages/help/Help.js";
import SuperAdminProfile from "../pages/superadmin/Profile/Profile.js";
import ResultReport from "../pages/schooladmin/LMS/ResultReport/ResultReport.js";
import AddNotification from "../pages/schooladmin/Dashboard/AddNotification.js";
import BusMaster from "../pages/schooladmin/Transport/BusMaster.js";
import DriverMaster from "../pages/schooladmin/Transport/DriverMaster.js";
import RouteConfiguration from "../pages/schooladmin/Transport/RouteConfiguration.js";
import Dummy from '../pages/schooladmin/Examination/GradeCard/Dummy.js';
import SchoolCommunication from "../pages/schooladmin/SchoolCommunication/SchoolCommunication.js";
import StudentPickup from "../pages/schooladmin/Transport/StudentPickup.js";
import BusFeeReceipt from "../pages/schooladmin/Transport/BusFeeCollection/BusFeeReceipt.js";
import EditRoute from "../pages/schooladmin/Transport/Models/EditRoute.js";
import AddRoute from "../pages/schooladmin/Transport/Models/AddRoute.js";
import PickupPoint from "../pages/schooladmin/Transport/Models/PickupPoint.js";
import EditPickupPoint from "../pages/schooladmin/Transport/Models/EditPickupPoint.js";
import AssignPickupPoint from "../pages/schooladmin/Transport/Models/ChangeModal.js";
import AddAssignPickupPoint from "../pages/schooladmin/Transport/Models/AddAssignePickUp.js";
import PrivacyPolicy from "../SupportPage/PrivacyPolicy.js";
import TermsAndCondition from "../SupportPage/TermsAndCondition.js";
import AboutUs from "../SupportPage/AboutUs.js";
import DailyBusFeeReport from '../pages/schooladmin/Transport/Reports/DailyBusFeeReport.js'
import StudentBusRouteReport from '../pages/schooladmin/Transport/Reports/StudentBusRouteReport.js'
import BusFeeBalanceReport from '../pages/schooladmin/Transport/Reports/BusFeeBalanceReport.js'
import BusRouteReport from '../pages/schooladmin/Transport/Reports/BusRouteReport.js'
import ActualMap from "../pages/schooladmin/Transport/Map/ActualMap.js";
import SalaryHeadMaster from "../pages/schooladmin/Payroll/SalaryHeadMaster.js";
import LeaveMaster from "../pages/schooladmin/Payroll/LeaveMaster.js";
import SalaryStructure from "../pages/schooladmin/Payroll/SalaryStructure.js";
import UploadAttendance from "../pages/schooladmin/Payroll/UploadAttendance.js";
import MonthlyAttendance from "../pages/schooladmin/Payroll/MonthlyAttendance.js";
import GenerateSalary from "../pages/schooladmin/Payroll/GenerateSalary.js"
import SalaryReport from "../pages/schooladmin/Payroll/SalaryReport.js"
import AttendenceReport from "../pages/schooladmin/Payroll/AttendenceReport.js";
import MonthlySalaryReport from "../pages/schooladmin/Payroll/MonthlySalaryReport.js";



const authProtectedRoutes = [
  { path: "/admin-dashboard", component: <AdminDashboard /> },
  { path: "/dashboard", component: <Dashboard /> },
  { path: "/organization", component: <Organization /> },
  { path: "/school-registration", component: <SchoolRegistration /> },
  { path: "/school-details", component: <EditSchoolReg /> },
  { path: "/users", component: <AddUsers /> },
  { path: "/user-details", component: <EditUsers /> },
  { path: "/session-management", component: <AddSession /> },
  { path: "/session-details", component: <EditSession /> },
  { path: "/master", component: <Master /> },
  { path: "/*", component: <NotFound /> },
  {path:"/PrivacyPolicy",component:<PrivacyPolicy/>},
  {path:"/TermsAndCondition",component:<TermsAndCondition/>},
  {path:"/AboutUs",component:<AboutUs/>},
  { path: "/student-registration", component: <StudentRegistration /> },
  { path: "/edit-student-registration", component: <EditStudentRegistration /> },
  { path: "/student-profile/", component: <StudentProfile /> },
  { path: "/student-admission", component: <Admission /> },
  { path: "/student-admission/:uid", component: <Admission /> },
  { path: "/student-attendance", component: <Attendance /> },
  { path: "/staff-master", component: <StaffMaster /> },
  { path: "/edit-staff-master", component: <EditStaffMaster /> },
  { path: "/user-master", component: <StaffUserMaster /> },
  { path: "/user-master/:id", component: <StaffUserMaster /> },
  { path: "/holiday-master", component: <HolidayMaster /> },
  { path: "/edit-holiday", component: <EditHoliday /> },
  { path: "/student-reports", component: <StudentReports /> },
  { path: "/regular-fees", component: <RegularFees /> },
  { path: "/regular-fees/:uid", component: <RegularFees /> },
  { path: "/special-fee", component: <SpecialFee /> },
  { path: "/bonafide", component: <BonafideCertificate /> },
  { path: "/prospectus-receipt", component: <ProspectusReceipt /> },
  { path: "/prospectus-report", component: <ProspectusReport /> },
  { path: "/cancel-admission", component: <CancelAdmission /> },
  { path: "/subject-master", component: <SubjectMaster /> },
  { path: "/class-subject", component: <ClassSubject /> },
  { path: "/roll-no-allotment", component: <RollNoAllotment /> },
  { path: "/content-uploader", component: <ContentUploader /> },
  { path: "/fee-collection", component: <FeeCollection /> },
  { path: "/fee-discount", component: <FeeDiscount /> },
  { path: "/leaving-certificate", component: <LeavingCertificate /> },
  { path: "/discount-report", component: <DiscountReport /> },
  { path: "/cancel-receipt", component: <CancelReceipt /> },
  { path: "/bulk-student-upload", component: <BulkUploadData /> },
  { path: "/mock-test", component: <TestPage /> },
  { path: "/mock-test-create", component: <TestCreatePage /> },
  { path: "/mock-test-edit", component: <TestEditPage /> },
  { path: "/mock-test-result/:id", component: <TestResult /> },
  { path: "/period-configuration", component: <PeriodConfiguration /> },
  { path: "/generate-timetable", component: <GenerateTimeTable /> },
  { path: "/teacher-timetable", component: <TeacherTimeTable /> },
  { path: "/teacher-dashboard", component: <TeacherDashboard /> },
  { path: "/syllabus", component: <Syllabus /> },
  { path: "/circulars", component: <Circular /> },
  { path: "/student-subject", component: <StudentSubject /> },
  { path: "/annual-planner", component: <AnnualPlaner /> },
  { path: "/home-work", component: <WorkSheet /> },
  { path: "/school-communication", component: <SchoolCommunication /> },
  { path: "/fee-outstanding-report", component: <FeeOutstandingReport /> },
  { path: "/events", component: <Events /> },
  { path: "/promote-student", component: <PromoteStudent /> },
  { path: "/fees-dashboard", component: <FeesDashboard /> },
  { path: "/exam-dashboard", component: <ExamDashboard /> },
  { path: "/group-class-mapping", component: <GroupClassMapping /> },
  { path: "/category-master", component: <CategoryMaster /> },
  { path: "/grade-master", component: <GradeMaster /> },
  { path: "/online-test", component: <OnlineTest /> },
  { path: "/create-online-test", component: <CreateTest /> },
  { path: "/online-test-edit", component: <OnlineTestEdit /> },
  { path: "/online-test-result/:id", component: <OnlineTestResult /> },
  { path: "/exam", component: <Exam /> },
  { path: "/exam-wise-subject", component: <ExamWiseSubject /> },
  { path: "/mark-entry", component: <MarkEntry /> },
  { path: "/exam-details", component: <ExamDetails /> },
  { path: "/grade-card", component: <GradeCard /> },
  { path: "/change-Password", component: <ChangePassword /> },
  { path: "/help", component: <Help /> },
  { path: "/hostel-dashboard", component: <HostelDashboard /> },
  { path: "/hostel-registration", component: <HostelRegistration /> },
  { path: "/hostel-details", component: <HostelDetails /> },
  { path: "/room-allotment", component: <RoomAllotment /> },
  { path: "/sms", component: <SMSNotification /> },
  { path: "/profile", component: <Profile /> },
  { path: "/super-admin-profile", component: <SuperAdminProfile /> },
  { path: "/administration-dashboard", component: <AdministrationDashboard /> },
  { path: "/admission-dashboard", component: <AdmissionDashboard /> },
  { path: "/room-report", component: <HostelReport /> },
  { path: "/room-deallocation", component: <BulkRoomDeallocation /> },
  { path: "/hostel-fees-receipt", component: <HostelFeesReceipt /> },
  { path: "/hostel-fee-collection", component: <HostelFeeCollection /> },
  { path: "/result-report", component: <ResultReport /> },
  {path: "/add-notification", component: <AddNotification/>},
  {path:'/bus-master',component:<BusMaster/>},
  {path:'/driver-master',component:<DriverMaster/>},
  {path:'/route-configuration',component:<AddRoute />},
  {path:'/dummy',component:<Dummy/>},
 {path:"/Student-Pickup",component:<StudentPickup/>},
 {path:"/Bus-Fee-Receipt", component:<BusFeeReceipt/>},
 {path:"/Edit-RouteConfiguration",component:<EditRoute />},
//  {path:"/add-Route",component:<AddRoute />},
 {path:"/Pickup-Point" ,component:<PickupPoint/>},
 {path:"/edit-pickup-point",component:<EditPickupPoint/>},
 {path:"/assign-pickup-point",component:<AssignPickupPoint/>},
 {path:"/Add-Assign-Pickup-Point",component:<AddAssignPickupPoint/>},
 {path:'/Daily-Bus-Fee-Report' ,component:<DailyBusFeeReport/>},
 {path:'/student-bus-route-report',component:<StudentBusRouteReport/>},
 {path:'/Bus-Fee-Balance-Report' ,component:<BusFeeBalanceReport/>},
 {path:'/Bus-Route-Report' ,component:<BusRouteReport/>},
 {path:'/Google-map' ,component:<ActualMap/>},
 {path:"/Salary-Head-Master",component:<SalaryHeadMaster/>},
 {path:"/leave-master",component:<LeaveMaster/>},
 {path:"/salary-structure",component:<SalaryStructure/>},
 {path:"/upload-attendance",component:<UploadAttendance/>},
 {path:"/monthly-attendance" ,component:<MonthlyAttendance/>},
 {path:"/generate-salary" ,component:<GenerateSalary/>},
 {path:"/Salary-Report",component:<SalaryReport/>},
 {path:"/attendence-report" ,component:<AttendenceReport/>},
 {path:"monthly-salary-report/",component:<MonthlySalaryReport/>}
];  


const publicRoutes = [
  { path: "/", component: <Mainscreen /> },
  { path: "*", component: <Navigate to="/login" /> },
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgotPassword /> },
  { path: "/verify-otp", component: <VerifyOTP /> },
  { path: "/confirm-password", component: <ConfirmPassword /> },
  { path: "/grade-card/:studentId", component: <DownloadGradeCard /> },
];

export { authProtectedRoutes, publicRoutes };
