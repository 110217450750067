import axios from "axios";
import { BASE_URL} from "../../Config/BaseUrl";
const token = localStorage.getItem("token");
const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

// getNotificationList: async (data) => {
//   const axiosConfig = createAxiosConfig("get_AddedNotification", "POST", data);
//   return await axios(axiosConfig);
// },
export const BusMasterApi = {
   BusMasterSave : async (data) => {
      const axiosConfig = createAxiosConfig("Bus_data_saveUpdate_api","POST", data);
      return await axios(axiosConfig);
    },

    BusMasterListData : async (data) => {
      const axiosConfig = createAxiosConfig("get_Busmaster_data","POST", data);
      return await axios(axiosConfig);
    },
    DeleteBusMasterdata:async (data)=>{
      const axiosConfig = createAxiosConfig("delete_BusMaster_data","POST",data);
      return await axios(axiosConfig);
    }

}