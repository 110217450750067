import React, { useEffect, useState, useRef, lazy, Suspense } from "react";
import Select from "react-select";
import { NavLink, useParams, useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useFormik } from "formik";
import {
  updateSchool,
  getCity,
  getFeeList,
  createFee,
  deleteFee,
  getClassSectionList,
  updateFee,
  createClass,
  deleteClass,
  getFeeListStructure,
  createFeeStructure,
  deleteFeeStructure,
  getSchoolRegistrationList,
} from "../../../actions/super-user/SchoolRegistration.js";
import Swal from "sweetalert2";
import { useAlert } from "react-alert";
import { getAllModule } from "../../../actions/super-user/CommanModule.js";
import Loader from "../../Loader/Loader.js";
import { sessionAPI } from "../../../api/sessionAPI.js";
import { getClassSection } from "../../../actions/school_user/Attenadance";
import ImageUpload from "../../schooladmin/studentRegistration/ImageUpload";
const UserAuth = lazy(() => import("./UserAuth.js"));
const HostelRegistration = lazy(() =>
  import("../../schooladmin/Hostel/HostelRegistration/HostelRegistration.js")
);
const SMSNotification = lazy(() =>
  import("../../schooladmin/Administration/SMS/SMSNotification.js")
);
import { resetUserMenu } from "../../../actions/super-user/UserAuthtication.js";
import SuspenseWrapper from "../../CommonComponent/SuspenseWrapper.js";
import SchoolStudentInfo from "./SchoolStudentInfo.js";
import PresiedentMessage from "./PresiedentMessage.js";
import Banner from "./Banner.js";
import Payroll from "./Payroll.js";
import DeleteStudent from "./DeleteStudent/DeleteStudent.js";

const EditSchoolReg = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let super_admin_id = school_user?.id;
  const { error, loading, schooldata, organizationdata, statelist, citylist } =
    useSelector((state) => state.schoolRegister);
  const { classsectionList, studentdata } = useSelector(
    (state) => state.attendance
  );
  const { fees, feeslist, feeStructure } = useSelector(
    (state) => state.schoolFee
  );
  const { classlist, classsection } = useSelector(
    (state) => state.classSection
  );
  const { createmasterData, masterData } = useSelector(
    (state) => state.masterData
  );
  const { moduleList } = useSelector((state) => state.module);
  const { active } = useSelector((state) => state.activeTab);
  const location = useLocation();
  const id = location.state?.id;

  useEffect(() => {
    if (!id) {
      navigate("/admin/admin-dashboard");
    }
  }, [id]);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const alert = useAlert();
  const [editData, setEditData] = useState(null);
  const [files1, setFiles1] = useState(null);
  const [ShowImg, setShowImg] = useState("");
  const [selectedState, setSelectedState] = useState("");
  const [edit, setEdit] = useState(false);
  const [classedit, setClassEdit] = useState(false);
  const [editId, setEditId] = useState();
  const [input, SetInput] = useState();
  const [editModule, setEditModule] = useState([]);
  const [checkedIds, setCheckedIds] = useState([]);
  const [getConcessionList, setGetConcessionList] = useState([]);
  const [mediumList, setMediumList] = useState([]);
  const [selectconnession, setSelectconnession] = useState("");
  const [selectclass, setSelectclass] = useState("");
  const [selectSection, setSelectSection] = useState("");
  const [preSelectedValue, setPreSelectedValue] = useState("");
  const [selectSession, setSelectSession] = useState("");
  const [sessiondata, setSessiondata] = useState([]);
  const [isFeeMainId, setIsFeeMainId] = useState([]);
  const [tableData1, setTableData1] = useState([]);
  const [removeModule, setRemoveModule] = useState([]);
  const [cityValue, setcityValue] = useState("");
  const [manageState, setManagesState] = useState("");

  const [cityCoordinates, setCityCoordinates] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);

  if (schooldata?.status === 403) {
    alert.error(schooldata.msg);
  }
  const editModuleList = editModule?.map((m) => {
    return {
      id: m.fk_module_id,
      name: m.fk_module__name,
    };
  });

  useEffect(() => {
    const defaultCheckedIds = editModuleList?.map((module) => module.id);
    setCheckedIds(defaultCheckedIds);
  }, [editModule]);

  const handleSwitchChange = (id) => {
    if (checkedIds?.includes(id)) {
      setCheckedIds(checkedIds.filter((checkedId) => checkedId !== id));
      setRemoveModule([...removeModule, id]);
    } else {
      setCheckedIds([...checkedIds, id]);
      setRemoveModule(removeModule.filter((checkedId) => checkedId !== id));
    }
  };
  useEffect(() => {
    if (token) {
      dispatch(getAllModule(token));
    }
  }, [dispatch, token]);

  const classSectionList = classsectionList?.payload?.map((cl) => {
    return {
      label: cl.class_name + " " + cl.section,
      value: `${cl.id} ${cl.fk_class_id}`,
    };
  });
  const connessionList = getConcessionList?.map((cl) => {
    return {
      label: cl.value,
      value: cl.id,
    };
  });
  const sessiondataList = sessiondata?.map((cl) => {
    return {
      label: cl.name,
      value: cl.id,
    };
  });
  function handleSelectclassSection(data) {
    setPreSelectedValue(data?.value);
    const values = data.value.split(" ");
    const selectSection = values[0];
    const selectclass = values[1];
    setSelectSection(selectSection);
    setSelectclass(selectclass);
  }
  function handleSelectconnession(data) {
    setSelectconnession(data);
  }
  function handleSelectSession(data) {
    setSelectSession(data);
  }

  let feeId = {
    school_id: Number(id),
  };
  useEffect(() => {
    if (token) {
      dispatch(getFeeList(token, feeId));
    }
  }, [dispatch, token]);
  useEffect(() => {
    if (token) {
      dispatch(getClassSectionList(token, feeId));
    }
  }, [dispatch, token]);
  let s_data = {
    school_id: Number(id),
  };
  useEffect(() => {
    if (token) {
      dispatch(getClassSection(token, s_data));
    }
  }, [dispatch, token, id]);
  useEffect(() => {
    let post = schooldata?.school_list?.find((sid) => sid.id === Number(id));
    setEditData(post);
    setShowImg(post?.school_logo);
    setEditModule(post?.module_list);
  }, [schooldata, id, schooldata?.school_list]);

  let getcityasperstate = {
    state_id: selectedState ? selectedState?.value : editData?.fk_state_id,
  };

  useEffect(() => {
    if (token) {
      dispatch(getCity(token, getcityasperstate));
    }
  }, [dispatch, token, selectedState, editData]);

  let sel_section_id = selectSection;
  let con_id = selectconnession?.value;
  let session_id = selectSession?.value;

  let fdata = {
    fk_section: sel_section_id || "",
    fk_concession: con_id || "",
    fk_academic_session: session_id,
    school_id: Number(id),
  };

  useEffect(() => {
    if (token && sel_section_id && con_id && session_id) {
      dispatch(getFeeListStructure(token, fdata));
    }
  }, [token, sel_section_id, con_id, session_id]);

  useEffect(() => {
    if (
      token &&
      sel_section_id &&
      con_id &&
      session_id &&
      feeStructure?.status === 403
    ) {
      Swal.fire({
        text: feeStructure?.msg,
        icon: "error",
        allowOutsideClick: false,
      });
    } else {
      setIsFeeMainId(feeStructure?.main_id);

      setTableData1(feeStructure?.payload);
    }
  }, [feeStructure]);

  useEffect(() => {
    setGetConcessionList(
      masterData?.payload?.filter((obj) => obj.name === "Concession")
    );
    setMediumList(masterData?.payload?.filter((obj) => obj.name === "Medium"));
  }, [masterData]);

  const orgListOption = organizationdata?.payload?.map((org) => {
    return {
      value: org.id,
      label: org.name,
    };
  });
  const stateListOption = statelist?.payload?.map((state) => {
    return {
      value: state.id,
      label: state.name,
    };
  });

  const cityListOption = citylist?.payload?.map((state) => {
    return {
      value: state.id,
      label: state.name,
    };
  });

  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      organization_id: editData?.fk_organization_id || "",
      name: editData?.name || "",
      contact_person: editData?.contact_person || "",
      contact_number: editData?.contact_number || "",
      contact_email: editData?.contact_email || "",
      address: editData?.address || "",
      state_id: manageState ? manageState : editData?.fk_state_id,
      city_id: cityValue ? cityValue : editData?.fk_city_id,
      pincode: editData?.pincode || "",
      status: editData?.status === "Active" ? true : false || false,
      school_logo: editData?.school_logo || "",
      udise_no: editData?.udise_no || "",
      // school_code: editData?.school_code || "",
      student_initial: editData?.student_initial || "",
      empolyee_initial: editData?.empolyee_initial || "",
      is_government: editData?.is_government || false,
      is_removeSchoollogo: "False",
      lat: cityCoordinates ? cityCoordinates.latitude : editData?.lat,
      lng: cityCoordinates ? cityCoordinates.longitude : editData?.lng,
      short_school_name: "",
      msg_api_key: "",
      sender_id: "",
    },
    validationSchema: Yup.object().shape({
      contact_email: Yup.string()
        .required("Contact Email is required")
        .email("Invalid email format")
        .matches(
          /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
          "Please enter a valid email address."
        ),
      sender_id: Yup.string().max(
        100,
        "Sender ID must not be greater than 100 characters"
      ),
      msg_api_key: Yup.string().max(
        100,
        "Message API key must not be greater than 100 characters"
      ),
      short_school_name: Yup.string().max(
        100,
        "Short school name must not be greater than 100 characters"
      ),
      udise_no: Yup.string()
        .required("Udise number is required")
        .matches(/^[0-9]{11}$/, "Udise No. must be an 11 number"),
      // school_code: Yup.string()
      //   .required("School code is required")
      //   .matches(/^\d{2,4}$/, "School code must be between 2 to 4 digits"),
      student_initial: Yup.string().required("Student initial is required"),
      // .matches(
      //   /^[a-zA-Z0-9]{3}$/,
      //   "Student initial must be between 3  alphanumeric characters"
      // ),
      empolyee_initial: Yup.string().required("Employee initial  is required"),
      // .matches(
      //   /^[a-zA-Z0-9]{3,4}$/,
      //   "Employee initial must be between 3 to 4 alphanumeric characters"
      // ),
      pincode: Yup.string()
        .required("Pincode is required")
        .matches(/^\d{6}$/, "Pincode must be a 6-digit number"),
      contact_number: Yup.string()
        .required("Contact number is required")
        .matches(/^[0-9]{10}$/, "Contact number must be a 10-digit number"),
    }),
    onSubmit: (values, { resetForm }) => {
      if (checkedIds.length === 0) {
        Swal.fire({
          title: "",
          text: "Please select at least one Module.",
          icon: "info",
          allowOutsideClick: false,
        });
      } else {
        const data = new FormData();
        data.append("school_id", Number(id));
        data.append("super_admin_id", super_admin_id);
        data.append("organization_id", values.organization_id);
        data.append("name", values.name);
        data.append("contact_person", values.contact_person);
        data.append("contact_number", values.contact_number);
        data.append("contact_email", values.contact_email);
        data.append("address", values.address);
        data.append("school_logo", values.school_logo);
        data.append("is_removeSchoollogo", values.is_removeSchoollogo);
        data.append("state_id", values.state_id);
        data.append("city_id", values.city_id);
        data.append("pincode", values.pincode);
        data.append("status", values.status ? "Active" : "Inactive");
        data.append("udise_no", values.udise_no);
        // data.append("school_code", values.school_code);
        data.append("school_code", "");
        data.append("student_initial", values.student_initial);
        data.append("empolyee_initial", values.empolyee_initial);
        data.append("is_government", values.is_government ? "True" : "False");
        data.append("modules", JSON.stringify(checkedIds));
        data.append("remove_modules", JSON.stringify(removeModule));
        data.append("lat", values.lat);
        data.append("lng", values.lng);
        data.append("short_school_name", values.short_school_name);
        data.append("msg_api_key", values.msg_api_key);
        data.append("sender_id", values.sender_id);
        dispatch(updateSchool(token, data))
          .then(() => {
            dispatch(getSchoolRegistrationList(token));
          })
          .catch((err) => console.error(err));
      }
    },
  });
  // const clearFormField = () => {
  //   validation.resetForm();
  //   validation.setFieldValue("organization_id", "");
  //   validation.setFieldValue("state_id", "");
  //   validation.setFieldValue("city_id", "");
  //   validation.setFieldValue("school_logo", "");
  //   setEditData([]);
  //   setFiles1(null);
  //   setEditModule([]);
  // };

  const handleFileRead = async (e) => {
    const file = e.target.files[0];
    validation.setFieldValue("school_logo", file);
    setFiles1(file);
    const base64 = await convertBase64(file);
    setShowImg(base64);
  };

  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const formClear = () => {
    fessformik.resetForm();
  };
  const apiKey = "AIzaSyA9w97EGCFVrTi0OEkK4N5T30nyEpP7IBM";

  const fetchCoordinates = async (selectedOption) => {
    if (!selectedOption) return;
    setcityValue(selectedOption.value);

    const city_id = selectedOption.label;

    const apiUrl = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
      city_id
    )}&key=${apiKey}`;
    try {
      const response = await fetch(apiUrl);
      const data = await response.json();

      if (data.status === "OK") {
        const location = data.results[0].geometry.location;
        const latitude = location.lat;
        const longitude = location.lng;
        setCityCoordinates({ latitude, longitude });
      } else {
        console.error(
          "Failed to retrieve coordinates for the city:",
          data.status
        );
      }
    } catch (error) {
      console.error("Error occurred while fetching data:", error);
    }
  };

  const fessformik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: Number(id),
      name: input?.name || "",
      code: input?.code || "",
      fees_id: editId ? editId : "",
      insert_by: school_user?.id,
    },
    validationSchema: Yup.object({
      // name: Yup.string().required("This is required"),
      // contact_person: Yup.string().required("This is required"),
    }),
    onSubmit: (values) => {
      if (edit) {
        dispatch(updateFee(token, values, SetInput, setEdit))
          .then(() => {
            dispatch(getFeeList(token, feeId));
          })
          .catch((error) => {
            console.error(error);
          });
      } else {
        dispatch(createFee(token, values, formClear))
          .then(() => {
            dispatch(getFeeList(token, feeId));
          })
          .catch((error) => {
            console.error(error);
          });
      }
    },
  });
  const onFeeDelete = (feesId) => {
    const did = {
      school_id: Number(id),
      fees_id: feesId,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteFee(token, did))
          .then(() => {
            dispatch(getFeeList(token, feeId));
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  const onClassDelete = (id) => {
    const did = {
      class_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteClass(token, did))
          .then(() => {
            dispatch(getClassSectionList(token, feeId));
            dispatch(getClassSection(token, s_data));
          })
          .catch((error) => {
            console.error(error);
          });
      }
    });
  };

  const handleStateChange = (option) => {
    setSelectedState(option);
    // validation.setFieldValue("state_id", option ? option.value : "");
    setManagesState(option.value);
  };

  const onFeeEdit = (data) => {
    setEdit(true);
    setEditId(data.id);
    SetInput(data);
  };

  const initialTableData = [
    {
      checked: false,
      id: "",
      rid: 1,
      section: "A",
      medium: null,
      min_strength: "",
      max_strength: "",
      active_status: true,
    },
    {
      checked: false,
      id: "",
      rid: 2,
      section: "B",
      medium: null,
      min_strength: "",
      max_strength: "",
      active_status: true,
    },
    {
      checked: false,
      id: "",
      rid: 3,
      section: "C",
      medium: null,
      min_strength: "",
      max_strength: "",
      active_status: true,
    },
    {
      checked: false,
      id: "",
      rid: 4,
      section: "D",
      medium: null,
      min_strength: "",
      max_strength: "",
      active_status: true,
    },
  ];
  const mediumOptionList = mediumList?.map((m) => ({
    value: m.value,
    label: m.value,
  }));
  const [tableData, setTableData] = useState(initialTableData);
  const [inputValue1, setInputValue1] = useState("");
  const [workingDays, setWorkingDays] = useState(0);
  const [inputValue2, setInputValue2] = useState("");
  const [class_id, setclass_id] = useState("");
  const [activeTab, setActiveTab] = useState("classes-all");

  const onClassEdit = (data) => {
    setActiveTab("classes-add");
    let { class_code, class_name, id, section_data, working_days } = data;
    setInputValue1(class_code);
    setInputValue2(class_name);
    setWorkingDays(working_days);
    setclass_id(id);
    setClassEdit(true);
    section_data.forEach((stuItem) => {
      const existingItem = initialTableData.find(
        (dataItem) => dataItem.section === stuItem.section
      );
      if (existingItem) {
        existingItem.checked = true;
        existingItem.id = stuItem.id;
        existingItem.medium = stuItem.medium;
        existingItem.min_strength = stuItem.min_strength;
        existingItem.max_strength = stuItem.max_strength;
      }
    });
    setTableData(initialTableData);
  };

  const handleInputValue1Change = (event) => {
    const inputText = event.target.value;
    setInputValue1(inputText);
  };
  const handleworkingdays = (event) => {
    const inputText = event.target.value;
    if (/^\d*$/.test(inputText)) {
      setWorkingDays(event.target.value);
    }
  };

  const handleInputValue2Change = (event) => {
    setInputValue2(event.target.value);
  };

  const handleCheckboxChange = (index) => {
    const newData = [...tableData];
    newData[index].checked = !newData[index].checked;
    setTableData(newData);
  };
  const handleStatusChange = (index) => {
    const newData = [...tableData];
    newData[index].active_status = !newData[index].active_status;
    setTableData(newData);
  };

  const handleSelectChange = (index, medium) => {
    const newData = [...tableData];
    newData[index].medium = medium;
    setTableData(newData);
  };

  const handleInputChange = (index, field, value) => {
    const newData = [...tableData];
    newData[index][field] = value;
    setTableData(newData);
  };
  let clearField = () => {
    setInputValue2("");
    setInputValue1("");
    setWorkingDays("");
    setclass_id("");
    setTableData(initialTableData);
    setClassEdit(false);
    setActiveTab("classes-all");
  };
  const handleGetSelectedData = () => {
    const updatedTableData = tableData?.filter((row) => row.checked);
    const section_data = updatedTableData?.map(
      ({ checked, class_name, class_code, fk_class_id, rid, ...rest }) => rest
    );
    const data = {
      class_id: class_id || "",
      class_code: inputValue1 || "",
      working_days: workingDays || 0,
      school_id: Number(id),
      class_name: inputValue2 || "",
      section_data: JSON.stringify(section_data),
      insert_by: school_user?.id,
    };
    if (inputValue1.trim() === "" && inputValue2.trim() === "") {
      Swal.fire({
        text: "Please enter a value for the input fields.",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }
    if (section_data?.length === 0) {
      Swal.fire({
        text: "Please Select at least one section.",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }
    const hasEmptyValues = section_data?.some((item) => {
      return (
        item.medium === null
        // ||
        // item.max_strength === "" ||
        // item.min_strength === ""
      );
    });
    if (hasEmptyValues) {
      Swal.fire({
        text: " Please fill all the required fields.",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }

    dispatch(createClass(token, data, clearField))
      .then(() => {
        dispatch(getClassSectionList(token, feeId));
        dispatch(getClassSection(token, s_data));
      })
      .catch((error) => {
        console.error(error);
      });
  };

  useEffect(() => {
    let org_Id = { organization_id: editData?.fk_organization_id };
    const fetchData = async () => {
      try {
        const response = await sessionAPI.getAllSession(org_Id);
        setSessiondata(response.data.payload);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [editData]);

  // const handleCheckboxChange1 = (index) => {
  //   const newData = [...tableData1];
  //   newData[index].checked = !newData[index].checked;
  //   setTableData1(newData);
  //   handleStatusChange1(index, newData[index].checked ? "old" : "new");
  // };
  // const handleStatusChange1 = (index, checkboxType) => {
  //   const newData = [...tableData1];
  //   if (checkboxType === "new") {
  //     newData[index].admission_type = "new";
  //   } else if (checkboxType === "old") {
  //     newData[index].admission_type = "old";
  //   }
  //   setTableData1(newData);
  // };

  let [isFeeStrDelete, setIsFeeStrDelete] = useState(true);
  const handleCheckboxChange1 = (index) => {
    const newData = [...tableData1];

    newData[index].checked = !newData[index].checked;
    setTableData1(newData);
    if (newData[index].checked) {
      handleStatusChange1(index, "both");
    } else {
      handleStatusChange1(index, "");
      handleInputChange1(index, "installments", "");
      handleInputChange1(index, "installment_amount", "");
    }
  };

  const handleStatusChange1 = (index, checkboxType) => {
    setIsFeeStrDelete(false);
    const newData = [...tableData1];
    if (checkboxType === "new") {
      newData[index].admission_type = "old";
    } else if (checkboxType === "old") {
      newData[index].admission_type = "new";
    } else if (checkboxType === "both") {
      newData[index].admission_type = "both";
    } else {
      newData[index].admission_type = "";
    }
    setTableData1(newData);
  };

  const handleInputChange1 = (index, field, value) => {
    const newData = [...tableData1];
    newData[index][field] = value;
    setTableData1(newData);
  };

  const handleGetSelectedData1 = () => {
    const dataArray = tableData1?.filter((row) => row.checked);
    const fees_data = dataArray?.map(
      ({ checked, fees_head_name, fees_head_code, ...rest }) => rest
    );
    const data = {
      id: isFeeMainId || "",
      fk_school: Number(id),
      fk_section: sel_section_id || "",
      fk_class: selectclass || "",
      fk_concession: con_id || "",
      fk_academic_session: session_id || "",
      fees_data,
    };
    if (fees_data?.length === 0) {
      Swal.fire({
        text: "Please select field.",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }

    const hasEmptyValues = fees_data?.some((item) => {
      return (
        item.installments === "" ||
        item.installment_amount === "" ||
        item.total_amount === ""
      );
    });
    if (hasEmptyValues) {
      Swal.fire({
        text: " Please fill all the required fields.",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }

    dispatch(createFeeStructure(token, JSON.stringify(data)))
      .then(() => {
        setSelectSession([]);
        setSelectconnession([]);
        setPreSelectedValue("");
        setIsFeeStrDelete(true);
        // dispatch(getFeeListStructure(token, fdata));
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const handleDeleteData1 = () => {
    const dataArray = tableData1?.filter((row) => row.checked);
    const fees_data = dataArray?.map(
      ({ checked, fees_head_name, fees_head_code, id, ...rest }) => id
    );

    const data = {
      fees_structure_ids: fees_data,
      id: isFeeMainId || "",
    };
    if (fees_data?.length === 0) {
      Swal.fire({
        text: "Please select field.",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    } else {
      Swal.fire({
        title: "Are you sure?",
        text: "You will not be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes, delete it!",
        allowOutsideClick: false,
      }).then((result) => {
        if (result.isConfirmed) {
          dispatch(deleteFeeStructure(token, JSON.stringify(data)))
            .then(() => {
              dispatch(getFeeListStructure(token, fdata));
              // setSelectSession([]);
              // setSelectconnession([]);
              // setPreSelectedValue('')
              setIsFeeStrDelete(true);
            })
            .catch((error) => {
              console.error(error);
            });
        }
      });
    }
  };

  let handleBasicInfoClear = () => {
    setEditId();
    SetInput();
    setEdit(false);
    fessformik.resetForm();
    setSelectSession("");
    setSelectSection("");
    setSelectclass("");
    setSelectconnession("");
    setPreSelectedValue("");
    dispatch(getFeeList(token, feeId));
    clearField();
    setIsFeeStrDelete(true);
  };

  let handleclearClassSection = () => {
    setEditId();
    SetInput();
    setEdit(false);
    clearField();
    setSelectSession("");
    setSelectSection("");
    setSelectclass("");
    setSelectconnession("");
    setPreSelectedValue("");
    dispatch(getFeeList(token, feeId));
    setIsFeeStrDelete(true);
  };
  let handleclearFeeHead = () => {
    clearField();
    setSelectSession("");
    setSelectSection("");
    setSelectclass("");
    setSelectconnession("");
    setPreSelectedValue("");
    dispatch(getFeeList(token, feeId));
    setIsFeeStrDelete(true);
  };
  let handleClearFeeStructure = () => {
    setEditId();
    SetInput();
    setEdit(false);
    clearField();
  };
  const [menuList, setMenuList] = useState([]);
  const [designationId, setDesignationId] = useState("");
  const handleClearUserModule = () => {
    dispatch(resetUserMenu());
    setMenuList([]);
    setDesignationId("");
  };

  let isHostel = moduleList?.payload?.find((m) => m.name === "Hostel");
  let checkHostel = checkedIds?.includes(isHostel?.id);
  let IsPayroll = moduleList?.payload?.find((m) => m.name === "Payroll");
  let checkPayroll = checkedIds?.includes(IsPayroll?.id);

  if (loading) return <Loader />;
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">School Registration</h1>
              <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item">
                  <NavLink as={NavLink} to="/admin/school-registration">
                    School List
                  </NavLink>
                </li>
                <li className="breadcrumb-item active" aria-current="page">
                  {editData?.name}
                </li>
              </ol>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Student-all"
                >
                  Update School
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="tab-pane active" id="Student-add">
              <div className="row clearfix">
                <div className="col-lg-2 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <ul className="nav nav-tabs flex-column py-2">
                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                active === "Basic-info" ? "active" : ""
                              }`}
                              data-toggle="tab"
                              href="#Basic-info"
                              onClick={handleBasicInfoClear}
                            >
                              {" "}
                              Basic Info
                            </a>
                          </li>

                          <li className="nav-item">
                            <a
                              className={`nav-link ${
                                active === "Fees-heads" ? "active" : ""
                              }`}
                              data-toggle="tab"
                              href="#Fees-heads"
                              onClick={handleclearFeeHead}
                            >
                              Fees Heads
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Classes-section"
                              onClick={handleclearClassSection}
                            >
                              {" "}
                              Classes & Section
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Fees-structure"
                              onClick={handleClearFeeStructure}
                            >
                              Fees Structure
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#UserAuth"
                              onClick={handleClearUserModule}
                            >
                              Authorization
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#MessageSetup"
                              // onClick={handleClearUserModule}
                            >
                              Message Setup
                            </a>
                          </li>
                          {checkHostel && (
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#Hostel"
                                // onClick={handleClearUserModule}
                              >
                                Hostel Registration
                              </a>
                            </li>
                          )}
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Info"
                              // onClick={handleClearUserModule}
                            >
                              Info
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#President_1"
                              // onClick={handleClearUserModule}
                            >
                              Principal's Message
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#Banner"
                              // onClick={handleClearUserModule}
                            >
                              Add Banner
                            </a>
                          </li>
                          {checkPayroll && (
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#Payroll"
                                // onClick={handleClearUserModule}
                              >
                                Payroll
                              </a>
                            </li>
                          )}
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#DeleteStudent"
                              // onClick={handleClearUserModule}
                            >
                             Delete Student
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="col-lg-10 col-md-12 col-sm-12">
                  <div className="tab-content">
                    <div
                      className={`tab-pane ${
                        active === "Basic-info" ? "active" : ""
                      }`}
                      id="Basic-info"
                    >
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                          <form
                            className="card-body"
                            noValidate
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            <div className="row">
                              <div className="col-lg-8">
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Organization
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Select
                                      name="organization_id"
                                      options={orgListOption}
                                      placeholder="Select "
                                      onChange={(option) =>
                                        validation.setFieldValue(
                                          "organization_id",
                                          option.value
                                        )
                                      }
                                      onBlur={validation.handleBlur(
                                        "organization_id"
                                      )}
                                      value={orgListOption?.find(
                                        (option) =>
                                          option.value ===
                                          validation.values.organization_id
                                      )}
                                      isSearchable={true}
                                      
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    School Name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter School Name"
                                      name="name"
                                      value={validation.values.name}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-lg-3 col-form-label"
                                    htmlFor="udisnumber"
                                  >
                                    U-DISE No
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="udisnumber"
                                      type="text"
                                      className="form-control"
                                      placeholder="Please Enter U-DISE Number"
                                      name="udise_no"
                                      value={validation.values.udise_no}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (
                                          /^\d*$/.test(inputValue) &&
                                          inputValue.length <= 12
                                        ) {
                                          validation.handleChange(e);
                                        }
                                      }}
                                      onBlur={validation.handleBlur}
                                      
                                      autoComplete="off"
                                      maxLength={11}
                                    />
                                    {validation.touched.udise_no &&
                                      validation.errors.udise_no && (
                                        <div className="error-message">
                                          {validation.errors.udise_no}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                {/* <div className="form-group row">
                                  <label
                                    className="col-lg-3 col-form-label"
                                    htmlFor="Schoolcode"
                                  >
                                   School Code
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="schoolcode"
                                      type="text"
                                      className="form-control"
                                      placeholder="Please Enter School Code"
                                      name="school_code"
                                      value={validation.values.school_code}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (
                                          /^\d*$/.test(inputValue) && 
                                          inputValue.length <= 4
                                        ) {
                                          validation.handleChange(e);
                                        }
                                      }}
                                      onBlur={validation.handleBlur}
                                      
                                      autoComplete="off"
                                      maxLength={4}
                                    />
                                    {validation.touched.school_code &&
                                      validation.errors.school_code && (
                                        <div className="error-message">
                                          {validation.errors.school_code}
                                        </div>
                                      )}
                                  </div>
                                </div> */}

                                <div className="form-group row">
                                  <label
                                    className="col-lg-3 col-form-label"
                                    htmlFor="schoolcode"
                                  >
                                    Student Initial
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="schoolcode"
                                      type="text"
                                      className="form-control"
                                      placeholder="Please Enter Student Initial"
                                      name="student_initial"
                                      value={validation.values.student_initial}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      
                                      autoComplete="off"
                                      maxLength={3}
                                      // minLength={5}
                                    />
                                    {validation.touched.student_initial &&
                                      validation.errors.student_initial && (
                                        <div className="error-message">
                                          {validation.errors.student_initial}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-lg-3 col-form-label"
                                    htmlFor="EmpolyeeInitial"
                                  >
                                    Empolyee Initial
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="EmpolyeeInitial"
                                      type="text"
                                      className="form-control"
                                      placeholder="Please Enter Empolyee Initial"
                                      name="empolyee_initial"
                                      value={validation.values.empolyee_initial}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      
                                      autoComplete="off"
                                      maxLength={3}
                                    />
                                    {validation.touched.empolyee_initial &&
                                      validation.errors.empolyee_initial && (
                                        <div className="error-message">
                                          {validation.errors.empolyee_initial}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-lg-3 col-form-label"
                                    htmlFor="short_school_name"
                                    style={{}}
                                  >
                                    Short School Name
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="short_school_name"
                                      type="text"
                                      className="form-control"
                                      placeholder="Please Enter Short School Name"
                                      name="short_school_name"
                                      value={
                                        validation.values.short_school_name
                                      }
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      
                                      autoComplete="off"
                                    />{" "}
                                    {validation.touched.short_school_name &&
                                      validation.errors.short_school_name && (
                                        <div className="error-message">
                                          {validation.errors.short_school_name}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-lg-3 col-form-label"
                                    htmlFor="msg_api_key"
                                  >
                                    Massage Api Key
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="msg_api_key"
                                      type="text"
                                      className="form-control"
                                      placeholder="Please Enter Massage Api Key "
                                      name="msg_api_key"
                                      value={validation.values.msg_api_key}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      autoComplete="off"
                                    />
                                    {validation.touched.msg_api_key &&
                                      validation.errors.msg_api_key && (
                                        <div className="error-message">
                                          {validation.errors.msg_api_key}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label
                                    className="col-lg-3 col-form-label"
                                    htmlFor="sender_id"
                                    style={{}}
                                  >
                                    Sender id
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      id="sender_id"
                                      type="text"
                                      className="form-control"
                                      placeholder="Please Enter Sender id "
                                      name="sender_id"
                                      value={validation.values.sender_id}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                    />{" "}
                                    {validation.touched.sender_id &&
                                      validation.errors.sender_id && (
                                        <div className="error-message">
                                          {validation.errors.sender_id}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Government{" "}
                                    <span className="text-danger">*</span>
                                  </label>

                                  <div className="col-lg-9 d-flex  align-items-center">
                                    <label className="custom-switch pr-2">
                                      <input
                                        type="checkbox"
                                        className="custom-switch-input"
                                        name="is_government"
                                        checked={
                                          validation.values.is_government
                                        }
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                      />
                                      <span className="custom-switch-indicator"></span>
                                    </label>
                                    {validation.values.is_government ? (
                                      <b>Yes</b>
                                    ) : (
                                      <b>No</b>
                                    )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Address
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Address"
                                      name="address"
                                      value={validation.values.address}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    State <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Select
                                      name="state_id"
                                      options={stateListOption}
                                      placeholder="Select "
                                      onChange={
                                        handleStateChange
                                        // (option) =>
                                        //   validation.setFieldValue(
                                        //     "state_id",
                                        //     option.value
                                        //   )
                                      }
                                      onBlur={validation.handleBlur("state_id")}
                                      value={stateListOption?.find(
                                        (option) =>
                                          option.value ===
                                          validation.values.state_id
                                      )}
                                      isSearchable={true}
                                      
                                    />
                                  </div>
                                </div>
                                {/* <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    City <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                  <Select
                                      name="city_id"
                                      options={cityListOption}
                                      placeholder="Select "
                                      // value={selectedOptions}
                                      // onChange={handleSelect}
                                      onChange={(option) =>{
                                        fetchCoordinates(option)
                                        validation.setFieldValue("city_id",option.value);
                                        
                                      }}
                                      onBlur={validation.handleBlur("city_id")}
                                      value={
                                        validation.values.city_id
                                          ? cityListOption?.find(
                                              (option) =>
                                                option.value ===
                                                validation.values.city_id
                                            )
                                          : null
                                      }
                                      isSearchable={true}
                                      
                                    />
                                  </div>
                                </div> */}
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    City <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <Select
                                      name="city_id"
                                      options={cityListOption}
                                      placeholder="Select"
                                      onChange={(selectedOption) => {
                                        fetchCoordinates(selectedOption);
                                      }}
                                      onBlur={validation.handleBlur("city_id")}
                                      value={
                                        validation.values.city_id
                                          ? cityListOption?.find(
                                              (option) =>
                                                option.value ===
                                                validation.values.city_id
                                            )
                                          : null
                                      }
                                      isSearchable={true}
                                      
                                    />
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Pincode{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Pincode"
                                      name="pincode"
                                      value={validation.values.pincode}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (
                                          /^\d*$/.test(inputValue) &&
                                          inputValue.length <= 6
                                        ) {
                                          validation.handleChange(e);
                                        }
                                      }}
                                      onBlur={validation.handleBlur}
                                      
                                      maxLength={6}
                                    />
                                    {validation.touched.pincode &&
                                      validation.errors.pincode && (
                                        <div className="error-message">
                                          {validation.errors.pincode}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Status{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9 d-flex  align-items-center">
                                    <label className="custom-switch pr-2">
                                      <input
                                        type="checkbox"
                                        className="custom-switch-input"
                                        name="status"
                                        checked={validation.values.status}
                                        onChange={validation.handleChange}
                                        onBlur={validation.handleBlur}
                                      />
                                      <span className="custom-switch-indicator"></span>
                                    </label>
                                    {validation.values.status ? (
                                      <b>Yes</b>
                                    ) : (
                                      <b>No</b>
                                    )}
                                  </div>
                                </div>

                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Contact Person{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Contact Person"
                                      name="contact_person"
                                      value={validation.values.contact_person}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      
                                    />
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Contact Number
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="text"
                                      className="form-control"
                                      placeholder="Enter Contact Number"
                                      name="contact_number"
                                      value={validation.values.contact_number}
                                      onChange={(e) => {
                                        const inputValue = e.target.value;
                                        if (
                                          /^\d*$/.test(inputValue) &&
                                          inputValue.length <= 10
                                        ) {
                                          validation.handleChange(e);
                                        }
                                      }}
                                      onBlur={validation.handleBlur}
                                      
                                      maxLength={10}
                                    />
                                    {validation.touched.contact_number &&
                                      validation.errors.contact_number && (
                                        <div className="error-message">
                                          {validation.errors.contact_number}
                                        </div>
                                      )}
                                  </div>
                                </div>
                                <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    Contact Email{" "}
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="email"
                                      className="form-control"
                                      placeholder="Enter Contact Email"
                                      name="contact_email"
                                      value={validation.values.contact_email}
                                      onChange={validation.handleChange}
                                      onBlur={validation.handleBlur}
                                      
                                    />
                                    {validation.touched.contact_email &&
                                    validation.errors.contact_email ? (
                                      <div className="error-message">
                                        {validation.errors.contact_email}
                                      </div>
                                    ) : null}
                                  </div>
                                </div>
                                {/* <div className="form-group row">
                                  <label className="col-lg-3 col-form-label">
                                    School Logo
                                    <span className="text-danger">*</span>
                                  </label>
                                  <div className="col-lg-9">
                                    <input
                                      type="file"
                                      name="school_logo"
                                      className="dropify"
                                      accept="image/*"
                                      onChange={handleFileRead}
                                    />
                                  </div>
                                </div> */}
                              </div>
                              <div className="col-lg-4 d-flex flex-column">
                                <ImageUpload
                                  formik={validation}
                                  fieldName="school_logo"
                                  fieldName1="is_removeSchoollogo"
                                  showImg={ShowImg}
                                  setShowImg={setShowImg}
                                />

                                <div>
                                  <h6 className="font-18 font-weight-bold mt-2 text-muted">
                                    Module Access{" "}
                                    <span className="text-danger">*</span>
                                  </h6>
                                  <ul className="setting-list list-unstyled mt-1 setting_switch">
                                    {moduleList?.payload?.map(
                                      (module, index) => (
                                        <li key={index}>
                                          <label className="custom-switch">
                                            <span
                                              className="custom-switch-description"
                                              htmlFor={`flexSwitchCheckDefault-${index}`}
                                            >
                                              {module.name}
                                            </span>
                                            <input
                                              type="checkbox"
                                              className="custom-switch-input"
                                              name="custom-switch-checkbox"
                                              id={`flexSwitchCheckDefault-${index}`}
                                              checked={checkedIds?.includes(
                                                module.id
                                              )}
                                              onChange={() =>
                                                handleSwitchChange(module.id)
                                              }
                                            />
                                            <span className="custom-switch-indicator"></span>
                                          </label>
                                        </li>
                                      )
                                    )}
                                  </ul>
                                </div>
                              </div>
                            </div>
                            <div className="col-sm-12 justify-content-center align-item-center mb-3">
                              <button
                                type="submit"
                                className="mr-1 btn btn-primary px-5"
                              >
                                Update
                              </button>
                              {/* <button
                                   type="cancel"
                                    className="btn btn-outline-secondary px-5"
                                  >
                                    Cancel
                                  </button> */}
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>

                    <div
                      className={`tab-pane ${
                        active === "Fees-heads" ? "active" : ""
                      }`}
                      id="Fees-heads"
                    >
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <div className="card">
                          <form
                            className="card-body"
                            onSubmit={(e) => {
                              e.preventDefault();
                              fessformik.handleSubmit();
                              return false;
                            }}
                          >
                            <div className="form-group row">
                              <label className="col-lg-2 col-md-2 col-form-label mr-0">
                                Fee Head Code
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-lg-3 col-md-3">
                                <input
                                  name="code"
                                  type="text"
                                  className="form-control"
                                  placeholder="Enter Fees Head code"
                                  value={fessformik.values.code}
                                  onChange={(event) => {
                                    const inputText = event.target.value;
                                    if (/^\d*$/.test(inputText)) {
                                      fessformik.handleChange(event);
                                    }
                                  }}
                                  onBlur={fessformik.handleBlur}
                                  
                                  maxLength={5}
                                  autoComplete="off"
                                />
                              </div>

                              <label className="col-lg-2 col-md-1 col-form-label mr-0 ">
                                Fee Head Name{" "}
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-lg-3 col-md-3 ">
                                <input
                                  type="text"
                                  className="form-control"
                                  name="name"
                                  placeholder="Enter Fees Head Name"
                                  value={fessformik.values.name}
                                  onChange={fessformik.handleChange}
                                  onBlur={fessformik.handleBlur}
                                  
                                  autoComplete="off"
                                />
                              </div>
                              <div className="col-md-2 col-lg-2">
                                <button
                                  type="submit"
                                  className="btn btn-primary"
                                >
                                  {" "}
                                  <i className="fa fa-plus px-1"></i>
                                  {edit ? "UPDATE" : "ADD"}
                                </button>
                              </div>
                            </div>
                          </form>
                        </div>
                      </div>
                      <div className="card">
                        <div className="card-body">
                          <div className="table-responsive">
                            <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
                              <thead>
                                <tr>
                                  <th>Sr.No.</th>
                                  <th>Fee Head Name</th>
                                  <th>Fee Head Code</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {feeslist?.payload?.map((fee, index) => (
                                  <tr key={index}>
                                    <td>{index + 1}</td>
                                    <td>{fee.name}</td>
                                    <td>{fee.code}</td>
                                    <td className="">
                                      <button
                                        type="button"
                                        className="btn btn-icon text-muted btn-sm"
                                        title="Edit"
                                        onClick={() => onFeeEdit(fee)}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </button>
                                      <button
                                        type="button"
                                        className="btn btn-icon btn-sm"
                                        title="Delete"
                                        onClick={() => onFeeDelete(fee.id)}
                                      >
                                        <i className="fa fa-trash-o text-danger"></i>
                                      </button>
                                      {/* <NavLink
                                        to="#"
                                        className="card-options-remove mr-3"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Delete"
                                        onClick={() => onFeeDelete(fee.id)}
                                      >
                                        <i className="fa fa-trash-o text-danger"></i>
                                      </NavLink>
                                      <NavLink
                                        className="text-muted"
                                        data-toggle="tooltip"
                                        data-placement="top"
                                        title="Edit"
                                        to=""
                                        onClick={() => onFeeEdit(fee)}
                                      >
                                        <i className="fa fa-edit"></i>
                                      </NavLink> */}
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="Classes-section">
                      <ul className="nav nav-tabs ml-3">
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === "classes-all" ? "active" : ""
                            }`}
                            data-toggle="tab"
                            href="#classes-all"
                            onClick={handleclearClassSection}
                          >
                            Classes & Section List
                          </a>
                        </li>
                        <li className="nav-item">
                          <a
                            className={`nav-link ${
                              activeTab === "classes-add" ? "active" : ""
                            }`}
                            data-toggle="tab"
                            href="#classes-add"
                          >
                            Add Classes & Section
                          </a>
                        </li>
                      </ul>

                      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                        <div className="tab-content">
                          <div
                            className={`tab-pane ${
                              activeTab === "classes-all" ? "active" : ""
                            }`}
                            id="classes-all"
                          >
                            <div className="card">
                              <div className="card-body">
                                <div className="table-responsive card">
                                  <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
                                    <thead>
                                      <tr>
                                        <th>Sr.No.</th>
                                        <th>Class</th>
                                        <th>Class Code</th>
                                        <th>Section</th>
                                        <th>Action</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {classlist?.payload?.map(
                                        (section, index) => (
                                          <tr key={index}>
                                            <td>{index + 1}</td>
                                            <td>{section.class_name}</td>
                                            <td>{section.class_code}</td>
                                            <td>
                                              {section.section_data.map(
                                                (se) => (
                                                  <span className="px-1">
                                                    {se.section}
                                                  </span>
                                                )
                                              )}
                                            </td>

                                            <td className="">
                                              <button
                                                type="button"
                                                className="btn btn-icon text-muted btn-sm"
                                                title="Edit"
                                                onClick={() =>
                                                  onClassEdit(section)
                                                }
                                              >
                                                <i className="fa fa-edit"></i>
                                              </button>
                                              <button
                                                type="button"
                                                className="btn btn-icon btn-sm"
                                                title="Delete"
                                                onClick={() =>
                                                  onClassDelete(section.id)
                                                }
                                              >
                                                <i className="fa fa-trash-o text-danger"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        )
                                      )}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`tab-pane ${
                              activeTab === "classes-add" ? "active" : ""
                            }`}
                            id="classes-add"
                          >
                            <div className="row clearfix">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="card">
                                  <form
                                    className="card-body"
                                    onSubmit={(e) => {
                                      e.preventDefault();
                                      handleGetSelectedData();
                                    }}
                                  >
                                    <div className="form-group row">
                                      <div className="col-lg-4 col-md-6">
                                        <label className="col-form-label">
                                          Class Code{" "}
                                          <span className="text-danger">*</span>
                                        </label>

                                        <input
                                          type="text"
                                          placeholder="Enter Class Code"
                                          className="form-control"
                                          name="class_code"
                                          value={inputValue1}
                                          onChange={handleInputValue1Change}
                                          
                                          maxLength={5}
                                        />
                                      </div>
                                      <div className="col-lg-4 col-md-6">
                                        <label className="col-form-label">
                                          Class Name{" "}
                                          <span className="text-danger">*</span>
                                        </label>

                                        <input
                                          type="text"
                                          placeholder="Enter Class Name"
                                          className="form-control"
                                          name="class_name"
                                          value={inputValue2}
                                          onChange={handleInputValue2Change}
                                          
                                        />
                                      </div>
                                      <div className="col-lg-4 col-md-6 d-none">
                                        <label className="col-form-label">
                                          Working Days{" "}
                                          <span className="text-danger">*</span>
                                        </label>

                                        <input
                                          type="text"
                                          placeholder="Enter Working Days"
                                          className="form-control"
                                          name="working_days"
                                          value={workingDays}
                                          onChange={handleworkingdays}
                                          maxLength={5}
                                        />
                                      </div>
                                    </div>
                                    <div className="d-flex justify-content-end">
                                      <button
                                        type="submit"
                                        className="btn btn-primary btn-lg"
                                      >
                                        {" "}
                                        <i className="fa fa-plus px-1"></i>
                                        {classedit ? "UPDATE" : "ADD"}
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>

                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="card ">
                                  <div className="card-body">
                                    <div className="info">
                                      <p className="p-2">
                                        <strong>Note :-</strong> You have to
                                        select at least one section
                                      </p>
                                    </div>
                                    <div className="row clearfix card">
                                      <div className="table-responsive">
                                        <table className="table table-hover table-vcenter table-striped table-sm  text-nowrap">
                                          <thead>
                                            <tr className="text-center">
                                              <th></th>
                                              <th>Sr.No.</th>
                                              <th>Section</th>
                                              <th>
                                                Medium
                                                <span className="text-danger">
                                                  *
                                                </span>
                                              </th>
                                              <th>Min Strength</th>
                                              <th>Max Strength</th>
                                            </tr>
                                          </thead>
                                          <tbody>
                                            {tableData.map((row, index) => (
                                              <tr key={row.rid}>
                                                <td>
                                                  <input
                                                    type="checkbox"
                                                    checked={row.checked}
                                                    onChange={() =>
                                                      handleCheckboxChange(
                                                        index
                                                      )
                                                    }
                                                  />
                                                </td>
                                                <td>{index + 1}</td>
                                                <td>
                                                  <span className="font-16">
                                                    {row.section}
                                                  </span>
                                                </td>
                                                <td className="">
                                                  <Select
                                                    options={mediumOptionList}
                                                    placeholder="Select Medium"
                                                    value={
                                                      row.medium
                                                        ? mediumOptionList.find(
                                                            (option) =>
                                                              option.label ===
                                                              row.medium
                                                          )
                                                        : null
                                                    }
                                                    onChange={(
                                                      selectedOption
                                                    ) =>
                                                      handleSelectChange(
                                                        index,
                                                        selectedOption.label
                                                      )
                                                    }
                                                    isSearchable={true}
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.min_strength}
                                                    onChange={(event) =>
                                                      handleInputChange(
                                                        index,
                                                        "min_strength",
                                                        event.target.value
                                                      )
                                                    }
                                                    maxLength={5}
                                                  />
                                                </td>
                                                <td>
                                                  <input
                                                    type="text"
                                                    className="form-control"
                                                    value={row.max_strength}
                                                    onChange={(event) =>
                                                      handleInputChange(
                                                        index,
                                                        "max_strength",
                                                        event.target.value
                                                      )
                                                    }
                                                    maxLength={5}
                                                  />
                                                </td>
                                              </tr>
                                            ))}
                                          </tbody>
                                        </table>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="Fees-structure">
                      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                        <div className="tab-content">
                          <div
                            className="tab-pane active"
                            id="Fees-structure-all"
                          >
                            <div className="row row-deck">
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="card">
                                  <form className="card-body">
                                    <div className="form-group row">
                                      <div className="col-md-4">
                                        <label className="col-form-label">
                                          Session :
                                          <span className="text-danger"></span>
                                        </label>
                                        <div className="">
                                          <Select
                                            options={sessiondataList}
                                            placeholder="Select Session"
                                            value={selectSession}
                                            onChange={handleSelectSession}
                                            isSearchable={true}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        {" "}
                                        <label className="col-form-label">
                                          Class :
                                          <span className="text-danger"></span>
                                        </label>
                                        <div className="">
                                          <Select
                                            options={classSectionList}
                                            placeholder="Select Class"
                                            value={
                                              preSelectedValue
                                                ? classSectionList?.find(
                                                    (option) =>
                                                      option.value ===
                                                      preSelectedValue
                                                  )
                                                : null
                                            }
                                            onChange={handleSelectclassSection}
                                            isSearchable={true}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-4">
                                        <label className="col-form-label ">
                                          Concession :{" "}
                                          <span className="text-danger"></span>
                                        </label>
                                        <div>
                                          <Select
                                            options={connessionList}
                                            placeholder="Select Concession"
                                            value={selectconnession}
                                            onChange={handleSelectconnession}
                                            isSearchable={true}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </form>
                                </div>
                              </div>
                              <div className="col-lg-12 col-md-12 col-sm-12">
                                <div className="card">
                                  <div className="card-body">
                                    <div className="info">
                                      <p className="p-2">
                                        <strong>Note :-</strong> You have to
                                        select at least one field
                                      </p>
                                    </div>
                                    <div className="table-responsive card">
                                      <table className="table table-hover table-vcenter table-striped table-sm text-nowrap">
                                        <thead>
                                          <tr className="text-center">
                                            <th></th>
                                            <th>Sr.No.</th>
                                            <th>Fee Type</th>
                                            <th>
                                              No. of <br />
                                              Installments
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </th>
                                            <th>
                                              Amount{" "}
                                              <span className="text-danger">
                                                *
                                              </span>
                                            </th>
                                            <th>
                                              Total Amount <br />
                                              (Annually)
                                            </th>
                                            {/* <tr>
                                              <th colspan="2">
                                                Admission Type
                                                <th>Old</th>
                                              <th>New</th>
                                              </th>
                                            </tr> */}

                                            <th>
                                              {" "}
                                              Admission <br /> Old
                                            </th>
                                            <th>
                                              {" "}
                                              Admission <br />
                                              New
                                            </th>
                                          </tr>
                                        </thead>
                                        <tbody>
                                          {tableData1?.map((row, index) => (
                                            <tr key={index} className="">
                                              <td className="text-center">
                                                <input
                                                  type="checkbox"
                                                  checked={row.checked}
                                                  onChange={() =>
                                                    handleCheckboxChange1(index)
                                                  }
                                                />
                                              </td>
                                              <td className="text-center">
                                                {index + 1}
                                              </td>
                                              <td>
                                                <span className="font-16">
                                                  {row.fees_head_name}
                                                </span>
                                              </td>

                                              <td className="text-right">
                                                <input
                                                  type="number"
                                                  className="form-control text-right"
                                                  value={row.installments}
                                                  onChange={(event) =>
                                                    handleInputChange1(
                                                      index,
                                                      "installments",
                                                      event.target.value
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td className="">
                                                <input
                                                  type="number"
                                                  className="form-control text-right"
                                                  value={row.installment_amount}
                                                  onChange={(event) =>
                                                    handleInputChange1(
                                                      index,
                                                      "installment_amount",
                                                      event.target.value
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td>
                                                <input
                                                  type="number"
                                                  className="form-control text-right"
                                                  value={
                                                    (row.total_amount =
                                                      row.installments *
                                                      row.installment_amount)
                                                  }
                                                  onChange={(event) =>
                                                    handleInputChange1(
                                                      index,
                                                      "total_amount",
                                                      event.target.value
                                                    )
                                                  }
                                                  readOnly
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    row.admission_type ===
                                                      "old" ||
                                                    row.admission_type ===
                                                      "both"
                                                  }
                                                  onChange={() =>
                                                    handleStatusChange1(
                                                      index,
                                                      "old"
                                                    )
                                                  }
                                                />
                                              </td>
                                              <td className="text-center">
                                                <input
                                                  type="checkbox"
                                                  checked={
                                                    row.admission_type ===
                                                      "new" ||
                                                    row.admission_type ===
                                                      "both"
                                                  }
                                                  onChange={() =>
                                                    handleStatusChange1(
                                                      index,
                                                      "new"
                                                    )
                                                  }
                                                />
                                              </td>
                                            </tr>
                                          ))}
                                        </tbody>
                                      </table>
                                    </div>
                                    <div className="col-sm-12 pt-2 justify-content-center align-item-center">
                                      <button
                                        type="submit"
                                        onClick={handleGetSelectedData1}
                                        className="btn btn-primary btn-sm mr-2"
                                      >
                                        <i className="fa fa-plus px-1"></i>
                                        Save
                                      </button>
                                      <button
                                        type="submit"
                                        onClick={handleBasicInfoClear}
                                        className="btn btn-outline-danger btn-sm mr-2 "
                                      >
                                        {" "}
                                        <i className="fa fa-times px-1"></i>
                                        Clear
                                      </button>
                                      {/* <button
                                        type="submit"
                                        className="btn btn-outline-danger btn-sm"
                                        onClick={handleDeleteData1}
                                        disabled={isFeeStrDelete}
                                      >
                                        Delete
                                      </button> */}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="tab-pane" id="UserAuth">
                      <SuspenseWrapper>
                        <UserAuth
                          designationId={designationId}
                          setDesignationId={setDesignationId}
                          schoolId={Number(id)}
                          insertBy={school_user?.id}
                          menuList={menuList}
                          setMenuList={setMenuList}
                        />
                      </SuspenseWrapper>
                    </div>
                    <div className="tab-pane" id="MessageSetup">
                      <SuspenseWrapper>
                        <SMSNotification
                          school_id={Number(id)}
                          school_emp_id={school_user?.id}
                          designation_id={designationId}
                        />
                      </SuspenseWrapper>
                    </div>
                    <div className="tab-pane" id="Hostel">
                      <SuspenseWrapper>
                        <HostelRegistration
                          token={token}
                          schoolId={Number(id)}
                          school_emp_id={school_user?.id}
                        />{" "}
                      </SuspenseWrapper>
                    </div>
                    <div className="tab-pane" id="Info">
                      <SuspenseWrapper>
                        <SchoolStudentInfo
                          token={token}
                          schoolId={Number(id)}
                          organization_id={editData?.fk_organization_id}
                        />{" "}
                      </SuspenseWrapper>
                    </div>
                    <div className="tab-pane" id="President_1">
                      <SuspenseWrapper>
                        <PresiedentMessage
                          token={token}
                          schoolId={Number(id)}
                        />
                      </SuspenseWrapper>
                    </div>
                    <div className="tab-pane" id="Banner">
                      <SuspenseWrapper>
                        <Banner token={token} schoolId={Number(id)} />
                      </SuspenseWrapper>
                    </div>
                    <div className="tab-pane" id="Payroll">
                      <SuspenseWrapper>
                        <Payroll token={token} schoolId={Number(id)} />
                      </SuspenseWrapper>
                    </div>
                    <div className="tab-pane" id="DeleteStudent">
                      <SuspenseWrapper>
                        <DeleteStudent token={token} schoolId={Number(id)} />
                      </SuspenseWrapper>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSchoolReg;
