import React, { useState, useEffect } from "react";
import {
  NavLink,
  useNavigate,
  useParams,
  useHistory,
  useLocation,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useFormik } from "formik";
import Select from "react-select";
import Swal from "sweetalert2";
import { sessionAPI } from "../../../api/sessionAPI";
import { useAlert } from "react-alert";
import Loader from "../../Loader/Loader";
import * as Yup from "yup";
import { getSchoolOrganization } from "../../../actions/super-user/SchoolRegistration";

const EditSession = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let super_admin_id = school_user?.id
  const location = useLocation();
  const id = location.state;
  useEffect(()=>{
    if(!id){
      navigate("/admin/admin-dashboard")
    }
  },[id])
  const [loader, setLoader] = useState(false);
  const { organizationdata } = useSelector((state) => state.schoolRegister);
  const [editedData, setEditedData] = useState({
    name: "",
    start_date: "",
    end_date: "",
    current_session: "",
    organization_id: "",
    super_admin_id
  });
  const { session, error } = useSelector((state) => state);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const alert = useAlert();
  const orgListOption = organizationdata?.payload?.map((org) => {
    return {
      value: org.id,
      label: org.name,
    };
  });

  useEffect(() => {
    if (token) {
      dispatch(getSchoolOrganization(token));
    }
  }, [dispatch, token]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await sessionAPI.getAllSession();
        const selectedUser = response?.data?.payload?.find(
          (user) => user.id === Number(id)
        );
        setEditedData(selectedUser);
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [id]);

  const validationSchema = Yup.object().shape({
    // name: Yup.string().required('Name is required'),
    // start_date: Yup.string().required('Start date is required'),
    // end_date: Yup.string().required('End date is required'),
    // current_session: Yup.boolean(),
    // session_id: Yup.string().required('Designation is required'),
  });

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      name: editedData.name || "",
      start_date: editedData.start_date || "",
      end_date: editedData.end_date || "",
      current_session: editedData.current_session,
      organization_id: editedData.fk_organization_id || "",
    },
    validationSchema,
    onSubmit: async (values) => {
      try {
        const editData = {
          ...values,
          session_id: id,
          super_admin_id
        };
        if (editedData.start_date > editedData.end_date) {
          Swal.fire({
            title: "",
            text: "The 'To' date cannot be before the 'From' date. ",
            icon: "info",
            allowOutsideClick: false,
          });
          return;
        }

        if (editData.current_session) {
          const confirmed = await Swal.fire({
            title: "Set Current Session",
            text: "You want this Session as Current Session?",
            icon: "question",
            showCancelButton: true,
            cancelButtonColor: "#f46a6a",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            allowOutsideClick: false,
          });

          if (confirmed.isConfirmed) {
            editData.current_session = true;
          } else {
            editData.current_session = false;
          }
        }

        const res = await sessionAPI.updateSession(editData);
        if (res.data.status === 200) {
          Swal.fire({
            text: res.data.msg,
            icon: "success",
            imageAlt: "success image",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              setEditedData({});
              navigate("/admin/session-management");
            }
          });
        }
        if (res.data.status === 403) {
          Swal.fire({
            text: res.data.msg,
            icon: "error",
            imageAlt: "success image",
            allowOutsideClick: false,
          });
        }
      } catch (err) {
        console.log(err);
      }
    },
  });

  const hanldeDropdown = (selectedOptions) => {
    const selectedSchoolId = selectedOptions.value;
    setEditedData({ ...editedData, organization_id: selectedSchoolId });
    formik.setFieldValue("organization_id", selectedOptions.value);
  };

  if (loader || !editedData) {
    return <Loader />;
  }

  const handleChecked = (e) => {
    const isSession = e.target.checked;
    // const isAdmin =  e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setEditedData({ ...editedData, current_session: isSession });
    formik.setFieldValue("current_session", e.target.checked);
    if (!isSession) {
      formik.setFieldValue("current_session", false);
    }
  };

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Session</h1>
              {/* <ol className="breadcrumb page-breadcrumb">
                                <li className="breadcrumb-item"><a href="admin/session-management">Session List</a></li>
                                <li className="breadcrumb-item active" aria-current="page">Session View</li>
                            </ol> */}
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <NavLink
                  className="nav-link"
                  data-toggle="tab"
                  // href="#staff-master"
                  as={NavLink}
                  to="/admin/session-management"
                >
                  Session List
                </NavLink>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Staff-edit"
                >
                  Edit Session
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-7 col-md-12 col-sm-12">
              <div className="card">
                <form className="card-body" onSubmit={formik.handleSubmit}>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Organization
                      <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-6">
                      <Select
                        name="organization_id"
                        options={orgListOption}
                        value={orgListOption?.find(
                          (opt) => opt.value === formik.values.organization_id
                        )}
                        placeholder="Select "
                        onChange={hanldeDropdown}
                        isSearchable={true}
                        required
                      />
                    </div>
                  </div>
                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Session Name <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-6">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Enter Session name"
                        name="name"
                        value={editedData.name}
                        onChange={(e) => {
                          const firstName = e.target.value;
                          setEditedData({ ...editedData, name: firstName });
                          formik.setFieldValue("name", firstName);
                        }}
                      />
                      {formik.touched.name && formik.errors.name && (
                        <div className="text-danger">{formik.errors.name}</div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      Start Date <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-6">
                      <input
                        type="date"
                        className="form-control"
                        name="start_date"
                        value={editedData.start_date}
                        onChange={(e) => {
                          const firstName = e.target.value;
                          setEditedData({
                            ...editedData,
                            start_date: firstName,
                          });
                          formik.setFieldValue("start_date", firstName);
                        }}
                      />
                      {formik.touched.start_date &&
                        formik.errors.start_date && (
                          <div className="text-danger">
                            {formik.errors.start_date}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label">
                      End Date <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-6">
                      <input
                        type="date"
                        className="form-control"
                        name="end_date"
                        value={editedData.end_date}
                        onChange={(e) => {
                          const firstName = e.target.value;
                          setEditedData({ ...editedData, end_date: firstName });
                          formik.setFieldValue("end_date", firstName);
                        }}
                        // required
                      />
                      {formik.touched.end_date && formik.errors.end_date && (
                        <div className="text-danger">
                          {formik.errors.end_date}
                        </div>
                      )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label
                      htmlFor="current_session"
                      className="col-md-3 col-form-label"
                    >
                      Current Session <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-7 ml-1">
                      <label className="custom-switch">
                        <input
                          type="checkbox"
                          className="custom-switch-input"
                          name="current_session"
                          checked={editedData.current_session}
                          onChange={handleChecked}
                          // required
                        />
                        <span className="custom-switch-indicator"></span>
                      </label>
                      {formik.touched.current_session &&
                        formik.errors.current_session && (
                          <div className="text-danger">
                            {formik.errors.current_session}
                          </div>
                        )}
                    </div>
                  </div>

                  <div className="form-group row">
                    <label className="col-md-3 col-form-label"></label>
                    <div className="col-md-7">
                      <button type="submit" className="btn btn-primary mr-3">
                        Update
                      </button>
                      {/* <button
                        type="submit"
                        className="btn btn-outline-danger"
                        data-toggle="tab"
                        href="#Fees-all"
                        onClick={() => onDelete(id)}
                      >
                        Delete
                      </button> */}
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSession;
