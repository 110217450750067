import React, { useState, useEffect } from "react";
import { Heading } from "../../CommonComponent/heading";
// import MonthlyAttendenceModle from "./MonthlyAttendenceModle/MonthlyAttendenceModle";
import Swal from "sweetalert2";
import { MonthlyAttendenceAPI } from "../../../api/Payroll/MonthlyAttendence";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import Select from "react-select";
import CheckButton from "../Hostel/RoomAllotment/CheckButton";
import { useFormik } from "formik";
import Switch from "react-switch";
import "./Switch.css";
import * as Yup from "yup";
import useLoading from "../../CommonComponent/useLoading";
import useLocalStorageData from "../Comman/LocalStorageData";
import Loader from "../../Loader/Loader";

const MonthlyAttendance = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [Date1, setDate1] = useState(new Date().toISOString().slice(0, 7));
  const [EmployeeDetails, setEmployeeDetails] = useState([]);
  const [monthDays, setMonthDays] = useState([]);
  const [employeeObject, setEmployeeObject] = useState({});
  const [remLeave, setRemLeave] = useState(0);
  const [options, setOptions] = useState([]);
  const [weekoff, setWeekoff] = useState([]);
  const [holiday, setHoliday] = useState([]);
  const [selectedEmpId, setSelectedEmpId] = useState("");
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [firstDay, setFirstDay] = useState("");
  const [isGenerated, setIsGenerated] = useState(true);

  const [lastDay, setLastDay] = useState("");
  const [leaveData, setLeaveData] = useState([]);
  const getTotalDaysInMonth = (year, month) => {
    const totalDays = new Date(year, month, 0).getDate();
    return totalDays;
  };

  //

  const getEmployList = async () => {
    if (!Date1) {
      Swal.fire({
        text: "Please select month and year",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }
    try {
      let data = {
        school_id: school_id,
        attendance_month: Date1,
      };

      startLoading();
      const response = await MonthlyAttendenceAPI.getMonthlyAttendance(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        setEmployeeObject(responseData);
        setEmployeeDetails(responseData?.result);

        const options = responseData.leave_type_arr.map((item) => {
          const label = item.leave_type;
          const value = item.id;
          return { label: label, value: value };
        });
        setOptions(options);

        setMonthDays(responseData?.total_days);
        setIsGenerated(responseData?.is_salary_generated);
        setWeekoff(responseData?.weekoff_arr);

        setHoliday(responseData?.holiday_arr);

        let date = new Date(Date1);

        const year = date.getFullYear();
        const month = date.getMonth();

        const firstDay = new Date(year, month, 1).toLocaleDateString("en-CA", {
          year: "numeric",
          month: "2-digit",
          day: "2-digit",
        });
        // console.log(firstDay, "firstDay ::::::::::");
        const lastDay = new Date(year, month + 1, 0).toLocaleDateString(
          "en-CA",
          {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
          }
        );

        setFirstDay(firstDay);
        setLastDay(lastDay);
      }
    } catch (error) {
      console.log(error);
    } finally {
      stopLoading();
    }
  };

  let totalDays = monthDays;
  // Create an array of days
  const days = Array.from({ length: monthDays }, (_, i) => i + 1);

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////
  const [modal, setModal] = useState(false);
  const [isToggled, setIsToggled] = useState(false);
  const [isHalfDay, setIsHalfDay] = useState(false);
  const [leaveDays, setLeaveDays] = useState(0);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedOption, setSelectedOption] = useState("");
  const [approve, setApprove] = useState(false);
  // const [options,setOptions] = useState([])
  const handelHalfDay = () => {
    setIsHalfDay(!isHalfDay);
    setLeaveDays(0.5);
    formik.setFieldValue("to_date", fromDate);
  };

  const handleToggle = () => {
    setIsToggled(!isToggled);
  };
  const [isLeft, setIsLeft] = useState(true);

  const handleLeftClick = () => {
    setIsLeft(true);
  };

  const handleRightClick = () => {
    setIsLeft(false);
  };
  const handleFromDateChange = (event) => {
    setFromDate(event.target.value);
    formik.setFieldValue("from_date", event.target.value);
    if (isHalfDay) {
      formik.setFieldValue("to_date", event.target.value);
    }
  };
  const handleToDateChange = (e) => {
    const { value } = e.target;

    const fromDateValue = new Date(formik.values.from_date);
    const toDateValue = new Date(value);

    // Check if the selected "To Date" is before the "From Date"
    if (toDateValue < fromDateValue) {
      formik.setFieldError("to_date", "To Date must be after From Date");
    } else {
      // Clear the error if the validation passes
      formik.setFieldError("to_date", "");
      formik.setFieldValue("to_date", value);
      setToDate(value);
    }
  };

  let handelLeaveChange = (select) => {
    setSelectedOption(select.value);
    const selectedLeaveType = select.label;
    const remainingLeave = selectedEmpId?.mem?.[selectedLeaveType];
    setRemLeave(remainingLeave || 0);
    formik.setFieldValue("leave_type_id", select.value);
  };
  // console.log(selectedEmpId ,"::::::remLeave:::::::")

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: school_id,
      staff_id: selectedEmpId?.fk_staff_id,
      leave_type_id: "",
      from_date: "",
      to_date: "",
      attendance_month: Date1,
      is_approved: "True",
      number_of_leaves: "",
      reason: "",
    },
    validationSchema: Yup.object().shape({
      leave_type_id: Yup.string().required("Leave type is required"),
      from_date: Yup.string().required("From date is required"),
      to_date: isHalfDay
        ? Yup.string().nullable(true)
        : Yup.string().required("To date is required"),
      reason: Yup.string().required("Reason is required"),
    }),
    onSubmit: async (values) => {
      let isFirst = "";
      if (isHalfDay) {
        isFirst = isLeft ? "First" : "Second";
        setLeaveDays(0.5);
      } else {
        isFirst = "";
      }

      values = {
        ...values,
        leave_balance: remLeave,
        half_1or2: isFirst,
        half_full_day: isHalfDay ? "Half" : "Full",
        number_of_leaves: leaveDays,
      };

      try {
        startLoading();
        const response = await MonthlyAttendenceAPI.saveLeaveForStaff(values);
        const responseData = response.data;
        if (responseData.status === 200) {
          stopLoading();
          Swal.fire({
            text: responseData.msg,
            icon: "success",
          }).then(() => {
            handelClear();
            getEmployList();
          });
        } else {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        stopLoading();
      }
    },
  });

  const handelClear = () => {
    formik.resetForm();
    setLeaveDays(0);
    setSelectedOption("");
    setModal(!modal);
    setApprove(false);
    setIsHalfDay(false);
    setRemLeave(0);
  };

  // const calculateLeaveDays = (
  //   fromDate,
  //   toDate,
  //   excludeDays = [],
  //   holidays = []
  // ) => {
  //   const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
  //   const start = new Date(fromDate);
  //   const end = new Date(toDate);

  //   // Calculate the number of days between start and end
  //   const totalDays = Math.round(Math.abs((start - end) / oneDay)) + 1;

  //   // Convert holidays to Date objects for comparison
  //   const holidayDates = holidays.map((holiday) =>
  //     new Date(holiday).setHours(0, 0, 0, 0)
  //   );

  //   // Count the number of excluded days and holidays within the leave period
  //   let leaveDays = totalDays;
  //   for (
  //     let date = new Date(start);
  //     date <= end;
  //     date.setDate(date.getDate() + 1)
  //   ) {
  //     const day = date.getDay();
  //     const currentDate = date.setHours(0, 0, 0, 0); // Set time to 00:00:00 for accurate comparison
  //     if (excludeDays.includes(day) || holidayDates.includes(currentDate)) {
  //       leaveDays--;
  //     }
  //   }

  //   setLeaveDays(leaveDays);
  //   formik.setFieldValue("number_of_leaves", leaveDays);
  // };
  const calculateLeaveDays = (
    fromDate,
    toDate,
    excludeDays = [],
    holidays = []
  ) => {
    const oneDay = 24 * 60 * 60 * 1000; // milliseconds in a day
    const start = new Date(fromDate);

    const end = new Date(toDate);

    // Ensure end date is after start date
    if (end < start) {
      setLeaveDays(0);
      formik.setFieldValue("number_of_leaves", 0);
      return;
    }

    // Calculate the number of days between start and end
    const totalDays = Math.round(Math.abs((end - start) / oneDay)) + 1;

    // Convert holidays to Date objects for comparison
    const holidayDates = holidays.map((holiday) =>
      new Date(holiday).setHours(0, 0, 0, 0)
    );

    // Count the number of excluded days and holidays within the leave period
    let leaveDays = 0;
    for (
      let date = new Date(start);
      date <= end;
      date.setDate(date.getDate() + 1)
    ) {
      const day = date.getDay();
      const currentDate = date.setHours(0, 0, 0, 0); // Set time to 00:00:00 for accurate comparison
      if (!excludeDays.includes(day) && !holidayDates.includes(currentDate)) {
        leaveDays++;
      }
    }

    setLeaveDays(leaveDays);
    formik.setFieldValue("number_of_leaves", leaveDays);
  };

  const dayNameToNumber = {
    Sunday: 0,
    Monday: 1,
    Tuesday: 2,
    Wednesday: 3,
    Thursday: 4,
    Friday: 5,
    Saturday: 6,
  };

  useEffect(() => {
    if (fromDate && toDate) {
      const excludeDays = weekoff.map((day) => dayNameToNumber[day]);

      const holidays = holiday;
      calculateLeaveDays(fromDate, toDate, excludeDays, holidays);
    }
  }, [fromDate, toDate, weekoff]);

  const toggle = () => setModal(!modal);

  const ManageData = (id) => {
    getLeaveData();
  };

  const handelDateChange = (e) => {
    if (isHalfDay) {
      formik.setFieldValue("to_date", fromDate);
    }
    setDate1(e.target.value);
  };

  const getLeaveData = async (id) => {
    const SelectedEmploye = EmployeeDetails?.find(
      (emp) => emp.fk_staff_id === id
    );
    setSelectedEmpId(SelectedEmploye);
    let data = {
      school_id: school_id,

      staff_id: id,
      attendance_month: Date1,
    };
    try {
      startLoading();
      const response = await MonthlyAttendenceAPI.getLeaveList(data);
      const responseData = response.data;

      if (responseData.status === 200) {
        setLeaveData(responseData.leave_arr);
        setModal(!modal);
      } else {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      stopLoading();
    }
  };

  const handleDelete = (leaveId, EmpId) => {
    Swal.fire({
      title: "Are you sure?",
      text: "Want to delete this record?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        let data = {
          leave_id: leaveId,
        };
        MonthlyAttendenceAPI.deleteLeaveEntry(data)
          .then((response) => {
            const responseData = response.data;
            if (responseData.status === 200) {
              Swal.fire({
                text: responseData.msg,
                icon: "success",
              });
              getLeaveData(EmpId);
              getEmployList();
            } else {
              Swal.fire({
                text: responseData.msg,
                icon: "error",
              });
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    });
  };

  const calculatePresentAndAbsentCounts = (attendance, monthDays) => {
    let presentCount = monthDays;
    let naCount = 0;

    attendance.forEach((record) => {
      if (record.status === "A") {
        presentCount -= 1;
      } else if (record.status === "AFH" || record.status === "ASH") {
        presentCount -= 0.5;
      } else if (record.status === "NA") {
        presentCount -= 1;
        naCount += 1; // Track the number of "NA" records
      }
    });

    const absentCount = Number(monthDays - presentCount - naCount); // Subtract naCount from the absent calculation
    return { presentCount, absentCount, naCount };
  };
  // Update counts in real-time
  useEffect(() => {
    setEmployeeDetails((prevData) =>
      prevData.map((employee) => {
        const { presentCount, absentCount } = calculatePresentAndAbsentCounts(
          employee.attendance[0],
          monthDays
        );
        return {
          ...employee,
          mem: {
            ...employee.mem,
            present: presentCount,
            absent: absentCount,
          },
        };
      })
    );
  }, []);

  const handleAttendanceClick = (employeeId, day) => {
    setEmployeeDetails((prevData) =>
      prevData.map((employee) => {
        if (employee.fk_staff_id === employeeId) {
          const newAttendance = employee.attendance[0].map((record) => {
            if (record.Day === day) {
              // Define the status cycle
              const statusCycle = ["P", "A", "AFH", "ASH"];
              const currentIndex = statusCycle.indexOf(record.status);
              const nextIndex = (currentIndex + 1) % statusCycle.length;
              const nextStatus = statusCycle[nextIndex];

              return {
                ...record,
                status: nextStatus,
              };
            }
            return record;
          });

          // Calculate present and absent counts
          const { presentCount, absentCount } = calculatePresentAndAbsentCounts(
            newAttendance,
            monthDays
          );

          return {
            ...employee,
            attendance: [newAttendance],
            mem: {
              ...employee.mem,
              present: presentCount,
              absent: absentCount,
            },
          };
        }
        return employee;
      })
    );
  };

  const handelSave = async () => {
    if (!Date1) {
      Swal.fire({
        text: "Please select month and year",
        icon: "error",
        allowOutsideClick: false,
      });
      return;
    }

    startLoading();

    try {
      let data = {
        school_id: school_id,
        attendance_month: Date1,
        result: EmployeeDetails,
      };
      const response = await MonthlyAttendenceAPI.updateMonthlyAtt(data);
      const responseData = response.data;

      if (responseData.status === 200) {
        Swal.fire({
          text: responseData.msg,
          icon: "success",
        });
        getEmployList();
      } else {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
        });
      }
    } catch {
    } finally {
      stopLoading();
    }
  };

  //////////////////////////////////////////////////////////////////////////////////////////////////////////////////

  if (isLoading) return <Loader />;
  return (
    <div className="container-fluid">
      <Heading pageTitle={"Monthly Attendance"}></Heading>

      <div className="card mt-2">
        <div className="card-body">
          <div className="col-lg-12 row form-group ">
            <div className="col-lg-2 ">
              <label className="col-form-label ">Month & Year</label>
            </div>
            <div className="col-lg-3">
              <input
                type="month"
                className="form-control"
                placeholder="Month & Year"
                value={Date1}
                onChange={handelDateChange}
              />
            </div>

            <div className="input-group-append">
              <button
                className="btn btn-outline-secondary mx-1"
                type="button"
                onClick={getEmployList}
              >
                <i className="fa fa-eye"> </i> View
              </button>
            </div>

            {!isGenerated && (
              <div className="input-group-append">
                <button
                  className="btn btn-primary mx-1"
                  type="button"
                  onClick={handelSave}
                >
                  <i className="fa fa-plus"> </i> Save
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card mt-2">
        <div class="card-header">
          <h3 class="card-title">Employee List</h3>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
              <thead>
                <tr>
                  <th>Action</th>
                  <th>SR NO</th>
                  <th>FULL NAME</th>
                  <th>MONTH DAYS</th>
                  <th>PRESENT DAYS</th>
                  <th>absent</th>
                  {days.map((day) => (
                    <th className="text-center" key={day}>
                      {day}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {EmployeeDetails && EmployeeDetails.length > 0 ? (
                  EmployeeDetails.map((employee, index) => (
                    <tr key={employee.fk_staff_id}>
                      <td>
                        {" "}
                        <div>
                          <button
                            className="btn btn-primary"
                            onClick={() => getLeaveData(employee.fk_staff_id)}
                          >
                            <i className="fa fa-add"></i> Add
                          </button>
                        </div>
                      </td>
                      <td>{index + 1}</td>
                      <td>{`${employee.first_name} ${employee.last_name}`}</td>
                      <td>{totalDays}</td>
                      <td>{employee?.mem?.present || 0}</td>
                      <td>{employee?.mem?.absent || 0}</td>
                      {days?.map((day) => {
                        let attendanceRecord;
                        if (employee?.attendance) {
                          attendanceRecord = employee?.attendance[0]?.find(
                            (record) => record.Day === day
                          );
                        }
                        const status = attendanceRecord
                          ? attendanceRecord.status
                          : "P";
                        return (
                          <td
                            style={{
                              textAlign: "center",
                              minWidth: "50px",
                              cursor: "pointer",
                            }}
                            key={day}
                            className={
                              status === "P"
                                ? "text-success mx-1"
                                : status === "A"
                                ? "text-danger mx-1"
                                : status === "AFH"
                                ? "colorForHalfDay mx-1"
                                : status === "ASH"
                                ? "colorForHalfDay mx-1"
                                : "ColorsForWOF text-white mx-1"
                            }
                            onClick={() =>
                              (status === "A" ||
                                status === "P" ||
                                status === "AFH" ||
                                status === "ASH") &&
                              handleAttendanceClick(employee.fk_staff_id, day)
                            }
                          >
                            {status}
                          </td>
                        );
                      })}
                    </tr>
                  ))
                ) : (
                  <tr>
                    <td colSpan="7" className="text-center"></td>
                  </tr>
                )}
              </tbody>
            </table>
            {EmployeeDetails && EmployeeDetails.length > 0 ? (
            <div className="note p-3">
              <h6>
                <span className="text-danger">*</span>Note
              </h6>
              <p>- AFH: Absent First Half</p>
              <p>- ASH: Absent Second Half</p>
            </div>
            ) : (
              null
            )}
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal}
        toggle={toggle}
        className="custom1_modal1"
        backdrop="static"
      >
        <div className="modal-header">
          <div className="col-12 row">
            <div className="col-6">
              <h4>LEAVE ENTRY </h4>
            </div>
            <div className="col-1"></div>
            <div className="input-group-append col-5 row ">
              <div className="col-lg-4 ">
                <label className="col-form-label ">Month & Year</label>
              </div>

              <div className="col-lg-8">
                <input
                  type="month"
                  className="form-control"
                  placeholder="Month & Year"
                  value={Date1}
                  disabled
                  // onChange={handelDateChange}
                />
              </div>
            </div>
          </div>
        </div>
        <ModalBody>
          <div className="container-fluid">
            <div className="card ">
              <div className="card-body ">
                <div className="row">
                  <div className="col-lg-7 border-right">
                    <div className="col-lg-12 row form-group ">
                      <div className="col-lg-12 ">
                        <label className="col-form-label ">Leave Type</label>
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-lg-12">
                        <Select
                          options={options}
                          value={
                            selectedOption
                              ? options?.find((c) => c.value === selectedOption)
                              : null
                          }
                          onChange={handelLeaveChange}
                        />{" "}
                        {formik.touched.leave_type_id &&
                          formik.errors.leave_type_id && (
                            <div className="text-danger">
                              {formik.errors.leave_type_id}
                            </div>
                          )}
                      </div>

                      <div className="col-lg-12 ">
                        <label className="col-form-label">Leave Balance</label>
                      </div>
                      <div className="col-lg-12">
                        <input
                          disabled={true}
                          className="form-control"
                          value={remLeave}
                        />
                      </div>
                    </div>

                    <hr></hr>

                    <div className="col-lg-12  row form-group ">
                      <div className=" col-lg-12 ">
                        <label className="col-form-label ">Half Day</label>
                      </div>
                      <div className="col-lg-12">
                        <label className="custom-switch">
                          <input
                            type="checkbox"
                            className="custom-switch-input"
                            name="login_user"
                            checked={isHalfDay}
                            onChange={handelHalfDay}
                          />
                          <span className="custom-switch-indicator"></span>
                        </label>
                      </div>

                      {isHalfDay && (
                        <div className="col-lg-12">
                          <div className="form-box float-right">
                            <div className="button-box">
                              <div
                                id="btn"
                                className={isLeft ? "" : "right"}
                              ></div>
                              <button
                                type="button"
                                className="toggle-btn"
                                onClick={handleLeftClick}
                              >
                                First Half
                              </button>
                              <button
                                type="button"
                                className="toggle-btn"
                                onClick={handleRightClick}
                              >
                                Second Half
                              </button>
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    <hr></hr>
                    <div className="form-group row col-lg-12">
                      <div className="col-lg-12">
                        <label className="col-form-label">From Date</label>
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-lg-12">
                        <input
                          type="date"
                          className="form-control"
                          min={firstDay}
                          max={lastDay}
                          name="from_date"
                          value={formik.values.from_date}
                          onChange={handleFromDateChange}
                        />
                        {formik.touched.from_date &&
                          formik.errors.from_date && (
                            <div className="text-danger">
                              {formik.errors.from_date}
                            </div>
                          )}
                      </div>
                      <div className="col-lg-12">
                        <label className=" col-form-label">To Date</label>
                        <span className="text-danger">*</span>
                      </div>

                      <div className="col-lg-12">
                        <input
                          type="date"
                          className="form-control"
                          name="to_date"
                          min={firstDay}
                          max={lastDay}
                          disabled={isHalfDay}
                          value={formik.values.to_date}
                          onChange={handleToDateChange}
                        />
                        {formik.touched.to_date && formik.errors.to_date && (
                          <div className="text-danger">
                            {formik.errors.to_date}
                          </div>
                        )}
                      </div>
                    </div>

                    <div className="col-12 row mt-3">
                      <div className="col-lg-12">
                        <label className="col-form-label ">
                          Number Of leaves
                        </label>
                      </div>
                      <div className="col-lg-10">
                        <input
                          className="form-control"
                          value={leaveDays}
                          disabled={true}
                        />
                      </div>
                    </div>
                    <hr></hr>
                    <div className="col-12 row mt-3 form-group">
                      <div className="col-lg-12">
                        <label className="col-form-label ">Reason</label>
                        <span className="text-danger">*</span>
                      </div>
                      <div className="col-lg-10">
                        <textarea
                          className="form-control"
                          name="reason"
                          value={formik.values.reason}
                          onChange={formik.handleChange}
                        />{" "}
                        {formik.touched.reason && formik.errors.reason && (
                          <div className="text-danger">
                            {formik.errors.reason}
                          </div>
                        )}
                      </div>
                    </div>
                    <hr></hr>
                    <div className="col-lg-12  row form-group "></div>
                  </div>
                  <div className="col-lg-5">
                    <h5>Active Leave</h5>
                    <div className="mt-4 scrollable-container">
                      {leaveData && leaveData.length > 0 ? (
                        leaveData.map((employee, index) => (
                          <div className="col-12 mb-3" key={index}>
                            <div className="card">
                              <div className="card-body">
                                <h5 className="card-title text-left">
                                  [{employee.status}]
                                </h5>
                                <div className="row justify-content-between">
                                  <p className="card-text">
                                    Date: {employee.leave_date}
                                  </p>
                                  <i
                                    className="fa fa-trash-o text-danger pr-3"
                                    onClick={() =>
                                      handleDelete(
                                        employee.id,
                                        employee.fk_staff_id
                                      )
                                    }
                                  ></i>
                                </div>
                              </div>
                            </div>
                          </div>
                        ))
                      ) : (
                        <p>No Active Leave</p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button color="primary" onClick={formik.handleSubmit}>
            Add Leave
          </Button>{" "}
          <Button color="secondary" onClick={handelClear}>
            Cancel
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default MonthlyAttendance;
