import React, { useEffect, useRef } from "react";

const PageRedirect = () => {
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let insertBy = -school_user?.id;
  const insertByRef = useRef(-school_user?.id);
  useEffect(() => {
    if (insertByRef.current !== insertBy) {
      window.location.href = "/Admin/admin-dashboard";
    }
  }, [insertBy]);
  return insertBy;
};

export default PageRedirect;
