export const USER_REQUEST = 'USER_REQUEST'
export  const USERS_SUCCESS="USERS_SUCCESS"

export const CREATE_USERS = 'CREATE_USERS'
export const GET_USERS = 'GET_USERS'

export const School_Designation = "School_Designation"

export const UPDATE_USERS = "UPDATE_USERS"
export const DELETE_USERS = "DELETE_USERS"

export const USERS_FAILD = 'USERS_FAILD'
export const CLEAR_ERROR = 'CLEAR_ERROR'

////////////////////////////////
export const CREATE_USERS_REQUEST = 'CREATE_USERS_REQUEST'
export const CREATE_USERS_SUCCESS = 'CREATE_USERS_SUCCESS'
export const CREATE_USERS_FAILURE = 'CREATE_USERS_FAILURE'

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE'

export const UPDATE_USERS_REQUEST = 'UPDATE_USERS_REQUEST'
export const UPDATE_USERS_SUCCESS = 'UPDATE_USERS_SUCCESS'
export const UPDATE_USERS_FAILURE = 'UPDATE_USERS_FAILURE'

export const DELETE_USERS_REQUEST = 'DELETE_USERS_REQUEST'
export const DELETE_USERS_SUCCESS = 'DELETE_USERS_SUCCESS'
export const DELETE_USERS_FAILURE = 'DELETE_USERS_FAILURE'

export const SCHOOL_DESIGN_REQUEST = "SCHOOL_DESIGN_REQUEST"
export const SCHOOL_DESIGN_SUCCESS = "SCHOOL_DESIGN_SUCCESS"
export const SCHOOL_DESIGN_FAILURE = "SCHOOL_DESIGN_FAILURE"

// CREATE_STAFF_SUCCESS

