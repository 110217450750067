import React from "react";

const Heading = ({ pageTitle, children }) => {
  return (
    <div className="d-flex justify-content-between align-items-center ">
      <div className="header-action">
        <h1 className="page-title">{pageTitle}</h1>
      </div>
      <div className=" mt-2">{children}</div>
    </div>
  );
};
const HeadingBreadcrumb = ({ pageTitle, children }) => {
  return (
    <div className="d-flex justify-content-between align-items-center ">
      <div className="header-action">
        <h1 className="page-title">{pageTitle}</h1>
        {/* <ol className="breadcrumb page-breadcrumb">
          <li className="breadcrumb-item">
            <a href="#">Vidyamate</a>
          </li>
          <li className="breadcrumb-item active" aria-current="page">
            Student Registration
          </li>
        </ol> */}
      </div>
      <ul className="nav nav-tabs page-header-tab">{children} </ul>
    </div>
  );
};

export { Heading, HeadingBreadcrumb };
{
  /* <ol className="breadcrumb page-breadcrumb">
  <li className="breadcrumb-item">
    <a href="#">Vidyamate</a>
  </li>
  <li className="breadcrumb-item active" aria-current="page">
    Student Registration
  </li>
</ol>; */
}
