import axios from "axios";
import { BASE_URL_ROUTER } from "../../Config/BaseUrl";
import Swal from "sweetalert2";
import {
  CREATE_STAFF_FAILURE,
  CREATE_STAFF_REQUEST,
  CREATE_STAFF_SUCCESS,
  DELETE_STAFF_FAILURE,
  DELETE_STAFF_REQUEST,
  DELETE_STAFF_SUCCESS,
  GET_STAFF_FAILURE,
  GET_STAFF_REQUEST,
  GET_STAFF_SUCCESS,
  UPDATE_STAFF_FAILURE,
  UPDATE_STAFF_REQUEST,
  UPDATE_STAFF_SUCCESS,
} from "../../Constants/school_user/StaffMaster";

export const createStaff = (token, getStaff) => {
  return async (dispatch) => {
    try {
      dispatch({ type: CREATE_STAFF_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.post(
        `${BASE_URL_ROUTER}staff_master_api/`,
        getStaff,
        config
      );
      const data = response.data;
      dispatch({ type: CREATE_STAFF_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: CREATE_STAFF_FAILURE,
        payload: error,
      });
    }
  };
};

export const getStaffList = (token, s_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_STAFF_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${BASE_URL_ROUTER}staff_master_api/?school_id=${s_id}&staff_type=All`,
        config
      );
      const data = response.data;
      dispatch({ type: GET_STAFF_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_STAFF_FAILURE,
        payload: error,
      });
    }
  };
};
export const getTeacherList = (token, s_id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_STAFF_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.get(
        `${BASE_URL_ROUTER}staff_master_api/?school_id=${s_id}&staff_type=teacher`,
        config
      );
      const data = response.data;
      dispatch({ type: GET_STAFF_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_STAFF_FAILURE,
        payload: error,
      });
    }
  };
};

export const deleteStaff = (token, ID) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_STAFF_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.delete(
        `${BASE_URL_ROUTER}staff_master_api/${ID}/`,

        config
      );
      const data = response.data;
      dispatch({ type: DELETE_STAFF_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        });
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      dispatch({
        type: DELETE_STAFF_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateStaff = (token, u_id, staff, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_STAFF_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
        },
      };
      const response = await axios.patch(
        `${BASE_URL_ROUTER}staff_master_api/${u_id}/`,
        staff,
        config
      );
      const data = response.data;
      dispatch({ type: UPDATE_STAFF_SUCCESS, payload: data });
      if (response.data.status === 200) {
        Swal.fire({
          text: response.data.msg,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        }).then((result) => {
          if (result.isConfirmed) {
            navigate("/school/staff-master");
          }
        });
      }
      if (response.data.status === 403) {
        Swal.fire({
          text: response.data.msg,
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      dispatch({
        type: UPDATE_STAFF_FAILURE,
        payload: error,
      });
    }
  };
};
