export const CREATE_HOLIDAY_REQUEST = 'CREATE_HOLIDAY_REQUEST';
export const CREATE_HOLIDAY_SUCCESS = 'CREATE_HOLIDAY_SUCCESS';
export const CREATE_HOLIDAY_FAILURE = 'CREATE_HOLIDAY_FAILURE';

export const GET_HOLIDAY_REQUEST = 'GET_HOLIDAY_REQUEST';
export const GET_HOLIDAY_SUCCESS = 'GET_HOLIDAY_SUCCESS';
export const GET_HOLIDAY_FAILURE = 'GET_HOLIDAY_FAILURE';

export const DELETE_HOLIDAY_REQUEST = 'DELETE_HOLIDAY_REQUEST';
export const DELETE_HOLIDAY_SUCCESS = 'DELETE_HOLIDAY_SUCCESS';
export const DELETE_HOLIDAY_FAILURE = 'DELETE_HOLIDAY_FAILURE';

export const UPDATE_HOLIDAY_REQUEST = 'UPDATE_HOLIDAY_REQUEST';
export const UPDATE_HOLIDAY_SUCCESS = 'UPDATE_HOLIDAY_SUCCESS';
export const UPDATE_HOLIDAY_FAILURE = 'UPDATE_HOLIDAY_FAILURE';