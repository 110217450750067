import {
  CREATE_MASTERDATA_FAILD,
  CREATE_MASTERDATA_REQUIST,
  CREATE_MASTERDATA_SUCCESS,
  DELETE_MASTERDATA_FAILD,
  DELETE_MASTERDATA_REQUIST,
  DELETE_MASTERDATA_SUCCESS,
  GET_MASTERDATA_FAILD,
  GET_MASTERDATA_REQUIST,
  GET_MASTERDATA_SUCCESS,
  UPDATE_MASTERDATA_FAILD,
  UPDATE_MASTERDATA_REQUIST,
  UPDATE_MASTERDATA_SUCCESS,
} from "../../Constants/Common";

const initialState = {
  masterData: null,
  createmasterData: [],
  loading: false,
};

export const masterDataReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_MASTERDATA_REQUIST:
    case GET_MASTERDATA_REQUIST:
    case DELETE_MASTERDATA_REQUIST:
    case UPDATE_MASTERDATA_REQUIST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_MASTERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        createmasterData: action.payload,
      };
    case GET_MASTERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        masterData: action.payload,
      };
    case UPDATE_MASTERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        masterData: action.payload,
      };
    case DELETE_MASTERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        masterData: action.payload,
      };

    case CREATE_MASTERDATA_FAILD:
    case GET_MASTERDATA_FAILD:
    case DELETE_MASTERDATA_FAILD:
    case UPDATE_MASTERDATA_FAILD:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
