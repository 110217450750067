import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");


const createAxiosConfig=(url,method,data)=>{
    return{
        method,
        url:`${BASE_URL}${url}/`,
        headers:{
            Authorization:`Bearer ${token}`,
            "Content-Type":"application/json",
        },data,
    };

}
export const gradeMasterAPI={
    createGradeCard:async(data)=>{
        const axiosConfig=createAxiosConfig(
            "Generate_GradeCard",
            "POST",data
        );
        return await axios(axiosConfig)
    },
    getStudentforGradeCard:async(data)=>{
        const axiosConfig=createAxiosConfig(
            "get_StudentforGradeCard",
            "POST",data
        );
        return await axios(axiosConfig)
    },
    getGradeMasterData:async(data)=>{
        const axiosConfig=createAxiosConfig(
            "get_GradeMasterData",
            "POST",data
        );
        return await axios(axiosConfig)
    },
    createGradeMasterData:async(data)=>{
        const axiosConfig=createAxiosConfig(
            "addUpdate_GradeData",
            "POST",data
        );
        return await axios(axiosConfig)
    },
   
    
}

