import {
  CREATE_CATEGORY_FAILD,
  CREATE_CATEGORY_REQUIST,
  CREATE_CATEGORY_SUCCESS,
  DELETE_CATEGORY_FAILD,
  DELETE_CATEGORY_REQUIST,
  DELETE_CATEGORY_SUCCESS,
  GET_CAST_MASTERDATA_FAILD,
  GET_CAST_MASTERDATA_REQUIST,
  GET_CAST_MASTERDATA_SUCCESS,
} from "../../Constants/SuperUser/CastMaster";

const initialState = {
  castMaster: null,
  deleteCategory: null,
  category: [],
  loading: false,
};

export const castMasterReducer = (state = initialState, action) => {
  switch (action.type) {
    case GET_CAST_MASTERDATA_REQUIST:
    case CREATE_CATEGORY_REQUIST:
    case DELETE_CATEGORY_REQUIST:
      return {
        ...state,
        loading: true,
      };

    case CREATE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        category: action.payload,
      };
    case GET_CAST_MASTERDATA_SUCCESS:
      return {
        ...state,
        loading: false,
        castMaster: action.payload,
      };
    case DELETE_CATEGORY_SUCCESS:
      return {
        ...state,
        loading: false,
        deleteCategory: action.payload,
      };

    case CREATE_CATEGORY_FAILD:
    case GET_CAST_MASTERDATA_FAILD:
    case DELETE_CATEGORY_FAILD:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
