import {
  CREATE_FEEDISCOUNT_FAILURE,
  CREATE_FEEDISCOUNT_REQUEST,
  CREATE_FEEDISCOUNT_SUCCESS,
} from "../../Constants/school_user/FeeDiscount";

const initialState = {
  loading: false,
};

export const feeDiscountReducer = (state = initialState, action) => {
  switch (action.type) {
    case CREATE_FEEDISCOUNT_REQUEST:
      return {
        loading: true,
      };
    case CREATE_FEEDISCOUNT_SUCCESS:
      return {
        ...state,
        loading: false,
        fee: action.payload,
      };

    case CREATE_FEEDISCOUNT_FAILURE:
      return {
        ...state,
        loading: false,
        error: action.payload,
      };

    default:
      return state;
  }
};
