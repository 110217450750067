export const GET_CAST_MASTERDATA_REQUIST='GET_CAST_MASTERDATA_REQUIST'
export const GET_CAST_MASTERDATA_SUCCESS="GET_CAST_MASTERDATA_SUCCESS"
export const GET_CAST_MASTERDATA_FAILD="GET_CAST_MASTERDATA_FAILD"

export const CREATE_CATEGORY_REQUIST='CREATE_CATEGORY_REQUIST'
export const CREATE_CATEGORY_SUCCESS="CREATE_CATEGORY_SUCCESS"
export const CREATE_CATEGORY_FAILD="CREATE_CATEGORY_FAILD"


export const DELETE_CATEGORY_REQUIST='DELETE_CATEGORY_REQUIST'
export const DELETE_CATEGORY_SUCCESS="DELETE_CATEGORY_SUCCESS"
export const DELETE_CATEGORY_FAILD="DELETE_CATEGORY_FAILD"