import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

export const EventAPI = {
  addEvent: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}add_event_activity_api/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data,
    };
    return await axios(axiosConfig);
  },

  updateEvent: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}update_event_activity/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getEvent: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_event_activity/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getOneEvent: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_eventactivity_data/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  deleteEvent: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}delete_event_activity/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
};
