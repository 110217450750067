import React, { useEffect, useState } from "react";
import { Heading } from "../../CommonComponent/heading";

import { salaryStructure } from "../../../api/Payroll/SalaryStructure";
import useLoading from "../../CommonComponent/useLoading";
import Loader from "../../Loader/Loader";
import useLocalStorageData from "../Comman/LocalStorageData";

import Swal from "sweetalert2";

const MonthlySalaryReport = () => {
  const [Date1, setDate1] = useState("");
  const [employList, setEmployList] = useState([]);
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const [actualHeads, setActualHeads] = useState([]);
  const [actualEarningsHead, setActualEarningsHead] = useState();
  const [actualDeductionHeads, setActualDeductionHeads] = useState();
  const [generatedEarningHeads, setGeneratedEarningHeads] = useState([]);
  const [generatedDeductionHeads, setGeneratedDeductionHeads] = useState([]);
  const [actualGross, setActualGross] = useState(0);
  const [actualDeduction, setActualDeduction] = useState(0);
  const [generatedGross, setGeneratedGross] = useState(0);
  const [HeadList, setHeadList] = useState([]);
  const [empList, setEmpList] = useState([]);

  const hadelDateChange = (e) => {
    let value = e.target.value;
    setDate1(value);
    setActualEarningsHead([]);
    setActualDeductionHeads();
    setGeneratedEarningHeads([]);
    setGeneratedDeductionHeads([]);
    setEmployList([])
    // getEmployList(value);
  };

  const getEmployList = async (ids) => {
    startLoading();
    let data = {
      school_id: school_id,
      attendance_month: ids,
    };
    try {
      const response = await salaryStructure.salary_report_view(data);
      const responseData = response.data;
      const isGenerated = responseData.is_generated;
      console.log(isGenerated, "isGenerated");
      if (responseData.status === 200 && isGenerated) {


        let actualEarningHeads =
          responseData.result[0].salary_list[0].actual[0].Earnings.sort(
            (a, b) => a.sequence - b.sequence
          ) || [];

        let actualDeductionHeads =
          responseData.result[0].salary_list[0].actual[1].Deductions.sort(
            (a, b) => a.sequence - b.sequence
          ) || [];

        let generatedEarningHeads =
          responseData.result[0].salary_list[1].generated[0].Earnings.sort(
            (a, b) => a.sequence - b.sequence
          ) || [];

        let generatedDeductionHeads =
          responseData.result[0].salary_list[1].generated[1].Deductions.sort(
            (a, b) => a.sequence - b.sequence
          ) || [];
        console.log(generatedDeductionHeads);
        setActualEarningsHead(actualEarningHeads);
        setActualDeductionHeads(actualDeductionHeads);
        setGeneratedEarningHeads(generatedEarningHeads);
        setGeneratedDeductionHeads(generatedDeductionHeads);

        const grossPay = actualEarningHeads.reduce(
          (sum, earning) => sum + earning.amount,
          0
        );
        const totalDeductions = actualDeductionHeads.reduce(
          (sum, deduction) => sum + deduction.amount,
          0
        );
        const netPay = grossPay - totalDeductions;

        setEmployList(responseData.result);
        const sortedEmpList = responseData.result.map((employee) => {
          const salaryList = [
            ...(employee.salary_list[0].actual[0].Earnings || []),
            ...(employee.salary_list[0].actual[1].Deductions || []),
            ...(employee.salary_list[1].generated[0].Earnings || []),
            ...(employee.salary_list[1].generated[1].Deductions || []),
          ];
          const sortedSalaryList = salaryList.sort(
            (a, b) => a.sequence - b.sequence
          );
          return { ...employee, salary_list: sortedSalaryList };
        });
        console.log(sortedEmpList);

        let headList = sortedEmpList[0]?.salary_list || [];
        setHeadList(headList);
        setEmpList(sortedEmpList);
      } else {
        Swal.fire({
          text: isGenerated ? responseData.msg : "Salary not generated for this Month",
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
    } finally {
      stopLoading();
    }
  };

  let totalActualGrossPay = 0;
  let totalActualDeductions = 0;
  let totalActualNetPay = 0;
  let totalGeneratedGrossPay = 0;
  let totalGeneratedDeductions = 0;
  let totalGeneratedNetPay = 0;

  employList.forEach((employee) => {
    const actualEarnings = employee.salary_list[0]?.actual[0]?.Earnings || [];
    const actualDeductions =
      employee.salary_list[0]?.actual[1]?.Deductions || [];
    const generatedEarnings =
      employee.salary_list[1]?.generated[0]?.Earnings || [];
    const generatedDeductions =
      employee.salary_list[1]?.generated[1]?.Deductions || [];

    const actualGrossPay = actualEarnings.reduce(
      (sum, earning) => sum + earning.amount,
      0
    );
    const actualTotalDeductions = actualDeductions.reduce(
      (sum, deduction) => sum + deduction.amount,
      0
    );
    const actualNetPay = actualGrossPay - actualTotalDeductions;

    const generatedGrossPay = generatedEarnings.reduce(
      (sum, earning) => sum + earning.amount,
      0
    );
    const generatedTotalDeductions = generatedDeductions.reduce(
      (sum, deduction) => sum + deduction.amount,
      0
    );
    const generatedNetPay = generatedGrossPay - generatedTotalDeductions;

    totalActualGrossPay += actualGrossPay;
    totalActualDeductions += actualTotalDeductions;
    totalActualNetPay += actualNetPay;
    totalGeneratedGrossPay += generatedGrossPay;
    totalGeneratedDeductions += generatedTotalDeductions;
    totalGeneratedNetPay += generatedNetPay;
  });

  if (isLoading) return <Loader />;
  return (
    <div className="container-fluid mt-1">
      <Heading pageTitle={"Monthly Salary Report"}></Heading>

      <div className="card mt-2">
        <div className="card-body  row">
          <div className="col-lg-12 row form-group ">
            <div className="col-lg-2 ">
              <label className="col-form-label">Month & Year</label>
            </div>
            <div className="col-lg-4">
              <input
                type="month"
                className="form-control"
                placeholder="Month & Year"
                value={Date1}
                onChange={(e) => hadelDateChange(e)}
              />
            </div>
            <button
              className="btn btn-outline-secondary"
              onClick={() => getEmployList(Date1)}
            >
                <i className="fa fa-eye mx-1"></i>
              VIEW
            </button>
          </div>
        </div>
      </div>

      <div className="card mt-2">
        <div class="card-header">
          <h3 class="card-title">Employee List</h3>
        </div>
        <div className="card-body">
          <div className="table-responsive">
            <table className="table table-hover table-vcenter table-sm table-striped text-nowrap">
              <thead>
                <tr>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th></th>
                  <th
                    className="text-center"
                    colSpan={
                      actualEarningsHead?.length +
                      actualDeductionHeads?.length +
                      3
                    }
                  >
                    Actual
                  </th>
                  <th
                    className="text-center"
                    style={{
                      borderLeft: "2px solid #dee2e6",
                    }}
                    colSpan={
                      generatedEarningHeads?.length +
                      generatedDeductionHeads?.length
                    }
                  >
                    Generated
                  </th>
                </tr>

                <tr>
                  <th>SR NO</th>
                  <th>FULL NAME</th>
                  <th>emp no</th>
                  <th>designation</th>
                  <th>joining date</th>
                  <th>PRESENT DAYS</th>
                  <th>absent</th>
                  {actualEarningsHead?.map((s, index) => (
                    <th key={index} className="text-center text-success">
                      {s.heads_name}
                    </th>
                  ))}
                  <th className="text-center text-success">Gross</th>
                  {actualDeductionHeads?.map((s, index) => (
                    <th key={index} className="text-center text-danger">
                      {s.heads_name}
                    </th>
                  ))}
                  <th className="text-center text-danger">Total Deduction</th>
                  <th className="text-center ">Net Pay</th>
                  {generatedEarningHeads?.map((s, index) => (
                    <th key={index} className="text-center text-success">
                      {s.heads_name}
                    </th>
                  ))}
                  <th className="text-center text-success">Gross</th>
                  {generatedDeductionHeads?.map((s, index) => (
                    <th key={index} className="text-center text-danger">
                      {s.heads_name}
                    </th>
                  ))}
                  <th className="text-center text-danger">Total Deduction</th>
                  <th className="text-center ">Net Pay</th>
                </tr>
              </thead>
              <tbody>
                {employList.map((employee, index) => {
                  // Calculate gross pay from actual earnings
                  const actualEarnings =
                    employee.salary_list[0]?.actual[0]?.Earnings || [];
                  console.log(
                    actualEarnings,
                    "actualEarnings : : : : : : : : : :"
                  );
                  const actualDeductions =
                    employee.salary_list[0]?.actual[1]?.Deductions || [];
                  const generatedEarnings =
                    employee.salary_list[1]?.generated[0]?.Earnings || [];
                  const generatedDeductions =
                    employee.salary_list[1]?.generated[1]?.Deductions || [];

                  const ActualgrossPay = actualEarnings.reduce(
                    (sum, earning) => sum + earning.amount,
                    0
                  );
                  const ActualtotalDeductions = actualDeductions.reduce(
                    (sum, deduction) => sum + deduction.amount,
                    0
                  );
                  const ActualnetPay = ActualgrossPay - ActualtotalDeductions;

                  const GeneratedgrossPay = generatedEarnings.reduce(
                    (sum, earning) => sum + earning.amount,
                    0
                  );
                  const GeneratedtotalDeductions = generatedDeductions.reduce(
                    (sum, deduction) => sum + deduction.amount,
                    0
                  );
                  const GeneratednetPay =
                    GeneratedgrossPay - GeneratedtotalDeductions;

                  return (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{employee.first_name + " " + employee.last_name}</td>
                      <td>{employee.emp_no}</td>
                      <td>{employee.designation}</td>
                      <td>{employee.joiningDate}</td>
                      <td>{employee.mem.present}</td>
                      <td>{employee.mem.absent || 0}</td>

                      {actualEarnings
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((earning) => (
                          <td
                            key={earning.sequence}
                            style={{ minWidth: "100px" }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              value={Math.round(earning.amount)}
                              disabled
                            />
                          </td>
                        ))}
                      <td style={{ minWidth: "100px" }}>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={Math.round(ActualgrossPay)}
                        />
                      </td>

                      {actualDeductions
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((deduction) => (
                          <td
                            key={deduction.sequence}
                            style={{ minWidth: "100px" }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              value={deduction.amount}
                              disabled
                            />
                          </td>
                        ))}
                      <td style={{ minWidth: "100px" }}>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={Math.round(ActualtotalDeductions)}
                        />
                      </td>
                      <td style={{ minWidth: "100px" }}>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={Math.round(ActualnetPay)}
                        />
                      </td>

                      {generatedEarnings
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((earning) => (
                          <td
                            key={earning.sequence}
                            style={{ minWidth: "100px" }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              value={Math.round(earning.amount)}
                              disabled
                            />
                          </td>
                        ))}
                      <td style={{ minWidth: "100px" }}>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={Math.round(GeneratedgrossPay)}
                        />
                      </td>

                      {generatedDeductions
                        .sort((a, b) => a.sequence - b.sequence)
                        .map((deduction) => (
                          <td
                            key={deduction.sequence}
                            style={{ minWidth: "100px" }}
                          >
                            <input
                              type="text"
                              className="form-control"
                              value={Math.round(deduction.amount)}
                              disabled
                            />
                          </td>
                        ))}
                      <td style={{ minWidth: "100px" }}>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={Math.round(GeneratedtotalDeductions)}
                        />
                      </td>
                      <td style={{ minWidth: "100px" }}>
                        <input
                          type="text"
                          className="form-control"
                          disabled
                          value={Math.round(GeneratednetPay)}
                        />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MonthlySalaryReport;
