export const RoomTable = (props) => {
  const { data,handeleDeleteRoom ,handeleEditRoom}=props
  
  return (
    <>
      {data?.map((floor, index) => {
        const [floorName, floorData] = Object.entries(floor)[0];
        return (
          <div key={index}>
            <h6 style={{ fontWeight: "bold" }}>{floorName}</h6>
            <div className="d-flex flex-wrap">
              {floorData?.map((room, roomIndex) => (
                <div
                  className="badge bg-light text-dark m-1 p-2 bed-style"
                  key={roomIndex}
                >
                  Floor :-{room.floorNo} Room :-{room.roomNo} Bed :-{room.bedNo}{" "}
                  <span
                    style={{
                      color: "green",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={()=>handeleEditRoom(room)}
                  >
                    {" "}
                    <i className="fa fa-pencil"></i>
                  </span>
                  <span
                    style={{
                      color: "red",
                      fontSize: "1rem",
                      cursor: "pointer",
                    }}
                    onClick={()=>handeleDeleteRoom(room)}
                  >
                    {" "}
                    <i className="fa fa-times"></i>
                  </span>
                </div>
              ))}
            </div>
          </div>
        );
      })}
    </>
  );
};
