import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";
import {
  CREATE_ORGANIZATION_FAILD,
  CREATE_ORGANIZATION_REQUIST,
  CREATE_ORGANIZATION_SUCCESS,
  DELETE_ORGANIZATION_FAILD,
  DELETE_ORGANIZATION_REQUIST,
  DELETE_ORGANIZATION_SUCCESS,
  GET_ORGANIZATION_FAILD,
  GET_ORGANIZATION_REQUIST,
  GET_ORGANIZATION_SUCCESS,
  UPDATE_ORGANIZATION_FAILD,
  UPDATE_ORGANIZATION_REQUIST,
  UPDATE_ORGANIZATION_SUCCESS,
} from "../../Constants/OrganizationConstant";

export const getAllOrganization = (token) => async (dispatch) => {
  try {
    dispatch({ type: GET_ORGANIZATION_REQUIST });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const { data } = await axios.post(
      `${BASE_URL}get_organization/`,
      {},
      config
    );

    dispatch({ type: GET_ORGANIZATION_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: GET_ORGANIZATION_FAILD, payload: error });
  }
};

export const createOrganization = (token, orgdata) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_ORGANIZATION_REQUIST });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${BASE_URL}register_organization/`,
      orgdata,
      config
    );
    const data = response.data;
    dispatch({ type: CREATE_ORGANIZATION_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: CREATE_ORGANIZATION_FAILD, payload: error });
  }
};
export const updateOrganization = (token, orgdata) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_ORGANIZATION_REQUIST });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${BASE_URL}edit_organization/`,
      orgdata,
      config
    );
    const data = response.data;
    dispatch({ type: UPDATE_ORGANIZATION_SUCCESS, payload: data });
    return data;
  } catch (error) {
    dispatch({ type: UPDATE_ORGANIZATION_FAILD, payload: error });
  }
};
export const deleteOrganization = (token, orgdata) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_ORGANIZATION_REQUIST });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${BASE_URL}delete_organization/`,
      orgdata,
      config
    );
    const data = response.data;
    dispatch({ type: DELETE_ORGANIZATION_SUCCESS, payload: data });
    if (response.data.status === 200) {
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
      }).then((result) => {
        if (result.isConfirmed) {
          // window.location.href = "/organization";
        }
      });
    }
  } catch (error) {
    dispatch({ type: DELETE_ORGANIZATION_FAILD, payload: error });
  }
};


