import React, { useState, useEffect } from "react";
import { Link, useNavigate, NavLink } from "react-router-dom";
import Swal from "sweetalert2";
import Select from "react-select";
import * as Yup from "yup";
import { useFormik } from "formik";
import { useDispatch, useSelector } from "react-redux";
import { getClass } from "../../../../actions/Admission/StudentReport";

import {
  createSubject,
  deleteSubject,
  getSubjectList,
  updateSubject,
} from "../../../../actions/school_user/SubjectMaster";
import Loader from "../../../Loader/Loader";
const SubjectMaster = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { id, user_type, name, user_detail } = school_user;
  let s_id = school_user?.user_detail?.fk_school_id;
  const dispatch = useDispatch();
  const { allClass } = useSelector((state) => state.getClass);
  const { subjectdata, loading } = useSelector((state) => state.subject);
  const [edit, setedit] = useState(false);
  const [editdata, seteditdata] = useState("");

  useEffect(() => {
    if (token) {
      dispatch(getSubjectList(token, s_id));
    }
  }, [dispatch, token]);

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      fk_school: s_id,
      subject_name: editdata?.subject_name || "",
      subject_code: editdata?.subject_code || "",
    },

    validationSchema: Yup.object().shape({
      subject_name: Yup.string().required("Subject Name is required"),
      subject_code: Yup.string().required("Subject Code is required"),
    }),

    onSubmit: (values, { resetForm }) => {
      const data = new FormData();
      data.append("fk_school", values.fk_school);
      data.append("subject_name", values.subject_name);
      data.append("subject_code", values.subject_code);
      if (edit) {
        dispatch(updateSubject(token, editdata?.id, data))
          .then(() => {
            setedit(false);
            seteditdata("");
            dispatch(getSubjectList(token, s_id));
          })
          .catch((err) => console.error(err));
      } else {
        dispatch(createSubject(token, data, resetForm))
          .then(() => {
            dispatch(getSubjectList(token, s_id));
          })
          .catch((err) => console.error(err));
      }

      // resetForm();
    },
  });

  const onDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteSubject(token, id))
          .then(() => {
            dispatch(getSubjectList(token, s_id));
          })
          .catch((err) => console.error(err));
      }
    });
  };

  let onEdit = (data) => {
    setedit(true);
    seteditdata(data);
  };
  let handleCancel = (event) => {
    event.preventDefault();
    formik.resetForm();
    setedit(false);
    seteditdata("");
  };
  function convertDate(data) {
    const parts = data.split("-");
    const formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    return formattedDate;
  }

  if (loading) return <Loader />;
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Subject Master</h1>
            </div>
           
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content row">
            <div className="col-lg-6 col-md-12 col-sm-12">
              <div className="card">
                <form
                  className="card-body"
                  onSubmit={(e) => {
                    e.preventDefault();
                    formik.handleSubmit();
                    return false;
                  }}
                >
                  <div className="row">
                    <div className="col-md-12">
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Subject Name
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className={`form-control input-height ${
                              formik.touched.subject_name &&
                              formik.errors.subject_name
                                ? "is-invalid"
                                : ""
                            }`}
                            name="subject_name"
                            placeholder="Enter Subject Name"
                            value={formik.values.subject_name}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.subject_name &&
                          formik.errors.subject_name ? (
                            <div className="invalid-feedback">
                              {formik.errors.subject_name}
                            </div>
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Subject Code
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className={`form-control input-height ${
                              formik.touched.subject_code &&
                              formik.errors.subject_code
                                ? "is-invalid"
                                : ""
                            }`}
                            name="subject_code"
                            placeholder="Enter Subject Code"
                            value={formik.values.subject_code}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          {formik.touched.subject_code &&
                          formik.errors.subject_code ? (
                            <div className="invalid-feedback">
                              {formik.errors.subject_code}
                            </div>
                          ) : null}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 d-flex justify-content-end align-item-center my-3">
                    <button
                      type="submit"
                      className="btn btn-primary btn-sm px-3
                      "
                    >
                      {" "}
                      <i className="fa fa-plus pr-1"></i>
                      {edit ? "Update" : "Add"}
                    </button>
                    <button
                      onClick={handleCancel}
                      className="btn btn-outline-danger btn-sm ml-2"
                    >
                      <i className="fa fa-times pr-1"></i>
                      Clear
                    </button>
                  </div>
                </form>
              </div>
            </div>
            <div className="table-responsive card col-lg-6 col-md-12 col-sm-12">
              <table className="table table-hover table-vcenter table-striped table-sm text-nowrap">
                <thead>
                  <tr>
                    <th>Sr.No.</th>
                    <th>Subject</th>
                    <th>Subject Code</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {subjectdata?.payload?.map((subject, index) => (
                    <tr key={index}>
                      <td>{index + 1}</td>
                      <td>{subject.subject_name}</td>
                      <td>{subject.subject_code}</td>
                      <td>
                        <NavLink
                          to="#"
                          className="card-options-remove mr-3"
                          data-toggle="tooltip"
                                  data-placement="top"
                                  title="Delete"
                          onClick={() => onDelete(subject.id)}
                        >
                          <i className="fa fa-trash-o text-danger"></i>
                        </NavLink>
                        <NavLink
                          className="text-muted"
                          to="#"
                          data-toggle="tooltip"
                          data-placement="top"
                          title="Edit"
                          onClick={() => onEdit(subject)}
                        >
                          <i className="fa fa-edit"></i>
                        </NavLink>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SubjectMaster;
