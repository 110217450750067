import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import CreatableSelect from "react-select/creatable";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import { CircularProgressbar } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import {
  addChapter,
  addContent,
  deleteContent,
  getChaptertList,
  getClassWiseSubjectList,
  getContentList,
  updateContent,
} from "../../../../actions/school_user/contentUploader";
import * as Yup from "yup";
import { useFormik } from "formik";
import { NavLink } from "react-router-dom";
import { FcVideoFile } from "react-icons/fc";
import { FaFilePdf } from "react-icons/fa";
import { resetState } from "../../../../actions/ResetAction/ResetAction";
import useSessionRedirect from "../../Comman/SessionChange";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";

const ContentUploader = () => {
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id } = school_user?.user_detail;
  const dispatch = useDispatch();
  const { subject, chapter, contentData } = useSelector(
    (state) => state.contentUploader
  );
  const [uploadProgress, setUploadProgress] = useState(0);
  const { allClass } = useSelector((state) => state.getClass);
  const [inputType, setInputType] = useState("file");
  const [contentType, setContentType] = useState("Video");
  const [selectClass, setSelectClass] = useState(null);
  const [selectSubject, setSelectSubject] = useState(null);
  const [selectChapter, setSelectChapter] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [chapterList, setChapterList] = useState([]);
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isEditData, setIsEditData] = useState("");

  const [edit, setedit] = useState(false);

  const handleRadioChange = (event) => {
    setInputType(event.target.value);
  };

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  let cl_subId = {
    academic_id: session_id,
    school_id: school_id,
    class_id: selectClass,
    employee_id: school_emp_id,
  };

  useEffect(() => {
    if (token && selectClass) {
      dispatch(getClassWiseSubjectList(token, cl_subId));
    }
  }, [dispatch, token, selectClass, session_id]);
  let content_list = {
    academic_id: session_id,
    school_id: school_id,
    class_id: selectClass,
    subject_id: selectSubject,
    chapter_id: selectChapter?.value,
  };
  useEffect(() => {
    if (selectClass && selectSubject && selectChapter?.value) {
      dispatch(getContentList(content_list));
    }
  }, [dispatch, selectClass, selectSubject, selectChapter?.value, session_id]);

  useEffect(() => {
    setContent(contentData);
  }, [contentData]);

  useEffect(() => {
    let List = subject?.payload?.map((c) => {
      return {
        label: `${c.subject_name}`,
        value: c.id,
      };
    });
    setSubjectList(List);
  }, [subject]);

  useEffect(() => {
    let List = chapter?.payload?.map((c) => {
      return {
        label: `${c.name}`,
        value: c.id,
      };
    });
    setChapterList(List);
  }, [chapter]);

  let cdata = {
    school_id: school_id,
    class_id: selectClass,
    subject_id: selectSubject,
  };
  useEffect(() => {
    if (selectSubject) {
      dispatch(getChaptertList(cdata));
    }
  }, [selectSubject]);

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

 

  const handleSelectChange = (selected) => {
    setSelectChapter(selected);
  };
  const handleCreateOption = async (inputValue) => {
    let newOption = {
      academic_id: session_id,
      school_id: school_id,
      class_id: selectClass,
      subject_id: selectSubject,
      name: inputValue,
      insert_by: school_emp_id,
    };
    try {
      let res = await dispatch(addChapter(newOption));
      let result = res?.payload?.find((r) => r.name === inputValue);
      await dispatch(getChaptertList(cdata));
      setSelectChapter({
        label: result.name,
        value: result.id,
      });
    } catch (error) {
      console.error(error);
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      class_id: selectClass,
      subject_content_id: content?.payload?.subject_content_id || "",
      subject_id: selectSubject,
      chapter_id: selectChapter?.value,
      content_type: contentType,
      title: isEditData?.title || "",
      content_file: isEditData?.content_file || "",
      content_url: isEditData?.content_url || "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("Title is required"),
    }),

    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      setUploadProgress(0);
      try {
        let response;
        let onUploadProgress = (ProgressEvent) => {
          const progress = Math.round(
            (ProgressEvent.loaded / ProgressEvent.total) * 100
          );
          setUploadProgress(progress);
        };
        const data = new FormData();
        data.append("insert_by", school_emp_id);
        data.append("title", values.title);
        data.append("content_url", values.content_url);
        if (edit) {
          data.append("content_id", isEditData?.id);
        } else {
          data.append("academic_id", values.academic_id);
          data.append("school_id", values.school_id);
          data.append("class_id", values.class_id);
          data.append("subject_content_id", values.subject_content_id);
          data.append("subject_id", values.subject_id);
          data.append("chapter_id", values.chapter_id);
          data.append("content_type", values.content_type);
        }
        data.append("content_file", values.content_file);
        response = await dispatch(
          edit
            ? updateContent(data, onUploadProgress)
            : addContent(data, onUploadProgress)
        );
        if (response.status === 200) {
          setIsLoading(false);
          Swal.fire({
            text: response.message,
            icon: "success",
            imageAlt: "success image",
            allowOutsideClick: false,
          }).then((result) => {
            if (result.isConfirmed) {
              dispatch(getContentList(content_list));
              if (edit) {
                setedit(false);
                setIsEditData("");
              }
              resetForm();
            }
          });
        }
        if (response.status === 403) {
          setIsLoading(false);
          Swal.fire({
            text: response.msg,
            icon: "error",
            imageAlt: "error image",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        setIsLoading(false);
        console.error("Error sending video:", error);
      }
    },
  });
  // useEffect(() => {
  //   formik.setFieldValue("class_id", selectClass);
  //   formik.setFieldValue("subject_content_id", selectSubject);
  // }, [selectClass, selectSubject]);

  const handleFormSubmit = (e) => {
    e.preventDefault();
    if (
      !formik.values.class_id ||
      !formik.values.subject_id ||
      !formik.values.chapter_id
    ) {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please select required Fields .",
        allowOutsideClick: false,
      });
      return;
    }
    if (!formik.values.content_file && !formik.values.content_url) {
      Swal.fire({
        icon: "error",
        title: "",
        text: "Please select a file or provide a URL.",
        allowOutsideClick: false,
      });
      return;
    }
    if (formik.values.content_file) {
      let file = formik.values.content_file;
      if (file.size >= 50 * 1024 * 1024) {
        Swal.fire({
          text: "File size must be less than 50 MB.",
          icon: "error",
          imageAlt: "error image",
          allowOutsideClick: false,
        });
        return;
      }
    }
    formik.handleSubmit();
  };

  const onDelete = (id) => {
    let cId = {
      content_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteContent(cId))
          .then((response) => {
            if (response.status === 200) {
              Swal.fire({
                text: response.message,
                icon: "success",
                imageAlt: "success image",
                allowOutsideClick: false,
              });
            }
            if (response.status === 403) {
              Swal.fire({
                text: response.message,
                icon: "error",
                imageAlt: "error image",
                allowOutsideClick: false,
              });
            }
            dispatch(getContentList(content_list));
          })
          .catch((err) => console.log(err));
      }
    });
  };

  const onEdit = (data) => {
    setInputType(data?.content_file ? "file" : "url");
    setedit(true);
    setIsEditData(data);
  };
  const fileInputRef = useRef(null);
  const fileInputRef1 = useRef(null);

  const handleClear = (data) => {
    if (data) {
      setContentType(data);
    }
    formik.resetForm();
    setedit(false);
    formik.setFieldValue("content_file", null);
    setIsEditData("");
    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
    if (fileInputRef1.current) {
      fileInputRef1.current.value = "";
    }
  };

  let handleChangeClass = (select) => {
    setSelectClass(select?.value);
    setSelectSubject(null);
    setSelectChapter(null);
    setContent("");
    handleClear();
    setSubjectList([]);
    setChapterList([]);
  };
  useEffect(() => {
    handleChangeClass();
  }, [session_id]);
  if (isLoading) {
    return (
      <div className="progress-container">
        <div className="progress-center">
          <CircularProgressbar
            value={uploadProgress}
            text={`${uploadProgress}%`}
          />
        </div>
      </div>
    );
  }
  function getFileName(data) {
    let name = "";
    const path = data.split("/");
    const lastPath = path[path.length - 1];
    if (lastPath.endsWith(".pdf")) {
      name = lastPath;
    } else {
      name = lastPath;
    }
    return name;
  }
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Content Uploader</h1>
            </div>
          </div>
        </div>
      </div>
     <div></div>
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body row">
                  <div className="form-group col-lg-4  row">
                    <label className="col-lg-4 col-form-label">
                      Class<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        name="class_id"
                        placeholder="Select Class..."
                        options={classList}
                        value={
                          selectClass
                            ? classList?.find((c) => c.value === selectClass)
                            : null
                        }
                        onChange={handleChangeClass}
                        isSearchable={true}
                      />
                    </div>
                  </div>


                  <div className="form-group col-lg-4  row">
                    <label className="col-lg-4 col-form-label">
                      Subject<span className="text-danger">*</span>
                    </label>
                    <div className="col-lg-8">
                      <Select
                        placeholder="Select Subject"
                        options={subjectList}
                        value={
                          selectSubject
                            ? subjectList.find(
                                (option) => option.value === selectSubject
                              )
                            : null
                        }
                        onChange={(select) => {
                          setSelectSubject(select.value);
                          setSelectChapter(null);
                          setContent("");
                        }}
                        isSearchable={true}
                      />
                    </div>
                  </div>
                  <div className="form-group col-lg-4  row">
                    <label className="col-lg-4 col-form-label">
                      Chapter<span className="text-danger">*</span>
                    </label>{" "}
                    <div className="col-lg-8"> 
                      <CreatableSelect
                        options={chapterList}
                        placeholder="Enter Chapter Name"
                        value={selectChapter}
                        onChange={handleSelectChange}
                        onCreateOption={handleCreateOption}
                        aria-describedby="Chapter"
                      />
                      <small id="Chapter" className="form-text text-muted">If the Chapter is not available,type and press enter.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-body">
                  <ul className="nav nav-tabs">
                    <li className="nav-item">
                      <a
                        className="nav-link active"
                        data-toggle="tab"
                        href="#video"
                        onClick={() => handleClear("Video")}
                      >
                        VIDEO
                      </a>
                    </li>
                    <li className="nav-item">
                      <a
                        className="nav-link"
                        data-toggle="tab"
                        href="#Notes"
                        onClick={() => handleClear("Notes")}
                      >
                        NOTES
                      </a>
                    </li>
                  </ul>

                  <div className="tab-content mt-4">
                    <div className="tab-pane active" id="video">
                      <div className="col-lg-12 col-md-12 col-sm-12 row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <form onSubmit={handleFormSubmit}>
                            <div className="form-group row">
                              <label className="col-lg-3 col-form-label">
                                Title
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-9">
                                <input
                                  type="text"
                                  className={`form-control input-height ${
                                    formik.touched.title && formik.errors.title
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="title"
                                  placeholder="Enter Title"
                                  value={formik.values.title}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.title && formik.errors.title ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.title}
                                  </div>
                                ) : null}
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-lg-3 col-form-label">
                                Video Type
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-9 d-flex  align-items-center">
                                <div className="form-check ">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fileOrUrl"
                                    id="file"
                                    value="file"
                                    checked={inputType === "file"}
                                    onChange={handleRadioChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="file"
                                  >
                                    File
                                  </label>
                                </div>
                                <div className="form-check mx-3">
                                  <input
                                    className="form-check-input"
                                    type="radio"
                                    name="fileOrUrl"
                                    id="url"
                                    value="url"
                                    checked={inputType === "url"}
                                    onChange={handleRadioChange}
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="url"
                                  >
                                    Url
                                  </label>
                                </div>
                              </div>
                            </div>
                            <div className="form-group row">
                              <label className="col-lg-3 col-form-label">
                                File Uploader
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-9">
                                {inputType === "file" ? (
                                  <input
                                    type="file"
                                    className="form-control"
                                    name="content_file"
                                    ref={fileInputRef}
                                    onChange={(e) =>
                                      formik.setFieldValue(
                                        "content_file",
                                        e.currentTarget.files[0]
                                      )
                                    }
                                    onBlur={formik.handleBlur}
                                    accept=".mp4"
                                  />
                                ) : (
                                  <input
                                    type="url"
                                    className="form-control"
                                    placeholder="Enter URL"
                                    name="content_url"
                                    value={formik.values.content_url}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                )}
                              </div>
                            </div>
                            {edit ? (
                              <div className="form-group row">
                                <label className="col-lg-3 col-form-label">
                                  <span className="text-danger"></span>
                                </label>
                                <div className="col-md-9">
                                  <a
                                    href={isEditData?.content_file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <FcVideoFile
                                      style={{
                                        fontSize: "1.5rem",
                                      }}
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="View Video"
                                    />
                                  </a>
                                  <span>
                                    {getFileName(isEditData?.content_file)}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}
                            <div className="row my-4">
                              <button
                                type="submit"
                                className="btn btn-primary btn-sm mx-1"
                              > <i className="fa fa-plus px-1"></i>
                                {edit ? "Update" : "Add"}
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-sm mx-2"
                                onClick={() => handleClear()}
                              >
                                <i className="fa fa-times px-1"></i>
                                Clear
                              </button>
                            </div>
                          </form>
                        </div>
                        <div className="table-responsive col-lg-6 col-md-12 col-sm-12">
                          <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
                            <thead>
                              <tr>
                                <th>Title</th>
                                <th>File Type</th>
                                <th>File</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {content?.payload?.Video_data &&
                              content?.payload?.Video_data.length > 0 ? (
                                content?.payload?.Video_data?.map(
                                  (video, index) => (
                                    <tr key={index}>
                                      <td>{video.title}</td>
                                      <td>{video.content_type}</td>
                                      <td>
                                        {video.content_file ? (
                                          <a
                                            href={video.content_file}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <FcVideoFile
                                              style={{
                                                fontSize: "1.5rem",
                                                
                                              }}
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="View Video"
                                            />
                                          </a>
                                        ) : (
                                          <a
                                            href={video.content_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <FcVideoFile
                                              style={{
                                                fontSize: "1.5rem",
                                              }}
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="View Video"
                                            />
                                          </a>
                                        )}
                                      </td>
                                      <td>
                                        <NavLink
                                          to="#"
                                          className="card-options-remove mr-3"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete"
                                          onClick={() => onDelete(video.id)}
                                        >
                                          <i className="fa fa-trash-o text-danger"></i>
                                        </NavLink>
                                        <NavLink
                                          className="text-muted"
                                          to="#"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit"
                                          onClick={() => onEdit(video)}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </NavLink>
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <EmptyListMessage
                                  Table="table"
                                  message={"Video Not Available !"}
                                  Span={4}
                                />
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div className="tab-pane" id="Notes">
                      <div className="col-lg-12 col-md-12 col-sm-12 row">
                        <div className="col-lg-6 col-md-12 col-sm-12">
                          <form onSubmit={handleFormSubmit}>
                            <div className="form-group row">
                              <label className="col-lg-3 col-form-label">
                                Title
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-9">
                                <input
                                  type="text"
                                  className={`form-control input-height ${
                                    formik.touched.title && formik.errors.title
                                      ? "is-invalid"
                                      : ""
                                  }`}
                                  name="title"
                                  placeholder="Enter Title"
                                  value={formik.values.title}
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                />
                                {formik.touched.title && formik.errors.title ? (
                                  <div className="invalid-feedback">
                                    {formik.errors.title}
                                  </div>
                                ) : null}
                              </div>
                            </div>

                            <div className="form-group row">
                              <label className="col-lg-3 col-form-label">
                                File Uploader
                                <span className="text-danger">*</span>
                              </label>
                              <div className="col-md-9">
                                <input
                                  type="file"
                                  className="form-control"
                                  name="content_file"
                                  ref={fileInputRef1}
                                  onChange={(e) =>
                                    formik.setFieldValue(
                                      "content_file",
                                      e.currentTarget.files[0]
                                    )
                                  }
                                  onBlur={formik.handleBlur}
                                  // accept=".pdf,.jpg,.jpeg,.png,.gif"
                                  accept=".pdf"
                                />
                              </div>
                            </div>
                            {edit ? (
                              <div className="form-group row">
                                <label className="col-lg-3 col-form-label">
                                  File
                                  <span className="text-danger">*</span>
                                </label>
                                <div className="col-md-9">
                                  <a
                                    href={isEditData?.content_file}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                  >
                                    <FaFilePdf
                                      style={{
                                        fontSize: "1.5rem",
                                      }}
                                      className="text-danger"
                                      data-toggle="tooltip"
                                      data-placement="top"
                                      title="View Pdf"
                                    />
                                  </a>
                                  <span>
                                    {getFileName(isEditData?.content_file)}
                                  </span>
                                </div>
                              </div>
                            ) : (
                              ""
                            )}

                            <div className="row my-4">
                            <button
                                type="submit"
                                className="btn btn-primary btn-sm mx-1"
                              > <i className="fa fa-plus px-1"></i>
                                {edit ? "Update" : "Add"}
                              </button>
                              <button
                                type="button"
                                className="btn btn-outline-danger btn-sm mx-2"
                                onClick={() => handleClear()}
                              >
                                <i className="fa fa-times px-1"></i>
                                Clear
                              </button>
                            </div>
                          </form>
                        </div>
                        <div className="table-responsive  col-lg-6 col-md-12 col-sm-12">
                          <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap">
                            <thead>
                              <tr>
                                <th>Title</th>
                                <th>File Type</th>
                                <th>File</th>
                                <th>Action</th>
                              </tr>
                            </thead>
                            <tbody>
                              {content?.payload?.Notes_data &&
                              content?.payload?.Notes_data.length > 0 ? (
                                content?.payload?.Notes_data?.map(
                                  (video, index) => (
                                    <tr key={index}>
                                      <td>{video.title}</td>
                                      <td>{video.content_type}</td>
                                      <td>
                                        {video.content_file ? (
                                          <a
                                            href={video.content_file}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <FaFilePdf
                                              style={{
                                                fontSize: "1.5rem",
                                                // color:"red"
                                              }}
                                              className="text-danger"
                                              data-toggle="tooltip"
                                              data-placement="top"
                                              title="View Pdf"
                                            />
                                          </a>
                                        ) : (
                                          <a
                                            href={video.content_url}
                                            target="_blank"
                                            rel="noopener noreferrer"
                                          >
                                            <FcVideoFile
                                              style={{
                                                fontSize: "1.5rem",
                                              }}
                                            />
                                          </a>
                                        )}
                                      </td>
                                      <td>
                                        <NavLink
                                          to="#"
                                          className="card-options-remove mr-3"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Delete"
                                          onClick={() => onDelete(video.id)}
                                        >
                                          <i className="fa fa-trash-o text-danger"></i>
                                        </NavLink>
                                        <NavLink
                                          className="text-muted"
                                          to="#"
                                          data-toggle="tooltip"
                                          data-placement="top"
                                          title="Edit"
                                          onClick={() => onEdit(video)}
                                        >
                                          <i className="fa fa-edit"></i>
                                        </NavLink>
                                      </td>
                                    </tr>
                                  )
                                )
                              ) : (
                                <EmptyListMessage
                                  Table="table"
                                  message={"Notes Not Available !"}
                                  Span={4}
                                />
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContentUploader;
