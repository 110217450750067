import React from "react";
import { Container, Row, Col, Form, Input, Button } from "reactstrap";
import Select from "react-select";

const ExamDashboard = () => {
  return (
    <Container>
      <h1 className="mt-4 mb-4">Dashboard</h1>

      <Row>
        <Col lg="5" md="6" sm="12"></Col>

        <Col lg="5" md="6" sm="12"></Col>
      </Row>

      {/* Add more rows and columns for additional content */}
    </Container>
  );
};

export default ExamDashboard;
