import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import "react-circular-progressbar/dist/styles.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import useSessionRedirect from "../../Comman/SessionChange";
import { MEDIA_BASE_URL } from "../../../../Config/BaseUrl";
import { CircularAPI } from "../../../../api/TeacherDesk/Circular";
import { NavLink } from "react-router-dom";
import { FaFileDownload } from "react-icons/fa";
import Loader from "../../../Loader/Loader";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import { validateFile } from "../../../CommonComponent/FileValidationUtil";
import {
  ClearButton,
  SaveButton,
} from "../../../CommonComponent/ReusableButton";

const Circular = () => {
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id, first_name, last_name } = school_user?.user_detail;
  const dispatch = useDispatch();
  const { classsectionList } = useSelector((state) => state.attendance);
  const [activeDate, setActiveDate] = useState(false);
  const [selectedSectionId, setSelectedSectionId] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [circularData, setCircularData] = useState([]);
  const [iscircular, setIsCircular] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const fileInputRef = useRef(null);

  const fetchCircularData = async () => {
    let data = {
      academic_id: session_id,
      school_id: school_id,
    };
    try {
      let response = await CircularAPI.getCircular(data);
      let responseData = response.data;
      if (responseData.status === 200) {
        setCircularData(responseData.Circular);
      } else if (responseData.status === 403) {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClear = () => {
    validation.resetForm();
    setSelectedSectionId([]);
    setSelectAllChecked(false);
    setActiveDate(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setIsEdit(false);
    setIsCircular([]);
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      insert_by: school_emp_id,
      main_title: iscircular?.main_title || "",
      title: iscircular?.title || "",
      circular_by: iscircular?.circular_by || `${first_name} ${last_name}`,
      from_date:
        iscircular?.from_date || new Date().toISOString().substr(0, 10),
      to_date: iscircular?.to_date || new Date().toISOString().substr(0, 10),
      is_active: iscircular?.is_active || false,
      circular_id: iscircular?.id || "",
      section: [],
      circular: iscircular?.circular || null,
    },
    validationSchema: Yup.object().shape({
      main_title: Yup.string().required("This field is required."),
      title: Yup.string().required("This field is required."),
      circular: Yup.mixed()
        .test("filesize", "File is too large. maximum size is 5mb", (value) => {
          if (typeof value === "string") {
            return true;
          }
          return !value || (value && value.size <= 5 * 1024 * 1024); 
        })
        .nullable(),
    }),

    onSubmit: async (values) => {
      if (!selectedSectionId.length) {
        Swal.fire({
          title: "",
          text: "Select Atleast One Class.",
          icon: "warning",
          allowOutsideClick: false,
        });
        return;
      } 
    
      try {
        isEdit ? setIsLoading(false) : setIsLoading(true);
        const data = new FormData();
        data.append("academic_id", values.academic_id);
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("title", values.title);
        data.append("main_title", values.main_title);
        data.append("circular_by", values.circular_by);
        data.append("from_date", activeDate ? values.from_date : "None");
        data.append("to_date", activeDate ? values.to_date : "None");
        data.append("circular_id", values.circular_id);
        data.append("circular", values.circular);
        data.append("is_active", values.is_active ? "True" : "False");
        data.append("section", JSON.stringify(selectedSectionId));
        for (const [key, value] of data.entries()) {
          console.log(`${key}: ${value}`);
        }
        const response = await CircularAPI.addCircular(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          setIsLoading(false);
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          fetchCircularData();
          handleClear();
        } else if (responseData.status === 403) {
          setIsLoading(false);
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const onDelete = (id) => {
    let cir_id = {
      circular_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await CircularAPI.deleteCircular(cir_id);
        let responseData = response.data;
        if (responseData.status === 200) {
          fetchCircularData();
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchCircularData();
  }, [session_id]);

  let handleCheckboxChange = (c_id) => {
    if (selectedSectionId.includes(c_id)) {
      setSelectedSectionId(selectedSectionId.filter((id) => id !== c_id));
    } else {
      setSelectedSectionId([...selectedSectionId, c_id]);
    }
  };

  const handleSelectAllClass = () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      const allId = classsectionList?.payload?.map((cl) => cl.id);
      setSelectedSectionId(allId);
    } else {
      setSelectedSectionId([]);
    }
  };

  const onEdit = async (id) => {
    let cir_id = {
      circular_id: id,
    };
    try {
      let response = await CircularAPI.getOneCircular(cir_id);
      let responseData = response.data;
      if (responseData.status === 200) {
        let circular = responseData.circular_data[0];
        setIsCircular(circular);
        setIsEdit(true);
        const allClassId = circular?.section_id?.map((cl) => cl.fk_section_id);
        setSelectedSectionId(allClassId);
        let from_date = responseData.circular_data[0]?.from_date;
        if (from_date) {
          setActiveDate(true);
        } else {
          setActiveDate(false);
        }
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  let handleFileChange = async (file) => {
    if (!file) return;
    if (validateFile(file, fileInputRef)) {
      validation.setFieldValue("circular", file);
      let base64 = await convertBase64(file);
      setSelectedFile(base64);
    }
  };
 

  function formatDate(data) {
    const date = new Date(data);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }
  function convertDateToddmmyy(data) {
    const parts = data.split("-");
    const formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    return formattedDate;
  }
  function getFileName(data) {
    let name = "";
    const path = data.split("/");
    const lastPath = path[path.length - 1];
    if (lastPath.endsWith(".pdf")) {
      name = lastPath;
    } else {
      name = lastPath;
    }
    return name;
  }
  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Circulars</h1>
            </div>
          </div>
        </div>
      </div>

      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    validation.handleSubmit();
                    return false;
                  }}
                >
                  <div className="card-body row">
                    <div className="col-lg-8 col-md-12">
                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label">
                          Title <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Title"
                            name="main_title"
                            value={validation.values.main_title}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.touched.main_title &&
                          validation.errors.main_title ? (
                            <ErrorDisplay
                              errorMsg={validation.errors.main_title}
                            />
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label">
                          Notices <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <textarea
                            type="text"
                            className="form-control"
                            placeholder="Notice"
                            name="title"
                            value={validation.values.title}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                            autoComplete="off"
                          />
                          {validation.touched.title &&
                          validation.errors.title ? (
                            <ErrorDisplay errorMsg={validation.errors.title} />
                          ) : null}
                        </div>
                      </div>
                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label">
                          Upload Document:<span className="text-danger"></span>
                        </label>{" "}
                        <div className="col-md-8">
                          <input
                            type="file"
                            className="form-control"
                            name="circular"
                            ref={fileInputRef}
                            onChange={(e) => {
                              handleFileChange(e.currentTarget.files[0]);
                            }}
                            onBlur={validation.handleBlur}
                            accept=".jpg, .jpeg, .png, .gif, .pdf"
                          />
                          {validation.touched.circular &&
                          validation.errors.circular ? (
                            <ErrorDisplay
                              errorMsg={validation.errors.circular}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label"></label>
                        <div className="col-md-8 d-flex  align-items-center">
                          {iscircular?.circular ? (
                            <a
                              href={`${MEDIA_BASE_URL}${iscircular?.circular}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              <FaFileDownload
                                style={{
                                  fontSize: "1.3rem",
                                }}
                                className="text-danger"
                              />
                            </a>
                          ) : (
                            ""
                          )}
                          {iscircular?.circular ? (
                            <span className="px-2">
                              {getFileName(iscircular?.circular)}
                            </span>
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label">
                          Set Validity
                        </label>
                        <div className="col-md-8 d-flex  align-items-center">
                          <input
                            type="checkbox"
                            checked={activeDate}
                            onChange={() => setActiveDate(!activeDate)}
                            style={{ cursor: "pointer" }}
                          />
                        </div>
                      </div>
                      {activeDate && (
                        <div className="form-group col-md-12 row justify-content-between">
                          <div className="form-group col-md-6 row">
                            <label className="col-md-5 col-form-label">
                              Active Date <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-7 d-flex  align-items-center ">
                              <input
                                type="date"
                                name="from_date"
                                className="form-control"
                                value={validation.values.from_date || ""}
                                onChange={validation.handleChange}
                              />
                            </div>
                          </div>
                          <div className="form-group col-md-6 row">
                            <label className="col-md-5 col-form-label">
                              Expiry Date <span className="text-danger">*</span>
                            </label>
                            <div className="col-md-7 d-flex  align-items-center ">
                              <input
                                type="date"
                                name="to_date"
                                className="form-control"
                                value={validation.values.to_date}
                                onChange={validation.handleChange}
                              />
                            </div>
                          </div>
                        </div>
                      )}

                      <div className="form-group col-md-12 row">
                        <label className="col-md-4 col-form-label">
                          Notice By <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-8">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Notice By"
                            name="circular_by"
                            value={validation.values.circular_by}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                        </div>
                      </div>
                      <div className="form-group col-md-12">
                        <label
                          className="col-md-12 col-form-label"
                          style={{
                            borderBottom: "1px dashed black",
                          }}
                        >
                          <>Settings</>
                        </label>
                      </div>
                      <div className="form-group col-md-12 row justify-content-between">
                        <div className="form-group col-md-4 row">
                          <label className="col-md-8 col-form-label">
                            Active
                          </label>
                          <div className="col-md-4 d-flex  align-items-center ">
                            <label className="custom-switch pr-1">
                              <input
                                type="checkbox"
                                className="custom-switch-input custom-switch"
                                name="is_active"
                                checked={validation.values.is_active}
                                onChange={validation.handleChange}
                                style={{ cursor: "pointer" }}
                              />
                              <span className="custom-switch-indicator"></span>
                            </label>
                            {validation.values.is_active ? (
                              <b>YES</b>
                            ) : (
                              <b>NO</b>
                            )}
                          </div>
                        </div>
                        {/* <div className="form-group col-md-4 row">
                        <label className="col-md-8 col-form-label">
                          Is Important
                        </label>
                        <div className="col-md-4 d-flex  align-items-center ">
                          <label className="custom-switch pr-1">
                            <input
                              type="checkbox"
                              className="custom-switch-input"
                              name="status"
                              checked={active}
                              onChange={() => setActive(!active)}
                            />
                            <span className="custom-switch-indicator"></span>
                          </label>
                          {active ? <b>YES</b> : <b>NO</b>}
                        </div>
                      </div> */}
                      </div>

                      <div className="form-group text-right">
                        <SaveButton isEdit={isEdit} />
                        <ClearButton
                          type="button"
                          onClick={() => handleClear()}
                          className="ml-2"
                        />
                      </div>
                    </div>

                    <div className="col-lg-4 col-md-12">
                      <PerfectScrollbar
                        style={{
                          height: "300px",
                          paddingRight: "16px",
                          boxSizing: "content-box",
                        }}
                        options={{ suppressScrollX: true }}
                      >
                        <div>
                          <table className="table table-sm table-hover table-bordered">
                            <thead className="thead-light">
                              <tr className="text-center">
                                <th>Sr No</th>
                                <th>Class Name</th>
                                <th>
                                  <input
                                    type="checkbox"
                                    checked={selectAllChecked}
                                    onChange={handleSelectAllClass}
                                    style={{ cursor: "pointer" }}
                                  />
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {classsectionList?.payload?.map((cl, index) => (
                                <tr
                                  className={`text-center ${
                                    selectedSectionId.includes(cl.id)
                                      ? "selected-class"
                                      : ""
                                  }`}
                                  key={index}
                                >
                                  <td>{index + 1}</td>
                                  <td className="text-left">
                                    {cl.class_name} {cl.section}
                                  </td>

                                  <td>
                                    <input
                                      type="checkbox"
                                      checked={
                                        selectedSectionId.includes(cl.id) ||
                                        false
                                      }
                                      onChange={() =>
                                        handleCheckboxChange(cl.id)
                                      }
                                      style={{ cursor: "pointer" }}
                                    />
                                  </td>
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      </PerfectScrollbar>
                    </div>
                  </div>
                </form>
              </div>
            </div>

            <div className="col-lg-12 col-md-12 col-sm-12">
              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">Notice List</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-hover table-vcenter table-striped mb-0 text-nowrap table-sm">
                      <thead>
                        <tr className="text-center">
                          <th>Sr No</th>
                          <th>Create Date</th>
                          <th>Title</th>
                          <th>Active Date</th>
                          <th>Expairy Date</th>
                          <th>Notice By</th>
                          <th>Is Active</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {circularData?.map((notice, index) => (
                          <tr key={index} className="text-center">
                            <td>{index + 1}</td>
                            <td>{formatDate(notice.create_date)}</td>
                            <td className="text-left text-wrap">
                              {notice.main_title}
                            </td>
                            <td>
                              {notice.from_date && formatDate(notice.from_date)}
                            </td>
                            <td>
                              {notice.to_date && formatDate(notice.to_date)}
                            </td>
                            <td className="text-left">{notice.circular_by}</td>
                            <td>{notice.is_active ? "Yes" : "No"}</td>
                            <td>
                              <NavLink
                                to="#"
                                className="card-options-remove mr-3"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                onClick={() => onDelete(notice.id)}
                              >
                                <i className="fa fa-trash-o text-danger"></i>
                              </NavLink>
                              <NavLink
                                className="text-muted"
                                to="#"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                                onClick={() => onEdit(notice.id)}
                              >
                                <i
                                  className="fa fa-edit"
                                  style={{ color: "green" }}
                                ></i>
                              </NavLink>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Circular;
