import React, { useEffect, useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import Pagination from "react-js-pagination";
const HostelReceiptList = (props) => {
  
  const { setSelectStudent, school_id, setReceiptType } = props;
  const [modal, setModal] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [receiptNo, setReceiptNo] = useState("");
  const [CurrentPage, setCurrentPage] = useState(1);
  const [Page, setPage] = useState(1);

  const resultPerPage = 10;
  const [studentList, setStudentList] = useState([]);
  const toggle = () => {
    setModal(!modal);
    setCurrentPage(1);
    setSearchQuery("");
    setReceiptNo("");
  };

  const handleSelectRoom = (data) => {
    setSelectStudent(data);
    setReceiptType("Edit");
    setModal(!modal);
  };

  const handleInputChangeSearch = (e) => {
    const query = e.target.value;
    setSearchQuery(query);
  };
  const handleReceiptChange = (e) => {
    const query = e.target.value;
    setReceiptNo(query);
  };

  const fetchHostelReceiptList = async () => {
    try {
      let fData = {
        school_id: school_id,
        receipt_no: receiptNo,
        student_name: searchQuery,
      };
      const { data } = await HostelAPI.getHostelPayment(fData, CurrentPage);
      if (data.status === 200) {
        setStudentList(data.payment_details);
        setPage(data.payment_count);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleSearch = () => {
    fetchHostelReceiptList();
    setSearchQuery("");
    setReceiptNo("");
  };
  useEffect(() => {
    fetchHostelReceiptList();

  }, [CurrentPage]);
  const closeBtn = (
    <button className="btn btn-sm close " onClick={toggle} type="button">
      <i className="fa fa-times" style={{ color: "red" }}></i>
    </button>
  );
  return (
    <>
      <button
        className="btn btn-outline-secondary btn-sm mx-1"
        data-toggle="tooltip"
        data-placement="top"
        title="View Student List"
        type="button"
        onClick={() => {
          setSelectStudent("");
          toggle();
          handleSearch();
        }}
      >
        <i className="fa fa-search"> </i> Search
      </button>
      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box" backdrop="static" keyboard={false}>
        <ModalHeader toggle={toggle} close={closeBtn}>
          <div className="row">
            <div className="col-md-5 my-1">
              <input 
                name="code"
                type="number"
                className="form-control"
                placeholder="Receipt No"
                value={receiptNo}
                onChange={handleReceiptChange} 
                autoComplete="off"
              />
            </div>
            <div className="col-md-5 my-1">
              <input
                type="text"
                className="form-control"
                name="name"
                placeholder="Student Name  "
                value={searchQuery}
                onChange={handleInputChangeSearch}
                autoComplete="off"
              />
            </div>
            <div className="col-md-2 my-1">
              <button
                type="submit"
                className="btn btn-primary"
                onClick={handleSearch}
              >
                Search
              </button>
            </div>
          </div>
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            {studentList && studentList?.length > 0 ? (
              <Table size="sm" className="table-bordered" striped>
                <thead className="table-dark">
                  <tr className="text-center text-nowrap ">
                    <th>Sr No</th>
                    <th>Receipt_no</th>
                    <th>Student Name</th>
                    <th>Student Type</th>
                    <th>Payment mode</th>
                    <th>Payment status</th>
                    <th>Total paid fees</th>
                  </tr>
                </thead>
                <tbody>
                  {studentList?.map((room, index) => (
                    <tr
                      key={index}
                      className="clickable-row-color text-center"
                      onClick={() => handleSelectRoom(room)}
                    >
                      <td className="text-center">
                        {(CurrentPage - 1) * 10 + index + 1}
                      </td>
                      <td>{room.receipt_no}</td>
                      <td className="text-left">
                        {room.student_name
                          ? room.student_name
                          : room.fk_student__fk_student__std_name}
                      </td>
                      <td>{room.student_type}</td>
                      <td>{room.payment_mode}</td>
                      <td>{room.payment_status}</td>
                      <td className="text-right">{room.paid_amount}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyListMessage message={"Student Not Available !"} Div="div" />
            )}
          </div>
        </ModalBody>
        <ul className="pagination justify-content-center">
          {
            <Pagination
              activePage={CurrentPage}
              itemsCountPerPage={resultPerPage}
              totalItemsCount={Page}
              // totalItemsCount={
              //   filteredProducts && productCount ? productCount : 0
              // }
              hideFirstLastPages={false}
              onChange={(e) => setCurrentPage(e)}
              nextPageText="Next"
              prevPageText="Previous"
              lastPageText="Last"
              firstPageText="1st"
              itemClass="page-item"
              linkClass="page-link"
              activeClass="active"
              activeLinkClass="active"
              hideDisabled={false}
            />
          }
        </ul>
      </Modal>
    </>
  );
};

export default HostelReceiptList;
