import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";
import {
  CREATE_FEEDISCOUNT_FAILURE,
  CREATE_FEEDISCOUNT_REQUEST,
  CREATE_FEEDISCOUNT_SUCCESS,
} from "../../Constants/school_user/FeeDiscount";

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const updateFeeDiscount = (body) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_FEEDISCOUNT_REQUEST });
    const response = await axios.post(
      `${BASE_URL}update_fee_discount/`,
      body,
      config
    );
    const data = response.data;
    dispatch({ type: CREATE_FEEDISCOUNT_SUCCESS, payload: data });
    return data
  } catch (error) {
    dispatch({ type: CREATE_FEEDISCOUNT_FAILURE, payload: error });
  }
};
