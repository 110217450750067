import React from "react";
import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Label,
  Row,
  Table,
} from "reactstrap";
import { SessionContext } from "../../../../context/sessionContext";
import { useContext } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import Select from "react-select";
import {
  ShowTestQuiz,
  deleteTest,
  reSetReducerState,
} from "../../../../actions/MockTest/MockTest";
import {
  getChaptertList,
  getClassWiseSubjectList,
} from "../../../../actions/school_user/contentUploader";
import Swal from "sweetalert2";
import { Heading } from "../../../CommonComponent/heading";

const TestPage = () => {
  const token = localStorage.getItem("token");

  const { Session } = useContext(SessionContext);
  let session_id = Session || sessionStorage.getItem("SessionId");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id } = school_user?.user_detail;
  const dispatch = useDispatch();
  const { subject, chapter, contentData } = useSelector(
    (state) => state.contentUploader
  );
  const { allClass } = useSelector((state) => state.getClass);
  const { showTest } = useSelector((state) => state.ShowTestQuiz);
  const { deleteTestQuestion } = useSelector((state) => state.deleteQuestion);

  const [selectClass, setSelectClass] = useState(null);
  const [selectSubject, setSelectSubject] = useState(null);
  const [selectChapter, setSelectChapter] = useState(null);
  const [subjectList, setSubjectList] = useState([]);
  const [chapterList, setChapterList] = useState([]);

  let cl_subId = {
    academic_id: session_id,
    school_id: school_id,
    class_id: selectClass,
    employee_id: school_emp_id,
  };

  useEffect(() => {
    if (token && selectClass) {
      dispatch(getClassWiseSubjectList(token, cl_subId));
    }
  }, [dispatch, token, selectClass]);

  useEffect(() => {
    let List = subject?.payload?.map((c) => {
      return {
        label: `${c.subject_name}`,
        value: c.id,
      };
    });
    setSubjectList(List);
  }, [subject]);

  useEffect(() => {
    let List = chapter?.payload?.map((c) => {
      return {
        label: `${c.name}`,
        value: c.id,
      };
    });
    setChapterList(List);
  }, [chapter]);

  let cdata = {
    school_id: school_id,
    class_id: selectClass,
    subject_id: selectSubject,
  };
  useEffect(() => {
    if (selectSubject) {
      dispatch(getChaptertList(cdata));
    }
  }, [selectSubject]);

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

  let handleChangeClass = (select) => {
    setSelectClass(select.value);
    setSelectSubject(null);
    setSelectChapter(null);
  };

  useEffect(() => {
    dispatch(reSetReducerState());
  }, []);

  const GetAllTest = async () => {
    const body = {
      academic_id: session_id,
      school_id: school_id,
      employee_id: school_emp_id,
      class_id: selectClass,
      subject_id: selectSubject,
      chapter_id: selectChapter,
      test_type: "Mock",
    };

    try {
      dispatch(ShowTestQuiz(body));
    } catch (error) {
      console.log(error);
    }
  };
  useEffect(() => {
    GetAllTest();
  }, [selectChapter, selectClass, selectSubject]);

  useEffect(() => {
    if (session_id) {
      GetAllTest();
    }
  }, [session_id]);

  useEffect(() => {
    GetAllTest();
  }, [deleteTestQuestion]);

  const handleResetFilter = () => {
    setSelectClass(0);
    setSelectSubject(0);
    setSelectChapter(0);
    GetAllTest();
  };

  const onTestDelete = (id) => {
    let body = {
      test_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "Do you want to delete Mock Test ?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
    }).then((result) => {
      if (result.isConfirmed) {
        dispatch(deleteTest(body)).then((res) => {
          Swal.fire("Deleted!", "Deleted success", "success");
          GetAllTest();
        });
      }
    });
  };

  function formatDate(dateString) {
    const dateObj = new Date(dateString);
    const day = dateObj.getDate();
    const month = dateObj.getMonth() + 1; // add 1 because getMonth() returns a zero-based index
    const year = dateObj.getFullYear();

    // pad single digit numbers with a leading zero
    const formattedDay = day.toString().padStart(2, "0");
    const formattedMonth = month.toString().padStart(2, "0");

    // return the formatted date string
    return `${formattedDay}-${formattedMonth}-${year}`;
  }

  function convertTime(timestamp) {
    const date = new Date(timestamp);
    const hours = date.getHours();
    const minutes = date.getMinutes();
    const time = `${hours % 12 || 12}:${minutes < 10 ? "0" : ""}${minutes} ${
      hours >= 12 ? "PM" : "AM"
    }`;

    return time;
  }

  return (
    <Container fluid={true}>
      <Heading pageTitle={"Mock Test Quiz"}>
        <Link className="btn btn-primary me-1" to="/school/mock-test-create">
          <i className="fa fa-plus-square pr-1"></i> Create Mock Test
        </Link>
        <button className="btn btn-danger ml-1" onClick={handleResetFilter}>
          Reset Filter
        </button>
      </Heading>
      
      <Card className="mt-4">
        <CardBody>
          <Row className="">
            <Col lg={4} md={6} sm={12}>
              <FormGroup row>
                <Label
                  className="form-check-label"
                  htmlFor="inlineFormCheck"
                  sm={4}
                >
                  Class
                </Label>

                <Col sm={8}>
                  <Select
                    name="class_id"
                    placeholder="Select Class"
                    options={classList}
                    value={
                      selectClass
                        ? classList?.find((c) => c.value === selectClass)
                        : null
                    }
                    onChange={handleChangeClass}
                    isSearchable={true}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <FormGroup row>
                <Label
                  className="form-check-label"
                  htmlFor="inlineFormCheck"
                  sm={4}
                >
                  Subject
                </Label>

                <Col sm={8}>
                  <Select
                    placeholder="Select Subject"
                    options={subjectList}
                    value={
                      selectSubject
                        ? subjectList.find(
                            (option) => option.value === selectSubject
                          )
                        : null
                    }
                    onChange={(select) => setSelectSubject(select.value)}
                    isSearchable={true}
                  />
                </Col>
              </FormGroup>
            </Col>
            <Col lg={4} md={6} sm={12}>
              <FormGroup row>
                <Label
                  className="form-check-label"
                  htmlFor="inlineFormCheck"
                  sm={4}
                >
                  Chapter
                </Label>

                <Col sm={8}>
                  <Select
                    placeholder="Enter Chapter Name"
                    options={chapterList}
                    value={
                      selectChapter
                        ? chapterList.find(
                            (option) => option.value === selectChapter
                          )
                        : null
                    }
                    onChange={(select) => setSelectChapter(select.value)}
                    isSearchable={true}
                  />
                </Col>
              </FormGroup>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
          <CardTitle className="fs-6">Active Test</CardTitle>
          <Row className="border-rounded">
            <Col xl={12}>
              <div className="table-responsive">
                <Table className="table table-striped" id="table-to-xls">
                  <thead>
                    <tr className="text-nowrap">
                      <th>Test Name</th>
                      <th>Class</th>
                      <th>Subject</th>
                      <th>Chapter</th>
                      <th>No. of Ques.</th>
                      <th>Action</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showTest?.mock_test_list
                      ?.filter((activetest) => activetest.is_active === true)
                      .map((test) => (
                        <tr key={test.id}>
                          <td className="text-start font-weight-bold">
                            {test.test_name}
                          </td>
                          <td>{test.class_name?.toUpperCase()}</td>
                          <td>{test.subject_name}</td>
                          <td>{test.chapter_name}</td>
                          <td>{test.no_of_question} Ques.</td>

                          <td>
                            <div className="d-flex gap-3">
                              <Link
                                to={`/school/mock-test-edit`}
                                state={{ test: test }}
                                className="text-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                              >
                                <i className="fa fa-pencil text-success mx-2 text-center"></i>
                              </Link>
                              <Link
                                to="#"
                                className="text-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                onClick={() => onTestDelete(test.id)}
                              >
                                <i
                                  className="fa fa-trash font-size-18 mx-2"
                                  id="deletetooltip"
                                />
                              </Link>
                            </div>{" "}
                          </td>
                          <td>
                            <div className="square-switch d-flex">
                              {/* <input
                                            type="checkbox"
                                            id="square-switch1"
                                            className="switch"
                                            // defaultChecked={TestActive}
                                            checked={test.is_active}
                                            onChange={() => {
                                              ActiveInactive(
                                                !test.is_active,
                                                test.id
                                              )
                                              setTestActive(!test.is_active)
                                            }}
                                          /> 
                                          <label
                                            htmlFor="square-switch1"
                                            data-on-label="*"
                                            data-off-label="x"
                                          /> */}
                              <h6
                                className={`text-center ${
                                  test.is_active
                                    ? "text-success"
                                    : "text-danger"
                                } `}
                              >
                                {test.is_active ? "Active" : "Inactive"}
                              </h6>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>
        <CardBody>
          <CardTitle className="fs-3">In-Active Test</CardTitle>
          <Row className="border-rounded">
            <Col xl={12}>
              <div className="table-responsive">
                <Table className="table table-striped" id="table-to-xls">
                  <thead>
                    <tr className="text-nowrap">
                      <th>Test Name</th>
                      <th>Class</th>
                      <th>Subject</th>
                      <th>Chapter</th>
                      <th>No. of Ques.</th>
                      <th>Action</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {showTest?.mock_test_list
                      ?.filter((activetest) => activetest.is_active === false)
                      .map((test) => (
                        <tr key={test.id}>
                          <td className="text-start font-weight-bold">
                            {test.test_name}
                          </td>

                          <td>{test.class_name?.toUpperCase()}</td>
                          <td>{test.subject_name}</td>
                          <td>{test.chapter_name}</td>

                          <td>{test.no_of_question} Ques.</td>

                          <td>
                            <div className="d-flex gap-3">
                              <Link
                                to={`/school/mock-test-edit`}
                                state={{ test: test }}
                                className="text-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                              >
                                <i className="fa fa-pencil text-success mx-2 text-center"></i>
                              </Link>
                              <Link
                                to="#"
                                className="text-danger"
                                onClick={() => onTestDelete(test.id)}
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                              >
                                <i
                                  className="fa fa-trash mx-2 text-center"
                                  id="deletetooltip"
                                />
                              </Link>
                            </div>{" "}
                          </td>
                          <td>
                            <div className="square-switch d-flex">
                              {/* <input
                                              type="checkbox"
                                              id="square-switch1"
                                              className="switch"
                                              // defaultChecked={TestActive}
                                              checked={test.is_active}
                                              onChange={() => {
                                                ActiveInactive(
                                                  !test.is_active,
                                                  test.id
                                                )
                                                setTestActive(!test.is_active)
                                              }}
                                            /> 
                                            <label
                                              htmlFor="square-switch1"
                                              data-on-label="*"
                                              data-off-label="x"
                                            /> */}
                              <h6
                                className={`text-center ${
                                  test.is_active
                                    ? "text-success"
                                    : "text-danger"
                                } `}
                              >
                                {test.is_active ? "Active" : "Inactive"}
                              </h6>
                            </div>
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </Table>
              </div>
            </Col>
          </Row>
        </CardBody>

        <CardBody>
          <Row className="border-rounded-10 d-none">
            <Col lg={12}>
              <CardBody>
                <CardTitle className="fs-6">Past Test</CardTitle>
                <div className="table-responsive">
                  <Table className="table table-striped" id="table-to-xls">
                    <thead>
                      <tr>
                        <th>Test Name</th>
                        <th>Class</th>
                        <th>Subject</th>
                        <th>Chapter</th>
                        <th>Date</th>
                        <th>Time Between</th>
                        <th>No. of Ques.</th>
                        <th>Duration</th>
                        <th>Action</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {showTest?.upcoming_data?.map((test) => (
                        <tr key={test.id}>
                          <td className="text-start font-weight-bold">
                            {/* <Link to={`/edittest/${test.id}`}> */}
                            <Link to={`/test-result/${test.id}`}>
                              {test.test_name}
                            </Link>
                          </td>
                          <td>{test.class_name?.toUpperCase()}</td>
                          <td>{test.subject_name}</td>
                          <td>{test.chapter_name}</td>
                          <td> {formatDate(test.start_datetime)}</td>
                          <td>
                            {convertTime(test.start_datetime)}
                            {" To "}
                            {convertTime(test.end_datetime)}
                          </td>
                          <td>{test.no_of_question} Ques.</td>
                          <td>{test.duration} Min</td>
                          <td>
                            <div className="d-flex gap-3">
                              <Link
                                to={`/edittest/${test.id}`}
                                className="text-success"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Edit"
                              >
                                <i className="mdi mdi-pencil text-success font-size-18"></i>
                              </Link>
                              <Link
                                to="#"
                                className="text-danger"
                                data-toggle="tooltip"
                                data-placement="top"
                                title="Delete"
                                onClick={() => onTestDelete(test.id)}
                              >
                                <i
                                  className="fa fa-trash font-size-18"
                                  id="deletetooltip"
                                />
                              </Link>
                            </div>
                          </td>
                          <td>
                            <h6
                              className={` ${
                                test.is_active ? "text-success" : "text-danger"
                              } text-start`}
                            >
                              {test.is_active ? "Active" : "Inactive"}
                            </h6>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                </div>
              </CardBody>
            </Col>
          </Row>
        </CardBody>
      </Card>
    </Container>
  );
};

export default TestPage;
