import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};
export const ExamsAPI = {

  createExam: async (data) => {
    const axiosConfig = createAxiosConfig("add_updateExam", "POST", data);
    return await axios(axiosConfig);
  },
  getExam: async (data) => {
    const axiosConfig = createAxiosConfig("get_Exam", "POST", data);
    return await axios(axiosConfig);
  },


  getStudentResultReport: async (data) => {
    const axiosConfig = createAxiosConfig("student_result_reportapi", "POST", data);
    return await axios(axiosConfig);
  },

  
  getAssessment: async (data) => {
    const axiosConfig = createAxiosConfig("get_Assessment", "POST", data);
    return await axios(axiosConfig);
  },

  deleteExam: async (data) => {
    const axiosConfig = createAxiosConfig("delete_Exam", "POST", data);
    return await axios(axiosConfig);
  },
  createAssessment: async (data) => {
    const axiosConfig = createAxiosConfig("add_updateAssessment", "POST", data);
    return await axios(axiosConfig);
  },
  getAssessment: async (data) => {
    const axiosConfig = createAxiosConfig("get_Assessment", "POST", data);
    return await axios(axiosConfig);
  },

  deleteAssessment: async (data) => {
    const axiosConfig = createAxiosConfig("delete_Assessment", "POST", data);
    return await axios(axiosConfig);
  },

  getExamWiseSubject: async (data) => {
    const axiosConfig = createAxiosConfig("get_ExamWiseSubject", "POST", data);
    return await axios(axiosConfig);
  },
  createExamWiseSubject: async (data) => {
    const axiosConfig = createAxiosConfig(
      "add_updateExamWiseSubject",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },

  deleteExamWiseSubject: async (data) => {
    const axiosConfig = createAxiosConfig(
      "delete_ExamWiseSubject",
      "POST",
      data
    );
    return await axios(axiosConfig);
  },
};
