import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
import Swal from "sweetalert2";
import {
  ADD_CHAPTER_FAILURE,
  ADD_CHAPTER_REQUEST,
  ADD_CHAPTER_SUCCESS,
  ADD_CONTENT_FAILURE,
  ADD_CONTENT_REQUEST,
  ADD_CONTENT_SUCCESS,
  DELETE_CONTENT_FAILURE,
  DELETE_CONTENT_REQUEST,
  DELETE_CONTENT_SUCCESS,
  GET_CHAPTER_FAILURE,
  GET_CHAPTER_REQUEST,
  GET_CHAPTER_SUCCESS,
  GET_CLASS_WISE_SUBJECT_FAILURE,
  GET_CLASS_WISE_SUBJECT_REQUEST,
  GET_CLASS_WISE_SUBJECT_SUCCESS,
  GET_CONTENT_FAILURE,
  GET_CONTENT_REQUEST,
  GET_CONTENT_SUCCESS,
  UPDATE_CONTENT_FAILURE,
  UPDATE_CONTENT_REQUEST,
  UPDATE_CONTENT_SUCCESS,
} from "../../Constants/school_user/contentUploader";

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const getClassWiseSubjectList = (token, classSubject) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CLASS_WISE_SUBJECT_REQUEST });
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json",
        },
      };
      const response = await axios.post(
        `${BASE_URL}get_class_subject_api/`,
        classSubject,
        config
      );
      const data = response.data;
      dispatch({ type: GET_CLASS_WISE_SUBJECT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_CLASS_WISE_SUBJECT_FAILURE,
        payload: error,
      });
    }
  };
};
export const getChaptertList = (cdata) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CHAPTER_REQUEST });
      const response = await axios.post(
        `${BASE_URL}get_chapter_api/`,
        cdata,
        config
      );
      const data = response.data;
      dispatch({ type: GET_CHAPTER_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_CHAPTER_FAILURE,
        payload: error,
      });
    }
  };
};
export const addContent = (cdata, onUploadProgress) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_CONTENT_REQUEST });

      const response = await axios.post(
        `${BASE_URL}Add_subjectContent/`,
        cdata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: onUploadProgress,
        }
      );
      const data = response.data;
      dispatch({ type: ADD_CONTENT_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: ADD_CONTENT_FAILURE,
        payload: error,
      });
      throw error;
    }
  };
};
export const addChapter = (cdata) => {
  return async (dispatch) => {
    try {
      dispatch({ type: ADD_CHAPTER_REQUEST });
      const response = await axios.post(
        `${BASE_URL}add_chapter_api/`,
        cdata,
        config
      );
      const data = response.data;
      dispatch({ type: ADD_CHAPTER_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: ADD_CHAPTER_FAILURE,
        payload: error,
      });
      throw error;
    }
  };
};

export const getContentList = (cdata) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_CONTENT_REQUEST });
      const response = await axios.post(
        `${BASE_URL}get_subject_content_api/`,
        cdata,
        config
      );
      const data = response.data;
      dispatch({ type: GET_CONTENT_SUCCESS, payload: data });
    } catch (error) {
      dispatch({
        type: GET_CONTENT_FAILURE,
        payload: error,
      });
    }
  };
};
export const deleteContent = (cdata) => {
  return async (dispatch) => {
    try {
      dispatch({ type: DELETE_CONTENT_REQUEST });
      const response = await axios.post(
        `${BASE_URL}delete_subjectContent/`,
        cdata,
        config
      );
      const data = response.data;
      dispatch({ type: DELETE_CONTENT_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: DELETE_CONTENT_FAILURE,
        payload: error,
      });
    }
  };
};

export const updateContent = (cdata, onUploadProgress) => {
  return async (dispatch) => {
    try {
      dispatch({ type: UPDATE_CONTENT_REQUEST });

      const response = await axios.post(
        `${BASE_URL}update_subjectContent/`,
        cdata,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: onUploadProgress,
        }
      );
      const data = response.data;
      dispatch({ type: UPDATE_CONTENT_SUCCESS, payload: data });
      return data;
    } catch (error) {
      dispatch({
        type: UPDATE_CONTENT_FAILURE,
        payload: error,
      });
      throw error;
    }
  };
};