import { LOGIN_SUCCESS, LOGIN_FAILURE } from "../../Constants/AuthConstant";

const initialState = {
  token: localStorage.getItem("token") || null,
  userType: JSON.parse(localStorage.getItem("User")) || null,
  error: null,
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_SUCCESS:
      return {
        ...state,
        token: action.payload.token,
        userType: action.payload.userType,
        error: null,
      };
    case LOGIN_FAILURE:
      return {
        ...state,
        error: action.payload,
      };
    default:
      return state;
  }
};

export default authReducer;

// import {
//   CLEAR_ERRORS,
//   LOGIN_FAILD,
//   LOGIN_REQUIST,
//   LOGIN_SUCCESS,
// } from "../Constants/UserConstant";

// const initialState = {
//   user: [],
//   loading: false,
// };

// export const userReducer = (state = initialState, action) => {
//   switch (action.type) {
//     case LOGIN_REQUIST:
//       return {
//         loading: true,
//         // isAuth: false,
//       };

//     case LOGIN_SUCCESS:
//       return {
//         ...state,
//         loading: false,
//         // isAuth: true,
//         user: action.payload,
//       };

//     case LOGIN_FAILD:
//       return {
//         ...state,
//         loading: false,
//         // isAuth: false,
//         user: null,
//         error: action.payload,
//       };

//     case CLEAR_ERRORS:
//       return {
//         ...state,
//         error: null,
//       };

//     default:
//       return state;
//   }
// };
