import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";
import { SHOW_ONLINETEST_RESULT_FAIELD, SHOW_ONLINETEST_RESULT_REQUIST, SHOW_ONLINETEST_RESULT_SUCCESS } from "../../Constants/MockTest/OnlineTest";
const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const ShowOnlineTestResult = (body) => async (dispatch) => {
    try {
      dispatch({ type: SHOW_ONLINETEST_RESULT_REQUIST });
      const response = await axios.post(
        `${BASE_URL}student_OnlineTestResultWeb/`,
        body,
        config
      );
      const data = response.data;
      dispatch({ type: SHOW_ONLINETEST_RESULT_SUCCESS, payload: data });
      return data
    } catch (error) {
      dispatch({ type: SHOW_ONLINETEST_RESULT_FAIELD, payload: error });
    }
  };