import React from 'react'

const AboutUs = () => {
  return (
    <div className='p-5'>
     <h5><b>About FiPal Technologies LLP</b></h5>
<p>At FiPal Technologies LLP, we are passionate about leveraging technology to transform education and streamline school management processes. With a dedicated team of software developers, we specialize in developing innovative products and providing cutting-edge services in the realm of software and software-enabled applications.
</p>
<h5><b>Our Flagship Product: VidyaMate - Your All-in-One ERP Solution for Schools and Colleges</b></h5>
<p>Our flagship product, VidyaMate, is an all-in-one ERP (Enterprise Resource Planning) software designed to revolutionize school and college administration. Built with advanced features and intuitive interfaces, VidyaMate empowers educational institutions to efficiently manage all aspects of their operations.
</p>
<h5><b>Comprehensive Solutions</b></h5>



<p>VidyaMate offers a comprehensive suite of tools and functionalities to meet the diverse needs of educational institutions. From student management and academic planning to financial management and communication, VidyaMate provides a holistic solution to streamline administrative tasks and enhance productivity.
</p>
<h5><b>Accessible Anytime, Anywhere</b></h5>


<p>VidyaMate is accessible anytime, anywhere, through its web-based platform and mobile applications. Whether on campus or on the go, administrators, teachers, students, and parents can seamlessly access relevant information and perform tasks with ease.
</p>

<h5><b>Our Journey</b></h5>

<p>Our journey began two years ago, driven by a vision to enhance the educational landscape through technology. Over this period, our team of five dedicated software developers worked tirelessly to conceptualize, design, and develop VidyaMate. Through meticulous planning, rigorous testing, and continuous refinement, we transformed our vision into a comprehensive ERP solution for schools and colleges.
</p>
<h5><b>Commitment to Excellence</b></h5>


<p>At FiPal Technologies LLP, we are committed to excellence in everything we do. We strive to deliver superior products and services that exceed the expectations of our clients. Our relentless pursuit of innovation, coupled with our unwavering dedication to customer satisfaction, sets us apart in the industry.
</p>
<h5><b>Global Reach</b></h5>

<p>While headquartered in India, our reach extends beyond borders, serving clients both in India and overseas. We take pride in our ability to adapt to diverse market needs and provide customized solutions tailored to the unique requirements of each client.
</p>
<h5><b>Partner with Us</b></h5>

<p>Whether you are a school seeking to streamline your administrative processes or a college looking to optimize operations, we invite you to partner with us. Together, we can embark on a journey of digital transformation, empowering educators, students, and parents alike.
</p>
<h5><b>Contact Us</b></h5>

<p>Ready to embark on your digital journey with VidyaMate? Get in touch with us today to learn more about our products and services. Let us be your trusted partner in advancing education through technology.
</p>
<p>FiPal Technologies LLP - Transforming Education, Empowering Minds.
</p>
    </div>
  )
}

export default AboutUs
