import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");


const createAxiosConfig=(url,method,data)=>{
    return{
        method,
        url:`${BASE_URL}${url}/`,
        headers:{
            Authorization:`Bearer ${token}`,
            "Content-Type":"application/json",
        },data,
    };

}
export const categoryMasterAPI={
    createCategory:async(data)=>{
        const axiosConfig=createAxiosConfig(
            "add_updateExaminationCategory",
            "POST",data
        );
        return await axios(axiosConfig)
    },
    getCategory:async(data)=>{
        const axiosConfig=createAxiosConfig(
            "get_ExaminationCategory",
            "POST",data
        );
        return await axios(axiosConfig)
    },
    getGroupList:async(data)=>{
        const axiosConfig=createAxiosConfig(
            "get_GroupList",
            "POST",data
        );
        return await axios(axiosConfig)
    },
    getSubjectList:async(data)=>{
        const axiosConfig=createAxiosConfig(
            "get_SubjectList",
            "POST",data
        );
        return await axios(axiosConfig)
    },
    getCategoryData:async(data)=>{
        const axiosConfig=createAxiosConfig(
            "get_ExaminatinCategoryData",
            "POST",data
        );
        return await axios(axiosConfig)
    },
    deleteCategory:async(data)=>{
        const axiosConfig=createAxiosConfig(
            "delete_ExaminationCategory",
            "POST",data
        );
        return await axios(axiosConfig)
    }
}

