import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import useLocalStorageData from "../../Comman/LocalStorageData";
import useSessionRedirect from "../../Comman/SessionChange";
import useLoading from "../../../CommonComponent/useLoading";
import { categoryMasterAPI } from "../../../../api/Examination/Category";
import { useFormik } from "formik";
import * as Yup from "yup";
import Loader from "../../../Loader/Loader";
import { Button, Table } from "reactstrap";
import { gradeMasterAPI } from "../../../../api/Examination/Grade";

const GradeMaster = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [isEdit, setIsEdit] = useState(false);
  const [groupList, setGroupList] = useState([]);
  const [gradeList, setGradeList] = useState([]);
  const [selectedGroup, setSelectedGroup] = useState([]);
  const [grade_type, setGrade_type] = useState("");
  let [validationError, setValidationError] = useState(false);
  const initialData = {
    id: "",
    grade: "",
    min_marks: "",
    max_marks: "",
  };
  const [tableData, setTableData] = useState([]);

  const fetchGroup = async () => {
    let body = {
      school_id: school_id,
      academic_id: session_id,
    };
    try {
      let response = await categoryMasterAPI.getGroupList(body);
      let responseData = response.data;
      // console.log(responseData);
      if (responseData.status === 200) {
        let list = responseData?.GroupList?.map((c) => ({
          label: `${c.group_name}`,
          value: c.id,
        }));
        setGroupList(list);
      }
    } catch (error) {
      console.error(error);
    }
  };
  // console.log(groupList);

  useEffect(() => {
    fetchGroup();
  }, [session_id, school_id]);

  //this is static data
  const groupList1 = ["GRADE", "MARK"];
  //this is static data
  const handleClear = () => {
    formik.resetForm();
    setGradeList([]);
    setTableData([]);
    setIsEdit(false);
    setSelectedGroup([])

  };
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      insert_by: school_emp_id,
      group_id: "",
      grade_data: [],
      grade_type: "",
    },
    validationSchema: Yup.object().shape({
      group_id: Yup.string().required("This field is required."),
      grade_type:Yup.string().required("This field is required."),
    }),

    onSubmit: async (values) => {
      let grade_list = tableData.filter((g) => g.grade !== "");
      startLoading();
      try {
        const data = new FormData();
        data.append("academic_id", values.academic_id);
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("group_id", values.group_id);
        data.append("grade_data", JSON.stringify(grade_list));
        data.append("grade_type", values.grade_type);
        const response = await gradeMasterAPI.createGradeMasterData(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        stopLoading();
      }
    },
  });
  let validationControl = async () => {
    let Validationstatus = true;
    if (!formik.values.group_id) {
      Validationstatus = false;
    }
    if(!formik.values.grade_type){
      Validationstatus = false
    }
    if (!Validationstatus) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 5000);
    }
    return Validationstatus;
  };

  const fetchGrade = async () => {
    let isValid = await validationControl();
    if (!isValid) return;
    startLoading();
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
        group_id: formik.values.group_id,
        grade_type: formik.values.grade_type,
      };
      let response = await gradeMasterAPI.getGradeMasterData(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        let gradeList = responseData.grade_data[0];
        if (responseData.grade_data.length > 0) {
          let list = gradeList.grade?.map((g) => ({
            id: g.id,
            grade: g.grade,
            min_marks: g.min_percent,
            max_marks: g.max_percent,
          }));
          setGradeList(gradeList);
          setTableData([...list, initialData]);
          setIsEdit(true);
        } else {
          setIsEdit(false);
          setTableData([initialData]);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };

  const handleInputChange = (e, rowIndex, propertyName) => {
    const updatedData = [...tableData];
    updatedData[rowIndex][propertyName] = e.target.value;
    setTableData(updatedData);
  };
  const handleSelectGroup1 = (selectedOption) => {
    setSelectedGroup(selectedOption);
    formik.setFieldValue("grade_type", selectedOption?.value || "");
  };
  const addRow = () => {
    const newRow = {
      id: "",
      grade: "",
      min_marks: "",
      max_marks: "",
    };
    setTableData([...tableData, newRow]);
  };
  const deleteRow = (indexToDelete) => {
    const updatedTableData = tableData.filter(
      (_, index) => index !== indexToDelete
    );
    setTableData(updatedTableData);
  };
  const handleSelectGroup = (selectedOptions) => {
    formik.setFieldValue("group_id", selectedOptions?.value);
    setGradeList([]);
    setTableData([]);
  };
  if (isLoading) return <Loader />;

  return (
    <div className="section-body">
      <div className="container-fluid">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Grade Master</h1>
            </div>
            <div className=" mt-2">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mx-1"
                onClick={() => fetchGrade()}
              >
                <i className="fa fa-eye  px-1"></i>
                View
              </button>
              <button
                className="btn btn-primary btn-sm mx-1"
                type="submit"
                disabled={tableData?.length > 0 ? false : true}
              >
                <i className="fa fa-plus px-1"></i>
                {isEdit ? "Update" : "Save"}
              </button>

              <button
                type="button"
                className="btn btn-outline-danger btn-sm mx-1"
                onClick={() => handleClear()}
              >
                <i className="fa fa-times px-1"></i>
                Clear
              </button>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="card">
              <div className="card-body row">
                <div className="form-group col-lg-6 col-md-12 row">
                  <label className="col-md-4 col-form-label">
                    Group Name <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-8">
                    <Select
                      name="group_id"
                      placeholder="Select Group Name"
                      options={groupList}
                      value={
                        formik.values.group_id
                          ? groupList?.find(
                              (g) => g.value === formik.values.group_id
                            )
                          : null
                      }
                      onChange={(selectedOptions) =>
                        handleSelectGroup(selectedOptions)
                      }
                      onBlur={formik.handleBlur}
                      isSearchable={true}
                    />
                    {formik.touched.group_id && formik.errors.group_id ? (
                      <ErrorDisplay errorMsg={formik.errors.group_id} />
                    ) : null}
                    {validationError && !formik.values.group_id ? (
                      <ErrorDisplay errorMsg={"Group is required"} />
                    ) : null}
                  </div>
                </div>
                <div className="form-group col-lg-6 col-md-12 row">
                  <label className="col-md-3 col-form-label ml-2">
                    Grade Type <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-8">
                    <Select
                      name="group_id"
                      placeholder="Select Group Name"
                      options={groupList1.map((option) => ({
                        label: option,
                        value: option,
                      }))}
                      value={selectedGroup}
                      onChange={(selectedOptions) =>
                        handleSelectGroup1(selectedOptions)
                      }
                      isSearchable={true}
                    />
                    {formik.touched.grade_type && formik.errors.grade_type ? (
                      <ErrorDisplay errorMsg={formik.errors.grade_type} />
                    ) : null}
                    {validationError && !formik.values.grade_type ? (
                      <ErrorDisplay errorMsg={"Grade type is required"} />
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="card">
            <div className="card-body">
              <div className="mt-4 table-responsive">
                <Table
                  size="sm"
                  className="table-bordered table-vcenter table-striped text-nowrap"
                  striped
                >
                  <thead className="">
                    <tr className="text-center text-nowrap ">
                      <th>Grade</th>
                      <th>Min Marks</th>
                      <th>Max Marks</th>
                    </tr>
                  </thead>
                  <tbody>
                    {tableData?.map((rowData, rowIndex) => (
                      <tr key={rowIndex}>
                        <td className="align-middle">
                          <input
                            name={`grade-${rowIndex}`}
                            type="text"
                            placeholder="Grade"
                            className="form-control  border"
                            value={rowData.grade}
                            onChange={(e) =>
                              handleInputChange(e, rowIndex, "grade")
                            }
                            autoComplete="off"
                            maxLength={2}
                          />
                        </td>
                        <td className="align-middle">
                          <input
                            name={`min_marks-${rowIndex}`}
                            type="number"
                            placeholder="Min Marks"
                            className="form-control  border"
                            value={rowData.min_marks}
                            onChange={(e) =>
                              handleInputChange(e, rowIndex, "min_marks")
                            }
                            autoComplete="off"
                            maxLength={3}
                          />
                        </td>
                        <td className="align-middle">
                          <input
                            name={`max_marks-${rowIndex}`}
                            type="number"
                            placeholder="Max Marks"
                            className="form-control  border"
                            value={rowData.max_marks}
                            onChange={(e) =>
                              handleInputChange(e, rowIndex, "max_marks")
                            }
                            autoComplete="off"
                            maxLength={3}
                          />
                        </td>

                        <td className="text-center">
                          {rowIndex === tableData?.length - 1 && (
                            <Button
                              className="btn btn-primary btn-sm mx-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Add Row"
                              onClick={addRow}
                              type="button"
                            >
                              <i className="fa fa-plus"></i>
                            </Button>
                          )}
                          {rowIndex !== tableData?.length - 1 && (
                            <button
                              type="button"
                              className="btn btn-outline-danger btn-sm mx-1"
                              data-toggle="tooltip"
                              data-placement="top"
                              title="Delete"
                              onClick={() => deleteRow(rowIndex)}
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default GradeMaster;
