import React, { useContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { SessionContext } from "../../../../context/sessionContext";
import { AttendanceContext } from "../../../../context/attendanceContext";
import {
  ShowStudentSubjectMapping,
  SaveStudentSubjectMapping,
} from "../../../../actions/TeacherDesk/StudentSubject";
import Loader from "../../../Loader/Loader";
import { Container, Table } from "reactstrap";
import Select from "react-select";
import {
  ClearButton,
  SaveButton,
} from "../../../CommonComponent/ReusableButton";
import { Heading } from "../../../CommonComponent/heading";

const StudentSubject = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  const { Session } = useContext(SessionContext);
  let session_id = Session || sessionStorage.getItem("SessionId");
  const { classListOption } = useContext(AttendanceContext);
  const [selectClass, setSelectClass] = useState(0);
  const [studentList, setStudentList] = useState([]);
  const [subjectList, setSubjectList] = useState([]);
  const [showValidationError, setValidationError] = useState(false);
  const [showLoader, setShowLoader] = useState(false);
  const dispatch = useDispatch();
  const { showStudentSubject } = useSelector(
    (state) => state.ShowStudentSubjectMapping
  );
  let { school_emp_id } = school_user?.user_detail;
  const handleclassselect = (e) => {
    setSelectClass(e.value);
  };

  useEffect(() => {
    if (selectClass) {
      let body = {
        academic_id: session_id,
        school_id: school_id,
        section_id: selectClass,
      };
      if (selectClass === "" || selectClass === 0) {
      } else {
        setShowLoader(true);
        dispatch(ShowStudentSubjectMapping(body, setShowLoader));
      }
    }
  }, [selectClass]);

  useEffect(() => {
    if (showStudentSubject) {
      const { student_list, subject_data } = showStudentSubject;
      setStudentList(student_list);
      setSubjectList(subject_data);
    }
  }, [showStudentSubject]);

  useEffect(() => {
    let body = {
      academic_id: session_id,
      school_id: school_id,
      section_id: selectClass,
    };
    if (selectClass === "" || selectClass === 0) {
    } else {
      setShowLoader(true);
      dispatch(ShowStudentSubjectMapping(body, setShowLoader));
    }
  }, []);

  const handleResetData = () => {
    setStudentList([]);
    setSubjectList([]);
    setSelectClass(0);
  };

  const handleStudentSubjectMapping = () => {
    if (selectClass === "" || selectClass === 0) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 5000);
    } else {
      let body = {
        academic_id: session_id,
        school_id: school_id,
        section_id: selectClass,
        insert_by: school_emp_id,
        subject_mapped: studentList.map((stud) => ({
          student_id: stud.student_id,
          subject_data: stud.subject_data.map((subj) => ({
            subject_id: subj.subject_id,
            is_selected: subj.is_selected,
          })),
        })),
      };
      setShowLoader(true);
      dispatch(SaveStudentSubjectMapping(body, handleLoader));
    }
  };

  const handlerSubjectCheck = (event, student, studsub) => {
    const { student_id } = { ...student };
    const { subject_id } = { ...studsub };
    const studentdata = studentList.map((studdata) =>
      studdata.student_id === student_id
        ? {
            ...studdata,
            subject_data: studdata.subject_data.map((sub) => ({
              ...sub,
              is_selected:
                sub.subject_id === subject_id && sub.is_selected === "False"
                  ? "True"
                  : sub.subject_id === subject_id && sub.is_selected === "True"
                  ? "False"
                  : sub.is_selected,
            })),
          }
        : { ...studdata }
    );
    setStudentList(studentdata);
  };

  const handleLoader = () => {
    setShowLoader((showLoader) => !showLoader);
  };

  if (showLoader) return <Loader />;
  return (
    <Container fluid={true}>
      <Heading pageTitle={"Student Subject Mapping"}></Heading>
      <div className="card mt-4">
        <div className="card-body">
          <div className="mb-3 px-lg-4 row">
            <div className="col-md-2 col-sm-12 col-xs-12">
              <label className="form-label">
                Class Name <span style={{ color: "Red" }}>*</span>
              </label>
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12">
              <Select
                name="class_id"
                placeholder="Select Class"
                options={classListOption}
                isSearchable={true}
                onChange={handleclassselect}
                value={
                  selectClass
                    ? classListOption?.find((c) => c.value === selectClass)
                    : null
                }
              />
              {showValidationError &&
              (selectClass === "" || selectClass === 0) ? (
                <div class="popup-message">
                  <i class="fa fa-exclamation-circle"></i>
                  This field is required.
                </div>
              ) : (
                ""
              )}
            </div>
            <div className="col-md-4 col-sm-12 col-xs-12 my-1 text-right">
              <SaveButton
                disabled={studentList?.length === 0}
                onClick={handleStudentSubjectMapping}
                isEdit={false}
              />
              <ClearButton onClick={handleResetData} className="ml-2" />
            </div>
          </div>

          <div className="mb-3 table-responsive">
            <Table
              className="table table-hover table-vcenter table-striped  text-nowrap table-sm"
              id="table-to-xls"
            >
              <thead>
                <tr className="text-center">
                  <th className="text-center">Sr No</th>
                  <th>Student No</th>
                  <th>Student Name</th>
                  {subjectList?.map((subject) => (
                    <th key={subject?.school_id} className="text-center">
                      {subject?.subject_name}
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {studentList && selectClass
                  ? studentList.map((student, index) => (
                      <tr key={student?.student_no}>
                        <td className="text-center">{index + 1}</td>
                        <td className="text-center">{student?.student_no}</td>
                        <td>{student?.student_name}</td>
                        {student?.subject_data?.map((studsub, i) => (
                          <td key={studsub.subject_id} className="text-center">
                            <input
                              type="checkbox"
                              onClick={(event) =>
                                handlerSubjectCheck(event, student, studsub)
                              }
                              checked={
                                studsub.is_selected.toLowerCase() === "true"
                                  ? "checked"
                                  : ""
                              }
                              style={{ cursor: "pointer" }}
                            />
                          </td>
                        ))}
                      </tr>
                    ))
                  : ""}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </Container>
  );
};

export default StudentSubject;
