import React, { useEffect, useState } from "react";
import {
  ClearButton,
  SaveButton,
  ViewButton,
} from "../../../CommonComponent/ReusableButton";
import useSessionRedirect from "../../Comman/SessionChange";

import { Link, useLocation, useNavigate } from "react-router-dom";
import { Formik, useFormik } from "formik";
import * as Yup from "yup";
import Select from "react-select";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { RouteconfigurationAPI } from "../../../../api/Transport/RouteConfiguration";
import { StudentBusMappingAPI } from "../../../../api/Transport/StudentBussMapping";
import Swal from "sweetalert2";
import { Button } from "reactstrap";
const AddAssignPickupPoint = (props) => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const location = useLocation();
  const { id, studentRouteMapId ,section} = location.state || {};
  const [pickuplist, setPickupList] = useState([]);
  const [selectedOption, setSelectedOptions] = useState([]);
  const [selectedRoute, setSelectedRoute] = useState([]);
  const [busFees, setBussFees] = useState("");
  const [isEdit, setIsEdit] = useState(false);
  const [PreviousData, setPreviousData] = useState([]);
  const navigate = useNavigate();
  console.log(section,"section_id")


  useEffect(() => {
    if (session_id) {
      getPickupPoint();
    }
  }, [session_id]);

  const getPickupPoint = async () => {
    let data = {
      school_id: school_id,
    };
    const response = await RouteconfigurationAPI.getPickupPoint(data);
    const responseData = response.data;
    const filteredBusOptions = responseData.pickup_point.map((bus) => ({
      value: bus.id,
      label: bus.pickup_point,
      route: bus.fk_route__route_name,
      feeAmount: bus.fee_amount,
    }));
    setPickupList(filteredBusOptions);
  };



  const handlePickupChange = (selectedOption) => {
    setSelectedOptions(selectedOption.value);
    setSelectedRoute(selectedOption.route);
    setBussFees(selectedOption.feeAmount);
    
  };
console.log(isEdit,"isEdit")
  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      school_id: school_id,
      pickup_id: selectedOption ? selectedOption.toString() : "",
      student_adm_id: id,
      assign_date: "",
      bus_start_date: "",
      bus_fee: busFees,
      total_amount: "",
      installment: 1,
    },
    validationSchema: Yup.object().shape({
      pickup_id: Yup.string().required("Pickup point is required"),
      bus_start_date: Yup.string().required("Bus start date is required"),
      assign_date: Yup.string().required("Assign date is required"),
      installment: Yup.string().required("Installment is required"),
    }),
    onSubmit: async (values) => {
      console.log(isEdit, "isEdit");
      if (isEdit === false) {
        let data = {
          ...values,
          total_amount: values.bus_fee * values.installment,
          academic_id: session_id,
          insert_by: school_emp_id,
        };
        if (!data.pickup_id) {
          Swal.fire({
            text: "Please select pickup point",
            icon: "error",
            allowOutsideClick: false,
          });
          return;
        }
        try {
          const response = await RouteconfigurationAPI.studentRrouteMappingApi(
            data
          );
          const responseData = response.data;
          if (responseData.status === 200) {
            goBack();
            Swal.fire({
              text: responseData.msg,
              icon: "success",
              allowOutsideClick: false,
            });
            handleClear();
          }
        } catch (err) {
          console.log(err);
        }
      } else {
        let data = {
          ...values,
          route_mapped_id: PreviousData.route_mapped_id,
          total_amount: values.bus_fee * values.installment,
          academic_id: session_id,
          insert_by: school_emp_id,
        };
        if (!data.pickup_id) {
          Swal.fire({
            text: "Please select pickup point",
            icon: "error",
            allowOutsideClick: false,
          });
          return;
        }
        try {
          const response = await StudentBusMappingAPI.changePickupPointapi(
            data
          );
          const responseData = response.data;
          if (responseData.status === 200) {
            goBack();
            Swal.fire({
              text: responseData.msg,
              icon: "success",
              allowOutsideClick: false,
            });
            handleClear();
          }
          if (responseData.status === 403) {
            Swal.fire({
              text: responseData.msg,
              icon: "error",
              allowOutsideClick: false,
            });
          }
        } catch (err) {
          console.log(err);
        }
      }
    },
  });

  const goBack = () => {
    navigate("/school/Student-Pickup", { state: { SelectedClassId: section } });
  };
  useEffect(() => {
    checkStudentRoutemappedApi();
    if (studentRouteMapId) {
      setIsEdit(true);
    }
  }, [studentRouteMapId]);
  const checkStudentRoutemappedApi = async () => {
    let data = {
      school_id: school_id,
      student_adm_id: id,
    };
    const response = await StudentBusMappingAPI.checkStudentRoutemappedApi(
      data
    );
    const responseData = response.data;
    if (responseData.status === 200) {
      setPreviousData(responseData.payload); 
    }
    console.log(responseData);
  };
  const handleClear = () => {
    formik.resetForm();
    setSelectedOptions(null); 
    setSelectedRoute([]);
    setBussFees("");
  };
 
  const totalFees = formik.values.installment
    ? formik.values.installment * formik.values.bus_fee
    : formik.values.bus_fee;

  return (
    <>
      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
        <div className="heading">
          <div class="d-flex justify-content-between col-md-12 align-items-center ">
            <div class="header-action">
              <h1 class="page-title"> Assign Pick Up</h1>
            </div>
            <button class="btn btn-outline-info" onClick={goBack}>
              Go Back
            </button>
          </div>
        </div>
        <div className="card p-3 mt-3">
          <div className="card-body">
            <form onSubmit={formik.handleSubmit}>
              <div className="col-lg-12 col-md-12">
                {PreviousData.pickup_point&& (
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Previous Pickup
                    </label>
                    <div className="col-md-9">
                      <input
                        type="text"
                        placeholder="Pickup/Drop Point"
                        className="form-control custom-input"
                        name="pickup_id"
                        value={PreviousData.pickup_point}
                        readOnly 
                      />
                    </div>
                  </div>
                )}

                {PreviousData.route_name && (
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Previous Route
                    </label> 
                    <div className="col-md-9">
                      <input
                        type="text"
                        placeholder="Pickup/Drop Point"
                        className="form-control custom-input"
                        name="route_name"
                      value={PreviousData.route_name}
                        readOnly 
                      />
                    </div>
                  </div>
                )}
                <div className="form-group col-md-12 row">
                  <label className="col-md-3 col-form-label">
                    Pickup/Drop Point <span className="text-danger">*</span>
                  </label>
                  <div className="col-md-9">
                    <Select
                      type="text"
                      placeholder="Pickup/Drop point"
                      className=""
                      value={
                        selectedOption
                          ? pickuplist.find((c) => c.value === selectedOption)
                          : null
                      }
                      options={pickuplist}
                      name="pickup_id"
                      onChange={handlePickupChange}
                    />
                    {formik.touched.pickup_id && formik.errors.pickup_id && (
                      <div className="text-danger">
                        {formik.errors.pickup_id}
                      </div>
                    )}
                  </div>
                </div>
                <div className="form-group col-md-12 row">
                  <label className="col-md-3 col-form-label">
                    Route 
                  </label>
                  <div className="col-md-9">
                    <input
                      type="text"
                      placeholder="Route"
                      className="form-control custom-input"
                      name="route"
                      value={selectedRoute}
                      readOnly
                    />
                  </div>
                </div>

                <div className="form-group col-md-12 row">
                  <div className="form-group col-md-12 row mt-3">
                    <label className="  col-md-3 col-form-label">
                      Assign Date<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-3">
                      <input
                        type="date"
                        placeholder="Registration No"
                        className="form-control custom-input"
                        name="assign_date"
                        value={formik.values.assign_date}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.assign_date &&
                        formik.errors.assign_date && (
                          <div className="text-danger">
                            {formik.errors.assign_date}
                          </div>
                        )}
                    </div>
                    <label className="col-md-3 col-form-label">Bus Fee</label>
                    <div className="col-md-3">
                      <input
                        type="number"
                        placeholder=" Bus fee "
                        className="form-control custom-input"
                        name="bus_fee"
                        value={formik.values.bus_fee}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                    </div>
                  </div>
                  <div className="form-group col-md-12 row">
                    <label className="  col-md-3 col-form-label">
                      Bus Start Date<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-3">
                      <input
                        type="date"
                        placeholder=" Bus start date"
                        className="form-control custom-input"
                        name="bus_start_date"
                        value={formik.values.bus_start_date}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.bus_start_date &&
                        formik.errors.bus_start_date && (
                          <div className="text-danger">
                            {formik.errors.bus_start_date}
                          </div>
                        )}
                    </div>
                    <label className="  col-md-3 col-form-label">
                      Installment<span className="text-danger">*</span>
                    </label>
                    <div className="col-md-3">
                      <input
                        type="number"
                        placeholder="Installment"
                        className="form-control custom-input"
                        name="installment"
                        value={formik.values.installment}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />{" "}
                      {formik.touched.installment &&
                        formik.errors.installment && (
                          <div className="text-danger">
                            {formik.errors.installment}
                          </div>
                        )}
                    </div>
                  </div>
                  <div className="form-group col-md-12 row">
                    <div className="col-md-6"></div>
                    <label className="  col-md-3 col-form-label">Total</label>
                    <div className="col-md-3">
                      <input
                        type="Text"
                        placeholder=" Total"
                        className="form-control custom-input"
                        name="total_amount"
                        value={totalFees}
                      />
                    </div>
                  </div>
                </div>
                <div className="justify-content-center align-items-center">
                  <button className="btn btn-primary" type="submit">
                    <i className="fa fa-plus px-1"></i>Submit
                  </button>
                  <ClearButton className={"mx-1 m-1"} onClick={handleClear} />
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddAssignPickupPoint;
