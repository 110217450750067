import React, { useState, useContext, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { SessionContext } from "../../../../context/sessionContext";
import Swal from "sweetalert2";
import { getStudentById } from "../../../../actions/school_user/Certificate";
import StudentSessionWiseList from "../../Comman/StudentListSessionWise";
import { resetState } from "../../../../actions/ResetAction/ResetAction";
import { getClassSection } from "../../../../actions/school_user/Attenadance";
import LeavingCertificatePrint from "./LeavingCertificatePrint";
import { Table } from "reactstrap";
import { LeavingAPI } from "../../../../api/Admission/LeavingCertificate";
import { useNavigate } from "react-router-dom";
import useSessionRedirect from "../../Comman/SessionChange";

const LeavingCertificate = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { email, id, user_type, name, user_detail } = school_user;
  const { school_emp_id } = user_detail;
  let school_id = school_user?.user_detail?.fk_school_id;
  const session_id = useSessionRedirect();

  const dispatch = useDispatch();
  const { classsectionList } = useSelector((state) => state.attendance);
  const { studata } = useSelector((state) => state.certificate);
  const [isButtonView, setIsButtonView] = useState(true);
  const [isButtonGenerate, setIsButtonGenerate] = useState(true);
  const [student_uid, setstudent_uid] = useState("");
  const [isSelectClass, setIsSelectClass] = useState("");
  const section_id = isSelectClass?.value;
  const [classList, setClassList] = useState([]);
  const [leaveStudentList, setLeaveStudentList] = useState([]);
  const [leaveId, setLeaveId] = useState("");
  const [isCheck, setIsCheck] = useState(true);
  const [checkedRows, setCheckedRows] = useState([]);
  console.log(checkedRows,"checkedRows")
  const [rowData, setRowData] = useState([]);
  const [isFilterStudentList, setIsFilterStudentList] = useState([]);
  const [isTcNo, setIsTcNo] = useState("");
  const [selectedRowIndex, setSelectedRowIndex] = useState(null);
  const handleRowClick = (index) => {
    setSelectedRowIndex(index);
  };

  let dataS = studata?.payload;
  if (student_uid === "") {
    dataS = "";
  } else if (student_uid !== studata?.payload?.student_code) {
    dataS = "";
  }

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    setClassList(classsectionList?.payload);
  }, [classsectionList]);

  let classListOption = classList?.map((c) => {
    return {
      label: `${c.class_name} ${c.section}`,
      value: c.id,
    };
  });
  let s_data = {
    school_id: school_id,
  };
  useEffect(() => {
    if (token) {
      dispatch(getClassSection(token, s_data));
    }
  }, [dispatch, token, school_id]);

  const handleFetchData = async () => {
    if (!isSelectClass?.value) {
      Swal.fire({
        title: "",
        text: "Please Select class .",
        icon: "warning",
        allowOutsideClick: false,
      });

      return;
    }
    try {
      let data = {
        section_id: isSelectClass?.value,
        academic_id: session_id,
        school_id: school_id,
      };
      const response = await LeavingAPI.getLeavedStudentList(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        let filteredData = responseData?.payload;
        if (student_uid) {
          filteredData = filteredData?.filter(
            (d) => d.student_code === student_uid
          );
        }
        setLeaveStudentList(filteredData);
        if (filteredData.length === 0) {
          setIsButtonView(true);
          Swal.fire({
            text: "No Record Found for Selected Class",
            icon: "warning",
            title: "ALERT",
            allowOutsideClick: false,
          });
        } else {
          setIsButtonView(false);
        }
      } else if (responseData.status === 500) {
        Swal.fire({
          text: responseData.msg,
          icon: "warning",
          allowOutsideClick: false,
        });
      } else if (responseData.status === 403) {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClear = () => {
    setstudent_uid("");
    setIsSelectClass("");
    setLeaveStudentList([]);
    setRowData([]);
    setCheckedRows([]);
    setSelectAll(false)
    setIsButtonView(true);
    setIsTcNo("");
    setIsFilterStudentList([]);
  };

  const handleViewStudent = (e) => {
    setIsSelectClass(e);
    setCheckedRows([]);
    setLeaveStudentList([]);
    setIsButtonView(true);
    setstudent_uid("");
  };

  useEffect(() => {
    const fetchData = async () => {
      const body = {
        fk_academic: session_id,
        student_code: student_uid,
        school_id: Number(user_detail.fk_school_id),
      };
      try {
        let response = await dispatch(getStudentById(body));
        if (response.status === 500) {
          Swal.fire({
            text: response.msg,
            icon: "warning",
            imageAlt: "warning image",
          }).then((result) => {
            if (result.isConfirmed) {
              handleClear();
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [student_uid, session_id, dispatch]);

  let todayDate = new Date().toISOString().substr(0, 10);

  useEffect(() => {
    const initialRowData = leaveStudentList?.map(() => ({
      class_year: "",
      medical_inspection: "",
      student_left_school_date: todayDate,
      progress: "",
      conduct: "",
      remark: "",
    }));
    setRowData(initialRowData);
  }, [leaveStudentList]);
  // const handleInputChange = (index, fieldName, value) => {
  //   const updatedRowData = [...rowData];
  //   updatedRowData[index] = { ...updatedRowData[index], [fieldName]: value };
  //   setRowData(updatedRowData);
  // };

  const [selectAll, setSelectAll] = useState(false);
  const handleCheckboxChange = (index) => {
    const updatedCheckedRows = [...checkedRows];

  
      if (updatedCheckedRows.includes(index)) {
        updatedCheckedRows.splice(updatedCheckedRows.indexOf(index), 1);
        setIsCheck(true);
      } else {
        updatedCheckedRows.push(index);
        setIsCheck(false);
      }
      setCheckedRows(updatedCheckedRows);

      setSelectAll(updatedCheckedRows?.length === leaveStudentList?.length)
   
  };

  const handleSelectAllStudent = () => {
    if (selectAll) {
      setCheckedRows([]);
    } else {
      const allIndexes = Array.from(
        { length: leaveStudentList?.length },
        (_, i) => i
      );

      setCheckedRows(allIndexes);
    }
    setSelectAll(!selectAll);
    setIsCheck(!selectAll);
  };
  const handleInputChange = (index, field, value) => {
    const updatedRowData = [...rowData];
    if (!updatedRowData[index]) {
      updatedRowData[index] = {};
    }
    updatedRowData[index][field] = value;
    setRowData(updatedRowData);
  };

  const handleFetchLeavingData = async () => {
    try {
      let data = {
        academic_id: session_id,
        school_id: school_id,
        section_id: isSelectClass?.value || "",
        tc_no: isTcNo || "",
        student_no: student_uid || "",
      };
      const response = await LeavingAPI.getFilterStudentList(data);
      const responseData = response.data;
      if (responseData.status === 200) {
        let filterData = responseData?.leaving_certificate;
        setIsFilterStudentList(filterData);
        setIsTcNo("");
        if (filterData.length === 0) {
          Swal.fire({
            text: "No Record Found for Selected Class",
            icon: "warning",
            title: "ALERT",
            allowOutsideClick: false,
          });
        }
      } else if (responseData.status === 500) {
        Swal.fire({
          text: responseData.msg,
          icon: "warning",
          allowOutsideClick: false,
        });
      } else if (responseData.status === 403) {
        Swal.fire({
          text: responseData.msg,
          icon: "error",
          allowOutsideClick: false,
        });
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleCancelLeaving = async (data) => {
    Swal.fire({
      title: "CONFIRMATION",
      text: "Do you Want to Cancel TC. ?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let cancelTc = {
          tc_no: data.tc_no,
          leaving_id: data.id,
        };
        const response = await LeavingAPI.cancelLeaving(cancelTc);
        const responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          handleClear();
        } else if (responseData.status === 500) {
          Swal.fire({
            text: responseData.msg,
            icon: "warning",
            allowOutsideClick: false,
          });
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } else if (result.isDenied) {
        // window.location.href = "/school/bonafide";
      }
    });
  };

  function convertDate(data) {
    const date = new Date(data);
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
  }

  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const today = new Date();
  const formattedDate = formatDate(today);
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="header-action">
              <h1 className="page-title ">Leaving Certificate</h1>
              {/* <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Certificates
                </li>
              </ol> */}
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Bulk"
                  onClick={handleClear}
                >
                  Bulk Issue
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#Cancel"
                  onClick={handleClear}
                >
                  Cancel & Re-Print
                </a>
              </li>
              {/* <li className="nav-item">
                <AdmittedStudentList setstudent_uid={setstudent_uid} />
              </li> */}
            </ul>
          </div>
        </div>
      </div>
      <br />
      <div className="section-body mt-2">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="tab-pane active" id="Bulk">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-7 "></div>
                    <div className="col-lg-5  text-right">
                      <LeavingCertificatePrint
                        session_id={session_id}
                        school_id={school_id}
                        formattedDate={formattedDate}
                        handleClear={handleClear}
                        leaveStudentList={leaveStudentList}
                        checkedRows={checkedRows}
                        rowData={rowData}
                        section_id={section_id}
                        school_emp_id={school_emp_id}
                        title="View"
                        setLeaveId={setLeaveId}
                        isButtonView={isButtonView}
                        setIsButtonView={setIsButtonView}
                      />

                      <LeavingCertificatePrint
                        session_id={session_id}
                        school_id={school_id}
                        formattedDate={formattedDate}
                        handleClear={handleClear}
                        leaveStudentList={leaveStudentList}
                        checkedRows={checkedRows}
                        rowData={rowData}
                        section_id={section_id}
                        school_emp_id={school_emp_id}
                        title="GENERATE"
                        leaveId={leaveId}
                        isButtonView={isButtonView}
                        setIsButtonView={setIsButtonView}
                      />

                      <button
                        type="button"
                        className="btn btn-outline-danger mx-1 py-1"
                        onClick={handleClear}
                      >
                        <i className="fa fa-times px-1"></i>
                        CLEAR
                      </button>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-7  my-1">
                      <div className="row">
                        <label className="col-form-label col-lg-2">
                          Class <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="col-lg-10"
                          options={classListOption}
                          placeholder="Select Class"
                          value={isSelectClass}
                          onChange={handleViewStudent}
                          isSearchable={true}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: "white",
                              zIndex: 999,
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-5  my-1">
                      <div className="row">
                        <div className="col-sm-6 my-1">
                          <button
                            type="button"
                            className="btn btn-outline-secondary"
                            onClick={handleFetchData}
                          >
                            <i
                              className="fa fa-eye px-1"
                              aria-hidden="true"
                            ></i>
                            View Student
                          </button>
                        </div>
                        <div className="col-sm-6 my-1">
                          Total Leaved Student : {leaveStudentList?.length}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-lg-12">
                      <div className="row">
                        <div className="col-lg-3 col-md-5 col-sm-6 my-1">
                          <div className="input-group">
                            {/* <label className="col-form-label mx-2">
                              Student
                            </label> */}
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Student Code"
                              value={student_uid}
                              onChange={(e) => {
                                const inputText = e.target.value;
                                // if (/^\d*$/.test(inputText)) {
                                //   setstudent_uid(inputText);
                                // }
                                setstudent_uid(inputText);
                              }}
                            />
                            <StudentSessionWiseList
                              setstudent_uid={setstudent_uid}
                            />
                          </div>
                        </div>
                        <div className="col-lg-4 col-md-5 col-sm-6 my-1">
                          {/* <label className="col-form-label mx-2">
                            Student Name
                          </label> */}
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Student Name"
                            disabled
                            value={student_uid && dataS?.name}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-md-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">Student List</h3>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive ">
                        <Table className="table  text-nowrap" size="sm" hover>
                          <thead>
                            <tr className="text-left">
                              <th className="">
                                <input
                                  type="checkbox"
                                  checked={selectAll}
                                  onChange={() => handleSelectAllStudent()}
                                />
                              </th>
                              <th className="text-center">Sr No</th>
                              <th className="text-center">Student No</th>
                              <th className="text-center">Full Name</th>
                              <th>
                                Class and year in which th pupil
                                <br /> was first admitted into the school
                              </th>
                              <th>
                                {" "}
                                Whether qualified for promotion <br /> to a
                                Higher class
                              </th>
                              <th>
                                {" "}
                                Whether the pupil has <br />
                                undergone medical inspection
                              </th>
                              <th>
                                The date on which the pupils <br /> actually
                                left the school
                              </th>
                              <th className="px-5">Conduct</th>
                              <th className="px-5">Progress</th>
                              <th className="px-5">Remark</th>
                            </tr>
                          </thead>
                          <tbody>
                            {leaveStudentList?.map((s, index) => (
                              <tr key={index}>
                                <td className="align-middle">
                                  <input
                                    type="checkbox"
                                    checked={checkedRows.includes(index)}
                                    onChange={() => handleCheckboxChange(index)}
                                  />
                                </td>
                                <td className="text-center align-middle">
                                  {index + 1}
                                </td>
                                {/* <td>{index + 1}</td> */}
                                <td className="text-center align-middle">
                                  {s.student_code}
                                </td>
                                <td className="align-middle">
                                  {s.student_name}
                                </td>
                                {/* <td>{s.cancel_date}</td> */}
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={rowData[index]?.class_year || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "class_year",
                                        e.target.value
                                      )
                                    }
                                    placeholder="class year"
                                    disabled={
                                      !checkedRows.includes(index) || isCheck
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    className="form-control"
                                    type="text"
                                    value={"Yes"}
                                    // value={rowData[index]?.progress || ""}
                                    // onChange={(e) =>
                                    //   handleInputChange(
                                    //     index,
                                    //     "progress",
                                    //     e.target.value
                                    //   )
                                    // }
                                    placeholder="placeholder"
                                    disabled={
                                      !checkedRows.includes(index) || isCheck
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={
                                      rowData[index]?.medical_inspection || ""
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "medical_inspection",
                                        e.target.value
                                      )
                                    }
                                    placeholder="medical inspection"
                                    disabled={
                                      !checkedRows.includes(index) || isCheck
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="date"
                                    className="form-control"
                                    value={
                                      rowData[index]?.student_left_school_date
                                    }
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "student_left_school_date",
                                        e.target.value
                                      )
                                    }
                                    placeholder="student_left_school_date"
                                    disabled={
                                      !checkedRows.includes(index) || isCheck
                                    }
                                  />
                                </td>

                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={rowData[index]?.conduct || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "conduct",
                                        e.target.value
                                      )
                                    }
                                    placeholder="conduct"
                                    disabled={
                                      !checkedRows.includes(index) || isCheck
                                    }
                                  />
                                </td>
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={rowData[index]?.progress || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "progress",
                                        e.target.value
                                      )
                                    }
                                    placeholder="progress"
                                    disabled={
                                      !checkedRows.includes(index) || isCheck
                                    }
                                  />
                                </td>

                                {/* <td>{s.reason}</td> */}
                                <td>
                                  <input
                                    type="text"
                                    className="form-control"
                                    value={rowData[index]?.remark || ""}
                                    onChange={(e) =>
                                      handleInputChange(
                                        index,
                                        "remark",
                                        e.target.value
                                      )
                                    }
                                    placeholder="remark"
                                    disabled={
                                      !checkedRows.includes(index) || isCheck
                                    }
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </Table>
                        {/* <button onClick={sendDataToServer}>
                          Send Data to Server
                        </button> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="tab-pane" id="Cancel">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-7 "></div>
                    <div className="col-lg-5  text-right">
                      <button
                        type="button"
                        className="btn btn-outline-secondary py-1 mx-1"
                        onClick={handleFetchLeavingData}
                      >
                        <i className="fa fa-search px-1" aria-hidden="true"></i>
                        Search
                      </button>

                      <button
                        type="button"
                        className="btn btn-outline-danger py-1 mx-1"
                        onClick={handleClear}
                      >
                        <i className="fa fa-times px-1"></i>
                        CLEAR
                      </button>
                    </div>
                  </div>

                  <div className="row mt-4">
                    <div className="col-lg-7  my-1">
                      <div className="row">
                        <label className="col-form-label col-md-2">
                          Class <span className="text-danger">*</span>
                        </label>
                        <Select
                          className="col-md-10"
                          options={classListOption}
                          placeholder="Select Class"
                          value={isSelectClass}
                          onChange={handleViewStudent}
                          isSearchable={true}
                          styles={{
                            menu: (provided) => ({
                              ...provided,
                              backgroundColor: "white",
                              zIndex: 999,
                            }),
                          }}
                        />
                      </div>
                    </div>
                    <div className="col-lg-1 col-md-2 col-sm-6 orcircle my-1">
                      {" "}
                      <span>OR</span>
                    </div>
                    <div className="col-lg-4 col-md-4 col-sm-6 my-1">
                      <div className="input-group">
                        <label className="col-form-label mx-2">TC No</label>
                        <input
                          type="text"
                          className="form-control"
                          placeholder="TC No"
                          value={isTcNo}
                          onChange={(e) => {
                            const inputText = e.target.value;
                            if (/^\d*$/.test(inputText)) {
                              setIsTcNo(inputText);
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="row mt-4">
                    <div className="col-lg-3  my-1">
                      <div className="input-group">
                        {/* <label className="col-form-label mx-2">
                              Student
                            </label> */}
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Code"
                          value={student_uid}
                          onChange={(e) => {
                            const inputText = e.target.value;
                            if (/^\d*$/.test(inputText)) {
                              setstudent_uid(inputText);
                            }
                          }}
                        />
                        <StudentSessionWiseList
                          setstudent_uid={setstudent_uid}
                        />
                      </div>
                    </div>
                    <div className="col-lg-4  my-1">
                      {/* <label className="col-form-label mx-2">
                            Student Name
                          </label> */}
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Name"
                        disabled
                        value={student_uid && dataS?.name}
                      />
                    </div>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="card-header">
                  <h3 className="card-title">TC Details</h3>
                </div>
                <div className="card-body">
                  <div className="table-responsive table-sm">
                    <Table size="sm" className="" hover responsive>
                      <thead>
                        <tr className="text-center">
                          <th>TC No</th>
                          <th>Name</th>
                          <th>Class Section</th>
                          <th>Print Date</th>
                          <th></th>
                          <th></th>
                        </tr>
                      </thead>
                      <tbody>
                        {isFilterStudentList?.map((s, index) => (
                          <tr
                            className={`text-center  ${
                              index === selectedRowIndex ? "selected-row" : ""
                            } `}
                            key={index}
                            // onClick={() => handleRowClick(index)}
                          >
                            <td className="align-middle">{s.tc_no}</td>
                            <td className="text-left align-middle">
                              {s.fk_student__std_name}
                            </td>
                            <td className="align-middle">{`${s.fk_stu_leaved__fk_section__fk_class__class_name} - ${s.fk_stu_leaved__fk_section__section}`}</td>
                            <td className="align-middle">
                              {convertDate(s.insert_date)}
                            </td>
                            <td>
                              <button
                                type="button"
                                className="btn btn-outline-secondary py-1"
                                onClick={() => handleCancelLeaving(s)}
                              >
                                Cancel TC
                              </button>
                            </td>
                            <td>
                              <LeavingCertificatePrint
                                session_id={session_id}
                                school_id={school_id}
                                formattedDate={formattedDate}
                                handleClear={handleClear}
                                // leaveStudentList={leaveStudentList}
                                // checkedRows={checkedRows}
                                // rowData={rowData}
                                // section_id={section_id}
                                // leaveId={leaveId}
                                school_emp_id={school_emp_id}
                                title="Re-Prient TC"
                                rePrintData={s}
                              />
                              {/* <button
                                type="button"
                                className="btn btn-outline-secondary  py-1"
                                onClick={() => handleRePrintLeaving(s)}
                              >
                                Re-Prient TC
                              </button> */}
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default LeavingCertificate;
