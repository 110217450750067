import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";
const token = localStorage.getItem("token");

export const SyllabusAPI = {
  addSyllabus: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}add_syllabus_api/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "multipart/form-data",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  getSyllabus: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}get_syllabus/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
  deleteSyllabus: async (data) => {
    var axiosConfig = {
      method: "POST",
      url: `${BASE_URL}delete_syllabus/`,
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
      data,
    };
    return await axios(axiosConfig);
  },
};
