import React, { useState, useEffect, useRef } from "react";
import Select from "react-select";
import { useDispatch, useSelector } from "react-redux";
import Swal from "sweetalert2";
import "react-circular-progressbar/dist/styles.css";
import * as Yup from "yup";
import { useFormik } from "formik";
import PerfectScrollbar from "react-perfect-scrollbar";
import "react-perfect-scrollbar/dist/css/styles.css";
import useSessionRedirect from "../../Comman/SessionChange";
import { MEDIA_BASE_URL } from "../../../../Config/BaseUrl";
import { FcDownload } from "react-icons/fc";
import Loader from "../../../Loader/Loader";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import { EventAPI } from "../../../../api/TeacherDesk/Event";
import EventList from "./EventList";
import { Heading } from "../../../CommonComponent/heading";

const Events = () => {
  const token = localStorage.getItem("token");
  const session_id = useSessionRedirect();
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_id = school_user?.user_detail?.fk_school_id;
  let { school_emp_id, first_name, last_name } = school_user?.user_detail;
  const dispatch = useDispatch();
  const { classsectionList } = useSelector((state) => state.attendance);
  const [selectedSectionId, setSelectedSectionId] = useState([]);
  const [selectAllChecked, setSelectAllChecked] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [isEvent, setIsEvent] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [isDelete, setIsDelete] = useState(true);
  const [isEventId, setIsEventId] = useState(null);
  const [selectedFile, setSelectedFile] = useState([]);
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isContentType, setIsContentType] = useState("");
  const [isContentId, setIsContentId] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [inputType, setInputType] = useState("drive");
  const handleRadioChange = (e) => {
    setInputType(e.target.value);
  };
  const fileInputRef = useRef(null);
  let initialData = {
    content_file: "",
  };
  const [addYoutubeVideo, setAddYoutubeVideo] = useState([initialData]);
  const arrayOfVideos = addYoutubeVideo
    ?.map((item) => item.content_file)
    .filter((content) => content !== "");
  const containsEmptyString = arrayOfVideos?.every((str) => str === "");

  const handleAddRow = (e) => {
    e.preventDefault();
    const newRow = {
      content_file: "",
    };
    setAddYoutubeVideo([...addYoutubeVideo, newRow]);
  };
  const handleDeleteRow = (indexRow) => {
    let deleteRow1 = addYoutubeVideo.filter((_, index) => index !== indexRow);
    let deleteRow2 = isContentId.filter((_, index) => index !== indexRow);
    setAddYoutubeVideo(deleteRow1);
    setIsContentId(deleteRow2);
  };
  let handleAddVideo = (e, index) => {
    const updateVideo = [...addYoutubeVideo];
    updateVideo[index].content_file = e.target.value;
    setAddYoutubeVideo(updateVideo);
  };

  const fetchEventData = async () => {
    let data = {
      academic_id: session_id,
      school_id: school_id,
    };
    try {
      let response = await EventAPI.getEvent(data);
      let responseData = response.data;

      if (responseData.status === 200) {
        setEventData(responseData.event_activity);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const handleClear = () => {
    validation.resetForm();
    setSelectedSectionId([]);
    setSelectAllChecked(false);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
    setIsEdit(false);
    setAddYoutubeVideo([initialData]);
    setIsEvent([]);
    setIsDelete(true);
    setIsEventId(null);
    setSelectedFile([]);
    setSelectedFiles([]);
    setIsContentType("");
    setIsContentId([]);
    setInputType("drive");
  };
  const validation = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      insert_by: school_emp_id,
      title: isEvent?.title || "",
      description: isEvent?.description || "",
      section: [],
      content_type: "",
      start_date: isEvent?.start_date ?? new Date().toISOString().substr(0, 10),
      end_date: isEvent?.end_date ?? new Date().toISOString().substr(0, 10),
      content_file:
        isContentType === "Drive Link"
          ? isEvent?.event_detail?.[0]?.content
          : "" || "",
    },
    validationSchema: Yup.object().shape({
      title: Yup.string().required("This field is required."),
      description: Yup.string().required("This field is required."),
      // content_file: Yup.mixed()
      //   .test("filesize", "File is too large. maximum size is 5mb", (value) => {
      //     if (typeof value === "string") {
      //       return true;
      //     }
      //     return !value || (value && value.size <= 5 * 1024 * 1024);
      //   })
      //   .nullable(),
    }),

    onSubmit: async (values) => {
      if (!selectedSectionId.length) {
        Swal.fire({
          title: "",
          text: "Select Atleast One Class.",
          icon: "warning",
          allowOutsideClick: false,
        });
        return;
      }
      try {
        // isEdit ? setIsLoading(false) : setIsLoading(true);
        //  setIsLoading(true);
        const data = new FormData();
        data.append("academic_id", values.academic_id);
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("title", values.title);
        data.append("description", values.description);
        data.append("start_date", values.start_date);
        data.append("end_date", values.end_date);
        data.append("section", JSON.stringify(selectedSectionId));
        let content_type;
        if (
          typeof values.content_file === "string" &&
          values.content_file.startsWith("https://")
        ) {
          content_type = "Drive Link";
          data.append("content_type", "Drive Link");
          data.append("content_file", JSON.stringify([values.content_file]));
        } else if (!containsEmptyString) {
          content_type = "Youtube Link";
          data.append("content_type", "Youtube Link");
          data.append("content_file", JSON.stringify(arrayOfVideos));
        } else {
          content_type = "File";
          data.append("content_type", "File");
          data.append("content_file", JSON.stringify(selectedFile));
        }

        if (
          !(
            selectedFile.length > 0 ||
            arrayOfVideos.length > 0 ||
            values.content_file !== "" ||
            isContentId.length > 0
          )
        ) {
          Swal.fire({
            title: "",
            text: "Please Add atleast one file or drive link or youtube link.",
            icon: "warning",
            allowOutsideClick: false,
          });
          return;
        }
        let response;
        if (isEdit) {
          data.append("event_id", isEvent?.id);
          data.append(
            "content_file_id",
            JSON.stringify(isContentType !== content_type ? [] : isContentId)
          );
          response = await EventAPI.updateEvent(data);
        } else {
          response = await EventAPI.addEvent(data);
        }
        let responseData = response.data;
        if (responseData.status === 200) {
          setIsLoading(false);
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          fetchEventData();
          handleClear();
        } else if (responseData.status === 403) {
          setIsLoading(false);
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      }
    },
  });

  const handleSubmit = (e) => {
    e.preventDefault();
    validation.handleSubmit();
    return false;
  };

  const onDelete = (id) => {
    let Event_Id = {
      event_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await EventAPI.deleteEvent(Event_Id);
        let responseData = response.data;
        if (responseData.status === 200) {
          fetchEventData();
          handleClear();
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };

  useEffect(() => {
    fetchEventData();
  }, [session_id]);

  let handleCheckboxChange = (c_id) => {
    if (selectedSectionId.includes(c_id)) {
      setSelectedSectionId(selectedSectionId.filter((id) => id !== c_id));
    } else {
      setSelectedSectionId([...selectedSectionId, c_id]);
    }
  };

  const handleSelectAllClass = () => {
    setSelectAllChecked(!selectAllChecked);
    if (!selectAllChecked) {
      const allId = classsectionList?.payload?.map((cl) => cl.id);
      setSelectedSectionId(allId);
    } else {
      setSelectedSectionId([]);
    }
  };

  const onEdit = async (id) => {
    let Event_Id = {
      event_id: id,
    };
    try {
      let response = await EventAPI.getOneEvent(Event_Id);
      let responseData = response.data;
      if (responseData.status === 200) {
        let EventData = responseData.event_data[0];
        setIsEvent(EventData);
        setIsEdit(true);
        setIsDelete(false);
        const allClassId = EventData?.section?.map((cl) => cl.fk_section_id);
        setSelectedSectionId(allClassId);
        let content_t = "";
        EventData?.event_detail?.forEach((item) => {
          content_t = item.content_type;
        });
        setIsContentType(content_t);
        if (content_t === "File") {
          setInputType("file");
          setSelectedFiles(EventData?.event_detail);
        }
        if (content_t === "Youtube Link") {
          setInputType("link");
          setAddYoutubeVideo([...EventData?.event_detail, initialData]);
        }
        let event_id = [];
        EventData?.event_detail?.forEach((item) => {
          event_id.push(item.id);
        });
        setIsContentId(event_id);
        window.scrollTo(0, 0);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const convertBase64 = (file) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };
  let handleFileChange = async (files) => {
    if (!files) return;

    const base64Array = [];
    const fileArray = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      const base64 = await convertBase64(file);
      base64Array.push(base64);
      fileArray.push(file);
    }
    setSelectedFile([...selectedFile, ...base64Array]);
    setSelectedFiles([...selectedFiles, ...fileArray]);
    if (fileInputRef.current) {
      fileInputRef.current.value = null;
    }
  };
  const handleDeleteFile = (indexToDelete) => {
    setSelectedFiles((prevFiles) => {
      const updatedFiles = [...prevFiles];
      updatedFiles.splice(indexToDelete, 1);
      return updatedFiles;
    });
    setSelectedFile((prev) => {
      let fileList = [...prev];
      fileList.splice(indexToDelete, 1);
      return fileList;
    });
    setIsContentId((prev) => {
      let list = [...prev];
      list.splice(indexToDelete, 1);
      return list;
    });
  };
  function isImageFile(file) {
    const imageExtensions = [".jpg", ".jpeg", ".png", ".gif"];
    const fileExtension = file.name
      .slice(file.name.lastIndexOf("."))
      .toLowerCase();
    return imageExtensions.includes(fileExtension);
  }

  function getFileName(data) {
    let name = "";
    const path = data.split("/");
    const lastPath = path[path.length - 1];
    if (lastPath.endsWith(".pdf")) {
      name = lastPath;
    } else {
      name = lastPath;
    }
    return name;
  }

  if (isLoading) {
    return (
      <>
        <Loader />
      </>
    );
  }
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <form onSubmit={handleSubmit}>
            <Heading pageTitle={"Events"}>
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mx-1"
                onClick={() => handleClear()}
              >
                <i className="fa fa-pencil px-1"></i>
                New
              </button>
              <button className="btn btn-primary btn-sm mx-1" type="submit">
                <i className="fa fa-plus px-1"></i>
                {isEdit ? "Update" : "Save"}
              </button>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm mx-1"
                onClick={() => onDelete(isEvent?.id)}
                disabled={isDelete}
              >
                <i className="fa fa-trash px-1"></i>
                Delete
              </button>
              <EventList
                onEdit={onEdit}
                eventData={eventData}
                setIsEventId={setIsEventId}
                handleClear={handleClear}
              />
            </Heading>

            <div className="col-lg-12   mt-4">
              <div className="card">
                <div className="card-body row">
                  <div className="col-lg-8 ">
                    <div className="form-group  row">
                      <label className="col-lg-3 col-form-label">
                        Title <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-9">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Title"
                          name="title"
                          value={validation.values.title}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                        />
                        {validation.touched.title && validation.errors.title ? (
                          <ErrorDisplay errorMsg={validation.errors.title} />
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group  row">
                      <label className="col-lg-3 col-form-label">
                        Description <span className="text-danger">*</span>
                      </label>
                      <div className="col-lg-9">
                        <textarea
                          rows={5}
                          type="text"
                          className="form-control"
                          placeholder="Description"
                          name="description"
                          value={validation.values.description}
                          onChange={validation.handleChange}
                          onBlur={validation.handleBlur}
                          autoComplete="off"
                        />
                        {validation.touched.description &&
                        validation.errors.description ? (
                          <ErrorDisplay
                            errorMsg={validation.errors.description}
                          />
                        ) : null}
                      </div>
                    </div>
                    <div className="form-group  row justify-content-between">
                      <div className="form-group col-lg-6 row">
                        <label className="col-lg-5 col-form-label">
                          Event Start <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-7 d-flex  align-items-center ">
                          <input
                            type="date"
                            name="start_date"
                            className="form-control"
                            value={validation.values.start_date || ""}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </div>
                      <div className="form-group col-lg-6 row">
                        <label className="col-lg-5 col-form-label">
                          Event End <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-7 d-flex  align-items-center ">
                          <input
                            type="date"
                            name="end_date"
                            className="form-control"
                            value={validation.values.end_date}
                            onChange={validation.handleChange}
                          />
                        </div>
                      </div>
                    </div>

                    <div className="form-group  row">
                      <label className="col-lg-3 col-form-label">
                        Document Upload :<span className="text-danger"></span>
                      </label>
                      <div className="col-lg-9 d-flex align-items-center justify-content-around">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="content_file"
                            id="drive"
                            value="drive"
                            checked={inputType === "drive"}
                            onChange={handleRadioChange}
                            style={{ cursor: "pointer" }}
                          />
                          <label htmlFor="drive" className="form-check-label">
                            Drive Link
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="content_file"
                            id="file"
                            value="file"
                            checked={inputType === "file"}
                            onChange={handleRadioChange}
                            style={{ cursor: "pointer" }}
                          />
                          <label htmlFor="file" className="form-check-label">
                            File
                          </label>
                        </div>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="content_file"
                            id="link"
                            value="link"
                            checked={inputType === "link"}
                            onChange={handleRadioChange}
                            style={{ cursor: "pointer" }}
                          />
                          <label htmlFor="link" className="form-check-label">
                            Youtube Link
                          </label>
                        </div>
                      </div>
                    </div>
                    {inputType === "drive" && (
                      <div className="form-group  row">
                        <label className="col-lg-3 col-form-label">
                          Google Drive Link{" "}
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-9 d-flex">
                          <input
                            type="url"
                            className="form-control"
                            placeholder="Drive Link"
                            name="content_file"
                            value={validation.values.content_file}
                            onChange={validation.handleChange}
                            onBlur={validation.handleBlur}
                          />
                          {validation.values.content_file && (
                            <button
                              className="btn btn-danger"
                              type="button"
                              onClick={() => {
                                validation.setFieldValue("content_file", "");
                                setIsContentId([]);
                              }}
                            >
                              <i className="fa fa-times" aria-hidden="true"></i>
                            </button>
                          )}
                        </div>
                      </div>
                    )}
                    {inputType === "file" && (
                      <div className="form-group  row">
                        <label className="col-lg-3 col-form-label">
                          File:<span className="text-danger">*</span>
                        </label>{" "}
                        <div className="col-lg-9">
                          <input
                            type="file"
                            className="form-control"
                            name="content_file"
                            ref={fileInputRef}
                            onChange={(e) => {
                              handleFileChange(e.currentTarget.files);
                            }}
                            onBlur={validation.handleBlur}
                            accept=".jpg, .jpeg, .png, .gif, .pdf"
                            multiple
                          />
                          {validation.touched.content_file &&
                          validation.errors.content_file ? (
                            <ErrorDisplay
                              errorMsg={validation.errors.content_file}
                            />
                          ) : (
                            ""
                          )}
                        </div>
                      </div>
                    )}
                    {inputType === "link" && (
                      <div className="form-group  row">
                        <label className="col-lg-3 col-form-label">
                          Youtube Video <span className="text-danger">*</span>
                        </label>
                        <div className="col-lg-9">
                          {addYoutubeVideo.map((video, index) => (
                            <div className="row mb-1">
                              <input
                                type="text"
                                className="form-control col-lg-11"
                                placeholder="Youtube Video"
                                name={`content_file-${index}`}
                                value={
                                  video.content_file
                                    ? video.content_file
                                    : video.content || video.content_file
                                }
                                onChange={(e) => handleAddVideo(e, index)}
                              />
                              {index === addYoutubeVideo.length - 1 && (
                                <button
                                  className="btn btn-primary col-lg-1"
                                  onClick={handleAddRow}
                                >
                                  <i className="fa fa-plus"></i>
                                </button>
                              )}
                              {index !== addYoutubeVideo.length - 1 && (
                                <button
                                  type="button"
                                  className="btn btn-danger col-lg-1"
                                  onClick={() => handleDeleteRow(index)}
                                >
                                  <i className="fa fa-times"></i>
                                </button>
                              )}
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                    {inputType === "file" && (
                      <div className="form-group ">
                        <div className="card-header p-0">
                          <h3 className="card-title"> File List</h3>
                        </div>
                        <div className="pt-2">
                          {selectedFiles.length > 0 && (
                            <ul>
                              {selectedFiles?.map((file, index) => (
                                <li
                                  key={index}
                                  className="pb-1 "
                                  style={{ listStyleType: "none" }}
                                >
                                  {file?.content_file ? (
                                    <>
                                      <a
                                        href={`${MEDIA_BASE_URL}${file?.content_file}`}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                      >
                                        <FcDownload
                                          style={{
                                            fontSize: "1.3rem",
                                          }}
                                          className="text-danger"
                                        />
                                        <span className="px-2">
                                          {getFileName(file?.content_file)}
                                        </span>
                                      </a>
                                    </>
                                  ) : (
                                    <a
                                      href={URL.createObjectURL(file)}
                                      target="_blank"
                                      rel="noopener noreferrer"
                                    >
                                      <FcDownload
                                        style={{
                                          fontSize: "1.3rem",
                                        }}
                                        className="text-danger"
                                      />
                                      <span className="px-2">{file.name}</span>
                                    </a>
                                  )}

                                  <span
                                    type="button"
                                    className="btn-outline-danger mx-1"
                                    onClick={() => handleDeleteFile(index)}
                                  >
                                    <i className="fa fa-times px-1"></i>
                                  </span>
                                </li>
                              ))}
                            </ul>
                          )}
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="col-lg-4 ">
                    <PerfectScrollbar
                      style={{
                        height: "400px",
                        paddingRight: "16px",
                        boxSizing: "content-box",
                      }}
                      options={{ suppressScrollX: true }}
                    >
                      <div>
                        <table className="table table-sm table-hover table-bordered ">
                          <thead className="thead-light ">
                            <tr className="text-center">
                              <th>Sr No</th>
                              <th>Class Name</th>
                              <th>
                                <input
                                  type="checkbox"
                                  checked={selectAllChecked}
                                  onChange={handleSelectAllClass}
                                  style={{ cursor: "pointer" }}
                                />
                              </th>
                            </tr>
                          </thead>
                          <tbody>
                            {classsectionList?.payload?.map((cl, index) => (
                              <tr
                                className={`text-center ${
                                  selectedSectionId.includes(cl.id)
                                    ? "selected-class"
                                    : ""
                                }`}
                                key={index}
                              >
                                <td>{index + 1}</td>
                                <td className="text-left">
                                  {cl.class_name} {cl.section}
                                </td>

                                <td>
                                  <input
                                    type="checkbox"
                                    checked={
                                      selectedSectionId.includes(cl.id) || false
                                    }
                                    onChange={() => handleCheckboxChange(cl.id)}
                                    style={{ cursor: "pointer" }}
                                  />
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </PerfectScrollbar>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default Events;
