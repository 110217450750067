import React, { useEffect } from "react";

const TimeoutErrorDisplay = ({ formik, timeoutDuration }) => {

  useEffect(() => {
    const timeouts = {};

    Object.keys(formik.errors).forEach((field) => {
      if (formik.touched[field] && formik.errors[field]) {
        timeouts[field] = setTimeout(() => {
          formik.setFieldError(field, "")
        }, timeoutDuration);
      }
    });

    return () => {
      Object.values(timeouts).forEach((timeout) => clearTimeout(timeout));
    };
  }, [formik.touched, formik.errors, timeoutDuration]);

  return null; 
};

export default TimeoutErrorDisplay;
