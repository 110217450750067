import React from "react"
import ReactApexChart from "react-apexcharts"
import getChartColorsArray from "./ChartsDynamicColor";
import { SelectField } from "../../CommonComponent/SelectField";

const DonutChart = ({dataColors,allpresent_count , allabsent_count ,is_holiday_school_admin ,isTeacher}) => {
  const dountApexChartColors = getChartColorsArray(dataColors);
 
  let holiday;
  if(is_holiday_school_admin === "True"){

    holiday = 1
  }else{
    holiday = 0
  }

  const series = [allpresent_count || 0, allabsent_count || 0 , holiday || 0];
  const options = {
   
    labels : isTeacher ? ["Present Staff","Absent Staff", "Holiday"] :  ["Present Students","Absent Students", "Holiday "],
    colors: dountApexChartColors,
    legend: {
      show: true,
      position: "right",
      // horizontalAlign: "center",
      // verticalAlign: "middle",
      // floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  }


  return (
 
    <>

    <ReactApexChart
      options={options}
      series={series}
      type="donut"
      
      height="190"
    />
   </>
  )
}

export default DonutChart
