import React from "react";

const Mainscreen = () => {
  const isLogin = JSON.parse(window.localStorage.getItem("User"));
  if (!isLogin) {
    window.location.replace("/login");
  } else if (isLogin === "Admin") {
    window.location.replace("/admin/dashboard");
  } else if (isLogin === "School") {
    window.location.replace("/school/dashboard");
  }
};

export default Mainscreen;
