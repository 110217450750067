import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL_ROUTER } from "../../Config/BaseUrl";
import {
  CREATE_MASTERDATA_FAILD,
  CREATE_MASTERDATA_REQUIST,
  CREATE_MASTERDATA_SUCCESS,
  DELETE_MASTERDATA_FAILD,
  DELETE_MASTERDATA_REQUIST,
  DELETE_MASTERDATA_SUCCESS,
  GET_MASTERDATA_FAILD,
  GET_MASTERDATA_REQUIST,
  GET_MASTERDATA_SUCCESS,
  UPDATE_MASTERDATA_FAILD,
  UPDATE_MASTERDATA_REQUIST,
  UPDATE_MASTERDATA_SUCCESS,
} from "../../Constants/Common";

export const CreateConnession = (token, orgdata) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_MASTERDATA_REQUIST });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.post(
      `${BASE_URL_ROUTER}masterdata_api/`,
      orgdata,
      config
    );
    const data = response.data;
    dispatch({ type: CREATE_MASTERDATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: CREATE_MASTERDATA_FAILD, payload: error });
  }
};
export const updateMasterData = (token, u_id, orgdata) => async (dispatch) => {
  try {
    dispatch({ type: UPDATE_MASTERDATA_REQUIST });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.patch(
      `${BASE_URL_ROUTER}masterdata_api/${u_id}/`,
      orgdata,
      config
    );
    const data = response.data;
    dispatch({ type: UPDATE_MASTERDATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({ type: UPDATE_MASTERDATA_FAILD, payload: error });
  }
};

export const getMasterData = (token) => async (dispatch) => {
  try {
    dispatch({ type: GET_MASTERDATA_REQUIST });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.get(`${BASE_URL_ROUTER}masterdata_api/`, config);
    const data = response.data;
    dispatch({ type: GET_MASTERDATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: GET_MASTERDATA_FAILD,
      payload: error,
    });
  }
};
export const deleteMasterData = (token, id) => async (dispatch) => {
  try {
    dispatch({ type: DELETE_MASTERDATA_REQUIST });
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
        "Content-Type": "application/json",
      },
    };
    const response = await axios.delete(
      `${BASE_URL_ROUTER}masterdata_api/${id}/`,
      config
    );
    const data = response.data;
    dispatch({ type: DELETE_MASTERDATA_SUCCESS, payload: data });
  } catch (error) {
    dispatch({
      type: DELETE_MASTERDATA_FAILD,
      payload: error,
    });
  }
};
