import React, { useEffect, useState } from "react";
import { IoIosArrowDown, IoIosArrowForward } from "react-icons/io";
import { FaSquare, FaCheckSquare, FaMinusSquare } from "react-icons/fa";
import { CastMasterAPI } from "../../../api/SchoolAdmin/Master";

const Category = (props) => {
  const {
    category,
    isCast,
    handleAddCast,
    setIsCategorySeleted,
    isCategorySeleted,
    handleAddSubcaste,
  } = props;
  const [expanded, setExpanded] = useState(false);
  const [expandedSubCaste, setExpandedSubCaste] = useState(false);
  const [expandedCaste, setExpandedCaste] = useState({});
  // console.log(expandedCaste);
  const handleToggle = () => {
    // setExpanded(!expanded);
    setExpandedCaste((prev) => ({
      ...prev,
      [category.id]: !prev[category.id],
    }));
  };
  const handleToggleSubCaste = (castId) => {
    // setExpandedSubCaste(!expandedSubCaste);
    setExpandedCaste((prev) => ({
      ...prev,
      [castId]: !prev[castId],
    }));
  };

  return (
    <div>
      <span onClick={handleToggle}>
        {category.caste && (
          <>
            {expandedCaste[category.id] ? (
              <IoIosArrowDown />
            ) : (
              <IoIosArrowForward />
            )}
          </>
        )}
      </span>
      <FaSquare className="" />
      <span
        className={`checkbox ${
          isCategorySeleted?.category_id === category.id
            ? "isCategory-selected"
            : ""
        }`}
        onClick={() => setIsCategorySeleted(category)}
      >
        {category.name}
      </span>

      {expandedCaste[category.id] && (
        <div>
          {category.caste.map((caste) => (
            <div key={caste.id} className="pl-3">
              <span onClick={() => handleToggleSubCaste(caste.id)}>
                <>
                  {expandedCaste[caste.id] ? (
                    <IoIosArrowDown />
                  ) : (
                    <IoIosArrowForward />
                  )}
                </>

                <span className="checkbox">{caste.name}</span>
              </span>
              {expandedCaste[caste.id] && (
                <div>
                  {caste.subCaste.map((subCaste) => (
                    <div key={subCaste.id} className="pl-3">
                      {subCaste.name}
                    </div>
                  ))}

                  <div className="">
                    <span
                      type="button"
                      className="btn-outline-secondary cast-button"
                      onClick={() => handleAddSubcaste(caste.id)}
                    >
                      <i className=""></i>
                      Add New Sub Caste
                    </span>
                  </div>
                </div>
              )}
            </div>
          ))}
          <div className="cast-div">
            <span
              type="button"
              className="btn-outline-secondary cast-button"
              onClick={() => handleAddCast(category.id)}
            >
              <i className=""></i>
              Add New Caste
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default Category;
