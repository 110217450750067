import {
    CREATE_USERS_FAILURE,
    CREATE_USERS_REQUEST,
    CREATE_USERS_SUCCESS,
    GET_USERS_FAILURE,
    GET_USERS_REQUEST,
    GET_USERS_SUCCESS,
    UPDATE_USERS_FAILURE,
    UPDATE_USERS_REQUEST,
    UPDATE_USERS_SUCCESS,
    DELETE_USERS_FAILURE,
    DELETE_USERS_REQUEST,
    DELETE_USERS_SUCCESS,
    SCHOOL_DESIGN_FAILURE,
    SCHOOL_DESIGN_REQUEST,
    SCHOOL_DESIGN_SUCCESS,
} from "../../Constants/UserSchoolConstant";

const initialState = {
    allUsers: null,
    SchDesg: null,
    createUsers: null,
    updateUsers: null,
    deleteUsers: null,
    loading: false,
    school_users: [],
    error: null,
};
export const addUsersReducer = (state = initialState, action) => {
    switch (action.type) {
        case CREATE_USERS_REQUEST:
        case GET_USERS_REQUEST:
        case UPDATE_USERS_REQUEST:
        case SCHOOL_DESIGN_REQUEST:
        case DELETE_USERS_REQUEST:
            return {
                ...state,
                loading: true,
                error: null,
                // school_users: [],
            };
        case GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                allUsers: action.payload,
                error: null,
            };
        case CREATE_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                createUsers: action.payload,
                error: null,
            };
        case UPDATE_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                updateUsers: action.payload,
                error: null,
            };
        case DELETE_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                deleteUsers: action.payload,
                error: null,
            };
        case SCHOOL_DESIGN_SUCCESS:
            return {
                ...state,
                loading: false,
                SchDesg: action.payload,
                // school_users: action.payload,
                error: null,
            };

        case CREATE_USERS_FAILURE:
        case GET_USERS_FAILURE:
        case UPDATE_USERS_FAILURE:
        case DELETE_USERS_FAILURE:
        case SCHOOL_DESIGN_FAILURE:
            return {
                ...state,
                loading: true,
                error: action.payload,
            };

        // case CLEAR_ERROR:
        //     return {
        //         ...state,
        //         error: null,
        //     };
        default:
            return state;
    }
}