import axios from "axios";
import Swal from "sweetalert2";
import { BASE_URL } from "../../Config/BaseUrl";
import {
  CREATE_STAFF_USER_REQUEST,
  CREATE_STAFF_USER_SUCCESS,
  CREATE_STAFF_USER_FAILURE,
  GET_STAFF_USER_REQUEST,
  GET_STAFF_USER_SUCCESS,
  GET_STAFF_USER_FAILURE,
} from "../../Constants/school_user/addStaffUser.js";

const token = localStorage.getItem("token");
const config = {
  headers: {
    Authorization: `Bearer ${token}`,
    "Content-Type": "application/json",
  },
};

export const addStaffUser = (body, navigate) => async (dispatch) => {
  try {
    dispatch({ type: CREATE_STAFF_USER_REQUEST });
    const response = await axios.post(
      `${BASE_URL}register_staff_user/`,
      body,
      config
    );
    const data = response.data;
    if (response.data.status === 200) {
      dispatch({ type: CREATE_STAFF_USER_SUCCESS, payload: data });
      Swal.fire({
        text: response.data.msg,
        icon: "success",
        imageAlt: "success image",
      }).then((result) => {
        console.log(result);
      });
    } else {
      Swal.fire({
        text: response.data.msg,
        icon: "error",
        imageAlt: "error image",
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(response.data);
        }
      });
    }
  } catch (error) {
    dispatch({ type: CREATE_STAFF_USER_FAILURE, payload: error });
  }
};

export const getStaffUser = (body) => async (dispatch) => {
  try {
    dispatch({ type: GET_STAFF_USER_REQUEST });
    const response = await axios.post(
      `${BASE_URL}get_designantion_staff_user/`,
      body,
      config
    );
    const data = response.data;
    if (response.data.status === 200) {
      dispatch({ type: GET_STAFF_USER_SUCCESS, payload: data });
    }
  } catch (error) {
    dispatch({ type: GET_STAFF_USER_FAILURE, payload: error });
  }
};
