import React, { useRef } from "react";
import { useState } from "react";
import "./Certificate.css";
import html2pdf from "html2pdf.js";
import jsPDF from "jspdf";
import "jspdf-autotable";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import { LeavingAPI } from "../../../../api/Admission/LeavingCertificate";
import Swal from "sweetalert2";
import { useReactToPrint } from "react-to-print";
import PageComponent from "./PageComponent";

const LeavingCertificatePrint = (props) => {

  const {
    formattedDate,
    handleClear,
    leaveStudentList,
    checkedRows,
    rowData,
    session_id,
    school_id,
    section_id,
    school_emp_id,
    title,
    leaveId,
    setLeaveId,
    isButtonView,
    setIsButtonView,
    rePrintData,
  } = props;
  console.log(leaveStudentList, "leaveStudentList");
  const [modal, setModal] = useState(false);
  const [stData, setStData] = useState([]);
  const [tcNo, setTcNo] = useState("");
  console.log(tcNo, "tcNo");
  const componentRef = useRef();
  const handlePrint = useReactToPrint({
    content: () => componentRef.current,
  });

  const toggle = () => {
    if (!checkedRows?.length && !rePrintData) {
      Swal.fire({
        title: "",
        text: "Please Select Student",
        icon: "info",
        allowOutsideClick: false,
      });
      return;
    }
    if (!modal) {
      setModal(true);
    } else {
      setModal(false);
    }
  };
  const sendDataToServer = async (isGenerate) => {
    const selectedRows = leaveStudentList.map((row, index) => ({
      ...row,
      ...rowData[index],
    }));
    const dataToSend = selectedRows?.filter((_, index) =>
      checkedRows.includes(index)
    );
    setStData(dataToSend);
    const extractedData = dataToSend.map((item) => ({
      cancel_date: item.cancel_date || "",
      student_id: item.fk_student || "",
      progress: item.progress || "",
      conduct: item.conduct || "",
      class_year: item.class_year || "",
      medical_inspection: item.medical_inspection || "",
      student_left_school_date: item.student_left_school_date || "",
      remark: item.remark || "",
      reason: item.reason || "",
      declared_eligible_clg_course: item.declared_eligible_clg_course || "",
      student_leave_off_id: item.id || "",
    
    }));
    console.log(extractedData, "1");
    let data = {
      academic_id: session_id,
      school_id: school_id,
      section_id: section_id,
      insert_by: school_emp_id,
      // ...extractedData[0],
      leaving_data: extractedData,
    };

    if (!checkedRows?.length) {
      return;
    }
    if (isGenerate) {
      Swal.fire({
        title: "CONFIRMATION",
        text: "Do you want to Generate Leaving Certificate for Selected Student ?",
        icon: "question",
        showDenyButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#f46a6a",
        confirmButtonText: "Yes",
        denyButtonText: `No`,
        allowOutsideClick: false,
      }).then(async (result) => {
        if (result.isConfirmed) {
          let gdata = {
            ...data,
            // stu_leaved_id: leaveId,
            leaved_date: "",
          };
          console.log(gdata, "save leaving");

          const response = await LeavingAPI.generateLeavingCertificate(gdata);
          setTcNo(response.data.tc_code_list);
          toggle();
        } else if (result.isDenied) {
          // window.location.href = "/school/bonafide";
        }
      });
    } else {
      const response = await LeavingAPI.viewLeavingCertificateDetail(data);
      setLeaveId(response.data.student_leaved_off_id);
    }
  };

  const handleConvertToPdf = async () => {
    // stData?.forEach(async (s, index) => {
    const element = document.getElementById(`model-con`);
    if (element) {
      const opt = {
        margin: 5,
        filename: `generated_pdf.pdf`,
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 1 },
        pagebreak: { mode: "css", after: "#divToConvert" },
        jsPDF: { unit: "mm", format: "legal", orientation: "portrait" },
      };
      const pdfDataUri = await html2pdf()
        .from(element)
        .set(opt)
        .outputPdf("datauristring");

      const newTab = window.open();
      if (newTab) {
        newTab.document.write(
          `<style> body { margin: 0px !important; overflow: hidden !important; }</style><iframe width="100%" height="100%" src="${pdfDataUri}"></iframe>`
        );
        newTab.document.close();
      } else {
        console.error("Failed to open a new tab.");
      }
    }
    // });

    setModal(!modal);
    setStData([]);
    handleClear();
  };

  const handleConvertToPdf1 = async () => {
    let allPdfData = "";
    stData?.forEach(async (s, index) => {
      const element = document.getElementById(`divToConvert-${index}`);
      if (element) {
        const opt = {
          margin: 15,
          filename: `generated_${index}.pdf`,
          image: { type: "jpeg", quality: 0.98 },
          html2canvas: { scale: 1 },
          jsPDF: { unit: "mm", format: "legal", orientation: "portrait" },
        };
        const pdfDataUri = await html2pdf()
          .from(element)
          .set(opt)
          .outputPdf("datauristring");

        allPdfData += pdfDataUri;
      }
    });
    console.log(allPdfData, "allPdfData");
    const newTab = await window.open();
    if (newTab) {
      newTab.document.write(
        `<style> body { margin: 0px !important; overflow: hidden !important; }</style><iframe width="100%" height="100%" src="${allPdfData}"></iframe>`
      );
      newTab.document.close();
    } else {
      console.error("Failed to open a new tab.");
    }

    setModal(!modal);
    setStData([]);
    handleClear();
  };

  const handleRePrintLeaving = async (data) => {
    setTcNo(data.tc_no);
    let rePrint = {
      academic_id: session_id,
      school_id: school_id,
      section_id: data.fk_stu_leaved__fk_section_id,
      tc_no: data.tc_no,
      insert_by: school_emp_id,
    };
    const response = await LeavingAPI.getLeavingInfo(rePrint);
    const responseData = response.data;
    if (responseData.status === 200) {
      let reP = [];
      reP.push(responseData?.student_details);
      setStData(reP);
      toggle();
    }
  };

  return (
    <>
      {title === "View" && (
        <button
          type="button"
          className="btn btn-outline-secondary py-1 mx-1"
          onClick={() => {
            toggle();
            sendDataToServer();
          }}
          disabled={isButtonView}
        >
          {" "}
          <i className="fa fa-eye px-1" aria-hidden="true"></i>
          VIEW
        </button>
      )}
      {title === "GENERATE" && (
        <button
          type="button"
          className="btn btn-outline-secondary py-1  mx-1"
          onClick={() => {
            sendDataToServer(true);
          }}
          disabled={isButtonView}
        >
          GENERATE
        </button>
      )}
      {title === "Re-Prient TC" && (
        <button
          type="button"
          className="btn btn-outline-secondary  py-1 mx-1"
          onClick={() => handleRePrintLeaving(rePrintData)}
          // disabled={isButtonView}
        >
          Re-Prient TC
        </button>
      )}

      <Modal isOpen={modal} toggle={toggle} fullscreen className="modal-lg" backdrop="static" >
      <div className="text-right m-3">
            <button
              className="btn btn-primary"
              onClick={handleConvertToPdf}
              // onClick={handlePrint}
            >
              Print Certificate
            </button>
            <button className="btn btn-secondary mx-1" onClick={toggle} type="button">Close</button>
            <hr className="border border-muted" />

          </div>
        <ModalBody id="model-con">
          {stData?.map((s, index) =>
            index !== stData.length - 1 ? (
              <PageComponent
                key={index}
                ref={componentRef}
                s={s}
                tcNo={tcNo[index]}
                id={`divToConvert`}
              />
            ) : (
              <PageComponent key={index} ref={componentRef} s={s}   tcNo={tcNo[index]} />
            )
          )}
        </ModalBody>
        <ModalFooter>
         
        </ModalFooter>
      </Modal>
    </>
  );
};

export default LeavingCertificatePrint;
