export const CREATE_MOCK_TEST_REQUIST = "CREATE_MOCK_TEST_REQUIST";
export const CREATE_MOCK_TEST_SUCCESS = "CREATE_MOCK_TEST_SUCCESS";
export const CREATE_MOCK_TEST_FAIELD = "CREATE_MOCK_TEST_FAIELD";

export const ADD_QUESTION_REQUIST = "ADD_QUESTION_REQUIST";
export const ADD_QUESTION_SUCCESS = "ADD_QUESTION_SUCCESS";
export const ADD_QUESTION_FAIELD = "ADD_QUESTION_FAIELD";

export const GET_QUESTION_REQUIST = "GET_QUESTION_REQUIST";
export const GET_QUESTION_SUCCESS = "GET_QUESTION_SUCCESS";
export const GET_QUESTION_FAIELD = "GET_QUESTION_FAIELD";

export const DELETE_QUESTION_REQUIST = "DELETE_QUESTION_REQUIST";
export const DELETE_QUESTION_SUCCESS = "DELETE_QUESTION_SUCCESS";
export const DELETE_QUESTION_FAIELD = "DELETE_QUESTION_FAIELD";

export const DELETE_TEST_REQUIST = "DELETE_TEST_REQUIST";
export const DELETE_TEST_SUCCESS = "DELETE_TEST_SUCCESS";
export const DELETE_TEST_FAIELD = "DELETE_TEST_FAIELD";

export const TEST_STATUS_REQUIST = "TEST_STATUS_REQUIST";
export const TEST_STATUS_SUCCESS = "TEST_STATUS_SUCCESS";
export const TEST_STATUS_FAIELD = "TEST_STATUS_FAIELD";

export const ADD_CLASS_TEST_REQUIST = "ADD_CLASS_TEST_REQUIST";
export const ADD_CLASS_TEST_SUCCESS = "ADD_CLASS_TEST_SUCCESS";
export const ADD_CLASS_TEST_FAIELD = "ADD_CLASS_TEST_FAIELD";

export const EDIT_CLASS_TEST_REQUIST = "EDIT_CLASS_TEST_REQUIST";
export const EDIT_CLASS_TEST_SUCCESS = "EDIT_CLASS_TEST_SUCCESS";
export const EDIT_CLASS_TEST_FAIELD = "EDIT_CLASS_TEST_FAIELD";

export const SHOW_TEST_QUIZ_REQUIST = "SHOW_TEST_QUIZ_REQUIST";
export const SHOW_TEST_QUIZ_SUCCESS = "SHOW_TEST_QUIZ_SUCCESS";
export const SHOW_TEST_QUIZ_FAIELD = "SHOW_TEST_QUIZ_FAIELD";

export const CLEAR_ERROR = "CLEAR_ERROR";

export const TEST_STATUS_RESET_STATE = "TEST_STATUS_RESET_STATE";


// online

export const SHOW_ONLINETEST_REQUIST = "SHOW_ONLINETEST_REQUIST";
export const SHOW_ONLINETEST_SUCCESS = "SHOW_ONLINETEST_SUCCESS";
export const SHOW_ONLINETEST_FAIELD = "SHOW_ONLINETEST_FAIELD";

