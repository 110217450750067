export const BASE_URL = "https://api.vidyamate.in/";
export const MEDIA_BASE_URL = "https://api.vidyamate.in/media/";
export const BASE_URL_ROUTER = "https://api.vidyamate.in/router/";


// export const BASE_URL = "http://192.168.29.5:8040/";
// export const MEDIA_BASE_URL = "http://192.168.29.5:8040/media/";
// export const BASE_URL_ROUTER = "http://192.168.29.5:8040/router/";


// export const BASE_URL = "http://api.vidyamate.in:8040/";
// export const MEDIA_BASE_URL = "http://api.vidyamate.in:8040/media/";
// export const BASE_URL_ROUTER = "http://api.vidyamate.in:8040/router/";



// export const BASE_URL = "http://api.vidyamate.in:8050/";
// export const MEDIA_BASE_URL = "http://api.vidyamate.in:8050/media/";
// export const BASE_URL_ROUTER = "http://api.vidyamate.in:8050/router/";

// export const BASE_URL="http://52.66.125.211:8040/"
// export const MEDIA_BASE_URL="http://52.66.125.211:8040/media/"
    
