import React, { useState, useEffect, useRef, useContext } from "react";
import Select from "react-select";
import useSessionRedirect from "../../Comman/SessionChange";
import useLocalStorageData from "../../Comman/LocalStorageData";
import { useFormik } from "formik";
import * as Yup from "yup";
import groupClassMapAPI from "../../../../api/Examination/GroupClassMapping";
import Swal from "sweetalert2";
import ErrorDisplay from "../../../CommonComponent/ErrorDisplay";
import useLoading from "../../../CommonComponent/useLoading";
import Loader from "../../../Loader/Loader";
import { SessionContext } from "../../../../context/sessionContext";
import { ExamsAPI } from "../../../../api/Examination/Exam";

const ExamWiseSubject = () => {
  const { school_id, token, school_emp_id } = useLocalStorageData();
  const session_id = useSessionRedirect();
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [classGroupList, setClassGroupList] = useState([]);
  const [examData, setExamData] = useState([]);
  const [examWiseSubject, setExamWiseSubject] = useState([]);
  const [isExamWiseSubjectId, setIsExamWiseSubjectId] = useState(null);
  const [start_date, setStart_Date] = useState("");

  const [end_date, setEnd_Date] = useState("");
  let [validationError, setValidationError] = useState(false);
  const { SessionData } = useContext(SessionContext);

  let examOption = examData?.map((c) => ({
    label: `${c.fk_exam__exam_name} ${c.assessment} `,
    value: c.id,
  }));

  let groupOption = classGroupList?.map((c) => ({
    label: `${c.group_name} `,
    value: c.id,
  }));

  const fetchClassGroup = async () => {
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
      };
      let response = await groupClassMapAPI.getGroupClass(body);
      let responseData = response.data;
      setClassGroupList(responseData.group_ofclass);
    } catch (error) {
      console.error(error);
    }
  };

  const fetchExamsData = async () => {
    startLoading();
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
      };
      let response = await ExamsAPI.getAssessment(body);
      let responseData = response.data;
      if (responseData.status === 200) {
        // setExamData(responseData.exam);
        setExamData(responseData.assessment);
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      academic_id: session_id,
      school_id: school_id,
      insert_by: school_emp_id,
      group_id: "",
      exam_id: "",
      exam_subject: [],
      start_date: start_date,
      end_date: end_date,
    },
    validationSchema: Yup.object().shape({
      group_id: Yup.string().required("Group is required."),
      exam_id: Yup.string().required("Exam is required."),
      start_date: Yup.date(),
      end_date: Yup.date().min(
        Yup.ref("start_date"),
        "End date must be after start date"
      ),
    }),
    onSubmit: async (values) => {
      let subList = examWiseSubject
        .flatMap((category) => category.exam_subject)
        ?.map((sub) => ({
          id: sub.id,
          is_selected: sub.is_selected,
          min_marks: sub.min_marks,
          max_marks: sub.max_marks,
          grade: sub.grade,
        }));
      let isSelected = subList.some((item) => item.is_selected === "True");
      if (!isSelected) {
        Swal.fire({
          title: "",
          text: "Please Select Subject",
          icon: "warning",
          allowOutsideClick: false,
        });
        return;
      }
      startLoading();
      try {
        const data = new FormData();
        data.append("academic_id", values.academic_id);
        data.append("school_id", values.school_id);
        data.append("insert_by", values.insert_by);
        data.append("group_id", values.group_id);
        data.append("exam_id", values.exam_id);
        data.append("exam_subject", JSON.stringify(subList));
        data.append("start_date", values.start_date);
        data.append("end_date", values.end_date);
        const response = await ExamsAPI.createExamWiseSubject(data);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            allowOutsideClick: false,
          });
          // fetchExamWiseSubject();
          handleClear();
        } else if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            allowOutsideClick: false,
          });
        }
      } catch (error) {
        console.error(error);
      } finally {
        stopLoading();
      }
    },
  });
  console.log(start_date, end_date);
  const handleClear = () => {
    formik.resetForm();
    setExamWiseSubject([]);
    setIsExamWiseSubjectId(null);
    setEnd_Date("");
    setStart_Date("");
  };

  let validationControl = async () => {
    let Validationstatus = true;
    if (!formik.values.exam_id) {
      Validationstatus = false;
    } else if (!formik.values.group_id) {
      Validationstatus = false;
    }
    if (!Validationstatus) {
      setValidationError(true);
      setTimeout(() => {
        setValidationError(false);
      }, 5000);
    }
    return Validationstatus;
  };
 
  const fetchExamWiseSubject = async () => {
    let isValid = await validationControl();
    if (!isValid) return;
    startLoading();
    try {
      let body = {
        school_id: school_id,
        academic_id: session_id,
        group_id: formik.values.group_id,
        exam_id: formik.values.exam_id,
        start_date: formik.values.start_date,
        end_date: formik.values.end_date,
      };
      let response = await ExamsAPI.getExamWiseSubject(body);
      let responseData = response.data;
      if (responseData.start_date !== "None") {
        formik.setFieldValue("start_date", responseData.start_date);
        formik.setFieldValue("end_date", responseData.end_date);
      }
      
      if (responseData.status === 200) {
        if (responseData.exam_subject.length > 0) {
          setExamWiseSubject(responseData.exam_subject);
          setIsExamWiseSubjectId(responseData.examWise_SubjectId);
        }
      }
    } catch (error) {
      console.error(error);
    } finally {
      stopLoading();
    }
  };

  const handleCheckboxChange = (catIndex, subIndex) => {
    setExamWiseSubject((prevSubject) => {
      const updateSubject = [...prevSubject];
      const boolValue =
        updateSubject[catIndex].exam_subject[subIndex].is_selected;
      updateSubject[catIndex].exam_subject[subIndex].is_selected =
        boolValue.toLowerCase() == "true" ? "False" : "True";
      return updateSubject;
    });
  };

  const handleInputChange = (catIndex, subIndex, fieldName, value) => {
    setExamWiseSubject((prevSubject) => {
      const updateSubject = [...prevSubject];
      updateSubject[catIndex].exam_subject[subIndex][fieldName] =
        value.toUpperCase();
      return updateSubject;
    });
  };

  useEffect(() => {
    fetchClassGroup();
    fetchExamsData();
  }, [session_id]);

  const onDelete = (id) => {
    let delete_id = {
      examWise_SubjectId: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
      allowOutsideClick: false,
    }).then(async (result) => {
      if (result.isConfirmed) {
        let response = await ExamsAPI.deleteExamWiseSubject(delete_id);
        let responseData = response.data;
        if (responseData.status === 200) {
          Swal.fire({
            text: responseData.msg,
            icon: "success",
            imageAlt: "success image",
          });
          fetchExamWiseSubject();
          handleClear();
        }
        if (responseData.status === 403) {
          Swal.fire({
            text: responseData.msg,
            icon: "error",
            imageAlt: "error image",
          });
        }
      }
    });
  };

  if (isLoading) return <Loader />;
  return (
    <div className="section-body">
      <div className="container-fluid">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            formik.handleSubmit();
            return false;
          }}
        >
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">Exam Wise Subject</h1>
            </div>
            <div className=" mt-2">
              <button
                type="button"
                className="btn btn-outline-secondary btn-sm mx-1"
                onClick={() => fetchExamWiseSubject()}
              >
                <i className="fa fa-eye  px-1"></i>
                View
              </button>
              <button
                className="btn btn-primary btn-sm mx-1"
                type="submit"
                disabled={examWiseSubject?.length > 0 ? false : true}
              >
                <i className="fa fa-plus px-1"></i>
                Save
              </button>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm mx-1"
                onClick={() => onDelete(isExamWiseSubjectId)}
                disabled={isExamWiseSubjectId ? false : true}
              >
                <i className="fa fa-trash px-1"></i>
                Delete
              </button>
              <button
                type="button"
                className="btn btn-outline-danger btn-sm mx-1"
                onClick={() => handleClear()}
              >
                <i className="fa fa-times px-1"></i>
                Clear
              </button>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            <div className="card">
              <div className="card-body row">
                <div className="col-lg-6 col-md-12">
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Exam <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="exam_id"
                        placeholder="Select Class"
                        options={examOption}
                        value={
                          formik.values.exam_id
                            ? examOption?.find(
                                (g) => g.value === formik.values.exam_id
                              )
                            : null
                        }
                        onChange={(selectedOptions) => {
                          formik.setFieldValue(
                            "exam_id",
                            selectedOptions?.value || ""
                          ),
                            formik.setFieldValue("group_id", null || "");
                          setExamWiseSubject([]);
                          setIsExamWiseSubjectId(null);
                        }}
                        onBlur={formik.handleBlur}
                        isSearchable={true}
                      />
                      {formik.touched.exam_id && formik.errors.exam_id ? (
                        <ErrorDisplay errorMsg={formik.errors.exam_id} />
                      ) : null}
                      {validationError && !formik.values.exam_id ? (
                        <ErrorDisplay errorMsg={"Exam is required"} />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 col-md-12">
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Group <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <Select
                        name="group_id"
                        placeholder="Select Group"
                        options={groupOption}
                        value={
                          formik.values.group_id
                            ? groupOption?.find(
                                (g) => g.value === formik.values.group_id
                              )
                            : null
                        }
                        onChange={(selectedOptions) => {
                          formik.setFieldValue(
                            "group_id",
                            selectedOptions?.value || ""
                          ),
                            setExamWiseSubject([]);
                          setIsExamWiseSubjectId(null);
                        }}
                        onBlur={formik.handleBlur}
                        isSearchable={true}
                      />
                      {formik.touched.group_id && formik.errors.group_id ? (
                        <ErrorDisplay errorMsg={formik.errors.group_id} />
                      ) : null}
                      {validationError && !formik.values.group_id ? (
                        <ErrorDisplay errorMsg={"Group is required"} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      Start Date <span className="text-danger">*</span> 
                    </label>
                    <div className="col-md-9">
                      <input
                        type="date"
                        name="start_date"
                        className="form-control"
                        value={formik.values.start_date}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "start_date",
                            event.target.value
                          );
                        }}
                      />
                      {formik.touched.start_date && formik.errors.start_date ? (
                        <ErrorDisplay errorMsg={formik.errors.start_date} />
                      ) : null}
                      {validationError && !formik.values.start_date ? (
                        <ErrorDisplay errorMsg={"start_date required"} />
                      ) : null}
                    </div>
                  </div>
                </div>

                <div className="col-lg-6 col-md-12">
                  <div className="form-group col-md-12 row">
                    <label className="col-md-3 col-form-label">
                      End Date <span className="text-danger">*</span>
                    </label>
                    <div className="col-md-9">
                      <input
                        type="date"
                        name="end_date"
                        className="form-control"
                        value={formik.values.end_date}
                        onChange={(event) => {
                          formik.setFieldValue(
                            "end_date",
                            event.target.value
                          );
                        }}
  
                      />
                      {formik.touched.end_date && formik.errors.end_date ? (
                        <ErrorDisplay errorMsg={formik.errors.end_date} />
                      ) : null}
                      {validationError && !formik.values.end_date ? (
                        <ErrorDisplay errorMsg={"end date required"} />
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
            {examWiseSubject?.map((exam, catIndex) => (
              <div className="card" key={catIndex}>
                <div className="card-header">
                  <h3 className="card-title">
                    Category : {exam.category_name}
                  </h3>
                </div>
                <div className="card-body row ">
                  {exam?.exam_subject?.map((sub, subIndex) => (
                    <div className="col-lg-4 col-md-6 px-5" key={subIndex}>
                      <div className="form-group row">
                        <div className="col-md-1"></div>
                        <div className="col-md-6">
                          <input
                            id={`checkbox${subIndex}`}
                            className="form-check-input"
                            type="checkbox"
                            checked={sub.is_selected.toLowerCase() === "true"}
                            onChange={() =>
                              handleCheckboxChange(catIndex, subIndex)
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor={`checkbox${subIndex}`}
                          >
                            {sub.fk_subject__subject_name}
                          </label>
                        </div>
                      </div>
                      {exam.marking == "Grade" ? (
                        <div className="form-group  row">
                          {/* <div className="col-md-6">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Grade"
                              name="grade"
                              value={sub.grade}
                              onChange={(e) => {
                                const inputText = e.target.value;
                                if (/^[a-zA-Z]{0,1}$/.test(inputText))
                                  handleInputChange(
                                    catIndex,
                                    subIndex,
                                    "grade",
                                    inputText
                                  );
                              }}
                              autoComplete="off"
                            />
                            <small className="form-text">Grade</small>
                          </div> */}
                        </div>
                      ) : (
                        <div className="form-group  row">
                          <div className="col-md-6">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Min Mark"
                              name="min_marks"
                              value={sub.min_marks}
                              onChange={(e) => {
                                const inputText = e.target.value;
                                const inputZeros = inputText.replace(/^0+/, "");
                                if (/^\d{0,3}$/.test(inputZeros)) {
                                  handleInputChange(
                                    catIndex,
                                    subIndex,
                                    "min_marks",
                                    inputZeros
                                  );
                                }
                              }}
                              autoComplete="off"
                            />
                            <small className="form-text">Min Mark</small>
                          </div>
                          <div className="col-md-6">
                            <input
                              type="number"
                              className="form-control"
                              placeholder="Max Mark"
                              name="max_marks"
                              value={sub.max_marks}
                              onChange={(e) => {
                                const inputText = e.target.value;
                                const inputZeros = inputText.replace(/^0+/, "");
                                if (/^\d{0,3}$/.test(inputZeros)) {
                                  handleInputChange(
                                    catIndex,
                                    subIndex,
                                    "max_marks",
                                    inputZeros
                                  );
                                }
                              }}
                              autoComplete="off"
                            />
                            <small className="form-text">Max Mark</small>
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </form>
      </div>
    </div>
  );
};

export default ExamWiseSubject;

// let subLists = examWiseSubject.map((exam) =>
//   (exam.exam_subject || []).map((sub) => ({
//     id: sub.id,
//     is_selected: sub.is_selected,
//     min_marks: sub.min_marks,
//     max_marks: sub.max_marks,
//   }))
// );
