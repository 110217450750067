import React, { useState, useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import Swal from "sweetalert2";
import { allUsers } from "../../../actions/users/UsersAction";
import { useAlert } from "react-alert";
import { UsersAPI } from "../../../api/usersAPI";
import Loader from "../../Loader/Loader";
import useLoading from "../../CommonComponent/useLoading";
import { getTitle } from "../../CommonComponent/helper";

const AddUsers = () => {
  getTitle("Users");
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let super_admin_id = school_user?.id;
  const { isLoading, startLoading, stopLoading } = useLoading();
  const [selectedOptions, setSelectedOptions] = useState();
  const [loader, setLoader] = useState(false);
  const [userdata, setUserdata] = useState([]);
  const sortedUserdata = [...userdata];
  sortedUserdata.sort((a, b) => {
    const nameA = a.user_data[0].first_name.toLowerCase();
    const nameB = b.user_data[0].first_name.toLowerCase();
    if (nameA < nameB) return -1;
    if (nameA > nameB) return 1;
    return 0;
  });
  const [optionList, setOptionList] = useState([]);  
  const [desOptionList, setDesOptionList] = useState([]);
  const [fetchingData, setFetchingData] = useState(false);
  const [emailError, setEmailError] = useState("");
  const [schoolval, setSchoolVal] = useState("");
  const [edit, setEdit] = useState(false);
  const [editId, setEditId] = useState(0);
  const [deleteId, setDeleteId] = useState({});
  const [input, setInput] = useState({
    school_id: "",
    first_name: "",
    last_name: "",
    email: "",
    is_admin: false,
    super_admin_id,
  });
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value =
      e.target.type === "checkbox" ? e.target.checked : e.target.value;
    setInput((values) => ({ ...values, [name]: value }));

    if (name === "email") {
      const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
      if (!emailPattern.test(value)) {
        setEmailError("Please enter a valid email address.");
      } else {
        setEmailError("");
      }
    }
  };

  const hanldeDropdown = (selectedOptions) => {
    setSchoolVal(selectedOptions.value);
    setInput((values) => ({ ...values, school_id: selectedOptions.value }));
  };
  const handleDesignation = (selectedOptions) => {
    setInput((values) => ({
      ...values,
      designation_id: selectedOptions.value,
    }));
  };

  const handleChecked = (e) => {
    setInput((values) => ({ ...values, is_admin: e.target.checked }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    startLoading();
    try {
      const res = await UsersAPI.createUsers(input);
   
      if (res.data.status === 200) {
        setFetchingData(!fetchingData);
        Swal.fire({
          text: res.data.msg,
          icon: "success",
          imageAlt: "success image",
          allowOutsideClick: false,
        });
        setInput({
          school_id: "",
          first_name: "",
          last_name: "",
          email: "",
          designation_id: "",
          is_admin: false,
          super_admin_id,
        });
        navigate("/admin/users");
      }

      if (res.data.status === 403) {
        Swal.fire({
          text: res.data.msg,
          icon: "error",
          imageAlt: "success image",
          allowOutsideClick: false,
        });
      }
    } catch (err) {
      console.error(err);
    } finally {
      stopLoading();
    }
  };

  // for getSchoolDesignation
  const fetchData = async () => {
    try {
      const res = await UsersAPI.getSchoolDesignation();
      const mappedOptions = res.data.school_list.map((item) => ({
        value: item.id,
        label: item.name,
      }));
      setOptionList(mappedOptions);

      const desigOptions = res.data.designation_list.map((item) => ({
        value: item.id,
        label: item.value,
      }));
      setDesOptionList(desigOptions);
    } catch (err) {
      if (err.response && err.response.status === 401) {
        console.error("Unauthorized access 401");
      } else {
        console.error(err);
      }
    }
  };

  useEffect(() => {
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      startLoading();
      try {
        const response = await UsersAPI.getAllUsers();
        setUserdata(response.data.payload);
      } catch (err) {
        if (err.response && err.response.status === 401) {
          console.error("Unauthorized access");
        } else {
          console.error(err);
        }
      } finally {
        stopLoading();
      }
    };
    fetchData();
  }, [fetchingData]);

  function handleSelect(data) {
    setSelectedOptions(data);
  }

  const onDelete = async (id) => {
    const did = {
      user_id: id,
    };
    Swal.fire({
      title: "Are you sure?",
      text: "You will not be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        try {
          const res = await UsersAPI.deleteUsers(did);

          Swal.fire("Deleted!", res.data.msg, "success");
          setFetchingData(!fetchingData);
          navigate("/admin/users");
        } catch (err) {
          console.error(err);
        }
      }
    });
  };
  if (isLoading) return <Loader /> ;
  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center ">
            <div className="header-action">
              <h1 className="page-title">User</h1>
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Fees-all"
                >
                  User List
                </a>
              </li>
              <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Fees-add">
                  Add User
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className="section-body mt-4"> 
        <div className="container-fluid"> 
          <div className="tab-content">
            <div className="tab-pane active" id="Fees-all">
              <div className="card">
                <div className="card-body table-responsive">
                  <table className="table table-hover  table-vcenter table-sm table-striped text-nowrap">
                    <thead>
                      <tr className="text-center">
                        <th>User Name</th>
                        <th>Email</th>
                        <th>Designation</th>
                        <th>Admin</th>
                        <th>Action</th>
                      </tr>
                    </thead>
                    {sortedUserdata &&
                      sortedUserdata.map((arr, i) => { 
                        return (
                          <tbody key={i}>
                            <tr className="text-white bg-lightgray">
                              <h6 className="m-auto p-2">{arr.school__name}</h6>
                              <td></td>
                              <td></td>
                              <td></td>
                              <td></td>
                            </tr>

                            {arr?.user_data
                              ?.sort((a, b) => {
                                const nameA = a.first_name.toLowerCase();
                                const nameB = b.first_name.toLowerCase();
                                if (nameA < nameB) return -1;
                                if (nameA > nameB) return 1;
                                return 0;
                              })
                              .map((data, index) => {
                                return (
                                  arr.school__name ==
                                    data.school_details.name && (
                                    <>
                                      <tr key={index}>
                                        <td>
                                          <b>
                                            {index + 1}.{"  "}
                                          </b>
                                          {data.first_name +
                                            " " +
                                            data.last_name}
                                        </td>
                                        <td>{data.email}</td>
                                        <td>{data.designation_name}</td>
                                        <td>{data.is_admin ? "Yes" : "No"}</td>

                                        <td className="d-flex gap-2">
                                          <NavLink
                                            to="#"
                                            className="card-options-remove mr-3"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Delete"
                                            onClick={() =>
                                              onDelete(data.fk_user_id)
                                            }
                                          >
                                            <i className="fa fa-trash-o text-danger"></i>
                                          </NavLink>
                                          <NavLink
                                            className="text-muted"
                                            data-toggle="tooltip"
                                            data-placement="top"
                                            title="Edit"
                                            to={`/admin/user-details`}
                                            state={data.id}
                                          >
                                            <i className="fa fa-edit"></i>
                                          </NavLink>
                                        </td>
                                      </tr>
                                    </>
                                  )
                                );
                              })}
                          </tbody>
                        );
                      })}
                  </table>
                </div>
              </div>
            </div>

            <div className="tab-pane" id="Fees-add">
              <div className="row clearfix">
                <div className="col-lg-8 col-md-12 col-sm-12">
                  <div className="card">
                    <div className="card-header">
                      <h3 className="card-title">User Information </h3>
                    </div>
                    <form className="card-body" onSubmit={handleSubmit}>
                      <div className="form-group row">
                        <label
                          htmlFor="school_id"
                          className="col-md-3 col-form-label"
                        >
                          School Name<span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Select
                            options={optionList}
                            name="school_id"
                            placeholder="Select School Name"
                            onChange={hanldeDropdown}
                            isSearchable={true}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          First Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter First name"
                            name="first_name"
                            value={input.first_name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Last Name <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Enter Last name"
                            name="last_name"
                            value={input.last_name}
                            onChange={handleChange}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Email
                          <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <input
                            type="email"
                            className="form-control"
                            name="email"
                            placeholder="Enter email"
                            value={input.email}
                            onChange={handleChange}
                            required
                          />
                          {emailError && (
                            <div className="text-danger">{emailError}</div>
                          )}
                        </div>
                      </div>
                      <div className="form-group row">
                        <label className="col-md-3 col-form-label">
                          Designation <span className="text-danger">*</span>
                        </label>
                        <div className="col-md-9">
                          <Select
                            options={desOptionList}
                            name="designation_id"
                            placeholder="Select Designation"
                            onChange={handleDesignation}
                            isSearchable={false}
                            required
                          />
                        </div>
                      </div>
                      <div className="form-group row d-none">
                        <label className="col-md-3 col-form-label">
                          Admin Access
                        </label>
                        <div className="col-md-9">
                          <label className="custom-switch">
                            <input
                              type="checkbox"
                              className="custom-switch-input"
                              name="is_admin"
                              checked={input.is_admin}
                              onChange={handleChecked}
                            />
                            <span className="custom-switch-indicator"></span>
                          </label>
                        </div>
                      </div>
                      <div className="form-group row">
                        <div className="col-md-9 offset-md-3">
                          <button type="submit" className="btn btn-primary">
                            Save
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default AddUsers;
