import axios from "axios";
import { BASE_URL } from "../../Config/BaseUrl";

const token = localStorage.getItem("token");

const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

export const salaryStructure = {
    getSalarystructureSchoolwise: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_salarystructure_schoolwise",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
  addUpdateSalaryStructure: async (data) => {
    const axiosConfig = createAxiosConfig(
      "add_update_salary_structure",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },

  getStaffArr: async (data) => {
    const axiosConfig = createAxiosConfig(
      "get_staff_arr",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },

  salary_report_view: async (data) => {
    const axiosConfig = createAxiosConfig(
      "salary_report_view",
      "POST",
      data
    );
    return await axios(axiosConfig)
  },
};