import React, { useState, useEffect } from "react";
import "./Certificate.css";
import html2pdf from "html2pdf.js";
import { useDispatch, useSelector } from "react-redux";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import Select from "react-select";
import {
  getClass,
  getSection,
} from "../../../../actions/Admission/StudentReport";
import Swal from "sweetalert2";
import {
  bonafiteCertificate,
  characterCertificate,
  getBonafiteCertificateList,
  getCharacterCertificateList,
  getStudentById,
} from "../../../../actions/school_user/Certificate";
import AdmittedStudentList from "../../Comman/AdmittedStuList";
import StudentSessionWiseList from "../../Comman/StudentListSessionWise";
import { resetState } from "../../../../actions/ResetAction/ResetAction";
import useSessionRedirect from "../../Comman/SessionChange";
import ValidationComponent from "../../Comman/Validation";
import ValidationMessage from "../../../CommonComponent/ValidationMessage";
import CertificateTable from "./CertificateTable";

import www from "../../Examination/GradeCard/www.png";

const BonafideCertificate = () => {
  const token = localStorage.getItem("token");
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  const { logoData, loading, error } = useSelector((state) => state.schoolLogo);
  const { email, id, user_type, name, user_detail } = school_user;
  const {
    school_name,
    organization_name,
    school_address,
    school_contact_email,
    school_contact_number,
    school_pincode,
    udise_no,
    school_logo,
    school_emp_id,
  } = user_detail;

  let school_id = school_user?.user_detail?.fk_school_id;
  const session_id = useSessionRedirect();

  const dispatch = useDispatch();
  const [studentData, setStudentData] = useState(null);
  const [bonafitecode, setBonafitecode] = useState(null);
  const [charactercode, setcharactercode] = useState(null);
  const [charList, setCharList] = useState([]);
  const [bonafideList, setBonafideList] = useState([]);
  const [student_uid, setstudent_uid] = useState("");
  const [Class, setClass] = useState("");
  const [Section, setSection] = useState("");
  const { allClass } = useSelector((state) => state.getClass);
  const { allSection } = useSelector((state) => state.getSection);
  const { charlist, bonalist, studata } = useSelector(
    (state) => state.certificate
  );

  let dataS = studata?.payload;
  if (student_uid === "") {
    dataS = "";
  } else if (student_uid !== studata?.payload?.student_code) {
    dataS = "";
  }

  useEffect(() => {
    dispatch(resetState());
  }, [dispatch]);

  useEffect(() => {
    setStudentData(studata?.payload);
  }, [studata]);

  useEffect(() => {
    setCharList(charlist?.payload);
  }, [charlist]);

  useEffect(() => {
    setBonafideList(bonalist?.payload);
  }, [bonalist]);

  let stu_b = {
    academic_id: session_id,
    school_id: school_id,
    student_id: dataS?.id,
  };

  useEffect(() => {
    if (dataS?.id) {
      dispatch(getBonafiteCertificateList(stu_b));
    }
  }, [dispatch, dataS?.id, student_uid, session_id]);

  useEffect(() => {
    if (dataS?.id) {
      dispatch(getCharacterCertificateList(stu_b));
    }
  }, [dispatch, dataS?.id, student_uid, session_id]);

  const [modal1, setModal1] = useState(false);
  const [modal2, setModal2] = useState(false);

  const handleClear = (data) => {
    setstudent_uid("");
    setCharList([]);
    setBonafideList([]);
    setBonafitecode(null);
    setcharactercode(null);
  };

  const {
    validationError: studentValidationError,
    validateControl: studentValidateControl,
  } = ValidationComponent({
    data: { student_uid },
    requiredFields: ["student_uid"],
  });
  const {
    validationError: studentValidationError2,
    validateControl: studentValidateControl2,
  } = ValidationComponent({
    data: { student_uid },
    requiredFields: ["student_uid"],
  });

  const toggle1 = async () => {
    const isValid = await studentValidateControl();
    if (isValid) setModal1(!modal1);
  };
  const toggle2 = async () => {
    const isValid = await studentValidateControl2();
    if (isValid) setModal2(!modal2);
  };

  function convertDate(data) {
    const parts = data.split("-");
    const formattedDate = parts[2] + "-" + parts[1] + "-" + parts[0];
    return formattedDate;
  }

  const classList = allClass?.payload?.map((list) => {
    return {
      label: list.class_name,
      value: list.id,
    };
  });

  const sectionList = allSection?.payload?.map((list) => {
    return {
      label: list.section,
      value: list.id,
    };
  });

  useEffect(() => {
    const body = {
      class_id: Number(Class?.value),
    };
    if (Class?.value) {
      dispatch(getSection(body));
    }
  }, [dispatch, Class]);

  const handleConvertToPdf = async () => {
    const element = document.getElementById("divToConvert");
    if (element) {
      const opt = {
        margin: 10,
        filename: "generated.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      const pdfDataUri = await html2pdf()
        .from(element)
        .set(opt)
        .outputPdf("datauristring");

      const newTab = window.open();
      newTab.document.write(
        '<style> body { margin: 0px !important; overflow: hidden !important; }</style><iframe width="100%" height="100%" src="' +
          pdfDataUri +
          '"></iframe>'
      );
      newTab.document.close();
    }

    setModal1(!modal1);
    handleClear();
  };
  const handleConvertToPdf1 = async () => {
    const element = document.getElementById("divToConvert");
    if (element) {
      const opt = {
        margin: 10,
        filename: "generated.pdf",
        image: { type: "jpeg", quality: 0.98 },
        html2canvas: { scale: 2 },
        jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
      };
      const pdfDataUri = await html2pdf()
        .from(element)
        .set(opt)
        .outputPdf("datauristring");

      const newTab = window.open();
      newTab.document.write(
        '<style> body { margin: 0px !important; overflow: hidden !important; }</style><iframe width="100%" height="100%" src="' +
          pdfDataUri +
          '"></iframe>'
      );
      newTab.document.close();
    }

    setModal2(!modal2);
    handleClear();
  };

  const handleSubmit = async () => {
    const isValid = await studentValidateControl();
    if (!isValid) return;
    Swal.fire({
      title: "CONFIRMATION",
      text: "Do you want to Generate Bonafide for Selected Student ?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: `No`,
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          academic_session: session_id,
          student_id: dataS?.id,
          school_id: school_id,
          insert_by: school_emp_id,
        };
        dispatch(bonafiteCertificate(body))
          .then((res) => {
            setBonafitecode(res.bonafite_code);
            dispatch(getBonafiteCertificateList(stu_b));
          })
          .catch((err) => console.error(err));
        toggle1();
      } else if (result.isDenied) {
        // window.location.href = "/school/bonafide";
      }
    });
  };
  const handleSubmit1 = async () => {
    const isValid = await studentValidateControl2();
    if (!isValid) return;
    Swal.fire({
      title: "CONFIRMATION",
      text: "Do you want to Generate Character Certificates for Selected Student ?",
      icon: "question",
      showDenyButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#f46a6a",
      confirmButtonText: "Yes",
      denyButtonText: `No`,
      allowOutsideClick: false,
    }).then((result) => {
      if (result.isConfirmed) {
        const body = {
          academic_session: session_id,
          student_id: dataS?.id,
          school_id: school_id,
          insert_by: school_emp_id,
        };
        dispatch(characterCertificate(body))
          .then((res) => {
            setcharactercode(res.character_code);
            dispatch(getCharacterCertificateList(stu_b));
          })
          .catch((err) => console.error(err));
        toggle2();
      } else if (result.isDenied) {
        // window.location.href = "/school/bonafide";
      }
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      const body = {
        fk_academic: session_id,
        student_code: student_uid,
        school_id: Number(user_detail.fk_school_id),
      };
      try {
        let response = await dispatch(getStudentById(body));
        if (response.status === 500) {
          Swal.fire({
            text: response.msg,
            icon: "warning",
            imageAlt: "warning image",
          }).then((result) => {
            if (result.isConfirmed) {
              handleClear();
            }
          });
        }
      } catch (err) {
        console.error(err);
      }
    };
    fetchData();
  }, [student_uid, session_id, dispatch]);
  const formatDate = (date) => {
    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0");
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  const today = new Date();
  const formattedDate = formatDate(today);

  return (
    <>
      <div className="section-body">
        <div className="container-fluid">
          <div className="d-flex justify-content-between align-items-center">
            <div className="header-action">
              <h1 className="page-title">Student Certificates</h1>
              {/* <ol className="breadcrumb page-breadcrumb">
                <li className="breadcrumb-item active" aria-current="page">
                  Certificates
                </li>
              </ol> */}
            </div>
            <ul className="nav nav-tabs page-header-tab">
              <li className="nav-item">
                <a
                  className="nav-link active"
                  data-toggle="tab"
                  href="#Bonafide"
                  onClick={handleClear}
                >
                  Bonafide Certificates
                </a>
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  data-toggle="tab"
                  href="#Character"
                  onClick={handleClear}
                >
                  Character Certificates
                </a>
              </li>
              <li className="nav-item">
                <AdmittedStudentList setstudent_uid={setstudent_uid} />
              </li>
            </ul>
          </div>
        </div>
      </div>
      <br />
      <div className="section-body mt-4">
        <div className="container-fluid">
          <div className="tab-content">
            <div className="tab-pane active" id="Bonafide">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    <div className="col-lg-6  ">
                      <div className="form-group row">
                        <div className="col-lg-6   my-1">
                          <div className="input-group">
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Student Code"
                              value={student_uid}
                              onChange={(e) => {
                                const inputText = e.target.value;
                                // if (/^\d*$/.test(inputText)) {
                                //   setstudent_uid(inputText);
                                // }
                                setstudent_uid(inputText);
                              }}
                            />
                            <StudentSessionWiseList
                              setstudent_uid={setstudent_uid}
                            />
                            <ValidationMessage
                              condition={studentValidationError && !student_uid}
                              errorMsg={"Please Select Student code."}
                            />
                          </div>
                        </div>
                        <div className="col-lg-6 my-1">
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Student Name"
                            disabled
                            value={student_uid && dataS?.name}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-6  my-1">
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm mx-1"
                          onClick={toggle1}
                        >
                          <i className="fa fa-eye px-1"></i>
                          VIEW
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm mx-1"
                          onClick={handleSubmit}
                        >
                          GENERATE
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-danger btn-sm mx-1"
                          onClick={handleClear}
                        >
                          <i className="fa fa-times px-1"></i>
                          CLEAR
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <CertificateTable
                tableHeading={"Bonafide Issued History"}
                tableData={bonafideList}
              />
            </div>
            <div className="tab-pane" id="Character">
              <div className="card">
                <div className="card-body">
                  <div className="row">
                    {/* <div className="col-lg-2 ">
                          <div className="input-group">
                            <label className="col-form-label mx-2">Class</label>
                            <Select
                              options={classList}
                              placeholder="Select Class"
                              value={Class}
                              onChange={(e) => setClass(e)}
                              isSearchable={true}
                            />
                          </div>
                        </div>
                        <div className="col-lg-2 ">
                          <div className="input-group">
                            <label className="col-form-label mx-2">Section</label>
                            <Select
                              options={sectionList}
                              placeholder="Select Section"
                              value={Section}
                              onChange={(e) => setSection(e)}
                              isSearchable={true}
                            />
                          </div>
                        </div> */}
                    <div className="col-lg-3  my-1">
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Student Code"
                          value={student_uid}
                          onChange={(e) => {
                            const inputText = e.target.value;
                            if (/^\d*$/.test(inputText)) {
                              setstudent_uid(inputText);
                            }
                          }}
                        />
                        <StudentSessionWiseList
                          setstudent_uid={setstudent_uid}
                        />
                        <ValidationMessage
                          condition={studentValidationError2 && !student_uid}
                          errorMsg={"Please Select Student code."}
                        />
                      </div>
                    </div>
                    <div className="col-lg-3  my-1">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Student Name"
                        disabled
                        value={student_uid && dataS?.name}
                      />
                    </div>
                    <div className="col-lg-6  my-1">
                      <div className="d-flex">
                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm mx-1"
                          onClick={toggle2}
                        >
                          <i className="fa fa-eye px-1"></i>
                          VIEW
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-secondary btn-sm mx-1"
                          onClick={handleSubmit1}
                        >
                          GENERATE
                        </button>

                        <button
                          type="button"
                          className="btn btn-outline-danger mx-1"
                          onClick={handleClear}
                        >
                          <i className="fa fa-times px-1"></i>
                          CLEAR
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <CertificateTable
                tableHeading={"Character History"}
                tableData={charList}
              />
            </div>
          </div>
        </div>
      </div>

      <Modal
        isOpen={modal1}
        toggle={toggle1}
        fullscreen
        className="modal-lg"
        backdrop="static"
      >
        {/* <ModalHeader toggle={toggle}>  </ModalHeader> */}{" "}
        {/* <div className="form-group row p-4">
          <div className="col-lg-4 col-md-4 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={toggle}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div> */}
        <div className="text-right m-3 ">
          <button className="btn btn-primary" onClick={handleConvertToPdf}>
            Print Certificate
          </button>

          <Button color="secondary ml-2" onClick={toggle1}>
            Close
          </Button>
          <hr className="border border-muted" />

        </div>
        <ModalBody>
          <>
            <div className="" id="divToConvert">
              <div className="d-flex align-items-stretch">
                {/* Column for the image in the left-top corner */}
                <div className="col-3">
                  {logoData ? (
                    <img
                      src={`data:image/png;base64,${logoData}`}
                      alt="school_logo"
                      width={100}
                      height={100}
                      className=""
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <img
                      src={www}
                      alt="fallback_image"
                      width={100}
                      height={100}
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                </div>

                {/* Column for the text in the center */}
                <div className="col-9">
                  <div className="text-center col-8">
                    <p className="font-weight-bold text-dark">
                      {organization_name}
                    </p>
                    <div className="font-weight-bold">
                      {dataS?.student_name}
                    </div>
                    <div>
                      <h6>{school_address}</h6>
                      <h6 style={{ fontSize: "0.7rem" }}>
                        Pincode- {school_pincode}, Ph: {school_contact_number}
                      </h6>
                      <h6 style={{ fontSize: "0.7rem" }}>
                        E-mail: {school_contact_email}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              {/* Divider at the end */}
              <div className="border-bottom w-100 my-2"></div>

              <div className="certificate">
                <div className="certificate-header">BONAFIDE CERTIFICATE</div>
                <div className="content1">
                  <div className="serial-no">
                    SR. No. :-{bonafitecode ? bonafitecode : null}
                  </div>
                  <div className="gr-no">GR. NO. :-</div>
                  <div className="student-info">
                    <p>
                      This is to certify that,{" "}
                      <strong>Mast./Miss. {dataS?.name}</strong>, is/was
                      Bonafide Student of this Institution for {dataS?.section}{" "}
                      Section studying in std. {dataS?.std_class} for the
                      Academic year
                      {` ${dataS?.academic_year}`}. His/Her Birth date as
                      recorded in our General Register is
                      <strong>
                        {dataS?.date_of_birth
                          ? ` ${convertDate(dataS?.date_of_birth)}`
                          : null}
                      </strong>{" "}
                      (In words <strong>{dataS?.in_words}</strong>) and Birth
                      place is{" "}
                      <strong>
                        {dataS?.birth_place ? dataS?.birth_place : "........."}
                      </strong>{" "}
                      and caste is
                      <strong>
                        {` ${dataS?.caste ? dataS?.caste : " ........."}`}
                      </strong>
                      .
                    </p>
                    <p>
                      To the best of our knowledge, he/she bears a good moral
                      character.
                    </p>
                  </div>
                </div>
                <div className="print-footer">
                  <div className="date">DATE: {formattedDate}</div>
                  {/* <div className="signature">Prepare by</div> */}
                  <div className="signature">Authorised Signatory</div>
                </div>
              </div>
            </div>
          </>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
        </ModalFooter>
      </Modal>
      <Modal
        isOpen={modal2}
        toggle={toggle2}
        fullscreen
        className="modal-lg"
        backdrop="static"
      >
        {/* <ModalHeader toggle={toggle}>  </ModalHeader> */}{" "}
        {/* <div className="form-group row p-4">
          <div className="col-lg-4 col-md-4 d-flex justify-content-end">
            <button
              type="button"
              className="btn btn-outline-danger btn-sm"
              onClick={toggle}
            >
              <i className="fa fa-times"></i>
            </button>
          </div>
        </div> */}
         <div className="text-right m-3">
              <button className="btn btn-primary" onClick={handleConvertToPdf1}>
                Print Certificate
              </button>
              <Button color="secondary ml-2" onClick={toggle2}>
                Close
              </Button>
              <hr className="border border-muted" />

            </div>
        <ModalBody>
          <>
            <div className="" id="divToConvert">
              <div className="d-flex align-items-stretch">
                {/* Column for the image in the left-top corner */}
                <div className="col-3">
                  {logoData ? (
                    <img
                      src={`data:image/png;base64,${logoData}`}
                      alt="school_logo"
                      width={100}
                      height={100}
                      className=""
                      style={{ borderRadius: "50%" }}
                    />
                  ) : (
                    <img
                      // src={www}  // replace with your fallback image path
                      alt="fallback_image"
                      width={100}
                      height={100}
                      style={{ borderRadius: "50%" }}
                    />
                  )}
                </div>

                {/* Column for the text in the center */}
                <div className="col-9">
                  <div className="text-center col-8">
                    <p className="font-weight-bold">{organization_name}</p>
                    <div className="font-weight-bold">
                      {dataS?.student_name}
                    </div>
                    <div>
                      <h6>{school_address}</h6>
                      <h6 style={{ fontSize: "0.7rem" }}>
                        Pincode- {school_pincode}, Ph: {school_contact_number}
                      </h6>
                      <h6 style={{ fontSize: "0.7rem" }}>
                        E-mail: {school_contact_email}
                      </h6>
                    </div>
                  </div>
                </div>
              </div>

              {/* Divider at the end */}

              <div className="border-bottom  w-100 my-2"></div>
              <div className="d-flex justify-content-between mt-1">
                <b>No :-{charactercode ? charactercode : null} </b>
                <b> Date :{formattedDate}</b>
              </div>
              <div className="certificate">
                <div className="certificate-header">
                  CONDUCT / CHARACTER CERTIFICATE :-
                </div>
                <div className="student-info">
                  <p>
                    This is to certify that ,{" "}
                    <strong className="text-decoration-underline">
                      {dataS?.name}
                    </strong>
                    was a bonafide student of this school for{" "}
                    <strong>1 year</strong> , from <b>2022</b> to <b>2023</b> ,{" "}
                    he/she has passed standard {dataS?.std_class} As far as
                    knowledge to us he/she bears a good moral character. I wish
                    him/her every success in life.
                  </p>
                  <p></p>
                  <p></p>
                  <p></p>
                  <p></p>
                  <div className="d-flex justify-content-between mt-1">
                    <b className=""> Date :{formattedDate}</b>
                    <b className="fw-bold">
                      Signature of Head of the Institution with seal{" "}
                    </b>
                  </div>
                </div>
              </div>
            </div>
           
          </>
        </ModalBody>
        <ModalFooter>
          {/* <Button color="secondary" onClick={toggle}>
            Cancel
          </Button> */}
        </ModalFooter>
      </Modal>
    </>
  );
};

export default BonafideCertificate;
