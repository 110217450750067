export const validateFile = (file,fileInputRef) => {
    const acceptedExtensions = [".jpg", ".jpeg", ".png", ".gif", ".pdf"];
    const maxFileSizeMB = 5;
    const fileName = file.name;
    const path = fileName.split(".");
    const fileExtension = `.${path[path.length - 1]}`;
    const fileSizeMB = file.size / (1024 * 1024);
    if (fileSizeMB > maxFileSizeMB) {
      Swal.fire({
        text: "File size exceeds the maximum allowed (5 MB)!",
        icon: "warning",
        imageAlt: "warning image",
        allowOutsideClick: false,
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      return false;
    }
    if (!acceptedExtensions.includes(fileExtension.toLowerCase())) {
      Swal.fire({
        text: "This file is not accepted!",
        icon: "warning",
        imageAlt: "warning image",
        allowOutsideClick: false,
      });
      if (fileInputRef.current) {
        fileInputRef.current.value = null;
      }
      return false;
    }
    return true;
  };