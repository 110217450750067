import React, { useState, useEffect } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Table,
} from "reactstrap";
import EmptyListMessage from "../../../CommonComponent/EmptyListMessage";
import HostelAPI from "../../../../api/Hostel/HostelRegistration";
import { convertDateInMMDDYY } from "../../../CommonComponent/DateFormatUtil";

const RoomAllotmentList = (props) => {
  const { allotmentList, setIsEditRoom } = props;
  const [modal, setModal] = useState(false);

  const toggle = () => {
    setModal(!modal);
  };

  const handleSelectRoom = (data) => {
    setIsEditRoom(data);
    setModal(!modal);
  };

  const closeBtn = (
    <button className="btn btn-sm close " onClick={toggle} type="button">
      <i className="fa fa-times" style={{ color: "red" }}></i>
    </button>
  );
  return (
    <>
      <button
        className="btn btn-outline-secondary btn-sm mx-1"
        type="button"
        onClick={toggle}
      >
        <i className="fa fa-search"> </i> Search
      </button>
      <Modal isOpen={modal} toggle={toggle} fullscreen className="mobel_box" backdrop="static" keyboard={false} >
        <ModalHeader toggle={toggle} close={closeBtn}>
          {/* {title} */}
        </ModalHeader>
        <ModalBody>
          <div className="table-responsive">
            {allotmentList && allotmentList?.length > 0 ? (
              <Table size="sm" className="table-bordered" striped>
                <thead className="table-dark">
                  <tr className="text-center text-nowrap ">
                    <th>Hostel Name</th>
                    <th>Student No</th>
                    <th>Student Name</th>
                    <th>Student Type</th>
                    <th>Start Date</th>
                    <th>End Date</th>
                  </tr>
                </thead>
                <tbody>
                  {allotmentList?.map((room, index) => (
                    <tr
                      key={index}
                      className="clickable-row-color text-center"
                      onClick={() => handleSelectRoom(room)}
                    >
                      <td className="text-left">
                        {room.fk_hostel__hostelName}
                      </td>
                      <td className="text-center">
                        {room.fk_student__fk_student__student_code}
                      </td>
                      <td className="text-left">
                        {room.student_name
                          ? room.student_name
                          : room.fk_student__fk_student__std_name}
                      </td>
                      <td>{room.student_type}</td>
                      <td>{convertDateInMMDDYY(room.start_date)}</td>
                      <td>{convertDateInMMDDYY(room.end_date)}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            ) : (
              <EmptyListMessage
                message={"Student Not Available !"}
                Div="div"
              />
            )}
          </div>
        </ModalBody>
      </Modal>
    </>
  );
};

export default RoomAllotmentList;
