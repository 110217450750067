import React from "react";
import { Table } from "reactstrap";
import { formatDate } from "../../../CommonComponent/DateFormatUtil";

const CertificateTable = ({ tableData, tableHeading }) => {
  return (
    <>
      <div className="card">
        <div className="card-header">
          <h3 className="card-title">{tableHeading}</h3>
        </div>
        <div className="card-body row">
          <div className="table-responsive col-md-6">
            <Table size="sm" className="table-hover text-nowrap ">
              <thead>
                <tr className="text-center">
                  <th>Sr No</th>
                  <th>Certificate No</th>
                  <th>Print Date</th>
                  <th>Print Status</th>
                </tr>
              </thead>
              <tbody>
                {tableData?.map((s, index) => (
                  <tr className="text-center" key={index}>
                    <td>{index + 1}</td>
                    <td>{s.bonafite_no ? s.bonafite_no : s.charactor_code}</td>
                    <td>{formatDate(s.created_date)}</td>
                    <td>{s.status}</td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>
        </div>
      </div>
    </>
  );
};

export default CertificateTable;
