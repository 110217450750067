import axios from "axios";
import { BASE_URL} from "../../Config/BaseUrl";
const token = localStorage.getItem("token");
const createAxiosConfig = (url, method, data) => {
  return {
    method,
    url: `${BASE_URL}${url}/`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};
const createAxiosConfigForPagination = (url, method, data, cpage) => {
  return {
    method,
    url: `${BASE_URL}${url}/?page=${cpage}`,
    headers: {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json",
    },
    data,
  };
};

export const BusFeeCollectionAPI = { 
    getStudentRouteMapped : async (data) => {
       const axiosConfig = createAxiosConfig("get_StudentRoute_mapped","POST", data);
       return await axios(axiosConfig);
     },
     getBusPayment: async (data, cpage) => {
      const axiosConfig = createAxiosConfigForPagination(
        "search_bus_fee_receipt",
        "POST",
        data,
        cpage
      );
      return await axios(axiosConfig);
    },
    busFeePaymentApi :async(data)=>{
      const axiosConfig = createAxiosConfig("Bus_fee_payment_api","POST",data);
      return await axios(axiosConfig);
    },

    BusCancelPaymentApi: async (data) => {
      const axiosConfig = createAxiosConfig("Bus_cancel_payment_api", "POST", data);
      return await axios(axiosConfig);
    } 
  }