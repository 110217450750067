import React from "react";
import PropTypes from "prop-types";
import withRouter from "./WithRouter";

const NonAuthLayout = (props) => {
  return (
    <React.Fragment>
      {props.children}
      {/* <Footer /> */}
    </React.Fragment>
  );
};

NonAuthLayout.propTypes = {
  children: PropTypes.any,
  location: PropTypes.object,
};

export default withRouter(NonAuthLayout);
