import "./App.css";
import { authProtectedRoutes, publicRoutes } from "../routes/Routes";
import { Route, Routes, useNavigate } from "react-router-dom";
import Authmiddleware from "../routes/Authmiddleware";
import NonAuthLayout from "../routes/NonAuthLayout";
import Layout from "../pages/Layout/Layout";
import { useEffect } from "react";
import { DualLoginApi } from "../api/DualLogin/DualLogin";
import Swal from "sweetalert2"; // Import SweetAlert library

function App() {
  const school_user = JSON.parse(localStorage.getItem("school_user"));
  let school_emp_id = school_user?.user_detail?.school_emp_id;
  const email = school_user?.email || "";
  const token = localStorage.getItem("token") || "";
  const school_id = school_user?.user_detail?.fk_school_id || "";
  const navigate = useNavigate();

  useEffect(() => {
    const checkTokenAndTime = async () => {
      let data = {
        school_emp_id: school_emp_id || "",
        email: email,
        token: token,
        school_id: school_id,
      };

      const response = await DualLoginApi.DualLogin(data);
      const responseData = response.data;
      if (responseData.user_status === "False") {
        Swal.fire({
          icon: "info",
          title: "Session Expired",
          text: "Another Login detected. Please log in again.",
          confirmButtonText: "OK",
          allowOutsideClick: false,
        }).then(() => {
          navigate("/logout");
        });
        localStorage.clear();
      } else {
        const loginTime = parseInt(localStorage.getItem("loginTime"), 10);
        if (loginTime) {
          const currentTime = new Date().getTime();
          const elapsedTime = currentTime - loginTime;
          const twelveHoursInMillis = 12 * 60 * 60 * 1000;

          if (elapsedTime >= twelveHoursInMillis) {
            Swal.fire({
              icon: "info",
              title: "Session Timeout",
              text: "Your session has timed out. Please log in again.",
              confirmButtonText: "OK",
              allowOutsideClick: false,
            }).then(() => {
              navigate("/logout");
            });
          } else {
            const remainingTime = twelveHoursInMillis - elapsedTime;
            const timeoutId = setTimeout(() => {
              Swal.fire({
                icon: "info",
                title: "Session Timeout",
                text: "Your session has timed out. Please log in again.",
                confirmButtonText: "OK",
                allowOutsideClick: false,
              }).then(() => {
                navigate("/logout");
              });
            }, remainingTime);

            return () => clearTimeout(timeoutId);
          }
        }
      }
    };

    checkTokenAndTime();
  }, [navigate, school_emp_id, email, token, school_id]);

  return (
    <>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}
        {school_user &&
          authProtectedRoutes.map((route, idx) => (
            <Route
              path={
                school_user?.user_type === "Admin"
                  ? `/admin${route.path}`
                  : `/school${route.path}`
              }
              element={
                <Authmiddleware>
                  <Layout>{route.component}</Layout>
                </Authmiddleware>
              }
              key={idx}
              exact={true}
            />
          ))}
      </Routes>
    </>
  );
}

export default App;
